namespace StatBanana.Web.Client.Domain.Strategiser

open System
open StatBanana.Web.Client.Domain.Dota2

/// Specifies the type of selectable values, when something can be selected or not.
type Selectable<'T> =
    | Selected of 'T
    | NotSelected

/// Specifies the type of creatable values, when something can be created or not.
type Creatable<'T> =
    | Created of 'T
    | NotCreated

type MeasuringVariants =
    | StraightMeasuring
    | RadialMeasuring

[<RequireQualifiedAccess>]
type AttackRangeCircleVisibility =
    | Visible
    | NotVisible

module AttackRangeState =
    let getName (attackRangeState : AttackRangeCircleVisibility) =
        match attackRangeState with
        | AttackRangeCircleVisibility.Visible -> "Visible"
        | AttackRangeCircleVisibility.NotVisible -> "Hidden"
    let fromString (string : string) : AttackRangeCircleVisibility option =
        match string with
        | "Visible" -> Some AttackRangeCircleVisibility.Visible
        | "NotVisible" -> Some AttackRangeCircleVisibility.NotVisible
        | _ -> None

[<RequireQualifiedAccess>]
type BuildingState =
    | Destroyed
    | NotDestroyed
    | Captured of FactionType

module BuildingState =
    let getName (state : BuildingState) =
        match state with
        | BuildingState.Destroyed -> "Destroyed"
        | BuildingState.NotDestroyed -> "Not Destroyed"
        | BuildingState.Captured side -> (FactionType.getName side)
    let fromString (string : string) : BuildingState option =
        let radiantString = "Captured " + (FactionType.getName FactionType.Radiant)
        let direString = "Captured " + (FactionType.getName FactionType.Dire)
        match string with
        | "Destroyed" -> Some BuildingState.Destroyed
        | "NotDestroyed" -> Some BuildingState.NotDestroyed
        | capturedString when capturedString = radiantString -> Some (BuildingState.Captured FactionType.Radiant)
        | capturedString when capturedString = direString -> Some (BuildingState.Captured FactionType.Dire)
        | _ -> None

/// Coordinates that represent the location of something on a Strategiser map.
type Coordinates =
    { latitude : float
      longitude : float }

type Modal =
    | DemoFinishedModal
    | DemoWelcomeModal
    | KeyboardShortcutsModal
    | MapViewerWelcomeModal
    | NoModal

type ViewerMode =
    | MapViewer
    | SessionViewer of collaborationEnabled : bool

type Mode =
    | DemoMode
    | EditorMode of collaborationEnabled : bool
    | ViewerMode of ViewerMode
module Mode =
    let isDemoMode (mode : Mode) : bool =
        match mode with
        | DemoMode ->
            true
        | _ ->
            false

    let isMapViewerMode (mode : Mode) : bool =
        match mode with
        | ViewerMode MapViewer ->
            true
        | _ ->
            false

    let isViewerMode (mode : Mode) : bool =
        match mode with
        | ViewerMode _ ->
            true
        | DemoMode
        | EditorMode true
        | EditorMode false ->
            false

    let isCollaborating (mode : Mode) : bool =
        match mode with
        | EditorMode true
        | ViewerMode (SessionViewer true) ->
            true
        | DemoMode
        | ViewerMode _
        | EditorMode false ->
            false

    let isHostingCollaboration (mode : Mode) : bool =
        match mode with
        | EditorMode true ->
            true
        | DemoMode
        | ViewerMode _
        | EditorMode false ->
            false

    let isViewingCollaboration (mode : Mode) : bool =
        match mode with
        | ViewerMode (SessionViewer true) ->
            true
        | DemoMode
        | ViewerMode _
        | EditorMode true
        | EditorMode false ->
            false

    let enableCollaboration (mode : Mode) : Mode =
        match mode with
        | DemoMode ->
            DemoMode
        | EditorMode _ ->
            EditorMode true
        | ViewerMode _ ->
            ViewerMode (SessionViewer true)

    let disableCollaboration (mode : Mode) : Mode =
        match mode with
        | DemoMode ->
            DemoMode
        | EditorMode _ ->
            EditorMode false
        | ViewerMode _ ->
            ViewerMode (SessionViewer false)