/// Firebase auth service, handles authentication through firebase.
namespace StatBanana.Web.Client.Services

open System

open Fable.Core.JsInterop
open Fable.Import

open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Import.Firebase
open StatBanana.Web.Client.Import.Firebase.Auth
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Dto.Firebase
open StatBanana.Web.Client.Import.Firebase.Firestore

module FirebaseAuthService =
    /// <summary>
    ///     Starts the auth ui.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase auth client.
    /// </param>
    ///
    /// <param name="authUIConfig">
    ///     The config to pass to the auth ui.
    /// </param>
    ///
    /// <param name="buttonActionText">
    ///     The action text to show in the ui buttons.
    /// </param>
    let private startUI
        (auth : Firebase.Auth.Auth)
        (authUIConfig : AuthUIConfig) : unit =
        // Check for an existing ui instance
        let existingUI = FirebaseUI.FirebaseUI.auth.AuthUI.getInstance()
        match existingUI with
        | None ->
            let ui = auth
                     |> Some
                     |> FirebaseUI.FirebaseUI.auth.AuthUI.Create
            let startConfig =
                createObj [
                    "callbacks" ==> createObj [
                        "signInFailure" ==> authUIConfig.onSignInFailure
                        "signInSuccessWithAuthResult" ==>
                            authUIConfig.onSuccessfulSignIn
                        "uiShown" ==>
                            authUIConfig.onUIShown
                    ]
                    "credentialHelper" ==> FirebaseUI.Auth.CredentialHelperStatic.NONE
                    "privacyPolicyUrl" ==> authUIConfig.privacyUrl
                    "signInOptions" ==> [|
                        createObj [
                          "provider" ==> Auth.Provider.GOOGLE_SIGN_IN_METHOD
                        ]
                        createObj [
                          "provider" ==> Auth.Provider.EMAIL_PASSWORD_SIGN_IN_METHOD
                          "requireDisplayName" ==> false
                        ]
                    |]
                    "tosUrl" ==> authUIConfig.termsOfServiceUrl
                ] :?> JS.Object
            ui.start (!^authUIConfig.uiContainerId, startConfig)
        | Some _ -> ()

    /// <summary>
    ///     Deletes the existing auth ui if it exists.
    /// </summary>
    let private deleteUI () =
        let existingUI = FirebaseUI.FirebaseUI.auth.AuthUI.getInstance()
        Option.map
            (fun (ui : FirebaseUI.Auth.AuthUI) -> ui.delete ())
            existingUI
        |> ignore

    /// <summary>
    ///     Handles when the auth state is changed.
    /// </summary>
    ///
    /// <param name="onSignedIn">
    ///     A handler that is called when a user is signed in.
    /// </param>
    ///
    /// <param name="onSignedOut">
    ///     A handler that is called when a user is signed out.
    /// </param>
    let private onAuthStateChanged
        (auth : Firebase.Auth.Auth)
        (onSignedIn : Result<AuthenticatedUser, string> -> unit)
        (onSignedOut : unit -> unit) =

        let getValidAuthenticatedUser
            (user : User)
            (tokenResult : Firebase.Auth.IdTokenResult)
            : Result<AuthenticatedUser, string> =

            match user.email with
            | Some email ->
                { claims = tokenResult.claims |> UserClaim.toDomainList
                  displayName = user.displayName
                  email = email
                  id = user.uid
                  photo = user.photoURL
                  token = tokenResult.token
                  tokenIssuedAt =
                      tokenResult.issuedAtTime
                      |> DateTimeOffset.Parse }
                |> Ok
            | _ ->
                Error "Log in failed: user email was undefined"

        let authChangeHandler (firebaseUser : Firebase.User option) : obj option =
            match firebaseUser with
            | Some user ->
                user.getIdTokenResult()
                |> Promise.map (fun result ->
                    let authenticatedUser =
                        getValidAuthenticatedUser user result

                    // Send result to callback
                    onSignedIn authenticatedUser)
                |> Promise.catch (fun err ->
                    // Send Error result to callback
                    err.ToString()
                    |> Error
                    |> onSignedIn)
                |> ignore
            | None -> onSignedOut ()

            None

        auth.onIdTokenChanged !^authChangeHandler
        |> ignore

    let private isTokenRefreshRequired
        (app : Firebase.App.App)
        (currentUser : AuthenticatedUser)
        onRequireRefresh
        onError =

        let onNextHandler (snap : DocumentSnapshot) =
            match snap.get !^"userClaimsUpdatedAt" with
            | Some userClaimsUpdatedAt ->
                let userClaimsUpdatedAtTime =
                    (userClaimsUpdatedAt :?> Firestore.Timestamp).seconds
                    |> int64
                    |> DateTimeOffset.FromUnixTimeSeconds
                if DateTimeOffset.Compare (currentUser.tokenIssuedAt, userClaimsUpdatedAtTime) < 0 then
                    onRequireRefresh ()
            | None ->
                ()

        let onErrorHandler (error : FirestoreError) =
            error.message
            |> Exception
            |> onError

        jsOptions<DocumentReferenceOnSnapshotObserver> <| fun observer ->
            observer.next <- onNextHandler |> Some
            observer.error <- onErrorHandler |> Some
        |> app.firestore().collection("users").doc(currentUser.id).onSnapshot
        |> ignore

    /// <summary>
    ///     Get the users current idToken if it has not expired. Otherwise,
    ///     refresh the token and return a new one.
    /// </summary>
    ///
    /// <param name="auth">
    ///     Firebase auth SDK instance.
    /// </param>
    ///
    /// <param name="_">
    ///     The currently authenticated user in the Elmish application.  This
    ///     parameter is not used directly, but is required to prevent requesting
    ///     an id token when there is not currently authenticated user.
    /// </param>
    let private getIdToken
        (auth : Firebase.Auth.Auth)
        (forceRefresh : bool)
        (_ : AuthenticatedUser) : JS.Promise<BearerToken> =

        match auth.currentUser with
        | Some user ->
            user.getIdTokenResult(forceRefresh)
            |> Promise.map (fun result ->
                BearerToken result.token)

        | None ->
            failwith "idToken not available: no currently authenticated user."

    let isUIPendingRedirect () =
        let existingUI = FirebaseUI.FirebaseUI.auth.AuthUI.getInstance()
        match existingUI with
        | Some ui -> ui.isPendingRedirect ()
        | None -> false

    /// <summary>
    ///     Signs the user out.
    /// </summary>
    let private signOut (auth : Firebase.Auth.Auth) () =
        () |> auth.signOut |> ignore

    /// <summary>
    ///     The service implementation.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase app.
    /// </param>
    let initialise (app : Firebase.App.App) : AuthService =
        let auth = app.auth()
        { deleteUI = deleteUI
          getBearerToken = getIdToken auth
          isUIPendingRedirect = isUIPendingRedirect
          onStateChanged = onAuthStateChanged auth
          isTokenRefreshRequired = isTokenRefreshRequired app
          signOut = signOut auth
          startUI = startUI auth }