/// Component for rendering the auth ui container
///
/// This component renders the container for the auth ui,
/// calling the onComponentRendered callback once the component
/// is rendered. The callback is expected to initialise the ui within
/// the rendered container.
///
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.AuthContainer

open Fable.Import.React
open Fable.Helpers.React
open Fable.Helpers.React.Props

type AuthContainerProps = {
    containerId : string
    onComponentRendered : string -> unit
}

type AuthContainer(initialProps) =
    inherit Component<AuthContainerProps, obj>(initialProps)
    override this.componentDidMount() =
        this.props.onComponentRendered(this.props.containerId)
    override this.render() =
        div [ Id this.props.containerId ] []

let inline authContainer containerId onComponentRendered =
    ofType<AuthContainer,_,_> { containerId = containerId
                                onComponentRendered = onComponentRendered } []
