namespace StatBanana.Web.Client.Services

open Fable.Core.JsInterop
open Fable.PowerPack
open Fable.Import
open Fable.Import.Axios.Globals

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import

/// Initialise and configure the Firebase SDK for use in our application.
module FirebaseApp =

    /// Import only the parts of the Firebase SDK we actually use

    /// Import Firebase App - always required and must be first
    let private firebase: Firebase.IExports = importDefault "firebase/app"

    // Import Firebase Auth - use via the firebase app import above
    importAll "firebase/auth"

    // Import Firebase Firestore - use via the firebase app import above
    importAll "firebase/firestore"

    // Import Firebase Database - use via the firebase app import above
    importAll "firebase/database"

    /// Manually configure the Firebase SDK.  Use when running locally via webpack-dev-server
    let private initialiseLocalDev (): JS.Promise<Firebase.App.App> =
        let devConfig =
            createObj
                [ "apiKey"
                  ==> "AIzaSyAGrTUf7Jl_3n959keHj6anUtrFY8lsIbs"
                  "authDomain" ==> "alpha.statbanana.com"
                  "databaseURL"
                  ==> "https://statbanana-development.firebaseio.com/"
                  "projectId" ==> "statbanana-development" ] :?> JS.Object

        promise { return firebase.initializeApp devConfig }

    /// Use SDK auto-configuration - Firebase will load the appropriate config based on the deployment environment.
    /// Only works on Firebase hosting and/or when running locally using 'firebase serve'
    ///
    /// Automatic SDK configuration makes it easy to manage multiple environments (such as dev, staging, and production)
    /// from a single codebase. By relying on the reserved URL, we can deploy the same code to multiple Firebase projects.
    ///
    /// For details, refer to: https://firebase.google.com/docs/hosting/reserved-urls?authuser=0#sdk_auto-configuration
    let private intitialiseRemote (env: DeploymentEnvironment): JS.Promise<Firebase.App.App> =
        axios.get ("/__/firebase/init.json")
        |> Promise.map (fun response ->
            let config = response.data

            // Update authDomain to use the appropriate gamer.ai subdomain, so
            // we don't display firebaseapp.com domains on our auth pages.
            let authDomain =
                match env with
                | DeploymentEnvironment.LocalDevelopment
                | DeploymentEnvironment.Development -> "alpha.statbanana.com"
                | DeploymentEnvironment.Production -> "statbanana.com"

            config?authDomain <- authDomain

            firebase.initializeApp (config))

    /// Initialise the Firebase SDK
    let initialiseApp (env: DeploymentEnvironment): JS.Promise<Firebase.App.App> =
        // Note: Webpack autmoatically sets the DEBUG directive when calling fable-compiler for dev-server builds.
#if DEBUG
        initialiseLocalDev ()
#else
        intitialiseRemote env
#endif
