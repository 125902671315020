[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.PricingTable

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Domain
open StatBanana.Web.Client.Components.Molecules

let table
    (isSubscribed : bool)
    (hasTrialled : bool)
    (onSubscribeClick : BillingPeriod -> unit)
    (freeFeatures : string list)
    (premiumFeatures : string list)
    : ReactElement =

    let fullPremiumFeatures =
        premiumFeatures
        |> List.append freeFeatures

    div [ ClassName "pricing-table" ] [
        PricingPlan.free freeFeatures
        PricingPlan.premium
            isSubscribed
            hasTrialled
            onSubscribeClick
            fullPremiumFeatures
    ]