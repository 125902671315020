module StatBanana.Web.Client.Components.Atoms.PayPalButton

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open StatBanana.Domain
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.PayPal.PayPalJS

[<Literal>]
let private buttonContainerId = "ButtonContainer"

let private getPaypalPlanId (env: DeploymentEnvironment) (plan: Plan) =
    match env with
    | Development
    | LocalDevelopment ->
        match plan with
        | { package = Standard; billingPeriod = Monthly } -> "P-9YV409693N0775047LVKOXUA"
        | { package = Standard; billingPeriod = Yearly } -> "P-798216804X216850VLVKOXYI"
    | Production ->
        match plan with
        | { package = Standard; billingPeriod = Monthly } -> "P-2M012460612976547LVKOWVY"
        | { package = Standard; billingPeriod = Yearly } -> "P-1G8184976X0590251LVKOWOQ"

type Props =
    { env: DeploymentEnvironment
      onSubmit: string -> unit
      plan: Plan }

type State = { paypal: PayPal.IExports option }

type PayPalButton(initialProps) as this =
    inherit Component<Props, State>(initialProps)
    do this.setInitState { paypal = None }

    let initialisePayPalButton (paypal: PayPal.IExports) =
        let createSubscription _ (actions: PayPal.Buttons.CreateSubscriptionActions) =

            jsOptions<PayPal.Buttons.CreateSubscriptionOptions>
            <| fun options ->
                options.auto_renewal <- true |> Some
                options.plan_id <- getPaypalPlanId this.props.env this.props.plan
            |> actions.subscription.create

        let onApprove (data: PayPal.Buttons.OnApproveData) (actions: PayPal.Buttons.OnApproveActions) =

            this.props.onSubmit data.subscriptionID

        let options =
            jsOptions<PayPal.Buttons.ButtonsOptions>
            <| fun options ->
                options.createSubscription <- createSubscription
                options.onApprove <- onApprove
                options.style <-
                    jsOptions<PayPal.Buttons.ButtonsStyles>
                    <| fun style ->
                        style.label <- PayPal.Buttons.Pay
                        style.layout <- PayPal.Buttons.Horizontal

        paypal.Buttons(options).render("#" + buttonContainerId)

    override this.componentDidMount() =
        let paypalScript = Browser.document.createElement_script ()

        let clientKey =
            match this.props.env with
            | Development
            | LocalDevelopment -> "AQndnUfbL9pnZ-syG8gKOhF02QykhN4C5NCBS03tdZ5MGYUeqnZkS-jG7yiRwibR9eY_XuPNf8eqoiF2"
            | Production -> "AU84-U-jlslZ02PX5KFpHv31PX6j5QjL6mhs9enb_cjNu-AfA4JQEU978E5csX14m5RKrkmKzh9qEYvb"

        paypalScript.src <-
            "https://www.paypal.com/sdk/js?client-id="
            + clientKey
            + "&vault=true"
        paypalScript.addEventListener_load (fun _ ->
            let paypal: PayPal.IExports = Browser.window?paypal
            this.setState (fun state _ -> { state with paypal = Some paypal })
            initialisePayPalButton paypal)

        paypalScript
        |> Browser.document.body.appendChild
        |> ignore

    override this.render() = div [ Id buttonContainerId ] []

let inline button (props: Props) = ofType<PayPalButton, _, _> props []
