module StatBanana.Web.Client.Domain.ImagesPage

type OverwatchMap =
    | BlizzardWorld
    | Busan
    | Dorado
    | Eichenwalde
    | Hanamura
    | Havana
    | Hollywood
    | HorizonLunarColony
    | Ilios
    | Junkertown
    | KingsRow
    | LijiangTower
    | Nepal
    | Numbani
    | Oasis
    | Paris
    | Rialto
    | Route66
    | TempleOfAnubis
    | Volskaya
    | WatchpointGibraltar
module OverwatchMap =
    let all =
        [ BlizzardWorld
          Busan
          Dorado
          Eichenwalde
          Hanamura
          Havana
          Hollywood
          HorizonLunarColony
          Ilios
          Junkertown
          KingsRow
          LijiangTower
          Nepal
          Numbani
          Oasis
          Paris
          Rialto
          Route66
          TempleOfAnubis
          Volskaya
          WatchpointGibraltar ]

    let getName (map : OverwatchMap) : string =
        match map with
        | BlizzardWorld -> "Blizzard World"
        | Busan -> "Busan"
        | Dorado -> "Dorado"
        | Eichenwalde -> "Eichenwalde"
        | Hanamura -> "Hanamura"
        | Havana -> "Havana"
        | Hollywood -> "Hollyworld"
        | HorizonLunarColony -> "Horizon Lunar Colony"
        | Ilios -> "Ilios"
        | Junkertown -> "Junkertown"
        | KingsRow -> "KingsRow"
        | LijiangTower -> "Lijiang Tower"
        | Nepal -> "Nepal"
        | Numbani -> "Numbani"
        | Oasis -> "Oasis"
        | Paris -> "Paris"
        | Rialto -> "Rialto"
        | Route66 -> "Route 66"
        | TempleOfAnubis -> "Temple of Anubis"
        | Volskaya -> "Volskaya"
        | WatchpointGibraltar -> "Watchpoint: Gibraltar"

    let getPreviewUrl (map : OverwatchMap) : string =
        let assetDir = "assets/images_page/overwatch_previews/"
        let fileName =
            match map with
            | BlizzardWorld -> "blizzworld_icon.jpg"
            | Busan -> "busan_icon.jpg"
            | Dorado -> "dorado_icon.jpg"
            | Eichenwalde -> "eich_icon.jpg"
            | Hanamura -> "hana_icon.jpg"
            | Havana -> "havana_icon.jpg"
            | Hollywood -> "holly_icon.jpg"
            | HorizonLunarColony -> "horizon_icon.jpg"
            | Ilios -> "ilios_icon.jpg"
            | Junkertown -> "junk_icon.jpg"
            | KingsRow -> "kings_icon.jpg"
            | LijiangTower -> "lijiang_icon.jpg"
            | Nepal -> "nepal_icon.jpg"
            | Numbani -> "numbani_icon.jpg"
            | Oasis -> "oasis_icon.jpg"
            | Paris -> "paris_icon.jpg"
            | Rialto -> "rialto_icon.jpg"
            | Route66 -> "route_icon.jpg"
            | TempleOfAnubis -> "anubis_icon.jpg"
            | Volskaya -> "volskaya_icon.jpg"
            | WatchpointGibraltar -> "watchpoint_icon.jpg"
        assetDir + fileName

    let getLowResImageUrl (map : OverwatchMap) : string option =
        match map with
        | BlizzardWorld ->
            "https://drive.google.com/open?id=1IHQyMYsm9_Tz6F4NqiRxe8wfbm-W9eXW"
            |> Some
        | Busan ->
            "https://drive.google.com/open?id=19egQ_j8q83OauiG-Y_Y2kXwn27XZVohT"
            |> Some
        | Dorado ->
            "https://drive.google.com/open?id=1yz09tpSGZ31oWeAFFq5rNbAPR-esZ7S3"
            |> Some
        | Eichenwalde ->
            "https://drive.google.com/open?id=1KTP34BeJ_7QQnH5oRERcPhDW5Y8x-o_f"
            |> Some
        | Hanamura ->
            "https://drive.google.com/open?id=1zk_Dqj5T5s294crdjV2ogJoFix3NFtpB"
            |> Some
        | Havana ->
            "https://drive.google.com/open?id=1U6sX65iKwXBLhjRtrUJW4YJYHVhG6wTq"
            |> Some
        | Hollywood ->
            "https://drive.google.com/open?id=1YDf5te0F546qE_-gDouMCPP3r3w2su9n"
            |> Some
        | HorizonLunarColony ->
            "https://drive.google.com/open?id=1f56wRNA597msHI8RILBhp4dDxr1WbWsw"
            |> Some
        | Ilios ->
            "https://drive.google.com/open?id=1brKnmjy9HK7jyq_Gn16fgm2wbKEM7LjF"
            |> Some
        | Junkertown ->
            "https://drive.google.com/open?id=1u9qLIrJ0AU54punlSZXIGo8Zh1reXB7O"
            |> Some
        | KingsRow ->
            "https://drive.google.com/open?id=1pskvTj33hpqgtTvcqTNi_EIfjCc0dAkq"
            |> Some
        | LijiangTower ->
            "https://drive.google.com/open?id=1J5FcpgmjrFhXvXIjFU6vGe9IK96uuHn8"
            |> Some
        | Nepal ->
            "https://drive.google.com/open?id=1SrRj33MkWldq2gWFf1nZ17OQ57qZ_nzf"
            |> Some
        | Numbani ->
            "https://drive.google.com/open?id=1gMfY5tXi-30QuyTNDn2KGMdTy7turxWa"
            |> Some
        | Oasis ->
            "https://drive.google.com/open?id=1oTu63pYR3FtlrYQA43898ZLcrXdOAzV0"
            |> Some
        | Paris ->
            "https://drive.google.com/open?id=1JEyVTHVO0GOrCLUkyoVDExBNLpBuaiUc"
            |> Some
        | Rialto ->
            None
        | Route66 ->
            "https://drive.google.com/open?id=1PuJfaqCkcv2cbNyQQGlPCVasJx5n_ega"
            |> Some
        | TempleOfAnubis ->
            "https://drive.google.com/open?id=1W2WFSOqnjqp0N5pnsudPDJAs4napNF_K"
            |> Some
        | Volskaya ->
            "https://drive.google.com/open?id=1xvQS6xr5Tw8sl-AzZB5bsntIjk5_DfKl"
            |> Some
        | WatchpointGibraltar ->
            "https://drive.google.com/open?id=1-w09SeRfOSeJTMF2nPFB_h-4r7m9tRWL"
            |> Some

    let getHighResImageUrl (map : OverwatchMap) : string =
        match map with
        | BlizzardWorld -> "https://drive.google.com/open?id=12LfcRpyjUS7NV3KNQcxbluYTtX7GPMk-"
        | Busan -> "https://drive.google.com/open?id=18BIEqEC5KtXscBPpm_XakGXzENJiClnZ"
        | Dorado -> "https://drive.google.com/open?id=1flxWL0plP_-2jy0-UrM9sEeBDGOWToxF"
        | Eichenwalde -> "https://drive.google.com/open?id=1d7FuLNZ-SOnTfsWcYsQQTK0oGuX5xiHf"
        | Hanamura -> "https://drive.google.com/open?id=1zJIoWE6tqyuOXEwAfqQK4O23sCYBlz9s"
        | Havana -> "https://drive.google.com/open?id=1KOoJ8yVhasT0soQXSRMs82zHN7CeG549"
        | Hollywood -> "https://drive.google.com/open?id=1nGxoje9NBkVIfTQanHNVG22sOnbPxhpE"
        | HorizonLunarColony -> "https://drive.google.com/open?id=1kqsn1XgrvwIla5R0ejQ_S53z1jJ-yUd1"
        | Ilios -> "https://drive.google.com/open?id=1BBLG8y0GGxCLEW0FK0nopZ2ISXpPIHmk"
        | Junkertown -> "https://drive.google.com/open?id=1tECichGmNZqEq7qNhZ2Wz21fCajRbUEo"
        | KingsRow -> "https://drive.google.com/open?id=10If1ecmAW3c0CUpDaefyMwwgYWmsOp7d"
        | LijiangTower -> "https://drive.google.com/open?id=1fRXx6WFYdIieW8Ymfqbbj0MjhZwrFaOB"
        | Nepal -> "https://drive.google.com/open?id=1DFXQmC1CAu-s-6SkpRqPY23P-x-Vj4Tg"
        | Numbani -> "https://drive.google.com/open?id=1iJYEVKqr1HwEXzfenplLIcHsedDpkGPV"
        | Oasis -> "https://drive.google.com/open?id=1si5G9Xtt-hfMG_QZpMMXBcnmUa4VBVXz"
        | Paris -> "https://drive.google.com/open?id=1DWQcIHTv_6NgSs-xnm7m3dqRgiZIUE-g"
        | Rialto -> "https://drive.google.com/open?id=1jrEJXl70oDUNqS1UGhlDJ4RfEVCq1dJ9"
        | Route66 -> "https://drive.google.com/open?id=15HPRFGowqsQm_eJ_uEqPz-GW-OWvNuWo"
        | TempleOfAnubis -> "https://drive.google.com/open?id=10LJ9GJRBbQePyCm46hr45jYl3iXcLaTx"
        | Volskaya -> "https://drive.google.com/open?id=1ivL5PpWZUXksd1UPTNdMFeyhGq5Rae_o"
        | WatchpointGibraltar -> "https://drive.google.com/open?id=1jo6V3YjmLjF32wEmtx9V00IuOuqnm1zA"

    let getHighResAnnotatedImageUrl (map : OverwatchMap) : string =
        match map with
        | BlizzardWorld -> "https://drive.google.com/open?id=1cS_lsov7DDUTs-9nSmvswGVF8UHy9cBB"
        | Busan -> "https://drive.google.com/open?id=1yw_kd6vKjX1GLsCSsDoxQBWlw_7wz9OW"
        | Dorado -> "https://drive.google.com/open?id=1Bvm5o0HgTMlSj1owbHnl_zKiLfFzfbt8"
        | Eichenwalde -> "https://drive.google.com/open?id=1A4OmlwHcUDgkxSSu8T3NWYpn2WnaKnMt"
        | Hanamura -> "https://drive.google.com/open?id=1SYJ27oSEFQAJMRIsvYO0Cl5lmqn34OZp"
        | Havana -> "https://drive.google.com/open?id=1LM3Mf2bJal45z4tl7al973XYbCyMjiI4"
        | Hollywood -> "https://drive.google.com/open?id=1HnxJwohJDNIBrzKHl1rg3w_hNhutJN1B"
        | HorizonLunarColony -> "https://drive.google.com/open?id=1E8vvo5ieeCEkbCHIu3EhNiWwVm9qobGw"
        | Ilios -> "https://drive.google.com/open?id=1uVCt2Ca1jN66fxPLFepB1uiZyHDCph-4"
        | Junkertown -> "https://drive.google.com/open?id=1e8YQs_xvw-vd7Hqb31C0_u-j0u8-Po0r"
        | KingsRow -> "https://drive.google.com/open?id=1qPwiAqEIASVYw_IYFvQQVh3V4SY5kFSi"
        | LijiangTower -> "https://drive.google.com/open?id=1CTyxkuRunbTaZdIoZDrsvIvy-fV9Gu2s"
        | Nepal -> "https://drive.google.com/open?id=1IWsiUNOosuzSzykxewR9xALDGL_W8b7d"
        | Numbani -> "https://drive.google.com/open?id=1ijNOop_eg5rEfNI4XVpFfpDTQ8BvzaMf"
        | Oasis -> "https://drive.google.com/open?id=1UhY0KZnOtFohma4waOxlQ71L93hCx-Pm"
        | Paris -> "https://drive.google.com/open?id=1aC_3IXKel5pmrpvCSM8xdmIzNa4wTXD-"
        | Rialto -> "https://drive.google.com/open?id=1VXgJ6CIVT7WM8HCxoajXLWHo_Bs37UJP"
        | Route66 -> "https://drive.google.com/open?id=1QkWzk6St0u9IBl58Z5bGm7-poVIaBFkq"
        | TempleOfAnubis -> "https://drive.google.com/open?id=11TQDaVuBdOchR_jTvEW3tpW254-oGCVN"
        | Volskaya -> "https://drive.google.com/open?id=1qhKYXPb1WoKNpZ1uw4d0oQiRFE_Rdt-4"
        | WatchpointGibraltar -> "https://drive.google.com/open?id=17mTCjFlJnsgevWDWMO0gxav3gEPc-Hng"

type Map =
    | OverwatchMap of OverwatchMap