[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.AnimateOnScroll

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open StatBanana.Web.Client.Extensions

/// Available types of animations
[<RequireQualifiedAccess>]
type Animation =
    | FadeUp

/// A set of props affecting the behaviour of AOS
[<RequireQualifiedAccess>]
type Prop =
    | AnimationType of Animation
    | PersistAfterScroll of bool

type Styles =
    { animate : string
      defaultState : string
      fadeUp : string }
let private styles : Styles = importAll "./AnimateOnScroll.sass"

let aosClassName = "aos"
let private permanentClassName = "permanent"

let private animateElementOnScroll (element : Browser.Element) =
    let threshold = 100.
    let rect = element.getBoundingClientRect ()
    let letsAnimate =
        (rect.top >= 0. && rect.top - threshold <= Browser.window.innerHeight)
     || (rect.bottom <= Browser.window.innerHeight)
    let isPermanent = element.classList.contains permanentClassName
    if letsAnimate then
        element.classList.add styles.animate
    else if not isPermanent then
        element.classList.remove styles.animate

let private scrollListener _ =
    let elements = Browser.document.querySelectorAll ("." + aosClassName)
    elements
    |> Fable.nodeListOfToList
    |> List.iter animateElementOnScroll

/// Initialises AOS behaviour attaching a scroll event listener to the window
let initAOS () : unit =
    Browser.window.addEventListener_scroll scrollListener

/// <summary>
///     A component that wraps a set of React elements, enabling them to animate
///     in and/or out on scroll.
/// </summary>
///
/// <params name="props">
///     A list of props for the component affecting its behaviour
/// </params>
///
/// <params name="children">
///     A list of React elements to enable the Animate On Scroll effect for
/// </params>
let aos
    (props : Prop list)
    (children : ReactElement list) : ReactElement =
    let getClassNamesFromProps props =
        let getClassNameFromProp prop =
            match prop with
            | Prop.AnimationType Animation.FadeUp ->
                styles.fadeUp
            | Prop.PersistAfterScroll true ->
                permanentClassName
            | Prop.PersistAfterScroll false ->
                ""
        List.map getClassNameFromProp props
    let className =
        [ aosClassName
          styles.defaultState ]
        |> List.append (getClassNamesFromProps props)
        |> String.concat " "
    div [ ClassName className ]
        children