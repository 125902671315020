namespace StatBanana.Web.Client.Dto.Firestore.Strategiser

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Session DTOs.
[<RequireQualifiedAccess>]
module SessionDto =
    module Save =
        let fromDomain (save : Strategiser.Save) : obj =
            createObj [
                "drawings" ==>
                    (save.drawings
                     |> List.map DrawingDto.Drawing.fromDomain
                     |> List.toArray)
                "layers" ==>
                    (save.layers
                     |> List.map LayerDto.Layer.fromDomain
                     |> List.toArray)
                "rulers" ==>
                    (save.rulers
                     |> List.map RulerDto.Ruler.fromDomain
                     |> List.toArray)
            ]

        let toDomain (save : obj) : Strategiser.Save =
            let drawings =
                save
                |> JsonAdapter.getArray "drawings"
                |> List.map DrawingDto.Drawing.toDomain
            let layers =
                save
                |> JsonAdapter.getArray "layers"
                |> List.map LayerDto.Layer.toDomain
            let rulers =
                save
                |> JsonAdapter.getArray "rulers"
                |> List.map RulerDto.Ruler.toDomain
            { drawings = drawings
              layers = layers
              rulers = rulers }
