namespace StatBanana.Web.Client.Dto.Database.Strategiser

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Keyframe DTOs.
[<RequireQualifiedAccess>]
module KeyframeDto =
    module KeyframeableAttributes =
        let fromDomain (attributes : Strategiser.KeyframeableAttributes) : obj =
            createObj [
                "capturedBy" ==> attributes.capturedBy
                "isGrey" ==> attributes.isGrey
                "opacity" ==> attributes.opacity
                "position" ==>
                    (attributes.position
                     |> StrategiserDto.Coordinates.fromDomain)
                "scale" ==> attributes.scale
            ]

        let toDomain (attributes : obj) : Strategiser.KeyframeableAttributes =
            let capturedByData =
                (fun _ -> 0) <| fun _ -> JsonAdapter.getInt "capturedBy" attributes
            let capturedBy =
                if capturedByData <> 0 then
                    capturedByData
                    |> ItemDto.FactionType.toDomain
                else
                    Dota2.FactionType.Dire
            { isGrey = attributes |> JsonAdapter.getBool "isGrey"
              opacity = attributes |> JsonAdapter.getFloat "opacity"
              position =
                  attributes
                  |> JsonAdapter.getObj "position"
                  |> StrategiserDto.Coordinates.toDomain
              capturedBy = capturedBy
              scale = attributes |> JsonAdapter.getFloat "scale" }

    module Keyframe =

        let fromDomain
            (time : int, attributes : Strategiser.KeyframeableAttributes)
            : obj =

                createObj [
                    "Attributes" ==>
                        (attributes |> KeyframeableAttributes.fromDomain)
                    "Time" ==> time
                ]

        let toDomain
            (keyframePair : obj)
            : int * Strategiser.KeyframeableAttributes =

            let time = keyframePair |> JsonAdapter.getInt "Time"
            let attributes =
                keyframePair
                |> JsonAdapter.getObj "Attributes"
                |> KeyframeableAttributes.toDomain
            time, attributes

    module Keyframes =
        let fromDomain (keyframes : Strategiser.Keyframes) : obj [] =
            keyframes
            |> Map.toList
            |> List.map Keyframe.fromDomain
            |> List.toArray

        let toDomain (keyframes : 'a list option) : Strategiser.Keyframes =
            keyframes
            |> Option.map (fun keyframes ->
                keyframes
                |> List.map Keyframe.toDomain
                |> Map.ofList)
            |> Option.defaultValue Map.empty