namespace StatBanana.Web.Client.Cmd

open Elmish

open StatBanana.Web.Client.Domain

/// Auth related commands
[<RequireQualifiedAccess>]
module AuthCmd =

    // Signs out the user
    let signOutUser (app : AppConfig) : Cmd<_> =
        [ fun _ -> app.authService.signOut () ]

    // Force refresh user token
    let refreshToken (app : AppConfig) (user : AuthenticatedUser) : Cmd<_> =
        [ fun _ -> app.authService.getBearerToken true user |> ignore ]