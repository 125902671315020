[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.CheckoutPage

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Import

open Elmish
open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Import.Stripe.StripeJS
open StatBanana.Web.Client.Services

type Model =
    { AuthenticatedUser : AuthenticatedUser
      CanCompleteCheckout : bool
      CanContinueCheckout : bool
      Error : exn option
      Order : Order
      OrderSubmitSuccessful : bool
      PaymentMethodNonce : Fetchable<string>
      SubmittingOrder : bool
      UserProfile : Fetchable<UserProfile option> }

type Msg =
    | UserProfileDoesNotExist
    | FetchUserProfileError of exn
    | UserProfileFetched of UserProfile
    | CanContinueCheckout
    | CanCompleteCheckout
    | CantContinueCheckout
    | PaymentMethodNonceFetched of string
    | FetchPaymentMethodNonceFailed of exn
    | SubmitStripeOrder of Stripe.TokenResult option
    | SubmitPayPalOrder of string
    | PayPalOrderSubmitted
    | StripeOrderSubmitted
    | SubmitOrderFailed of exn

type Styles =
    { columnHeader : string
      columnHeaderButton : string }

module Cmd =

    let saveOrder (order : Order) : Cmd<Msg> =
        order
        |> LocalStorageService.saveSubscriptionOrder
        Cmd.none

    let clearSavedOrder () : Cmd<Msg> =
        LocalStorageService.deleteSubscriptionOrder ()
        Cmd.none

    let fetchUserProfile
        (app : AppConfig)
        (authenticatedUser : AuthenticatedUser)
        : Cmd<Msg> =

        let ofSuccess result =
            match result with
            | Some firestoreUser ->
                UserProfileFetched firestoreUser
            | None ->
                UserProfileDoesNotExist
        let ofError (exn : exn) =
            FetchUserProfileError exn
        Cmd.ofPromise
            app.userStoreService.getUser
            authenticatedUser.id
            ofSuccess
            ofError

    let submitPayPalOrder
        (app : AppConfig)
        (user : AuthenticatedUser)
        (subscriptionId : string)
        (order : Order)
        : Cmd<Msg> =

        let ofSuccess _ =
            PayPalOrderSubmitted
        let ofError (exn : exn) =
            SubmitOrderFailed exn

        Cmd.ofPromise
            (app.subscriptionService.create user (Some subscriptionId) None)
            order
            ofSuccess
            ofError

    let submitStripeOrder
        (app : AppConfig)
        (user : AuthenticatedUser)
        (stripeToken : Stripe.TokenResult option)
        (order : Order)
        : Cmd<Msg> =

        let ofSuccess _ =
            StripeOrderSubmitted
        let ofError (exn : exn) =
            SubmitOrderFailed exn

        let token =
            stripeToken
            |> Option.bind (fun result -> Some result.token)
        Cmd.ofPromise
            (app.subscriptionService.create user None token)
            order
            ofSuccess
            ofError

    let redirectToSuccessPage () : Cmd<Msg> =
        Browser.window.location.href <- Route.NewSubscriptionSuccess |> Route.getPath

        Cmd.none

let init
    (app : AppConfig)
    (user : AuthenticatedUser) : Model * Cmd<Msg> =

    let order =
        LocalStorageService.loadSubscriptionOrder ()
        |> Option.defaultValue (Order.getDefault ())

    { AuthenticatedUser = user
      CanCompleteCheckout = false
      CanContinueCheckout = false
      Error = None
      Order = order
      OrderSubmitSuccessful = false
      PaymentMethodNonce = Fetching
      SubmittingOrder = false
      UserProfile = Fetching },
    Cmd.fetchUserProfile app user

/// <summary>
///     Updates the model in response to a message.
/// </summary>
///
/// <param name="app">
///     App config, including injected services.
/// </param>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="currentModel">
///     The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> * Cmd<GlobalMsg> =

    match msg with
    | CanContinueCheckout ->
        let newModel = { currentModel with CanContinueCheckout = true }
        newModel, Cmd.none, Cmd.none
    | CanCompleteCheckout ->
        let newModel =
            { currentModel with
                CanCompleteCheckout = true
                CanContinueCheckout = true }
        newModel, Cmd.none, Cmd.none
    | CantContinueCheckout ->
        let newModel =
            { currentModel with
                CanCompleteCheckout = false
                CanContinueCheckout = false
                PaymentMethodNonce = Fetching }
        newModel, Cmd.none, Cmd.none

    | UserProfileFetched userProfile ->
        let newModel =
            { currentModel with
                UserProfile = userProfile |> Some |> Fetched }
        newModel, Cmd.none, Cmd.none
    | UserProfileDoesNotExist ->
        let newModel =
            { currentModel with
                UserProfile = Fetched None }
        newModel, Cmd.none, Cmd.none
    | FetchUserProfileError exn ->
        let newModel =
            { currentModel with
                UserProfile = Fetched None }
        newModel, Cmd.none, ErrorCmd.unhandledException exn

    | PaymentMethodNonceFetched nonce ->
        let newModel =
            { currentModel with
                CanContinueCheckout = true
                PaymentMethodNonce = Fetched nonce }
        newModel, Cmd.none, Cmd.none
    | FetchPaymentMethodNonceFailed exn ->
        currentModel, Cmd.none, Cmd.none

    | SubmitPayPalOrder subscriptionId ->
        let newModel =
            { currentModel with SubmittingOrder = true }
        newModel,
        Cmd.submitPayPalOrder
            app
            currentModel.AuthenticatedUser
            subscriptionId
            currentModel.Order,
        Cmd.none
    | SubmitStripeOrder token ->
        let newModel =
            { currentModel with SubmittingOrder = true }
        newModel,
        Cmd.submitStripeOrder
            app
            currentModel.AuthenticatedUser
            token
            currentModel.Order,
        Cmd.none
    | PayPalOrderSubmitted ->
        let newModel =
            { currentModel with
                OrderSubmitSuccessful = true }
        let cmds =
            [ Cmd.clearSavedOrder ()
              // Send UserSubscribed event
              AnalyticsCmd.userSubscribed app currentModel.AuthenticatedUser.id
              Cmd.redirectToSuccessPage () ]
        newModel, Cmd.batch cmds, Cmd.none
    | StripeOrderSubmitted ->
        let newModel =
            { currentModel with
                OrderSubmitSuccessful = true }
        let cmds =
            [ Cmd.clearSavedOrder ()
              // Send UserSubscribed event
              AnalyticsCmd.userSubscribed app currentModel.AuthenticatedUser.id
              Cmd.redirectToSuccessPage () ]
        newModel, Cmd.batch cmds, Cmd.none
    | SubmitOrderFailed exn ->
        let newModel =
            { currentModel with
                Error = Some exn
                SubmittingOrder = false }
        newModel, Cmd.none, Cmd.none

let view
    (app : AppConfig)
    (user : AuthenticatedUser)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    let styles : Styles = importAll "./Checkout.sass"

    let onSubmitPaypal subscriptionId =
        SubmitPayPalOrder subscriptionId
        |> dispatch

    let onSubmitStripe token =
        SubmitStripeOrder token
        |> dispatch

    let columnHeaderProps : Content.Option list =
        [ Content.Option.Size Size.IsLarge
          Content.Option.CustomClass styles.columnHeader ]

    let renderPaymentSection env isSubmittingOrder userProfile =
        let existingCardInfo =
            userProfile
            |> Option.bind (fun profile -> profile.stripeCardInfo)

        let renderError (error : exn option) =
            match error with
            | Some error ->
                Message.message [ Message.Color Color.IsDanger ] [
                    Message.body [] [
                        str error.Message
                    ]
                ]
            | None ->
                nothing

        match model.Order.lineItem with
        | Subscribe plan ->
            let providers =
                [ PaymentProvider.PayPal; PaymentProvider.Stripe ]
            fragment [] [
                // Payment details
                Content.content [ Content.Option.Size Size.IsLarge ] [
                    str "Payment"
                ]
                PaymentForm.card
                    env
                    isSubmittingOrder
                    providers
                    ignore
                    plan
                    existingCardInfo
                    onSubmitPaypal
                    onSubmitStripe
                renderError model.Error
            ]
        | Cancel ->
            nothing

    if user |> AuthenticatedUser.hasStrategiserAccess app.deploymentEnvironment Game.Dota2 then
        StandardTemplate.template [
            Section.section [] [
                Heading.h1 [] [ str "You are already subscribed!" ]
            ]
        ] (Some Route.Checkout) (user |> Some) onSignOut
    else
        match model.UserProfile with
        | Fetching ->
            FullscreenCenterContentTemplate.template [
                Loading.loading ()
            ]
        | Fetched userProfile ->
            StandardTemplate.template [
                Section.section [] [
                    Heading.h1 [] [ str "New Subscription" ]

                    Content.content [ Content.Option.Size Size.IsMedium ] [
                        str "Complete your order to get instant access to the world's best strategy tool."
                    ]
                ]

                Section.section [] [
                    Columns.columns [ Columns.Option.IsGap (Screen.All, Columns.ISize.Is8) ] [
                        Column.column [] [
                            Content.content columnHeaderProps [
                                str "Order"
                            ]
                            CheckoutItem.checkoutItem
                                model.Order
                        ]
                        Column.column [] [
                            renderPaymentSection app.deploymentEnvironment model.SubmittingOrder userProfile
                        ]
                    ]
                ]
            ] (Some Route.Checkout) (user |> Some) onSignOut