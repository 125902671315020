module StatBanana.Web.Client.Components.Organisms.Footer

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fable.FontAwesome
open Fulma
open StatBanana.Domain
open StatBanana.Web.Client.Domain

type Styles =
    { link : string
      links : string
      logo : string
      socials : string
      topRow : string }

let footer () =
    let styles : Styles = importAll "./Footer.sass"

    Footer.footer [] [
        Container.container [] [
            div [ ClassName styles.topRow ] [
                div [ ClassName styles.logo ] [
                    a [ Href "/" ] [
                        img [ Src "/logo_statbanana.png" ]
                    ]
                ]
                div [ ClassName styles.socials ] [
                    a [ Href "https://twitter.com/StatBanana" ] [
                        Fa.stack [ Fa.Stack.Size Fa.FaLarge ] [
                            Fa.i [ Fa.Solid.Square
                                   Fa.Stack2x ] []
                            Fa.i [ Fa.Brand.Twitter
                                   Fa.Stack1x
                                   Fa.Inverse ] []
                        ]
                    ]
                    a [ Href "https://discord.gg/4hxPkez" ] [
                        Fa.stack [ Fa.Stack.Size Fa.FaLarge ] [
                            Fa.i [ Fa.Solid.Square
                                   Fa.Stack2x ] []
                            Fa.i [ Fa.Brand.Discord
                                   Fa.Stack1x
                                   Fa.Inverse ] []
                        ]
                    ]
                ]
            ]
            div [ ClassName styles.links ] [
                a [ ClassName styles.link
                    Href (Route.Pricing |> Route.getPath) ] [
                    str "Pricing"
                ]
                str " · "
                a [ ClassName styles.link
                    Href (Route.Images |> Route.getPath) ] [
                    str "Overhead Map Images"
                ]
                str " · "
                a [ ClassName styles.link
                    Href (Game.Dota2 |> Route.MapViewer |> Route.getPath) ] [
                    str "Dota 2 Map Viewer"
                ]
                str " · "
                a [ ClassName styles.link
                    Href (Route.Terms |> Route.getPath) ] [
                    str "Terms"
                ]
                str " · "
                a [ ClassName styles.link
                    Href (Route.Privacy |> Route.getPath) ] [
                    str "Privacy"
                ]
            ]
            Text.p [ Modifiers [ Modifier.TextSize (Screen.All, TextSize.Is7)
                                 Modifier.TextWeight TextWeight.Light ] ] [
                str "Copyright © 2019 Gamurs Pty Ltd."
            ]
        ]
    ]