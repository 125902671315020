module StatBanana.Web.Client.Components.Molecules.NotificationPanel

open Fable.Core.JsInterop
open Fable.Import.React
open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fulma

open StatBanana.Web.Client.Domain.Strategiser

type Styles = { notification : string }

let panel
    (onClickDismiss : Notification -> unit)
    (notifications : Notification list) : ReactElement =
    let styles : Styles = importAll "./NotificationPanel.sass"
    let renderNotification onClickDismiss notification =
        let dismissClickHandler _ =
            onClickDismiss notification
        let notificationColor =
            match notification with
            | DefaultNotification _ ->
                Color.IsPrimary
            | ErrorNotification _ ->
                Color.IsDanger
        let renderTitle () =
            match notification |> Notification.getTitle with
            | Some title ->
                Text.div [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                    str title
                ]
            | None ->
                nothing
        Notification.notification [ Notification.CustomClass styles.notification
                                    Notification.Color notificationColor ] [
            Notification.delete [ Props [ OnClick dismissClickHandler ] ] []
            renderTitle ()
            str (notification |> Notification.getMessage)
        ]
    div [] [
        notifications
        |> List.map (renderNotification onClickDismiss)
        |> ofList
    ]