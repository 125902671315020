namespace StatBanana.Web.Client.Dto.Firestore.Strategiser

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Keyframe DTOs.
[<RequireQualifiedAccess>]
module KeyframeDto =
    module KeyframeableAttributes =
        let fromDomain (attributes : Strategiser.KeyframeableAttributes) : obj =
            createObj [
                "isGrey" ==> attributes.isGrey
                "opacity" ==> attributes.opacity
                "position" ==>
                    (attributes.position
                     |> StrategiserDto.Coordinates.fromDomain)
                "capturedBy" ==>
                    (attributes.capturedBy
                     |> ItemDto.FactionType.fromDomain)
                "scale" ==> attributes.scale
            ]

        let toDomain (attributes : obj) : Strategiser.KeyframeableAttributes =
            let capturedByData =
                (fun _ -> 0) <| fun _ -> JsonAdapter.getInt "capturedBy" attributes
            Fable.Import.Browser.console.log capturedByData
            let capturedBy =
                if capturedByData <> 0 then
                    capturedByData
                    |> ItemDto.FactionType.toDomain
                else
                    Dota2.FactionType.Dire

            { isGrey = attributes |> JsonAdapter.getBool "isGrey"
              opacity = attributes |> JsonAdapter.getFloat "opacity"
              position =
                  attributes
                  |> JsonAdapter.getObj "position"
                  |> StrategiserDto.Coordinates.toDomain
              capturedBy = capturedBy
              scale = attributes |> JsonAdapter.getFloat "scale" }

    module Keyframes =
        let fromDomain (keyframes : Strategiser.Keyframes) : obj [] =
            keyframes
            |> Map.toList
            |> List.map (fun (time, attributes) ->
                createObj [
                    "Attributes" ==>
                        (attributes |> KeyframeableAttributes.fromDomain)
                    "Time" ==> time
                ])
            |> List.toArray

        let toDomain (keyframes : 'a list) : Strategiser.Keyframes =
            keyframes
            |> List.map (fun keyframePair ->
                let time = keyframePair |> JsonAdapter.getInt "Time"
                let attributes =
                    keyframePair
                    |> JsonAdapter.getObj "Attributes"
                    |> KeyframeableAttributes.toDomain
                time, attributes)
            |> Map.ofList