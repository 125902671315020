module StatBanana.Web.Client.Domain.PricingPage

open StatBanana.Domain

type Product =
    | Dota2
    | LeagueOfLegends
    | Overwatch
module Product =
    let all =
        [ Dota2
          LeagueOfLegends
          Overwatch ]

    let getName (product : Product) : string =
        match product with
        | Dota2 ->
            "Dota 2"
        | LeagueOfLegends ->
            "LoL"
        | Overwatch ->
            "Overwatch"

    let isLegacy (product : Product) : bool =
        match product with
        | Dota2
        | LeagueOfLegends ->
            false
        | Overwatch ->
            true

    let isReleased (product : Product) : bool =
        match product with
        | Dota2
        | Overwatch ->
            true
        | LeagueOfLegends ->
            false

    let getFreeFeatures (product : Product) : string list =
        match product with
        | Dota2 ->
            [ "High Resolution Dota 2 Map Viewer"
              "View Shared Strategies"
              "Join Collaborative Sessions" ]
        | LeagueOfLegends ->
            [ "High Resolution League of Legends Map Viewer"
              "View Shared Strategies"
              "Join Collaborative Sessions" ]
        | Overwatch ->
            [ "High Resolution Overwatch Map Viewer"
              "Join Collaborative Sessions" ]

    let getPremiumFeatures (product : Product) : string list =
        match product with
        | Dota2 ->
            [ "Save Strategies"
              "Powerful Animation Tools"
              "Host Collaborative Sessions" ]
        | LeagueOfLegends ->
            [ "Save Strategies"
              "Powerful Animation Tools"
              "Host Collaborative Sessions" ]
        | Overwatch ->
            [ "Save Strategies"
              "Powerful Animation Tools"
              "Host Collaborative Sessions" ]