[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.Leaflet

open Fable.Core
open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React
open System
open System.Text.RegularExpressions

open Leaflet

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Extensions

type LinearRuler =
    Leaflet.Polyline<obj,obj> * Leaflet.Marker<obj>

[<RequireQualifiedAccess>]
type private PolylineErasingMethod =
    | Click
    | MouseDown
    | MouseOver

type RadialRuler =
    Leaflet.Circle<obj> * Leaflet.Polyline<obj,obj> * Leaflet.Marker<obj>

type Props =
    { center : LatLngExpression
      currentSidebarTool : SidebarTool
      createTextMapItem : Map -> LatLngExpression -> float -> unit
      defaultZoom : float
      drawings : Map<Guid, Drawing>
      linearRulers : Map<Guid, RulerPoints>
      map : Loadable<Map>
      onClick : LeafletEvent -> unit
      onCreated : Map -> unit
      onDrawingStrokeCreated : (Guid * Leaflet.Polyline<obj,obj>)-> unit
      onDrawingStrokeRemoved : (Guid)-> unit
      onLinearRulerCreated : (Guid * LinearRuler) -> unit
      onLinearRulerRemoved : (Guid) -> unit
      onRadialRulerCreated : (Guid * RadialRuler) -> unit
      onRadialRulerRemoved : (Guid) -> unit
      onZoomed : LeafletEvent -> unit
      radialRulers : Map<Guid, RulerPoints>
      tileUrl : string }

type State =
    { activeDrawing : (Guid * Polyline<obj,obj>) option
      activeLinearRuler : (Guid * LinearRuler) option
      activeRadialRuler : (Guid * RadialRuler) option
      drawings : Map<Guid, Polyline<obj,obj>>
      isMouseDown : bool
      linearRulers : Map<Guid, LinearRuler>
      radialRulers : Map<Guid, RadialRuler>
      userJustAddedRulerOrDrawing : bool }

type TileLayerOptionsWithEdgeBuffer =
    inherit TileLayerOptions
    abstract edgeBufferTiles : int option with get, set

type Styles =
    { leaflet : string
      polylineDrawing : string
      eraserTool : string
      drawingTool : string
      mapContainer : string
      textingTool : string }

/// A custom react component that wraps around Leaflet and renders our map.
type Leaflet(initialProps) as this =
    inherit Component<Props, State>(initialProps)
    do
        this.setInitState
            { activeDrawing = None
              activeLinearRuler = None
              activeRadialRuler = None
              drawings = Map.empty
              isMouseDown = false
              linearRulers = Map.empty
              radialRulers = Map.empty
              userJustAddedRulerOrDrawing = false }

    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let _ : obj = importAll "leaflet-edgebuffer"

    let styles : Styles = importAll "./Leaflet.sass"

    /// <summary>
    ///     Adds a tile layer to a given map.
    /// </summary>
    ///
    /// <param name="tileUrl">
    ///     The url of the tileserver to populate the tile layer.
    /// </param>
    /// <param name="map">
    ///     The map instance to which the tile layer will be added to.
    /// </param>
    let addTileLayer tileUrl map =
        // add tilelayer to the map
        let tileLayerOptions =
            jsOptions<TileLayerOptionsWithEdgeBuffer> (fun options ->
                options.bounds <- (!^(-1024., 0.), !^(0., 1024.))
                                  |> leafletJS.LatLngBounds.Create
                                  |> U2.Case1
                                  |> Some
                options.minZoom <- Some -1.
                options.minNativeZoom <- Some 0.
                options.edgeBufferTiles <- Some 3)
        let tileLayer = leafletJS.tileLayer (tileUrl, tileLayerOptions)
        map |> U2.Case1 |> tileLayer.addTo |> ignore

    /// <summary>
    ///     Calculates the simple cartestian (since we are using Simple CRS)
    ///     distance between two Points.
    /// </summary>
    ///
    /// <param name="pointA">
    ///     The first point (x, y).
    /// </param>
    /// <param name="pointB">
    ///     The second point.
    /// </param>
    let distanceBetweenPoints
        (pointA : (float * float))
        (pointB : (float * float)) : float =
        sqrt((fst pointA - fst pointB) ** 2. + (snd pointA - snd pointB) ** 2.)

    let disablePanningAndDoubleClickToZoom (map : Map) =
        map.dragging.disable () |> ignore
        map.doubleClickZoom.disable () |> ignore

    let enablePanningAndDoubleClickToZoom (map : Map) =
        map.dragging.enable () |> ignore
        map.doubleClickZoom.enable () |> ignore

    let onErase (event : LeafletEvent) (method : PolylineErasingMethod) =
        let shouldErase : bool =
            match method with
            | PolylineErasingMethod.Click ->
                this.props.currentSidebarTool = Erasing
            | PolylineErasingMethod.MouseDown
            | PolylineErasingMethod.MouseOver ->
                this.state.isMouseDown
                    && this.props.currentSidebarTool = Erasing
        if shouldErase then
            match event.target with
            | Some target ->
                let polyline = target :?> Polyline<obj,obj>
                let polylineOptions : PolylineOptions = polyline.options
                match polylineOptions.className with
                | Some className ->
                    if (className.Contains "linearRuler-") then
                        let matches =
                            (Regex @"linearRuler-[a-z0-9\-]+").Matches
                                className
                        let rulerIdString = matches.Item(0).ToString ()
                        let rulerId =
                            rulerIdString.Replace ("linearRuler-", "")
                            |> Guid.Parse

                        this.state.linearRulers
                        |> Map.tryFind rulerId
                        |> Option.iter (fun (line, marker) ->
                            line.remove () |> ignore
                            marker.remove () |> ignore
                            this.props.onLinearRulerRemoved rulerId)

                        this.state.radialRulers
                        |> Map.tryFind rulerId
                        |> Option.iter (fun (circle, line, marker) ->
                            circle.remove () |> ignore
                            line.remove () |> ignore
                            marker.remove () |> ignore
                            this.props.onRadialRulerRemoved rulerId)

                    else if className.Contains "radialRuler-" then
                        let matches =
                            (Regex @"radialRuler-[a-z0-9\-]+").Matches
                                className
                        let rulerIdString = matches.Item(0).ToString ()
                        let rulerId =
                            rulerIdString.Replace ("radialRuler-", "")
                            |> Guid.Parse
                        let circle, line, marker =
                            this.state.radialRulers
                            |> Map.find rulerId
                        circle.remove () |> ignore
                        line.remove () |> ignore
                        marker.remove () |> ignore
                        this.props.onRadialRulerRemoved rulerId
                    else if className.Contains "drawing-" then
                        polyline.remove () |> ignore
                        let matches =
                            (Regex @"drawing-[a-z0-9\-]+").Matches
                                className
                        let strokeId = matches.Item(0).ToString ()

                        strokeId.Replace ("drawing-", "")
                        |> Guid.Parse
                        |> this.props.onDrawingStrokeRemoved
                | None -> ()
            | None -> ()

    let createDrawing id onErase origin =
        let drawingPolylineOptions =
            jsOptions<PolylineOptions> <| fun options ->
                options.className <-
                    (styles.polylineDrawing +
                     " drawing-" + id.ToString ())
                     |> Some
                options.color <- Some "#ffee22"
                options.opacity <- Some 1.
                options.smoothFactor <- Some 0.
                options.weight <- Some 5.
        let drawingPolylineOrigin = ResizeArray<LatLngExpression> [ origin ]
        let newDrawing =
            leafletJS.polyline (drawingPolylineOrigin, drawingPolylineOptions)
        newDrawing.addEventListener
            ("mouseover", (fun event -> onErase event PolylineErasingMethod.MouseOver))
            |> ignore
        newDrawing.addEventListener
            ("mousedown", (fun event -> onErase event PolylineErasingMethod.MouseDown))
            |> ignore
        newDrawing.addEventListener
            ("click", (fun event -> onErase event PolylineErasingMethod.Click))
            |> ignore
        newDrawing

    let createRulerLabelIcon distance =
        let iconOptions = jsOptions<DivIconOptions> <| fun options ->
            options.className <-
                Some ("rulerLabel-" + id.ToString ())
            options.html <-
                sprintf
                    "<span style='white-space: nowrap; color: white; font-size: 14px;'>%0.2f units</span>"
                    distance
                |> U2.Case1
                |> Some
        leafletJS.divIcon iconOptions

    let createRulerMarker distance (ruler : Polyline<obj,obj>) =
        let rulerLabelMarkerOptions =
            jsOptions<MarkerOptions> (fun options ->
                options.icon <-
                    (createRulerLabelIcon distance) |> U2.Case2 |> Some
                options.interactive <- false |> Some)
        leafletJS.marker
            (!^ruler.getCenter(), rulerLabelMarkerOptions)

    let createRadialRuler id onErase origin distance =
        let radialRulerID = "radialRuler-" + id.ToString ()
        let newCircle =
            Leaflet.createRadialMeasurement
                radialRulerID
                origin
                distance
        newCircle.addEventListener
            ("mouseover", (fun event -> onErase event PolylineErasingMethod.MouseOver))
            |> ignore
        newCircle.addEventListener
            ("mousedown", (fun event -> onErase event PolylineErasingMethod.MouseDown))
            |> ignore
        newCircle.addEventListener
            ("click", (fun event -> onErase event PolylineErasingMethod.Click))
            |> ignore
        newCircle

    let createLinearRuler id onErase origin =
        let rulerPolylineOptions =
            jsOptions<PolylineOptions> <| fun options ->
                options.className <-
                    String.concat " " [ styles.polylineDrawing; id ]
                    |> Some
                options.color <- Some "#ff9d00"
                options.dashArray <- Some "30 10"
                options.opacity <- Some 0.8
                options.smoothFactor <- Some 0.
                options.weight <- Some 4.

        let rulerPolylineOrigin =
            ResizeArray<LatLngExpression> [ U3.Case1 origin ]

        let newRuler =
            leafletJS.polyline
                (rulerPolylineOrigin, rulerPolylineOptions)
        newRuler.addEventListener
            ("mouseover", (fun event -> onErase event PolylineErasingMethod.MouseOver))
            |> ignore
        newRuler.addEventListener
            ("mousedown", (fun event -> onErase event PolylineErasingMethod.MouseDown))
            |> ignore
        newRuler.addEventListener
            ("click", (fun event -> onErase event PolylineErasingMethod.Click))
            |> ignore
        newRuler

    let addDrawingsToMapAndState
        map
        (drawingsToAdd : Map<Guid, Drawing>)
        state =
        let newDrawings =
            drawingsToAdd
            |> Map.fold (fun drawingsToAddToState _ drawing ->
                match drawing.coordinates with
                | origin :: rest ->
                    let id = Guid.NewGuid ()
                    let newDrawing =
                        createDrawing
                            id
                            onErase
                            !^(origin.latitude, origin.longitude)
                    rest
                    |> List.map (fun coords ->
                        (coords.latitude, coords.longitude) |> U3.Case3)
                    |> ResizeArray<LatLngExpression>
                    |> newDrawing.setLatLngs
                    |> ignore
                    newDrawing.addTo !^map |> ignore
                    drawingsToAddToState
                    |> Map.add id newDrawing
                | [] ->
                    drawingsToAddToState) state.drawings
        { state with drawings = newDrawings }

    let removeDrawingsFromMapAndState
        (drawingsToRemove : Map<Guid, Polyline<obj,obj>>)
        state =
        let newDrawings =
            drawingsToRemove
            |> Map.fold (fun drawingsToRemoveFromState id _ ->
                let polyline = state.drawings |> Map.find id
                polyline.remove () |> ignore
                drawingsToRemoveFromState
                |> Map.remove id) state.drawings
        { state with drawings = newDrawings }

    let addLinearRulersToMapAndState
        map
        (rulersToAdd : Map<Guid, RulerPoints>)
        state =
        let newRulers =
            rulersToAdd
            |> Map.fold (fun rulersToAddToState _ points ->
                let id = Guid.NewGuid ()

                let origin =
                    points.originPoint.latitude,
                    points.originPoint.longitude
                let endPoint =
                    points.endPoint.latitude, points.endPoint.longitude
                let distance =
                    distanceBetweenPoints origin endPoint

                let newRuler =
                    createLinearRuler
                        ("linearRuler-" + id.ToString())
                        onErase
                        (origin |> Leaflet.createLatLngFromTuple)
                endPoint
                |> U3.Case3
                |> U2.Case1
                |> newRuler.addLatLng
                |> ignore
                newRuler.addTo !^map |> ignore

                let newMarker = createRulerMarker distance newRuler
                newMarker.addTo !^map |> ignore

                rulersToAddToState
                |> Map.add id (newRuler, newMarker)) state.linearRulers
        { state with linearRulers = newRulers }

    let removeLinearRulersFromMapAndState
        (rulersToRemove : Map<Guid, LinearRuler>)
        state =
        let newRulers =
            rulersToRemove
            |> Map.fold (fun rulersToRemoveFromState id _ ->
                let (polyline, marker) = state.linearRulers |> Map.find id
                polyline.remove () |> ignore
                marker.remove () |> ignore
                rulersToRemoveFromState
                |> Map.remove id) state.linearRulers
        { state with linearRulers = newRulers }

    let addRadialRulersToMapAndState
        map
        (rulersToAdd : Map<Guid, RulerPoints>)
        state =
        let newRulers =
            rulersToAdd
            |> Map.fold (fun rulersToAddToState _ points ->
                let id = Guid.NewGuid ()

                let origin =
                    points.originPoint.latitude,
                    points.originPoint.longitude
                let endPoint =
                    points.endPoint.latitude, points.endPoint.longitude
                let distance =
                    distanceBetweenPoints origin endPoint

                let newCircle =
                    createRadialRuler
                        id
                        onErase
                        (origin |> Leaflet.createLatLngFromTuple)
                        distance
                newCircle.addTo !^map |> ignore

                let newRuler =
                    createLinearRuler
                        ("linearRuler-" + id.ToString())
                        onErase
                        (origin |> Leaflet.createLatLngFromTuple)
                endPoint
                |> U3.Case3
                |> U2.Case1
                |> newRuler.addLatLng
                |> ignore
                newRuler.addTo !^map |> ignore

                let newMarker = createRulerMarker distance newRuler
                newMarker.addTo !^map |> ignore

                rulersToAddToState
                |> Map.add id (newCircle, newRuler, newMarker)) state.radialRulers
        { state with radialRulers = newRulers }

    let removeRadialRulersToMapAndState
        (rulersToRemove : Map<Guid, RadialRuler>)
        state =
        let newRulers =
            rulersToRemove
            |> Map.fold (fun rulersToRemoveFromState id _ ->
                let (circle, polyline, marker) =
                    state.radialRulers |> Map.find id
                circle.remove () |> ignore
                polyline.remove () |> ignore
                marker.remove () |> ignore
                rulersToRemoveFromState
                |> Map.remove id) state.radialRulers
        { state with radialRulers = newRulers }

    let updateDrawingsAndRulers map =
        // update map with drawings and rulers from props
        (fun state _ ->
            let getTheDifferenceBetweenMaps mapB mapA =
                mapA
                |> Map.filter (fun key _ ->
                    mapB
                    |> Map.containsKey key
                    |> not)
            let drawingsToAdd =
                this.props.drawings
                |> getTheDifferenceBetweenMaps this.state.drawings
            let drawingsToRemove =
                this.state.drawings
                |> getTheDifferenceBetweenMaps this.props.drawings
            let linearRulersToAdd =
                this.props.linearRulers
                |> getTheDifferenceBetweenMaps this.state.linearRulers
            let linearRulersToRemove =
                this.state.linearRulers
                |> getTheDifferenceBetweenMaps this.props.linearRulers
            let radialRulersToAdd =
                this.props.radialRulers
                |> getTheDifferenceBetweenMaps this.state.radialRulers
            let radialRulersToRemove =
                this.state.radialRulers
                |> getTheDifferenceBetweenMaps this.props.radialRulers
            state
            |> addDrawingsToMapAndState map drawingsToAdd
            |> removeDrawingsFromMapAndState drawingsToRemove
            |> addLinearRulersToMapAndState map linearRulersToAdd
            |> removeLinearRulersFromMapAndState linearRulersToRemove
            |> addRadialRulersToMapAndState map radialRulersToAdd
            |> removeRadialRulersToMapAndState radialRulersToRemove)
        |> this.setState

    override this.componentDidMount() =
        // create a Leaflet map
        let mapOptions =
            jsOptions<MapOptions> <| fun options ->
                options.attributionControl <- Some false
                options.center <-  Some this.props.center
                options.crs <- Some cRS.Simple
                options.minZoom <- Some -1.
                options.maxZoom <- Some 4.
                options.zoom <- Some this.props.defaultZoom
                options.zoomControl <- Some false

        match this.props.map with
        | Loaded _ -> ()
        | Loading ->
            let map = leafletJS.map ("map" |> U2.Case1, mapOptions)

            // add event handlers to the map
            let onMapClick (event : LeafletEvent) =
                if this.props.currentSidebarTool = Texting then
                    this.props.createTextMapItem
                        map
                        (event?latlng|> U3.Case1)
                        (map.getZoom ())
                this.props.onClick event

            ignore (map.addEventListener ("click", onMapClick))
            ignore (map.addEventListener ("zoomend",  this.props.onZoomed))

            let onMapMouseDown (event : LeafletEvent) =
                (fun state _ -> { state with isMouseDown = true })
                |> this.setState

                // There's an issue with the Leaflet bindings not resolving
                // click events to a LeafletMouseEvent but instead resolve
                // them to a LeafletEvent
                let origin : LatLng = event?latlng

                // DISABLED
                // Helpful snippet for moving buildings and camps. (just uncomment)
                // This snippet outputs the currently clicked position
                // as a Dota2 coordinate to the console.
                // let lat =
                //     event?latlng?lat
                //     |> Leaflet.convertLatLngDistanceToDota2Distance 
                // let lng =
                //     event?latlng?lng
                //     |> Leaflet.convertLatLngDistanceToDota2Distance 
                // Browser.console.log ("Lat: " + lat.ToString() + " Lng:" + lng.ToString())

                match this.props.currentSidebarTool with
                | Drawing ->
                    let id = Guid.NewGuid ()

                    let newDrawing =
                        createDrawing
                            id
                            onErase
                            !^origin
                    newDrawing.addTo !^map |> ignore

                    (fun state _ ->
                        { state with
                            activeDrawing = (id, newDrawing) |> Some })
                    |> this.setState
                | Measuring RadialMeasuring ->
                    let id = Guid.NewGuid ()

                    let newCircle =
                        createRadialRuler
                            id
                            onErase
                            origin
                            0.
                    newCircle.addTo !^map |> ignore

                    let newRuler =
                        createLinearRuler
                            ("radialRuler-" + id.ToString())
                            onErase
                            origin
                    newRuler.addTo !^map |> ignore

                    let newMarker = createRulerMarker 0. newRuler
                    newMarker.addTo !^map |> ignore

                    (fun state _ ->
                        { state with
                            activeRadialRuler =
                                (id, (newCircle, newRuler, newMarker))
                                |> Some })
                    |> this.setState
                | Measuring StraightMeasuring ->
                    let id = Guid.NewGuid ()

                    let newRuler =
                        createLinearRuler
                            ("linearRuler-" + id.ToString())
                            onErase
                            origin
                    newRuler.addTo !^map |> ignore

                    let newMarker = createRulerMarker 0. newRuler
                    newMarker.addTo !^map |> ignore

                    (fun state _ ->
                        { state with
                            activeLinearRuler =
                                (id, (newRuler, newMarker))
                                |> Some })
                    |> this.setState
                | Erasing
                | Panning
                | Redoing
                | Texting
                | Undoing ->
                    ()

            let onMapMouseMove (event : LeafletEvent) =
                let newPosition : LatLng = event?latlng
                match this.state.isMouseDown, this.props.currentSidebarTool with
                | true, Drawing ->
                    match this.state.activeDrawing with
                    | Some (_, drawing) ->
                        drawing.addLatLng (U2.Case1 !^newPosition) |> ignore
                    | None ->
                        ()
                | true, Measuring RadialMeasuring ->
                    match this.state.activeRadialRuler with
                    | Some (_, (circle, ruler, marker)) ->
                        let rulerOrigin = ruler.getLatLngs().[0]
                        let rulerLatLngs = new ResizeArray<LatLngExpression> ()
                        rulerLatLngs.Add(!^rulerOrigin)
                        rulerLatLngs.Add(!^newPosition)
                        ruler.setLatLngs rulerLatLngs |> ignore

                        let newRadius =
                            distanceBetweenPoints
                                (rulerOrigin.lng, rulerOrigin.lat)
                                (newPosition.lng, newPosition.lat)
                        circle.setRadius newRadius |> ignore

                        newRadius
                        |> Leaflet.convertLatLngDistanceToDota2Distance
                        |> createRulerLabelIcon
                        |> U2.Case2
                        |> marker.setIcon
                        |> ignore
                        marker.setLatLng !^newPosition |> ignore
                    | None ->
                        ()
                | true, Measuring StraightMeasuring ->
                    match this.state.activeLinearRuler with
                    | Some (_, (ruler, marker)) ->
                        let rulerOrigin = ruler.getLatLngs().[0]
                        let rulerLatLngs = new ResizeArray<LatLngExpression> ()
                        rulerLatLngs.Add !^rulerOrigin
                        rulerLatLngs.Add !^newPosition
                        ruler.setLatLngs rulerLatLngs |> ignore

                        let newDistance =
                            distanceBetweenPoints
                                (rulerOrigin.lng, rulerOrigin.lat)
                                (newPosition.lng, newPosition.lat)

                        newDistance
                        |> Leaflet.convertLatLngDistanceToDota2Distance
                        |> createRulerLabelIcon
                        |> U2.Case2
                        |> marker.setIcon
                        |> ignore
                        marker.setLatLng !^newPosition |> ignore
                    | None ->
                        ()
                | true, Erasing
                | true, Panning
                | true, Redoing
                | true, Texting
                | true, Undoing
                | false, Drawing
                | false, Erasing
                | false, Measuring StraightMeasuring
                | false, Measuring RadialMeasuring
                | false, Panning
                | false, Redoing
                | false, Texting
                | false, Undoing -> ()

            let onMapMouseUp () =
                (fun state _ -> { state with isMouseDown = false })
                |> this.setState

                match this.props.currentSidebarTool with
                | Drawing ->
                    match this.state.activeDrawing with
                    | Some (id, drawing) ->
                        (id, drawing)
                        |> this.props.onDrawingStrokeCreated

                        (fun state _ ->
                            { state with
                                activeDrawing = None
                                drawings =
                                    this.state.drawings.Add (id, drawing)
                                userJustAddedRulerOrDrawing = true })
                        |> this.setState
                    | None ->
                        ()
                | Measuring RadialMeasuring ->
                    match this.state.activeRadialRuler with
                    | Some (id, ruler) ->
                        (id, ruler)
                        |> this.props.onRadialRulerCreated

                        (fun state _ ->
                            { state with
                                activeRadialRuler = None
                                radialRulers =
                                    this.state.radialRulers.Add (id, ruler)
                                userJustAddedRulerOrDrawing = true })
                        |> this.setState
                    | None ->
                        ()
                | Measuring StraightMeasuring ->
                    match this.state.activeLinearRuler with
                    | Some (id, ruler) ->
                        (id, ruler)
                        |> this.props.onLinearRulerCreated

                        (fun state _ ->
                            { state with
                                activeLinearRuler = None
                                linearRulers =
                                    this.state.linearRulers.Add (id, ruler)
                                userJustAddedRulerOrDrawing = true })
                        |> this.setState
                    | None ->
                        ()
                | Erasing
                | Panning
                | Redoing
                | Texting
                | Undoing ->
                    ()

            let onMapMouseUpdate (event : LeafletEvent) =
                match event.``type`` with
                | "mousedown" -> onMapMouseDown event
                | "mousemove" -> onMapMouseMove event
                | "mouseup" -> onMapMouseUp ()
                | _ -> ()

            map.addEventListener
                ("mouseup mousedown mousemove", onMapMouseUpdate)
            |> ignore

            // populate map with tiles
            map |> addTileLayer this.props.tileUrl |> ignore

            updateDrawingsAndRulers map

            // pass the map back up
            this.props.onCreated map

    override this.componentDidUpdate(prevProps, prevState) =

        // Handle changing tools
        match this.props.map, this.props.currentSidebarTool with
        | Loaded map, Drawing ->
            disablePanningAndDoubleClickToZoom map

            let mapContainer : Browser.HTMLDivElement = map?_container
            mapContainer.classList.remove(styles.eraserTool)
            mapContainer.classList.remove(styles.textingTool)
            mapContainer.classList.add(styles.drawingTool)
        | Loaded map, Erasing ->
            disablePanningAndDoubleClickToZoom map

            let mapContainer : Browser.HTMLDivElement = map?_container
            mapContainer.classList.remove(styles.drawingTool)
            mapContainer.classList.remove(styles.textingTool)
            mapContainer.classList.add(styles.eraserTool)
        | Loaded map, Texting ->

            disablePanningAndDoubleClickToZoom map

            let mapContainer : Browser.HTMLDivElement = map?_container
            mapContainer.classList.remove(styles.drawingTool)
            mapContainer.classList.remove(styles.eraserTool)
            mapContainer.classList.add(styles.textingTool)
        | Loaded map, Measuring RadialMeasuring
        | Loaded map, Measuring StraightMeasuring ->
            disablePanningAndDoubleClickToZoom map

            let mapContainer : Browser.HTMLDivElement = map?_container
            mapContainer.classList.remove(styles.drawingTool)
            mapContainer.classList.remove(styles.eraserTool)
            mapContainer.classList.remove(styles.textingTool)
        | Loaded map, Panning ->
            enablePanningAndDoubleClickToZoom map

            let mapContainer : Browser.HTMLDivElement = map?_container
            mapContainer.classList.remove(styles.drawingTool)
            mapContainer.classList.remove(styles.textingTool)
            mapContainer.classList.remove(styles.eraserTool)
        | Loaded _, Redoing
        | Loaded _, Undoing
        | Loading, _ ->
            ()

        if prevProps.drawings <> this.props.drawings
           || prevProps.linearRulers <> this.props.linearRulers
           || prevProps.radialRulers <> this.props.radialRulers then
            match this.props.map with
            | Loaded map ->
                updateDrawingsAndRulers map
            | Loading ->
                ()

    override this.render () =
        fragment [] [
            div [ ClassName (styles.leaflet + " " + styles.mapContainer)
                  Id "map" ] []
        ]

/// <summary>
///     A custom React component that wraps around Leaflet and renders a map.
/// </summary>
///
/// <param name="props">
///     A record of props to pass to the component.
/// </param>
let inline leaflet (props : Props) = ofType<Leaflet,_,_> props []
