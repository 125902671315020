/// Error page to render if there is an unhandled error
/// in our client application
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.ClientErrorPage

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Elmish
open Fulma

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import

/// Application state passed down to this page
type Model = unit

/// Events/actions that can be dispatched by the this page.
type Msg = unit

/// Initialises the page.
let init () : Model * Cmd<Msg> =
    (), Cmd.none

/// <summary>
/// Update the model in response to a message.
/// </summary>
///
/// <param name="app">
/// App config, including injected services
/// </param>
///
/// <param name="msg">
/// The message to action.
/// </param>
///
/// <param name="model">
/// The model prior to actioning the message.
/// </param>
let update (app : AppConfig) (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match model, msg with
    | _ -> model, Cmd.none


/// Page view method - for elmish
let view
    (env : DeploymentEnvironment)
    (optionalUser : AuthenticatedUser option)
    (onSignOut : unit -> unit) =

    StandardTemplate.template [
        Section.section [] [
            Heading.h2 [] [
                str "Something went wrong!"
            ]
            Button.mainCtaButton
                Size.IsLarge
                "/"
                "Go Back Home"
        ]
    ] None optionalUser onSignOut
