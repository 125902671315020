/// Payment Form
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.PaymentForm

open Fable.Helpers.React
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.Stripe.StripeJS

let form
    (env : DeploymentEnvironment)
    (providers : PaymentProvider list)
    (onReady : unit -> unit)
    (plan : Plan)
    (existingCardInfo : CardInfo option)
    (onSubmitPaypal : string -> unit)
    (onSubmitStripe : Stripe.TokenResult option -> unit)
    : ReactElement =

    div [] [
        (if providers |> List.contains PaymentProvider.Stripe then
            Field.div [] [
                StripeForm.form
                    { env = env
                      existingCardInfo = existingCardInfo
                      onReady = onReady
                      onSubmit = onSubmitStripe }
            ]
         else nothing)
        (if providers |> List.contains PaymentProvider.PayPal then
            Field.div [] [
                PayPalButton.button
                    { env = env
                      onSubmit = onSubmitPaypal
                      plan = plan }
            ]
         else nothing)
    ]

// Create a Card for the payment form
let card
    (env : DeploymentEnvironment)
    (isLoading : bool)
    (providers : PaymentProvider list)
    (onReady : unit -> unit)
    (plan : Plan)
    (existingCardInfo : CardInfo option)
    (onSubmitPaypal : string -> unit)
    (onSubmitStripe : Stripe.TokenResult option -> unit)
    : ReactElement =

    Card.card [] [
        match isLoading with
        | true ->
            yield Card.content [ Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                Loading.loading ()
            ]
        | false ->
            yield Card.content [] [
                form env providers onReady plan existingCardInfo onSubmitPaypal onSubmitStripe
            ]
    ]