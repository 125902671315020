namespace StatBanana.Web.Client.Dto.Firestore.Strategiser

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Ruler DTOs.
[<RequireQualifiedAccess>]
module RulerDto =
    module RulerPoints =
        let fromDomain (points : Strategiser.RulerPoints) : obj =
            createObj [
                "OriginPoint" ==>
                    (points.originPoint
                     |> StrategiserDto.Coordinates.fromDomain)
                "EndPoint" ==>
                    (points.endPoint |> StrategiserDto.Coordinates.fromDomain)
            ]

        let toDomain (points : obj) : Strategiser.RulerPoints =
            let originPoint =
                points
                |> JsonAdapter.getObj "OriginPoint"
                |> StrategiserDto.Coordinates.toDomain
            let endPoint =
                points
                |> JsonAdapter.getObj "EndPoint"
                |> StrategiserDto.Coordinates.toDomain
            { originPoint = originPoint
              endPoint = endPoint }

    module Ruler =
        let fromDomain (ruler : Strategiser.Ruler) : obj =
            match ruler with
            | Strategiser.LinearRuler points ->
                createObj [
                  "Tag" ==> "LinearRuler"
                  "LinearRulerData" ==> (points |> RulerPoints.fromDomain)
                  "RadialRulerData" ==> null
                ]
            | Strategiser.RadialRuler points ->
                createObj [
                  "Tag" ==> "RadialRuler"
                  "LinearRulerData" ==> null
                  "RadialRulerData" ==> (points |> RulerPoints.fromDomain)
                ]

        let toDomain (ruler : obj) : Strategiser.Ruler =
            let tag = ruler |> JsonAdapter.getString "Tag"
            match tag with
            | "LinearRuler" ->
                ruler
                |> JsonAdapter.getObj "LinearRulerData"
                |> RulerPoints.toDomain
                |> Strategiser.LinearRuler
            | "RadialRuler" ->
                ruler
                |> JsonAdapter.getObj "RadialRulerData"
                |> RulerPoints.toDomain
                |> Strategiser.RadialRuler
            | unexpectedTag ->
                sprintf "Unexpected Ruler DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise