module StatBanana.Web.Client.Pages.LoadingPage

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Templates

type Styles = { container : string }

/// Application state passed down to this page
type Model = unit

/// Events/actions that can be dispatched by this page.
type Msg = unit

/// Initialises the page.
let init () : Model =
    ()

/// <summary>
///     Render a full-screen loading page.
/// </summary>
///
/// <remarks>
///     This page has no Msg, Model, init or update logic.  If you need those, you should probably
///     be rendering the Loading animation component within one of the other pages.
/// </remarks>
let view () =
    FullscreenCenterContentTemplate.template [
        Loading.loading ()
    ]