namespace StatBanana.Web.Client.Domain

open StatBanana.Domain
open System

/// The authenticated user.
type AuthenticatedUser =
    { claims: UserClaim list
      displayName: string option
      email: string
      id: string
      photo: string option
      token: string
      tokenIssuedAt: DateTimeOffset }

module AuthenticatedUser =
    let hasStrategiserAccess (env: DeploymentEnvironment) (game: Game) (user: AuthenticatedUser): bool =

        let isDeveloper = user.claims |> UserClaim.isDeveloper

        let isInfluencer = user.claims |> UserClaim.isInfluencer

        let isSubscriber =
            user.claims |> UserClaim.isSubscribed game

        match env with
        | DeploymentEnvironment.Development ->
            // Only developers and influencers can access the strategiser on alpha
            (isDeveloper && isSubscriber) || isInfluencer
        | DeploymentEnvironment.LocalDevelopment
        | DeploymentEnvironment.Production ->
            // Developers and influencers can bypass subscription requirement on prod
            isDeveloper || isInfluencer || isSubscriber
