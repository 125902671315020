namespace StatBanana.Web.Client.Cmd

open Elmish

open StatBanana.Web.Client.Domain

/// Analytics related commands
[<RequireQualifiedAccess>]
module AnalyticsCmd =
    /// Send an analytics event to be handled by the analytics service.
    let private sendEvent
        (app : AppConfig)
        (event : AnalyticsEvent)
        : Cmd<_> =

        [ fun _ -> app.analyticsService event ]

    /// Command to set the Analytics userId, which will be associated with all analytics
    /// events in the current session
    let userIdAvailable (app : AppConfig) (userId : string) : Cmd<_> =
        AnalyticsEvent.UserIdAvailable userId
        |> sendEvent app

    /// Send an event indicating that the user signed up for a StatBanana account.
    let userSignedUp (app : AppConfig) (userId : string) : Cmd<_> =
        AnalyticsEvent.UserSignedUp userId
        |> sendEvent app

    /// Send an event indicating that the user has subscribed to
    /// a premium StatBanana account.
    let userSubscribed (app : AppConfig) (userId : string) : Cmd<_> =
        AnalyticsEvent.UserSubscribed userId
        |> sendEvent app

    /// Send an invalid route (aka 404) to Analytics
    let invalidRoute (app : AppConfig) (route : string) : Cmd<_> =
        AnalyticsEvent.InvalidRoute route
        |> sendEvent app

    /// Command to send a pageview event to Analytics for the specified route
    let pageview (app : AppConfig) (route : Route) : Cmd<_> =
        AnalyticsEvent.PageView route
        |> sendEvent app

    let strategiserAction (app : AppConfig) (action : Strategiser.UserAction) : Cmd<_> =
        AnalyticsEvent.StrategiserAction action
        |> sendEvent app

    /// Send an unhandled error to Analytics
    let sendError (app : AppConfig) (description : string) : Cmd<_> =
        AnalyticsEvent.UnhandledError description
        |> sendEvent app

    /// Send an unhandled exception to Analytics
    let sendException (app : AppConfig) (ex : exn) : Cmd<_> =
        AnalyticsEvent.UnhandledException ex
        |> sendEvent app