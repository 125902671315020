module StatBanana.Web.Client.Main

open Fable.Import

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Services
open StatBanana.Web.Client.Services.Analytics
open StatBanana.Web.Client.Services.Firebase

let private currentDeploymentEnvironment (): DeploymentEnvironment =
#if DEBUG
    JS.console.log ("You are running a LocalDevelopment build of the web-app")
    DeploymentEnvironment.LocalDevelopment
#else
#if DEVELOPMENT
    DeploymentEnvironment.Development
#else
#if PRODUCTION
    DeploymentEnvironment.Production
#else
    failwith "Unable to determine current deployment environment."
#endif
#endif
#endif

let private analyticsService (env: DeploymentEnvironment): AnalyticsService =
    // Initialise Amplitude
    let amplitudeProvider = Amplitude.provider env

    // Initialise Google Analytics
    let googleAnalyticsProvider = GoogleAnalytics.provider env

    [ amplitudeProvider
      googleAnalyticsProvider ]
    |> AnalyticsService.service

let private startApp firebaseApp: unit =

    let env = currentDeploymentEnvironment ()

    // Inject dependencies
    let authService =
        FirebaseAuthService.initialise firebaseApp

    let appConfig: AppConfig =
        { analyticsService = analyticsService env
          articleService = MarkdownArticleService.service
          authService = authService
          collabSessionService = FirebaseCollaborativeSessionService.initialise firebaseApp
          deploymentEnvironment = env
          sessionStoreService = FirebaseSessionService.initialise firebaseApp
          subscriptionService = FirebaseSubscriptionService.initialise authService.getBearerToken
          userStoreService = FirebaseUserService.initialise firebaseApp }

    // Start the app
    App.start appConfig

/// Initialise the Firebase SDK, then start the app
currentDeploymentEnvironment ()
|> FirebaseApp.initialiseApp
|> Promise.iter startApp
