/// A full timeline used to control map elements
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.Sidebar

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import

type Styles =
    { content : string
      opened : string
      openLeft : string
      openRight : string
      sidebar : string
      tabs : string }

type SidebarTabAndContentData =
    { content : ReactElement option
      disabled : bool
      icon : ReactElement
      id : SidebarTab
      position : SidebarTabPosition
      title : string option }

/// <summary>
///     Renders a sidebar UI component with tabs
/// </summary>
///
/// <param name="openedTab">
///     The current opened tab, optional
/// </param>
/// <param name="selectedTool">
///     The current selected tool
/// </param>
/// <param name="openDirection">
///     The direction the sidebar should open
/// </param>
/// <param name="hasContentPane">
///     Whether the sidebar should have a content pane
/// </param>
/// <param name="onOpen">
///     The function to call when the sidebar is opened
/// </param>
/// <param name="onClose">
///     The function to call when the sidebar is closed
/// </param>
/// <param name="onSelectTool">
///     The function to call when a sidebar tool is selected
/// </param>
/// <param name="tabs">
///     The list of tabs, used to render the sidebar
/// </param>
let sidebar
    (openedTab : SidebarTab option)
    (selectedTool : SidebarTool)
    (openDirection : SidebarOpenDirection)
    (hasContentPane : bool)
    (onOpen : SidebarTab -> unit)
    (onClose : unit -> unit)
    (onSelectTool : SidebarTool -> unit)
    (tabs : SidebarTabAndContentData list)
    : ReactElement =

    let styles: Styles = importAll "./Sidebar.sass"
    let onTabClick tab =
       match openedTab with
       | Some t when t <> tab -> onOpen tab
       | Some _ -> onClose ()
       | None -> onOpen tab
    let isTabOpened (tab : SidebarTabAndContentData) =
        match openedTab with
        | Some t -> t = tab.id
        | None -> false
    let isToolSelected (tool : SidebarTool) =
        match tool, selectedTool with
        | Measuring _, Measuring _ -> true
        | tool, selectedTool -> tool = selectedTool
    let renderTab (tab : SidebarTabAndContentData) =
        let tabData : SidebarTab.SidebarTabData =
            { disabled = tab.disabled
              icon = tab.icon
              id = tab.id }
        match tab.content, tab.disabled with
        | None, false ->
            // Clicked a "tool" rather than a tab with content
            let tool : SidebarTool =
                match tab.id with
                | Draw -> Drawing
                | Erase -> Erasing
                | Measure -> Measuring StraightMeasuring
                | Redo -> Redoing
                | Text -> Texting
                | Undo -> Undoing
                | GameSpecificMarkers
                | Heroes
                | Info
                | Pan
                | Sessions
                | Symbols
                | User ->
                    Panning
            let toolVariants =
                match tool with
                | Measuring _ ->
                    [ Measuring StraightMeasuring ; Measuring RadialMeasuring ]
                | Drawing | Erasing | Panning | Redoing | Texting | Undoing ->
                    []
            if toolVariants.Length > 0 then
                SidebarTab.toolTabWithVariants
                    openDirection
                    toolVariants
                    selectedTool
                    (isToolSelected tool)
                    onSelectTool
                    tabData
            else
                SidebarTab.toolTab
                    tool
                    (isToolSelected tool)
                    onSelectTool
                    tabData
                    openDirection
        | None, true
        | Some _, false
        | Some _, true ->
            SidebarTab.tab
                (isTabOpened tab)
                onTabClick
                tabData
                openDirection
    let renderTabContent tab =
        SidebarContent.content
            (isTabOpened tab)
            (tab.id.ToString ())
            tab.title
            tab.content

    let className =
        ClassNames.classNames [
            (styles.sidebar, true)
            (styles.opened, Option.isSome openedTab)
            (styles.openLeft, openDirection = SidebarOpenDirection.Left)
            (styles.openRight, openDirection = SidebarOpenDirection.Right)
        ]
    div [ ClassName className ] [
        div [ ClassName styles.tabs ] [
            ul [ Role "tablist" ]
                (tabs
                 |> List.filter (fun tab ->
                     tab.position = SidebarTabPosition.Top)
                 |> List.map renderTab)
            ul [ Role "tablist" ]
                (tabs
                 |> List.filter (fun tab ->
                     tab.position = SidebarTabPosition.Bottom)
                 |> List.map renderTab)
        ]
        (if hasContentPane then
            div [ ClassName styles.content ] [
                ofList (List.map renderTabContent tabs)
            ]
         else nothing)
    ]
