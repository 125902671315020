[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.AuthPage

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Elmish

open System
open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.Firebase
open StatBanana.Web.Client.Import.Firebase.Auth
open StatBanana.Web.Client.Services

type AuthAction =
    | SignUp
    | LogIn

module AuthAction =
    let toDisplayName (authAction : AuthAction) : string =
        match authAction with
        | SignUp -> "Start Your 14-Day Free Trial"
        | LogIn -> "Welcome Back"

    let toWelcomeText (authAction : AuthAction) : string =
        match authAction with
        | SignUp ->
            "Powerful animation tools, live collaboration features. \
             Create strategies today without a credit card."
        | LogIn ->
            "Log in to save sessions and collaborate on strategies."

    let toFormLink
        (destination : Route)
        (authAction : AuthAction)
        : ReactElement =

        match authAction with
        | SignUp ->
            let destinationViaLogin =
                destination |> Some |> Route.LogIn
            p [] [
                str "Already have a StatBanana account? "
                a [ Href (destinationViaLogin |> Route.getPath) ] [
                    str "Log in now"
                ]
            ]
        | LogIn ->
            let destinationViaSignup =
                destination |> Some |> Route.SignUp
            p [] [
                str "Don’t have a StatBanana account? "
                a [ Href (destinationViaSignup |> Route.getPath) ] [
                    str "Sign up now"
                ]
            ]

/// Application state passed down to the auth page.
type Model =
    { Action : AuthAction
      Destination : Route
      Loading : bool }

/// Events/actions that can be dispatched by the auth page.
type Msg =
    | AuthComponentRendered of string
    | CheckLoading
    | Error
    | NewUserSignedUp of string

/// Commands that can be executed in response to messages on the auth page
module private Cmd =

    // Start the auth ui
    let startUI
        (app : AppConfig)
        (containerId : string)
        (action : AuthAction)
        : Cmd<Msg> =

        let onUIShownHandler () =
            let googleButtonLabel =
                Fable.Import.Browser.document.querySelector
                    (".firebaseui-idp-google .firebaseui-idp-text-long")
            let emailButtonLabel =
                Fable.Import.Browser.document.querySelector
                    (".firebaseui-idp-password .firebaseui-idp-text-long")

            match action with
            | LogIn ->
                googleButtonLabel.textContent <- "Log In with Google"
                emailButtonLabel.textContent <- "Log In with Email"
            | SignUp ->
                googleButtonLabel.textContent <- "Sign Up with Google"
                emailButtonLabel.textContent <- "Sign Up with Email"

        let sub dispatch =
            /// Callback for when a user has been authenticated
            let handleUserSignedIn (authResult : obj) : bool =
                let userCredentials = authResult :?> Auth.UserCredential

                // Check if a user is a new user
                match userCredentials.additionalUserInfo, userCredentials.user with
                | Some userInfo, Some user ->
                    // Send a NewUserSignedUp msg
                    if userInfo.isNewUser then
                        NewUserSignedUp user.uid |> dispatch

                | None, Some _
                | Some _, None
                | None, None ->
                    ()

                app.authService.deleteUI ()
                false

            let authUIConfig : AuthUIConfig = {
                uiContainerId = sprintf "#%s" containerId
                onSignInFailure = ignore
                onSuccessfulSignIn = handleUserSignedIn
                onUIShown = onUIShownHandler
                privacyUrl = "/privacy"
                termsOfServiceUrl = "/terms"
            }
            app.authService.startUI authUIConfig
        Cmd.ofSub sub

    let storeSignUpDateTime () : Cmd<_> =
        DateTimeOffset.Now
        |> LocalStorageService.saveSignedUpDate

        Cmd.none

let private delay duration =
    promise
        { do! Promise.sleep duration
          return 1. }

/// <summary>
/// Initialises the page.
/// </summary>
let init (authAction : AuthAction) (destination : Route) : Model * Cmd<Msg> =
    { Action = authAction
      Destination = destination
      Loading = false },
    Cmd.ofMsg CheckLoading

/// <summary>
/// Update the model in response to a message.
/// </summary>
///
/// <param name="app">
/// App config, including injected services
/// </param>
///
/// <param name="msg">
/// The message to action.
/// </param>
///
/// <param name="model">
/// The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (model : Model)
    : Model * Cmd<Msg> =
    match msg with
    | AuthComponentRendered containerId ->
        model, Cmd.startUI app containerId model.Action
    | CheckLoading ->
        let loading =
            Fable.Import.Browser.document.querySelector
                ".firebaseui-id-page-callback" <> null
        { model with Loading = loading },
        Cmd.ofPromise delay 300 (fun _ -> CheckLoading) (fun _ -> Error)
    | Error ->
        model, Cmd.none
    | NewUserSignedUp (userId : string) ->
        // Send an analytics event for a new user signing up
        // As well as save the signed up date for Strategiser plays
        model, Cmd.batch
                [ AnalyticsCmd.userSignedUp app userId
                  Cmd.storeSignUpDateTime () ]

//
// VIEW
//

/// <summary>
/// Renders the auth page.
/// </summary>
///
/// <param name="onAuthComponentRendered">
/// Callback for when the auth component has been rendered
/// </param>
let private renderAuth
    (isUIPendingRedirect : bool)
    (onAuthComponentRendered : string -> unit)
    (authAction : AuthAction)
    (authDestination : Route)
    (onSignOut : unit -> unit) =

    let displayName = authAction |> AuthAction.toDisplayName
    let welcomeText = authAction |> AuthAction.toWelcomeText
    let formLink = authAction |> AuthAction.toFormLink authDestination

    StandardTemplate.template [
        Authentication.authentication
            isUIPendingRedirect
            onAuthComponentRendered
            displayName
            welcomeText
            formLink
    ] None None onSignOut

/// <summary>
/// Defines the view to render based on the current state.
/// </summary>
///
/// <param name="model">
/// The page model
/// </param>
///
/// <param name="dispatch">
/// Function to dispatch messages to change the state of the application
/// </param>
let view
    (app : AppConfig)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    let onAuthComponentRendered =
        fun (containerId : string) ->
            containerId
            |> AuthComponentRendered
            |> dispatch

    renderAuth
        model.Loading
        onAuthComponentRendered
        model.Action
        model.Destination
        onSignOut