/// A draggable Item for use in an item pane
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.DraggableItem

open Fable.Import.React

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import.ReactDraggable

/// <summary>
///     Renders a wrapped Item that gives it the ability to be
///     dragged and dropped
/// </summary>
///
/// <param name="onStop">
///     The function to call when dragging is stopped
/// </param>
/// <param name="item">
///     The Item to make draggable
/// </param>
let draggableItem
    (onStop : MouseEvent -> Item -> unit)
    (item : Item) : ReactElement =
    let onStopHandler (event : MouseEvent) _ =
        onStop event item

    // For what EnableUserSelectHack does refer to:
    // https://github.com/mzabriskie/react-draggable/issues/315
    draggable [ DraggableProps.EnableUserSelectHack false
                DraggableProps.OnStop onStopHandler
                DraggableProps.Position { x = 0; y = 0 } ] [
        Marker.marker item
    ]
