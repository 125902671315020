namespace StatBanana.Dto.Firestore.Subscriptions

open Fable.Core.JsInterop

open StatBanana.Domain
open StatBanana.Utils

/// Field definitions for Firestore LineItem DTOs.
[<RequireQualifiedAccess>]
module LineItemDto =
    module LineItem =
        let fromDomain (lineItem : LineItem) : obj =
            match lineItem with
            | Subscribe plan ->
                createObj [
                    "Tag" ==> "Subscribe"
                    "SubscribeData" ==> (plan |> PlanDto.Plan.fromDomain)
                ]
            | Cancel ->
                createObj [
                    "Tag" ==> "Cancel"
                    "SubscribeData" ==> null
                ]

        let toDomain (lineItem : obj) : LineItem =
            let tag = lineItem |> JsonAdapter.getString "Tag"
            match tag with
            | "Subscribe" ->
                lineItem
                |> JsonAdapter.getObj "SubscribeData"
                |> PlanDto.Plan.toDomain
                |> Subscribe
            | "Cancel" -> Cancel
            | unexpectedTag ->
                sprintf "Unexpected LineItem DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise