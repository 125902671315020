[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.ImagesPage

open System

open Fable.Core.JsInterop
open Fable.Helpers
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import

open Elmish
open Fulma

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain.ImagesPage

type Model =
    { OverwatchFilterString : string }

type Msg =
    | UpdateOverwatchFilterString of filterString : string

let init () : Model * Cmd<Msg> =
    { OverwatchFilterString = "" }, Cmd.none

/// <summary>
///     Updates the model in response to a message.
/// </summary>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="currentModel">
///     The model prior to actioning the message.
/// </param>
let update
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> =

    match msg with
    | UpdateOverwatchFilterString filterString ->
        { OverwatchFilterString = filterString }, Cmd.none

type private Styles =
    { image : string
      images : string }

let private renderMapImageCard (map : Map) =
    let styles : Styles = importAll "./Images.sass"

    let title =
        match map with
        | OverwatchMap map ->
            map |> OverwatchMap.getName

    let preview =
        match map with
        | OverwatchMap map ->
            map |> OverwatchMap.getPreviewUrl

    let lowRes =
        match map with
        | OverwatchMap map ->
            map |> OverwatchMap.getLowResImageUrl

    let highRes =
        match map with
        | OverwatchMap map ->
            map |> OverwatchMap.getHighResImageUrl

    let annotatedHighRes =
        match map with
        | OverwatchMap map ->
            map |> OverwatchMap.getHighResAnnotatedImageUrl


    Card.card [ CustomClass styles.image ] [
        Card.image [] [
            Image.image [ Image.IsSquare ] [
                img [ Src preview ]
            ]
        ]
        Card.content [] [
            Text.p [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                str title
            ]
            lowRes
            |> Option.map (fun image ->
                a [ Href image ] [
                    str "Low Resolution"
                ])
            |> Option.defaultValue nothing
            div [] [
                a [ Href highRes ] [
                    str "High Resolution"
                ]
            ]
            div [] [
                a [ Href annotatedHighRes ] [
                    str "High Resolution (Annotated)"
                ]
            ]
        ]
    ]

let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    let styles : Styles = importAll "./Images.sass"

    let overwatchFilterHandler (event : React.FormEvent) =
        event.Value
        |> UpdateOverwatchFilterString
        |> dispatch

    let filterOverwatchMaps (map : OverwatchMap) =
        if model.OverwatchFilterString |> String.IsNullOrWhiteSpace |> not then
            let mapName =
                map |> OverwatchMap.getName |> String.map Char.ToLower
            mapName.Contains(model.OverwatchFilterString.ToLower())
        else
            true

    StandardTemplate.template [
        Section.section [] [
            Heading.h1 [] [ str "Map Images" ]
            Text.p [] [
                "They're posted here for coaches, writers, analysts, and other content creators to use.
                    We only ask that you not use them in any non-free works, that you not redistribute them unaltered, and
                    that either the logo in the corner is kept, or that you include something to the effect of
                    Overhead map courtesy of https://statbanana.com/ below the image or wherever it is most suitable.
                    If you have any questions we can be contacted on Discord.
                    There is a small amount of distortion just due to the nature of creating an orthographic image
                    from a bunch of perspective shots, but if you see any egregious errors then feel free to report them in Discord."
                |> str
            ]
        ]

        Section.section [] [
            Heading.h2 [] [ str "Overwatch" ]
            Box.box' [] [
                "These overhead maps were created by Coggle from around 500 images per map (9,718 total)
                    taken while looking straight down, this obviously took a significant amount of time
                    (over 5 months of full time work)."
                |> str
            ]

            Field.div [] [
                Input.text [ Input.Placeholder "Rialto"
                             Input.OnChange overwatchFilterHandler ]
            ]

            div [ ClassName styles.images ] [
                OverwatchMap.all
                |> List.filter filterOverwatchMaps
                |> List.map OverwatchMap
                |> List.map renderMapImageCard
                |> ofList
            ]
        ]
    ] (Some Route.Images) optionalUser onSignOut