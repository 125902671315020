/// A price
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.Price

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Domain

let private formatAmount amount =
    sprintf
        "%.2f"
        (float amount)

let private generateCurrencyString price =
    match price |> Price.getCurrency with
    | Currency.USD ->
        "$"
    |> str

let private generateAmountString price =
    match price with
    | Price.List price ->
        price.amount
    | Price.Discounted price ->
        price.discountedAmount
    |> formatAmount
    |> str

let amount
    (price : Price)
    : ReactElement =

    span [] [
        price |> generateCurrencyString
        price |> generateAmountString
    ]

module PricingPage =
    let amount
        (price : Price)
        : ReactElement =

        span [ ClassName "plan-price-amount" ] [
            span [ ClassName "plan-price-currency" ] [
                price |> generateCurrencyString
            ]
            price |> generateAmountString
        ]

    let amountWithPeriod
        (billingPeriod : BillingPeriod)
        (price : Price)
        : ReactElement =

        let periodString =
            match billingPeriod with
            | BillingPeriod.Monthly -> "Per month"
            | BillingPeriod.Yearly -> "Per year"
            |> str

        fragment [] [
            amount price
            div [] [
                periodString
            ]
        ]