[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.ArticlePage

open Elmish

open Fulma
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import

/// Application state passed down to this page
type Model =
    { Content : Fetchable<string>
      Slug : string }

/// Events/actions that can be dispatched by this page.
type Msg =
    | ArticleFetched of content : string
    | ArticleFetchFailed

module Cmd =

    let fetchArticle (app : AppConfig) (slug : string) : Cmd<_> =

        let ofSuccess (content : string) =
            ArticleFetched content

        let ofError _ =
            ArticleFetchFailed

        Cmd.ofPromise
            app.articleService
            slug
            ofSuccess
            ofError

/// Run on initialisation.
let init (app : AppConfig) (slug : string) : Model * Cmd<Msg> =
     { Content = Fetching
       Slug = slug }, Cmd.fetchArticle app slug

/// <summary>
///     Updates the model in response to a message
/// </summary>
/// <param name="msg">
///     The message to action
/// </param>
/// <param name="currentModel">
///     The model prior to actioning the message
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> =

    match msg with
    | ArticleFetched content ->
        { currentModel with Content = Fetched content }, Cmd.none
    | ArticleFetchFailed ->
        currentModel, Cmd.none

/// <summary>
///     Defines the view to render based on the current state.
/// </summary>
/// <param name="model">
///     The current state or model
/// </param>
/// <param name="dispatchMsg">C
///     The function that allows the view to dispatch Messages
/// </param>
let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    match model.Content with
    | Fetched content ->
        StandardTemplate.template [
            Section.section [] [
                Columns.columns [] [
                    Column.column [ Column.Width (Screen.Desktop, Column.IsTwoThirds)
                                    Column.Width (Screen.Touch, Column.IsFull) ] [
                        Content.content [] [
                            ReactMarkdown.Markdown [ ReactMarkdown.Props.Source content ]
                        ]
                    ]
                ]
            ]
        ] (Route.Article model.Slug |> Some) optionalUser onSignOut
    | Fetching ->
        FullscreenCenterContentTemplate.template [
            Loading.loading ()
        ]