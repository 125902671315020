module StatBanana.Web.Client.Components.Molecules.StripeForm

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.Stripe.StripeJS

[<Literal>]
let private cardElementId = "CardElement"

type WhichCard =
    | New
    | Saved

type Props =
    { env : DeploymentEnvironment
      existingCardInfo : CardInfo option
      onReady : unit -> unit
      onSubmit : Stripe.TokenResult option -> unit }

type State =
    { card : Stripe.Element.CardElementObject option
      error : string option
      stripe : Stripe.StripeObject option
      whichCard : WhichCard }

type Styles =
    { whichCardOption : string
      whichCardRadio : string }

type StripeForm(initialProps) as this =
    inherit Component<Props, State>(initialProps)
    do
        let whichCard =
            match this.props.existingCardInfo with
            | Some _ -> Saved
            | None -> New
        this.setInitState
            { card = None
              error = None
              stripe = None
              whichCard = whichCard }

    let styles : Styles = importAll "./StripeForm.sass"

    let cardChangeEventHandler (event : Stripe.Element.CardElementChangeEvent) =
        match event.error with
        | Some error ->
            this.setState (fun state _ -> { state with error = Some error.message })
        | None ->
            this.setState (fun state _ -> { state with error = None })

    let cardElementReadyEventHandler () =
        this.props.onReady ()

    let initialiseStripeForm (stripe : Stripe.StripeObject) =
        let elements = stripe.elements()
        let card = elements.create_card ()
        card.mount !^("#" + cardElementId)
        card.addEventListener_change cardChangeEventHandler
        card.addEventListener_ready cardElementReadyEventHandler
        this.setState (fun state _ -> { state with card = Some card })

    let submitHandler (event : FormEvent) =
        event.preventDefault ()

        match this.state.stripe, this.state.card with
        | Some stripe, Some card ->
            match this.props.existingCardInfo, this.state.whichCard with
            | Some _, Saved ->
                this.props.onSubmit None
            | Some _, New
            | None, New ->
                stripe.createToken card
                |> Promise.map (fun token ->
                    match token.error with
                    | Some error ->
                        this.setState (fun state _ -> { state with error = Some error.message })
                    | None ->
                        this.props.onSubmit (Some token))
                |> Promise.catch (fun error ->
                    this.setState (fun state _ -> { state with error = Some error.Message }))
                |> ignore
            | None, Saved ->
                ()
        | _ ->
            ()

    override this.componentDidMount () =
        let stripeScript = Browser.document.createElement_script ()
        stripeScript.src <- "https://js.stripe.com/v3/"
        stripeScript.addEventListener_load (fun _ ->
            let Stripe : Stripe.IExports = Browser.window?Stripe
            let clientKey =
                match this.props.env with
                | Development
                | LocalDevelopment ->
                    "pk_test_BPgvN07Ii2khuDMXSgyraaNa00LZWjxvM5"
                | Production ->
                    "pk_live_umbMwdDHrJgsTaj4uBtYtGCH00OotbdC7J"
            let stripe = Stripe.Invoke clientKey
            this.setState (fun state _ -> { state with stripe = Some stripe })
            initialiseStripeForm stripe)

        stripeScript
        |> Browser.document.body.appendChild
        |> ignore

    override this.render () =
        let savedCard cardInfo =
            Field.div [] [
                Label.label [] [
                    str "Saved Card"
                ]
                sprintf
                    "%s ending in %d expiring %d/%d"
                    cardInfo.brand
                    cardInfo.lastFour
                    cardInfo.expiryMonth
                    cardInfo.expiryYear
                |> str
            ]

        let newCard labelText =
            Field.div [] [
                Label.label [ Label.For cardElementId ] [
                    str labelText
                ]
                div [ Id cardElementId ] []
                Help.help [ Help.Color IsDanger ] [
                    match this.state.error with
                    | Some error ->
                        yield str error
                    | None ->
                        yield str ""
                ]
            ]

        form [ OnSubmit submitHandler ] [
            Field.div [] [
                match this.props.existingCardInfo with
                | Some cardInfo ->
                    yield fragment [] [
                        div [ ClassName styles.whichCardOption ] [
                            input [ Checked (this.state.whichCard = Saved)
                                    ClassName styles.whichCardRadio
                                    OnChange (fun _ ->
                                        this.setState
                                            (fun state _ -> { state with whichCard = Saved }))
                                    Name "card"
                                    Type "radio"
                                    Value "saved" ]
                            savedCard cardInfo
                        ]
                        div [ ClassName styles.whichCardOption ] [
                            input [ Checked (this.state.whichCard = New)
                                    ClassName styles.whichCardRadio
                                    OnChange (fun _ ->
                                        this.setState
                                            (fun state _ -> { state with whichCard = New }))
                                    Name "card"
                                    Type "radio"
                                    Value "new" ]
                            newCard "Use New Card"
                        ]
                    ]
                | None ->
                    yield newCard "Enter Your Card Details"
            ]
            Field.div [] [
                Button.button [ Button.IsFullWidth ] [
                    str "Pay with Card"
                ]
            ]
        ]

let inline form (props : Props) = ofType<StripeForm,_,_> props []