/// A module to show the current viewers of a public session
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.CurrentSessionViewers

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma.Extensions.Wikiki

open Fable.FontAwesome
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain.Strategiser

type private Styles =
    { container : string
      hostIndicator : string
      viewer : string }

let viewers
    (viewerSize : int)
    (ownerId : string option)
    (online : CollaborativeSessionUser list)
    : ReactElement =

    let styles : Styles = importAll "./CurrentSessionViewers.sass"

    let renderViewer viewer =
        let className =
            String.concat
                " "
                [ styles.viewer
                  Tooltip.ClassName
                  Tooltip.IsTooltipTop ]
        let tooltipText =
            viewer.displayName
            |> Option.defaultValue viewer.email
        let renderHostIndicator () =
            match ownerId with
            | Some owner ->
                if viewer.id = owner then
                    i [ ClassName styles.hostIndicator ] [
                        Fa.i [ Fa.Solid.User ] []
                    ]
                else
                    nothing
            | None ->
                nothing
        div [ ClassName className
              Data ("tooltip", tooltipText) ] [
            UserAvatar.avatar
                viewer.photo
                viewer.displayName
                viewer.email
                viewerSize
            renderHostIndicator ()
        ]

    div [ ClassName styles.container ] [
        online
        |> List.map renderViewer
        |> ofList
    ]