module StatBanana.Web.Client.Components.Molecules.PremiumAd

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fulma

open StatBanana.Web.Client.Domain

let ad () =
    Card.card [] [
        Card.content [] [
            Heading.h3 [] [
                str "Save sessions."
                br []
                str "Collaborate with your teammates."
            ]
            p [] [
                str "Get unlimited access to StatBanana today with a 14-day free trial of premium."
            ]
        ]
        Card.footer [] [
            Card.Footer.a [ GenericOption.Props
                                [ Href (None |> Route.SignUp |> Route.getPath) ] ] [
                str "Start Free Trial"
            ]
        ]
    ]