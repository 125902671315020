namespace StatBanana.Web.Client.Domain.Strategiser

open System

open Fable.Import

open StatBanana.Domain
open StatBanana.Web.Client.Domain

/// Exception indicating that a session operation failed.
type SessionOperationFailed (msg : string) =
    inherit Exception (msg)

/// A service responsible for dealing with sessions.
type SessionStoreService =
    { createSession : AuthenticatedUser -> Game -> string option -> Save -> JS.Promise<SessionId * Session>
      deleteSession : SessionId -> JS.Promise<unit>
      getSession : SessionId -> JS.Promise<Session option>
      getSessionsForUser : string -> JS.Promise<(SessionId * Session) list>
      makeSessionPrivate : SessionId -> JS.Promise<SessionId>
      makeSessionPublic : SessionId -> JS.Promise<SessionId>
      renameSession : string -> SessionId -> JS.Promise<SessionId * string>
      saveToSession : Save -> SessionId -> JS.Promise<SessionId * float> }
