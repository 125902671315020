namespace StatBanana.Web.Client.Domain.Strategiser

/// <summary>
///     A type that allows a given state to have a history, will the ability to traverse back and forth "in time".
/// </summary>
///
/// <typeparam name="'T">
///     The type of the state to enable a history for.
/// </typeparam>
type History<'T> =
    { past : List<'T>
      present : 'T
      future : List<'T> }
module History =
    /// <summary>
    ///     For a given state, create a new History.
    /// </summary>
    ///
    /// <param name="state">
    ///     The state to create a new History for.
    /// </param>
    let create (state : 'T) : History<'T> =
        { past = []
          present = state
          future = [] }

    /// <summary>
    ///     Sets a given History's present to a given state, add existing present to past, erases the future.
    /// </summary>
    ///
    /// <param name="newState">
    ///     The state to set as the new present for the History..
    /// </param>
    ///
    /// <param name="history">
    ///     The History to set the present for.
    /// </param>
    let setPresent (newState : 'T) (history : History<'T>) : History<'T> =
        { past = history.present :: history.past
          present = newState
          future = [] }

    /// <summary>
    ///     For a given History, get the present/current state.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to get the present state for.
    /// </param>
    let getPresent (history : History<'T>) : 'T =
        history.present

    /// <summary>
    ///     In a given History, go back in time one step.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to go back one step in time in.
    /// </param>
    let goBackOne (history : History<'T>) : History<'T> =
        match history.past with
        | [] ->
            history
        | head :: tail ->
            { history with
                past = tail
                present = head
                future = history.present :: history.future }

    /// <summary>
    ///     In a given History, go forward in time one step.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to go forward one step in time in.
    /// </param>
    let goForwardOne (history : History<'T>) : History<'T> =
        match history.future with
        | [] ->
            history
        | head :: tail ->
            { history with
                past = history.present :: history.past
                present = head
                future = tail }

    /// <summary>
    ///     In a given History, remove all stored past states.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to remove all stored past states.
    /// </param>
    let removePastStates (history : History<'T>) : History<'T> =
        { history with
            past = []
            present = history.present
            future = history.future }

    /// <summary>
    ///     In a given History, remove all stored future states.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to remove all stored future states.
    /// </param>
    let removeFutureStates (history : History<'T>) : History<'T> =
        { history with
            past = history.past
            present = history.present
            future = [] }

    /// <summary>
    ///     Determine if a given History has a past.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to check if a past exists for it.
    /// </param>
    let hasPast (history : History<'T>) : bool =
        history.past.Length > 0

    /// <summary>
    ///     Determine if a given History has a future.
    /// </summary>
    ///
    /// <param name="history">
    ///     The History to check if a future exists for it.
    /// </param>
    let hasFuture (history : History<'T>) : bool =
        history.future.Length > 0