namespace StatBanana.Web.Client.Services

open System

open Fable.Core.JsInterop
open Fable.Import.Axios
open Fable.Import.Axios.Globals
open Fable.Import

open StatBanana.Domain
open StatBanana.Dto.Firestore.Subscriptions
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Extensions

module FirebaseSubscriptionService =

    /// Indicates the service failed to initialise payment methods
    type PaymentMethodsInitialisationError (msg : string) =
        inherit Exception (msg)

    /// Indicates the service failed to submit order
    type OrderSubmitError (msg : string) =
        inherit Exception (msg)

    let private submitCreateSubscriptionOrder
        (getToken : AuthGetBearerToken)
        (user : AuthenticatedUser)
        (paypalSubscriptionId : string option)
        (stripeToken : obj option)
        (order : Order)
        : JS.Promise<bool> =

        user
        |> getToken false
        |> Promise.bind (fun token ->
            let config = Axios.authenticateWith token
            let requestBody =
                createObj [
                    "email" ==> user.email
                    "game" ==> (order.game |> Game.toSlug)
                    "lineItem" ==> (order.lineItem |> LineItemDto.LineItem.fromDomain)
                    "stripeToken" ==> stripeToken
                    "paypalSubscriptionid" ==> paypalSubscriptionId
                ]

            axios.post ("/api/subscriptions_v1", requestBody, config))
        |> Promise.map (fun _ -> true)
        |> Promise.catchAxios (fun error ->
            let handleError error =
                let message =
                    match error with
                    | AxiosError.ErrorResponse response ->
                        response.response.data
                    | _ ->
                        AxiosError.getMessage error
                message
                |> sprintf "Failed to submit subscription order. Reason: %s"
                |> OrderSubmitError
                |> raise
            handleError error)

    let private submitChangeBillingCycleOrder
        (getToken : AuthGetBearerToken)
        (auth : AuthenticatedUser)
        (game : Game)
        : JS.Promise<bool> =

        auth
        |> getToken false
        |> Promise.bind (fun token ->
            let config = Axios.authenticateWith token
            axios.put
                (sprintf
                     "/api/subscriptions_v1/%s/billingCycle"
                     (game |> Game.toId), createEmpty, config))
        |> Promise.map (fun _ -> true)
        |> Promise.catchAxios (fun error ->
            let handleError error =
                let message =
                    match error with
                    | AxiosError.ErrorResponse response ->
                        response.response.data
                    | _ ->
                        AxiosError.getMessage error
                message
                |> sprintf "Failed to change billing cycle. Reason: %s"
                |> OrderSubmitError
                |> raise
            handleError error)

    let private submitCancelSubscriptionOrder
        (getToken : AuthGetBearerToken)
        (auth : AuthenticatedUser)
        (game : Game)
        : JS.Promise<bool> =

        auth
        |> getToken false
        |> Promise.bind (fun token ->
            let config = Axios.authenticateWith token
            axios.delete (sprintf "/api/subscriptions_v1/%s" (game |> Game.toId), config))
        |> Promise.map (fun _ -> true)
        |> Promise.catchAxios (fun error ->
            let handleError error =
                let message =
                    match error with
                    | AxiosError.ErrorResponse response ->
                        response.response.data
                    | _ ->
                        AxiosError.getMessage error
                message
                |> sprintf "Failed to cancel subscription. Reason: %s"
                |> OrderSubmitError
                |> raise
            handleError error)

    /// <summary>
    ///     The service implementation.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase app.
    /// </param>
    let initialise (getToken : AuthGetBearerToken) : SubscriptionService =
        { create = submitCreateSubscriptionOrder getToken
          changeBillingCycle = submitChangeBillingCycleOrder getToken
          cancel = submitCancelSubscriptionOrder getToken }