module StatBanana.Web.Client.Pages.NewSubscriptionSuccessPage

open Fable.Helpers.React

open Elmish
open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain

/// Application state passed down to this page
type Model = unit

/// Events/actions that can be dispatched by this page.
type Msg = unit

/// Initialises the page.
let init
    (app : AppConfig)
    (user : AuthenticatedUser)
    : Model * Cmd<Msg> =

    (), Cmd.none

let update
    (app : AppConfig)
    (user : AuthenticatedUser)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> =

    currentModel, Cmd.none

let view
    (app : AppConfig)
    (user : AuthenticatedUser)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    if user.claims |> UserClaim.isSubscribed Game.Dota2 then
        StandardTemplate.template [
            Section.section [ Section.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                Heading.h1 [] [ str "Subscription Successful" ]
                Content.content [] [
                    "Your subscription has been confirmed, and you now have premium access to StatBanana!"
                    |> str
                ]
                Button.mainCtaButton
                    Size.IsLarge
                    ((Game.Dota2, None) |> Route.Strategiser |> Route.getPath)
                    "Create Your First Strategy Now!"
            ]
        ] (Some Route.NewSubscriptionSuccess) (Some user) onSignOut
    else
        FullscreenCenterContentTemplate.template [
            Loading.loading ()
        ]