module StatBanana.Web.Client.Components.Molecules.PricingPlan

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain

type private Styles =
    { spacer : string
      switch : string }

let private renderFeature feature =
    div [ ClassName "plan-item" ] [
        feature
        |> str
    ]

let free
    features
    : ReactElement =

    let styles : Styles = importAll "./PricingPlan.sass"
    div [ ClassName "pricing-plan" ] [
        div [ ClassName "plan-header" ] [
            str "Starter"
        ]
        div [ ClassName "plan-price" ] [
            span [ ClassName "plan-price-amount" ] [
                str "Free"
            ]
            div [] [
                str "Forever"
            ]
            div [ ClassName styles.spacer ] []
        ]
        div [ ClassName "plan-items" ] [
            features
            |> List.map renderFeature
            |> ofList
        ]
    ]

type private PremiumProps =
    { features : string list
      hasTrialled : bool
      isSubscribed : bool
      onSubscribeClick : BillingPeriod -> unit }

type private PremiumState =
    { billingPeriod : BillingPeriod }

type private PremiumMsg =
    | ToggleBillingPeriod

let private initPremium (props : PremiumProps) : PremiumState =
    { billingPeriod = Monthly }

let private updatePremium
    (msg : PremiumMsg)
    (state : PremiumState)
    : PremiumState =

    match msg with
    | ToggleBillingPeriod ->
        let newBillingPeriod =
            if state.billingPeriod = BillingPeriod.Monthly then
                BillingPeriod.Yearly
            else BillingPeriod.Monthly
        { state with billingPeriod = newBillingPeriod }

let private viewPremium
    (model : ReactiveComponents.Model<PremiumProps, PremiumState>)
    (dispatch : PremiumMsg -> unit)
    : ReactElement =

    let styles : Styles = importAll "./PricingPlan.sass"

    let handleSubscribeClick _ =
        model.state.billingPeriod
        |> model.props.onSubscribeClick

    let onSwitchBillingPeriodHandler _ =
        dispatch ToggleBillingPeriod

    let price =
        { package = PremiumPackage.Standard
          billingPeriod = model.state.billingPeriod }
        |> PlanPricing.getCurrentPrice
        |> Price.PricingPage.amountWithPeriod model.state.billingPeriod

    let button =
        if model.props.isSubscribed then
            Button.a [ Button.Disabled true
                       Button.IsFullWidth ] [
                str "Current Plan"
            ]
        else if model.props.hasTrialled then

            Button.button [ Button.IsFullWidth
                            Button.Props [ OnClick handleSubscribeClick ] ] [
                str "Upgrade Now"
            ]
        else
            Button.a [ Button.IsFullWidth
                       Button.Props [ Href (None |> Route.SignUp |> Route.getPath) ] ] [
                str "Start Free Trial"
            ]

    div [ ClassName "pricing-plan is-warning" ] [
        div [ ClassName "plan-header" ] [
            str "Premium"
        ]
        div [ ClassName "plan-price" ] [
            price
            div [ ClassName styles.switch ] [
                Switch.billingPeriod
                    (model.state.billingPeriod = Yearly)
                    onSwitchBillingPeriodHandler
            ]
        ]
        div [ ClassName "plan-items" ] [
            model.props.features
            |> List.map renderFeature
            |> ofList
        ]
        div [ ClassName "plan-footer" ] [
            button
        ]
    ]

let premium
    (isSubscribed : bool)
    (hasTrialled : bool)
    (onSubscribeClick : BillingPeriod -> unit)
    (features : string list)
    : ReactElement =

    reactiveCom
        initPremium
        updatePremium
        viewPremium
        "premium"
        { features = features
          hasTrialled = hasTrialled
          isSubscribed = isSubscribed
          onSubscribeClick = onSubscribeClick }
        []