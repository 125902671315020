namespace StatBanana.Web.Client.Domain.Dota2

type AbilityType =
    | Active
    | Passive

type AbilityTargetType =
    | NoTarget
    | PointTarget
    | AreaTarget
    | UnitTarget

type Ability =
    | AbaddonAphoticShield
    | AbaddonBorrowedTime
    | AbaddonDeathCoil
    | AbaddonFrostmourne
    | AbyssalUnderlordAtrophyAura
    | AbyssalUnderlordCancelDarkRift
    | AbyssalUnderlordDarkRift
    | AbyssalUnderlordFirestorm
    | AbyssalUnderlordPitOfMalice
    | AlchemistAcidSpray
    | AlchemistChemicalRage
    | AlchemistGoblinsGreed
    | AlchemistUnstableConcoction
    | AlchemistUnstableConcoctionThrow
    | AlphaWolfCommandAura
    | AlphaWolfCriticalStrike
    | AncientApparitionChillingTouch
    | AncientApparitionColdFeet
    | AncientApparitionIceBlast
    | AncientApparitionIceBlastRelease
    | AncientApparitionIceVortex
    | AncientGolemRockslide
    | AntimageBlink
    | AntimageCounterspell
    | AntimageManaBreak
    | AntimageManaVoid
    | AntimageSpellShield
    | ArcWardenFlux
    | ArcWardenMagneticField
    | ArcWardenSparkWraith
    | ArcWardenTempestDouble
    | AxeBattleHunger
    | AxeBerserkersCall
    | AxeCounterHelix
    | AxeCullingBlade
    | BackdoorProtection
    | BackdoorProtectionInBase
    | BaneBrainSap
    | BaneEnfeeble
    | BaneFiendsGrip
    | BaneNightmare
    | BaneNightmareEnd
    | BatriderFirefly
    | BatriderFlamebreak
    | BatriderFlamingLasso
    | BatriderStickyNapalm
    | BearEmpty
    | BeastmasterBoarPoison
    | BeastmasterCallOfTheWild
    | BeastmasterCallOfTheWildBoar
    | BeastmasterCallOfTheWildHawk
    | BeastmasterGreaterBoarPoison
    | BeastmasterHawkInvisibility
    | BeastmasterInnerBeast
    | BeastmasterPrimalRoar
    | BeastmasterWildAxes
    | BigThunderLizardFrenzy
    | BigThunderLizardSlam
    | BigThunderLizardWardrumsAura
    | BlackDragonDragonhideAura
    | BlackDragonFireball
    | BlackDragonSplashAttack
    | BloodseekerBloodBath
    | BloodseekerBloodrage
    | BloodseekerRupture
    | BloodseekerThirst
    | BlueDragonspawnOverseerDevotionAura
    | BlueDragonspawnOverseerEvasion
    | BlueDragonspawnSorcererEvasion
    | BountyHunterJinada
    | BountyHunterShurikenToss
    | BountyHunterTrack
    | BountyHunterWindWalk
    | BrewmasterCinderBrew
    | BrewmasterDrunkenBrawler
    | BrewmasterDrunkenHaze
    | BrewmasterEarthHurlBoulder
    | BrewmasterEarthPulverize
    | BrewmasterEarthSpellImmunity
    | BrewmasterFirePermanentImmolation
    | BrewmasterPrimalSplit
    | BrewmasterStormCyclone
    | BrewmasterStormDispelMagic
    | BrewmasterStormWindWalk
    | BrewmasterThunderClap
    | BristlebackBristleback
    | BristlebackQuillSpray
    | BristlebackViscousNasalGoo
    | BristlebackWarpath
    | BroodmotherIncapacitatingBite
    | BroodmotherInsatiableHunger
    | BroodmotherPoisonSting
    | BroodmotherSpawnSpiderite
    | BroodmotherSpawnSpiderlings
    | BroodmotherSpinWeb
    | BroodmotherSpinWebDestroy
    | CentaurDoubleEdge
    | CentaurHoofStomp
    | CentaurReturn
    | CentaurStampede
    | ChaosKnightChaosBolt
    | ChaosKnightChaosStrike
    | ChaosKnightPhantasm
    | ChaosKnightRealityRift
    | ChenDivineFavor
    | ChenHandOfGod
    | ChenHolyPersuasion
    | ChenPenitence
    | ChenTestOfFaith
    | ChenTestOfFaithTeleport
    | ClinkzBurningArmy
    | ClinkzDeathPact
    | ClinkzSearingArrows
    | ClinkzStrafe
    | ClinkzWindWalk
    | CnyBeastForceAttack
    | CnyBeastTeleport
    | ConsumableHidden
    | CourierAutodeliver
    | CourierBurst
    | CourierGoToEnemySecretshop
    | CourierGoToSecretshop
    | CourierGoToSideshop
    | CourierMorph
    | CourierReturnStashItems
    | CourierReturnToBase
    | CourierShield
    | CourierTakeStashAndTransferItems
    | CourierTakeStashItems
    | CourierTransferItems
    | CourierTransferItemsToOtherPlayer
    | CrystalMaidenBrillianceAura
    | CrystalMaidenCrystalNova
    | CrystalMaidenFreezingField
    | CrystalMaidenFrostbite
    | DarkSeerIonShell
    | DarkSeerSurge
    | DarkSeerVacuum
    | DarkSeerWallOfReplica
    | DarkTrollWarlordEnsnare
    | DarkTrollWarlordRaiseDead
    | DarkWillowBedlam
    | DarkWillowBrambleMaze
    | DarkWillowCursedCrown
    | DarkWillowShadowRealm
    | DarkWillowTerrorize
    | DazzleBadJuju
    | DazzlePoisonTouch
    | DazzleShadowWave
    | DazzleShallowGrave
    | DazzleWeave
    | DeathProphetCarrionSwarm
    | DeathProphetExorcism
    | DeathProphetSilence
    | DeathProphetSpiritSiphon
    | DeathProphetWitchcraft
    | DisruptorGlimpse
    | DisruptorKineticField
    | DisruptorStaticStorm
    | DisruptorThunderStrike
    | DoomBringerDevour
    | DoomBringerDoom
    | DoomBringerEmpty
    | DoomBringerInfernalBlade
    | DoomBringerScorchedEarth
    | DotaBaseAbility
    | DragonKnightBreatheFire
    | DragonKnightDragonBlood
    | DragonKnightDragonTail
    | DragonKnightElderDragonForm
    | DrowRangerFrostArrows
    | DrowRangerMarksmanship
    | DrowRangerSilence
    | DrowRangerTrueshot
    | DrowRangerWaveOfSilence
    | EarthSpiritBoulderSmash
    | EarthSpiritGeomagneticGrip
    | EarthSpiritMagnetize
    | EarthSpiritPetrify
    | EarthSpiritRollingBoulder
    | EarthSpiritStoneCaller
    | EarthshakerAftershock
    | EarthshakerEchoSlam
    | EarthshakerEnchantTotem
    | EarthshakerFissure
    | ElderTitanAncestralSpirit
    | ElderTitanEarthSplitter
    | ElderTitanEchoStomp
    | ElderTitanEchoStompSpirit
    | ElderTitanNaturalOrder
    | ElderTitanNaturalOrderSpirit
    | ElderTitanReturnSpirit
    | EmberSpiritActivateFireRemnant
    | EmberSpiritFireRemnant
    | EmberSpiritFlameGuard
    | EmberSpiritSearingChains
    | EmberSpiritSleightOfFist
    | EnchantressEnchant
    | EnchantressImpetus
    | EnchantressNaturesAttendants
    | EnchantressUntouchable
    | EnigmaBlackHole
    | EnigmaDemonicConversion
    | EnigmaMalefice
    | EnigmaMidnightPulse
    | EnragedWildkinTornado
    | EnragedWildkinToughnessAura
    | FacelessVoidBacktrack
    | FacelessVoidChronosphere
    | FacelessVoidTimeDilation
    | FacelessVoidTimeLock
    | FacelessVoidTimeWalk
    | FillerAbility
    | ForestTrollHighPriestHeal
    | ForestTrollHighPriestManaAura
    | ForgedSpiritMeltingStrike
    | FrostivusDecorateTree
    | FrostivusFestiveFirework
    | FrostivusSummonSnowman
    | FrostivusThrowSnowball
    | FurionForceOfNature
    | FurionSprout
    | FurionTeleportation
    | FurionWrathOfNature
    | GenericHidden
    | GhostFrostAttack
    | GiantWolfCriticalStrike
    | GnollAssassinEnvenomedWeapon
    | GraniteGolemBash
    | GraniteGolemHpAura
    | GrimstrokeDarkArtistry
    | GrimstrokeInkCreature
    | GrimstrokeSoulChain
    | GrimstrokeSpiritWalk
    | GyrocopterCallDown
    | GyrocopterFlakCannon
    | GyrocopterHomingMissile
    | GyrocopterRocketBarrage
    | HarpyStormChainLightning
    | HealingCampfire
    | HuskarBerserkersBlood
    | HuskarBurningSpear
    | HuskarInnerFire
    | HuskarInnerVitality
    | HuskarLifeBreak
    | InvokerAlacrity
    | InvokerChaosMeteor
    | InvokerColdSnap
    | InvokerDeafeningBlast
    | InvokerEmp
    | InvokerEmpty
    | InvokerExort
    | InvokerForgeSpirit
    | InvokerGhostWalk
    | InvokerIceWall
    | InvokerInvoke
    | InvokerQuas
    | InvokerSunStrike
    | InvokerTornado
    | InvokerWex
    | JakiroDualBreath
    | JakiroIcePath
    | JakiroLiquidFire
    | JakiroMacropyre
    | JuggernautBladeDance
    | JuggernautBladeFury
    | JuggernautHealingWard
    | JuggernautOmniSlash
    | KeeperOfTheLightBlindingLight
    | KeeperOfTheLightChakraMagic
    | KeeperOfTheLightEmpty
    | KeeperOfTheLightIlluminate
    | KeeperOfTheLightIlluminateEnd
    | KeeperOfTheLightManaLeak
    | KeeperOfTheLightRecall
    | KeeperOfTheLightSpiritForm
    | KeeperOfTheLightSpiritFormIlluminate
    | KeeperOfTheLightSpiritFormIlluminateEnd
    | KeeperOfTheLightWillOWisp
    | KoboldTaskmasterSpeedAura
    | KunkkaGhostship
    | KunkkaReturn
    | KunkkaTidebringer
    | KunkkaTorrent
    | KunkkaXMarksTheSpot
    | LegionCommanderDuel
    | LegionCommanderMomentOfCourage
    | LegionCommanderOverwhelmingOdds
    | LegionCommanderPressTheAttack
    | LeshracDiabolicEdict
    | LeshracLightningStorm
    | LeshracPulseNova
    | LeshracSplitEarth
    | LichChainFrost
    | LichDarkSorcery
    | LichFrostArmor
    | LichFrostAura
    | LichFrostNova
    | LichFrostShield
    | LichSinisterGaze
    | LifeStealerAssimilate
    | LifeStealerAssimilateEject
    | LifeStealerConsume
    | LifeStealerControl
    | LifeStealerEmpty
    | LifeStealerFeast
    | LifeStealerInfest
    | LifeStealerOpenWounds
    | LifeStealerRage
    | LinaDragonSlave
    | LinaFierySoul
    | LinaLagunaBlade
    | LinaLightStrikeArray
    | LionFingerOfDeath
    | LionImpale
    | LionManaDrain
    | LionVoodoo
    | LoneDruidRabid
    | LoneDruidSavageRoar
    | LoneDruidSavageRoarBear
    | LoneDruidSpiritBear
    | LoneDruidSpiritBearDefender
    | LoneDruidSpiritBearDemolish
    | LoneDruidSpiritBearEntangle
    | LoneDruidSpiritBearReturn
    | LoneDruidSpiritLink
    | LoneDruidTrueForm
    | LoneDruidTrueFormBattleCry
    | LoneDruidTrueFormDruid
    | LunaEclipse
    | LunaLucentBeam
    | LunaLunarBlessing
    | LunaMoonGlaive
    | LycanFeralImpulse
    | LycanHowl
    | LycanShapeshift
    | LycanSummonWolves
    | LycanSummonWolvesCriticalStrike
    | LycanSummonWolvesInvisibility
    | MagnataurEmpower
    | MagnataurReversePolarity
    | MagnataurShockwave
    | MagnataurSkewer
    | MarsArenaOfBlood
    | MarsBulwark
    | MarsGodsRebuke
    | MarsSpear
    | MedusaManaShield
    | MedusaMysticSnake
    | MedusaSplitShot
    | MedusaStoneGaze
    | MeepoDividedWeStand
    | MeepoEarthbind
    | MeepoGeostrike
    | MeepoPoof
    | MeepoRansack
    | MiranaArrow
    | MiranaInvis
    | MiranaLeap
    | MiranaStarfall
    | MonkeyKingBoundlessStrike
    | MonkeyKingJinguMastery
    | MonkeyKingMischief
    | MonkeyKingPrimalSpring
    | MonkeyKingPrimalSpringEarly
    | MonkeyKingTreeDance
    | MonkeyKingUntransform
    | MonkeyKingWukongsCommand
    | MorphlingAdaptiveStrikeAgi
    | MorphlingAdaptiveStrikeStr
    | MorphlingHybrid
    | MorphlingMorph
    | MorphlingMorphAgi
    | MorphlingMorphReplicate
    | MorphlingMorphStr
    | MorphlingReplicate
    | MorphlingWaveform
    | MudGolemHurlBoulder
    | MudGolemRockDestroy
    | MudgolemCloakAura
    | NagaSirenEnsnare
    | NagaSirenMirrorImage
    | NagaSirenRipTide
    | NagaSirenSongOfTheSiren
    | NagaSirenSongOfTheSirenCancel
    | NecrolyteDeathPulse
    | NecrolyteHeartstopperAura
    | NecrolyteReapersScythe
    | NecrolyteSadist
    | NecrolyteSadistStop
    | NecronomiconArcherAoe
    | NecronomiconArcherManaBurn
    | NecronomiconArcherPurge
    | NecronomiconWarriorLastWill
    | NecronomiconWarriorManaBurn
    | NecronomiconWarriorSight
    | NeutralSpellImmunity
    | NevermoreDarkLord
    | NevermoreNecromastery
    | NevermoreRequiem
    | NevermoreShadowraze
    | NightStalkerCripplingFear
    | NightStalkerDarkness
    | NightStalkerHunterInTheNight
    | NightStalkerVoid
    | NyxAssassinBurrow
    | NyxAssassinImpale
    | NyxAssassinManaBurn
    | NyxAssassinSpikedCarapace
    | NyxAssassinUnburrow
    | NyxAssassinVendetta
    | ObsidianDestroyerArcaneOrb
    | ObsidianDestroyerAstralImprisonment
    | ObsidianDestroyerEquilibrium
    | ObsidianDestroyerEssenceAura
    | ObsidianDestroyerSanityEclipse
    | OgreMagiBloodlust
    | OgreMagiFireblast
    | OgreMagiFrostArmor
    | OgreMagiIgnite
    | OgreMagiMulticast
    | OgreMagiUnrefinedFireblast
    | OmniknightDegenAura
    | OmniknightGuardianAngel
    | OmniknightPacify
    | OmniknightPurification
    | OmniknightRepel
    | OracleFalsePromise
    | OracleFatesEdict
    | OracleFortunesEnd
    | OraclePurifyingFlames
    | PangolierGyroshell
    | PangolierGyroshellStop
    | PangolierHeartpiercer
    | PangolierLuckyShot
    | PangolierShieldCrash
    | PangolierSwashbuckle
    | PhantomAssassinBlur
    | PhantomAssassinCoupDeGrace
    | PhantomAssassinPhantomStrike
    | PhantomAssassinStiflingDagger
    | PhantomLancerDoppelwalk
    | PhantomLancerJuxtapose
    | PhantomLancerPhantomEdge
    | PhantomLancerSpiritLance
    | PhoenixFireSpirits
    | PhoenixIcarusDive
    | PhoenixIcarusDiveStop
    | PhoenixLaunchFireSpirit
    | PhoenixSunRay
    | PhoenixSunRayStop
    | PhoenixSunRayToggleMove
    | PhoenixSupernova
    | PolarFurbolgUrsaWarriorThunderClap
    | PuckDreamCoil
    | PuckEtherealJaunt
    | PuckIllusoryOrb
    | PuckPhaseShift
    | PuckWaningRift
    | PudgeDismember
    | PudgeFleshHeap
    | PudgeMeatHook
    | PudgeRot
    | PugnaDecrepify
    | PugnaLifeDrain
    | PugnaNetherBlast
    | PugnaNetherWard
    | QueenofpainBlink
    | QueenofpainScreamOfPain
    | QueenofpainShadowStrike
    | QueenofpainSonicWave
    | RattletrapBatteryAssault
    | RattletrapHookshot
    | RattletrapPowerCogs
    | RattletrapRocketFlare
    | RazorEyeOfTheStorm
    | RazorPlasmaField
    | RazorStaticLink
    | RazorUnstableCurrent
    | RikiBlinkStrike
    | RikiPermanentInvisibility
    | RikiSmokeScreen
    | RikiTricksOfTheTrade
    | RoshanBash
    | RoshanDevotion
    | RoshanInherentBuffs
    | RoshanSlam
    | RoshanSpellBlock
    | RubickArcaneSupremacy
    | RubickEmpty
    | RubickFadeBolt
    | RubickHidden
    | RubickNullField
    | RubickSpellSteal
    | RubickTelekinesis
    | RubickTelekinesisLand
    | SandkingBurrowstrike
    | SandkingCausticFinale
    | SandkingEpicenter
    | SandkingSandStorm
    | SatyrHellcallerShockwave
    | SatyrHellcallerUnholyAura
    | SatyrSoulstealerManaBurn
    | SatyrTricksterPurge
    | ShadowDemonDemonicPurge
    | ShadowDemonDisruption
    | ShadowDemonShadowPoison
    | ShadowDemonShadowPoisonRelease
    | ShadowDemonSoulCatcher
    | ShadowShamanEtherShock
    | ShadowShamanMassSerpentWard
    | ShadowShamanShackles
    | ShadowShamanVoodoo
    | ShootFirework
    | ShredderChakram
    | ShredderReactiveArmor
    | ShredderReturnChakram
    | ShredderTimberChain
    | ShredderWhirlingDeath
    | SilencerCurseOfTheSilent
    | SilencerGlaivesOfWisdom
    | SilencerGlobalSilence
    | SilencerLastWord
    | SkeletonKingHellfireBlast
    | SkeletonKingMortalStrike
    | SkeletonKingReincarnation
    | SkeletonKingVampiricAura
    | SkywrathMageAncientSeal
    | SkywrathMageArcaneBolt
    | SkywrathMageConcussiveShot
    | SkywrathMageMysticFlare
    | SlardarAmplifyDamage
    | SlardarBash
    | SlardarSlithereenCrush
    | SlardarSprint
    | SlarkDarkPact
    | SlarkEssenceShift
    | SlarkPounce
    | SlarkShadowDance
    | SniperAssassinate
    | SniperHeadshot
    | SniperShrapnel
    | SniperTakeAim
    | SpawnlordAura
    | SpawnlordMasterBash
    | SpawnlordMasterFreeze
    | SpawnlordMasterStomp
    | SpectreDesolate
    | SpectreDispersion
    | SpectreHaunt
    | SpectreReality
    | SpectreSpectralDagger
    | SpiritBreakerBulldoze
    | SpiritBreakerChargeOfDarkness
    | SpiritBreakerEmpoweringHaste
    | SpiritBreakerGreaterBash
    | SpiritBreakerNetherStrike
    | StormSpiritBallLightning
    | StormSpiritElectricVortex
    | StormSpiritOverload
    | StormSpiritStaticRemnant
    | SvenGodsStrength
    | SvenGreatCleave
    | SvenStormBolt
    | SvenWarcry
    | TechiesFocusedDetonate
    | TechiesLandMines
    | TechiesMinefieldSign
    | TechiesRemoteMines
    | TechiesRemoteMinesSelfDetonate
    | TechiesStasisTrap
    | TechiesSuicide
    | TemplarAssassinMeld
    | TemplarAssassinPsiBlades
    | TemplarAssassinPsionicTrap
    | TemplarAssassinRefraction
    | TemplarAssassinSelfTrap
    | TemplarAssassinTrap
    | TerrorbladeConjureImage
    | TerrorbladeMetamorphosis
    | TerrorbladeReflection
    | TerrorbladeSunder
    | TidehunterAnchorSmash
    | TidehunterGush
    | TidehunterKrakenShell
    | TidehunterRavage
    | TinkerHeatSeekingMissile
    | TinkerLaser
    | TinkerMarchOfTheMachines
    | TinkerRearm
    | TinyAvalanche
    | TinyCraggyExterior
    | TinyGrow
    | TinyToss
    | TinyTossTree
    | TornadoTempest
    | TreantEyesInTheForest
    | TreantLeechSeed
    | TreantLivingArmor
    | TreantNaturesGuise
    | TreantOvergrowth
    | TrollWarlordBattleTrance
    | TrollWarlordBerserkersRage
    | TrollWarlordFervor
    | TrollWarlordWhirlingAxesMelee
    | TrollWarlordWhirlingAxesRanged
    | TuskFrozenSigil
    | TuskIceShards
    | TuskIceShardsStop
    | TuskLaunchSnowball
    | TuskSnowball
    | TuskTagTeam
    | TuskWalrusKick
    | TuskWalrusPunch
    | UndyingDecay
    | UndyingFleshGolem
    | UndyingSoulRip
    | UndyingTombstone
    | UndyingTombstoneZombieAura
    | UndyingTombstoneZombieDeathstrike
    | UrsaEarthshock
    | UrsaEnrage
    | UrsaFurySwipes
    | UrsaOverpower
    | VengefulspiritCommandAura
    | VengefulspiritMagicMissile
    | VengefulspiritNetherSwap
    | VengefulspiritWaveOfTerror
    | VenomancerPlagueWard
    | VenomancerPoisonNova
    | VenomancerPoisonSting
    | VenomancerVenomousGale
    | ViperCorrosiveSkin
    | ViperNethertoxin
    | ViperPoisonAttack
    | ViperViperStrike
    | VisageGraveChill
    | VisageGravekeepersCloak
    | VisageSoulAssumption
    | VisageStoneFormSelfCast
    | VisageSummonFamiliars
    | VisageSummonFamiliarsStoneForm
    | WarlockFatalBonds
    | WarlockGolemFlamingFists
    | WarlockGolemPermanentImmolation
    | WarlockRainOfChaos
    | WarlockShadowWord
    | WarlockUpheaval
    | WeaverGeminateAttack
    | WeaverShukuchi
    | WeaverTheSwarm
    | WeaverTimeLapse
    | WindrunnerFocusfire
    | WindrunnerPowershot
    | WindrunnerShackleshot
    | WindrunnerWindrun
    | WinterWyvernArcticBurn
    | WinterWyvernColdEmbrace
    | WinterWyvernSplinterBlast
    | WinterWyvernWintersCurse
    | WispOvercharge
    | WispRelocate
    | WispSpirits
    | WispSpiritsIn
    | WispSpiritsOut
    | WispTether
    | WispTetherBreak
    | WitchDoctorDeathWard
    | WitchDoctorMaledict
    | WitchDoctorParalyzingCask
    | WitchDoctorVoodooRestoration
    | ZuusArcLightning
    | ZuusCloud
    | ZuusLightningBolt
    | ZuusStaticField
    | ZuusThundergodsWrath

module Ability =
    /// <summary>
    ///
    /// </summary>
    ///
    /// <param name="ability">
    ///     The Dota 2 ability to obtain an AbilityType for
    /// </param>
    ///
    /// <returns>
    ///     The AbilityType of the given Dota 2 Ability.
    /// </returns>
    let getType (ability : Ability) : AbilityType =
        match ability with
        | AbaddonAphoticShield -> Active
        | AbaddonBorrowedTime -> Active
        | AbaddonDeathCoil -> Active
        | AbaddonFrostmourne -> Passive
        | AbyssalUnderlordAtrophyAura -> Passive
        | AbyssalUnderlordCancelDarkRift -> Active
        | AbyssalUnderlordDarkRift -> Active
        | AbyssalUnderlordFirestorm -> Active
        | AbyssalUnderlordPitOfMalice -> Active
        | AlchemistAcidSpray -> Active
        | AlchemistChemicalRage -> Active
        | AlchemistGoblinsGreed -> Passive
        | AlchemistUnstableConcoction -> Active
        | AlchemistUnstableConcoctionThrow -> Active
        | AlphaWolfCommandAura -> Passive
        | AlphaWolfCriticalStrike -> Passive
        | AncientApparitionChillingTouch -> Active
        | AncientApparitionColdFeet -> Active
        | AncientApparitionIceBlast -> Active
        | AncientApparitionIceBlastRelease -> Active
        | AncientApparitionIceVortex -> Active
        | AncientGolemRockslide -> Active
        | AntimageBlink -> Active
        | AntimageCounterspell -> Active
        | AntimageManaBreak -> Passive
        | AntimageManaVoid -> Active
        | AntimageSpellShield -> Passive
        | ArcWardenFlux -> Active
        | ArcWardenMagneticField -> Active
        | ArcWardenSparkWraith -> Active
        | ArcWardenTempestDouble -> Active
        | AxeBattleHunger -> Active
        | AxeBerserkersCall -> Active
        | AxeCounterHelix -> Passive
        | AxeCullingBlade -> Active
        | BackdoorProtection -> Passive
        | BackdoorProtectionInBase -> Passive
        | BaneBrainSap -> Active
        | BaneEnfeeble -> Active
        | BaneFiendsGrip -> Active
        | BaneNightmare -> Active
        | BaneNightmareEnd -> Active
        | BatriderFirefly -> Active
        | BatriderFlamebreak -> Active
        | BatriderFlamingLasso -> Active
        | BatriderStickyNapalm -> Active
        | BearEmpty -> Passive
        | BeastmasterBoarPoison -> Passive
        | BeastmasterCallOfTheWild -> Active
        | BeastmasterCallOfTheWildBoar -> Active
        | BeastmasterCallOfTheWildHawk -> Active
        | BeastmasterGreaterBoarPoison -> Passive
        | BeastmasterHawkInvisibility -> Passive
        | BeastmasterInnerBeast -> Passive
        | BeastmasterPrimalRoar -> Active
        | BeastmasterWildAxes -> Active
        | BigThunderLizardFrenzy -> Active
        | BigThunderLizardSlam -> Active
        | BigThunderLizardWardrumsAura -> Passive
        | BlackDragonDragonhideAura -> Passive
        | BlackDragonFireball -> Active
        | BlackDragonSplashAttack -> Passive
        | BloodseekerBloodBath -> Active
        | BloodseekerBloodrage -> Active
        | BloodseekerRupture -> Active
        | BloodseekerThirst -> Passive
        | BlueDragonspawnOverseerDevotionAura -> Passive
        | BlueDragonspawnOverseerEvasion -> Passive
        | BlueDragonspawnSorcererEvasion -> Passive
        | BountyHunterJinada -> Passive
        | BountyHunterShurikenToss -> Active
        | BountyHunterTrack -> Active
        | BountyHunterWindWalk -> Active
        | BrewmasterCinderBrew -> Active
        | BrewmasterDrunkenBrawler -> Active
        | BrewmasterDrunkenHaze -> Active
        | BrewmasterEarthHurlBoulder -> Active
        | BrewmasterEarthPulverize -> Passive
        | BrewmasterEarthSpellImmunity -> Passive
        | BrewmasterFirePermanentImmolation -> Passive
        | BrewmasterPrimalSplit -> Active
        | BrewmasterStormCyclone -> Active
        | BrewmasterStormDispelMagic -> Active
        | BrewmasterStormWindWalk -> Active
        | BrewmasterThunderClap -> Active
        | BristlebackBristleback -> Passive
        | BristlebackQuillSpray -> Active
        | BristlebackViscousNasalGoo -> Active
        | BristlebackWarpath -> Passive
        | BroodmotherIncapacitatingBite -> Passive
        | BroodmotherInsatiableHunger -> Active
        | BroodmotherPoisonSting -> Passive
        | BroodmotherSpawnSpiderite -> Passive
        | BroodmotherSpawnSpiderlings -> Active
        | BroodmotherSpinWeb -> Active
        | BroodmotherSpinWebDestroy -> Active
        | CentaurDoubleEdge -> Active
        | CentaurHoofStomp -> Active
        | CentaurReturn -> Active
        | CentaurStampede -> Active
        | ChaosKnightChaosBolt -> Active
        | ChaosKnightChaosStrike -> Passive
        | ChaosKnightPhantasm -> Active
        | ChaosKnightRealityRift -> Active
        | ChenDivineFavor -> Active
        | ChenHandOfGod -> Active
        | ChenHolyPersuasion -> Active
        | ChenPenitence -> Active
        | ChenTestOfFaith -> Active
        | ChenTestOfFaithTeleport -> Active
        | ClinkzBurningArmy -> Active
        | ClinkzDeathPact -> Active
        | ClinkzSearingArrows -> Active
        | ClinkzStrafe -> Active
        | ClinkzWindWalk -> Active
        | CnyBeastForceAttack -> Active
        | CnyBeastTeleport -> Active
        | ConsumableHidden -> Active
        | CourierAutodeliver -> Active
        | CourierBurst -> Active
        | CourierGoToEnemySecretshop -> Active
        | CourierGoToSecretshop -> Active
        | CourierGoToSideshop -> Active
        | CourierMorph -> Active
        | CourierReturnStashItems -> Active
        | CourierReturnToBase -> Active
        | CourierShield -> Active
        | CourierTakeStashAndTransferItems -> Active
        | CourierTakeStashItems -> Active
        | CourierTransferItems -> Active
        | CourierTransferItemsToOtherPlayer -> Active
        | CrystalMaidenBrillianceAura -> Passive
        | CrystalMaidenCrystalNova -> Active
        | CrystalMaidenFreezingField -> Active
        | CrystalMaidenFrostbite -> Active
        | DarkSeerIonShell -> Active
        | DarkSeerSurge -> Active
        | DarkSeerVacuum -> Active
        | DarkSeerWallOfReplica -> Active
        | DarkTrollWarlordEnsnare -> Active
        | DarkTrollWarlordRaiseDead -> Active
        | DarkWillowBedlam -> Active
        | DarkWillowBrambleMaze -> Active
        | DarkWillowCursedCrown -> Active
        | DarkWillowShadowRealm -> Active
        | DarkWillowTerrorize -> Active
        | DazzleBadJuju -> Passive
        | DazzlePoisonTouch -> Active
        | DazzleShadowWave -> Active
        | DazzleShallowGrave -> Active
        | DazzleWeave -> Active
        | DeathProphetCarrionSwarm -> Active
        | DeathProphetExorcism -> Active
        | DeathProphetSilence -> Active
        | DeathProphetSpiritSiphon -> Active
        | DeathProphetWitchcraft -> Passive
        | DisruptorGlimpse -> Active
        | DisruptorKineticField -> Active
        | DisruptorStaticStorm -> Active
        | DisruptorThunderStrike -> Active
        | DoomBringerDevour -> Active
        | DoomBringerDoom -> Active
        | DoomBringerEmpty -> Passive
        | DoomBringerInfernalBlade -> Active
        | DoomBringerScorchedEarth -> Active
        | DotaBaseAbility -> Active
        | DragonKnightBreatheFire -> Active
        | DragonKnightDragonBlood -> Passive
        | DragonKnightDragonTail -> Active
        | DragonKnightElderDragonForm -> Active
        | DrowRangerFrostArrows -> Active
        | DrowRangerMarksmanship -> Passive
        | DrowRangerSilence -> Active
        | DrowRangerTrueshot -> Active
        | DrowRangerWaveOfSilence -> Active
        | EarthSpiritBoulderSmash -> Active
        | EarthSpiritGeomagneticGrip -> Active
        | EarthSpiritMagnetize -> Active
        | EarthSpiritPetrify -> Active
        | EarthSpiritRollingBoulder -> Active
        | EarthSpiritStoneCaller -> Active
        | EarthshakerAftershock -> Passive
        | EarthshakerEchoSlam -> Active
        | EarthshakerEnchantTotem -> Active
        | EarthshakerFissure -> Active
        | ElderTitanAncestralSpirit -> Active
        | ElderTitanEarthSplitter -> Active
        | ElderTitanEchoStomp -> Active
        | ElderTitanEchoStompSpirit -> Active
        | ElderTitanNaturalOrder -> Passive
        | ElderTitanNaturalOrderSpirit -> Passive
        | ElderTitanReturnSpirit -> Active
        | EmberSpiritActivateFireRemnant -> Active
        | EmberSpiritFireRemnant -> Active
        | EmberSpiritFlameGuard -> Active
        | EmberSpiritSearingChains -> Active
        | EmberSpiritSleightOfFist -> Active
        | EnchantressEnchant -> Active
        | EnchantressImpetus -> Active
        | EnchantressNaturesAttendants -> Active
        | EnchantressUntouchable -> Passive
        | EnigmaBlackHole -> Active
        | EnigmaDemonicConversion -> Active
        | EnigmaMalefice -> Active
        | EnigmaMidnightPulse -> Active
        | EnragedWildkinTornado -> Active
        | EnragedWildkinToughnessAura -> Passive
        | FacelessVoidBacktrack -> Passive
        | FacelessVoidChronosphere -> Active
        | FacelessVoidTimeDilation -> Active
        | FacelessVoidTimeLock -> Passive
        | FacelessVoidTimeWalk -> Active
        | FillerAbility -> Passive
        | ForestTrollHighPriestHeal -> Active
        | ForestTrollHighPriestManaAura -> Passive
        | ForgedSpiritMeltingStrike -> Passive
        | FrostivusDecorateTree -> Active
        | FrostivusFestiveFirework -> Active
        | FrostivusSummonSnowman -> Active
        | FrostivusThrowSnowball -> Active
        | FurionForceOfNature -> Active
        | FurionSprout -> Active
        | FurionTeleportation -> Active
        | FurionWrathOfNature -> Active
        | GenericHidden -> Active
        | GhostFrostAttack -> Passive
        | GiantWolfCriticalStrike -> Passive
        | GnollAssassinEnvenomedWeapon -> Passive
        | GraniteGolemBash -> Passive
        | GraniteGolemHpAura -> Passive
        | GrimstrokeDarkArtistry -> Active
        | GrimstrokeInkCreature -> Active
        | GrimstrokeSoulChain -> Active
        | GrimstrokeSpiritWalk -> Active
        | GyrocopterCallDown -> Active
        | GyrocopterFlakCannon -> Active
        | GyrocopterHomingMissile -> Active
        | GyrocopterRocketBarrage -> Active
        | HarpyStormChainLightning -> Active
        | HealingCampfire -> Active
        | HuskarBerserkersBlood -> Passive
        | HuskarBurningSpear -> Active
        | HuskarInnerFire -> Active
        | HuskarInnerVitality -> Active
        | HuskarLifeBreak -> Active
        | InvokerAlacrity -> Active
        | InvokerChaosMeteor -> Active
        | InvokerColdSnap -> Active
        | InvokerDeafeningBlast -> Active
        | InvokerEmp -> Active
        | InvokerEmpty -> Passive
        | InvokerExort -> Active
        | InvokerForgeSpirit -> Active
        | InvokerGhostWalk -> Active
        | InvokerIceWall -> Active
        | InvokerInvoke -> Active
        | InvokerQuas -> Active
        | InvokerSunStrike -> Active
        | InvokerTornado -> Active
        | InvokerWex -> Active
        | JakiroDualBreath -> Active
        | JakiroIcePath -> Active
        | JakiroLiquidFire -> Active
        | JakiroMacropyre -> Active
        | JuggernautBladeDance -> Passive
        | JuggernautBladeFury -> Active
        | JuggernautHealingWard -> Active
        | JuggernautOmniSlash -> Active
        | KeeperOfTheLightBlindingLight -> Active
        | KeeperOfTheLightChakraMagic -> Active
        | KeeperOfTheLightEmpty -> Passive
        | KeeperOfTheLightIlluminate -> Active
        | KeeperOfTheLightIlluminateEnd -> Active
        | KeeperOfTheLightManaLeak -> Active
        | KeeperOfTheLightRecall -> Active
        | KeeperOfTheLightSpiritForm -> Active
        | KeeperOfTheLightSpiritFormIlluminate -> Active
        | KeeperOfTheLightSpiritFormIlluminateEnd -> Active
        | KeeperOfTheLightWillOWisp -> Active
        | KoboldTaskmasterSpeedAura -> Passive
        | KunkkaGhostship -> Active
        | KunkkaReturn -> Active
        | KunkkaTidebringer -> Active
        | KunkkaTorrent -> Active
        | KunkkaXMarksTheSpot -> Active
        | LegionCommanderDuel -> Active
        | LegionCommanderMomentOfCourage -> Passive
        | LegionCommanderOverwhelmingOdds -> Active
        | LegionCommanderPressTheAttack -> Active
        | LeshracDiabolicEdict -> Active
        | LeshracLightningStorm -> Active
        | LeshracPulseNova -> Active
        | LeshracSplitEarth -> Active
        | LichChainFrost -> Active
        | LichDarkSorcery -> Active
        | LichFrostArmor -> Active
        | LichFrostAura -> Passive
        | LichFrostNova -> Active
        | LichFrostShield -> Active
        | LichSinisterGaze -> Active
        | LifeStealerAssimilate -> Active
        | LifeStealerAssimilateEject -> Active
        | LifeStealerConsume -> Active
        | LifeStealerControl -> Active
        | LifeStealerEmpty -> Passive
        | LifeStealerFeast -> Passive
        | LifeStealerInfest -> Active
        | LifeStealerOpenWounds -> Active
        | LifeStealerRage -> Active
        | LinaDragonSlave -> Active
        | LinaFierySoul -> Passive
        | LinaLagunaBlade -> Active
        | LinaLightStrikeArray -> Active
        | LionFingerOfDeath -> Active
        | LionImpale -> Active
        | LionManaDrain -> Active
        | LionVoodoo -> Active
        | LoneDruidRabid -> Active
        | LoneDruidSavageRoar -> Active
        | LoneDruidSavageRoarBear -> Active
        | LoneDruidSpiritBear -> Active
        | LoneDruidSpiritBearDefender -> Passive
        | LoneDruidSpiritBearDemolish -> Passive
        | LoneDruidSpiritBearEntangle -> Passive
        | LoneDruidSpiritBearReturn -> Active
        | LoneDruidSpiritLink -> Active
        | LoneDruidTrueForm -> Active
        | LoneDruidTrueFormBattleCry -> Active
        | LoneDruidTrueFormDruid -> Active
        | LunaEclipse -> Active
        | LunaLucentBeam -> Active
        | LunaLunarBlessing -> Passive
        | LunaMoonGlaive -> Passive
        | LycanFeralImpulse -> Passive
        | LycanHowl -> Active
        | LycanShapeshift -> Active
        | LycanSummonWolves -> Active
        | LycanSummonWolvesCriticalStrike -> Passive
        | LycanSummonWolvesInvisibility -> Passive
        | MagnataurEmpower -> Active
        | MagnataurReversePolarity -> Active
        | MagnataurShockwave -> Active
        | MagnataurSkewer -> Active
        | MarsArenaOfBlood -> Active
        | MarsBulwark -> Passive
        | MarsGodsRebuke -> Active
        | MarsSpear -> Active
        | MedusaManaShield -> Active
        | MedusaMysticSnake -> Active
        | MedusaSplitShot -> Passive
        | MedusaStoneGaze -> Active
        | MeepoDividedWeStand -> Passive
        | MeepoEarthbind -> Active
        | MeepoGeostrike -> Passive
        | MeepoPoof -> Active
        | MeepoRansack -> Passive
        | MiranaArrow -> Active
        | MiranaInvis -> Active
        | MiranaLeap -> Active
        | MiranaStarfall -> Active
        | MonkeyKingBoundlessStrike -> Active
        | MonkeyKingJinguMastery -> Passive
        | MonkeyKingMischief -> Active
        | MonkeyKingPrimalSpring -> Active
        | MonkeyKingPrimalSpringEarly -> Active
        | MonkeyKingTreeDance -> Active
        | MonkeyKingUntransform -> Active
        | MonkeyKingWukongsCommand -> Active
        | MorphlingAdaptiveStrikeAgi -> Active
        | MorphlingAdaptiveStrikeStr -> Active
        | MorphlingHybrid -> Active
        | MorphlingMorph -> Active
        | MorphlingMorphAgi -> Active
        | MorphlingMorphReplicate -> Active
        | MorphlingMorphStr -> Active
        | MorphlingReplicate -> Active
        | MorphlingWaveform -> Active
        | MudGolemHurlBoulder -> Active
        | MudGolemRockDestroy -> Passive
        | MudgolemCloakAura -> Passive
        | NagaSirenEnsnare -> Active
        | NagaSirenMirrorImage -> Active
        | NagaSirenRipTide -> Passive
        | NagaSirenSongOfTheSiren -> Active
        | NagaSirenSongOfTheSirenCancel -> Active
        | NecrolyteDeathPulse -> Active
        | NecrolyteHeartstopperAura -> Passive
        | NecrolyteReapersScythe -> Active
        | NecrolyteSadist -> Active
        | NecrolyteSadistStop -> Active
        | NecronomiconArcherAoe -> Passive
        | NecronomiconArcherManaBurn -> Active
        | NecronomiconArcherPurge -> Active
        | NecronomiconWarriorLastWill -> Passive
        | NecronomiconWarriorManaBurn -> Passive
        | NecronomiconWarriorSight -> Passive
        | NeutralSpellImmunity -> Passive
        | NevermoreDarkLord -> Passive
        | NevermoreNecromastery -> Passive
        | NevermoreRequiem -> Active
        | NevermoreShadowraze -> Active
        | NightStalkerCripplingFear -> Active
        | NightStalkerDarkness -> Active
        | NightStalkerHunterInTheNight -> Passive
        | NightStalkerVoid -> Active
        | NyxAssassinBurrow -> Active
        | NyxAssassinImpale -> Active
        | NyxAssassinManaBurn -> Active
        | NyxAssassinSpikedCarapace -> Active
        | NyxAssassinUnburrow -> Active
        | NyxAssassinVendetta -> Active
        | ObsidianDestroyerArcaneOrb -> Active
        | ObsidianDestroyerAstralImprisonment -> Active
        | ObsidianDestroyerEquilibrium -> Active
        | ObsidianDestroyerEssenceAura -> Passive
        | ObsidianDestroyerSanityEclipse -> Active
        | OgreMagiBloodlust -> Active
        | OgreMagiFireblast -> Active
        | OgreMagiFrostArmor -> Active
        | OgreMagiIgnite -> Active
        | OgreMagiMulticast -> Passive
        | OgreMagiUnrefinedFireblast -> Active
        | OmniknightDegenAura -> Passive
        | OmniknightGuardianAngel -> Active
        | OmniknightPacify -> Active
        | OmniknightPurification -> Active
        | OmniknightRepel -> Active
        | OracleFalsePromise -> Active
        | OracleFatesEdict -> Active
        | OracleFortunesEnd -> Active
        | OraclePurifyingFlames -> Active
        | PangolierGyroshell -> Active
        | PangolierGyroshellStop -> Active
        | PangolierHeartpiercer -> Passive
        | PangolierLuckyShot -> Passive
        | PangolierShieldCrash -> Active
        | PangolierSwashbuckle -> Active
        | PhantomAssassinBlur -> Active
        | PhantomAssassinCoupDeGrace -> Passive
        | PhantomAssassinPhantomStrike -> Active
        | PhantomAssassinStiflingDagger -> Active
        | PhantomLancerDoppelwalk -> Active
        | PhantomLancerJuxtapose -> Passive
        | PhantomLancerPhantomEdge -> Active
        | PhantomLancerSpiritLance -> Active
        | PhoenixFireSpirits -> Active
        | PhoenixIcarusDive -> Active
        | PhoenixIcarusDiveStop -> Active
        | PhoenixLaunchFireSpirit -> Active
        | PhoenixSunRay -> Active
        | PhoenixSunRayStop -> Active
        | PhoenixSunRayToggleMove -> Active
        | PhoenixSupernova -> Active
        | PolarFurbolgUrsaWarriorThunderClap -> Active
        | PuckDreamCoil -> Active
        | PuckEtherealJaunt -> Active
        | PuckIllusoryOrb -> Active
        | PuckPhaseShift -> Active
        | PuckWaningRift -> Active
        | PudgeDismember -> Active
        | PudgeFleshHeap -> Passive
        | PudgeMeatHook -> Active
        | PudgeRot -> Active
        | PugnaDecrepify -> Active
        | PugnaLifeDrain -> Active
        | PugnaNetherBlast -> Active
        | PugnaNetherWard -> Active
        | QueenofpainBlink -> Active
        | QueenofpainScreamOfPain -> Active
        | QueenofpainShadowStrike -> Active
        | QueenofpainSonicWave -> Active
        | RattletrapBatteryAssault -> Active
        | RattletrapHookshot -> Active
        | RattletrapPowerCogs -> Active
        | RattletrapRocketFlare -> Active
        | RazorEyeOfTheStorm -> Active
        | RazorPlasmaField -> Active
        | RazorStaticLink -> Active
        | RazorUnstableCurrent -> Passive
        | RikiBlinkStrike -> Active
        | RikiPermanentInvisibility -> Passive
        | RikiSmokeScreen -> Active
        | RikiTricksOfTheTrade -> Active
        | RoshanBash -> Passive
        | RoshanDevotion -> Passive
        | RoshanInherentBuffs -> Passive
        | RoshanSlam -> Active
        | RoshanSpellBlock -> Passive
        | RubickArcaneSupremacy -> Passive
        | RubickEmpty -> Passive
        | RubickFadeBolt -> Active
        | RubickHidden -> Active
        | RubickNullField -> Active
        | RubickSpellSteal -> Active
        | RubickTelekinesis -> Active
        | RubickTelekinesisLand -> Active
        | SandkingBurrowstrike -> Active
        | SandkingCausticFinale -> Passive
        | SandkingEpicenter -> Active
        | SandkingSandStorm -> Active
        | SatyrHellcallerShockwave -> Active
        | SatyrHellcallerUnholyAura -> Passive
        | SatyrSoulstealerManaBurn -> Active
        | SatyrTricksterPurge -> Active
        | ShadowDemonDemonicPurge -> Active
        | ShadowDemonDisruption -> Active
        | ShadowDemonShadowPoison -> Active
        | ShadowDemonShadowPoisonRelease -> Active
        | ShadowDemonSoulCatcher -> Active
        | ShadowShamanEtherShock -> Active
        | ShadowShamanMassSerpentWard -> Active
        | ShadowShamanShackles -> Active
        | ShadowShamanVoodoo -> Active
        | ShootFirework -> Active
        | ShredderChakram -> Active
        | ShredderReactiveArmor -> Passive
        | ShredderReturnChakram -> Active
        | ShredderTimberChain -> Active
        | ShredderWhirlingDeath -> Active
        | SilencerCurseOfTheSilent -> Active
        | SilencerGlaivesOfWisdom -> Active
        | SilencerGlobalSilence -> Active
        | SilencerLastWord -> Active
        | SkeletonKingHellfireBlast -> Active
        | SkeletonKingMortalStrike -> Active
        | SkeletonKingReincarnation -> Passive
        | SkeletonKingVampiricAura -> Active
        | SkywrathMageAncientSeal -> Active
        | SkywrathMageArcaneBolt -> Active
        | SkywrathMageConcussiveShot -> Active
        | SkywrathMageMysticFlare -> Active
        | SlardarAmplifyDamage -> Active
        | SlardarBash -> Passive
        | SlardarSlithereenCrush -> Active
        | SlardarSprint -> Active
        | SlarkDarkPact -> Active
        | SlarkEssenceShift -> Passive
        | SlarkPounce -> Active
        | SlarkShadowDance -> Active
        | SniperAssassinate -> Active
        | SniperHeadshot -> Passive
        | SniperShrapnel -> Active
        | SniperTakeAim -> Active
        | SpawnlordAura -> Passive
        | SpawnlordMasterBash -> Passive
        | SpawnlordMasterFreeze -> Active
        | SpawnlordMasterStomp -> Active
        | SpectreDesolate -> Passive
        | SpectreDispersion -> Passive
        | SpectreHaunt -> Active
        | SpectreReality -> Active
        | SpectreSpectralDagger -> Active
        | SpiritBreakerBulldoze -> Active
        | SpiritBreakerChargeOfDarkness -> Active
        | SpiritBreakerEmpoweringHaste -> Active
        | SpiritBreakerGreaterBash -> Passive
        | SpiritBreakerNetherStrike -> Active
        | StormSpiritBallLightning -> Active
        | StormSpiritElectricVortex -> Active
        | StormSpiritOverload -> Passive
        | StormSpiritStaticRemnant -> Active
        | SvenGodsStrength -> Active
        | SvenGreatCleave -> Passive
        | SvenStormBolt -> Active
        | SvenWarcry -> Active
        | TechiesFocusedDetonate -> Active
        | TechiesLandMines -> Active
        | TechiesMinefieldSign -> Active
        | TechiesRemoteMines -> Active
        | TechiesRemoteMinesSelfDetonate -> Active
        | TechiesStasisTrap -> Active
        | TechiesSuicide -> Active
        | TemplarAssassinMeld -> Active
        | TemplarAssassinPsiBlades -> Passive
        | TemplarAssassinPsionicTrap -> Active
        | TemplarAssassinRefraction -> Active
        | TemplarAssassinSelfTrap -> Active
        | TemplarAssassinTrap -> Active
        | TerrorbladeConjureImage -> Active
        | TerrorbladeMetamorphosis -> Active
        | TerrorbladeReflection -> Active
        | TerrorbladeSunder -> Active
        | TidehunterAnchorSmash -> Active
        | TidehunterGush -> Active
        | TidehunterKrakenShell -> Passive
        | TidehunterRavage -> Active
        | TinkerHeatSeekingMissile -> Active
        | TinkerLaser -> Active
        | TinkerMarchOfTheMachines -> Active
        | TinkerRearm -> Active
        | TinyAvalanche -> Active
        | TinyCraggyExterior -> Active
        | TinyGrow -> Passive
        | TinyToss -> Active
        | TinyTossTree -> Active
        | TornadoTempest -> Passive
        | TreantEyesInTheForest -> Active
        | TreantLeechSeed -> Active
        | TreantLivingArmor -> Active
        | TreantNaturesGuise -> Passive
        | TreantOvergrowth -> Active
        | TrollWarlordBattleTrance -> Active
        | TrollWarlordBerserkersRage -> Active
        | TrollWarlordFervor -> Passive
        | TrollWarlordWhirlingAxesMelee -> Active
        | TrollWarlordWhirlingAxesRanged -> Active
        | TuskFrozenSigil -> Active
        | TuskIceShards -> Active
        | TuskIceShardsStop -> Active
        | TuskLaunchSnowball -> Active
        | TuskSnowball -> Active
        | TuskTagTeam -> Active
        | TuskWalrusKick -> Active
        | TuskWalrusPunch -> Active
        | UndyingDecay -> Active
        | UndyingFleshGolem -> Active
        | UndyingSoulRip -> Active
        | UndyingTombstone -> Active
        | UndyingTombstoneZombieAura -> Passive
        | UndyingTombstoneZombieDeathstrike -> Passive
        | UrsaEarthshock -> Active
        | UrsaEnrage -> Active
        | UrsaFurySwipes -> Passive
        | UrsaOverpower -> Active
        | VengefulspiritCommandAura -> Passive
        | VengefulspiritMagicMissile -> Active
        | VengefulspiritNetherSwap -> Active
        | VengefulspiritWaveOfTerror -> Active
        | VenomancerPlagueWard -> Active
        | VenomancerPoisonNova -> Active
        | VenomancerPoisonSting -> Passive
        | VenomancerVenomousGale -> Active
        | ViperCorrosiveSkin -> Passive
        | ViperNethertoxin -> Active
        | ViperPoisonAttack -> Active
        | ViperViperStrike -> Active
        | VisageGraveChill -> Active
        | VisageGravekeepersCloak -> Passive
        | VisageSoulAssumption -> Active
        | VisageStoneFormSelfCast -> Active
        | VisageSummonFamiliars -> Active
        | VisageSummonFamiliarsStoneForm -> Active
        | WarlockFatalBonds -> Active
        | WarlockGolemFlamingFists -> Passive
        | WarlockGolemPermanentImmolation -> Passive
        | WarlockRainOfChaos -> Active
        | WarlockShadowWord -> Active
        | WarlockUpheaval -> Active
        | WeaverGeminateAttack -> Passive
        | WeaverShukuchi -> Active
        | WeaverTheSwarm -> Active
        | WeaverTimeLapse -> Active
        | WindrunnerFocusfire -> Active
        | WindrunnerPowershot -> Active
        | WindrunnerShackleshot -> Active
        | WindrunnerWindrun -> Active
        | WinterWyvernArcticBurn -> Active
        | WinterWyvernColdEmbrace -> Active
        | WinterWyvernSplinterBlast -> Active
        | WinterWyvernWintersCurse -> Active
        | WispOvercharge -> Active
        | WispRelocate -> Active
        | WispSpirits -> Active
        | WispSpiritsIn -> Active
        | WispSpiritsOut -> Active
        | WispTether -> Active
        | WispTetherBreak -> Active
        | WitchDoctorDeathWard -> Active
        | WitchDoctorMaledict -> Active
        | WitchDoctorParalyzingCask -> Active
        | WitchDoctorVoodooRestoration -> Active
        | ZuusArcLightning -> Active
        | ZuusCloud -> Active
        | ZuusLightningBolt -> Active
        | ZuusStaticField -> Passive
        | ZuusThundergodsWrath -> Active

    /// <summary>
    ///
    /// </summary>
    ///
    /// <param name="ability">
    ///     The Dota 2 ability to obtain am AbilityTargetType for
    /// </param>
    ///
    /// <returns>
    ///     The AbilityTargetType of the given Dota 2 ability.
    /// </returns>
    let getTargetType (ability : Ability) : AbilityTargetType =
        match ability with
        | AbaddonAphoticShield -> UnitTarget
        | AbaddonBorrowedTime -> NoTarget
        | AbaddonDeathCoil -> UnitTarget
        | AbaddonFrostmourne -> NoTarget
        | AbyssalUnderlordAtrophyAura -> NoTarget
        | AbyssalUnderlordCancelDarkRift -> NoTarget
        | AbyssalUnderlordDarkRift -> UnitTarget
        | AbyssalUnderlordFirestorm -> AreaTarget
        | AbyssalUnderlordPitOfMalice -> AreaTarget
        | AlchemistAcidSpray -> AreaTarget
        | AlchemistChemicalRage -> NoTarget
        | AlchemistGoblinsGreed -> NoTarget
        | AlchemistUnstableConcoction -> NoTarget
        | AlchemistUnstableConcoctionThrow -> AreaTarget
        | AlphaWolfCommandAura -> NoTarget
        | AlphaWolfCriticalStrike -> NoTarget
        | AncientApparitionChillingTouch -> UnitTarget
        | AncientApparitionColdFeet -> UnitTarget
        | AncientApparitionIceBlast -> AreaTarget
        | AncientApparitionIceBlastRelease -> NoTarget
        | AncientApparitionIceVortex -> AreaTarget
        | AncientGolemRockslide -> PointTarget
        | AntimageBlink -> PointTarget
        | AntimageCounterspell -> NoTarget
        | AntimageManaBreak -> NoTarget
        | AntimageManaVoid -> AreaTarget
        | AntimageSpellShield -> NoTarget
        | ArcWardenFlux -> UnitTarget
        | ArcWardenMagneticField -> AreaTarget
        | ArcWardenSparkWraith -> AreaTarget
        | ArcWardenTempestDouble -> NoTarget
        | AxeBattleHunger -> UnitTarget
        | AxeBerserkersCall -> NoTarget
        | AxeCounterHelix -> NoTarget
        | AxeCullingBlade -> UnitTarget
        | BackdoorProtection -> NoTarget
        | BackdoorProtectionInBase -> NoTarget
        | BaneBrainSap -> UnitTarget
        | BaneEnfeeble -> UnitTarget
        | BaneFiendsGrip -> UnitTarget
        | BaneNightmare -> UnitTarget
        | BaneNightmareEnd -> NoTarget
        | BatriderFirefly -> NoTarget
        | BatriderFlamebreak -> AreaTarget
        | BatriderFlamingLasso -> UnitTarget
        | BatriderStickyNapalm -> AreaTarget
        | BearEmpty -> NoTarget
        | BeastmasterBoarPoison -> NoTarget
        | BeastmasterCallOfTheWild -> NoTarget
        | BeastmasterCallOfTheWildBoar -> NoTarget
        | BeastmasterCallOfTheWildHawk -> AreaTarget
        | BeastmasterGreaterBoarPoison -> NoTarget
        | BeastmasterHawkInvisibility -> NoTarget
        | BeastmasterInnerBeast -> NoTarget
        | BeastmasterPrimalRoar -> UnitTarget
        | BeastmasterWildAxes -> PointTarget
        | BigThunderLizardFrenzy -> UnitTarget
        | BigThunderLizardSlam -> NoTarget
        | BigThunderLizardWardrumsAura -> NoTarget
        | BlackDragonDragonhideAura -> NoTarget
        | BlackDragonFireball -> AreaTarget
        | BlackDragonSplashAttack -> NoTarget
        | BloodseekerBloodBath -> AreaTarget
        | BloodseekerBloodrage -> UnitTarget
        | BloodseekerRupture -> UnitTarget
        | BloodseekerThirst -> NoTarget
        | BlueDragonspawnOverseerDevotionAura -> NoTarget
        | BlueDragonspawnOverseerEvasion -> NoTarget
        | BlueDragonspawnSorcererEvasion -> NoTarget
        | BountyHunterJinada -> NoTarget
        | BountyHunterShurikenToss -> UnitTarget
        | BountyHunterTrack -> UnitTarget
        | BountyHunterWindWalk -> NoTarget
        | BrewmasterCinderBrew -> AreaTarget
        | BrewmasterDrunkenBrawler -> NoTarget
        | BrewmasterDrunkenHaze -> UnitTarget
        | BrewmasterEarthHurlBoulder -> UnitTarget
        | BrewmasterEarthPulverize -> NoTarget
        | BrewmasterEarthSpellImmunity -> NoTarget
        | BrewmasterFirePermanentImmolation -> NoTarget
        | BrewmasterPrimalSplit -> NoTarget
        | BrewmasterStormCyclone -> UnitTarget
        | BrewmasterStormDispelMagic -> AreaTarget
        | BrewmasterStormWindWalk -> NoTarget
        | BrewmasterThunderClap -> NoTarget
        | BristlebackBristleback -> NoTarget
        | BristlebackQuillSpray -> NoTarget
        | BristlebackViscousNasalGoo -> UnitTarget
        | BristlebackWarpath -> NoTarget
        | BroodmotherIncapacitatingBite -> NoTarget
        | BroodmotherInsatiableHunger -> NoTarget
        | BroodmotherPoisonSting -> NoTarget
        | BroodmotherSpawnSpiderite -> NoTarget
        | BroodmotherSpawnSpiderlings -> UnitTarget
        | BroodmotherSpinWeb -> AreaTarget
        | BroodmotherSpinWebDestroy -> NoTarget
        | CentaurDoubleEdge -> UnitTarget
        | CentaurHoofStomp -> NoTarget
        | CentaurReturn -> NoTarget
        | CentaurStampede -> NoTarget
        | ChaosKnightChaosBolt -> UnitTarget
        | ChaosKnightChaosStrike -> NoTarget
        | ChaosKnightPhantasm -> NoTarget
        | ChaosKnightRealityRift -> UnitTarget
        | ChenDivineFavor -> UnitTarget
        | ChenHandOfGod -> NoTarget
        | ChenHolyPersuasion -> UnitTarget
        | ChenPenitence -> UnitTarget
        | ChenTestOfFaith -> UnitTarget
        | ChenTestOfFaithTeleport -> UnitTarget
        | ClinkzBurningArmy -> PointTarget
        | ClinkzDeathPact -> UnitTarget
        | ClinkzSearingArrows -> UnitTarget
        | ClinkzStrafe -> NoTarget
        | ClinkzWindWalk -> NoTarget
        | CnyBeastForceAttack -> UnitTarget
        | CnyBeastTeleport -> NoTarget
        | ConsumableHidden -> NoTarget
        | CourierAutodeliver -> NoTarget
        | CourierBurst -> NoTarget
        | CourierGoToEnemySecretshop -> NoTarget
        | CourierGoToSecretshop -> NoTarget
        | CourierGoToSideshop -> NoTarget
        | CourierMorph -> NoTarget
        | CourierReturnStashItems -> NoTarget
        | CourierReturnToBase -> NoTarget
        | CourierShield -> NoTarget
        | CourierTakeStashAndTransferItems -> NoTarget
        | CourierTakeStashItems -> NoTarget
        | CourierTransferItems -> NoTarget
        | CourierTransferItemsToOtherPlayer -> UnitTarget
        | CrystalMaidenBrillianceAura -> NoTarget
        | CrystalMaidenCrystalNova -> AreaTarget
        | CrystalMaidenFreezingField -> NoTarget
        | CrystalMaidenFrostbite -> UnitTarget
        | DarkSeerIonShell -> UnitTarget
        | DarkSeerSurge -> UnitTarget
        | DarkSeerVacuum -> AreaTarget
        | DarkSeerWallOfReplica -> PointTarget
        | DarkTrollWarlordEnsnare -> UnitTarget
        | DarkTrollWarlordRaiseDead -> NoTarget
        | DarkWillowBedlam -> NoTarget
        | DarkWillowBrambleMaze -> AreaTarget
        | DarkWillowCursedCrown -> UnitTarget
        | DarkWillowShadowRealm -> NoTarget
        | DarkWillowTerrorize -> AreaTarget
        | DazzleBadJuju -> NoTarget
        | DazzlePoisonTouch -> UnitTarget
        | DazzleShadowWave -> UnitTarget
        | DazzleShallowGrave -> UnitTarget
        | DazzleWeave -> AreaTarget
        | DeathProphetCarrionSwarm -> PointTarget
        | DeathProphetExorcism -> NoTarget
        | DeathProphetSilence -> AreaTarget
        | DeathProphetSpiritSiphon -> UnitTarget
        | DeathProphetWitchcraft -> NoTarget
        | DisruptorGlimpse -> UnitTarget
        | DisruptorKineticField -> AreaTarget
        | DisruptorStaticStorm -> AreaTarget
        | DisruptorThunderStrike -> UnitTarget
        | DoomBringerDevour -> UnitTarget
        | DoomBringerDoom -> UnitTarget
        | DoomBringerEmpty -> NoTarget
        | DoomBringerInfernalBlade -> UnitTarget
        | DoomBringerScorchedEarth -> NoTarget
        | DotaBaseAbility -> UnitTarget
        | DragonKnightBreatheFire -> UnitTarget
        | DragonKnightDragonBlood -> NoTarget
        | DragonKnightDragonTail -> UnitTarget
        | DragonKnightElderDragonForm -> NoTarget
        | DrowRangerFrostArrows -> UnitTarget
        | DrowRangerMarksmanship -> NoTarget
        | DrowRangerSilence -> AreaTarget
        | DrowRangerTrueshot -> NoTarget
        | DrowRangerWaveOfSilence -> PointTarget
        | EarthSpiritBoulderSmash -> UnitTarget
        | EarthSpiritGeomagneticGrip -> UnitTarget
        | EarthSpiritMagnetize -> NoTarget
        | EarthSpiritPetrify -> UnitTarget
        | EarthSpiritRollingBoulder -> PointTarget
        | EarthSpiritStoneCaller -> PointTarget
        | EarthshakerAftershock -> NoTarget
        | EarthshakerEchoSlam -> NoTarget
        | EarthshakerEnchantTotem -> NoTarget
        | EarthshakerFissure -> PointTarget
        | ElderTitanAncestralSpirit -> AreaTarget
        | ElderTitanEarthSplitter -> PointTarget
        | ElderTitanEchoStomp -> NoTarget
        | ElderTitanEchoStompSpirit -> NoTarget
        | ElderTitanNaturalOrder -> NoTarget
        | ElderTitanNaturalOrderSpirit -> NoTarget
        | ElderTitanReturnSpirit -> NoTarget
        | EmberSpiritActivateFireRemnant -> PointTarget
        | EmberSpiritFireRemnant -> PointTarget
        | EmberSpiritFlameGuard -> NoTarget
        | EmberSpiritSearingChains -> NoTarget
        | EmberSpiritSleightOfFist -> AreaTarget
        | EnchantressEnchant -> UnitTarget
        | EnchantressImpetus -> UnitTarget
        | EnchantressNaturesAttendants -> NoTarget
        | EnchantressUntouchable -> NoTarget
        | EnigmaBlackHole -> AreaTarget
        | EnigmaDemonicConversion -> UnitTarget
        | EnigmaMalefice -> UnitTarget
        | EnigmaMidnightPulse -> AreaTarget
        | EnragedWildkinTornado -> PointTarget
        | EnragedWildkinToughnessAura -> NoTarget
        | FacelessVoidBacktrack -> NoTarget
        | FacelessVoidChronosphere -> AreaTarget
        | FacelessVoidTimeDilation -> NoTarget
        | FacelessVoidTimeLock -> NoTarget
        | FacelessVoidTimeWalk -> PointTarget
        | FillerAbility -> NoTarget
        | ForestTrollHighPriestHeal -> UnitTarget
        | ForestTrollHighPriestManaAura -> NoTarget
        | ForgedSpiritMeltingStrike -> NoTarget
        | FrostivusDecorateTree -> UnitTarget
        | FrostivusFestiveFirework -> AreaTarget
        | FrostivusSummonSnowman -> PointTarget
        | FrostivusThrowSnowball -> UnitTarget
        | FurionForceOfNature -> AreaTarget
        | FurionSprout -> PointTarget
        | FurionTeleportation -> PointTarget
        | FurionWrathOfNature -> PointTarget
        | GenericHidden -> NoTarget
        | GhostFrostAttack -> NoTarget
        | GiantWolfCriticalStrike -> NoTarget
        | GnollAssassinEnvenomedWeapon -> NoTarget
        | GraniteGolemBash -> NoTarget
        | GraniteGolemHpAura -> NoTarget
        | GrimstrokeDarkArtistry -> PointTarget
        | GrimstrokeInkCreature -> UnitTarget
        | GrimstrokeSoulChain -> AreaTarget
        | GrimstrokeSpiritWalk -> UnitTarget
        | GyrocopterCallDown -> AreaTarget
        | GyrocopterFlakCannon -> NoTarget
        | GyrocopterHomingMissile -> UnitTarget
        | GyrocopterRocketBarrage -> NoTarget
        | HarpyStormChainLightning -> UnitTarget
        | HealingCampfire -> AreaTarget
        | HuskarBerserkersBlood -> NoTarget
        | HuskarBurningSpear -> UnitTarget
        | HuskarInnerFire -> NoTarget
        | HuskarInnerVitality -> UnitTarget
        | HuskarLifeBreak -> UnitTarget
        | InvokerAlacrity -> UnitTarget
        | InvokerChaosMeteor -> PointTarget
        | InvokerColdSnap -> UnitTarget
        | InvokerDeafeningBlast -> PointTarget
        | InvokerEmp -> AreaTarget
        | InvokerEmpty -> NoTarget
        | InvokerExort -> NoTarget
        | InvokerForgeSpirit -> NoTarget
        | InvokerGhostWalk -> NoTarget
        | InvokerIceWall -> NoTarget
        | InvokerInvoke -> NoTarget
        | InvokerQuas -> NoTarget
        | InvokerSunStrike -> AreaTarget
        | InvokerTornado -> PointTarget
        | InvokerWex -> NoTarget
        | JakiroDualBreath -> UnitTarget
        | JakiroIcePath -> PointTarget
        | JakiroLiquidFire -> UnitTarget
        | JakiroMacropyre -> PointTarget
        | JuggernautBladeDance -> NoTarget
        | JuggernautBladeFury -> NoTarget
        | JuggernautHealingWard -> AreaTarget
        | JuggernautOmniSlash -> UnitTarget
        | KeeperOfTheLightBlindingLight -> AreaTarget
        | KeeperOfTheLightChakraMagic -> UnitTarget
        | KeeperOfTheLightEmpty -> NoTarget
        | KeeperOfTheLightIlluminate -> PointTarget
        | KeeperOfTheLightIlluminateEnd -> NoTarget
        | KeeperOfTheLightManaLeak -> UnitTarget
        | KeeperOfTheLightRecall -> NoTarget
        | KeeperOfTheLightSpiritForm -> NoTarget
        | KeeperOfTheLightSpiritFormIlluminate -> PointTarget
        | KeeperOfTheLightSpiritFormIlluminateEnd -> NoTarget
        | KeeperOfTheLightWillOWisp -> AreaTarget
        | KoboldTaskmasterSpeedAura -> NoTarget
        | KunkkaGhostship -> PointTarget
        | KunkkaReturn -> NoTarget
        | KunkkaTidebringer -> UnitTarget
        | KunkkaTorrent -> AreaTarget
        | KunkkaXMarksTheSpot -> UnitTarget
        | LegionCommanderDuel -> UnitTarget
        | LegionCommanderMomentOfCourage -> NoTarget
        | LegionCommanderOverwhelmingOdds -> AreaTarget
        | LegionCommanderPressTheAttack -> UnitTarget
        | LeshracDiabolicEdict -> NoTarget
        | LeshracLightningStorm -> UnitTarget
        | LeshracPulseNova -> NoTarget
        | LeshracSplitEarth -> AreaTarget
        | LichChainFrost -> UnitTarget
        | LichDarkSorcery -> NoTarget
        | LichFrostArmor -> UnitTarget
        | LichFrostAura -> NoTarget
        | LichFrostNova -> AreaTarget
        | LichFrostShield -> UnitTarget
        | LichSinisterGaze -> UnitTarget
        | LifeStealerAssimilate -> UnitTarget
        | LifeStealerAssimilateEject -> NoTarget
        | LifeStealerConsume -> NoTarget
        | LifeStealerControl -> NoTarget
        | LifeStealerEmpty -> NoTarget
        | LifeStealerFeast -> NoTarget
        | LifeStealerInfest -> UnitTarget
        | LifeStealerOpenWounds -> UnitTarget
        | LifeStealerRage -> NoTarget
        | LinaDragonSlave -> PointTarget
        | LinaFierySoul -> NoTarget
        | LinaLagunaBlade -> UnitTarget
        | LinaLightStrikeArray -> AreaTarget
        | LionFingerOfDeath -> AreaTarget
        | LionImpale -> UnitTarget
        | LionManaDrain -> UnitTarget
        | LionVoodoo -> UnitTarget
        | LoneDruidRabid -> NoTarget
        | LoneDruidSavageRoar -> NoTarget
        | LoneDruidSavageRoarBear -> NoTarget
        | LoneDruidSpiritBear -> NoTarget
        | LoneDruidSpiritBearDefender -> NoTarget
        | LoneDruidSpiritBearDemolish -> NoTarget
        | LoneDruidSpiritBearEntangle -> NoTarget
        | LoneDruidSpiritBearReturn -> NoTarget
        | LoneDruidSpiritLink -> NoTarget
        | LoneDruidTrueForm -> NoTarget
        | LoneDruidTrueFormBattleCry -> NoTarget
        | LoneDruidTrueFormDruid -> NoTarget
        | LunaEclipse -> NoTarget
        | LunaLucentBeam -> UnitTarget
        | LunaLunarBlessing -> NoTarget
        | LunaMoonGlaive -> NoTarget
        | LycanFeralImpulse -> NoTarget
        | LycanHowl -> NoTarget
        | LycanShapeshift -> NoTarget
        | LycanSummonWolves -> NoTarget
        | LycanSummonWolvesCriticalStrike -> NoTarget
        | LycanSummonWolvesInvisibility -> NoTarget
        | MagnataurEmpower -> UnitTarget
        | MagnataurReversePolarity -> NoTarget
        | MagnataurShockwave -> PointTarget
        | MagnataurSkewer -> PointTarget
        | MarsArenaOfBlood -> AreaTarget
        | MarsBulwark -> NoTarget
        | MarsGodsRebuke -> PointTarget
        | MarsSpear -> PointTarget
        | MedusaManaShield -> NoTarget
        | MedusaMysticSnake -> UnitTarget
        | MedusaSplitShot -> NoTarget
        | MedusaStoneGaze -> NoTarget
        | MeepoDividedWeStand -> NoTarget
        | MeepoEarthbind -> AreaTarget
        | MeepoGeostrike -> NoTarget
        | MeepoPoof -> PointTarget
        | MeepoRansack -> NoTarget
        | MiranaArrow -> PointTarget
        | MiranaInvis -> NoTarget
        | MiranaLeap -> NoTarget
        | MiranaStarfall -> NoTarget
        | MonkeyKingBoundlessStrike -> PointTarget
        | MonkeyKingJinguMastery -> NoTarget
        | MonkeyKingMischief -> NoTarget
        | MonkeyKingPrimalSpring -> AreaTarget
        | MonkeyKingPrimalSpringEarly -> NoTarget
        | MonkeyKingTreeDance -> UnitTarget
        | MonkeyKingUntransform -> NoTarget
        | MonkeyKingWukongsCommand -> AreaTarget
        | MorphlingAdaptiveStrikeAgi -> UnitTarget
        | MorphlingAdaptiveStrikeStr -> UnitTarget
        | MorphlingHybrid -> UnitTarget
        | MorphlingMorph -> NoTarget
        | MorphlingMorphAgi -> NoTarget
        | MorphlingMorphReplicate -> NoTarget
        | MorphlingMorphStr -> NoTarget
        | MorphlingReplicate -> UnitTarget
        | MorphlingWaveform -> PointTarget
        | MudGolemHurlBoulder -> UnitTarget
        | MudGolemRockDestroy -> NoTarget
        | MudgolemCloakAura -> NoTarget
        | NagaSirenEnsnare -> UnitTarget
        | NagaSirenMirrorImage -> NoTarget
        | NagaSirenRipTide -> NoTarget
        | NagaSirenSongOfTheSiren -> NoTarget
        | NagaSirenSongOfTheSirenCancel -> NoTarget
        | NecrolyteDeathPulse -> NoTarget
        | NecrolyteHeartstopperAura -> NoTarget
        | NecrolyteReapersScythe -> UnitTarget
        | NecrolyteSadist -> NoTarget
        | NecrolyteSadistStop -> NoTarget
        | NecronomiconArcherAoe -> NoTarget
        | NecronomiconArcherManaBurn -> UnitTarget
        | NecronomiconArcherPurge -> UnitTarget
        | NecronomiconWarriorLastWill -> NoTarget
        | NecronomiconWarriorManaBurn -> NoTarget
        | NecronomiconWarriorSight -> NoTarget
        | NeutralSpellImmunity -> NoTarget
        | NevermoreDarkLord -> NoTarget
        | NevermoreNecromastery -> NoTarget
        | NevermoreRequiem -> NoTarget
        | NevermoreShadowraze -> NoTarget
        | NightStalkerCripplingFear -> NoTarget
        | NightStalkerDarkness -> NoTarget
        | NightStalkerHunterInTheNight -> NoTarget
        | NightStalkerVoid -> UnitTarget
        | NyxAssassinBurrow -> NoTarget
        | NyxAssassinImpale -> PointTarget
        | NyxAssassinManaBurn -> UnitTarget
        | NyxAssassinSpikedCarapace -> NoTarget
        | NyxAssassinUnburrow -> NoTarget
        | NyxAssassinVendetta -> NoTarget
        | ObsidianDestroyerArcaneOrb -> UnitTarget
        | ObsidianDestroyerAstralImprisonment -> UnitTarget
        | ObsidianDestroyerEquilibrium -> NoTarget
        | ObsidianDestroyerEssenceAura -> NoTarget
        | ObsidianDestroyerSanityEclipse -> AreaTarget
        | OgreMagiBloodlust -> UnitTarget
        | OgreMagiFireblast -> UnitTarget
        | OgreMagiFrostArmor -> UnitTarget
        | OgreMagiIgnite -> AreaTarget
        | OgreMagiMulticast -> NoTarget
        | OgreMagiUnrefinedFireblast -> UnitTarget
        | OmniknightDegenAura -> NoTarget
        | OmniknightGuardianAngel -> NoTarget
        | OmniknightPacify -> AreaTarget
        | OmniknightPurification -> AreaTarget
        | OmniknightRepel -> UnitTarget
        | OracleFalsePromise -> UnitTarget
        | OracleFatesEdict -> UnitTarget
        | OracleFortunesEnd -> AreaTarget
        | OraclePurifyingFlames -> UnitTarget
        | PangolierGyroshell -> NoTarget
        | PangolierGyroshellStop -> NoTarget
        | PangolierHeartpiercer -> NoTarget
        | PangolierLuckyShot -> NoTarget
        | PangolierShieldCrash -> NoTarget
        | PangolierSwashbuckle -> PointTarget
        | PhantomAssassinBlur -> NoTarget
        | PhantomAssassinCoupDeGrace -> NoTarget
        | PhantomAssassinPhantomStrike -> UnitTarget
        | PhantomAssassinStiflingDagger -> UnitTarget
        | PhantomLancerDoppelwalk -> AreaTarget
        | PhantomLancerJuxtapose -> NoTarget
        | PhantomLancerPhantomEdge -> NoTarget
        | PhantomLancerSpiritLance -> UnitTarget
        | PhoenixFireSpirits -> NoTarget
        | PhoenixIcarusDive -> PointTarget
        | PhoenixIcarusDiveStop -> NoTarget
        | PhoenixLaunchFireSpirit -> AreaTarget
        | PhoenixSunRay -> PointTarget
        | PhoenixSunRayStop -> NoTarget
        | PhoenixSunRayToggleMove -> NoTarget
        | PhoenixSupernova -> NoTarget
        | PolarFurbolgUrsaWarriorThunderClap -> NoTarget
        | PuckDreamCoil -> AreaTarget
        | PuckEtherealJaunt -> NoTarget
        | PuckIllusoryOrb -> PointTarget
        | PuckPhaseShift -> NoTarget
        | PuckWaningRift -> NoTarget
        | PudgeDismember -> UnitTarget
        | PudgeFleshHeap -> NoTarget
        | PudgeMeatHook -> PointTarget
        | PudgeRot -> NoTarget
        | PugnaDecrepify -> UnitTarget
        | PugnaLifeDrain -> UnitTarget
        | PugnaNetherBlast -> AreaTarget
        | PugnaNetherWard -> PointTarget
        | QueenofpainBlink -> PointTarget
        | QueenofpainScreamOfPain -> NoTarget
        | QueenofpainShadowStrike -> UnitTarget
        | QueenofpainSonicWave -> PointTarget
        | RattletrapBatteryAssault -> NoTarget
        | RattletrapHookshot -> PointTarget
        | RattletrapPowerCogs -> NoTarget
        | RattletrapRocketFlare -> AreaTarget
        | RazorEyeOfTheStorm -> NoTarget
        | RazorPlasmaField -> NoTarget
        | RazorStaticLink -> UnitTarget
        | RazorUnstableCurrent -> NoTarget
        | RikiBlinkStrike -> UnitTarget
        | RikiPermanentInvisibility -> NoTarget
        | RikiSmokeScreen -> AreaTarget
        | RikiTricksOfTheTrade -> NoTarget
        | RoshanBash -> NoTarget
        | RoshanDevotion -> NoTarget
        | RoshanInherentBuffs -> NoTarget
        | RoshanSlam -> NoTarget
        | RoshanSpellBlock -> NoTarget
        | RubickArcaneSupremacy -> NoTarget
        | RubickEmpty -> NoTarget
        | RubickFadeBolt -> UnitTarget
        | RubickHidden -> NoTarget
        | RubickNullField -> NoTarget
        | RubickSpellSteal -> UnitTarget
        | RubickTelekinesis -> UnitTarget
        | RubickTelekinesisLand -> AreaTarget
        | SandkingBurrowstrike -> UnitTarget
        | SandkingCausticFinale -> NoTarget
        | SandkingEpicenter -> NoTarget
        | SandkingSandStorm -> NoTarget
        | SatyrHellcallerShockwave -> PointTarget
        | SatyrHellcallerUnholyAura -> NoTarget
        | SatyrSoulstealerManaBurn -> UnitTarget
        | SatyrTricksterPurge -> UnitTarget
        | ShadowDemonDemonicPurge -> UnitTarget
        | ShadowDemonDisruption -> UnitTarget
        | ShadowDemonShadowPoison -> AreaTarget
        | ShadowDemonShadowPoisonRelease -> NoTarget
        | ShadowDemonSoulCatcher -> AreaTarget
        | ShadowShamanEtherShock -> UnitTarget
        | ShadowShamanMassSerpentWard -> AreaTarget
        | ShadowShamanShackles -> UnitTarget
        | ShadowShamanVoodoo -> UnitTarget
        | ShootFirework -> PointTarget
        | ShredderChakram -> AreaTarget
        | ShredderReactiveArmor -> NoTarget
        | ShredderReturnChakram -> NoTarget
        | ShredderTimberChain -> PointTarget
        | ShredderWhirlingDeath -> NoTarget
        | SilencerCurseOfTheSilent -> AreaTarget
        | SilencerGlaivesOfWisdom -> UnitTarget
        | SilencerGlobalSilence -> NoTarget
        | SilencerLastWord -> UnitTarget
        | SkeletonKingHellfireBlast -> UnitTarget
        | SkeletonKingMortalStrike -> NoTarget
        | SkeletonKingReincarnation -> NoTarget
        | SkeletonKingVampiricAura -> NoTarget
        | SkywrathMageAncientSeal -> UnitTarget
        | SkywrathMageArcaneBolt -> UnitTarget
        | SkywrathMageConcussiveShot -> NoTarget
        | SkywrathMageMysticFlare -> AreaTarget
        | SlardarAmplifyDamage -> UnitTarget
        | SlardarBash -> NoTarget
        | SlardarSlithereenCrush -> NoTarget
        | SlardarSprint -> NoTarget
        | SlarkDarkPact -> NoTarget
        | SlarkEssenceShift -> NoTarget
        | SlarkPounce -> NoTarget
        | SlarkShadowDance -> NoTarget
        | SniperAssassinate -> UnitTarget
        | SniperHeadshot -> NoTarget
        | SniperShrapnel -> AreaTarget
        | SniperTakeAim -> NoTarget
        | SpawnlordAura -> NoTarget
        | SpawnlordMasterBash -> NoTarget
        | SpawnlordMasterFreeze -> UnitTarget
        | SpawnlordMasterStomp -> NoTarget
        | SpectreDesolate -> NoTarget
        | SpectreDispersion -> NoTarget
        | SpectreHaunt -> NoTarget
        | SpectreReality -> PointTarget
        | SpectreSpectralDagger -> UnitTarget
        | SpiritBreakerBulldoze -> NoTarget
        | SpiritBreakerChargeOfDarkness -> UnitTarget
        | SpiritBreakerEmpoweringHaste -> NoTarget
        | SpiritBreakerGreaterBash -> NoTarget
        | SpiritBreakerNetherStrike -> UnitTarget
        | StormSpiritBallLightning -> PointTarget
        | StormSpiritElectricVortex -> UnitTarget
        | StormSpiritOverload -> NoTarget
        | StormSpiritStaticRemnant -> NoTarget
        | SvenGodsStrength -> NoTarget
        | SvenGreatCleave -> NoTarget
        | SvenStormBolt -> AreaTarget
        | SvenWarcry -> NoTarget
        | TechiesFocusedDetonate -> AreaTarget
        | TechiesLandMines -> AreaTarget
        | TechiesMinefieldSign -> AreaTarget
        | TechiesRemoteMines -> PointTarget
        | TechiesRemoteMinesSelfDetonate -> NoTarget
        | TechiesStasisTrap -> AreaTarget
        | TechiesSuicide -> AreaTarget
        | TemplarAssassinMeld -> NoTarget
        | TemplarAssassinPsiBlades -> NoTarget
        | TemplarAssassinPsionicTrap -> PointTarget
        | TemplarAssassinRefraction -> NoTarget
        | TemplarAssassinSelfTrap -> NoTarget
        | TemplarAssassinTrap -> NoTarget
        | TerrorbladeConjureImage -> NoTarget
        | TerrorbladeMetamorphosis -> NoTarget
        | TerrorbladeReflection -> NoTarget
        | TerrorbladeSunder -> UnitTarget
        | TidehunterAnchorSmash -> NoTarget
        | TidehunterGush -> UnitTarget
        | TidehunterKrakenShell -> NoTarget
        | TidehunterRavage -> NoTarget
        | TinkerHeatSeekingMissile -> NoTarget
        | TinkerLaser -> UnitTarget
        | TinkerMarchOfTheMachines -> AreaTarget
        | TinkerRearm -> NoTarget
        | TinyAvalanche -> AreaTarget
        | TinyCraggyExterior -> UnitTarget
        | TinyGrow -> NoTarget
        | TinyToss -> AreaTarget
        | TinyTossTree -> PointTarget
        | TornadoTempest -> NoTarget
        | TreantEyesInTheForest -> UnitTarget
        | TreantLeechSeed -> UnitTarget
        | TreantLivingArmor -> PointTarget
        | TreantNaturesGuise -> NoTarget
        | TreantOvergrowth -> NoTarget
        | TrollWarlordBattleTrance -> NoTarget
        | TrollWarlordBerserkersRage -> NoTarget
        | TrollWarlordFervor -> NoTarget
        | TrollWarlordWhirlingAxesMelee -> NoTarget
        | TrollWarlordWhirlingAxesRanged -> PointTarget
        | TuskFrozenSigil -> NoTarget
        | TuskIceShards -> PointTarget
        | TuskIceShardsStop -> NoTarget
        | TuskLaunchSnowball -> NoTarget
        | TuskSnowball -> UnitTarget
        | TuskTagTeam -> NoTarget
        | TuskWalrusKick -> UnitTarget
        | TuskWalrusPunch -> UnitTarget
        | UndyingDecay -> AreaTarget
        | UndyingFleshGolem -> NoTarget
        | UndyingSoulRip -> UnitTarget
        | UndyingTombstone -> PointTarget
        | UndyingTombstoneZombieAura -> NoTarget
        | UndyingTombstoneZombieDeathstrike -> NoTarget
        | UrsaEarthshock -> NoTarget
        | UrsaEnrage -> NoTarget
        | UrsaFurySwipes -> NoTarget
        | UrsaOverpower -> NoTarget
        | VengefulspiritCommandAura -> NoTarget
        | VengefulspiritMagicMissile -> UnitTarget
        | VengefulspiritNetherSwap -> UnitTarget
        | VengefulspiritWaveOfTerror -> PointTarget
        | VenomancerPlagueWard -> PointTarget
        | VenomancerPoisonNova -> NoTarget
        | VenomancerPoisonSting -> NoTarget
        | VenomancerVenomousGale -> AreaTarget
        | ViperCorrosiveSkin -> NoTarget
        | ViperNethertoxin -> AreaTarget
        | ViperPoisonAttack -> UnitTarget
        | ViperViperStrike -> UnitTarget
        | VisageGraveChill -> UnitTarget
        | VisageGravekeepersCloak -> NoTarget
        | VisageSoulAssumption -> UnitTarget
        | VisageStoneFormSelfCast -> NoTarget
        | VisageSummonFamiliars -> NoTarget
        | VisageSummonFamiliarsStoneForm -> AreaTarget
        | WarlockFatalBonds -> UnitTarget
        | WarlockGolemFlamingFists -> NoTarget
        | WarlockGolemPermanentImmolation -> NoTarget
        | WarlockRainOfChaos -> AreaTarget
        | WarlockShadowWord -> UnitTarget
        | WarlockUpheaval -> AreaTarget
        | WeaverGeminateAttack -> NoTarget
        | WeaverShukuchi -> NoTarget
        | WeaverTheSwarm -> PointTarget
        | WeaverTimeLapse -> NoTarget
        | WindrunnerFocusfire -> UnitTarget
        | WindrunnerPowershot -> PointTarget
        | WindrunnerShackleshot -> UnitTarget
        | WindrunnerWindrun -> NoTarget
        | WinterWyvernArcticBurn -> NoTarget
        | WinterWyvernColdEmbrace -> UnitTarget
        | WinterWyvernSplinterBlast -> AreaTarget
        | WinterWyvernWintersCurse -> AreaTarget
        | WispOvercharge -> NoTarget
        | WispRelocate -> PointTarget
        | WispSpirits -> NoTarget
        | WispSpiritsIn -> NoTarget
        | WispSpiritsOut -> NoTarget
        | WispTether -> UnitTarget
        | WispTetherBreak -> NoTarget
        | WitchDoctorDeathWard -> PointTarget
        | WitchDoctorMaledict -> AreaTarget
        | WitchDoctorParalyzingCask -> UnitTarget
        | WitchDoctorVoodooRestoration -> NoTarget
        | ZuusArcLightning -> UnitTarget
        | ZuusCloud -> AreaTarget
        | ZuusLightningBolt -> PointTarget
        | ZuusStaticField -> NoTarget
        | ZuusThundergodsWrath -> NoTarget

    /// <summary>
    ///
    /// </summary>
    ///
    /// <param name="ability">
    ///     The Dota 2 ability to obtain an area of effect for
    /// </param>
    ///
    /// <returns>
    ///     The area of effect as a Distance for a given Ability
    /// </returns>
    let getEffectRadius (ability : Ability) : Distance =
        match ability with
        | AbaddonAphoticShield -> Distance.fromIntLiteral 0
        | AbaddonBorrowedTime -> Distance.fromIntLiteral 0
        | AbaddonDeathCoil -> Distance.fromIntLiteral 0
        | AbaddonFrostmourne -> Distance.fromIntLiteral 0
        | AbyssalUnderlordAtrophyAura -> Distance.fromIntLiteral 1200
        | AbyssalUnderlordCancelDarkRift -> Distance.fromIntLiteral 0
        | AbyssalUnderlordDarkRift -> Distance.fromIntLiteral 0
        | AbyssalUnderlordFirestorm -> Distance.fromIntLiteral 400
        | AbyssalUnderlordPitOfMalice -> Distance.fromIntLiteral 375
        | AlchemistAcidSpray -> Distance.fromIntLiteral 475
        | AlchemistChemicalRage -> Distance.fromIntLiteral 0
        | AlchemistGoblinsGreed -> Distance.fromIntLiteral 0
        | AlchemistUnstableConcoction -> Distance.fromIntLiteral 0
        | AlchemistUnstableConcoctionThrow -> Distance.fromIntLiteral 0
        | AlphaWolfCommandAura -> Distance.fromIntLiteral 1200
        | AlphaWolfCriticalStrike -> Distance.fromIntLiteral 0
        | AncientApparitionChillingTouch -> Distance.fromIntLiteral 0
        | AncientApparitionColdFeet -> Distance.fromIntLiteral 0
        | AncientApparitionIceBlast -> Distance.fromIntLiteral 0
        | AncientApparitionIceBlastRelease -> Distance.fromIntLiteral 0
        | AncientApparitionIceVortex -> Distance.fromIntLiteral 275
        | AncientGolemRockslide -> Distance.fromIntLiteral 0
        | AntimageBlink -> Distance.fromIntLiteral 0
        | AntimageCounterspell -> Distance.fromIntLiteral 0
        | AntimageManaBreak -> Distance.fromIntLiteral 0
        | AntimageManaVoid -> Distance.fromIntLiteral 500
        | AntimageSpellShield -> Distance.fromIntLiteral 0
        | ArcWardenFlux -> Distance.fromIntLiteral 0
        | ArcWardenMagneticField -> Distance.fromIntLiteral 300
        | ArcWardenSparkWraith -> Distance.fromIntLiteral 0
        | ArcWardenTempestDouble -> Distance.fromIntLiteral 0
        | AxeBattleHunger -> Distance.fromIntLiteral 0
        | AxeBerserkersCall -> Distance.fromIntLiteral 300
        | AxeCounterHelix -> Distance.fromIntLiteral 275
        | AxeCullingBlade -> Distance.fromIntLiteral 0
        | BackdoorProtection -> Distance.fromIntLiteral 900
        | BackdoorProtectionInBase -> Distance.fromIntLiteral 0
        | BaneBrainSap -> Distance.fromIntLiteral 0
        | BaneEnfeeble -> Distance.fromIntLiteral 0
        | BaneFiendsGrip -> Distance.fromIntLiteral 0
        | BaneNightmare -> Distance.fromIntLiteral 0
        | BaneNightmareEnd -> Distance.fromIntLiteral 0
        | BatriderFirefly -> Distance.fromIntLiteral 200
        | BatriderFlamebreak -> Distance.fromIntLiteral 375
        | BatriderFlamingLasso -> Distance.fromIntLiteral 0
        | BatriderStickyNapalm -> Distance.fromIntLiteral 375
        | BearEmpty -> Distance.fromIntLiteral 0
        | BeastmasterBoarPoison -> Distance.fromIntLiteral 0
        | BeastmasterCallOfTheWild -> Distance.fromIntLiteral 0
        | BeastmasterCallOfTheWildBoar -> Distance.fromIntLiteral 0
        | BeastmasterCallOfTheWildHawk -> Distance.fromIntLiteral 0
        | BeastmasterGreaterBoarPoison -> Distance.fromIntLiteral 0
        | BeastmasterHawkInvisibility -> Distance.fromIntLiteral 0
        | BeastmasterInnerBeast -> Distance.fromIntLiteral 1200
        | BeastmasterPrimalRoar -> Distance.fromIntLiteral 0
        | BeastmasterWildAxes -> Distance.fromIntLiteral 175
        | BigThunderLizardFrenzy -> Distance.fromIntLiteral 0
        | BigThunderLizardSlam -> Distance.fromIntLiteral 250
        | BigThunderLizardWardrumsAura -> Distance.fromIntLiteral 1200
        | BlackDragonDragonhideAura -> Distance.fromIntLiteral 0
        | BlackDragonFireball -> Distance.fromIntLiteral 300
        | BlackDragonSplashAttack -> Distance.fromIntLiteral 0
        | BloodseekerBloodBath -> Distance.fromIntLiteral 0
        | BloodseekerBloodrage -> Distance.fromIntLiteral 0
        | BloodseekerRupture -> Distance.fromIntLiteral 0
        | BloodseekerThirst -> Distance.fromIntLiteral 0
        | BlueDragonspawnOverseerDevotionAura -> Distance.fromIntLiteral 1200
        | BlueDragonspawnOverseerEvasion -> Distance.fromIntLiteral 0
        | BlueDragonspawnSorcererEvasion -> Distance.fromIntLiteral 0
        | BountyHunterJinada -> Distance.fromIntLiteral 0
        | BountyHunterShurikenToss -> Distance.fromIntLiteral 0
        | BountyHunterTrack -> Distance.fromIntLiteral 0
        | BountyHunterWindWalk -> Distance.fromIntLiteral 0
        | BrewmasterCinderBrew -> Distance.fromIntLiteral 400
        | BrewmasterDrunkenBrawler -> Distance.fromIntLiteral 0
        | BrewmasterDrunkenHaze -> Distance.fromIntLiteral 0
        | BrewmasterEarthHurlBoulder -> Distance.fromIntLiteral 0
        | BrewmasterEarthPulverize -> Distance.fromIntLiteral 0
        | BrewmasterEarthSpellImmunity -> Distance.fromIntLiteral 0
        | BrewmasterFirePermanentImmolation -> Distance.fromIntLiteral 220
        | BrewmasterPrimalSplit -> Distance.fromIntLiteral 0
        | BrewmasterStormCyclone -> Distance.fromIntLiteral 0
        | BrewmasterStormDispelMagic -> Distance.fromIntLiteral 600
        | BrewmasterStormWindWalk -> Distance.fromIntLiteral 0
        | BrewmasterThunderClap -> Distance.fromIntLiteral 400
        | BristlebackBristleback -> Distance.fromIntLiteral 0
        | BristlebackQuillSpray -> Distance.fromIntLiteral 700
        | BristlebackViscousNasalGoo -> Distance.fromIntLiteral 0
        | BristlebackWarpath -> Distance.fromIntLiteral 0
        | BroodmotherIncapacitatingBite -> Distance.fromIntLiteral 0
        | BroodmotherInsatiableHunger -> Distance.fromIntLiteral 0
        | BroodmotherPoisonSting -> Distance.fromIntLiteral 0
        | BroodmotherSpawnSpiderite -> Distance.fromIntLiteral 0
        | BroodmotherSpawnSpiderlings -> Distance.fromIntLiteral 0
        | BroodmotherSpinWeb -> Distance.fromIntLiteral 900
        | BroodmotherSpinWebDestroy -> Distance.fromIntLiteral 0
        | CentaurDoubleEdge -> Distance.fromIntLiteral 190
        | CentaurHoofStomp -> Distance.fromIntLiteral 315
        | CentaurReturn -> Distance.fromIntLiteral 0
        | CentaurStampede -> Distance.fromIntLiteral 0
        | ChaosKnightChaosBolt -> Distance.fromIntLiteral 0
        | ChaosKnightChaosStrike -> Distance.fromIntLiteral 0
        | ChaosKnightPhantasm -> Distance.fromIntLiteral 0
        | ChaosKnightRealityRift -> Distance.fromIntLiteral 0
        | ChenDivineFavor -> Distance.fromIntLiteral 0
        | ChenHandOfGod -> Distance.fromIntLiteral 0
        | ChenHolyPersuasion -> Distance.fromIntLiteral 0
        | ChenPenitence -> Distance.fromIntLiteral 0
        | ChenTestOfFaith -> Distance.fromIntLiteral 0
        | ChenTestOfFaithTeleport -> Distance.fromIntLiteral 0
        | ClinkzBurningArmy -> Distance.fromIntLiteral 0
        | ClinkzDeathPact -> Distance.fromIntLiteral 0
        | ClinkzSearingArrows -> Distance.fromIntLiteral 0
        | ClinkzStrafe -> Distance.fromIntLiteral 0
        | ClinkzWindWalk -> Distance.fromIntLiteral 0
        | CnyBeastForceAttack -> Distance.fromIntLiteral 0
        | CnyBeastTeleport -> Distance.fromIntLiteral 0
        | ConsumableHidden -> Distance.fromIntLiteral 0
        | CourierAutodeliver -> Distance.fromIntLiteral 0
        | CourierBurst -> Distance.fromIntLiteral 0
        | CourierGoToEnemySecretshop -> Distance.fromIntLiteral 0
        | CourierGoToSecretshop -> Distance.fromIntLiteral 0
        | CourierGoToSideshop -> Distance.fromIntLiteral 0
        | CourierMorph -> Distance.fromIntLiteral 0
        | CourierReturnStashItems -> Distance.fromIntLiteral 0
        | CourierReturnToBase -> Distance.fromIntLiteral 0
        | CourierShield -> Distance.fromIntLiteral 0
        | CourierTakeStashAndTransferItems -> Distance.fromIntLiteral 0
        | CourierTakeStashItems -> Distance.fromIntLiteral 0
        | CourierTransferItems -> Distance.fromIntLiteral 0
        | CourierTransferItemsToOtherPlayer -> Distance.fromIntLiteral 0
        | CrystalMaidenBrillianceAura -> Distance.fromIntLiteral 0
        | CrystalMaidenCrystalNova -> Distance.fromIntLiteral 425
        | CrystalMaidenFreezingField -> Distance.fromIntLiteral 835
        | CrystalMaidenFrostbite -> Distance.fromIntLiteral 0
        | DarkSeerIonShell -> Distance.fromIntLiteral 250
        | DarkSeerSurge -> Distance.fromIntLiteral 0
        | DarkSeerVacuum -> Distance.fromIntLiteral 400
        | DarkSeerWallOfReplica -> Distance.fromIntLiteral 0
        | DarkTrollWarlordEnsnare -> Distance.fromIntLiteral 0
        | DarkTrollWarlordRaiseDead -> Distance.fromIntLiteral 0
        | DarkWillowBedlam -> Distance.fromIntLiteral 0
        | DarkWillowBrambleMaze -> Distance.fromIntLiteral 0
        | DarkWillowCursedCrown -> Distance.fromIntLiteral 0
        | DarkWillowShadowRealm -> Distance.fromIntLiteral 0
        | DarkWillowTerrorize -> Distance.fromIntLiteral 0
        | DazzleBadJuju -> Distance.fromIntLiteral 1200
        | DazzlePoisonTouch -> Distance.fromIntLiteral 0
        | DazzleShadowWave -> Distance.fromIntLiteral 0
        | DazzleShallowGrave -> Distance.fromIntLiteral 0
        | DazzleWeave -> Distance.fromIntLiteral 575
        | DeathProphetCarrionSwarm -> Distance.fromIntLiteral 0
        | DeathProphetExorcism -> Distance.fromIntLiteral 700
        | DeathProphetSilence -> Distance.fromIntLiteral 425
        | DeathProphetSpiritSiphon -> Distance.fromIntLiteral 0
        | DeathProphetWitchcraft -> Distance.fromIntLiteral 0
        | DisruptorGlimpse -> Distance.fromIntLiteral 0
        | DisruptorKineticField -> Distance.fromIntLiteral 340
        | DisruptorStaticStorm -> Distance.fromIntLiteral 450
        | DisruptorThunderStrike -> Distance.fromIntLiteral 240
        | DoomBringerDevour -> Distance.fromIntLiteral 0
        | DoomBringerDoom -> Distance.fromIntLiteral 0
        | DoomBringerEmpty -> Distance.fromIntLiteral 0
        | DoomBringerInfernalBlade -> Distance.fromIntLiteral 0
        | DoomBringerScorchedEarth -> Distance.fromIntLiteral 600
        | DotaBaseAbility -> Distance.fromIntLiteral 0
        | DragonKnightBreatheFire -> Distance.fromIntLiteral 0
        | DragonKnightDragonBlood -> Distance.fromIntLiteral 0
        | DragonKnightDragonTail -> Distance.fromIntLiteral 0
        | DragonKnightElderDragonForm -> Distance.fromIntLiteral 0
        | DrowRangerFrostArrows -> Distance.fromIntLiteral 0
        | DrowRangerMarksmanship -> Distance.fromIntLiteral 0
        | DrowRangerSilence -> Distance.fromIntLiteral 300
        | DrowRangerTrueshot -> Distance.fromIntLiteral 0
        | DrowRangerWaveOfSilence -> Distance.fromIntLiteral 0
        | EarthSpiritBoulderSmash -> Distance.fromIntLiteral 180
        | EarthSpiritGeomagneticGrip -> Distance.fromIntLiteral 180
        | EarthSpiritMagnetize -> Distance.fromIntLiteral 350
        | EarthSpiritPetrify -> Distance.fromIntLiteral 0
        | EarthSpiritRollingBoulder -> Distance.fromIntLiteral 150
        | EarthSpiritStoneCaller -> Distance.fromIntLiteral 0
        | EarthshakerAftershock -> Distance.fromIntLiteral 300
        | EarthshakerEchoSlam -> Distance.fromIntLiteral 600
        | EarthshakerEnchantTotem -> Distance.fromIntLiteral 0
        | EarthshakerFissure -> Distance.fromIntLiteral 0
        | ElderTitanAncestralSpirit -> Distance.fromIntLiteral 275
        | ElderTitanEarthSplitter -> Distance.fromIntLiteral 0
        | ElderTitanEchoStomp -> Distance.fromIntLiteral 500
        | ElderTitanEchoStompSpirit -> Distance.fromIntLiteral 500
        | ElderTitanNaturalOrder -> Distance.fromIntLiteral 350
        | ElderTitanNaturalOrderSpirit -> Distance.fromIntLiteral 350
        | ElderTitanReturnSpirit -> Distance.fromIntLiteral 0
        | EmberSpiritActivateFireRemnant -> Distance.fromIntLiteral 450
        | EmberSpiritFireRemnant -> Distance.fromIntLiteral 450
        | EmberSpiritFlameGuard -> Distance.fromIntLiteral 400
        | EmberSpiritSearingChains -> Distance.fromIntLiteral 400
        | EmberSpiritSleightOfFist -> Distance.fromIntLiteral 250
        | EnchantressEnchant -> Distance.fromIntLiteral 0
        | EnchantressImpetus -> Distance.fromIntLiteral 0
        | EnchantressNaturesAttendants -> Distance.fromIntLiteral 275
        | EnchantressUntouchable -> Distance.fromIntLiteral 0
        | EnigmaBlackHole -> Distance.fromIntLiteral 420
        | EnigmaDemonicConversion -> Distance.fromIntLiteral 0
        | EnigmaMalefice -> Distance.fromIntLiteral 0
        | EnigmaMidnightPulse -> Distance.fromIntLiteral 550
        | EnragedWildkinTornado -> Distance.fromIntLiteral 0
        | EnragedWildkinToughnessAura -> Distance.fromIntLiteral 1200
        | FacelessVoidBacktrack -> Distance.fromIntLiteral 0
        | FacelessVoidChronosphere -> Distance.fromIntLiteral 425
        | FacelessVoidTimeDilation -> Distance.fromIntLiteral 775
        | FacelessVoidTimeLock -> Distance.fromIntLiteral 0
        | FacelessVoidTimeWalk -> Distance.fromIntLiteral 300
        | FillerAbility -> Distance.fromIntLiteral 500
        | ForestTrollHighPriestHeal -> Distance.fromIntLiteral 0
        | ForestTrollHighPriestManaAura -> Distance.fromIntLiteral 1200
        | ForgedSpiritMeltingStrike -> Distance.fromIntLiteral 0
        | FrostivusDecorateTree -> Distance.fromIntLiteral 500
        | FrostivusFestiveFirework -> Distance.fromIntLiteral 600
        | FrostivusSummonSnowman -> Distance.fromIntLiteral 0
        | FrostivusThrowSnowball -> Distance.fromIntLiteral 0
        | FurionForceOfNature -> Distance.fromIntLiteral 0
        | FurionSprout -> Distance.fromIntLiteral 150
        | FurionTeleportation -> Distance.fromIntLiteral 0
        | FurionWrathOfNature -> Distance.fromIntLiteral 0
        | GenericHidden -> Distance.fromIntLiteral 0
        | GhostFrostAttack -> Distance.fromIntLiteral 0
        | GiantWolfCriticalStrike -> Distance.fromIntLiteral 0
        | GnollAssassinEnvenomedWeapon -> Distance.fromIntLiteral 0
        | GraniteGolemBash -> Distance.fromIntLiteral 0
        | GraniteGolemHpAura -> Distance.fromIntLiteral 1200
        | GrimstrokeDarkArtistry -> Distance.fromIntLiteral 0
        | GrimstrokeInkCreature -> Distance.fromIntLiteral 0
        | GrimstrokeSoulChain -> Distance.fromIntLiteral 0
        | GrimstrokeSpiritWalk -> Distance.fromIntLiteral 400
        | GyrocopterCallDown -> Distance.fromIntLiteral 600
        | GyrocopterFlakCannon -> Distance.fromIntLiteral 1250
        | GyrocopterHomingMissile -> Distance.fromIntLiteral 0
        | GyrocopterRocketBarrage -> Distance.fromIntLiteral 400
        | HarpyStormChainLightning -> Distance.fromIntLiteral 0
        | HealingCampfire -> Distance.fromIntLiteral 0
        | HuskarBerserkersBlood -> Distance.fromIntLiteral 0
        | HuskarBurningSpear -> Distance.fromIntLiteral 0
        | HuskarInnerFire -> Distance.fromIntLiteral 500
        | HuskarInnerVitality -> Distance.fromIntLiteral 0
        | HuskarLifeBreak -> Distance.fromIntLiteral 0
        | InvokerAlacrity -> Distance.fromIntLiteral 0
        | InvokerChaosMeteor -> Distance.fromIntLiteral 275
        | InvokerColdSnap -> Distance.fromIntLiteral 0
        | InvokerDeafeningBlast -> Distance.fromIntLiteral 0
        | InvokerEmp -> Distance.fromIntLiteral 675
        | InvokerEmpty -> Distance.fromIntLiteral 0
        | InvokerExort -> Distance.fromIntLiteral 0
        | InvokerForgeSpirit -> Distance.fromIntLiteral 0
        | InvokerGhostWalk -> Distance.fromIntLiteral 400
        | InvokerIceWall -> Distance.fromIntLiteral 0
        | InvokerInvoke -> Distance.fromIntLiteral 0
        | InvokerQuas -> Distance.fromIntLiteral 0
        | InvokerSunStrike -> Distance.fromIntLiteral 175
        | InvokerTornado -> Distance.fromIntLiteral 200
        | InvokerWex -> Distance.fromIntLiteral 0
        | JakiroDualBreath -> Distance.fromIntLiteral 0
        | JakiroIcePath -> Distance.fromIntLiteral 150
        | JakiroLiquidFire -> Distance.fromIntLiteral 300
        | JakiroMacropyre -> Distance.fromIntLiteral 0
        | JuggernautBladeDance -> Distance.fromIntLiteral 0
        | JuggernautBladeFury -> Distance.fromIntLiteral 250
        | JuggernautHealingWard -> Distance.fromIntLiteral 500
        | JuggernautOmniSlash -> Distance.fromIntLiteral 0
        | KeeperOfTheLightBlindingLight -> Distance.fromIntLiteral 600
        | KeeperOfTheLightChakraMagic -> Distance.fromIntLiteral 0
        | KeeperOfTheLightEmpty -> Distance.fromIntLiteral 0
        | KeeperOfTheLightIlluminate -> Distance.fromIntLiteral 0
        | KeeperOfTheLightIlluminateEnd -> Distance.fromIntLiteral 0
        | KeeperOfTheLightManaLeak -> Distance.fromIntLiteral 0
        | KeeperOfTheLightRecall -> Distance.fromIntLiteral 0
        | KeeperOfTheLightSpiritForm -> Distance.fromIntLiteral 0
        | KeeperOfTheLightSpiritFormIlluminate -> Distance.fromIntLiteral 0
        | KeeperOfTheLightSpiritFormIlluminateEnd -> Distance.fromIntLiteral 0
        | KeeperOfTheLightWillOWisp -> Distance.fromIntLiteral 675
        | KoboldTaskmasterSpeedAura -> Distance.fromIntLiteral 1200
        | KunkkaGhostship -> Distance.fromIntLiteral 0
        | KunkkaReturn -> Distance.fromIntLiteral 0
        | KunkkaTidebringer -> Distance.fromIntLiteral 0
        | KunkkaTorrent -> Distance.fromIntLiteral 225
        | KunkkaXMarksTheSpot -> Distance.fromIntLiteral 0
        | LegionCommanderDuel -> Distance.fromIntLiteral 0
        | LegionCommanderMomentOfCourage -> Distance.fromIntLiteral 0
        | LegionCommanderOverwhelmingOdds -> Distance.fromIntLiteral 330
        | LegionCommanderPressTheAttack -> Distance.fromIntLiteral 0
        | LeshracDiabolicEdict -> Distance.fromIntLiteral 500
        | LeshracLightningStorm -> Distance.fromIntLiteral 0
        | LeshracPulseNova -> Distance.fromIntLiteral 450
        | LeshracSplitEarth -> Distance.fromIntLiteral 150
        | LichChainFrost -> Distance.fromIntLiteral 0
        | LichDarkSorcery -> Distance.fromIntLiteral 0
        | LichFrostArmor -> Distance.fromIntLiteral 0
        | LichFrostAura -> Distance.fromIntLiteral 1200
        | LichFrostNova -> Distance.fromIntLiteral 200
        | LichFrostShield -> Distance.fromIntLiteral 600
        | LichSinisterGaze -> Distance.fromIntLiteral 0
        | LifeStealerAssimilate -> Distance.fromIntLiteral 0
        | LifeStealerAssimilateEject -> Distance.fromIntLiteral 0
        | LifeStealerConsume -> Distance.fromIntLiteral 0
        | LifeStealerControl -> Distance.fromIntLiteral 0
        | LifeStealerEmpty -> Distance.fromIntLiteral 0
        | LifeStealerFeast -> Distance.fromIntLiteral 0
        | LifeStealerInfest -> Distance.fromIntLiteral 0
        | LifeStealerOpenWounds -> Distance.fromIntLiteral 0
        | LifeStealerRage -> Distance.fromIntLiteral 0
        | LinaDragonSlave -> Distance.fromIntLiteral 0
        | LinaFierySoul -> Distance.fromIntLiteral 0
        | LinaLagunaBlade -> Distance.fromIntLiteral 0
        | LinaLightStrikeArray -> Distance.fromIntLiteral 225
        | LionFingerOfDeath -> Distance.fromIntLiteral 0
        | LionImpale -> Distance.fromIntLiteral 0
        | LionManaDrain -> Distance.fromIntLiteral 0
        | LionVoodoo -> Distance.fromIntLiteral 0
        | LoneDruidRabid -> Distance.fromIntLiteral 0
        | LoneDruidSavageRoar -> Distance.fromIntLiteral 0
        | LoneDruidSavageRoarBear -> Distance.fromIntLiteral 0
        | LoneDruidSpiritBear -> Distance.fromIntLiteral 0
        | LoneDruidSpiritBearDefender -> Distance.fromIntLiteral 0
        | LoneDruidSpiritBearDemolish -> Distance.fromIntLiteral 0
        | LoneDruidSpiritBearEntangle -> Distance.fromIntLiteral 0
        | LoneDruidSpiritBearReturn -> Distance.fromIntLiteral 0
        | LoneDruidSpiritLink -> Distance.fromIntLiteral 0
        | LoneDruidTrueForm -> Distance.fromIntLiteral 0
        | LoneDruidTrueFormBattleCry -> Distance.fromIntLiteral 1000
        | LoneDruidTrueFormDruid -> Distance.fromIntLiteral 0
        | LunaEclipse -> Distance.fromIntLiteral 675
        | LunaLucentBeam -> Distance.fromIntLiteral 0
        | LunaLunarBlessing -> Distance.fromIntLiteral 0
        | LunaMoonGlaive -> Distance.fromIntLiteral 0
        | LycanFeralImpulse -> Distance.fromIntLiteral 1200
        | LycanHowl -> Distance.fromIntLiteral 0
        | LycanShapeshift -> Distance.fromIntLiteral 0
        | LycanSummonWolves -> Distance.fromIntLiteral 0
        | LycanSummonWolvesCriticalStrike -> Distance.fromIntLiteral 0
        | LycanSummonWolvesInvisibility -> Distance.fromIntLiteral 0
        | MagnataurEmpower -> Distance.fromIntLiteral 0
        | MagnataurReversePolarity -> Distance.fromIntLiteral 0
        | MagnataurShockwave -> Distance.fromIntLiteral 0
        | MagnataurSkewer -> Distance.fromIntLiteral 0
        | MarsArenaOfBlood -> Distance.fromIntLiteral 550
        | MarsBulwark -> Distance.fromIntLiteral 0
        | MarsGodsRebuke -> Distance.fromIntLiteral 0
        | MarsSpear -> Distance.fromIntLiteral 0
        | MedusaManaShield -> Distance.fromIntLiteral 0
        | MedusaMysticSnake -> Distance.fromIntLiteral 0
        | MedusaSplitShot -> Distance.fromIntLiteral 0
        | MedusaStoneGaze -> Distance.fromIntLiteral 1000
        | MeepoDividedWeStand -> Distance.fromIntLiteral 0
        | MeepoEarthbind -> Distance.fromIntLiteral 220
        | MeepoGeostrike -> Distance.fromIntLiteral 0
        | MeepoPoof -> Distance.fromIntLiteral 375
        | MeepoRansack -> Distance.fromIntLiteral 0
        | MiranaArrow -> Distance.fromIntLiteral 0
        | MiranaInvis -> Distance.fromIntLiteral 0
        | MiranaLeap -> Distance.fromIntLiteral 0
        | MiranaStarfall -> Distance.fromIntLiteral 650
        | MonkeyKingBoundlessStrike -> Distance.fromIntLiteral 0
        | MonkeyKingJinguMastery -> Distance.fromIntLiteral 0
        | MonkeyKingMischief -> Distance.fromIntLiteral 0
        | MonkeyKingPrimalSpring -> Distance.fromIntLiteral 0
        | MonkeyKingPrimalSpringEarly -> Distance.fromIntLiteral 0
        | MonkeyKingTreeDance -> Distance.fromIntLiteral 0
        | MonkeyKingUntransform -> Distance.fromIntLiteral 0
        | MonkeyKingWukongsCommand -> Distance.fromIntLiteral 0
        | MorphlingAdaptiveStrikeAgi -> Distance.fromIntLiteral 0
        | MorphlingAdaptiveStrikeStr -> Distance.fromIntLiteral 0
        | MorphlingHybrid -> Distance.fromIntLiteral 0
        | MorphlingMorph -> Distance.fromIntLiteral 0
        | MorphlingMorphAgi -> Distance.fromIntLiteral 0
        | MorphlingMorphReplicate -> Distance.fromIntLiteral 0
        | MorphlingMorphStr -> Distance.fromIntLiteral 0
        | MorphlingReplicate -> Distance.fromIntLiteral 0
        | MorphlingWaveform -> Distance.fromIntLiteral 0
        | MudGolemHurlBoulder -> Distance.fromIntLiteral 0
        | MudGolemRockDestroy -> Distance.fromIntLiteral 250
        | MudgolemCloakAura -> Distance.fromIntLiteral 0
        | NagaSirenEnsnare -> Distance.fromIntLiteral 0
        | NagaSirenMirrorImage -> Distance.fromIntLiteral 0
        | NagaSirenRipTide -> Distance.fromIntLiteral 300
        | NagaSirenSongOfTheSiren -> Distance.fromIntLiteral 1000
        | NagaSirenSongOfTheSirenCancel -> Distance.fromIntLiteral 0
        | NecrolyteDeathPulse -> Distance.fromIntLiteral 475
        | NecrolyteHeartstopperAura -> Distance.fromIntLiteral 700
        | NecrolyteReapersScythe -> Distance.fromIntLiteral 0
        | NecrolyteSadist -> Distance.fromIntLiteral 0
        | NecrolyteSadistStop -> Distance.fromIntLiteral 0
        | NecronomiconArcherAoe -> Distance.fromIntLiteral 1200
        | NecronomiconArcherManaBurn -> Distance.fromIntLiteral 0
        | NecronomiconArcherPurge -> Distance.fromIntLiteral 0
        | NecronomiconWarriorLastWill -> Distance.fromIntLiteral 0
        | NecronomiconWarriorManaBurn -> Distance.fromIntLiteral 0
        | NecronomiconWarriorSight -> Distance.fromIntLiteral 1000
        | NeutralSpellImmunity -> Distance.fromIntLiteral 0
        | NevermoreDarkLord -> Distance.fromIntLiteral 1200
        | NevermoreNecromastery -> Distance.fromIntLiteral 0
        | NevermoreRequiem -> Distance.fromIntLiteral 0
        | NevermoreShadowraze -> Distance.fromIntLiteral 0
        | NightStalkerCripplingFear -> Distance.fromIntLiteral 0
        | NightStalkerDarkness -> Distance.fromIntLiteral 0
        | NightStalkerHunterInTheNight -> Distance.fromIntLiteral 0
        | NightStalkerVoid -> Distance.fromIntLiteral 0
        | NyxAssassinBurrow -> Distance.fromIntLiteral 0
        | NyxAssassinImpale -> Distance.fromIntLiteral 0
        | NyxAssassinManaBurn -> Distance.fromIntLiteral 0
        | NyxAssassinSpikedCarapace -> Distance.fromIntLiteral 0
        | NyxAssassinUnburrow -> Distance.fromIntLiteral 0
        | NyxAssassinVendetta -> Distance.fromIntLiteral 0
        | ObsidianDestroyerArcaneOrb -> Distance.fromIntLiteral 0
        | ObsidianDestroyerAstralImprisonment -> Distance.fromIntLiteral 400
        | ObsidianDestroyerEquilibrium -> Distance.fromIntLiteral 0
        | ObsidianDestroyerEssenceAura -> Distance.fromIntLiteral 1200
        | ObsidianDestroyerSanityEclipse -> Distance.fromIntLiteral 375
        | OgreMagiBloodlust -> Distance.fromIntLiteral 0
        | OgreMagiFireblast -> Distance.fromIntLiteral 0
        | OgreMagiFrostArmor -> Distance.fromIntLiteral 0
        | OgreMagiIgnite -> Distance.fromIntLiteral 0
        | OgreMagiMulticast -> Distance.fromIntLiteral 0
        | OgreMagiUnrefinedFireblast -> Distance.fromIntLiteral 0
        | OmniknightDegenAura -> Distance.fromIntLiteral 300
        | OmniknightGuardianAngel -> Distance.fromIntLiteral 1200
        | OmniknightPacify -> Distance.fromIntLiteral 425
        | OmniknightPurification -> Distance.fromIntLiteral 0
        | OmniknightRepel -> Distance.fromIntLiteral 0
        | OracleFalsePromise -> Distance.fromIntLiteral 400
        | OracleFatesEdict -> Distance.fromIntLiteral 0
        | OracleFortunesEnd -> Distance.fromIntLiteral 300
        | OraclePurifyingFlames -> Distance.fromIntLiteral 0
        | PangolierGyroshell -> Distance.fromIntLiteral 150
        | PangolierGyroshellStop -> Distance.fromIntLiteral 0
        | PangolierHeartpiercer -> Distance.fromIntLiteral 0
        | PangolierLuckyShot -> Distance.fromIntLiteral 0
        | PangolierShieldCrash -> Distance.fromIntLiteral 500
        | PangolierSwashbuckle -> Distance.fromIntLiteral 0
        | PhantomAssassinBlur -> Distance.fromIntLiteral 0
        | PhantomAssassinCoupDeGrace -> Distance.fromIntLiteral 0
        | PhantomAssassinPhantomStrike -> Distance.fromIntLiteral 0
        | PhantomAssassinStiflingDagger -> Distance.fromIntLiteral 0
        | PhantomLancerDoppelwalk -> Distance.fromIntLiteral 0
        | PhantomLancerJuxtapose -> Distance.fromIntLiteral 0
        | PhantomLancerPhantomEdge -> Distance.fromIntLiteral 0
        | PhantomLancerSpiritLance -> Distance.fromIntLiteral 0
        | PhoenixFireSpirits -> Distance.fromIntLiteral 175
        | PhoenixIcarusDive -> Distance.fromIntLiteral 0
        | PhoenixIcarusDiveStop -> Distance.fromIntLiteral 0
        | PhoenixLaunchFireSpirit -> Distance.fromIntLiteral 175
        | PhoenixSunRay -> Distance.fromIntLiteral 130
        | PhoenixSunRayStop -> Distance.fromIntLiteral 0
        | PhoenixSunRayToggleMove -> Distance.fromIntLiteral 0
        | PhoenixSupernova -> Distance.fromIntLiteral 1300
        | PolarFurbolgUrsaWarriorThunderClap -> Distance.fromIntLiteral 300
        | PuckDreamCoil -> Distance.fromIntLiteral 0
        | PuckEtherealJaunt -> Distance.fromIntLiteral 0
        | PuckIllusoryOrb -> Distance.fromIntLiteral 225
        | PuckPhaseShift -> Distance.fromIntLiteral 0
        | PuckWaningRift -> Distance.fromIntLiteral 400
        | PudgeDismember -> Distance.fromIntLiteral 0
        | PudgeFleshHeap -> Distance.fromIntLiteral 0
        | PudgeMeatHook -> Distance.fromIntLiteral 0
        | PudgeRot -> Distance.fromIntLiteral 250
        | PugnaDecrepify -> Distance.fromIntLiteral 0
        | PugnaLifeDrain -> Distance.fromIntLiteral 0
        | PugnaNetherBlast -> Distance.fromIntLiteral 400
        | PugnaNetherWard -> Distance.fromIntLiteral 0
        | QueenofpainBlink -> Distance.fromIntLiteral 0
        | QueenofpainScreamOfPain -> Distance.fromIntLiteral 475
        | QueenofpainShadowStrike -> Distance.fromIntLiteral 0
        | QueenofpainSonicWave -> Distance.fromIntLiteral 0
        | RattletrapBatteryAssault -> Distance.fromIntLiteral 275
        | RattletrapHookshot -> Distance.fromIntLiteral 0
        | RattletrapPowerCogs -> Distance.fromIntLiteral 0
        | RattletrapRocketFlare -> Distance.fromIntLiteral 600
        | RazorEyeOfTheStorm -> Distance.fromIntLiteral 500
        | RazorPlasmaField -> Distance.fromIntLiteral 700
        | RazorStaticLink -> Distance.fromIntLiteral 200
        | RazorUnstableCurrent -> Distance.fromIntLiteral 0
        | RikiBlinkStrike -> Distance.fromIntLiteral 0
        | RikiPermanentInvisibility -> Distance.fromIntLiteral 0
        | RikiSmokeScreen -> Distance.fromIntLiteral 325
        | RikiTricksOfTheTrade -> Distance.fromIntLiteral 450
        | RoshanBash -> Distance.fromIntLiteral 0
        | RoshanDevotion -> Distance.fromIntLiteral 5
        | RoshanInherentBuffs -> Distance.fromIntLiteral 0
        | RoshanSlam -> Distance.fromIntLiteral 350
        | RoshanSpellBlock -> Distance.fromIntLiteral 0
        | RubickArcaneSupremacy -> Distance.fromIntLiteral 0
        | RubickEmpty -> Distance.fromIntLiteral 0
        | RubickFadeBolt -> Distance.fromIntLiteral 440
        | RubickHidden -> Distance.fromIntLiteral 0
        | RubickNullField -> Distance.fromIntLiteral 1200
        | RubickSpellSteal -> Distance.fromIntLiteral 0
        | RubickTelekinesis -> Distance.fromIntLiteral 0
        | RubickTelekinesisLand -> Distance.fromIntLiteral 325
        | SandkingBurrowstrike -> Distance.fromIntLiteral 0
        | SandkingCausticFinale -> Distance.fromIntLiteral 0
        | SandkingEpicenter -> Distance.fromIntLiteral 0
        | SandkingSandStorm -> Distance.fromIntLiteral 425
        | SatyrHellcallerShockwave -> Distance.fromIntLiteral 0
        | SatyrHellcallerUnholyAura -> Distance.fromIntLiteral 1200
        | SatyrSoulstealerManaBurn -> Distance.fromIntLiteral 0
        | SatyrTricksterPurge -> Distance.fromIntLiteral 0
        | ShadowDemonDemonicPurge -> Distance.fromIntLiteral 0
        | ShadowDemonDisruption -> Distance.fromIntLiteral 0
        | ShadowDemonShadowPoison -> Distance.fromIntLiteral 200
        | ShadowDemonShadowPoisonRelease -> Distance.fromIntLiteral 0
        | ShadowDemonSoulCatcher -> Distance.fromIntLiteral 175
        | ShadowShamanEtherShock -> Distance.fromIntLiteral 0
        | ShadowShamanMassSerpentWard -> Distance.fromIntLiteral 0
        | ShadowShamanShackles -> Distance.fromIntLiteral 0
        | ShadowShamanVoodoo -> Distance.fromIntLiteral 0
        | ShootFirework -> Distance.fromIntLiteral 0
        | ShredderChakram -> Distance.fromIntLiteral 200
        | ShredderReactiveArmor -> Distance.fromIntLiteral 0
        | ShredderReturnChakram -> Distance.fromIntLiteral 0
        | ShredderTimberChain -> Distance.fromIntLiteral 225
        | ShredderWhirlingDeath -> Distance.fromIntLiteral 300
        | SilencerCurseOfTheSilent -> Distance.fromIntLiteral 425
        | SilencerGlaivesOfWisdom -> Distance.fromIntLiteral 0
        | SilencerGlobalSilence -> Distance.fromIntLiteral 0
        | SilencerLastWord -> Distance.fromIntLiteral 0
        | SkeletonKingHellfireBlast -> Distance.fromIntLiteral 0
        | SkeletonKingMortalStrike -> Distance.fromIntLiteral 0
        | SkeletonKingReincarnation -> Distance.fromIntLiteral 0
        | SkeletonKingVampiricAura -> Distance.fromIntLiteral 0
        | SkywrathMageAncientSeal -> Distance.fromIntLiteral 0
        | SkywrathMageArcaneBolt -> Distance.fromIntLiteral 0
        | SkywrathMageConcussiveShot -> Distance.fromIntLiteral 0
        | SkywrathMageMysticFlare -> Distance.fromIntLiteral 170
        | SlardarAmplifyDamage -> Distance.fromIntLiteral 0
        | SlardarBash -> Distance.fromIntLiteral 0
        | SlardarSlithereenCrush -> Distance.fromIntLiteral 350
        | SlardarSprint -> Distance.fromIntLiteral 0
        | SlarkDarkPact -> Distance.fromIntLiteral 325
        | SlarkEssenceShift -> Distance.fromIntLiteral 0
        | SlarkPounce -> Distance.fromIntLiteral 0
        | SlarkShadowDance -> Distance.fromIntLiteral 0
        | SniperAssassinate -> Distance.fromIntLiteral 0
        | SniperHeadshot -> Distance.fromIntLiteral 0
        | SniperShrapnel -> Distance.fromIntLiteral 450
        | SniperTakeAim -> Distance.fromIntLiteral 0
        | SpawnlordAura -> Distance.fromIntLiteral 1200
        | SpawnlordMasterBash -> Distance.fromIntLiteral 0
        | SpawnlordMasterFreeze -> Distance.fromIntLiteral 0
        | SpawnlordMasterStomp -> Distance.fromIntLiteral 300
        | SpectreDesolate -> Distance.fromIntLiteral 500
        | SpectreDispersion -> Distance.fromIntLiteral 0
        | SpectreHaunt -> Distance.fromIntLiteral 0
        | SpectreReality -> Distance.fromIntLiteral 0
        | SpectreSpectralDagger -> Distance.fromIntLiteral 0
        | SpiritBreakerBulldoze -> Distance.fromIntLiteral 0
        | SpiritBreakerChargeOfDarkness -> Distance.fromIntLiteral 0
        | SpiritBreakerEmpoweringHaste -> Distance.fromIntLiteral 0
        | SpiritBreakerGreaterBash -> Distance.fromIntLiteral 0
        | SpiritBreakerNetherStrike -> Distance.fromIntLiteral 0
        | StormSpiritBallLightning -> Distance.fromIntLiteral 200
        | StormSpiritElectricVortex -> Distance.fromIntLiteral 0
        | StormSpiritOverload -> Distance.fromIntLiteral 300
        | StormSpiritStaticRemnant -> Distance.fromIntLiteral 260
        | SvenGodsStrength -> Distance.fromIntLiteral 0
        | SvenGreatCleave -> Distance.fromIntLiteral 0
        | SvenStormBolt -> Distance.fromIntLiteral 255
        | SvenWarcry -> Distance.fromIntLiteral 700
        | TechiesFocusedDetonate -> Distance.fromIntLiteral 700
        | TechiesLandMines -> Distance.fromIntLiteral 400
        | TechiesMinefieldSign -> Distance.fromIntLiteral 0
        | TechiesRemoteMines -> Distance.fromIntLiteral 0
        | TechiesRemoteMinesSelfDetonate -> Distance.fromIntLiteral 400
        | TechiesStasisTrap -> Distance.fromIntLiteral 400
        | TechiesSuicide -> Distance.fromIntLiteral 400
        | TemplarAssassinMeld -> Distance.fromIntLiteral 0
        | TemplarAssassinPsiBlades -> Distance.fromIntLiteral 0
        | TemplarAssassinPsionicTrap -> Distance.fromIntLiteral 0
        | TemplarAssassinRefraction -> Distance.fromIntLiteral 0
        | TemplarAssassinSelfTrap -> Distance.fromIntLiteral 0
        | TemplarAssassinTrap -> Distance.fromIntLiteral 0
        | TerrorbladeConjureImage -> Distance.fromIntLiteral 0
        | TerrorbladeMetamorphosis -> Distance.fromIntLiteral 0
        | TerrorbladeReflection -> Distance.fromIntLiteral 900
        | TerrorbladeSunder -> Distance.fromIntLiteral 0
        | TidehunterAnchorSmash -> Distance.fromIntLiteral 375
        | TidehunterGush -> Distance.fromIntLiteral 0
        | TidehunterKrakenShell -> Distance.fromIntLiteral 0
        | TidehunterRavage -> Distance.fromIntLiteral 1250
        | TinkerHeatSeekingMissile -> Distance.fromIntLiteral 2500
        | TinkerLaser -> Distance.fromIntLiteral 0
        | TinkerMarchOfTheMachines -> Distance.fromIntLiteral 900
        | TinkerRearm -> Distance.fromIntLiteral 0
        | TinyAvalanche -> Distance.fromIntLiteral 325
        | TinyCraggyExterior -> Distance.fromIntLiteral 0
        | TinyGrow -> Distance.fromIntLiteral 0
        | TinyToss -> Distance.fromIntLiteral 275
        | TinyTossTree -> Distance.fromIntLiteral 0
        | TornadoTempest -> Distance.fromIntLiteral 600
        | TreantEyesInTheForest -> Distance.fromIntLiteral 0
        | TreantLeechSeed -> Distance.fromIntLiteral 500
        | TreantLivingArmor -> Distance.fromIntLiteral 0
        | TreantNaturesGuise -> Distance.fromIntLiteral 0
        | TreantOvergrowth -> Distance.fromIntLiteral 800
        | TrollWarlordBattleTrance -> Distance.fromIntLiteral 0
        | TrollWarlordBerserkersRage -> Distance.fromIntLiteral 0
        | TrollWarlordFervor -> Distance.fromIntLiteral 0
        | TrollWarlordWhirlingAxesMelee -> Distance.fromIntLiteral 450
        | TrollWarlordWhirlingAxesRanged -> Distance.fromIntLiteral 0
        | TuskFrozenSigil -> Distance.fromIntLiteral 0
        | TuskIceShards -> Distance.fromIntLiteral 200
        | TuskIceShardsStop -> Distance.fromIntLiteral 0
        | TuskLaunchSnowball -> Distance.fromIntLiteral 0
        | TuskSnowball -> Distance.fromIntLiteral 0
        | TuskTagTeam -> Distance.fromIntLiteral 350
        | TuskWalrusKick -> Distance.fromIntLiteral 0
        | TuskWalrusPunch -> Distance.fromIntLiteral 0
        | UndyingDecay -> Distance.fromIntLiteral 325
        | UndyingFleshGolem -> Distance.fromIntLiteral 375
        | UndyingSoulRip -> Distance.fromIntLiteral 0
        | UndyingTombstone -> Distance.fromIntLiteral 0
        | UndyingTombstoneZombieAura -> Distance.fromIntLiteral 600
        | UndyingTombstoneZombieDeathstrike -> Distance.fromIntLiteral 1200
        | UrsaEarthshock -> Distance.fromIntLiteral 385
        | UrsaEnrage -> Distance.fromIntLiteral 0
        | UrsaFurySwipes -> Distance.fromIntLiteral 0
        | UrsaOverpower -> Distance.fromIntLiteral 0
        | VengefulspiritCommandAura -> Distance.fromIntLiteral 1200
        | VengefulspiritMagicMissile -> Distance.fromIntLiteral 0
        | VengefulspiritNetherSwap -> Distance.fromIntLiteral 0
        | VengefulspiritWaveOfTerror -> Distance.fromIntLiteral 0
        | VenomancerPlagueWard -> Distance.fromIntLiteral 0
        | VenomancerPoisonNova -> Distance.fromIntLiteral 830
        | VenomancerPoisonSting -> Distance.fromIntLiteral 0
        | VenomancerVenomousGale -> Distance.fromIntLiteral 125
        | ViperCorrosiveSkin -> Distance.fromIntLiteral 0
        | ViperNethertoxin -> Distance.fromIntLiteral 380
        | ViperPoisonAttack -> Distance.fromIntLiteral 0
        | ViperViperStrike -> Distance.fromIntLiteral 0
        | VisageGraveChill -> Distance.fromIntLiteral 0
        | VisageGravekeepersCloak -> Distance.fromIntLiteral 1200
        | VisageSoulAssumption -> Distance.fromIntLiteral 0
        | VisageStoneFormSelfCast -> Distance.fromIntLiteral 0
        | VisageSummonFamiliars -> Distance.fromIntLiteral 0
        | VisageSummonFamiliarsStoneForm -> Distance.fromIntLiteral 0
        | WarlockFatalBonds -> Distance.fromIntLiteral 0
        | WarlockGolemFlamingFists -> Distance.fromIntLiteral 300
        | WarlockGolemPermanentImmolation -> Distance.fromIntLiteral 0
        | WarlockRainOfChaos -> Distance.fromIntLiteral 0
        | WarlockShadowWord -> Distance.fromIntLiteral 0
        | WarlockUpheaval -> Distance.fromIntLiteral 650
        | WeaverGeminateAttack -> Distance.fromIntLiteral 0
        | WeaverShukuchi -> Distance.fromIntLiteral 175
        | WeaverTheSwarm -> Distance.fromIntLiteral 100
        | WeaverTimeLapse -> Distance.fromIntLiteral 0
        | WindrunnerFocusfire -> Distance.fromIntLiteral 0
        | WindrunnerPowershot -> Distance.fromIntLiteral 0
        | WindrunnerShackleshot -> Distance.fromIntLiteral 0
        | WindrunnerWindrun -> Distance.fromIntLiteral 0
        | WinterWyvernArcticBurn -> Distance.fromIntLiteral 0
        | WinterWyvernColdEmbrace -> Distance.fromIntLiteral 0
        | WinterWyvernSplinterBlast -> Distance.fromIntLiteral 0
        | WinterWyvernWintersCurse -> Distance.fromIntLiteral 0
        | WispOvercharge -> Distance.fromIntLiteral 0
        | WispRelocate -> Distance.fromIntLiteral 0
        | WispSpirits -> Distance.fromIntLiteral 0
        | WispSpiritsIn -> Distance.fromIntLiteral 0
        | WispSpiritsOut -> Distance.fromIntLiteral 0
        | WispTether -> Distance.fromIntLiteral 0
        | WispTetherBreak -> Distance.fromIntLiteral 0
        | WitchDoctorDeathWard -> Distance.fromIntLiteral 0
        | WitchDoctorMaledict -> Distance.fromIntLiteral 180
        | WitchDoctorParalyzingCask -> Distance.fromIntLiteral 0
        | WitchDoctorVoodooRestoration -> Distance.fromIntLiteral 500
        | ZuusArcLightning -> Distance.fromIntLiteral 500
        | ZuusCloud -> Distance.fromIntLiteral 0
        | ZuusLightningBolt -> Distance.fromIntLiteral 0
        | ZuusStaticField -> Distance.fromIntLiteral 0
        | ZuusThundergodsWrath -> Distance.fromIntLiteral 0

    /// <summary>
    ///    Serialise a given Dota 2 hero to a string id value.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="ability">
    ///     The Dota 2 ability to obtain an ID for.
    /// </param>
    ///
    /// <returns>
    ///     The in-game ID used by Dota2 to uniquely dentify an ability
    /// </returns>
    let toId (ability : Ability) : string =
        match ability with
        | AbaddonAphoticShield -> "abaddon_aphotic_shield"
        | AbaddonBorrowedTime -> "abaddon_borrowed_time"
        | AbaddonDeathCoil -> "abaddon_death_coil"
        | AbaddonFrostmourne -> "abaddon_frostmourne"
        | AbyssalUnderlordAtrophyAura -> "abyssal_underlord_atrophy_aura"
        | AbyssalUnderlordCancelDarkRift -> "abyssal_underlord_cancel_dark_rift"
        | AbyssalUnderlordDarkRift -> "abyssal_underlord_dark_rift"
        | AbyssalUnderlordFirestorm -> "abyssal_underlord_firestorm"
        | AbyssalUnderlordPitOfMalice -> "abyssal_underlord_pit_of_malice"
        | AlchemistAcidSpray -> "alchemist_acid_spray"
        | AlchemistChemicalRage -> "alchemist_chemical_rage"
        | AlchemistGoblinsGreed -> "alchemist_goblins_greed"
        | AlchemistUnstableConcoction -> "alchemist_unstable_concoction"
        | AlchemistUnstableConcoctionThrow -> "alchemist_unstable_concoction_throw"
        | AlphaWolfCommandAura -> "alpha_wolf_command_aura"
        | AlphaWolfCriticalStrike -> "alpha_wolf_critical_strike"
        | AncientApparitionChillingTouch -> "ancient_apparition_chilling_touch"
        | AncientApparitionColdFeet -> "ancient_apparition_cold_feet"
        | AncientApparitionIceBlast -> "ancient_apparition_ice_blast"
        | AncientApparitionIceBlastRelease -> "ancient_apparition_ice_blast_release"
        | AncientApparitionIceVortex -> "ancient_apparition_ice_vortex"
        | AncientGolemRockslide -> "ancient_golem_rockslide"
        | AntimageBlink -> "antimage_blink"
        | AntimageCounterspell -> "antimage_counterspell"
        | AntimageManaBreak -> "antimage_mana_break"
        | AntimageManaVoid -> "antimage_mana_void"
        | AntimageSpellShield -> "antimage_spell_shield"
        | ArcWardenFlux -> "arc_warden_flux"
        | ArcWardenMagneticField -> "arc_warden_magnetic_field"
        | ArcWardenSparkWraith -> "arc_warden_spark_wraith"
        | ArcWardenTempestDouble -> "arc_warden_tempest_double"
        | AxeBattleHunger -> "axe_battle_hunger"
        | AxeBerserkersCall -> "axe_berserkers_call"
        | AxeCounterHelix -> "axe_counter_helix"
        | AxeCullingBlade -> "axe_culling_blade"
        | BackdoorProtection -> "backdoor_protection"
        | BackdoorProtectionInBase -> "backdoor_protection_in_base"
        | BaneBrainSap -> "bane_brain_sap"
        | BaneEnfeeble -> "bane_enfeeble"
        | BaneFiendsGrip -> "bane_fiends_grip"
        | BaneNightmare -> "bane_nightmare"
        | BaneNightmareEnd -> "bane_nightmare_end"
        | BatriderFirefly -> "batrider_firefly"
        | BatriderFlamebreak -> "batrider_flamebreak"
        | BatriderFlamingLasso -> "batrider_flaming_lasso"
        | BatriderStickyNapalm -> "batrider_sticky_napalm"
        | BearEmpty -> "bear_empty1"
        | BeastmasterBoarPoison -> "beastmaster_boar_poison"
        | BeastmasterCallOfTheWild -> "beastmaster_call_of_the_wild"
        | BeastmasterCallOfTheWildBoar -> "beastmaster_call_of_the_wild_boar"
        | BeastmasterCallOfTheWildHawk -> "beastmaster_call_of_the_wild_hawk"
        | BeastmasterGreaterBoarPoison -> "beastmaster_greater_boar_poison"
        | BeastmasterHawkInvisibility -> "beastmaster_hawk_invisibility"
        | BeastmasterInnerBeast -> "beastmaster_inner_beast"
        | BeastmasterPrimalRoar -> "beastmaster_primal_roar"
        | BeastmasterWildAxes -> "beastmaster_wild_axes"
        | BigThunderLizardFrenzy -> "big_thunder_lizard_frenzy"
        | BigThunderLizardSlam -> "big_thunder_lizard_slam"
        | BigThunderLizardWardrumsAura -> "big_thunder_lizard_wardrums_aura"
        | BlackDragonDragonhideAura -> "black_dragon_dragonhide_aura"
        | BlackDragonFireball -> "black_dragon_fireball"
        | BlackDragonSplashAttack -> "black_dragon_splash_attack"
        | BloodseekerBloodBath -> "bloodseeker_blood_bath"
        | BloodseekerBloodrage -> "bloodseeker_bloodrage"
        | BloodseekerRupture -> "bloodseeker_rupture"
        | BloodseekerThirst -> "bloodseeker_thirst"
        | BlueDragonspawnOverseerDevotionAura -> "blue_dragonspawn_overseer_devotion_aura"
        | BlueDragonspawnOverseerEvasion -> "blue_dragonspawn_overseer_evasion"
        | BlueDragonspawnSorcererEvasion -> "blue_dragonspawn_sorcerer_evasion"
        | BountyHunterJinada -> "bounty_hunter_jinada"
        | BountyHunterShurikenToss -> "bounty_hunter_shuriken_toss"
        | BountyHunterTrack -> "bounty_hunter_track"
        | BountyHunterWindWalk -> "bounty_hunter_wind_walk"
        | BrewmasterCinderBrew -> "brewmaster_cinder_brew"
        | BrewmasterDrunkenBrawler -> "brewmaster_drunken_brawler"
        | BrewmasterDrunkenHaze -> "brewmaster_drunken_haze"
        | BrewmasterEarthHurlBoulder -> "brewmaster_earth_hurl_boulder"
        | BrewmasterEarthPulverize -> "brewmaster_earth_pulverize"
        | BrewmasterEarthSpellImmunity -> "brewmaster_earth_spell_immunity"
        | BrewmasterFirePermanentImmolation -> "brewmaster_fire_permanent_immolation"
        | BrewmasterPrimalSplit -> "brewmaster_primal_split"
        | BrewmasterStormCyclone -> "brewmaster_storm_cyclone"
        | BrewmasterStormDispelMagic -> "brewmaster_storm_dispel_magic"
        | BrewmasterStormWindWalk -> "brewmaster_storm_wind_walk"
        | BrewmasterThunderClap -> "brewmaster_thunder_clap"
        | BristlebackBristleback -> "bristleback_bristleback"
        | BristlebackQuillSpray -> "bristleback_quill_spray"
        | BristlebackViscousNasalGoo -> "bristleback_viscous_nasal_goo"
        | BristlebackWarpath -> "bristleback_warpath"
        | BroodmotherIncapacitatingBite -> "broodmother_incapacitating_bite"
        | BroodmotherInsatiableHunger -> "broodmother_insatiable_hunger"
        | BroodmotherPoisonSting -> "broodmother_poison_sting"
        | BroodmotherSpawnSpiderite -> "broodmother_spawn_spiderite"
        | BroodmotherSpawnSpiderlings -> "broodmother_spawn_spiderlings"
        | BroodmotherSpinWeb -> "broodmother_spin_web"
        | BroodmotherSpinWebDestroy -> "broodmother_spin_web_destroy"
        | CentaurDoubleEdge -> "centaur_double_edge"
        | CentaurHoofStomp -> "centaur_hoof_stomp"
        | CentaurReturn -> "centaur_return"
        | CentaurStampede -> "centaur_stampede"
        | ChaosKnightChaosBolt -> "chaos_knight_chaos_bolt"
        | ChaosKnightChaosStrike -> "chaos_knight_chaos_strike"
        | ChaosKnightPhantasm -> "chaos_knight_phantasm"
        | ChaosKnightRealityRift -> "chaos_knight_reality_rift"
        | ChenDivineFavor -> "chen_divine_favor"
        | ChenHandOfGod -> "chen_hand_of_god"
        | ChenHolyPersuasion -> "chen_holy_persuasion"
        | ChenPenitence -> "chen_penitence"
        | ChenTestOfFaith -> "chen_test_of_faith"
        | ChenTestOfFaithTeleport -> "chen_test_of_faith_teleport"
        | ClinkzBurningArmy -> "clinkz_burning_army"
        | ClinkzDeathPact -> "clinkz_death_pact"
        | ClinkzSearingArrows -> "clinkz_searing_arrows"
        | ClinkzStrafe -> "clinkz_strafe"
        | ClinkzWindWalk -> "clinkz_wind_walk"
        | CnyBeastForceAttack -> "cny_beast_force_attack"
        | CnyBeastTeleport -> "cny_beast_teleport"
        | ConsumableHidden -> "consumable_hidden"
        | CourierAutodeliver -> "courier_autodeliver"
        | CourierBurst -> "courier_burst"
        | CourierGoToEnemySecretshop -> "courier_go_to_enemy_secretshop"
        | CourierGoToSecretshop -> "courier_go_to_secretshop"
        | CourierGoToSideshop -> "courier_go_to_sideshop"
        | CourierMorph -> "courier_morph"
        | CourierReturnStashItems -> "courier_return_stash_items"
        | CourierReturnToBase -> "courier_return_to_base"
        | CourierShield -> "courier_shield"
        | CourierTakeStashAndTransferItems -> "courier_take_stash_and_transfer_items"
        | CourierTakeStashItems -> "courier_take_stash_items"
        | CourierTransferItems -> "courier_transfer_items"
        | CourierTransferItemsToOtherPlayer -> "courier_transfer_items_to_other_player"
        | CrystalMaidenBrillianceAura -> "crystal_maiden_brilliance_aura"
        | CrystalMaidenCrystalNova -> "crystal_maiden_crystal_nova"
        | CrystalMaidenFreezingField -> "crystal_maiden_freezing_field"
        | CrystalMaidenFrostbite -> "crystal_maiden_frostbite"
        | DarkSeerIonShell -> "dark_seer_ion_shell"
        | DarkSeerSurge -> "dark_seer_surge"
        | DarkSeerVacuum -> "dark_seer_vacuum"
        | DarkSeerWallOfReplica -> "dark_seer_wall_of_replica"
        | DarkTrollWarlordEnsnare -> "dark_troll_warlord_ensnare"
        | DarkTrollWarlordRaiseDead -> "dark_troll_warlord_raise_dead"
        | DarkWillowBedlam -> "dark_willow_bedlam"
        | DarkWillowBrambleMaze -> "dark_willow_bramble_maze"
        | DarkWillowCursedCrown -> "dark_willow_cursed_crown"
        | DarkWillowShadowRealm -> "dark_willow_shadow_realm"
        | DarkWillowTerrorize -> "dark_willow_terrorize"
        | DazzleBadJuju -> "dazzle_bad_juju"
        | DazzlePoisonTouch -> "dazzle_poison_touch"
        | DazzleShadowWave -> "dazzle_shadow_wave"
        | DazzleShallowGrave -> "dazzle_shallow_grave"
        | DazzleWeave -> "dazzle_weave"
        | DeathProphetCarrionSwarm -> "death_prophet_carrion_swarm"
        | DeathProphetExorcism -> "death_prophet_exorcism"
        | DeathProphetSilence -> "death_prophet_silence"
        | DeathProphetSpiritSiphon -> "death_prophet_spirit_siphon"
        | DeathProphetWitchcraft -> "death_prophet_witchcraft"
        | DisruptorGlimpse -> "disruptor_glimpse"
        | DisruptorKineticField -> "disruptor_kinetic_field"
        | DisruptorStaticStorm -> "disruptor_static_storm"
        | DisruptorThunderStrike -> "disruptor_thunder_strike"
        | DoomBringerDevour -> "doom_bringer_devour"
        | DoomBringerDoom -> "doom_bringer_doom"
        | DoomBringerEmpty -> "doom_bringer_empty1"
        | DoomBringerInfernalBlade -> "doom_bringer_infernal_blade"
        | DoomBringerScorchedEarth -> "doom_bringer_scorched_earth"
        | DotaBaseAbility -> "dota_base_ability"
        | DragonKnightBreatheFire -> "dragon_knight_breathe_fire"
        | DragonKnightDragonBlood -> "dragon_knight_dragon_blood"
        | DragonKnightDragonTail -> "dragon_knight_dragon_tail"
        | DragonKnightElderDragonForm -> "dragon_knight_elder_dragon_form"
        | DrowRangerFrostArrows -> "drow_ranger_frost_arrows"
        | DrowRangerMarksmanship -> "drow_ranger_marksmanship"
        | DrowRangerSilence -> "drow_ranger_silence"
        | DrowRangerTrueshot -> "drow_ranger_trueshot"
        | DrowRangerWaveOfSilence -> "drow_ranger_wave_of_silence"
        | EarthSpiritBoulderSmash -> "earth_spirit_boulder_smash"
        | EarthSpiritGeomagneticGrip -> "earth_spirit_geomagnetic_grip"
        | EarthSpiritMagnetize -> "earth_spirit_magnetize"
        | EarthSpiritPetrify -> "earth_spirit_petrify"
        | EarthSpiritRollingBoulder -> "earth_spirit_rolling_boulder"
        | EarthSpiritStoneCaller -> "earth_spirit_stone_caller"
        | EarthshakerAftershock -> "earthshaker_aftershock"
        | EarthshakerEchoSlam -> "earthshaker_echo_slam"
        | EarthshakerEnchantTotem -> "earthshaker_enchant_totem"
        | EarthshakerFissure -> "earthshaker_fissure"
        | ElderTitanAncestralSpirit -> "elder_titan_ancestral_spirit"
        | ElderTitanEarthSplitter -> "elder_titan_earth_splitter"
        | ElderTitanEchoStomp -> "elder_titan_echo_stomp"
        | ElderTitanEchoStompSpirit -> "elder_titan_echo_stomp_spirit"
        | ElderTitanNaturalOrder -> "elder_titan_natural_order"
        | ElderTitanNaturalOrderSpirit -> "elder_titan_natural_order_spirit"
        | ElderTitanReturnSpirit -> "elder_titan_return_spirit"
        | EmberSpiritActivateFireRemnant -> "ember_spirit_activate_fire_remnant"
        | EmberSpiritFireRemnant -> "ember_spirit_fire_remnant"
        | EmberSpiritFlameGuard -> "ember_spirit_flame_guard"
        | EmberSpiritSearingChains -> "ember_spirit_searing_chains"
        | EmberSpiritSleightOfFist -> "ember_spirit_sleight_of_fist"
        | EnchantressEnchant -> "enchantress_enchant"
        | EnchantressImpetus -> "enchantress_impetus"
        | EnchantressNaturesAttendants -> "enchantress_natures_attendants"
        | EnchantressUntouchable -> "enchantress_untouchable"
        | EnigmaBlackHole -> "enigma_black_hole"
        | EnigmaDemonicConversion -> "enigma_demonic_conversion"
        | EnigmaMalefice -> "enigma_malefice"
        | EnigmaMidnightPulse -> "enigma_midnight_pulse"
        | EnragedWildkinTornado -> "enraged_wildkin_tornado"
        | EnragedWildkinToughnessAura -> "enraged_wildkin_toughness_aura"
        | FacelessVoidBacktrack -> "faceless_void_backtrack"
        | FacelessVoidChronosphere -> "faceless_void_chronosphere"
        | FacelessVoidTimeDilation -> "faceless_void_time_dilation"
        | FacelessVoidTimeLock -> "faceless_void_time_lock"
        | FacelessVoidTimeWalk -> "faceless_void_time_walk"
        | FillerAbility -> "filler_ability"
        | ForestTrollHighPriestHeal -> "forest_troll_high_priest_heal"
        | ForestTrollHighPriestManaAura -> "forest_troll_high_priest_mana_aura"
        | ForgedSpiritMeltingStrike -> "forged_spirit_melting_strike"
        | FrostivusDecorateTree -> "frostivus2018_decorate_tree"
        | FrostivusFestiveFirework -> "frostivus2018_festive_firework"
        | FrostivusSummonSnowman -> "frostivus2018_summon_snowman"
        | FrostivusThrowSnowball -> "frostivus2018_throw_snowball"
        | FurionForceOfNature -> "furion_force_of_nature"
        | FurionSprout -> "furion_sprout"
        | FurionTeleportation -> "furion_teleportation"
        | FurionWrathOfNature -> "furion_wrath_of_nature"
        | GenericHidden -> "generic_hidden"
        | GhostFrostAttack -> "ghost_frost_attack"
        | GiantWolfCriticalStrike -> "giant_wolf_critical_strike"
        | GnollAssassinEnvenomedWeapon -> "gnoll_assassin_envenomed_weapon"
        | GraniteGolemBash -> "granite_golem_bash"
        | GraniteGolemHpAura -> "granite_golem_hp_aura"
        | GrimstrokeDarkArtistry -> "grimstroke_dark_artistry"
        | GrimstrokeInkCreature -> "grimstroke_ink_creature"
        | GrimstrokeSoulChain -> "grimstroke_soul_chain"
        | GrimstrokeSpiritWalk -> "grimstroke_spirit_walk"
        | GyrocopterCallDown -> "gyrocopter_call_down"
        | GyrocopterFlakCannon -> "gyrocopter_flak_cannon"
        | GyrocopterHomingMissile -> "gyrocopter_homing_missile"
        | GyrocopterRocketBarrage -> "gyrocopter_rocket_barrage"
        | HarpyStormChainLightning -> "harpy_storm_chain_lightning"
        | HealingCampfire -> "healing_campfire"
        | HuskarBerserkersBlood -> "huskar_berserkers_blood"
        | HuskarBurningSpear -> "huskar_burning_spear"
        | HuskarInnerFire -> "huskar_inner_fire"
        | HuskarInnerVitality -> "huskar_inner_vitality"
        | HuskarLifeBreak -> "huskar_life_break"
        | InvokerAlacrity -> "invoker_alacrity"
        | InvokerChaosMeteor -> "invoker_chaos_meteor"
        | InvokerColdSnap -> "invoker_cold_snap"
        | InvokerDeafeningBlast -> "invoker_deafening_blast"
        | InvokerEmp -> "invoker_emp"
        | InvokerEmpty -> "invoker_empty1"
        | InvokerExort -> "invoker_exort"
        | InvokerForgeSpirit -> "invoker_forge_spirit"
        | InvokerGhostWalk -> "invoker_ghost_walk"
        | InvokerIceWall -> "invoker_ice_wall"
        | InvokerInvoke -> "invoker_invoke"
        | InvokerQuas -> "invoker_quas"
        | InvokerSunStrike -> "invoker_sun_strike"
        | InvokerTornado -> "invoker_tornado"
        | InvokerWex -> "invoker_wex"
        | JakiroDualBreath -> "jakiro_dual_breath"
        | JakiroIcePath -> "jakiro_ice_path"
        | JakiroLiquidFire -> "jakiro_liquid_fire"
        | JakiroMacropyre -> "jakiro_macropyre"
        | JuggernautBladeDance -> "juggernaut_blade_dance"
        | JuggernautBladeFury -> "juggernaut_blade_fury"
        | JuggernautHealingWard -> "juggernaut_healing_ward"
        | JuggernautOmniSlash -> "juggernaut_omni_slash"
        | KeeperOfTheLightBlindingLight -> "keeper_of_the_light_blinding_light"
        | KeeperOfTheLightChakraMagic -> "keeper_of_the_light_chakra_magic"
        | KeeperOfTheLightEmpty -> "keeper_of_the_light_empty1"
        | KeeperOfTheLightIlluminate -> "keeper_of_the_light_illuminate"
        | KeeperOfTheLightIlluminateEnd -> "keeper_of_the_light_illuminate_end"
        | KeeperOfTheLightManaLeak -> "keeper_of_the_light_mana_leak"
        | KeeperOfTheLightRecall -> "keeper_of_the_light_recall"
        | KeeperOfTheLightSpiritForm -> "keeper_of_the_light_spirit_form"
        | KeeperOfTheLightSpiritFormIlluminate -> "keeper_of_the_light_spirit_form_illuminate"
        | KeeperOfTheLightSpiritFormIlluminateEnd -> "keeper_of_the_light_spirit_form_illuminate_end"
        | KeeperOfTheLightWillOWisp -> "keeper_of_the_light_will_o_wisp"
        | KoboldTaskmasterSpeedAura -> "kobold_taskmaster_speed_aura"
        | KunkkaGhostship -> "kunkka_ghostship"
        | KunkkaReturn -> "kunkka_return"
        | KunkkaTidebringer -> "kunkka_tidebringer"
        | KunkkaTorrent -> "kunkka_torrent"
        | KunkkaXMarksTheSpot -> "kunkka_x_marks_the_spot"
        | LegionCommanderDuel -> "legion_commander_duel"
        | LegionCommanderMomentOfCourage -> "legion_commander_moment_of_courage"
        | LegionCommanderOverwhelmingOdds -> "legion_commander_overwhelming_odds"
        | LegionCommanderPressTheAttack -> "legion_commander_press_the_attack"
        | LeshracDiabolicEdict -> "leshrac_diabolic_edict"
        | LeshracLightningStorm -> "leshrac_lightning_storm"
        | LeshracPulseNova -> "leshrac_pulse_nova"
        | LeshracSplitEarth -> "leshrac_split_earth"
        | LichChainFrost -> "lich_chain_frost"
        | LichDarkSorcery -> "lich_dark_sorcery"
        | LichFrostArmor -> "lich_frost_armor"
        | LichFrostAura -> "lich_frost_aura"
        | LichFrostNova -> "lich_frost_nova"
        | LichFrostShield -> "lich_frost_shield"
        | LichSinisterGaze -> "lich_sinister_gaze"
        | LifeStealerAssimilate -> "life_stealer_assimilate"
        | LifeStealerAssimilateEject -> "life_stealer_assimilate_eject"
        | LifeStealerConsume -> "life_stealer_consume"
        | LifeStealerControl -> "life_stealer_control"
        | LifeStealerEmpty -> "life_stealer_empty_1"
        | LifeStealerFeast -> "life_stealer_feast"
        | LifeStealerInfest -> "life_stealer_infest"
        | LifeStealerOpenWounds -> "life_stealer_open_wounds"
        | LifeStealerRage -> "life_stealer_rage"
        | LinaDragonSlave -> "lina_dragon_slave"
        | LinaFierySoul -> "lina_fiery_soul"
        | LinaLagunaBlade -> "lina_laguna_blade"
        | LinaLightStrikeArray -> "lina_light_strike_array"
        | LionFingerOfDeath -> "lion_finger_of_death"
        | LionImpale -> "lion_impale"
        | LionManaDrain -> "lion_mana_drain"
        | LionVoodoo -> "lion_voodoo"
        | LoneDruidRabid -> "lone_druid_rabid"
        | LoneDruidSavageRoar -> "lone_druid_savage_roar"
        | LoneDruidSavageRoarBear -> "lone_druid_savage_roar_bear"
        | LoneDruidSpiritBear -> "lone_druid_spirit_bear"
        | LoneDruidSpiritBearDefender -> "lone_druid_spirit_bear_defender"
        | LoneDruidSpiritBearDemolish -> "lone_druid_spirit_bear_demolish"
        | LoneDruidSpiritBearEntangle -> "lone_druid_spirit_bear_entangle"
        | LoneDruidSpiritBearReturn -> "lone_druid_spirit_bear_return"
        | LoneDruidSpiritLink -> "lone_druid_spirit_link"
        | LoneDruidTrueForm -> "lone_druid_true_form"
        | LoneDruidTrueFormBattleCry -> "lone_druid_true_form_battle_cry"
        | LoneDruidTrueFormDruid -> "lone_druid_true_form_druid"
        | LunaEclipse -> "luna_eclipse"
        | LunaLucentBeam -> "luna_lucent_beam"
        | LunaLunarBlessing -> "luna_lunar_blessing"
        | LunaMoonGlaive -> "luna_moon_glaive"
        | LycanFeralImpulse -> "lycan_feral_impulse"
        | LycanHowl -> "lycan_howl"
        | LycanShapeshift -> "lycan_shapeshift"
        | LycanSummonWolves -> "lycan_summon_wolves"
        | LycanSummonWolvesCriticalStrike -> "lycan_summon_wolves_critical_strike"
        | LycanSummonWolvesInvisibility -> "lycan_summon_wolves_invisibility"
        | MagnataurEmpower -> "magnataur_empower"
        | MagnataurReversePolarity -> "magnataur_reverse_polarity"
        | MagnataurShockwave -> "magnataur_shockwave"
        | MagnataurSkewer -> "magnataur_skewer"
        | MarsArenaOfBlood -> "mars_arena_of_blood"
        | MarsBulwark -> "mars_bulwark"
        | MarsGodsRebuke -> "mars_gods_rebuke"
        | MarsSpear -> "mars_spear"
        | MedusaManaShield -> "medusa_mana_shield"
        | MedusaMysticSnake -> "medusa_mystic_snake"
        | MedusaSplitShot -> "medusa_split_shot"
        | MedusaStoneGaze -> "medusa_stone_gaze"
        | MeepoDividedWeStand -> "meepo_divided_we_stand"
        | MeepoEarthbind -> "meepo_earthbind"
        | MeepoGeostrike -> "meepo_geostrike"
        | MeepoPoof -> "meepo_poof"
        | MeepoRansack -> "meepo_ransack"
        | MiranaArrow -> "mirana_arrow"
        | MiranaInvis -> "mirana_invis"
        | MiranaLeap -> "mirana_leap"
        | MiranaStarfall -> "mirana_starfall"
        | MonkeyKingBoundlessStrike -> "monkey_king_boundless_strike"
        | MonkeyKingJinguMastery -> "monkey_king_jingu_mastery"
        | MonkeyKingMischief -> "monkey_king_mischief"
        | MonkeyKingPrimalSpring -> "monkey_king_primal_spring"
        | MonkeyKingPrimalSpringEarly -> "monkey_king_primal_spring_early"
        | MonkeyKingTreeDance -> "monkey_king_tree_dance"
        | MonkeyKingUntransform -> "monkey_king_untransform"
        | MonkeyKingWukongsCommand -> "monkey_king_wukongs_command"
        | MorphlingAdaptiveStrikeAgi -> "morphling_adaptive_strike_agi"
        | MorphlingAdaptiveStrikeStr -> "morphling_adaptive_strike_str"
        | MorphlingHybrid -> "morphling_hybrid"
        | MorphlingMorph -> "morphling_morph"
        | MorphlingMorphAgi -> "morphling_morph_agi"
        | MorphlingMorphReplicate -> "morphling_morph_replicate"
        | MorphlingMorphStr -> "morphling_morph_str"
        | MorphlingReplicate -> "morphling_replicate"
        | MorphlingWaveform -> "morphling_waveform"
        | MudGolemHurlBoulder -> "mud_golem_hurl_boulder"
        | MudGolemRockDestroy -> "mud_golem_rock_destroy"
        | MudgolemCloakAura -> "mudgolem_cloak_aura"
        | NagaSirenEnsnare -> "naga_siren_ensnare"
        | NagaSirenMirrorImage -> "naga_siren_mirror_image"
        | NagaSirenRipTide -> "naga_siren_rip_tide"
        | NagaSirenSongOfTheSiren -> "naga_siren_song_of_the_siren"
        | NagaSirenSongOfTheSirenCancel -> "naga_siren_song_of_the_siren_cancel"
        | NecrolyteDeathPulse -> "necrolyte_death_pulse"
        | NecrolyteHeartstopperAura -> "necrolyte_heartstopper_aura"
        | NecrolyteReapersScythe -> "necrolyte_reapers_scythe"
        | NecrolyteSadist -> "necrolyte_sadist"
        | NecrolyteSadistStop -> "necrolyte_sadist_stop"
        | NecronomiconArcherAoe -> "necronomicon_archer_aoe"
        | NecronomiconArcherManaBurn -> "necronomicon_archer_mana_burn"
        | NecronomiconArcherPurge -> "necronomicon_archer_purge"
        | NecronomiconWarriorLastWill -> "necronomicon_warrior_last_will"
        | NecronomiconWarriorManaBurn -> "necronomicon_warrior_mana_burn"
        | NecronomiconWarriorSight -> "necronomicon_warrior_sight"
        | NeutralSpellImmunity -> "neutral_spell_immunity"
        | NevermoreDarkLord -> "nevermore_dark_lord"
        | NevermoreNecromastery -> "nevermore_necromastery"
        | NevermoreRequiem -> "nevermore_requiem"
        | NevermoreShadowraze -> "nevermore_shadowraze1"
        | NightStalkerCripplingFear -> "night_stalker_crippling_fear"
        | NightStalkerDarkness -> "night_stalker_darkness"
        | NightStalkerHunterInTheNight -> "night_stalker_hunter_in_the_night"
        | NightStalkerVoid -> "night_stalker_void"
        | NyxAssassinBurrow -> "nyx_assassin_burrow"
        | NyxAssassinImpale -> "nyx_assassin_impale"
        | NyxAssassinManaBurn -> "nyx_assassin_mana_burn"
        | NyxAssassinSpikedCarapace -> "nyx_assassin_spiked_carapace"
        | NyxAssassinUnburrow -> "nyx_assassin_unburrow"
        | NyxAssassinVendetta -> "nyx_assassin_vendetta"
        | ObsidianDestroyerArcaneOrb -> "obsidian_destroyer_arcane_orb"
        | ObsidianDestroyerAstralImprisonment -> "obsidian_destroyer_astral_imprisonment"
        | ObsidianDestroyerEquilibrium -> "obsidian_destroyer_equilibrium"
        | ObsidianDestroyerEssenceAura -> "obsidian_destroyer_essence_aura"
        | ObsidianDestroyerSanityEclipse -> "obsidian_destroyer_sanity_eclipse"
        | OgreMagiBloodlust -> "ogre_magi_bloodlust"
        | OgreMagiFireblast -> "ogre_magi_fireblast"
        | OgreMagiFrostArmor -> "ogre_magi_frost_armor"
        | OgreMagiIgnite -> "ogre_magi_ignite"
        | OgreMagiMulticast -> "ogre_magi_multicast"
        | OgreMagiUnrefinedFireblast -> "ogre_magi_unrefined_fireblast"
        | OmniknightDegenAura -> "omniknight_degen_aura"
        | OmniknightGuardianAngel -> "omniknight_guardian_angel"
        | OmniknightPacify -> "omniknight_pacify"
        | OmniknightPurification -> "omniknight_purification"
        | OmniknightRepel -> "omniknight_repel"
        | OracleFalsePromise -> "oracle_false_promise"
        | OracleFatesEdict -> "oracle_fates_edict"
        | OracleFortunesEnd -> "oracle_fortunes_end"
        | OraclePurifyingFlames -> "oracle_purifying_flames"
        | PangolierGyroshell -> "pangolier_gyroshell"
        | PangolierGyroshellStop -> "pangolier_gyroshell_stop"
        | PangolierHeartpiercer -> "pangolier_heartpiercer"
        | PangolierLuckyShot -> "pangolier_lucky_shot"
        | PangolierShieldCrash -> "pangolier_shield_crash"
        | PangolierSwashbuckle -> "pangolier_swashbuckle"
        | PhantomAssassinBlur -> "phantom_assassin_blur"
        | PhantomAssassinCoupDeGrace -> "phantom_assassin_coup_de_grace"
        | PhantomAssassinPhantomStrike -> "phantom_assassin_phantom_strike"
        | PhantomAssassinStiflingDagger -> "phantom_assassin_stifling_dagger"
        | PhantomLancerDoppelwalk -> "phantom_lancer_doppelwalk"
        | PhantomLancerJuxtapose -> "phantom_lancer_juxtapose"
        | PhantomLancerPhantomEdge -> "phantom_lancer_phantom_edge"
        | PhantomLancerSpiritLance -> "phantom_lancer_spirit_lance"
        | PhoenixFireSpirits -> "phoenix_fire_spirits"
        | PhoenixIcarusDive -> "phoenix_icarus_dive"
        | PhoenixIcarusDiveStop -> "phoenix_icarus_dive_stop"
        | PhoenixLaunchFireSpirit -> "phoenix_launch_fire_spirit"
        | PhoenixSunRay -> "phoenix_sun_ray"
        | PhoenixSunRayStop -> "phoenix_sun_ray_stop"
        | PhoenixSunRayToggleMove -> "phoenix_sun_ray_toggle_move"
        | PhoenixSupernova -> "phoenix_supernova"
        | PolarFurbolgUrsaWarriorThunderClap -> "polar_furbolg_ursa_warrior_thunder_clap"
        | PuckDreamCoil -> "puck_dream_coil"
        | PuckEtherealJaunt -> "puck_ethereal_jaunt"
        | PuckIllusoryOrb -> "puck_illusory_orb"
        | PuckPhaseShift -> "puck_phase_shift"
        | PuckWaningRift -> "puck_waning_rift"
        | PudgeDismember -> "pudge_dismember"
        | PudgeFleshHeap -> "pudge_flesh_heap"
        | PudgeMeatHook -> "pudge_meat_hook"
        | PudgeRot -> "pudge_rot"
        | PugnaDecrepify -> "pugna_decrepify"
        | PugnaLifeDrain -> "pugna_life_drain"
        | PugnaNetherBlast -> "pugna_nether_blast"
        | PugnaNetherWard -> "pugna_nether_ward"
        | QueenofpainBlink -> "queenofpain_blink"
        | QueenofpainScreamOfPain -> "queenofpain_scream_of_pain"
        | QueenofpainShadowStrike -> "queenofpain_shadow_strike"
        | QueenofpainSonicWave -> "queenofpain_sonic_wave"
        | RattletrapBatteryAssault -> "rattletrap_battery_assault"
        | RattletrapHookshot -> "rattletrap_hookshot"
        | RattletrapPowerCogs -> "rattletrap_power_cogs"
        | RattletrapRocketFlare -> "rattletrap_rocket_flare"
        | RazorEyeOfTheStorm -> "razor_eye_of_the_storm"
        | RazorPlasmaField -> "razor_plasma_field"
        | RazorStaticLink -> "razor_static_link"
        | RazorUnstableCurrent -> "razor_unstable_current"
        | RikiBlinkStrike -> "riki_blink_strike"
        | RikiPermanentInvisibility -> "riki_permanent_invisibility"
        | RikiSmokeScreen -> "riki_smoke_screen"
        | RikiTricksOfTheTrade -> "riki_tricks_of_the_trade"
        | RoshanBash -> "roshan_bash"
        | RoshanDevotion -> "roshan_devotion"
        | RoshanInherentBuffs -> "roshan_inherent_buffs"
        | RoshanSlam -> "roshan_slam"
        | RoshanSpellBlock -> "roshan_spell_block"
        | RubickArcaneSupremacy -> "rubick_arcane_supremacy"
        | RubickEmpty -> "rubick_empty1"
        | RubickFadeBolt -> "rubick_fade_bolt"
        | RubickHidden -> "rubick_hidden1"
        | RubickNullField -> "rubick_null_field"
        | RubickSpellSteal -> "rubick_spell_steal"
        | RubickTelekinesis -> "rubick_telekinesis"
        | RubickTelekinesisLand -> "rubick_telekinesis_land"
        | SandkingBurrowstrike -> "sandking_burrowstrike"
        | SandkingCausticFinale -> "sandking_caustic_finale"
        | SandkingEpicenter -> "sandking_epicenter"
        | SandkingSandStorm -> "sandking_sand_storm"
        | SatyrHellcallerShockwave -> "satyr_hellcaller_shockwave"
        | SatyrHellcallerUnholyAura -> "satyr_hellcaller_unholy_aura"
        | SatyrSoulstealerManaBurn -> "satyr_soulstealer_mana_burn"
        | SatyrTricksterPurge -> "satyr_trickster_purge"
        | ShadowDemonDemonicPurge -> "shadow_demon_demonic_purge"
        | ShadowDemonDisruption -> "shadow_demon_disruption"
        | ShadowDemonShadowPoison -> "shadow_demon_shadow_poison"
        | ShadowDemonShadowPoisonRelease -> "shadow_demon_shadow_poison_release"
        | ShadowDemonSoulCatcher -> "shadow_demon_soul_catcher"
        | ShadowShamanEtherShock -> "shadow_shaman_ether_shock"
        | ShadowShamanMassSerpentWard -> "shadow_shaman_mass_serpent_ward"
        | ShadowShamanShackles -> "shadow_shaman_shackles"
        | ShadowShamanVoodoo -> "shadow_shaman_voodoo"
        | ShootFirework -> "shoot_firework"
        | ShredderChakram -> "shredder_chakram"
        | ShredderReactiveArmor -> "shredder_reactive_armor"
        | ShredderReturnChakram -> "shredder_return_chakram"
        | ShredderTimberChain -> "shredder_timber_chain"
        | ShredderWhirlingDeath -> "shredder_whirling_death"
        | SilencerCurseOfTheSilent -> "silencer_curse_of_the_silent"
        | SilencerGlaivesOfWisdom -> "silencer_glaives_of_wisdom"
        | SilencerGlobalSilence -> "silencer_global_silence"
        | SilencerLastWord -> "silencer_last_word"
        | SkeletonKingHellfireBlast -> "skeleton_king_hellfire_blast"
        | SkeletonKingMortalStrike -> "skeleton_king_mortal_strike"
        | SkeletonKingReincarnation -> "skeleton_king_reincarnation"
        | SkeletonKingVampiricAura -> "skeleton_king_vampiric_aura"
        | SkywrathMageAncientSeal -> "skywrath_mage_ancient_seal"
        | SkywrathMageArcaneBolt -> "skywrath_mage_arcane_bolt"
        | SkywrathMageConcussiveShot -> "skywrath_mage_concussive_shot"
        | SkywrathMageMysticFlare -> "skywrath_mage_mystic_flare"
        | SlardarAmplifyDamage -> "slardar_amplify_damage"
        | SlardarBash -> "slardar_bash"
        | SlardarSlithereenCrush -> "slardar_slithereen_crush"
        | SlardarSprint -> "slardar_sprint"
        | SlarkDarkPact -> "slark_dark_pact"
        | SlarkEssenceShift -> "slark_essence_shift"
        | SlarkPounce -> "slark_pounce"
        | SlarkShadowDance -> "slark_shadow_dance"
        | SniperAssassinate -> "sniper_assassinate"
        | SniperHeadshot -> "sniper_headshot"
        | SniperShrapnel -> "sniper_shrapnel"
        | SniperTakeAim -> "sniper_take_aim"
        | SpawnlordAura -> "spawnlord_aura"
        | SpawnlordMasterBash -> "spawnlord_master_bash"
        | SpawnlordMasterFreeze -> "spawnlord_master_freeze"
        | SpawnlordMasterStomp -> "spawnlord_master_stomp"
        | SpectreDesolate -> "spectre_desolate"
        | SpectreDispersion -> "spectre_dispersion"
        | SpectreHaunt -> "spectre_haunt"
        | SpectreReality -> "spectre_reality"
        | SpectreSpectralDagger -> "spectre_spectral_dagger"
        | SpiritBreakerBulldoze -> "spirit_breaker_bulldoze"
        | SpiritBreakerChargeOfDarkness -> "spirit_breaker_charge_of_darkness"
        | SpiritBreakerEmpoweringHaste -> "spirit_breaker_empowering_haste"
        | SpiritBreakerGreaterBash -> "spirit_breaker_greater_bash"
        | SpiritBreakerNetherStrike -> "spirit_breaker_nether_strike"
        | StormSpiritBallLightning -> "storm_spirit_ball_lightning"
        | StormSpiritElectricVortex -> "storm_spirit_electric_vortex"
        | StormSpiritOverload -> "storm_spirit_overload"
        | StormSpiritStaticRemnant -> "storm_spirit_static_remnant"
        | SvenGodsStrength -> "sven_gods_strength"
        | SvenGreatCleave -> "sven_great_cleave"
        | SvenStormBolt -> "sven_storm_bolt"
        | SvenWarcry -> "sven_warcry"
        | TechiesFocusedDetonate -> "techies_focused_detonate"
        | TechiesLandMines -> "techies_land_mines"
        | TechiesMinefieldSign -> "techies_minefield_sign"
        | TechiesRemoteMines -> "techies_remote_mines"
        | TechiesRemoteMinesSelfDetonate -> "techies_remote_mines_self_detonate"
        | TechiesStasisTrap -> "techies_stasis_trap"
        | TechiesSuicide -> "techies_suicide"
        | TemplarAssassinMeld -> "templar_assassin_meld"
        | TemplarAssassinPsiBlades -> "templar_assassin_psi_blades"
        | TemplarAssassinPsionicTrap -> "templar_assassin_psionic_trap"
        | TemplarAssassinRefraction -> "templar_assassin_refraction"
        | TemplarAssassinSelfTrap -> "templar_assassin_self_trap"
        | TemplarAssassinTrap -> "templar_assassin_trap"
        | TerrorbladeConjureImage -> "terrorblade_conjure_image"
        | TerrorbladeMetamorphosis -> "terrorblade_metamorphosis"
        | TerrorbladeReflection -> "terrorblade_reflection"
        | TerrorbladeSunder -> "terrorblade_sunder"
        | TidehunterAnchorSmash -> "tidehunter_anchor_smash"
        | TidehunterGush -> "tidehunter_gush"
        | TidehunterKrakenShell -> "tidehunter_kraken_shell"
        | TidehunterRavage -> "tidehunter_ravage"
        | TinkerHeatSeekingMissile -> "tinker_heat_seeking_missile"
        | TinkerLaser -> "tinker_laser"
        | TinkerMarchOfTheMachines -> "tinker_march_of_the_machines"
        | TinkerRearm -> "tinker_rearm"
        | TinyAvalanche -> "tiny_avalanche"
        | TinyCraggyExterior -> "tiny_craggy_exterior"
        | TinyGrow -> "tiny_grow"
        | TinyToss -> "tiny_toss"
        | TinyTossTree -> "tiny_toss_tree"
        | TornadoTempest -> "tornado_tempest"
        | TreantEyesInTheForest -> "treant_eyes_in_the_forest"
        | TreantLeechSeed -> "treant_leech_seed"
        | TreantLivingArmor -> "treant_living_armor"
        | TreantNaturesGuise -> "treant_natures_guise"
        | TreantOvergrowth -> "treant_overgrowth"
        | TrollWarlordBattleTrance -> "troll_warlord_battle_trance"
        | TrollWarlordBerserkersRage -> "troll_warlord_berserkers_rage"
        | TrollWarlordFervor -> "troll_warlord_fervor"
        | TrollWarlordWhirlingAxesMelee -> "troll_warlord_whirling_axes_melee"
        | TrollWarlordWhirlingAxesRanged -> "troll_warlord_whirling_axes_ranged"
        | TuskFrozenSigil -> "tusk_frozen_sigil"
        | TuskIceShards -> "tusk_ice_shards"
        | TuskIceShardsStop -> "tusk_ice_shards_stop"
        | TuskLaunchSnowball -> "tusk_launch_snowball"
        | TuskSnowball -> "tusk_snowball"
        | TuskTagTeam -> "tusk_tag_team"
        | TuskWalrusKick -> "tusk_walrus_kick"
        | TuskWalrusPunch -> "tusk_walrus_punch"
        | UndyingDecay -> "undying_decay"
        | UndyingFleshGolem -> "undying_flesh_golem"
        | UndyingSoulRip -> "undying_soul_rip"
        | UndyingTombstone -> "undying_tombstone"
        | UndyingTombstoneZombieAura -> "undying_tombstone_zombie_aura"
        | UndyingTombstoneZombieDeathstrike -> "undying_tombstone_zombie_deathstrike"
        | UrsaEarthshock -> "ursa_earthshock"
        | UrsaEnrage -> "ursa_enrage"
        | UrsaFurySwipes -> "ursa_fury_swipes"
        | UrsaOverpower -> "ursa_overpower"
        | VengefulspiritCommandAura -> "vengefulspirit_command_aura"
        | VengefulspiritMagicMissile -> "vengefulspirit_magic_missile"
        | VengefulspiritNetherSwap -> "vengefulspirit_nether_swap"
        | VengefulspiritWaveOfTerror -> "vengefulspirit_wave_of_terror"
        | VenomancerPlagueWard -> "venomancer_plague_ward"
        | VenomancerPoisonNova -> "venomancer_poison_nova"
        | VenomancerPoisonSting -> "venomancer_poison_sting"
        | VenomancerVenomousGale -> "venomancer_venomous_gale"
        | ViperCorrosiveSkin -> "viper_corrosive_skin"
        | ViperNethertoxin -> "viper_nethertoxin"
        | ViperPoisonAttack -> "viper_poison_attack"
        | ViperViperStrike -> "viper_viper_strike"
        | VisageGraveChill -> "visage_grave_chill"
        | VisageGravekeepersCloak -> "visage_gravekeepers_cloak"
        | VisageSoulAssumption -> "visage_soul_assumption"
        | VisageStoneFormSelfCast -> "visage_stone_form_self_cast"
        | VisageSummonFamiliars -> "visage_summon_familiars"
        | VisageSummonFamiliarsStoneForm -> "visage_summon_familiars_stone_form"
        | WarlockFatalBonds -> "warlock_fatal_bonds"
        | WarlockGolemFlamingFists -> "warlock_golem_flaming_fists"
        | WarlockGolemPermanentImmolation -> "warlock_golem_permanent_immolation"
        | WarlockRainOfChaos -> "warlock_rain_of_chaos"
        | WarlockShadowWord -> "warlock_shadow_word"
        | WarlockUpheaval -> "warlock_upheaval"
        | WeaverGeminateAttack -> "weaver_geminate_attack"
        | WeaverShukuchi -> "weaver_shukuchi"
        | WeaverTheSwarm -> "weaver_the_swarm"
        | WeaverTimeLapse -> "weaver_time_lapse"
        | WindrunnerFocusfire -> "windrunner_focusfire"
        | WindrunnerPowershot -> "windrunner_powershot"
        | WindrunnerShackleshot -> "windrunner_shackleshot"
        | WindrunnerWindrun -> "windrunner_windrun"
        | WinterWyvernArcticBurn -> "winter_wyvern_arctic_burn"
        | WinterWyvernColdEmbrace -> "winter_wyvern_cold_embrace"
        | WinterWyvernSplinterBlast -> "winter_wyvern_splinter_blast"
        | WinterWyvernWintersCurse -> "winter_wyvern_winters_curse"
        | WispOvercharge -> "wisp_overcharge"
        | WispRelocate -> "wisp_relocate"
        | WispSpirits -> "wisp_spirits"
        | WispSpiritsIn -> "wisp_spirits_in"
        | WispSpiritsOut -> "wisp_spirits_out"
        | WispTether -> "wisp_tether"
        | WispTetherBreak -> "wisp_tether_break"
        | WitchDoctorDeathWard -> "witch_doctor_death_ward"
        | WitchDoctorMaledict -> "witch_doctor_maledict"
        | WitchDoctorParalyzingCask -> "witch_doctor_paralyzing_cask"
        | WitchDoctorVoodooRestoration -> "witch_doctor_voodoo_restoration"
        | ZuusArcLightning -> "zuus_arc_lightning"
        | ZuusCloud -> "zuus_cloud"
        | ZuusLightningBolt -> "zuus_lightning_bolt"
        | ZuusStaticField -> "zuus_static_field"
        | ZuusThundergodsWrath -> "zuus_thundergods_wrath"

    /// <summary>
    ///     Deserialise a Dota 2 ability that was serialised using <c>toId</c>.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="id">
    ///     The serialised Dota2 ability.
    /// </param>
    ///
    /// <returns>
    ///     Ability, or an error message if the hero could not be deserialised.
    /// </returns>
    let fromId (id : string) : Result<Ability, string> =
        match id with
        | "abaddon_aphotic_shield" -> Ok Ability.AbaddonAphoticShield
        | "abaddon_borrowed_time" -> Ok Ability.AbaddonBorrowedTime
        | "abaddon_death_coil" -> Ok Ability.AbaddonDeathCoil
        | "abaddon_frostmourne" -> Ok Ability.AbaddonFrostmourne
        | "abyssal_underlord_atrophy_aura" -> Ok Ability.AbyssalUnderlordAtrophyAura
        | "abyssal_underlord_cancel_dark_rift" -> Ok Ability.AbyssalUnderlordCancelDarkRift
        | "abyssal_underlord_dark_rift" -> Ok Ability.AbyssalUnderlordDarkRift
        | "abyssal_underlord_firestorm" -> Ok Ability.AbyssalUnderlordFirestorm
        | "abyssal_underlord_pit_of_malice" -> Ok Ability.AbyssalUnderlordPitOfMalice
        | "alchemist_acid_spray" -> Ok Ability.AlchemistAcidSpray
        | "alchemist_chemical_rage" -> Ok Ability.AlchemistChemicalRage
        | "alchemist_goblins_greed" -> Ok Ability.AlchemistGoblinsGreed
        | "alchemist_unstable_concoction" -> Ok Ability.AlchemistUnstableConcoction
        | "alchemist_unstable_concoction_throw" -> Ok Ability.AlchemistUnstableConcoctionThrow
        | "alpha_wolf_command_aura" -> Ok Ability.AlphaWolfCommandAura
        | "alpha_wolf_critical_strike" -> Ok Ability.AlphaWolfCriticalStrike
        | "ancient_apparition_chilling_touch" -> Ok Ability.AncientApparitionChillingTouch
        | "ancient_apparition_cold_feet" -> Ok Ability.AncientApparitionColdFeet
        | "ancient_apparition_ice_blast" -> Ok Ability.AncientApparitionIceBlast
        | "ancient_apparition_ice_blast_release" -> Ok Ability.AncientApparitionIceBlastRelease
        | "ancient_apparition_ice_vortex" -> Ok Ability.AncientApparitionIceVortex
        | "ancient_golem_rockslide" -> Ok Ability.AncientGolemRockslide
        | "antimage_blink" -> Ok Ability.AntimageBlink
        | "antimage_counterspell" -> Ok Ability.AntimageCounterspell
        | "antimage_mana_break" -> Ok Ability.AntimageManaBreak
        | "antimage_mana_void" -> Ok Ability.AntimageManaVoid
        | "antimage_spell_shield" -> Ok Ability.AntimageSpellShield
        | "arc_warden_flux" -> Ok Ability.ArcWardenFlux
        | "arc_warden_magnetic_field" -> Ok Ability.ArcWardenMagneticField
        | "arc_warden_spark_wraith" -> Ok Ability.ArcWardenSparkWraith
        | "arc_warden_tempest_double" -> Ok Ability.ArcWardenTempestDouble
        | "axe_battle_hunger" -> Ok Ability.AxeBattleHunger
        | "axe_berserkers_call" -> Ok Ability.AxeBerserkersCall
        | "axe_counter_helix" -> Ok Ability.AxeCounterHelix
        | "axe_culling_blade" -> Ok Ability.AxeCullingBlade
        | "backdoor_protection" -> Ok Ability.BackdoorProtection
        | "backdoor_protection_in_base" -> Ok Ability.BackdoorProtectionInBase
        | "bane_brain_sap" -> Ok Ability.BaneBrainSap
        | "bane_enfeeble" -> Ok Ability.BaneEnfeeble
        | "bane_fiends_grip" -> Ok Ability.BaneFiendsGrip
        | "bane_nightmare" -> Ok Ability.BaneNightmare
        | "bane_nightmare_end" -> Ok Ability.BaneNightmareEnd
        | "batrider_firefly" -> Ok Ability.BatriderFirefly
        | "batrider_flamebreak" -> Ok Ability.BatriderFlamebreak
        | "batrider_flaming_lasso" -> Ok Ability.BatriderFlamingLasso
        | "batrider_sticky_napalm" -> Ok Ability.BatriderStickyNapalm
        | "bear_empty1" -> Ok Ability.BearEmpty
        | "beastmaster_boar_poison" -> Ok Ability.BeastmasterBoarPoison
        | "beastmaster_call_of_the_wild" -> Ok Ability.BeastmasterCallOfTheWild
        | "beastmaster_call_of_the_wild_boar" -> Ok Ability.BeastmasterCallOfTheWildBoar
        | "beastmaster_call_of_the_wild_hawk" -> Ok Ability.BeastmasterCallOfTheWildHawk
        | "beastmaster_greater_boar_poison" -> Ok Ability.BeastmasterGreaterBoarPoison
        | "beastmaster_hawk_invisibility" -> Ok Ability.BeastmasterHawkInvisibility
        | "beastmaster_inner_beast" -> Ok Ability.BeastmasterInnerBeast
        | "beastmaster_primal_roar" -> Ok Ability.BeastmasterPrimalRoar
        | "beastmaster_wild_axes" -> Ok Ability.BeastmasterWildAxes
        | "big_thunder_lizard_frenzy" -> Ok Ability.BigThunderLizardFrenzy
        | "big_thunder_lizard_slam" -> Ok Ability.BigThunderLizardSlam
        | "big_thunder_lizard_wardrums_aura" -> Ok Ability.BigThunderLizardWardrumsAura
        | "black_dragon_dragonhide_aura" -> Ok Ability.BlackDragonDragonhideAura
        | "black_dragon_fireball" -> Ok Ability.BlackDragonFireball
        | "black_dragon_splash_attack" -> Ok Ability.BlackDragonSplashAttack
        | "bloodseeker_blood_bath" -> Ok Ability.BloodseekerBloodBath
        | "bloodseeker_bloodrage" -> Ok Ability.BloodseekerBloodrage
        | "bloodseeker_rupture" -> Ok Ability.BloodseekerRupture
        | "bloodseeker_thirst" -> Ok Ability.BloodseekerThirst
        | "blue_dragonspawn_overseer_devotion_aura" -> Ok Ability.BlueDragonspawnOverseerDevotionAura
        | "blue_dragonspawn_overseer_evasion" -> Ok Ability.BlueDragonspawnOverseerEvasion
        | "blue_dragonspawn_sorcerer_evasion" -> Ok Ability.BlueDragonspawnSorcererEvasion
        | "bounty_hunter_jinada" -> Ok Ability.BountyHunterJinada
        | "bounty_hunter_shuriken_toss" -> Ok Ability.BountyHunterShurikenToss
        | "bounty_hunter_track" -> Ok Ability.BountyHunterTrack
        | "bounty_hunter_wind_walk" -> Ok Ability.BountyHunterWindWalk
        | "brewmaster_cinder_brew" -> Ok Ability.BrewmasterCinderBrew
        | "brewmaster_drunken_brawler" -> Ok Ability.BrewmasterDrunkenBrawler
        | "brewmaster_drunken_haze" -> Ok Ability.BrewmasterDrunkenHaze
        | "brewmaster_earth_hurl_boulder" -> Ok Ability.BrewmasterEarthHurlBoulder
        | "brewmaster_earth_pulverize" -> Ok Ability.BrewmasterEarthPulverize
        | "brewmaster_earth_spell_immunity" -> Ok Ability.BrewmasterEarthSpellImmunity
        | "brewmaster_fire_permanent_immolation" -> Ok Ability.BrewmasterFirePermanentImmolation
        | "brewmaster_primal_split" -> Ok Ability.BrewmasterPrimalSplit
        | "brewmaster_storm_cyclone" -> Ok Ability.BrewmasterStormCyclone
        | "brewmaster_storm_dispel_magic" -> Ok Ability.BrewmasterStormDispelMagic
        | "brewmaster_storm_wind_walk" -> Ok Ability.BrewmasterStormWindWalk
        | "brewmaster_thunder_clap" -> Ok Ability.BrewmasterThunderClap
        | "bristleback_bristleback" -> Ok Ability.BristlebackBristleback
        | "bristleback_quill_spray" -> Ok Ability.BristlebackQuillSpray
        | "bristleback_viscous_nasal_goo" -> Ok Ability.BristlebackViscousNasalGoo
        | "bristleback_warpath" -> Ok Ability.BristlebackWarpath
        | "broodmother_incapacitating_bite" -> Ok Ability.BroodmotherIncapacitatingBite
        | "broodmother_insatiable_hunger" -> Ok Ability.BroodmotherInsatiableHunger
        | "broodmother_poison_sting" -> Ok Ability.BroodmotherPoisonSting
        | "broodmother_spawn_spiderite" -> Ok Ability.BroodmotherSpawnSpiderite
        | "broodmother_spawn_spiderlings" -> Ok Ability.BroodmotherSpawnSpiderlings
        | "broodmother_spin_web" -> Ok Ability.BroodmotherSpinWeb
        | "broodmother_spin_web_destroy" -> Ok Ability.BroodmotherSpinWebDestroy
        | "centaur_double_edge" -> Ok Ability.CentaurDoubleEdge
        | "centaur_hoof_stomp" -> Ok Ability.CentaurHoofStomp
        | "centaur_return" -> Ok Ability.CentaurReturn
        | "centaur_stampede" -> Ok Ability.CentaurStampede
        | "chaos_knight_chaos_bolt" -> Ok Ability.ChaosKnightChaosBolt
        | "chaos_knight_chaos_strike" -> Ok Ability.ChaosKnightChaosStrike
        | "chaos_knight_phantasm" -> Ok Ability.ChaosKnightPhantasm
        | "chaos_knight_reality_rift" -> Ok Ability.ChaosKnightRealityRift
        | "chen_divine_favor" -> Ok Ability.ChenDivineFavor
        | "chen_hand_of_god" -> Ok Ability.ChenHandOfGod
        | "chen_holy_persuasion" -> Ok Ability.ChenHolyPersuasion
        | "chen_penitence" -> Ok Ability.ChenPenitence
        | "chen_test_of_faith" -> Ok Ability.ChenTestOfFaith
        | "chen_test_of_faith_teleport" -> Ok Ability.ChenTestOfFaithTeleport
        | "clinkz_burning_army" -> Ok Ability.ClinkzBurningArmy
        | "clinkz_death_pact" -> Ok Ability.ClinkzDeathPact
        | "clinkz_searing_arrows" -> Ok Ability.ClinkzSearingArrows
        | "clinkz_strafe" -> Ok Ability.ClinkzStrafe
        | "clinkz_wind_walk" -> Ok Ability.ClinkzWindWalk
        | "cny_beast_force_attack" -> Ok Ability.CnyBeastForceAttack
        | "cny_beast_teleport" -> Ok Ability.CnyBeastTeleport
        | "consumable_hidden" -> Ok Ability.ConsumableHidden
        | "courier_autodeliver" -> Ok Ability.CourierAutodeliver
        | "courier_burst" -> Ok Ability.CourierBurst
        | "courier_go_to_enemy_secretshop" -> Ok Ability.CourierGoToEnemySecretshop
        | "courier_go_to_secretshop" -> Ok Ability.CourierGoToSecretshop
        | "courier_go_to_sideshop" -> Ok Ability.CourierGoToSideshop
        | "courier_morph" -> Ok Ability.CourierMorph
        | "courier_return_stash_items" -> Ok Ability.CourierReturnStashItems
        | "courier_return_to_base" -> Ok Ability.CourierReturnToBase
        | "courier_shield" -> Ok Ability.CourierShield
        | "courier_take_stash_and_transfer_items" -> Ok Ability.CourierTakeStashAndTransferItems
        | "courier_take_stash_items" -> Ok Ability.CourierTakeStashItems
        | "courier_transfer_items" -> Ok Ability.CourierTransferItems
        | "courier_transfer_items_to_other_player" -> Ok Ability.CourierTransferItemsToOtherPlayer
        | "crystal_maiden_brilliance_aura" -> Ok Ability.CrystalMaidenBrillianceAura
        | "crystal_maiden_crystal_nova" -> Ok Ability.CrystalMaidenCrystalNova
        | "crystal_maiden_freezing_field" -> Ok Ability.CrystalMaidenFreezingField
        | "crystal_maiden_frostbite" -> Ok Ability.CrystalMaidenFrostbite
        | "dark_seer_ion_shell" -> Ok Ability.DarkSeerIonShell
        | "dark_seer_surge" -> Ok Ability.DarkSeerSurge
        | "dark_seer_vacuum" -> Ok Ability.DarkSeerVacuum
        | "dark_seer_wall_of_replica" -> Ok Ability.DarkSeerWallOfReplica
        | "dark_troll_warlord_ensnare" -> Ok Ability.DarkTrollWarlordEnsnare
        | "dark_troll_warlord_raise_dead" -> Ok Ability.DarkTrollWarlordRaiseDead
        | "dark_willow_bedlam" -> Ok Ability.DarkWillowBedlam
        | "dark_willow_bramble_maze" -> Ok Ability.DarkWillowBrambleMaze
        | "dark_willow_cursed_crown" -> Ok Ability.DarkWillowCursedCrown
        | "dark_willow_shadow_realm" -> Ok Ability.DarkWillowShadowRealm
        | "dark_willow_terrorize" -> Ok Ability.DarkWillowTerrorize
        | "dazzle_bad_juju" -> Ok Ability.DazzleBadJuju
        | "dazzle_poison_touch" -> Ok Ability.DazzlePoisonTouch
        | "dazzle_shadow_wave" -> Ok Ability.DazzleShadowWave
        | "dazzle_shallow_grave" -> Ok Ability.DazzleShallowGrave
        | "dazzle_weave" -> Ok Ability.DazzleWeave
        | "death_prophet_carrion_swarm" -> Ok Ability.DeathProphetCarrionSwarm
        | "death_prophet_exorcism" -> Ok Ability.DeathProphetExorcism
        | "death_prophet_silence" -> Ok Ability.DeathProphetSilence
        | "death_prophet_spirit_siphon" -> Ok Ability.DeathProphetSpiritSiphon
        | "death_prophet_witchcraft" -> Ok Ability.DeathProphetWitchcraft
        | "disruptor_glimpse" -> Ok Ability.DisruptorGlimpse
        | "disruptor_kinetic_field" -> Ok Ability.DisruptorKineticField
        | "disruptor_static_storm" -> Ok Ability.DisruptorStaticStorm
        | "disruptor_thunder_strike" -> Ok Ability.DisruptorThunderStrike
        | "doom_bringer_devour" -> Ok Ability.DoomBringerDevour
        | "doom_bringer_doom" -> Ok Ability.DoomBringerDoom
        | "doom_bringer_empty1" -> Ok Ability.DoomBringerEmpty
        | "doom_bringer_infernal_blade" -> Ok Ability.DoomBringerInfernalBlade
        | "doom_bringer_scorched_earth" -> Ok Ability.DoomBringerScorchedEarth
        | "dota_base_ability" -> Ok Ability.DotaBaseAbility
        | "dragon_knight_breathe_fire" -> Ok Ability.DragonKnightBreatheFire
        | "dragon_knight_dragon_blood" -> Ok Ability.DragonKnightDragonBlood
        | "dragon_knight_dragon_tail" -> Ok Ability.DragonKnightDragonTail
        | "dragon_knight_elder_dragon_form" -> Ok Ability.DragonKnightElderDragonForm
        | "drow_ranger_frost_arrows" -> Ok Ability.DrowRangerFrostArrows
        | "drow_ranger_marksmanship" -> Ok Ability.DrowRangerMarksmanship
        | "drow_ranger_silence" -> Ok Ability.DrowRangerSilence
        | "drow_ranger_trueshot" -> Ok Ability.DrowRangerTrueshot
        | "drow_ranger_wave_of_silence" -> Ok Ability.DrowRangerWaveOfSilence
        | "earth_spirit_boulder_smash" -> Ok Ability.EarthSpiritBoulderSmash
        | "earth_spirit_geomagnetic_grip" -> Ok Ability.EarthSpiritGeomagneticGrip
        | "earth_spirit_magnetize" -> Ok Ability.EarthSpiritMagnetize
        | "earth_spirit_petrify" -> Ok Ability.EarthSpiritPetrify
        | "earth_spirit_rolling_boulder" -> Ok Ability.EarthSpiritRollingBoulder
        | "earth_spirit_stone_caller" -> Ok Ability.EarthSpiritStoneCaller
        | "earthshaker_aftershock" -> Ok Ability.EarthshakerAftershock
        | "earthshaker_echo_slam" -> Ok Ability.EarthshakerEchoSlam
        | "earthshaker_enchant_totem" -> Ok Ability.EarthshakerEnchantTotem
        | "earthshaker_fissure" -> Ok Ability.EarthshakerFissure
        | "elder_titan_ancestral_spirit" -> Ok Ability.ElderTitanAncestralSpirit
        | "elder_titan_earth_splitter" -> Ok Ability.ElderTitanEarthSplitter
        | "elder_titan_echo_stomp" -> Ok Ability.ElderTitanEchoStomp
        | "elder_titan_echo_stomp_spirit" -> Ok Ability.ElderTitanEchoStompSpirit
        | "elder_titan_natural_order" -> Ok Ability.ElderTitanNaturalOrder
        | "elder_titan_natural_order_spirit" -> Ok Ability.ElderTitanNaturalOrderSpirit
        | "elder_titan_return_spirit" -> Ok Ability.ElderTitanReturnSpirit
        | "ember_spirit_activate_fire_remnant" -> Ok Ability.EmberSpiritActivateFireRemnant
        | "ember_spirit_fire_remnant" -> Ok Ability.EmberSpiritFireRemnant
        | "ember_spirit_flame_guard" -> Ok Ability.EmberSpiritFlameGuard
        | "ember_spirit_searing_chains" -> Ok Ability.EmberSpiritSearingChains
        | "ember_spirit_sleight_of_fist" -> Ok Ability.EmberSpiritSleightOfFist
        | "enchantress_enchant" -> Ok Ability.EnchantressEnchant
        | "enchantress_impetus" -> Ok Ability.EnchantressImpetus
        | "enchantress_natures_attendants" -> Ok Ability.EnchantressNaturesAttendants
        | "enchantress_untouchable" -> Ok Ability.EnchantressUntouchable
        | "enigma_black_hole" -> Ok Ability.EnigmaBlackHole
        | "enigma_demonic_conversion" -> Ok Ability.EnigmaDemonicConversion
        | "enigma_malefice" -> Ok Ability.EnigmaMalefice
        | "enigma_midnight_pulse" -> Ok Ability.EnigmaMidnightPulse
        | "enraged_wildkin_tornado" -> Ok Ability.EnragedWildkinTornado
        | "enraged_wildkin_toughness_aura" -> Ok Ability.EnragedWildkinToughnessAura
        | "faceless_void_backtrack" -> Ok Ability.FacelessVoidBacktrack
        | "faceless_void_chronosphere" -> Ok Ability.FacelessVoidChronosphere
        | "faceless_void_time_dilation" -> Ok Ability.FacelessVoidTimeDilation
        | "faceless_void_time_lock" -> Ok Ability.FacelessVoidTimeLock
        | "faceless_void_time_walk" -> Ok Ability.FacelessVoidTimeWalk
        | "filler_ability" -> Ok Ability.FillerAbility
        | "forest_troll_high_priest_heal" -> Ok Ability.ForestTrollHighPriestHeal
        | "forest_troll_high_priest_mana_aura" -> Ok Ability.ForestTrollHighPriestManaAura
        | "forged_spirit_melting_strike" -> Ok Ability.ForgedSpiritMeltingStrike
        | "frostivus2018_decorate_tree" -> Ok Ability.FrostivusDecorateTree
        | "frostivus2018_festive_firework" -> Ok Ability.FrostivusFestiveFirework
        | "frostivus2018_summon_snowman" -> Ok Ability.FrostivusSummonSnowman
        | "frostivus2018_throw_snowball" -> Ok Ability.FrostivusThrowSnowball
        | "furion_force_of_nature" -> Ok Ability.FurionForceOfNature
        | "furion_sprout" -> Ok Ability.FurionSprout
        | "furion_teleportation" -> Ok Ability.FurionTeleportation
        | "furion_wrath_of_nature" -> Ok Ability.FurionWrathOfNature
        | "generic_hidden" -> Ok Ability.GenericHidden
        | "ghost_frost_attack" -> Ok Ability.GhostFrostAttack
        | "giant_wolf_critical_strike" -> Ok Ability.GiantWolfCriticalStrike
        | "gnoll_assassin_envenomed_weapon" -> Ok Ability.GnollAssassinEnvenomedWeapon
        | "granite_golem_bash" -> Ok Ability.GraniteGolemBash
        | "granite_golem_hp_aura" -> Ok Ability.GraniteGolemHpAura
        | "grimstroke_dark_artistry" -> Ok Ability.GrimstrokeDarkArtistry
        | "grimstroke_ink_creature" -> Ok Ability.GrimstrokeInkCreature
        | "grimstroke_soul_chain" -> Ok Ability.GrimstrokeSoulChain
        | "grimstroke_spirit_walk" -> Ok Ability.GrimstrokeSpiritWalk
        | "gyrocopter_call_down" -> Ok Ability.GyrocopterCallDown
        | "gyrocopter_flak_cannon" -> Ok Ability.GyrocopterFlakCannon
        | "gyrocopter_homing_missile" -> Ok Ability.GyrocopterHomingMissile
        | "gyrocopter_rocket_barrage" -> Ok Ability.GyrocopterRocketBarrage
        | "harpy_storm_chain_lightning" -> Ok Ability.HarpyStormChainLightning
        | "healing_campfire" -> Ok Ability.HealingCampfire
        | "huskar_berserkers_blood" -> Ok Ability.HuskarBerserkersBlood
        | "huskar_burning_spear" -> Ok Ability.HuskarBurningSpear
        | "huskar_inner_fire" -> Ok Ability.HuskarInnerFire
        | "huskar_inner_vitality" -> Ok Ability.HuskarInnerVitality
        | "huskar_life_break" -> Ok Ability.HuskarLifeBreak
        | "invoker_alacrity" -> Ok Ability.InvokerAlacrity
        | "invoker_chaos_meteor" -> Ok Ability.InvokerChaosMeteor
        | "invoker_cold_snap" -> Ok Ability.InvokerColdSnap
        | "invoker_deafening_blast" -> Ok Ability.InvokerDeafeningBlast
        | "invoker_emp" -> Ok Ability.InvokerEmp
        | "invoker_empty1" -> Ok Ability.InvokerEmpty
        | "invoker_exort" -> Ok Ability.InvokerExort
        | "invoker_forge_spirit" -> Ok Ability.InvokerForgeSpirit
        | "invoker_ghost_walk" -> Ok Ability.InvokerGhostWalk
        | "invoker_ice_wall" -> Ok Ability.InvokerIceWall
        | "invoker_invoke" -> Ok Ability.InvokerInvoke
        | "invoker_quas" -> Ok Ability.InvokerQuas
        | "invoker_sun_strike" -> Ok Ability.InvokerSunStrike
        | "invoker_tornado" -> Ok Ability.InvokerTornado
        | "invoker_wex" -> Ok Ability.InvokerWex
        | "jakiro_dual_breath" -> Ok Ability.JakiroDualBreath
        | "jakiro_ice_path" -> Ok Ability.JakiroIcePath
        | "jakiro_liquid_fire" -> Ok Ability.JakiroLiquidFire
        | "jakiro_macropyre" -> Ok Ability.JakiroMacropyre
        | "juggernaut_blade_dance" -> Ok Ability.JuggernautBladeDance
        | "juggernaut_blade_fury" -> Ok Ability.JuggernautBladeFury
        | "juggernaut_healing_ward" -> Ok Ability.JuggernautHealingWard
        | "juggernaut_omni_slash" -> Ok Ability.JuggernautOmniSlash
        | "keeper_of_the_light_blinding_light" -> Ok Ability.KeeperOfTheLightBlindingLight
        | "keeper_of_the_light_chakra_magic" -> Ok Ability.KeeperOfTheLightChakraMagic
        | "keeper_of_the_light_empty1" -> Ok Ability.KeeperOfTheLightEmpty
        | "keeper_of_the_light_illuminate" -> Ok Ability.KeeperOfTheLightIlluminate
        | "keeper_of_the_light_illuminate_end" -> Ok Ability.KeeperOfTheLightIlluminateEnd
        | "keeper_of_the_light_mana_leak" -> Ok Ability.KeeperOfTheLightManaLeak
        | "keeper_of_the_light_recall" -> Ok Ability.KeeperOfTheLightRecall
        | "keeper_of_the_light_spirit_form" -> Ok Ability.KeeperOfTheLightSpiritForm
        | "keeper_of_the_light_spirit_form_illuminate" -> Ok Ability.KeeperOfTheLightSpiritFormIlluminate
        | "keeper_of_the_light_spirit_form_illuminate_end" -> Ok Ability.KeeperOfTheLightSpiritFormIlluminateEnd
        | "keeper_of_the_light_will_o_wisp" -> Ok Ability.KeeperOfTheLightWillOWisp
        | "kobold_taskmaster_speed_aura" -> Ok Ability.KoboldTaskmasterSpeedAura
        | "kunkka_ghostship" -> Ok Ability.KunkkaGhostship
        | "kunkka_return" -> Ok Ability.KunkkaReturn
        | "kunkka_tidebringer" -> Ok Ability.KunkkaTidebringer
        | "kunkka_torrent" -> Ok Ability.KunkkaTorrent
        | "kunkka_x_marks_the_spot" -> Ok Ability.KunkkaXMarksTheSpot
        | "legion_commander_duel" -> Ok Ability.LegionCommanderDuel
        | "legion_commander_moment_of_courage" -> Ok Ability.LegionCommanderMomentOfCourage
        | "legion_commander_overwhelming_odds" -> Ok Ability.LegionCommanderOverwhelmingOdds
        | "legion_commander_press_the_attack" -> Ok Ability.LegionCommanderPressTheAttack
        | "leshrac_diabolic_edict" -> Ok Ability.LeshracDiabolicEdict
        | "leshrac_lightning_storm" -> Ok Ability.LeshracLightningStorm
        | "leshrac_pulse_nova" -> Ok Ability.LeshracPulseNova
        | "leshrac_split_earth" -> Ok Ability.LeshracSplitEarth
        | "lich_chain_frost" -> Ok Ability.LichChainFrost
        | "lich_dark_sorcery" -> Ok Ability.LichDarkSorcery
        | "lich_frost_armor" -> Ok Ability.LichFrostArmor
        | "lich_frost_aura" -> Ok Ability.LichFrostAura
        | "lich_frost_nova" -> Ok Ability.LichFrostNova
        | "lich_frost_shield" -> Ok Ability.LichFrostShield
        | "lich_sinister_gaze" -> Ok Ability.LichSinisterGaze
        | "life_stealer_assimilate" -> Ok Ability.LifeStealerAssimilate
        | "life_stealer_assimilate_eject" -> Ok Ability.LifeStealerAssimilateEject
        | "life_stealer_consume" -> Ok Ability.LifeStealerConsume
        | "life_stealer_control" -> Ok Ability.LifeStealerControl
        | "life_stealer_empty_1" -> Ok Ability.LifeStealerEmpty
        | "life_stealer_feast" -> Ok Ability.LifeStealerFeast
        | "life_stealer_infest" -> Ok Ability.LifeStealerInfest
        | "life_stealer_open_wounds" -> Ok Ability.LifeStealerOpenWounds
        | "life_stealer_rage" -> Ok Ability.LifeStealerRage
        | "lina_dragon_slave" -> Ok Ability.LinaDragonSlave
        | "lina_fiery_soul" -> Ok Ability.LinaFierySoul
        | "lina_laguna_blade" -> Ok Ability.LinaLagunaBlade
        | "lina_light_strike_array" -> Ok Ability.LinaLightStrikeArray
        | "lion_finger_of_death" -> Ok Ability.LionFingerOfDeath
        | "lion_impale" -> Ok Ability.LionImpale
        | "lion_mana_drain" -> Ok Ability.LionManaDrain
        | "lion_voodoo" -> Ok Ability.LionVoodoo
        | "lone_druid_rabid" -> Ok Ability.LoneDruidRabid
        | "lone_druid_savage_roar" -> Ok Ability.LoneDruidSavageRoar
        | "lone_druid_savage_roar_bear" -> Ok Ability.LoneDruidSavageRoarBear
        | "lone_druid_spirit_bear" -> Ok Ability.LoneDruidSpiritBear
        | "lone_druid_spirit_bear_defender" -> Ok Ability.LoneDruidSpiritBearDefender
        | "lone_druid_spirit_bear_demolish" -> Ok Ability.LoneDruidSpiritBearDemolish
        | "lone_druid_spirit_bear_entangle" -> Ok Ability.LoneDruidSpiritBearEntangle
        | "lone_druid_spirit_bear_return" -> Ok Ability.LoneDruidSpiritBearReturn
        | "lone_druid_spirit_link" -> Ok Ability.LoneDruidSpiritLink
        | "lone_druid_true_form" -> Ok Ability.LoneDruidTrueForm
        | "lone_druid_true_form_battle_cry" -> Ok Ability.LoneDruidTrueFormBattleCry
        | "lone_druid_true_form_druid" -> Ok Ability.LoneDruidTrueFormDruid
        | "luna_eclipse" -> Ok Ability.LunaEclipse
        | "luna_lucent_beam" -> Ok Ability.LunaLucentBeam
        | "luna_lunar_blessing" -> Ok Ability.LunaLunarBlessing
        | "luna_moon_glaive" -> Ok Ability.LunaMoonGlaive
        | "lycan_feral_impulse" -> Ok Ability.LycanFeralImpulse
        | "lycan_howl" -> Ok Ability.LycanHowl
        | "lycan_shapeshift" -> Ok Ability.LycanShapeshift
        | "lycan_summon_wolves" -> Ok Ability.LycanSummonWolves
        | "lycan_summon_wolves_critical_strike" -> Ok Ability.LycanSummonWolvesCriticalStrike
        | "lycan_summon_wolves_invisibility" -> Ok Ability.LycanSummonWolvesInvisibility
        | "magnataur_empower" -> Ok Ability.MagnataurEmpower
        | "magnataur_reverse_polarity" -> Ok Ability.MagnataurReversePolarity
        | "magnataur_shockwave" -> Ok Ability.MagnataurShockwave
        | "magnataur_skewer" -> Ok Ability.MagnataurSkewer
        | "mars_arena_of_blood" -> Ok Ability.MarsArenaOfBlood
        | "mars_bulwark" -> Ok Ability.MarsBulwark
        | "mars_gods_rebuke" -> Ok Ability.MarsGodsRebuke
        | "mars_spear" -> Ok Ability.MarsSpear
        | "medusa_mana_shield" -> Ok Ability.MedusaManaShield
        | "medusa_mystic_snake" -> Ok Ability.MedusaMysticSnake
        | "medusa_split_shot" -> Ok Ability.MedusaSplitShot
        | "medusa_stone_gaze" -> Ok Ability.MedusaStoneGaze
        | "meepo_divided_we_stand" -> Ok Ability.MeepoDividedWeStand
        | "meepo_earthbind" -> Ok Ability.MeepoEarthbind
        | "meepo_geostrike" -> Ok Ability.MeepoGeostrike
        | "meepo_poof" -> Ok Ability.MeepoPoof
        | "meepo_ransack" -> Ok Ability.MeepoRansack
        | "mirana_arrow" -> Ok Ability.MiranaArrow
        | "mirana_invis" -> Ok Ability.MiranaInvis
        | "mirana_leap" -> Ok Ability.MiranaLeap
        | "mirana_starfall" -> Ok Ability.MiranaStarfall
        | "monkey_king_boundless_strike" -> Ok Ability.MonkeyKingBoundlessStrike
        | "monkey_king_jingu_mastery" -> Ok Ability.MonkeyKingJinguMastery
        | "monkey_king_mischief" -> Ok Ability.MonkeyKingMischief
        | "monkey_king_primal_spring" -> Ok Ability.MonkeyKingPrimalSpring
        | "monkey_king_primal_spring_early" -> Ok Ability.MonkeyKingPrimalSpringEarly
        | "monkey_king_tree_dance" -> Ok Ability.MonkeyKingTreeDance
        | "monkey_king_untransform" -> Ok Ability.MonkeyKingUntransform
        | "monkey_king_wukongs_command" -> Ok Ability.MonkeyKingWukongsCommand
        | "morphling_adaptive_strike_agi" -> Ok Ability.MorphlingAdaptiveStrikeAgi
        | "morphling_adaptive_strike_str" -> Ok Ability.MorphlingAdaptiveStrikeStr
        | "morphling_hybrid" -> Ok Ability.MorphlingHybrid
        | "morphling_morph" -> Ok Ability.MorphlingMorph
        | "morphling_morph_agi" -> Ok Ability.MorphlingMorphAgi
        | "morphling_morph_replicate" -> Ok Ability.MorphlingMorphReplicate
        | "morphling_morph_str" -> Ok Ability.MorphlingMorphStr
        | "morphling_replicate" -> Ok Ability.MorphlingReplicate
        | "morphling_waveform" -> Ok Ability.MorphlingWaveform
        | "mud_golem_hurl_boulder" -> Ok Ability.MudGolemHurlBoulder
        | "mud_golem_rock_destroy" -> Ok Ability.MudGolemRockDestroy
        | "mudgolem_cloak_aura" -> Ok Ability.MudgolemCloakAura
        | "naga_siren_ensnare" -> Ok Ability.NagaSirenEnsnare
        | "naga_siren_mirror_image" -> Ok Ability.NagaSirenMirrorImage
        | "naga_siren_rip_tide" -> Ok Ability.NagaSirenRipTide
        | "naga_siren_song_of_the_siren" -> Ok Ability.NagaSirenSongOfTheSiren
        | "naga_siren_song_of_the_siren_cancel" -> Ok Ability.NagaSirenSongOfTheSirenCancel
        | "necrolyte_death_pulse" -> Ok Ability.NecrolyteDeathPulse
        | "necrolyte_heartstopper_aura" -> Ok Ability.NecrolyteHeartstopperAura
        | "necrolyte_reapers_scythe" -> Ok Ability.NecrolyteReapersScythe
        | "necrolyte_sadist" -> Ok Ability.NecrolyteSadist
        | "necrolyte_sadist_stop" -> Ok Ability.NecrolyteSadistStop
        | "necronomicon_archer_aoe" -> Ok Ability.NecronomiconArcherAoe
        | "necronomicon_archer_mana_burn" -> Ok Ability.NecronomiconArcherManaBurn
        | "necronomicon_archer_purge" -> Ok Ability.NecronomiconArcherPurge
        | "necronomicon_warrior_last_will" -> Ok Ability.NecronomiconWarriorLastWill
        | "necronomicon_warrior_mana_burn" -> Ok Ability.NecronomiconWarriorManaBurn
        | "necronomicon_warrior_sight" -> Ok Ability.NecronomiconWarriorSight
        | "neutral_spell_immunity" -> Ok Ability.NeutralSpellImmunity
        | "nevermore_dark_lord" -> Ok Ability.NevermoreDarkLord
        | "nevermore_necromastery" -> Ok Ability.NevermoreNecromastery
        | "nevermore_requiem" -> Ok Ability.NevermoreRequiem
        | "nevermore_shadowraze1" -> Ok Ability.NevermoreShadowraze
        | "night_stalker_crippling_fear" -> Ok Ability.NightStalkerCripplingFear
        | "night_stalker_darkness" -> Ok Ability.NightStalkerDarkness
        | "night_stalker_hunter_in_the_night" -> Ok Ability.NightStalkerHunterInTheNight
        | "night_stalker_void" -> Ok Ability.NightStalkerVoid
        | "nyx_assassin_burrow" -> Ok Ability.NyxAssassinBurrow
        | "nyx_assassin_impale" -> Ok Ability.NyxAssassinImpale
        | "nyx_assassin_mana_burn" -> Ok Ability.NyxAssassinManaBurn
        | "nyx_assassin_spiked_carapace" -> Ok Ability.NyxAssassinSpikedCarapace
        | "nyx_assassin_unburrow" -> Ok Ability.NyxAssassinUnburrow
        | "nyx_assassin_vendetta" -> Ok Ability.NyxAssassinVendetta
        | "obsidian_destroyer_arcane_orb" -> Ok Ability.ObsidianDestroyerArcaneOrb
        | "obsidian_destroyer_astral_imprisonment" -> Ok Ability.ObsidianDestroyerAstralImprisonment
        | "obsidian_destroyer_equilibrium" -> Ok Ability.ObsidianDestroyerEquilibrium
        | "obsidian_destroyer_essence_aura" -> Ok Ability.ObsidianDestroyerEssenceAura
        | "obsidian_destroyer_sanity_eclipse" -> Ok Ability.ObsidianDestroyerSanityEclipse
        | "ogre_magi_bloodlust" -> Ok Ability.OgreMagiBloodlust
        | "ogre_magi_fireblast" -> Ok Ability.OgreMagiFireblast
        | "ogre_magi_frost_armor" -> Ok Ability.OgreMagiFrostArmor
        | "ogre_magi_ignite" -> Ok Ability.OgreMagiIgnite
        | "ogre_magi_multicast" -> Ok Ability.OgreMagiMulticast
        | "ogre_magi_unrefined_fireblast" -> Ok Ability.OgreMagiUnrefinedFireblast
        | "omniknight_degen_aura" -> Ok Ability.OmniknightDegenAura
        | "omniknight_guardian_angel" -> Ok Ability.OmniknightGuardianAngel
        | "omniknight_pacify" -> Ok Ability.OmniknightPacify
        | "omniknight_purification" -> Ok Ability.OmniknightPurification
        | "omniknight_repel" -> Ok Ability.OmniknightRepel
        | "oracle_false_promise" -> Ok Ability.OracleFalsePromise
        | "oracle_fates_edict" -> Ok Ability.OracleFatesEdict
        | "oracle_fortunes_end" -> Ok Ability.OracleFortunesEnd
        | "oracle_purifying_flames" -> Ok Ability.OraclePurifyingFlames
        | "pangolier_gyroshell" -> Ok Ability.PangolierGyroshell
        | "pangolier_gyroshell_stop" -> Ok Ability.PangolierGyroshellStop
        | "pangolier_heartpiercer" -> Ok Ability.PangolierHeartpiercer
        | "pangolier_lucky_shot" -> Ok Ability.PangolierLuckyShot
        | "pangolier_shield_crash" -> Ok Ability.PangolierShieldCrash
        | "pangolier_swashbuckle" -> Ok Ability.PangolierSwashbuckle
        | "phantom_assassin_blur" -> Ok Ability.PhantomAssassinBlur
        | "phantom_assassin_coup_de_grace" -> Ok Ability.PhantomAssassinCoupDeGrace
        | "phantom_assassin_phantom_strike" -> Ok Ability.PhantomAssassinPhantomStrike
        | "phantom_assassin_stifling_dagger" -> Ok Ability.PhantomAssassinStiflingDagger
        | "phantom_lancer_doppelwalk" -> Ok Ability.PhantomLancerDoppelwalk
        | "phantom_lancer_juxtapose" -> Ok Ability.PhantomLancerJuxtapose
        | "phantom_lancer_phantom_edge" -> Ok Ability.PhantomLancerPhantomEdge
        | "phantom_lancer_spirit_lance" -> Ok Ability.PhantomLancerSpiritLance
        | "phoenix_fire_spirits" -> Ok Ability.PhoenixFireSpirits
        | "phoenix_icarus_dive" -> Ok Ability.PhoenixIcarusDive
        | "phoenix_icarus_dive_stop" -> Ok Ability.PhoenixIcarusDiveStop
        | "phoenix_launch_fire_spirit" -> Ok Ability.PhoenixLaunchFireSpirit
        | "phoenix_sun_ray" -> Ok Ability.PhoenixSunRay
        | "phoenix_sun_ray_stop" -> Ok Ability.PhoenixSunRayStop
        | "phoenix_sun_ray_toggle_move" -> Ok Ability.PhoenixSunRayToggleMove
        | "phoenix_supernova" -> Ok Ability.PhoenixSupernova
        | "polar_furbolg_ursa_warrior_thunder_clap" -> Ok Ability.PolarFurbolgUrsaWarriorThunderClap
        | "puck_dream_coil" -> Ok Ability.PuckDreamCoil
        | "puck_ethereal_jaunt" -> Ok Ability.PuckEtherealJaunt
        | "puck_illusory_orb" -> Ok Ability.PuckIllusoryOrb
        | "puck_phase_shift" -> Ok Ability.PuckPhaseShift
        | "puck_waning_rift" -> Ok Ability.PuckWaningRift
        | "pudge_dismember" -> Ok Ability.PudgeDismember
        | "pudge_flesh_heap" -> Ok Ability.PudgeFleshHeap
        | "pudge_meat_hook" -> Ok Ability.PudgeMeatHook
        | "pudge_rot" -> Ok Ability.PudgeRot
        | "pugna_decrepify" -> Ok Ability.PugnaDecrepify
        | "pugna_life_drain" -> Ok Ability.PugnaLifeDrain
        | "pugna_nether_blast" -> Ok Ability.PugnaNetherBlast
        | "pugna_nether_ward" -> Ok Ability.PugnaNetherWard
        | "queenofpain_blink" -> Ok Ability.QueenofpainBlink
        | "queenofpain_scream_of_pain" -> Ok Ability.QueenofpainScreamOfPain
        | "queenofpain_shadow_strike" -> Ok Ability.QueenofpainShadowStrike
        | "queenofpain_sonic_wave" -> Ok Ability.QueenofpainSonicWave
        | "rattletrap_battery_assault" -> Ok Ability.RattletrapBatteryAssault
        | "rattletrap_hookshot" -> Ok Ability.RattletrapHookshot
        | "rattletrap_power_cogs" -> Ok Ability.RattletrapPowerCogs
        | "rattletrap_rocket_flare" -> Ok Ability.RattletrapRocketFlare
        | "razor_eye_of_the_storm" -> Ok Ability.RazorEyeOfTheStorm
        | "razor_plasma_field" -> Ok Ability.RazorPlasmaField
        | "razor_static_link" -> Ok Ability.RazorStaticLink
        | "razor_unstable_current" -> Ok Ability.RazorUnstableCurrent
        | "riki_blink_strike" -> Ok Ability.RikiBlinkStrike
        | "riki_permanent_invisibility" -> Ok Ability.RikiPermanentInvisibility
        | "riki_smoke_screen" -> Ok Ability.RikiSmokeScreen
        | "riki_tricks_of_the_trade" -> Ok Ability.RikiTricksOfTheTrade
        | "roshan_bash" -> Ok Ability.RoshanBash
        | "roshan_devotion" -> Ok Ability.RoshanDevotion
        | "roshan_inherent_buffs" -> Ok Ability.RoshanInherentBuffs
        | "roshan_slam" -> Ok Ability.RoshanSlam
        | "roshan_spell_block" -> Ok Ability.RoshanSpellBlock
        | "rubick_arcane_supremacy" -> Ok Ability.RubickArcaneSupremacy
        | "rubick_empty1" -> Ok Ability.RubickEmpty
        | "rubick_fade_bolt" -> Ok Ability.RubickFadeBolt
        | "rubick_hidden1" -> Ok Ability.RubickHidden
        | "rubick_null_field" -> Ok Ability.RubickNullField
        | "rubick_spell_steal" -> Ok Ability.RubickSpellSteal
        | "rubick_telekinesis" -> Ok Ability.RubickTelekinesis
        | "rubick_telekinesis_land" -> Ok Ability.RubickTelekinesisLand
        | "sandking_burrowstrike" -> Ok Ability.SandkingBurrowstrike
        | "sandking_caustic_finale" -> Ok Ability.SandkingCausticFinale
        | "sandking_epicenter" -> Ok Ability.SandkingEpicenter
        | "sandking_sand_storm" -> Ok Ability.SandkingSandStorm
        | "satyr_hellcaller_shockwave" -> Ok Ability.SatyrHellcallerShockwave
        | "satyr_hellcaller_unholy_aura" -> Ok Ability.SatyrHellcallerUnholyAura
        | "satyr_soulstealer_mana_burn" -> Ok Ability.SatyrSoulstealerManaBurn
        | "satyr_trickster_purge" -> Ok Ability.SatyrTricksterPurge
        | "shadow_demon_demonic_purge" -> Ok Ability.ShadowDemonDemonicPurge
        | "shadow_demon_disruption" -> Ok Ability.ShadowDemonDisruption
        | "shadow_demon_shadow_poison" -> Ok Ability.ShadowDemonShadowPoison
        | "shadow_demon_shadow_poison_release" -> Ok Ability.ShadowDemonShadowPoisonRelease
        | "shadow_demon_soul_catcher" -> Ok Ability.ShadowDemonSoulCatcher
        | "shadow_shaman_ether_shock" -> Ok Ability.ShadowShamanEtherShock
        | "shadow_shaman_mass_serpent_ward" -> Ok Ability.ShadowShamanMassSerpentWard
        | "shadow_shaman_shackles" -> Ok Ability.ShadowShamanShackles
        | "shadow_shaman_voodoo" -> Ok Ability.ShadowShamanVoodoo
        | "shoot_firework" -> Ok Ability.ShootFirework
        | "shredder_chakram" -> Ok Ability.ShredderChakram
        | "shredder_reactive_armor" -> Ok Ability.ShredderReactiveArmor
        | "shredder_return_chakram" -> Ok Ability.ShredderReturnChakram
        | "shredder_timber_chain" -> Ok Ability.ShredderTimberChain
        | "shredder_whirling_death" -> Ok Ability.ShredderWhirlingDeath
        | "silencer_curse_of_the_silent" -> Ok Ability.SilencerCurseOfTheSilent
        | "silencer_glaives_of_wisdom" -> Ok Ability.SilencerGlaivesOfWisdom
        | "silencer_global_silence" -> Ok Ability.SilencerGlobalSilence
        | "silencer_last_word" -> Ok Ability.SilencerLastWord
        | "skeleton_king_hellfire_blast" -> Ok Ability.SkeletonKingHellfireBlast
        | "skeleton_king_mortal_strike" -> Ok Ability.SkeletonKingMortalStrike
        | "skeleton_king_reincarnation" -> Ok Ability.SkeletonKingReincarnation
        | "skeleton_king_vampiric_aura" -> Ok Ability.SkeletonKingVampiricAura
        | "skywrath_mage_ancient_seal" -> Ok Ability.SkywrathMageAncientSeal
        | "skywrath_mage_arcane_bolt" -> Ok Ability.SkywrathMageArcaneBolt
        | "skywrath_mage_concussive_shot" -> Ok Ability.SkywrathMageConcussiveShot
        | "skywrath_mage_mystic_flare" -> Ok Ability.SkywrathMageMysticFlare
        | "slardar_amplify_damage" -> Ok Ability.SlardarAmplifyDamage
        | "slardar_bash" -> Ok Ability.SlardarBash
        | "slardar_slithereen_crush" -> Ok Ability.SlardarSlithereenCrush
        | "slardar_sprint" -> Ok Ability.SlardarSprint
        | "slark_dark_pact" -> Ok Ability.SlarkDarkPact
        | "slark_essence_shift" -> Ok Ability.SlarkEssenceShift
        | "slark_pounce" -> Ok Ability.SlarkPounce
        | "slark_shadow_dance" -> Ok Ability.SlarkShadowDance
        | "sniper_assassinate" -> Ok Ability.SniperAssassinate
        | "sniper_headshot" -> Ok Ability.SniperHeadshot
        | "sniper_shrapnel" -> Ok Ability.SniperShrapnel
        | "sniper_take_aim" -> Ok Ability.SniperTakeAim
        | "spawnlord_aura" -> Ok Ability.SpawnlordAura
        | "spawnlord_master_bash" -> Ok Ability.SpawnlordMasterBash
        | "spawnlord_master_freeze" -> Ok Ability.SpawnlordMasterFreeze
        | "spawnlord_master_stomp" -> Ok Ability.SpawnlordMasterStomp
        | "spectre_desolate" -> Ok Ability.SpectreDesolate
        | "spectre_dispersion" -> Ok Ability.SpectreDispersion
        | "spectre_haunt" -> Ok Ability.SpectreHaunt
        | "spectre_reality" -> Ok Ability.SpectreReality
        | "spectre_spectral_dagger" -> Ok Ability.SpectreSpectralDagger
        | "spirit_breaker_bulldoze" -> Ok Ability.SpiritBreakerBulldoze
        | "spirit_breaker_charge_of_darkness" -> Ok Ability.SpiritBreakerChargeOfDarkness
        | "spirit_breaker_empowering_haste" -> Ok Ability.SpiritBreakerEmpoweringHaste
        | "spirit_breaker_greater_bash" -> Ok Ability.SpiritBreakerGreaterBash
        | "spirit_breaker_nether_strike" -> Ok Ability.SpiritBreakerNetherStrike
        | "storm_spirit_ball_lightning" -> Ok Ability.StormSpiritBallLightning
        | "storm_spirit_electric_vortex" -> Ok Ability.StormSpiritElectricVortex
        | "storm_spirit_overload" -> Ok Ability.StormSpiritOverload
        | "storm_spirit_static_remnant" -> Ok Ability.StormSpiritStaticRemnant
        | "sven_gods_strength" -> Ok Ability.SvenGodsStrength
        | "sven_great_cleave" -> Ok Ability.SvenGreatCleave
        | "sven_storm_bolt" -> Ok Ability.SvenStormBolt
        | "sven_warcry" -> Ok Ability.SvenWarcry
        | "techies_focused_detonate" -> Ok Ability.TechiesFocusedDetonate
        | "techies_land_mines" -> Ok Ability.TechiesLandMines
        | "techies_minefield_sign" -> Ok Ability.TechiesMinefieldSign
        | "techies_remote_mines" -> Ok Ability.TechiesRemoteMines
        | "techies_remote_mines_self_detonate" -> Ok Ability.TechiesRemoteMinesSelfDetonate
        | "techies_stasis_trap" -> Ok Ability.TechiesStasisTrap
        | "techies_suicide" -> Ok Ability.TechiesSuicide
        | "templar_assassin_meld" -> Ok Ability.TemplarAssassinMeld
        | "templar_assassin_psi_blades" -> Ok Ability.TemplarAssassinPsiBlades
        | "templar_assassin_psionic_trap" -> Ok Ability.TemplarAssassinPsionicTrap
        | "templar_assassin_refraction" -> Ok Ability.TemplarAssassinRefraction
        | "templar_assassin_self_trap" -> Ok Ability.TemplarAssassinSelfTrap
        | "templar_assassin_trap" -> Ok Ability.TemplarAssassinTrap
        | "terrorblade_conjure_image" -> Ok Ability.TerrorbladeConjureImage
        | "terrorblade_metamorphosis" -> Ok Ability.TerrorbladeMetamorphosis
        | "terrorblade_reflection" -> Ok Ability.TerrorbladeReflection
        | "terrorblade_sunder" -> Ok Ability.TerrorbladeSunder
        | "tidehunter_anchor_smash" -> Ok Ability.TidehunterAnchorSmash
        | "tidehunter_gush" -> Ok Ability.TidehunterGush
        | "tidehunter_kraken_shell" -> Ok Ability.TidehunterKrakenShell
        | "tidehunter_ravage" -> Ok Ability.TidehunterRavage
        | "tinker_heat_seeking_missile" -> Ok Ability.TinkerHeatSeekingMissile
        | "tinker_laser" -> Ok Ability.TinkerLaser
        | "tinker_march_of_the_machines" -> Ok Ability.TinkerMarchOfTheMachines
        | "tinker_rearm" -> Ok Ability.TinkerRearm
        | "tiny_avalanche" -> Ok Ability.TinyAvalanche
        | "tiny_craggy_exterior" -> Ok Ability.TinyCraggyExterior
        | "tiny_grow" -> Ok Ability.TinyGrow
        | "tiny_toss" -> Ok Ability.TinyToss
        | "tiny_toss_tree" -> Ok Ability.TinyTossTree
        | "tornado_tempest" -> Ok Ability.TornadoTempest
        | "treant_eyes_in_the_forest" -> Ok Ability.TreantEyesInTheForest
        | "treant_leech_seed" -> Ok Ability.TreantLeechSeed
        | "treant_living_armor" -> Ok Ability.TreantLivingArmor
        | "treant_natures_guise" -> Ok Ability.TreantNaturesGuise
        | "treant_overgrowth" -> Ok Ability.TreantOvergrowth
        | "troll_warlord_battle_trance" -> Ok Ability.TrollWarlordBattleTrance
        | "troll_warlord_berserkers_rage" -> Ok Ability.TrollWarlordBerserkersRage
        | "troll_warlord_fervor" -> Ok Ability.TrollWarlordFervor
        | "troll_warlord_whirling_axes_melee" -> Ok Ability.TrollWarlordWhirlingAxesMelee
        | "troll_warlord_whirling_axes_ranged" -> Ok Ability.TrollWarlordWhirlingAxesRanged
        | "tusk_frozen_sigil" -> Ok Ability.TuskFrozenSigil
        | "tusk_ice_shards" -> Ok Ability.TuskIceShards
        | "tusk_ice_shards_stop" -> Ok Ability.TuskIceShardsStop
        | "tusk_launch_snowball" -> Ok Ability.TuskLaunchSnowball
        | "tusk_snowball" -> Ok Ability.TuskSnowball
        | "tusk_tag_team" -> Ok Ability.TuskTagTeam
        | "tusk_walrus_kick" -> Ok Ability.TuskWalrusKick
        | "tusk_walrus_punch" -> Ok Ability.TuskWalrusPunch
        | "undying_decay" -> Ok Ability.UndyingDecay
        | "undying_flesh_golem" -> Ok Ability.UndyingFleshGolem
        | "undying_soul_rip" -> Ok Ability.UndyingSoulRip
        | "undying_tombstone" -> Ok Ability.UndyingTombstone
        | "undying_tombstone_zombie_aura" -> Ok Ability.UndyingTombstoneZombieAura
        | "undying_tombstone_zombie_deathstrike" -> Ok Ability.UndyingTombstoneZombieDeathstrike
        | "ursa_earthshock" -> Ok Ability.UrsaEarthshock
        | "ursa_enrage" -> Ok Ability.UrsaEnrage
        | "ursa_fury_swipes" -> Ok Ability.UrsaFurySwipes
        | "ursa_overpower" -> Ok Ability.UrsaOverpower
        | "vengefulspirit_command_aura" -> Ok Ability.VengefulspiritCommandAura
        | "vengefulspirit_magic_missile" -> Ok Ability.VengefulspiritMagicMissile
        | "vengefulspirit_nether_swap" -> Ok Ability.VengefulspiritNetherSwap
        | "vengefulspirit_wave_of_terror" -> Ok Ability.VengefulspiritWaveOfTerror
        | "venomancer_plague_ward" -> Ok Ability.VenomancerPlagueWard
        | "venomancer_poison_nova" -> Ok Ability.VenomancerPoisonNova
        | "venomancer_poison_sting" -> Ok Ability.VenomancerPoisonSting
        | "venomancer_venomous_gale" -> Ok Ability.VenomancerVenomousGale
        | "viper_corrosive_skin" -> Ok Ability.ViperCorrosiveSkin
        | "viper_nethertoxin" -> Ok Ability.ViperNethertoxin
        | "viper_poison_attack" -> Ok Ability.ViperPoisonAttack
        | "viper_viper_strike" -> Ok Ability.ViperViperStrike
        | "visage_grave_chill" -> Ok Ability.VisageGraveChill
        | "visage_gravekeepers_cloak" -> Ok Ability.VisageGravekeepersCloak
        | "visage_soul_assumption" -> Ok Ability.VisageSoulAssumption
        | "visage_stone_form_self_cast" -> Ok Ability.VisageStoneFormSelfCast
        | "visage_summon_familiars" -> Ok Ability.VisageSummonFamiliars
        | "visage_summon_familiars_stone_form" -> Ok Ability.VisageSummonFamiliarsStoneForm
        | "warlock_fatal_bonds" -> Ok Ability.WarlockFatalBonds
        | "warlock_golem_flaming_fists" -> Ok Ability.WarlockGolemFlamingFists
        | "warlock_golem_permanent_immolation" -> Ok Ability.WarlockGolemPermanentImmolation
        | "warlock_rain_of_chaos" -> Ok Ability.WarlockRainOfChaos
        | "warlock_shadow_word" -> Ok Ability.WarlockShadowWord
        | "warlock_upheaval" -> Ok Ability.WarlockUpheaval
        | "weaver_geminate_attack" -> Ok Ability.WeaverGeminateAttack
        | "weaver_shukuchi" -> Ok Ability.WeaverShukuchi
        | "weaver_the_swarm" -> Ok Ability.WeaverTheSwarm
        | "weaver_time_lapse" -> Ok Ability.WeaverTimeLapse
        | "windrunner_focusfire" -> Ok Ability.WindrunnerFocusfire
        | "windrunner_powershot" -> Ok Ability.WindrunnerPowershot
        | "windrunner_shackleshot" -> Ok Ability.WindrunnerShackleshot
        | "windrunner_windrun" -> Ok Ability.WindrunnerWindrun
        | "winter_wyvern_arctic_burn" -> Ok Ability.WinterWyvernArcticBurn
        | "winter_wyvern_cold_embrace" -> Ok Ability.WinterWyvernColdEmbrace
        | "winter_wyvern_splinter_blast" -> Ok Ability.WinterWyvernSplinterBlast
        | "winter_wyvern_winters_curse" -> Ok Ability.WinterWyvernWintersCurse
        | "wisp_overcharge" -> Ok Ability.WispOvercharge
        | "wisp_relocate" -> Ok Ability.WispRelocate
        | "wisp_spirits" -> Ok Ability.WispSpirits
        | "wisp_spirits_in" -> Ok Ability.WispSpiritsIn
        | "wisp_spirits_out" -> Ok Ability.WispSpiritsOut
        | "wisp_tether" -> Ok Ability.WispTether
        | "wisp_tether_break" -> Ok Ability.WispTetherBreak
        | "witch_doctor_death_ward" -> Ok Ability.WitchDoctorDeathWard
        | "witch_doctor_maledict" -> Ok Ability.WitchDoctorMaledict
        | "witch_doctor_paralyzing_cask" -> Ok Ability.WitchDoctorParalyzingCask
        | "witch_doctor_voodoo_restoration" -> Ok Ability.WitchDoctorVoodooRestoration
        | "zuus_arc_lightning" -> Ok Ability.ZuusArcLightning
        | "zuus_cloud" -> Ok Ability.ZuusCloud
        | "zuus_lightning_bolt" -> Ok Ability.ZuusLightningBolt
        | "zuus_static_field" -> Ok Ability.ZuusStaticField
        | "zuus_thundergods_wrath" -> Ok Ability.ZuusThundergodsWrath
        | invalidAbility ->
            sprintf "Invalid Dota 2 ability: %s" invalidAbility
            |> Error

    /// <summary>
    ///     Get the name for a given Dota 2 ability.
    /// </summary>
    ///
    /// <param name="ability">
    ///     The Dota 2 ability to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 ability.
    /// </returns>
    let getName (ability : Ability) : string =
        match ability with
        | AbaddonAphoticShield -> "Aphotic Shield"
        | AbaddonBorrowedTime -> "Borrowed Time"
        | AbaddonDeathCoil -> "Mist Coil"
        | AbaddonFrostmourne -> "Curse of Avernus"
        | AbyssalUnderlordAtrophyAura -> "Atrophy Aura"
        | AbyssalUnderlordCancelDarkRift -> "Cancel Dark Rift"
        | AbyssalUnderlordDarkRift -> "Dark Rift"
        | AbyssalUnderlordFirestorm -> "Firestorm"
        | AbyssalUnderlordPitOfMalice -> "Pit of Malice"
        | AlchemistAcidSpray -> "Acid Spray"
        | AlchemistChemicalRage -> "Chemical Rage"
        | AlchemistGoblinsGreed -> "Greevil's Greed"
        | AlchemistUnstableConcoction -> "Unstable Concoction"
        | AlchemistUnstableConcoctionThrow -> "Unstable Concoction Throw"
        | AlphaWolfCommandAura -> "Packleader's Aura"
        | AlphaWolfCriticalStrike -> "Critical Strike"
        | AncientApparitionChillingTouch -> "Chilling Touch"
        | AncientApparitionColdFeet -> "Cold Feet"
        | AncientApparitionIceBlast -> "Ice Blast"
        | AncientApparitionIceBlastRelease -> "Release"
        | AncientApparitionIceVortex -> "Ice Vortex"
        | AncientGolemRockslide -> "Reactive Armor"
        | AntimageBlink -> "Blink"
        | AntimageCounterspell -> "Counterspell"
        | AntimageManaBreak -> "Mana Break"
        | AntimageManaVoid -> "Mana Void"
        | AntimageSpellShield -> "Spell Shield"
        | ArcWardenFlux -> "Flux"
        | ArcWardenMagneticField -> "Magnetic Field"
        | ArcWardenSparkWraith -> "Spark Wraith"
        | ArcWardenTempestDouble -> "Tempest Double"
        | AxeBattleHunger -> "Battle Hunger"
        | AxeBerserkersCall -> "Berserker's Call"
        | AxeCounterHelix -> "Counter Helix"
        | AxeCullingBlade -> "Culling Blade"
        | BackdoorProtection -> "Backdoor Protection"
        | BackdoorProtectionInBase -> "Backdoor Protection"
        | BaneBrainSap -> "Brain Sap"
        | BaneEnfeeble -> "Enfeeble"
        | BaneFiendsGrip -> "Fiend's Grip"
        | BaneNightmare -> "Nightmare"
        | BaneNightmareEnd -> "Nightmare End"
        | BatriderFirefly -> "Firefly"
        | BatriderFlamebreak -> "Flamebreak"
        | BatriderFlamingLasso -> "Flaming Lasso"
        | BatriderStickyNapalm -> "Sticky Napalm"
        | BearEmpty -> "Empty"
        | BeastmasterBoarPoison -> "Poison"
        | BeastmasterCallOfTheWild -> "Degen Aura"
        | BeastmasterCallOfTheWildBoar -> "Call of the Wild Boar"
        | BeastmasterCallOfTheWildHawk -> "Call of the Wild Hawk"
        | BeastmasterGreaterBoarPoison -> "Poison"
        | BeastmasterHawkInvisibility -> "Invisibility"
        | BeastmasterInnerBeast -> "Inner Beast"
        | BeastmasterPrimalRoar -> "Primal Roar"
        | BeastmasterWildAxes -> "Wild Axes"
        | BigThunderLizardFrenzy -> "Frenzy"
        | BigThunderLizardSlam -> "Slam"
        | BigThunderLizardWardrumsAura -> "War Drums Aura"
        | BlackDragonDragonhideAura -> "Dragonhide Aura"
        | BlackDragonFireball -> "Fireball"
        | BlackDragonSplashAttack -> "Splash Attack"
        | BloodseekerBloodBath -> "Blood Rite"
        | BloodseekerBloodrage -> "Bloodrage"
        | BloodseekerRupture -> "Rupture"
        | BloodseekerThirst -> "Thirst"
        | BlueDragonspawnOverseerDevotionAura -> "Guardian Aura"
        | BlueDragonspawnOverseerEvasion -> "Evasion"
        | BlueDragonspawnSorcererEvasion -> "Evasion"
        | BountyHunterJinada -> "Jinada"
        | BountyHunterShurikenToss -> "Shuriken Toss"
        | BountyHunterTrack -> "Track"
        | BountyHunterWindWalk -> "Shadow Walk"
        | BrewmasterCinderBrew -> "Cinder Brew"
        | BrewmasterDrunkenBrawler -> "Drunken Brawler"
        | BrewmasterDrunkenHaze -> "Drunken Haze"
        | BrewmasterEarthHurlBoulder -> "Hurl Boulder"
        | BrewmasterEarthPulverize -> "Demolish"
        | BrewmasterEarthSpellImmunity -> "Spell Immunity"
        | BrewmasterFirePermanentImmolation -> "Permanent Immolation"
        | BrewmasterPrimalSplit -> "Primal Split"
        | BrewmasterStormCyclone -> "Cyclone"
        | BrewmasterStormDispelMagic -> "Dispel Magic"
        | BrewmasterStormWindWalk -> "Wind Walk"
        | BrewmasterThunderClap -> "Thunder Clap"
        | BristlebackBristleback -> "Bristleback"
        | BristlebackQuillSpray -> "Quill Spray"
        | BristlebackViscousNasalGoo -> "Viscous Nasal Goo"
        | BristlebackWarpath -> "Warpath"
        | BroodmotherIncapacitatingBite -> "Incapacitating Bite"
        | BroodmotherInsatiableHunger -> "Insatiable Hunger"
        | BroodmotherPoisonSting -> "Poison Sting"
        | BroodmotherSpawnSpiderite -> "Spawn Spiderite"
        | BroodmotherSpawnSpiderlings -> "Spawn Spiderlings"
        | BroodmotherSpinWeb -> "Spin Web"
        | BroodmotherSpinWebDestroy -> "Destroy Spin Web"
        | CentaurDoubleEdge -> "Double Edge"
        | CentaurHoofStomp -> "Hoof Stomp"
        | CentaurReturn -> "Retaliate"
        | CentaurStampede -> "Stampede"
        | ChaosKnightChaosBolt -> "Chaos Bolt"
        | ChaosKnightChaosStrike -> "Chaos Strike"
        | ChaosKnightPhantasm -> "Phantasm"
        | ChaosKnightRealityRift -> "Reality Rift"
        | ChenDivineFavor -> "Divine Favor"
        | ChenHandOfGod -> "Hand of God"
        | ChenHolyPersuasion -> "Holy Persuasion"
        | ChenPenitence -> "Penitence"
        | ChenTestOfFaith -> "Test of Faith"
        | ChenTestOfFaithTeleport -> "Battle Trance"
        | ClinkzBurningArmy -> "Burning Army"
        | ClinkzDeathPact -> "Death Pact"
        | ClinkzSearingArrows -> "Searing Arrows"
        | ClinkzStrafe -> "Strafe"
        | ClinkzWindWalk -> "Skeleton Walk"
        | CnyBeastForceAttack -> "Fireblast"
        | CnyBeastTeleport -> "Paralyzing Cask"
        | ConsumableHidden -> "Penitence"
        | CourierAutodeliver -> "Auto Deliver"
        | CourierBurst -> "Speed Burst"
        | CourierGoToEnemySecretshop -> "Quas"
        | CourierGoToSecretshop -> "Go To Secret Shop"
        | CourierGoToSideshop -> "Hoof Stomp"
        | CourierMorph -> "Supernova"
        | CourierReturnStashItems -> "Return Items"
        | CourierReturnToBase -> "Return to Base"
        | CourierShield -> "Shield"
        | CourierTakeStashAndTransferItems -> "Corrosive Skin"
        | CourierTakeStashItems -> "Retrieve Items"
        | CourierTransferItems -> "Transfer Items"
        | CourierTransferItemsToOtherPlayer -> "Cripple"
        | CrystalMaidenBrillianceAura -> "Arcane Aura"
        | CrystalMaidenCrystalNova -> "Crystal Nova"
        | CrystalMaidenFreezingField -> "Freezing Field"
        | CrystalMaidenFrostbite -> "Frostbite"
        | DarkSeerIonShell -> "Ion Shell"
        | DarkSeerSurge -> "Surge"
        | DarkSeerVacuum -> "Vacuum"
        | DarkSeerWallOfReplica -> "Wall of Replica"
        | DarkTrollWarlordEnsnare -> "Ensnare"
        | DarkTrollWarlordRaiseDead -> "Raise Dead"
        | DarkWillowBedlam -> "Bedlam"
        | DarkWillowBrambleMaze -> "Bramble Maze"
        | DarkWillowCursedCrown -> "Cursed Crown"
        | DarkWillowShadowRealm -> "Shadow Realm"
        | DarkWillowTerrorize -> "Terrorize"
        | DazzleBadJuju -> "Bad Juju"
        | DazzlePoisonTouch -> "Poison Touch"
        | DazzleShadowWave -> "Shadow Wave"
        | DazzleShallowGrave -> "Shallow Grave"
        | DazzleWeave -> "Weave"
        | DeathProphetCarrionSwarm -> "Crypt Swarm"
        | DeathProphetExorcism -> "Exorcism"
        | DeathProphetSilence -> "Silence"
        | DeathProphetSpiritSiphon -> "Spirit Siphon"
        | DeathProphetWitchcraft -> "Geostrike"
        | DisruptorGlimpse -> "Glimpse"
        | DisruptorKineticField -> "Kinetic Field"
        | DisruptorStaticStorm -> "Static Storm"
        | DisruptorThunderStrike -> "Thunder Strike"
        | DoomBringerDevour -> "Devour"
        | DoomBringerDoom -> "Doom"
        | DoomBringerEmpty -> "Devoured Ability"
        | DoomBringerInfernalBlade -> "Infernal Blade"
        | DoomBringerScorchedEarth -> "Scorched Earth"
        | DotaBaseAbility -> "Geostrike"
        | DragonKnightBreatheFire -> "Breathe Fire"
        | DragonKnightDragonBlood -> "Dragon Blood"
        | DragonKnightDragonTail -> "Dragon Tail"
        | DragonKnightElderDragonForm -> "Elder Dragon Form"
        | DrowRangerFrostArrows -> "Frost Arrows"
        | DrowRangerMarksmanship -> "Marksmanship"
        | DrowRangerSilence -> "Silence"
        | DrowRangerTrueshot -> "Precision Aura"
        | DrowRangerWaveOfSilence -> "Gust"
        | EarthSpiritBoulderSmash -> "Boulder Smash"
        | EarthSpiritGeomagneticGrip -> "Geomagnetic Grip"
        | EarthSpiritMagnetize -> "Magnetize"
        | EarthSpiritPetrify -> "Enchant Remnant"
        | EarthSpiritRollingBoulder -> "Rolling Boulder"
        | EarthSpiritStoneCaller -> "Stone Remnant"
        | EarthshakerAftershock -> "Aftershock"
        | EarthshakerEchoSlam -> "Echo Slam"
        | EarthshakerEnchantTotem -> "Enchant Totem"
        | EarthshakerFissure -> "Fissure"
        | ElderTitanAncestralSpirit -> "Astral Spirit"
        | ElderTitanEarthSplitter -> "Earth Splitter"
        | ElderTitanEchoStomp -> "Echo Stomp"
        | ElderTitanEchoStompSpirit -> "Echo Stomp"
        | ElderTitanNaturalOrder -> "Natural Order"
        | ElderTitanNaturalOrderSpirit -> "Natural Order"
        | ElderTitanReturnSpirit -> "Return Astral Spirit"
        | EmberSpiritActivateFireRemnant -> "Activate Fire Remnant"
        | EmberSpiritFireRemnant -> "Fire Remnant"
        | EmberSpiritFlameGuard -> "Flame Guard"
        | EmberSpiritSearingChains -> "Searing Chains"
        | EmberSpiritSleightOfFist -> "Sleight of Fist"
        | EnchantressEnchant -> "Enchant"
        | EnchantressImpetus -> "Impetus"
        | EnchantressNaturesAttendants -> "Nature's Attendants"
        | EnchantressUntouchable -> "Untouchable"
        | EnigmaBlackHole -> "Black Hole"
        | EnigmaDemonicConversion -> "Demonic Conversion"
        | EnigmaMalefice -> "Malefice"
        | EnigmaMidnightPulse -> "Midnight Pulse"
        | EnragedWildkinTornado -> "Tornado"
        | EnragedWildkinToughnessAura -> "Toughness Aura"
        | FacelessVoidBacktrack -> "Morph Replicate"
        | FacelessVoidChronosphere -> "Chronosphere"
        | FacelessVoidTimeDilation -> "Time Dilation"
        | FacelessVoidTimeLock -> "Time Lock"
        | FacelessVoidTimeWalk -> "Time Walk"
        | FillerAbility -> "Sanctuary"
        | ForestTrollHighPriestHeal -> "Heal"
        | ForestTrollHighPriestManaAura -> "Mana Aura"
        | ForgedSpiritMeltingStrike -> "Melting Strike"
        | FrostivusDecorateTree -> "Decorate Tree"
        | FrostivusFestiveFirework -> "Festive Firework"
        | FrostivusSummonSnowman -> "Summon Snowman"
        | FrostivusThrowSnowball -> "Throw Snowball"
        | FurionForceOfNature -> "Nature's Call"
        | FurionSprout -> "Sprout"
        | FurionTeleportation -> "Teleportation"
        | FurionWrathOfNature -> "Wrath of Nature"
        | GenericHidden -> "Wex"
        | GhostFrostAttack -> "Frost Attack"
        | GiantWolfCriticalStrike -> "Critical Strike"
        | GnollAssassinEnvenomedWeapon -> "Envenomed Weapon"
        | GraniteGolemBash -> "Essence Aura"
        | GraniteGolemHpAura -> "Granite Aura"
        | GrimstrokeDarkArtistry -> "Stroke of Fate"
        | GrimstrokeInkCreature -> "Phantom's Embrace"
        | GrimstrokeSoulChain -> "Soulbind"
        | GrimstrokeSpiritWalk -> "Ink Swell"
        | GyrocopterCallDown -> "Call Down"
        | GyrocopterFlakCannon -> "Flak Cannon"
        | GyrocopterHomingMissile -> "Homing Missile"
        | GyrocopterRocketBarrage -> "Rocket Barrage"
        | HarpyStormChainLightning -> "Chain Lightning"
        | HealingCampfire -> "Invoked Spell"
        | HuskarBerserkersBlood -> "Berserker's Blood"
        | HuskarBurningSpear -> "Burning Spear"
        | HuskarInnerFire -> "Inner Fire"
        | HuskarInnerVitality -> "Inner Vitality"
        | HuskarLifeBreak -> "Life Break"
        | InvokerAlacrity -> "Alacrity"
        | InvokerChaosMeteor -> "Chaos Meteor"
        | InvokerColdSnap -> "Cold Snap"
        | InvokerDeafeningBlast -> "Deafening Blast"
        | InvokerEmp -> "E.M.P."
        | InvokerEmpty -> "Invoked Spell"
        | InvokerExort -> "Exort"
        | InvokerForgeSpirit -> "Forge Spirit"
        | InvokerGhostWalk -> "Ghost Walk"
        | InvokerIceWall -> "Ice Wall"
        | InvokerInvoke -> "Invoke"
        | InvokerQuas -> "Quas"
        | InvokerSunStrike -> "Sun Strike"
        | InvokerTornado -> "Tornado"
        | InvokerWex -> "Wex"
        | JakiroDualBreath -> "Dual Breath"
        | JakiroIcePath -> "Ice Path"
        | JakiroLiquidFire -> "Liquid Fire"
        | JakiroMacropyre -> "Macropyre"
        | JuggernautBladeDance -> "Blade Dance"
        | JuggernautBladeFury -> "Blade Fury"
        | JuggernautHealingWard -> "Healing Ward"
        | JuggernautOmniSlash -> "Omnislash"
        | KeeperOfTheLightBlindingLight -> "Blinding Light"
        | KeeperOfTheLightChakraMagic -> "Chakra Magic"
        | KeeperOfTheLightEmpty -> "Empty"
        | KeeperOfTheLightIlluminate -> "Illuminate"
        | KeeperOfTheLightIlluminateEnd -> "Release Illuminate"
        | KeeperOfTheLightManaLeak -> "Mana Leak"
        | KeeperOfTheLightRecall -> "Recall"
        | KeeperOfTheLightSpiritForm -> "Spirit Form"
        | KeeperOfTheLightSpiritFormIlluminate -> "Illuminate"
        | KeeperOfTheLightSpiritFormIlluminateEnd -> "Release Illuminate"
        | KeeperOfTheLightWillOWisp -> "Will-O-Wisp"
        | KoboldTaskmasterSpeedAura -> "Speed Aura"
        | KunkkaGhostship -> "Ghostship"
        | KunkkaReturn -> "Return"
        | KunkkaTidebringer -> "Tidebringer"
        | KunkkaTorrent -> "Torrent"
        | KunkkaXMarksTheSpot -> "X Marks the Spot"
        | LegionCommanderDuel -> "Duel"
        | LegionCommanderMomentOfCourage -> "Moment of Courage"
        | LegionCommanderOverwhelmingOdds -> "Overwhelming Odds"
        | LegionCommanderPressTheAttack -> "Press The Attack"
        | LeshracDiabolicEdict -> "Diabolic Edict"
        | LeshracLightningStorm -> "Lightning Storm"
        | LeshracPulseNova -> "Pulse Nova"
        | LeshracSplitEarth -> "Split Earth"
        | LichChainFrost -> "Chain Frost"
        | LichDarkSorcery -> "Unholy Pact"
        | LichFrostArmor -> "Ice Armor"
        | LichFrostAura -> "Frost Aura"
        | LichFrostNova -> "Frost Blast"
        | LichFrostShield -> "Frost Shield"
        | LichSinisterGaze -> "Sinister Gaze"
        | LifeStealerAssimilate -> "Assimilate"
        | LifeStealerAssimilateEject -> "Eject"
        | LifeStealerConsume -> "Consume"
        | LifeStealerControl -> "Control"
        | LifeStealerEmpty -> ""
        | LifeStealerFeast -> "Feast"
        | LifeStealerInfest -> "Infest"
        | LifeStealerOpenWounds -> "Open Wounds"
        | LifeStealerRage -> "Rage"
        | LinaDragonSlave -> "Dragon Slave"
        | LinaFierySoul -> "Fiery Soul"
        | LinaLagunaBlade -> "Laguna Blade"
        | LinaLightStrikeArray -> "Light Strike Array"
        | LionFingerOfDeath -> "Finger of Death"
        | LionImpale -> "Earth Spike"
        | LionManaDrain -> "Mana Drain"
        | LionVoodoo -> "Hex"
        | LoneDruidRabid -> "Rabid"
        | LoneDruidSavageRoar -> "Savage Roar"
        | LoneDruidSavageRoarBear -> "Savage Roar"
        | LoneDruidSpiritBear -> "Summon Spirit Bear"
        | LoneDruidSpiritBearDefender -> "Defender"
        | LoneDruidSpiritBearDemolish -> "Demolish"
        | LoneDruidSpiritBearEntangle -> "Entangling Claws"
        | LoneDruidSpiritBearReturn -> "Return"
        | LoneDruidSpiritLink -> "Spirit Link"
        | LoneDruidTrueForm -> "True Form"
        | LoneDruidTrueFormBattleCry -> "Battle Cry"
        | LoneDruidTrueFormDruid -> "Druid Form"
        | LunaEclipse -> "Eclipse"
        | LunaLucentBeam -> "Lucent Beam"
        | LunaLunarBlessing -> "Lunar Blessing"
        | LunaMoonGlaive -> "Moon Glaives"
        | LycanFeralImpulse -> "Feral Impulse"
        | LycanHowl -> "Howl"
        | LycanShapeshift -> "Shapeshift"
        | LycanSummonWolves -> "Summon Wolves"
        | LycanSummonWolvesCriticalStrike -> "Cripple"
        | LycanSummonWolvesInvisibility -> "Invisibility"
        | MagnataurEmpower -> "Empower"
        | MagnataurReversePolarity -> "Reverse Polarity"
        | MagnataurShockwave -> "Shockwave"
        | MagnataurSkewer -> "Skewer"
        | MarsArenaOfBlood -> "Arena Of Blood"
        | MarsBulwark -> "Bulwark"
        | MarsGodsRebuke -> "God's Rebuke"
        | MarsSpear -> "Spear of Mars"
        | MedusaManaShield -> "Mana Shield"
        | MedusaMysticSnake -> "Mystic Snake"
        | MedusaSplitShot -> "Split Shot"
        | MedusaStoneGaze -> "Stone Gaze"
        | MeepoDividedWeStand -> "Divided We Stand"
        | MeepoEarthbind -> "Earthbind"
        | MeepoGeostrike -> "Geostrike"
        | MeepoPoof -> "Poof"
        | MeepoRansack -> "Ransack"
        | MiranaArrow -> "Sacred Arrow"
        | MiranaInvis -> "Moonlight Shadow"
        | MiranaLeap -> "Leap"
        | MiranaStarfall -> "Starstorm"
        | MonkeyKingBoundlessStrike -> "Boundless Strike"
        | MonkeyKingJinguMastery -> "Jingu Mastery"
        | MonkeyKingMischief -> "Mischief"
        | MonkeyKingPrimalSpring -> "Primal Spring"
        | MonkeyKingPrimalSpringEarly -> "Dark Ascension"
        | MonkeyKingTreeDance -> "Tree Dance"
        | MonkeyKingUntransform -> "Revert Form"
        | MonkeyKingWukongsCommand -> "Wukong's Command"
        | MorphlingAdaptiveStrikeAgi -> "Adaptive Strike (Agility)"
        | MorphlingAdaptiveStrikeStr -> "Adaptive Strike (Strength)"
        | MorphlingHybrid -> "Hybrid"
        | MorphlingMorph -> "Flamebreak"
        | MorphlingMorphAgi -> "Attribute Shift (Agility Gain)"
        | MorphlingMorphReplicate -> "Morph Replicate"
        | MorphlingMorphStr -> "Attribute Shift (Strength Gain)"
        | MorphlingReplicate -> "Morph"
        | MorphlingWaveform -> "Waveform"
        | MudGolemHurlBoulder -> "Hurl Boulder"
        | MudGolemRockDestroy -> "Shard Split"
        | MudgolemCloakAura -> "Cloak Aura"
        | NagaSirenEnsnare -> "Ensnare"
        | NagaSirenMirrorImage -> "Mirror Image"
        | NagaSirenRipTide -> "Rip Tide"
        | NagaSirenSongOfTheSiren -> "Song of the Siren"
        | NagaSirenSongOfTheSirenCancel -> "Song of the Siren End"
        | NecrolyteDeathPulse -> "Death Pulse"
        | NecrolyteHeartstopperAura -> "Heartstopper Aura"
        | NecrolyteReapersScythe -> "Reaper's Scythe"
        | NecrolyteSadist -> "Ghost Shroud"
        | NecrolyteSadistStop -> "Searing Arrows"
        | NecronomiconArcherAoe -> "Archer Aura"
        | NecronomiconArcherManaBurn -> "Mana Burn"
        | NecronomiconArcherPurge -> "Purge"
        | NecronomiconWarriorLastWill -> "Last Will"
        | NecronomiconWarriorManaBurn -> "Mana Break"
        | NecronomiconWarriorSight -> "True Sight"
        | NeutralSpellImmunity -> "Spell Immunity"
        | NevermoreDarkLord -> "Presence of the Dark Lord"
        | NevermoreNecromastery -> "Necromastery"
        | NevermoreRequiem -> "Requiem of Souls"
        | NevermoreShadowraze -> "Shadowraze"
        | NightStalkerCripplingFear -> "Crippling Fear"
        | NightStalkerDarkness -> "Dark Ascension"
        | NightStalkerHunterInTheNight -> "Hunter in the Night"
        | NightStalkerVoid -> "Void"
        | NyxAssassinBurrow -> "Burrow"
        | NyxAssassinImpale -> "Impale"
        | NyxAssassinManaBurn -> "Mana Burn"
        | NyxAssassinSpikedCarapace -> "Spiked Carapace"
        | NyxAssassinUnburrow -> "Unburrow"
        | NyxAssassinVendetta -> "Vendetta"
        | ObsidianDestroyerArcaneOrb -> "Arcane Orb"
        | ObsidianDestroyerAstralImprisonment -> "Astral Imprisonment"
        | ObsidianDestroyerEquilibrium -> "Equilibrium"
        | ObsidianDestroyerEssenceAura -> "Essence Aura"
        | ObsidianDestroyerSanityEclipse -> "Sanity's Eclipse"
        | OgreMagiBloodlust -> "Bloodlust"
        | OgreMagiFireblast -> "Fireblast"
        | OgreMagiFrostArmor -> "Ice Armor"
        | OgreMagiIgnite -> "Ignite"
        | OgreMagiMulticast -> "Multicast"
        | OgreMagiUnrefinedFireblast -> "Unrefined Fireblast"
        | OmniknightDegenAura -> "Degen Aura"
        | OmniknightGuardianAngel -> "Guardian Angel"
        | OmniknightPacify -> "Divine Sanction"
        | OmniknightPurification -> "Purification"
        | OmniknightRepel -> "Heavenly Grace"
        | OracleFalsePromise -> "False Promise"
        | OracleFatesEdict -> "Fate's Edict"
        | OracleFortunesEnd -> "Fortune's End"
        | OraclePurifyingFlames -> "Purifying Flames"
        | PangolierGyroshell -> "Rolling Thunder"
        | PangolierGyroshellStop -> "Stop Rolling"
        | PangolierHeartpiercer -> "Heartpiercer"
        | PangolierLuckyShot -> "Lucky Shot"
        | PangolierShieldCrash -> "Shield Crash"
        | PangolierSwashbuckle -> "Swashbuckle"
        | PhantomAssassinBlur -> "Blur"
        | PhantomAssassinCoupDeGrace -> "Coup de Grace"
        | PhantomAssassinPhantomStrike -> "Phantom Strike"
        | PhantomAssassinStiflingDagger -> "Stifling Dagger"
        | PhantomLancerDoppelwalk -> "Doppelganger"
        | PhantomLancerJuxtapose -> "Juxtapose"
        | PhantomLancerPhantomEdge -> "Phantom Rush"
        | PhantomLancerSpiritLance -> "Spirit Lance"
        | PhoenixFireSpirits -> "Fire Spirits"
        | PhoenixIcarusDive -> "Icarus Dive"
        | PhoenixIcarusDiveStop -> "Stop Icarus Dive"
        | PhoenixLaunchFireSpirit -> "Launch Fire Spirit"
        | PhoenixSunRay -> "Sun Ray"
        | PhoenixSunRayStop -> "Stop Sun Ray"
        | PhoenixSunRayToggleMove -> "Toggle Movement"
        | PhoenixSupernova -> "Supernova"
        | PolarFurbolgUrsaWarriorThunderClap -> "Thunder Clap"
        | PuckDreamCoil -> "Dream Coil"
        | PuckEtherealJaunt -> "Ethereal Jaunt"
        | PuckIllusoryOrb -> "Illusory Orb"
        | PuckPhaseShift -> "Phase Shift"
        | PuckWaningRift -> "Waning Rift"
        | PudgeDismember -> "Dismember"
        | PudgeFleshHeap -> "Flesh Heap"
        | PudgeMeatHook -> "Meat Hook"
        | PudgeRot -> "Rot"
        | PugnaDecrepify -> "Decrepify"
        | PugnaLifeDrain -> "Life Drain"
        | PugnaNetherBlast -> "Nether Blast"
        | PugnaNetherWard -> "Nether Ward"
        | QueenofpainBlink -> "Blink"
        | QueenofpainScreamOfPain -> "Scream Of Pain"
        | QueenofpainShadowStrike -> "Shadow Strike"
        | QueenofpainSonicWave -> "Sonic Wave"
        | RattletrapBatteryAssault -> "Battery Assault"
        | RattletrapHookshot -> "Hookshot"
        | RattletrapPowerCogs -> "Power Cogs"
        | RattletrapRocketFlare -> "Rocket Flare"
        | RazorEyeOfTheStorm -> "Eye of the Storm"
        | RazorPlasmaField -> "Plasma Field"
        | RazorStaticLink -> "Static Link"
        | RazorUnstableCurrent -> "Unstable Current"
        | RikiBlinkStrike -> "Blink Strike"
        | RikiPermanentInvisibility -> "Cloak and Dagger"
        | RikiSmokeScreen -> "Smoke Screen"
        | RikiTricksOfTheTrade -> "Tricks of the Trade"
        | RoshanBash -> "Inner Fire"
        | RoshanDevotion -> "Echo Stomp"
        | RoshanInherentBuffs -> "Stasis Trap"
        | RoshanSlam -> "Tag Team"
        | RoshanSpellBlock -> "Echo Slam"
        | RubickArcaneSupremacy -> "Arcane Supremacy"
        | RubickEmpty -> "Stolen Spell"
        | RubickFadeBolt -> "Fade Bolt"
        | RubickHidden -> "Attribute Shift (Agility Gain)"
        | RubickNullField -> "Null Field"
        | RubickSpellSteal -> "Spell Steal"
        | RubickTelekinesis -> "Telekinesis"
        | RubickTelekinesisLand -> "Telekinesis Land"
        | SandkingBurrowstrike -> "Burrowstrike"
        | SandkingCausticFinale -> "Caustic Finale"
        | SandkingEpicenter -> "Epicenter"
        | SandkingSandStorm -> "Sand Storm"
        | SatyrHellcallerShockwave -> "Shockwave"
        | SatyrHellcallerUnholyAura -> "Unholy Aura"
        | SatyrSoulstealerManaBurn -> "Mana Burn"
        | SatyrTricksterPurge -> "Purge"
        | ShadowDemonDemonicPurge -> "Demonic Purge"
        | ShadowDemonDisruption -> "Disruption"
        | ShadowDemonShadowPoison -> "Shadow Poison"
        | ShadowDemonShadowPoisonRelease -> "Shadow Poison Release"
        | ShadowDemonSoulCatcher -> "Soul Catcher"
        | ShadowShamanEtherShock -> "Ether Shock"
        | ShadowShamanMassSerpentWard -> "Mass Serpent Ward"
        | ShadowShamanShackles -> "Shackles"
        | ShadowShamanVoodoo -> "Hex"
        | ShootFirework -> "Hunter in the Night"
        | ShredderChakram -> "Chakram"
        | ShredderReactiveArmor -> "Reactive Armor"
        | ShredderReturnChakram -> "Return Chakram"
        | ShredderTimberChain -> "Timber Chain"
        | ShredderWhirlingDeath -> "Whirling Death"
        | SilencerCurseOfTheSilent -> "Arcane Curse"
        | SilencerGlaivesOfWisdom -> "Glaives of Wisdom"
        | SilencerGlobalSilence -> "Global Silence"
        | SilencerLastWord -> "Last Word"
        | SkeletonKingHellfireBlast -> "Wraithfire Blast"
        | SkeletonKingMortalStrike -> "Mortal Strike"
        | SkeletonKingReincarnation -> "Reincarnation"
        | SkeletonKingVampiricAura -> "Vampiric Aura"
        | SkywrathMageAncientSeal -> "Ancient Seal"
        | SkywrathMageArcaneBolt -> "Arcane Bolt"
        | SkywrathMageConcussiveShot -> "Concussive Shot"
        | SkywrathMageMysticFlare -> "Mystic Flare"
        | SlardarAmplifyDamage -> "Corrosive Haze"
        | SlardarBash -> "Bash of the Deep"
        | SlardarSlithereenCrush -> "Slithereen Crush"
        | SlardarSprint -> "Guardian Sprint"
        | SlarkDarkPact -> "Dark Pact"
        | SlarkEssenceShift -> "Essence Shift"
        | SlarkPounce -> "Pounce"
        | SlarkShadowDance -> "Shadow Dance"
        | SniperAssassinate -> "Assassinate"
        | SniperHeadshot -> "Headshot"
        | SniperShrapnel -> "Shrapnel"
        | SniperTakeAim -> "Take Aim"
        | SpawnlordAura -> "Prowler Aura"
        | SpawnlordMasterBash -> "Life Break"
        | SpawnlordMasterFreeze -> "Petrify"
        | SpawnlordMasterStomp -> "Desecrate"
        | SpectreDesolate -> "Desolate"
        | SpectreDispersion -> "Dispersion"
        | SpectreHaunt -> "Haunt"
        | SpectreReality -> "Reality"
        | SpectreSpectralDagger -> "Spectral Dagger"
        | SpiritBreakerBulldoze -> "Bulldoze"
        | SpiritBreakerChargeOfDarkness -> "Charge of Darkness"
        | SpiritBreakerEmpoweringHaste -> "Empowering Haste"
        | SpiritBreakerGreaterBash -> "Greater Bash"
        | SpiritBreakerNetherStrike -> "Nether Strike"
        | StormSpiritBallLightning -> "Ball Lightning"
        | StormSpiritElectricVortex -> "Electric Vortex"
        | StormSpiritOverload -> "Overload"
        | StormSpiritStaticRemnant -> "Static Remnant"
        | SvenGodsStrength -> "God's Strength"
        | SvenGreatCleave -> "Great Cleave"
        | SvenStormBolt -> "Storm Hammer"
        | SvenWarcry -> "Warcry"
        | TechiesFocusedDetonate -> "Focused Detonate"
        | TechiesLandMines -> "Proximity Mines"
        | TechiesMinefieldSign -> "Minefield Sign"
        | TechiesRemoteMines -> "Remote Mines"
        | TechiesRemoteMinesSelfDetonate -> "PINPOINT DETONATE"
        | TechiesStasisTrap -> "Stasis Trap"
        | TechiesSuicide -> "Blast Off!"
        | TemplarAssassinMeld -> "Meld"
        | TemplarAssassinPsiBlades -> "Psi Blades"
        | TemplarAssassinPsionicTrap -> "Psionic Trap"
        | TemplarAssassinRefraction -> "Refraction"
        | TemplarAssassinSelfTrap -> "Trap"
        | TemplarAssassinTrap -> "Trap"
        | TerrorbladeConjureImage -> "Conjure Image"
        | TerrorbladeMetamorphosis -> "Metamorphosis"
        | TerrorbladeReflection -> "Reflection"
        | TerrorbladeSunder -> "Sunder"
        | TidehunterAnchorSmash -> "Anchor Smash"
        | TidehunterGush -> "Gush"
        | TidehunterKrakenShell -> "Kraken Shell"
        | TidehunterRavage -> "Ravage"
        | TinkerHeatSeekingMissile -> "Heat-Seeking Missile"
        | TinkerLaser -> "Laser"
        | TinkerMarchOfTheMachines -> "March of the Machines"
        | TinkerRearm -> "Rearm"
        | TinyAvalanche -> "Avalanche"
        | TinyCraggyExterior -> "Tree Grab"
        | TinyGrow -> "Grow"
        | TinyToss -> "Toss"
        | TinyTossTree -> "Tree Throw"
        | TornadoTempest -> "Tempest"
        | TreantEyesInTheForest -> "Eyes In The Forest"
        | TreantLeechSeed -> "Leech Seed"
        | TreantLivingArmor -> "Living Armor"
        | TreantNaturesGuise -> "Nature's Guise"
        | TreantOvergrowth -> "Overgrowth"
        | TrollWarlordBattleTrance -> "Battle Trance"
        | TrollWarlordBerserkersRage -> "Berserker's Rage"
        | TrollWarlordFervor -> "Fervor"
        | TrollWarlordWhirlingAxesMelee -> "Whirling Axes (Melee)"
        | TrollWarlordWhirlingAxesRanged -> "Whirling Axes (Ranged)"
        | TuskFrozenSigil -> "Frozen Sigil"
        | TuskIceShards -> "Ice Shards"
        | TuskIceShardsStop -> "Ice Shards Stop"
        | TuskLaunchSnowball -> "Launch Snowball"
        | TuskSnowball -> "Snowball"
        | TuskTagTeam -> "Tag Team"
        | TuskWalrusKick -> "Walrus Kick"
        | TuskWalrusPunch -> "Walrus PUNCH!"
        | UndyingDecay -> "Decay"
        | UndyingFleshGolem -> "Flesh Golem"
        | UndyingSoulRip -> "Soul Rip"
        | UndyingTombstone -> "Tombstone"
        | UndyingTombstoneZombieAura -> "Mana Void"
        | UndyingTombstoneZombieDeathstrike -> "Deathlust"
        | UrsaEarthshock -> "Earthshock"
        | UrsaEnrage -> "Enrage"
        | UrsaFurySwipes -> "Fury Swipes"
        | UrsaOverpower -> "Overpower"
        | VengefulspiritCommandAura -> "Vengeance Aura"
        | VengefulspiritMagicMissile -> "Magic Missile"
        | VengefulspiritNetherSwap -> "Nether Swap"
        | VengefulspiritWaveOfTerror -> "Wave of Terror"
        | VenomancerPlagueWard -> "Plague Ward"
        | VenomancerPoisonNova -> "Poison Nova"
        | VenomancerPoisonSting -> "Poison Sting"
        | VenomancerVenomousGale -> "Venomous Gale"
        | ViperCorrosiveSkin -> "Corrosive Skin"
        | ViperNethertoxin -> "Nethertoxin"
        | ViperPoisonAttack -> "Poison Attack"
        | ViperViperStrike -> "Viper Strike"
        | VisageGraveChill -> "Grave Chill"
        | VisageGravekeepersCloak -> "Gravekeeper's Cloak"
        | VisageSoulAssumption -> "Soul Assumption"
        | VisageStoneFormSelfCast -> "Stone Form"
        | VisageSummonFamiliars -> "Summon Familiars"
        | VisageSummonFamiliarsStoneForm -> "Stone Form"
        | WarlockFatalBonds -> "Fatal Bonds"
        | WarlockGolemFlamingFists -> "Flaming Fists"
        | WarlockGolemPermanentImmolation -> "Permanent Immolation"
        | WarlockRainOfChaos -> "Chaotic Offering"
        | WarlockShadowWord -> "Shadow Word"
        | WarlockUpheaval -> "Upheaval"
        | WeaverGeminateAttack -> "Geminate Attack"
        | WeaverShukuchi -> "Shukuchi"
        | WeaverTheSwarm -> "The Swarm"
        | WeaverTimeLapse -> "Time Lapse"
        | WindrunnerFocusfire -> "Focus Fire"
        | WindrunnerPowershot -> "Powershot"
        | WindrunnerShackleshot -> "Shackleshot"
        | WindrunnerWindrun -> "Windrun"
        | WinterWyvernArcticBurn -> "Arctic Burn"
        | WinterWyvernColdEmbrace -> "Cold Embrace"
        | WinterWyvernSplinterBlast -> "Splinter Blast"
        | WinterWyvernWintersCurse -> "Winter's Curse"
        | WispOvercharge -> "Overcharge"
        | WispRelocate -> "Relocate"
        | WispSpirits -> "Spirits"
        | WispSpiritsIn -> "Spirits Movement"
        | WispSpiritsOut -> "Spirits Out"
        | WispTether -> "Tether"
        | WispTetherBreak -> "Break Tether"
        | WitchDoctorDeathWard -> "Death Ward"
        | WitchDoctorMaledict -> "Maledict"
        | WitchDoctorParalyzingCask -> "Paralyzing Cask"
        | WitchDoctorVoodooRestoration -> "Voodoo Restoration"
        | ZuusArcLightning -> "Arc Lightning"
        | ZuusCloud -> "Nimbus"
        | ZuusLightningBolt -> "Lightning Bolt"
        | ZuusStaticField -> "Static Field"
        | ZuusThundergodsWrath -> "Thundergod's Wrath"