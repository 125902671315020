namespace StatBanana.Web.Client.Services.Firebase

open System

open Fable.Core.JsInterop
open Fable.PowerPack

open StatBanana.Domain
open StatBanana.Utils
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Dto
open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Import.Firebase.Database
open StatBanana.Web.Client.Import.Firebase.Firestore

module FirebaseCollaborativeSessionService =

    let private firebase : Firebase.IExports = importAll "firebase/app"

    let private bindFirebaseError (error : Exception) =
        Fable.Import.JS.console.error (error.Message, error)
        error.Message
        |> CollaborativeSessionOperationFailed
        |> Promise.reject

    let private enableCollaboration
        (app : Firebase.App.App)
        (SessionId sessionId) =

        createObj [
            "enabled" ==> true
        ]
        :?> Fable.Import.JS.Object
        |> app.database().ref("session_collaboration_state/" + sessionId).update
        |> Promise.map (fun _ -> ())
        |> Promise.catchBind bindFirebaseError

    let private disableCollaboration
        (app : Firebase.App.App)
        (SessionId sessionId) =

        createObj [
            "enabled" ==> false
            "last_updated" ==> firebase.database.ServerValue.TIMESTAMP
            "map_marker_positions" ==> None
            "playback_state" ==> None
            "save" ==> None
            "time" ==> None
        ]
        :?> Fable.Import.JS.Object
        |> app.database().ref("session_collaboration_state/" + sessionId).update
        |> Promise.map (fun _ -> ())
        |> Promise.catchBind bindFirebaseError

    let private enterSession
        (app : Firebase.App.App)
        (currentUser : AuthenticatedUser)
        isOwner
        (SessionId sessionId) =

        let dbPath =
            sprintf "/session_collaboration_users/%s/%s"
                sessionId
                currentUser.id
        let firestorePath =
            sprintf "/sessions/%s/users/%s"
                sessionId
                currentUser.id
        let sessionStatusDatabaseRef =
            app.database().ref(dbPath)
        let sessionStatusFirestoreRef =
            app.firestore().doc(firestorePath)

        let collaborativeSessionUser =
            { id = currentUser.id
              displayName = currentUser.displayName
              email = currentUser.email
              photo = currentUser.photo }

        let isOfflineForDatabase = createObj [
            "state" ==> "offline"
            "is_owner" ==> isOwner
            "last_changed" ==> firebase.database.ServerValue.TIMESTAMP
        ]

        let isOnlineForDatabase = createObj [
            "state" ==> "online"
            "is_owner" ==> isOwner
            "profile" ==>
                (collaborativeSessionUser
                 |> Database.Strategiser.CollaborativeSessionDto.User.fromDomain)
            "last_changed" ==> firebase.database.ServerValue.TIMESTAMP
        ]

        let isOfflineForFirestore = createObj [
            "state" ==> "offline"
            "isOwner" ==> isOwner
            "last_changed" ==> firebase.firestore.FieldValue.serverTimestamp()
        ]

        let isOnlineForFirestore = createObj [
            "state" ==> "online"
            "isOwner" ==> isOwner
            "profile" ==>
                (collaborativeSessionUser
                 |> Firestore.Strategiser.CollaborativeSessionDto.User.fromDomain)
            "lastChanged" ==> firebase.firestore.FieldValue.serverTimestamp()
        ]

        let onConnectedCallback (snapshot : DataSnapshot) _ =
            match snapshot.``val`` () with
            | Some _ ->
                sessionStatusDatabaseRef.onDisconnect().set(Some isOfflineForDatabase)
                |> Promise.map (fun _ ->
                    sessionStatusDatabaseRef.set(isOnlineForDatabase |> Some))
                |> ignore
                :> obj
                |> Some
            | None ->
                sessionStatusDatabaseRef.set(Some isOfflineForFirestore)
                |> ignore
                sessionStatusFirestoreRef.set(isOnlineForFirestore :?> DocumentData)
                :> obj
                |> ignore
                None

        app.database().ref(".info/connected").on(EventType.Value, onConnectedCallback)
        |> ignore

    let private listenForUpdates
        (app : Firebase.App.App)
        (SessionId sessionId)
        onNew =

        let toUnit (result : 'a) : obj option =
            let resultObj : obj =
                result
                |> (fun r -> upcast r)
            Some resultObj

        let onValueHandler (snap : DataSnapshot) _  =

            let latestState = snap.``val``()

            match latestState with
            | Some latestState ->
                let enabled =
                    latestState
                    |> JsonAdapter.getBoolOption "enabled"
                    |> Option.defaultValue false

                let playbackState =
                    latestState
                    |> JsonAdapter.getStringOption "playback_state"
                    |> Option.map Database.Strategiser.PlaybackStateDto.PlaybackState.toDomain
                    |> Option.defaultValue PlaybackState.Paused

                let time =
                    latestState
                    |> JsonAdapter.getIntOption "time"
                    |> Option.defaultValue 0

                let mapMarkerPositions =
                    latestState
                    |> JsonAdapter.getObjOption "map_marker_positions"
                    |> Option.map Database.Strategiser.CollaborativeSessionDto.MapMarkerPositions.toDomain
                    |> Option.defaultValue Map.empty

                let latestSave =
                    latestState
                    |> JsonAdapter.getObjOption "save"
                    |> Option.map Database.Strategiser.SessionDto.Save.toDomain

                onNew
                    (enabled,
                     playbackState,
                     time,
                     mapMarkerPositions,
                     latestSave)
                |> toUnit
            | None ->
                onNew
                    (false,
                     PlaybackState.Paused,
                     0,
                     Map.empty,
                     None)
                |> toUnit

        app.database()
            .ref("session_collaboration_state/" + sessionId)
            .on(EventType.Value, onValueHandler)
        |> ignore

    let private listenForViewers
        (app : Firebase.App.App)
        (SessionId sessionId)
        onNew
        onError =

        let onNewHandler (snap : QuerySnapshot) =

            let wentOnline = ResizeArray<CollaborativeSessionUser> []
            let wentOffline = ResizeArray<CollaborativeSessionUser> []

            let collectChanges (change : DocumentChange) =
                match change.``type`` with
                | DocumentChangeType.Added ->
                    change.doc.data()?profile
                    |> Firestore.Strategiser.CollaborativeSessionDto.User.toDomain
                    |> wentOnline.Add
                | DocumentChangeType.Removed ->
                    change.doc.data()?profile
                    |> Firestore.Strategiser.CollaborativeSessionDto.User.toDomain
                    |> wentOffline.Add
                | DocumentChangeType.Modified ->
                    ()

            snap.docChanges()
            |> Seq.iter collectChanges

            onNew
                ((wentOnline |> Seq.toList), (wentOffline |> Seq.toList))

        let onErrorHandler (error : Fable.Import.JS.Error) =
            Fable.Import.JS.console.error error
            error.message
            |> Exception
            |> onError

        jsOptions<QueryOnSnapshotObserver> <| fun observer ->
            observer.next <- onNewHandler |> Some
            observer.error <- onErrorHandler |> Some
            observer.complete <- ignore |> Some
        |> app.firestore()
            .collection("sessions")
            .doc(sessionId)
            .collection("users")
            .where(!^"state", WhereFilterOp.EqualTo, "online" :> obj |> Some)
            .onSnapshot
        |> ignore

    let private sendUpdate
        (app : Firebase.App.App)
        playbackState
        time
        (mapMarkerPositions : Map<LayerId, Strategiser.Coordinates>)
        update
        (SessionId sessionId) =

        let updatedData ownerId =
            createObj [
                "enabled" ==> true
                "last_updated" ==> firebase.database.ServerValue.TIMESTAMP
                "map_marker_positions" ==>
                    (mapMarkerPositions
                     |> Database.Strategiser.CollaborativeSessionDto.MapMarkerPositions.fromDomain)
                "owner_id" ==> ownerId
                "playback_state" ==>
                    (playbackState
                     |> Database.Strategiser.PlaybackStateDto.PlaybackState.fromDomain)
                "save" ==>
                    (update
                     |> Database.Strategiser.SessionDto.Save.fromDomain)
                "time" ==> time
            ]

        let nowInMillis =
            DateTimeOffset.Now.ToUnixTimeMilliseconds() |> float

        (fun currentSessionData ->
            match currentSessionData with
            | Some sessionData ->
                match sessionData?owner_id, sessionData?last_updated with
                | Some ownerId, Some lastUpdated ->
                    if (lastUpdated |> float) < nowInMillis then
                        ownerId |> updatedData |> Some
                    else
                        sessionData |> Some
                | Some ownerId, None ->
                    ownerId |> updatedData |> Some
                | None, Some _
                | None, None ->
                    sessionData |> Some
            | None ->
                None)
        |> app.database().ref("session_collaboration_state/" + sessionId).transaction
        |> Promise.map (fun _ -> ())
        |> Promise.catchBind bindFirebaseError

    /// <summary>
    ///     The service implementation.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase app.
    /// </param>
    let initialise (app : Firebase.App.App) : CollaborativeSessionService =
        { disableCollaboration = disableCollaboration app
          enableCollaboration = enableCollaboration app
          enterSession = enterSession app
          listenForUpdates = listenForUpdates app
          listenForViewers = listenForViewers app
          sendUpdate = sendUpdate app }