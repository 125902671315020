namespace StatBanana.Web.Client.Services.Firebase

open System

open Fable.PowerPack

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Import.Firebase

module FirebaseUserService =

    let private usersCollectionName = "users"

    let private bindFirebaseError (error : Exception) =
        Fable.Import.Browser.console.error (error.Message, error)
        error.Message
        |> Exception
        |> Promise.reject

    let getUser
        (firebaseApp : Firebase.App.App)
        (userId : string) =
        let doc =
            firebaseApp.firestore()
                .collection(usersCollectionName)
                .doc(userId)
                .get()
        doc
        |> Promise.map (fun (doc : Firestore.DocumentSnapshot) ->
            doc.data()
            |> Option.map DocumentDeserialiser.UserProfile.userProfile)
        |> Promise.catchBind bindFirebaseError

    /// <summary>
    ///     The service implementation.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase app.
    /// </param>
    let initialise (app : Firebase.App.App) : UserStoreService =
        { getUser = getUser app }