namespace StatBanana.Web.Client

open Elmish

open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Pages

/// Elmish update logic for handling GlobalMsg
module GlobalMsgHandler =

    /// Update app state to display the ClientError page
    let private initClientErrorPage model externalCmds =
        let m, cmd = ClientErrorPage.init ()
        { model with PageModel = ClientErrorPageModel m},
        Cmd.batch (Cmd.map ClientErrorPageMsg cmd :: externalCmds)

    /// Update application state in response to a GlobalMsg
    let update (app : AppConfig) (msg : GlobalMsg) (model : Model) : Model * Cmd<Msg> =
        match msg with
        | GlobalMsg.UnhandledError error ->
            // Log error, report to Google Analytics, and display error page
            Fable.Import.JS.console.error("UnhandledError: ", error)
            initClientErrorPage model [ AnalyticsCmd.sendError app error ]
        | GlobalMsg.UnhandledException ex ->
            // Log error, report to Google Analytics, and display error page
            Fable.Import.JS.console.error("UnhandledException: ", ex)
            initClientErrorPage model [ AnalyticsCmd.sendException app ex ]
