namespace StatBanana.Web.Client.Services.Firebase

open System
open Fable.Core.JsInterop

open StatBanana.Domain
open StatBanana.Domain
open StatBanana.Dto.Firestore.Games
open StatBanana.Dto.Firestore.Subscriptions
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Dto.Firestore
open StatBanana.Web.Client.Import.Firebase
open StatBanana.Utils

/// Deserialise Firestore documents.
module DocumentDeserialiser =

    type DocumentParsingException (msg : string) =
        inherit Exception(msg)

    module Session =
        let firestoreSession
            (session : Firestore.DocumentData)
            : Strategiser.Session =

            let createdAt = session.Item "createdAt"
            let game = session.Item "game"
            let isPublic = session.Item "isPublic"
            let lastSavedAt = session.Item "lastSavedAt"
            let latestSave = session.Item "latestSave"
            let name = session.Item "name"
            let userId = session.Item "userId"

            match createdAt, game, isPublic, lastSavedAt, latestSave, name, userId with
            | Some createdAt,
              Some game,
              Some isPublic,
              Some lastSavedAt,
              Some latestSave,
              Some name,
              Some userId ->
                { createdAt =
                    (createdAt :?> Firestore.Timestamp).seconds
                    |> int64
                    |> DateTimeOffset.FromUnixTimeSeconds
                  game = game :?> string |> GameDto.Game.toDomain
                  isPublic = isPublic :?> bool
                  lastSavedAt =
                    (lastSavedAt :?> Firestore.Timestamp).seconds
                    |> int64
                    |> DateTimeOffset.FromUnixTimeSeconds
                  latestSave =
                    latestSave |> Strategiser.SessionDto.Save.toDomain
                  name = name :?> string
                  userId = userId :?> string }
            | _ ->
                sprintf "Required fields for Session DTO not found"
                |> DocumentParsingException
                |> raise

    module UserProfile =

        let private getSubscriptionForGame subscriptions game =
            subscriptions?(game |> Game.toId)
            |> Option.bind (fun sub ->
                match sub?started, sub?expires, sub?plan, sub?renewAs, sub?externalId, sub?status with
                | Some started, Some expires, Some plan, Some renewAs, Some externalId, Some status ->
                    let renewAs =
                        match renewAs with
                        | null ->
                            None
                        | renewObj ->
                            renewObj |> PlanDto.Plan.toDomain |> Some
                    { started =
                        started?seconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                      expires =
                        expires?seconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                      renewAs = renewAs
                      plan = plan |> PlanDto.Plan.toDomain
                      status = status |> SubscriptionDto.SubscriptionStatus.toDomain
                      externalId = externalId |> SubscriptionDto.ExternalSubscriptionId.toDomain }
                    |> Some
                | Some started, Some expires, Some plan, None, Some externalId, Some status ->
                    { started =
                        started?seconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                      expires =
                        expires?seconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                      renewAs = None
                      plan = plan |> PlanDto.Plan.toDomain
                      status = status |> SubscriptionDto.SubscriptionStatus.toDomain
                      externalId = externalId |> SubscriptionDto.ExternalSubscriptionId.toDomain }
                    |> Some
                | _ ->
                    None)

        let private getTrialUsedForGame trialsUsed game =
            trialsUsed
            |> JsonAdapter.getBoolOption (game |> Game.toId)
            |> Option.defaultValue false

        let userProfile (userProfile : Firestore.DocumentData) : UserProfile =
            let currentSubscriptions = userProfile.Item "currentSubscriptions"
            let trialsUsed = userProfile.Item "trialsUsed"

            let stripeCardInfo =
                let stripeCardBrand = userProfile.Item "stripeCardBrand"
                let stripeCardExpiryMonth = userProfile.Item "stripeCardExpiryMonth"
                let stripeCardExpiryYear = userProfile.Item "stripeCardExpiryYear"
                let stripeCardLastFour = userProfile.Item "stripeCardLast4"

                match stripeCardBrand, stripeCardExpiryMonth, stripeCardExpiryYear, stripeCardLastFour with
                | Some stripeCardBrand, Some stripeCardExpiryMonth, Some stripeCardExpiryYear, Some stripeCardLastFour ->
                    { brand = stripeCardBrand :?> string
                      expiryMonth = stripeCardExpiryMonth :?> int
                      expiryYear = stripeCardExpiryYear :?> int
                      lastFour = stripeCardLastFour :?> int }
                    |> Some
                | _ ->
                    None

            match currentSubscriptions, trialsUsed with
            | Some subscriptions, Some trialsUsed ->
                { currentSubscriptions =
                    { dota2 = Dota2 |> getSubscriptionForGame subscriptions }
                  stripeCardInfo = stripeCardInfo
                  trialsUsed =
                    { dota2 = Dota2 |> getTrialUsedForGame trialsUsed } }
            | Some subscriptions, None ->
                { currentSubscriptions =
                    { dota2 = Dota2 |> getSubscriptionForGame subscriptions }
                  stripeCardInfo = stripeCardInfo
                  trialsUsed = TrialsUsedSet.unused () }
            | None, Some trialsUsed ->
                { currentSubscriptions = SubscriptionSet.getDefault ()
                  stripeCardInfo = stripeCardInfo
                  trialsUsed =
                    { dota2 = Dota2 |> getTrialUsedForGame trialsUsed } }
            | None, None ->
                { currentSubscriptions = SubscriptionSet.getDefault ()
                  stripeCardInfo = stripeCardInfo
                  trialsUsed = TrialsUsedSet.unused () }