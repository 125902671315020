/// A modal that shows keyboard shortcuts
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.KeyboardShortcutsModal

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fulma

open StatBanana.Web.Client.Components.Molecules

/// <summary>
///     Renders a modal that shows available keyboard shortcuts
/// </summary>
///
/// <param name="isActive">
///     Determines whether the modal is opened or closed
/// </param>
/// <param name="onClose">
///     The function to call when the modal is closed
/// </param>
let modal isOpened onClose =
    let closeModal _ = onClose ()
    let closeButton =
        Button.button [ Button.OnClick closeModal
                        Button.Props [ Id "KeyboardShortcutsModal-close" ] ]
            [ str "Close" ]
    Modal.modal [ Modal.IsActive isOpened
                  Modal.Props [ Id "KeyboardShortcutsModal" ] ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str "Keyboard Shortcuts" ]
            ]
            Modal.Card.body [] [
                KeyboardShortcutsHelp.help
            ]
            Modal.Card.foot [] [
                closeButton
            ]
        ]
    ]