/// A component that stores timeline lanes
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.TimelineLanes

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fable.Import.React
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain.Strategiser

type Styles = { lanes : string }

type Model =
    { RulerWidth : int
      SelectedKeyframe : Selectable<LayerUnit * int>
      TimelineLayers : Layer list
      TimelineZoomLevel : TimelineZoomLevel }

/// <summary>
///     Renders a list of lanes
/// </summary>
///
/// <param name="model">
///     A record containing information required to render the lanes:
///     the number of marks per second, a list of MapItems, the width of the
///     ruler, the selected and the current zoom level of the timeline
/// </param>
///
/// <param name="onKeyframeClick">
///     The function to call when a keyframe is clicked
/// </param>
///
/// <param name="onKeyframeMove">
///     The function to call when a keyframe is moved
/// </param>
let lanes
    (model : Model)
    (onKeyframeClick : LayerUnit * int -> unit)
    (onKeyframeMove : LayerUnit * int -> unit)
    (onKeyframeMoved : LayerUnit * int -> int -> unit) : ReactElement =
    let styles: Styles = importAll "./TimelineLanes.sass"
    let renderLaneForLayer layer =
        TimelineLane.lane
             model.TimelineZoomLevel
             model.RulerWidth
             model.SelectedKeyframe
             onKeyframeClick
             onKeyframeMove
             onKeyframeMoved
             layer
    div [ ClassName styles.lanes
          Style [ Width (model.RulerWidth + 50) ] ] [
        model.TimelineLayers
        |> List.map renderLaneForLayer
        |> ofList
    ]