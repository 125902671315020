/// Ruler for timeline
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.TimelineRuler

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import

type Styles =
    { container : string
      labels : string
      label : string
      marks : string
      mark : string
      largeMark : string }

type Model = {
    MarksPerSecond : int
    TimelineDuration : int
    TimelineZoomLevel : TimelineZoomLevel }

/// <summary>
///     The function that calculates the distance between ruler marks in pixels
/// </summary>
///
/// <param name="marksPerSecond">
///     The click event to translate
/// </param>
/// <param name="zoomLevel">
///     The current zoom level of the timeline
/// </param>
let private getPixelDistanceBetweenMarks marksPerSecond zoomLevel =
    (100. * (zoomLevel |> TimelineZoomLevel.toFloat)
          * (1. / float marksPerSecond))
    |> int

/// <summary>
///     The function that calculates the width of the ruler from timeline params.
///     No DOM access.
/// </summary>
///
/// <param name="timelineDuration">
///     The maximum duration of the timeline
/// </param>
///
/// <param name="marksPerSecond">
///     The click event to translate
/// </param>
///
/// <param name="zoomLevel">
///     The current zoom level of the timeline
/// </param>
let getRulerWidth
    (timelineDuration : int)
    (marksPerSecond : int)
    (zoomLevel : TimelineZoomLevel) : int =
    (getPixelDistanceBetweenMarks marksPerSecond zoomLevel)
    * marksPerSecond
    * (timelineDuration / 1000)

let private styles: Styles = importAll "./TimelineRuler.sass"

/// <summary>
///     Renders a sequence of divs that represent ruler labels for time.
/// </summary>
///
/// <param name="timelineDuration">
///     The maximum duration of the timeline in milliseconds.
/// </param>
///
/// <param name="marksPerSecond">
///     The amount of marks rendered between each second on the timeline.
/// </param>
///
/// <param name="zoomLevel">
///     The current zoom level of the timeline.
/// </param>
let private labels timelineDuration marksPerSecond zoomLevel =
    let numberOfLabels = (timelineDuration / 1000)
    let labels : int list = [ for i in 0 .. numberOfLabels -> i ]
    let renderedLabels =
        List.map
            (fun i ->
                let distanceBetweenMarks =
                    getPixelDistanceBetweenMarks
                       marksPerSecond
                       zoomLevel
                let left = (distanceBetweenMarks * marksPerSecond * i + 5)
                div
                    [ ClassName styles.label
                      Style [ CSSProp.Left left ] ]
                    [ str (i.ToString ()) ])
            labels
    match zoomLevel with
    | WidestZoom ->
        renderedLabels
        |> List.mapi (fun i e -> if i % 2 = 0 then Some(e) else None)
        |> List.choose id
    | _ -> renderedLabels

/// <summary>
///     Renders a sequence of divs that represent ruler marks
/// </summary>
///
/// <param name="timelineDuration">
///     The maximum duration of the timeline in milliseconds.
/// </param>
///
/// <param name="marksPerSecond">
///     The amount of marks rendered between each second on the timeline.
/// </param>
///
/// <param name="zoomLevel">
///     The current zoom level of the timeline.
/// </param>
let private marks timelineDuration marksPerSecond zoomLevel =
    let numberOfMarks = marksPerSecond * (timelineDuration / 1000)
    let renderMark i =
        let leftValue =
            ((getPixelDistanceBetweenMarks marksPerSecond zoomLevel) * i)
        let sideClassName =
            match (zoomLevel, i) with
            | WidestZoom, i
                when i % (marksPerSecond * 2) = 0 -> styles.largeMark
            | zoomLevel, i
                when i % marksPerSecond = 0
                     && zoomLevel
                        |> TimelineZoomLevel.isWidestZoom
                        |> not
                 -> styles.largeMark
            | _ -> ""
        let className =
            ClassNames.classNames
                [ (styles.mark, true)
                  (sideClassName, sideClassName.Length > 0) ]
        div [ ClassName className
              Style [ CSSProp.Left leftValue ] ] [

        ]
    List.map renderMark [ for i in 0 .. numberOfMarks -> i ]

/// <summary>
///     Renders a timeline ruler with marks and time labels
/// </summary>
///
/// <param name="model">
///     A record containing information required to render the ruler:
///     end time, number of marks within a second, the current zoom level
/// </param>
let ruler (model : Model) : ReactElement =
    let rulerWidth =
        (getRulerWidth
            model.TimelineDuration
            model.MarksPerSecond
            model.TimelineZoomLevel)
        + 50  // we add 50px of buffer spacing for the last label
    div [ ClassName styles.container
          Style [ Width rulerWidth ] ] [
        div [ ClassName styles.labels ]
            (labels
                 model.TimelineDuration
                 model.MarksPerSecond
                 model.TimelineZoomLevel)
        div [ ClassName styles.marks ]
            (marks
                 model.TimelineDuration
                 model.MarksPerSecond
                 model.TimelineZoomLevel)
    ]