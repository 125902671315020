/// Rendering a timeline lane that contains keyframes
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.TimelineLane

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import

type Styles =
    { keyframe : string
      lane : string
      selected : string }

let private styles: Styles = importAll "./TimelineLane.sass"

let private getPositionOfKeyframe zoomLevel time =
    float (time / 10) * TimelineZoomLevel.toFloat (zoomLevel)

let private renderKeyframe
    rulerWidth
    zoomLevel
    isSelected
    onClick
    onMove
    onMoved
    (time, _) =
    let onKeyframeClickHandler (event : MouseEvent) =
        event.stopPropagation ()
        onClick ()
    let keyframePosition =
        getPositionOfKeyframe zoomLevel time
    let onKeyframeMoveHandler _ =
        onMove ()
    let onKeyframeMovedHandler time =
        onMoved time
    let keyframe ref =
         if time > 0 then
             let className =
                 ClassNames.classNames
                    [ ("handle", true)
                      (styles.keyframe, true)
                      (styles.selected, isSelected) ]
             div [ ClassName className
                   OnClick onKeyframeClickHandler
                   Ref ref ] []
         else fragment [] []
    DraggableTimelineObject.draggable
        rulerWidth
        zoomLevel
        onKeyframeMoveHandler
        onKeyframeMovedHandler
        (int keyframePosition)
        keyframe
        ignore

let private keyframes
    rulerWidth
    zoomLevel
    selectedKeyframe
    onKeyframeClick
    onKeyframeMove
    onKeyframeMoved
    (layerUnit : LayerUnit) =
    layerUnit.keyframes
    |> Map.toList
    |> List.map (fun keyframe ->
        let isSelected =
            match selectedKeyframe with
            | Selected (selectedLayerUnit, selectedTime) ->
                selectedLayerUnit = layerUnit && selectedTime = fst keyframe
            | NotSelected ->
                false
        (renderKeyframe
            rulerWidth
            zoomLevel
            isSelected
            (fun () -> onKeyframeClick (layerUnit, fst keyframe))
            (fun () -> onKeyframeMove (layerUnit, fst keyframe))
            (fun newTime ->
                onKeyframeMoved (layerUnit, fst keyframe) newTime)
            keyframe))
    |> ofList

/// <summary>
///     Renders a timeline lane that shows the keyframes for a mapitem in the
///     timeline field
/// </summary>
///
/// <param name="zoomLevel">
///     The current timeline zoom level
/// </param>
///
/// <param name="onKeyframeClick">
///     The function to call when a keyframe is clicked
/// </param>
///
/// <param name="onKeyframeMove">
///     The function to call when a keyframe is moved
/// </param>
///
/// <param name="layer">
///     The TimelineLayer to render the timeline lane for
/// </param>
let lane
    (zoomLevel : TimelineZoomLevel)
    (rulerWidth : int)
    (selectedKeyframe : Selectable<LayerUnit * int>)
    (onKeyframeClick : LayerUnit * int-> unit)
    (onKeyframeMove : LayerUnit * int -> unit)
    (onKeyframeMoved : LayerUnit * int -> int -> unit)
    (layer : Layer) : ReactElement =
    match layer with
    | LayerGroup group ->
        let renderChildLane unit =
            div [ ClassName styles.lane ] [
                keyframes
                    rulerWidth
                    zoomLevel
                    selectedKeyframe
                    onKeyframeClick
                    onKeyframeMove
                    onKeyframeMoved
                    unit
            ]
        let childLanes =
            if group.opened then
                ofList (List.map renderChildLane group.children)
            else nothing
        fragment [] [
            div [ ClassName styles.lane ] []
            childLanes
        ]
    | LayerUnit unit ->
        div [ ClassName styles.lane ] [
            keyframes
                rulerWidth
                zoomLevel
                selectedKeyframe
                onKeyframeClick
                onKeyframeMove
                onKeyframeMoved
                unit
        ]