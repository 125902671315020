/// An item info pane for the sidebar
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.ItemInfoPane

open Fable.Core
open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma
open Elmish.React

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import.ReactColor

type Styles = {
    abilityContainer : string
    abilityLabel: string
    container : string
    draggableContainer : string }

/// <summary>
///     Renders a contextual menu for a selected Item.
/// </summary>
///
/// <param name="isViewerMode">
///     Whether the component should be rendered in viewer (no edit) mode.
/// </param>
///
/// <param name="onSideChange">
///     The function that is called when the Side is changed.
/// </param>
///
/// <param name="onBuildingStateChange">
///     The function that is called when the BuildingState is changed.
/// </param>
///
/// <param name="onTextEdit">
///     The function that is called when the text is changed.
/// </param>
///
/// <param name="onColorEdit">
///     The function that is called when the text color is changed.
/// </param>
///
/// <param name="onFontSizeEdit">
///     The function that is called when the text font size is changed.
/// </param>
///
/// <param name="onDelete">
///     The function that is called when the delete button is clicked.
/// </param>
///
/// <param name="item">
///     An Item to render the contextual menu for.
/// </param>
let itemInfoPane
    (isViewerMode : bool)
    (onSideChange : Dota2.Side -> unit)
    (onAttackRangeStateChange : AttackRangeCircleVisibility -> unit)
    (onBuildingStateChange : BuildingState -> unit)
    (onTextColorEdit : string -> unit)
    (onTextFontSizeEdit : float -> unit)
    (onTextWidthChange : float -> unit)
    (onDelete : unit -> unit)
    (onItemDroppedOutOfPane : MouseEvent -> Item -> unit)
    (item : Item) : ReactElement =
    let styles: Styles = importAll "./ItemInfoPane.sass"
    let renderLabel label =
         Label.label [ Label.Modifiers [ Modifier.TextColor IsLight ] ] [
             str label
         ]
    let hideIfInViewerMode componentToRender =
        if isViewerMode then nothing
        else componentToRender

    let renderSideOptions item =
        match item with
        | Dota2Item (Dota2HeroItem hero) ->
            let currentSide = hero.side
            let onSelect side = onSideChange side
            Field.div [] [
                SideSelector.dota2SideSelector currentSide onSelect
            ]
        | Dota2Item (Dota2WardItem ward) ->
            let currentSide = ward.side
            let onSelect side = onSideChange side
            Field.div [] [
                SideSelector.dota2SideSelector currentSide onSelect
            ]
        | Dota2Item (Dota2LaneCreepItem creep) ->
            let currentSide = creep.side
            let onSelect side = onSideChange side
            Field.div [] [
                SideSelector.dota2SideSelector currentSide onSelect
            ]
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            nothing

    let renderAbilities item =
        match item with
        | Dota2Item (Dota2HeroItem hero) ->
            let abilities =
                Dota2.Hero.getAbilities hero.hero

            let renderAbility (ability : Dota2.Ability) =
                let dota2AbilityItem : Dota2AbilityItem =
                    { ability = ability }
                let abilityItem : Item =
                    (Dota2Item (Dota2AbilityItem dota2AbilityItem))

                let onDragStop event item =
                    onItemDroppedOutOfPane event abilityItem

                div [ ClassName styles.abilityContainer ] [
                    div [ ClassName styles.draggableContainer ] [
                        DraggableItem.draggableItem onDragStop abilityItem
                    ]
                    div [ ClassName styles.abilityLabel ] [
                        str (Dota2.Ability.getName ability)
                    ]
                ]

            let renderedAbilities = List.map renderAbility abilities
            div [] [
                Label.label [] [ str "Abilities" ]
                (ofList renderedAbilities)
            ]
        | Dota2Item (Dota2WardItem _)
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            nothing

    let renderBuildingStateOptions item =
        match item with
        | Dota2Item (Dota2BuildingItem building) ->
            match building.building with
            | Dota2.Building.Outpost side ->
                nothing
            | _ ->
                let onToggle state = onBuildingStateChange state
                let buildingState = building |> Dota2BuildingItem.getBuildingState
                Field.div [] [
                    BuildingStateToggle.toggle buildingState onToggle
                ]
        | Dota2Item (Dota2WardItem ward) ->
            let onToggle state = onBuildingStateChange state
            Field.div [] [
                BuildingStateToggle.toggle ward.state onToggle
            ]
        | Dota2Item (Dota2HeroItem _)
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            nothing

    let renderBuildingCapturedStateOptions item =
        match item with
        | Dota2Item (Dota2BuildingItem buildingItem) ->
            match buildingItem.building with
            | Dota2.Building.Outpost side ->
                let onToggle state = onBuildingStateChange state
                Field.div [] [
                    BuildingCapturedStateToggle.toggle buildingItem.state onToggle
                ]
            | _ ->
                nothing
        | Dota2Item (Dota2WardItem _)
        | Dota2Item (Dota2HeroItem _)
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            nothing

    let renderAttackRangeStateOptions item =
        match item with
        | Dota2Item (Dota2BuildingItem building) ->
            match building.building |> Dota2.Building.getAttack with
            | Some attack ->
                // Don't show the option if the building doesn't attack
                if Dota2.Distance.isNotZero attack.range then
                    let onToggle attackRangeState =
                        onAttackRangeStateChange attackRangeState
                    AttackRangeToggle.toggle
                        building.attackRangeCircleVisibility
                        onToggle
                else
                    nothing
            | None ->
                nothing
        | Dota2Item (Dota2HeroItem hero) ->
            let onToggle attackRangeState =
                onAttackRangeStateChange attackRangeState
            AttackRangeToggle.toggle hero.attackRangeCircleVisibility onToggle
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2WardItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            nothing

    let renderEditText item =
        let onColorChange (color : ColorResult) =
            onTextColorEdit color.hex
        let onFontSizeChange (event : FormEvent) =
            onTextFontSizeEdit (float event.Value)
        let onWidthChange (event : FormEvent) =
            onTextWidthChange (float event.Value)
        match item with
        | TextItem text ->
             fragment [] [
                 Field.div [] [
                     renderLabel "Color"
                     twitterPicker [ ColorProps.Color (U3.Case1 text.color)
                                     ColorProps.Triangle TriangleOptions.Hide
                                     ColorProps.OnChange onColorChange
                                     ColorProps.OnChangeComplete ignore ]
                 ]
                 Field.div [] [
                     renderLabel "Font Size"
                     Input.input
                         [ Input.OnChange onFontSizeChange
                           Input.Props
                               [ Helpers.valueOrDefault text.fontSize ]
                           Input.Type Input.Number ]
                 ]
                 Field.div [] [
                     renderLabel "Width"
                     Input.input
                         [ Input.OnChange onWidthChange
                           Input.Props
                               [ Helpers.valueOrDefault text.width ]
                           Input.Type Input.Number ]
                 ]
             ]
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2HeroItem _)
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2NeutralCampItem _)
        | Dota2Item (Dota2WardItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _ ->
            nothing

    let renderDeleteButton item =
        let onDeleteHandler _ =
            onDelete ()
        let deleteButton entityString =
             Field.div [] [
                Button.button [ Button.Color IsDanger
                                Button.OnClick onDeleteHandler ] [
                    str "Delete "
                    str entityString
                ]
             ]
        match item with
        | Dota2Item (Dota2HeroItem _) ->
             deleteButton "Hero"
        | Dota2Item (Dota2WardItem _) ->
             deleteButton "Ward"
        | Dota2Item (Dota2LaneCreepItem _) ->
             deleteButton "Lane Creep"
        | Dota2Item (Dota2AbilityItem _) ->
            deleteButton "Ability"
        | GenericItem _ ->
             deleteButton "Marker"
        | TextItem _ ->
             deleteButton "Text"
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2NeutralCampItem _) ->
            nothing

    div [ ClassName styles.container ] [
        renderAbilities item |> hideIfInViewerMode
        renderSideOptions item |> hideIfInViewerMode
        renderBuildingStateOptions item |> hideIfInViewerMode
        renderBuildingCapturedStateOptions item |> hideIfInViewerMode
        renderAttackRangeStateOptions item
        renderEditText item |> hideIfInViewerMode
        renderDeleteButton item |> hideIfInViewerMode
    ]