namespace StatBanana.Dto.Firestore.Subscriptions

open System

open Fable.Core.JsInterop

open StatBanana.Domain
open StatBanana.Domain
open StatBanana.Dto.Firestore
open StatBanana.Utils

/// Field definitions for Firestore Subscription DTOs.
[<RequireQualifiedAccess>]
module SubscriptionDto =

    let private convertFirestoreTimestampToDateTimeOffset timestamp =
        timestamp?seconds
        |> int64
        |> DateTimeOffset.FromUnixTimeSeconds

    module ExternalSubscriptionId =

        let fromDomain (externalId : ExternalSubscriptionId) : obj =
            match externalId with
            | ExternalSubscriptionId.Stripe (StripeSubscriptionId stripeId) ->
                createObj [
                    "Tag" ==> "Stripe"
                    "StripeSubscriptionId" ==> stripeId
                    "PaypalSubscriptionId" ==> null
                ]
            | ExternalSubscriptionId.PayPal (PayPalSubscriptionId paypalId) ->
                createObj [
                    "Tag" ==> "PayPal"
                    "StripeSubscriptionId" ==> null
                    "PaypalSubscriptionId" ==> paypalId
                ]
            | Trial ->
                createObj [
                    "Tag" ==> "Trial"
                    "StripeSubscriptionId" ==> null
                    "PaypalSubscriptionId" ==> null
                ]

        let toDomain (externalId : obj) : ExternalSubscriptionId =
            let tag = externalId |> JsonAdapter.getString "Tag"
            match tag with
            | "Stripe" ->
                externalId
                |> JsonAdapter.getString "StripeSubscriptionId"
                |> StripeSubscriptionId
                |> ExternalSubscriptionId.Stripe
            | "PayPal" ->
                externalId
                |> JsonAdapter.getString "PaypalSubscriptionId"
                |> PayPalSubscriptionId
                |> ExternalSubscriptionId.PayPal
            | "Trial" ->
                Trial
            | unexpectedTag ->
                sprintf "Unexpected ExternalSubscriptionId DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise

    module SubscriptionStatus =

        let fromDomain (status : SubscriptionStatus) : string =
            match status with
            | SubscriptionStatus.Active ->
                "active"
            | SubscriptionStatus.AwaitingConfirmation ->
                "awaiting_confirmation"
            | SubscriptionStatus.Cancelled ->
                "cancelled"
            | SubscriptionStatus.Expired ->
                "expired"
            | SubscriptionStatus.PastDue ->
                "past_due"
            | SubscriptionStatus.SwitchingPlan ->
                "switching_plan"
            | SubscriptionStatus.TrialPeriod ->
                "trial_period"

        let toDomain (status : string) : SubscriptionStatus =
            match status with
            | "active" ->
                SubscriptionStatus.Active
            | "awaiting_confirmation" ->
                SubscriptionStatus.AwaitingConfirmation
            | "cancelled" ->
                SubscriptionStatus.Cancelled
            | "expired" ->
                SubscriptionStatus.Expired
            | "past_due" ->
                SubscriptionStatus.PastDue
            | "switching_plan" ->
                SubscriptionStatus.SwitchingPlan
            | "trial_period" ->
                SubscriptionStatus.TrialPeriod
            | unexpectedStatus ->
                sprintf "Unexpected Subscription Status: %s" unexpectedStatus
                |> JsonAdapter.JsonParsingException
                |> raise

    module SubscriptionChange =

        let fromDomain (subscription : SubscriptionChange) : obj =
            let plan =
                subscription.plan
                |> ChangeableDto.Changeable.fromDomain PlanDto.Plan.fromDomain
            let renewAs =
                let renewAsDto renewAs =
                    match renewAs with
                    | Some plan ->
                        plan |> PlanDto.Plan.fromDomain
                    | None -> null
                subscription.renewAs
                |> ChangeableDto.Changeable.fromDomain renewAsDto
            let started =
                subscription.started
                |> ChangeableDto.Changeable.fromDomain id
            let expires =
                subscription.expires
                |> ChangeableDto.Changeable.fromDomain id
            let status =
                subscription.status
                |> ChangeableDto.Changeable.fromDomain SubscriptionStatus.fromDomain

            createObj [
                "plan" ==> plan
                "renewAs" ==> renewAs
                "started" ==> started
                "expires" ==> expires
                "externalId" ==>
                    (subscription.externalId |> ExternalSubscriptionId.fromDomain)
                "status" ==> status
            ]

        let deserialiseToDomain (data : obj) : SubscriptionChange =

            match data?plan, data?renewAs, data?started, data?expires, data?externalId, data?status with
            | Some plan, Some renewAs, Some started, Some expires, Some externalId, Some status ->
                let plan =
                    plan
                    |> ChangeableDto.Changeable.toDomain
                           JsonAdapter.getObj
                           PlanDto.Plan.toDomain
                let renewAs =
                    let dto renewAs =
                        renewAs
                        |> Option.bind (PlanDto.Plan.toDomain >> Some)
                    renewAs
                    |> ChangeableDto.Changeable.toDomain
                           JsonAdapter.getObjOption
                           dto
                let getTime time =
                    time
                    |> ChangeableDto.Changeable.toDomain
                            JsonAdapter.getObj
                            convertFirestoreTimestampToDateTimeOffset
                let status =
                    status
                    |> ChangeableDto.Changeable.toDomain
                            JsonAdapter.getString
                            SubscriptionStatus.toDomain

                { plan = plan
                  renewAs = renewAs
                  started = started |> getTime
                  expires = expires |> getTime
                  externalId = externalId
                  status = status }
            | _ ->
                "Required fields for deserialising embedded SubscriptionChange not found."
                |> Exception
                |> raise

        let toDomain (subscription : obj) : SubscriptionChange =
            let plan =
                subscription
                |> JsonAdapter.getObj "plan"
                |> ChangeableDto.Changeable.toDomain
                       JsonAdapter.getObj
                       PlanDto.Plan.toDomain
            let renewAs =
                let renewAsDto renewAs =
                    match renewAs with
                    | null ->
                        None
                    | _ ->
                        obj |> PlanDto.Plan.toDomain |> Some
                subscription
                |> JsonAdapter.getObj "renewAs"
                |> ChangeableDto.Changeable.toDomain
                       JsonAdapter.getObj
                       renewAsDto
            let time key =
                subscription
                |> JsonAdapter.getDateTimeOffset key
                |> ChangeableDto.Changeable.toDomain
                        JsonAdapter.getDateTimeOffset
                        id
            let externalId =
                subscription
                |> JsonAdapter.getObj "externalId"
                |> ExternalSubscriptionId.toDomain
            let status =
                subscription
                |> JsonAdapter.getString "status"
                |> ChangeableDto.Changeable.toDomain
                        JsonAdapter.getString
                        SubscriptionStatus.toDomain

            { plan = plan
              renewAs = renewAs
              started = time "started"
              expires = time "expires"
              externalId = externalId
              status = status }

    module Subscription =

        let fromDomain (subscription : Subscription) : obj =

            let renewAs =
                subscription.renewAs
                |> Option.bind (fun plan ->
                    plan |> PlanDto.Plan.fromDomain |> Some)

            createObj [
                "plan" ==> (subscription.plan |> PlanDto.Plan.fromDomain)
                "renewAs" ==> renewAs
                "started" ==> subscription.started
                "expires" ==> subscription.expires
                "externalId" ==> (subscription.externalId |> ExternalSubscriptionId.fromDomain)
                "status" ==> (subscription.status |> SubscriptionStatus.fromDomain)
            ]

        let deserialiseToDomain (data : obj) : Subscription =

            match data?plan, data?started, data?expires, data?externalId, data?status with
            | Some plan, Some started, Some expires, Some externalId, Some status ->
                let renewAs =
                    match data?renewAs with
                    | null ->
                        None
                    | plan ->
                        plan |> PlanDto.Plan.toDomain |> Some

                { plan =
                    plan
                    |> PlanDto.Plan.toDomain
                  renewAs = renewAs
                  started = started |> convertFirestoreTimestampToDateTimeOffset
                  expires = expires |> convertFirestoreTimestampToDateTimeOffset
                  externalId =
                    externalId
                    |> ExternalSubscriptionId.toDomain
                  status =
                    status
                    |> SubscriptionStatus.toDomain }
            | _ ->
                "Required fields for deserialising embedded Subscription not found."
                |> Exception
                |> raise

        let toDomain (subscription : obj) : Subscription =

            let renewAs =
                let obj = subscription |> JsonAdapter.getObj "renewAs"
                match obj with
                | null ->
                    None
                | _ ->
                    obj |> PlanDto.Plan.toDomain |> Some
            { plan = subscription |> JsonAdapter.getObj "plan" |> PlanDto.Plan.toDomain
              renewAs = renewAs
              started = subscription |> JsonAdapter.getDateTimeOffset "started"
              expires = subscription |> JsonAdapter.getDateTimeOffset "expires"
              externalId =
                subscription
                |> JsonAdapter.getObj "externalId"
                |> ExternalSubscriptionId.toDomain
              status =
                subscription
                |> JsonAdapter.getString "status"
                |> SubscriptionStatus.toDomain }