namespace StatBanana.Web.Client.Domain.Dota2

/// The possible heroes in Dota 2.
type Hero =
    | Abaddon
    | AbyssalUnderlord
    | Alchemist
    | AncientApparition
    | Antimage
    | ArcWarden
    | Axe
    | Bane
    | Batrider
    | Beastmaster
    | Bloodseeker
    | BountyHunter
    | Brewmaster
    | Bristleback
    | Broodmother
    | Centaur
    | ChaosKnight
    | Chen
    | Clinkz
    | CrystalMaiden
    | DarkSeer
    | DarkWillow
    | Dazzle
    | DeathProphet
    | Disruptor
    | DoomBringer
    | DragonKnight
    | DrowRanger
    | Earthshaker
    | EarthSpirit
    | ElderTitan
    | EmberSpirit
    | Enchantress
    | Enigma
    | FacelessVoid
    | Furion
    | Grimstroke
    | Gyrocopter
    | Huskar
    | Invoker
    | Jakiro
    | Juggernaut
    | KeeperOfTheLight
    | Kunkka
    | LegionCommander
    | Leshrac
    | Lich
    | LifeStealer
    | Lina
    | Lion
    | LoneDruid
    | Luna
    | Lycan
    | Magnataur
    | Mars
    | Medusa
    | Meepo
    | Mirana
    | MonkeyKing
    | Morphling
    | NagaSiren
    | Necrolyte
    | Nevermore
    | NightStalker
    | NyxAssassin
    | ObsidianDestroyer
    | OgreMagi
    | Omniknight
    | Oracle
    | Pangolier
    | PhantomAssassin
    | PhantomLancer
    | Phoenix
    | Puck
    | Pudge
    | Pugna
    | QueenOfPain
    | Rattletrap
    | Razor
    | Riki
    | Rubick
    | SandKing
    | ShadowDemon
    | ShadowShaman
    | Shredder
    | Silencer
    | SkeletonKing
    | SkywrathMage
    | Slardar
    | Slark
    | Sniper
    | Spectre
    | SpiritBreaker
    | StormSpirit
    | Sven
    | Techies
    | TemplarAssassin
    | Terrorblade
    | Tidehunter
    | Tinker
    | Tiny
    | Treant
    | TrollWarlord
    | Tusk
    | Undying
    | Ursa
    | VengefulSpirit
    | Venomancer
    | Viper
    | Visage
    | Warlock
    | Weaver
    | Windrunner
    | WinterWyvern
    | Wisp
    | WitchDoctor
    | Zuus

module Hero =
    /// A list of all of the heroes in Dota 2.
    let all : Hero list =
        [ Abaddon
          AbyssalUnderlord
          Alchemist
          AncientApparition
          Antimage
          ArcWarden
          Axe
          Bane
          Batrider
          Beastmaster
          Bloodseeker
          BountyHunter
          Brewmaster
          Bristleback
          Broodmother
          Centaur
          ChaosKnight
          Chen
          Clinkz
          CrystalMaiden
          DarkSeer
          DarkWillow
          Dazzle
          DeathProphet
          Disruptor
          DoomBringer
          DragonKnight
          DrowRanger
          Earthshaker
          EarthSpirit
          ElderTitan
          EmberSpirit
          Enchantress
          Enigma
          FacelessVoid
          Furion
          Grimstroke
          Gyrocopter
          Huskar
          Invoker
          Jakiro
          Juggernaut
          KeeperOfTheLight
          Kunkka
          LegionCommander
          Leshrac
          Lich
          LifeStealer
          Lina
          Lion
          LoneDruid
          Luna
          Lycan
          Magnataur
          Mars
          Medusa
          Meepo
          Mirana
          MonkeyKing
          Morphling
          NagaSiren
          Necrolyte
          Nevermore
          NightStalker
          NyxAssassin
          ObsidianDestroyer
          OgreMagi
          Omniknight
          Oracle
          Pangolier
          PhantomAssassin
          PhantomLancer
          Phoenix
          Puck
          Pudge
          Pugna
          QueenOfPain
          Rattletrap
          Razor
          Riki
          Rubick
          SandKing
          ShadowDemon
          ShadowShaman
          Shredder
          Silencer
          SkeletonKing
          SkywrathMage
          Slardar
          Slark
          Sniper
          Spectre
          SpiritBreaker
          StormSpirit
          Sven
          Techies
          TemplarAssassin
          Terrorblade
          Tidehunter
          Tinker
          Tiny
          Treant
          TrollWarlord
          Tusk
          Undying
          Ursa
          VengefulSpirit
          Venomancer
          Viper
          Visage
          Warlock
          Weaver
          Windrunner
          WinterWyvern
          Wisp
          WitchDoctor
          Zuus ]

    /// <summary>
    ///    Serialise a given Dota 2 hero to a string id value for persistance.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="hero">
    ///     The Dota 2 hero to serialise to a string id value.
    /// </param>
    ///
    /// <returns>
    ///     The serialised id value of the given Dota 2 hero.
    /// </returns>
    let toId (hero : Hero) : string =
        match hero with
        | Abaddon -> "abaddon"
        | AbyssalUnderlord -> "abyssal_underlord"
        | Alchemist -> "alchemist"
        | AncientApparition -> "ancient_apparition"
        | Antimage -> "antimage"
        | ArcWarden -> "arc_warden"
        | Axe -> "axe"
        | Bane -> "bane"
        | Batrider -> "batrider"
        | Beastmaster -> "beastmaster"
        | Bloodseeker -> "bloodseeker"
        | BountyHunter -> "bounty_hunter"
        | Brewmaster -> "brewmaster"
        | Bristleback -> "bristleback"
        | Broodmother -> "broodmother"
        | Centaur -> "centaur"
        | ChaosKnight -> "chaos_knight"
        | Chen -> "chen"
        | Clinkz -> "clinkz"
        | CrystalMaiden -> "crystal_maiden"
        | DarkSeer -> "dark_seer"
        | DarkWillow -> "dark_willow"
        | Dazzle -> "dazzle"
        | DeathProphet -> "death_prophet"
        | Disruptor -> "disruptor"
        | DoomBringer -> "doom_bringer"
        | DragonKnight -> "dragon_knight"
        | DrowRanger -> "drow_ranger"
        | Earthshaker -> "earthshaker"
        | EarthSpirit -> "earth_spirit"
        | ElderTitan -> "elder_titan"
        | EmberSpirit -> "ember_spirit"
        | Enchantress -> "enchantress"
        | Enigma -> "enigma"
        | FacelessVoid -> "faceless_void"
        | Furion -> "furion"
        | Grimstroke -> "grimstroke"
        | Gyrocopter -> "gyrocopter"
        | Huskar -> "huskar"
        | Invoker -> "invoker"
        | Jakiro -> "jakiro"
        | Juggernaut -> "juggernaut"
        | KeeperOfTheLight -> "keeper_of_the_light"
        | Kunkka -> "kunkka"
        | LegionCommander -> "legion_commander"
        | Leshrac -> "leshrac"
        | Lich -> "lich"
        | LifeStealer -> "life_stealer"
        | Lina -> "lina"
        | Lion -> "lion"
        | LoneDruid -> "lone_druid"
        | Luna -> "luna"
        | Lycan -> "lycan"
        | Magnataur -> "magnataur"
        | Mars -> "mars"
        | Medusa -> "medusa"
        | Meepo -> "meepo"
        | Mirana -> "mirana"
        | MonkeyKing -> "monkey_king"
        | Morphling -> "morphling"
        | NagaSiren -> "naga_siren"
        | Necrolyte -> "necrolyte"
        | Nevermore -> "nevermore"
        | NightStalker -> "night_stalker"
        | NyxAssassin -> "nyx_assassin"
        | ObsidianDestroyer -> "obsidian_destroyer"
        | OgreMagi -> "ogre_magi"
        | Omniknight -> "omniknight"
        | Oracle -> "oracle"
        | Pangolier -> "pangolier"
        | PhantomAssassin -> "phantom_assassin"
        | PhantomLancer -> "phantom_lancer"
        | Phoenix -> "phoenix"
        | Puck -> "puck"
        | Pudge -> "pudge"
        | Pugna -> "pugna"
        | QueenOfPain -> "queen_of_pain"
        | Rattletrap -> "rattletrap"
        | Razor -> "razor"
        | Riki -> "riki"
        | Rubick -> "rubick"
        | SandKing -> "sand_king"
        | ShadowDemon -> "shadow_demon"
        | ShadowShaman -> "shadow_shaman"
        | Shredder -> "shredder"
        | Silencer -> "silencer"
        | SkeletonKing -> "skeleton_king"
        | SkywrathMage -> "skywrath_mage"
        | Slardar -> "slardar"
        | Slark -> "slark"
        | Sniper -> "sniper"
        | Spectre -> "spectre"
        | SpiritBreaker -> "spirit_breaker"
        | StormSpirit -> "storm_spirit"
        | Sven -> "sven"
        | Techies -> "techies"
        | TemplarAssassin -> "templar_assassin"
        | Terrorblade -> "terrorblade"
        | Tidehunter -> "tidehunter"
        | Tinker -> "tinker"
        | Tiny -> "tiny"
        | Treant -> "treant"
        | TrollWarlord -> "troll_warlord"
        | Tusk -> "tusk"
        | Undying -> "undying"
        | Ursa -> "ursa"
        | VengefulSpirit -> "vengeful_spirit"
        | Venomancer -> "venomancer"
        | Viper -> "viper"
        | Visage -> "visage"
        | Warlock -> "warlock"
        | Weaver -> "weaver"
        | Windrunner -> "windrunner"
        | WinterWyvern -> "winter_wyvern"
        | Wisp -> "wisp"
        | WitchDoctor -> "witch_doctor"
        | Zuus -> "zuus"

    /// <summary>
    ///     Deserialise a Dota 2 hero that was serialised using <c>toId</c>.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="id">
    ///     The serialised Dota2 hero.
    /// </param>
    ///
    /// <returns>
    ///     Hero, or an error message if the hero could not be deserialised.
    /// </returns>
    let fromId (id : string) : Result<Hero, string> =
        match id with
        | "abaddon" -> Ok Hero.Abaddon
        | "abyssal_underlord" -> Ok Hero.AbyssalUnderlord
        | "alchemist" -> Ok Hero.Alchemist
        | "ancient_apparition" -> Ok Hero.AncientApparition
        | "antimage" -> Ok Hero.Antimage
        | "arc_warden" -> Ok Hero.ArcWarden
        | "axe" -> Ok Hero.Axe
        | "bane" -> Ok Hero.Bane
        | "batrider" -> Ok Hero.Batrider
        | "beastmaster" -> Ok Hero.Beastmaster
        | "bloodseeker" -> Ok Hero.Bloodseeker
        | "bounty_hunter" -> Ok Hero.BountyHunter
        | "brewmaster" -> Ok Hero.Brewmaster
        | "bristleback" -> Ok Hero.Bristleback
        | "broodmother" -> Ok Hero.Broodmother
        | "centaur" -> Ok Hero.Centaur
        | "chaos_knight" -> Ok Hero.ChaosKnight
        | "chen" -> Ok Hero.Chen
        | "clinkz" -> Ok Hero.Clinkz
        | "crystal_maiden" -> Ok Hero.CrystalMaiden
        | "dark_seer" -> Ok Hero.DarkSeer
        | "dark_willow" -> Ok Hero.DarkWillow
        | "dazzle" -> Ok Hero.Dazzle
        | "death_prophet" -> Ok Hero.DeathProphet
        | "disruptor" -> Ok Hero.Disruptor
        | "doom_bringer" -> Ok Hero.DoomBringer
        | "dragon_knight" -> Ok Hero.DragonKnight
        | "drow_ranger" -> Ok Hero.DrowRanger
        | "earthshaker" -> Ok Hero.Earthshaker
        | "earth_spirit" -> Ok Hero.EarthSpirit
        | "elder_titan" -> Ok Hero.ElderTitan
        | "ember_spirit" -> Ok Hero.EmberSpirit
        | "enchantress" -> Ok Hero.Enchantress
        | "enigma" -> Ok Hero.Enigma
        | "faceless_void" -> Ok Hero.FacelessVoid
        | "furion" -> Ok Hero.Furion
        | "grimstroke" -> Ok Hero.Grimstroke
        | "gyrocopter" -> Ok Hero.Gyrocopter
        | "huskar" -> Ok Hero.Huskar
        | "invoker" -> Ok Hero.Invoker
        | "jakiro" -> Ok Hero.Jakiro
        | "juggernaut" -> Ok Hero.Juggernaut
        | "keeper_of_the_light" -> Ok Hero.KeeperOfTheLight
        | "kunkka" -> Ok Hero.Kunkka
        | "legion_commander" -> Ok Hero.LegionCommander
        | "leshrac" -> Ok Hero.Leshrac
        | "lich" -> Ok Hero.Lich
        | "life_stealer" -> Ok Hero.LifeStealer
        | "lina" -> Ok Hero.Lina
        | "lion" -> Ok Hero.Lion
        | "lone_druid" -> Ok Hero.LoneDruid
        | "luna" -> Ok Hero.Luna
        | "lycan" -> Ok Hero.Lycan
        | "magnataur" -> Ok Hero.Magnataur
        | "mars" -> Ok Hero.Mars
        | "medusa" -> Ok Hero.Medusa
        | "meepo" -> Ok Hero.Meepo
        | "mirana" -> Ok Hero.Mirana
        | "monkey_king" -> Ok Hero.MonkeyKing
        | "morphling" -> Ok Hero.Morphling
        | "naga_siren" -> Ok Hero.NagaSiren
        | "necrolyte" -> Ok Hero.Necrolyte
        | "nevermore" -> Ok Hero.Nevermore
        | "night_stalker" -> Ok Hero.NightStalker
        | "nyx_assassin" -> Ok Hero.NyxAssassin
        | "obsidian_destroyer" -> Ok Hero.ObsidianDestroyer
        | "ogre_magi" -> Ok Hero.OgreMagi
        | "omniknight" -> Ok Hero.Omniknight
        | "oracle" -> Ok Hero.Oracle
        | "pangolier" -> Ok Hero.Pangolier
        | "phantom_assassin" -> Ok Hero.PhantomAssassin
        | "phantom_lancer" -> Ok Hero.PhantomLancer
        | "phoenix" -> Ok Hero.Phoenix
        | "puck" -> Ok Hero.Puck
        | "pudge" -> Ok Hero.Pudge
        | "pugna" -> Ok Hero.Pugna
        | "queen_of_pain" -> Ok Hero.QueenOfPain
        | "rattletrap" -> Ok Hero.Rattletrap
        | "razor" -> Ok Hero.Razor
        | "riki" -> Ok Hero.Riki
        | "rubick" -> Ok Hero.Rubick
        | "sand_king" -> Ok Hero.SandKing
        | "shadow_demon" -> Ok Hero.ShadowDemon
        | "shadow_shaman" -> Ok Hero.ShadowShaman
        | "shredder" -> Ok Hero.Shredder
        | "silencer" -> Ok Hero.Silencer
        | "skeleton_king" -> Ok Hero.SkeletonKing
        | "skywrath_mage" -> Ok Hero.SkywrathMage
        | "slardar" -> Ok Hero.Slardar
        | "slark" -> Ok Hero.Slark
        | "sniper" -> Ok Hero.Sniper
        | "spectre" -> Ok Hero.Spectre
        | "spirit_breaker" -> Ok Hero.SpiritBreaker
        | "storm_spirit" -> Ok Hero.StormSpirit
        | "sven" -> Ok Hero.Sven
        | "techies" -> Ok Hero.Techies
        | "templar_assassin" -> Ok Hero.TemplarAssassin
        | "terrorblade" -> Ok Hero.Terrorblade
        | "tidehunter" -> Ok Hero.Tidehunter
        | "tinker" -> Ok Hero.Tinker
        | "tiny" -> Ok Hero.Tiny
        | "treant" -> Ok Hero.Treant
        | "troll_warlord" -> Ok Hero.TrollWarlord
        | "tusk" -> Ok Hero.Tusk
        | "undying" -> Ok Hero.Undying
        | "ursa" -> Ok Hero.Ursa
        | "vengeful_spirit" -> Ok Hero.VengefulSpirit
        | "venomancer" -> Ok Hero.Venomancer
        | "viper" -> Ok Hero.Viper
        | "visage" -> Ok Hero.Visage
        | "warlock" -> Ok Hero.Warlock
        | "weaver" -> Ok Hero.Weaver
        | "windrunner" -> Ok Hero.Windrunner
        | "winter_wyvern" -> Ok Hero.WinterWyvern
        | "wisp" -> Ok Hero.Wisp
        | "witch_doctor" -> Ok Hero.WitchDoctor
        | "zuus" -> Ok Hero.Zuus
        | invalidHero ->
            sprintf "Invalid Dota 2 hero: %s" invalidHero
            |> Error

    /// <summary>
    ///    Get the name for a given Dota 2 hero.
    /// </summary>
    ///
    /// <param name="hero">
    ///     The Dota 2 hero to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 hero.
    /// </returns>
    let getName (hero : Hero) : string =
        match hero with
        | Abaddon -> "Abaddon"
        | AbyssalUnderlord -> "Underlord"
        | Alchemist -> "Alchemist"
        | AncientApparition -> "Ancient Apparition"
        | Antimage -> "Anti-Mage"
        | ArcWarden -> "Arc Warden"
        | Axe -> "Axe"
        | Bane -> "Bane"
        | Batrider -> "Batrider"
        | Beastmaster -> "Beastmaster"
        | Bloodseeker -> "Bloodseeker"
        | BountyHunter -> "Bounty Hunter"
        | Brewmaster -> "Brewmaster"
        | Bristleback -> "Bristleback"
        | Broodmother -> "Broodmother"
        | Centaur -> "Centaur"
        | ChaosKnight -> "Chaos Knight"
        | Chen -> "Chen"
        | Clinkz -> "Clinkz"
        | CrystalMaiden -> "Crystal Maiden"
        | DarkSeer -> "Dark Seer"
        | DarkWillow -> "Dark Willow"
        | Dazzle -> "Dazzle"
        | DeathProphet -> "Death Prophet"
        | Disruptor -> "Disruptor"
        | DoomBringer -> "Doom"
        | DragonKnight -> "Dragon Knight"
        | DrowRanger -> "Drow Ranger"
        | Earthshaker -> "Earthshaker"
        | EarthSpirit -> "EarthSpirit"
        | ElderTitan -> "Elder Titan"
        | EmberSpirit -> "Ember Spirit"
        | Enchantress -> "Enchantress"
        | Enigma -> "Enigma"
        | FacelessVoid -> "Faceless Void"
        | Furion -> "Nature's Prophet"
        | Grimstroke -> "Grimstroke"
        | Gyrocopter -> "Gyrocopter"
        | Huskar -> "Huskar"
        | Invoker -> "Invoker"
        | Jakiro -> "Jakiro"
        | Juggernaut -> "Juggernaut"
        | KeeperOfTheLight -> "Keeper of the Light"
        | Kunkka -> "Kunkka"
        | LegionCommander -> "Legion Commander"
        | Leshrac -> "Leshrac"
        | Lich -> "Lich"
        | LifeStealer -> "Life Stealer"
        | Lina -> "Lina"
        | Lion -> "Lion"
        | LoneDruid -> "Lone Druid"
        | Luna -> "Luna"
        | Lycan -> "Lycan"
        | Magnataur -> "Magnus"
        | Mars -> "Mars"
        | Medusa -> "Medusa"
        | Meepo -> "Meepo"
        | Mirana -> "Mirana"
        | MonkeyKing -> "Monkey King"
        | Morphling -> "Morphling"
        | NagaSiren -> "Naga Siren"
        | Necrolyte -> "Necrophos"
        | Nevermore -> "Nevermore"
        | NightStalker -> "Night Stalker"
        | NyxAssassin -> "Nyx Assassin"
        | ObsidianDestroyer -> "Outworld Devourer"
        | OgreMagi -> "Ogre Magi"
        | Omniknight -> "Omniknight"
        | Oracle -> "Oracle"
        | Pangolier -> "Pangolier"
        | PhantomAssassin -> "Phantom Assassin"
        | PhantomLancer -> "Phantom Lancer"
        | Phoenix -> "Phoenix"
        | Puck -> "Puck"
        | Pudge -> "Pudge"
        | Pugna -> "Pugna"
        | QueenOfPain -> "Queen of Pain"
        | Rattletrap -> "Rattletrap"
        | Razor -> "Razor"
        | Riki -> "Riki"
        | Rubick -> "Rubick"
        | SandKing -> "Sand King"
        | ShadowDemon -> "Shadow Demon"
        | ShadowShaman -> "Shadow Shaman"
        | Shredder -> "Timbersaw"
        | Silencer -> "Silencer"
        | SkeletonKing -> "Wraith King"
        | SkywrathMage -> "Skywrath Mage"
        | Slardar -> "Slardar"
        | Slark -> "Slark"
        | Sniper -> "Sniper"
        | Spectre -> "Spectre"
        | SpiritBreaker -> "Spirit Breaker"
        | StormSpirit -> "Storm Spirit"
        | Sven -> "Sven"
        | Techies -> "Techies"
        | TemplarAssassin -> "Templar Assassin"
        | Terrorblade -> "Terrorblade"
        | Tidehunter -> "Tidehunter"
        | Tinker -> "Tinker"
        | Tiny -> "Tiny"
        | Treant -> "Treant"
        | TrollWarlord -> "Troll Warlord"
        | Tusk -> "Tusk"
        | Undying -> "Undying"
        | Ursa -> "Ursa"
        | VengefulSpirit -> "Vengeful Spirit"
        | Venomancer -> "Venomancer"
        | Viper -> "Viper"
        | Visage -> "Visage"
        | Warlock -> "Warlock"
        | Weaver -> "Weaver"
        | Windrunner -> "Windranger"
        | WinterWyvern -> "Winter Wyvern"
        | Wisp -> "Wisp"
        | WitchDoctor -> "Witch Doctor"
        | Zuus -> "Zeus"

    /// <summary>
    ///    Get the Hero that belongs to a given Dota2 Ability
    /// </summary>
    ///
    /// <param name="ability">
    ///     The Dota 2 Ability to obtain the Hero for.
    /// </param>
    ///
    /// <returns>
    ///     A 'Hero option' that corresponds to a given Ability.
    ///     Some Abilities do not have Hero casters so the option is necessary
    /// </returns>
    let getHeroFromAbility (ability: Ability) : Hero option =
        match ability with
        | Ability.AbaddonAphoticShield -> Some Abaddon
        | Ability.AbaddonBorrowedTime -> Some Abaddon
        | Ability.AbaddonDeathCoil -> Some Abaddon
        | Ability.AbaddonFrostmourne -> Some Abaddon
        | Ability.AbyssalUnderlordAtrophyAura -> Some AbyssalUnderlord
        | Ability.AbyssalUnderlordCancelDarkRift -> Some AbyssalUnderlord
        | Ability.AbyssalUnderlordDarkRift -> Some AbyssalUnderlord
        | Ability.AbyssalUnderlordFirestorm -> Some AbyssalUnderlord
        | Ability.AbyssalUnderlordPitOfMalice -> Some AbyssalUnderlord
        | Ability.AlchemistAcidSpray -> Some Alchemist
        | Ability.AlchemistChemicalRage -> Some Alchemist
        | Ability.AlchemistGoblinsGreed -> Some Alchemist
        | Ability.AlchemistUnstableConcoction -> Some Alchemist
        | Ability.AlchemistUnstableConcoctionThrow -> Some Alchemist
        | Ability.AncientApparitionChillingTouch -> Some AncientApparition
        | Ability.AncientApparitionColdFeet -> Some AncientApparition
        | Ability.AncientApparitionIceBlast -> Some AncientApparition
        | Ability.AncientApparitionIceBlastRelease -> Some AncientApparition
        | Ability.AncientApparitionIceVortex -> Some AncientApparition
        | Ability.AntimageBlink -> Some Antimage
        | Ability.AntimageCounterspell -> Some Antimage
        | Ability.AntimageManaBreak -> Some Antimage
        | Ability.AntimageManaVoid -> Some Antimage
        | Ability.AntimageSpellShield -> Some Antimage
        | Ability.ArcWardenFlux -> Some ArcWarden
        | Ability.ArcWardenMagneticField -> Some ArcWarden
        | Ability.ArcWardenSparkWraith -> Some ArcWarden
        | Ability.ArcWardenTempestDouble -> Some ArcWarden
        | Ability.AxeBattleHunger -> Some Axe
        | Ability.AxeBerserkersCall -> Some Axe
        | Ability.AxeCounterHelix -> Some Axe
        | Ability.AxeCullingBlade -> Some Axe
        | Ability.BaneBrainSap -> Some Bane
        | Ability.BaneEnfeeble -> Some Bane
        | Ability.BaneFiendsGrip -> Some Bane
        | Ability.BaneNightmare -> Some Bane
        | Ability.BaneNightmareEnd -> Some Bane
        | Ability.BatriderFirefly -> Some Batrider
        | Ability.BatriderFlamebreak -> Some Batrider
        | Ability.BatriderFlamingLasso -> Some Batrider
        | Ability.BatriderStickyNapalm -> Some Batrider
        | Ability.BeastmasterBoarPoison -> Some Beastmaster
        | Ability.BeastmasterCallOfTheWild -> Some Beastmaster
        | Ability.BeastmasterCallOfTheWildBoar -> Some Beastmaster
        | Ability.BeastmasterCallOfTheWildHawk -> Some Beastmaster
        | Ability.BeastmasterGreaterBoarPoison -> Some Beastmaster
        | Ability.BeastmasterHawkInvisibility -> Some Beastmaster
        | Ability.BeastmasterInnerBeast -> Some Beastmaster
        | Ability.BeastmasterPrimalRoar -> Some Beastmaster
        | Ability.BeastmasterWildAxes -> Some Beastmaster
        | Ability.BloodseekerBloodBath -> Some Bloodseeker
        | Ability.BloodseekerBloodrage -> Some Bloodseeker
        | Ability.BloodseekerRupture -> Some Bloodseeker
        | Ability.BloodseekerThirst -> Some Bloodseeker
        | Ability.BountyHunterJinada -> Some BountyHunter
        | Ability.BountyHunterShurikenToss -> Some BountyHunter
        | Ability.BountyHunterTrack -> Some BountyHunter
        | Ability.BountyHunterWindWalk -> Some BountyHunter
        | Ability.BrewmasterCinderBrew -> Some Brewmaster
        | Ability.BrewmasterDrunkenBrawler -> Some Brewmaster
        | Ability.BrewmasterDrunkenHaze -> Some Brewmaster
        | Ability.BrewmasterEarthHurlBoulder -> Some Brewmaster
        | Ability.BrewmasterEarthPulverize -> Some Brewmaster
        | Ability.BrewmasterEarthSpellImmunity -> Some Brewmaster
        | Ability.BrewmasterFirePermanentImmolation -> Some Brewmaster
        | Ability.BrewmasterPrimalSplit -> Some Brewmaster
        | Ability.BrewmasterStormCyclone -> Some Brewmaster
        | Ability.BrewmasterStormDispelMagic -> Some Brewmaster
        | Ability.BrewmasterStormWindWalk -> Some Brewmaster
        | Ability.BrewmasterThunderClap -> Some Brewmaster
        | Ability.BristlebackBristleback -> Some Bristleback
        | Ability.BristlebackQuillSpray -> Some Bristleback
        | Ability.BristlebackViscousNasalGoo -> Some Bristleback
        | Ability.BristlebackWarpath -> Some Bristleback
        | Ability.BroodmotherIncapacitatingBite -> Some Broodmother
        | Ability.BroodmotherInsatiableHunger -> Some Broodmother
        | Ability.BroodmotherPoisonSting -> Some Broodmother
        | Ability.BroodmotherSpawnSpiderite -> Some Broodmother
        | Ability.BroodmotherSpawnSpiderlings -> Some Broodmother
        | Ability.BroodmotherSpinWeb -> Some Broodmother
        | Ability.BroodmotherSpinWebDestroy -> Some Broodmother
        | Ability.CentaurDoubleEdge -> Some Centaur
        | Ability.CentaurHoofStomp -> Some Centaur
        | Ability.CentaurReturn -> Some Centaur
        | Ability.CentaurStampede -> Some Centaur
        | Ability.ChaosKnightChaosBolt -> Some ChaosKnight
        | Ability.ChaosKnightChaosStrike -> Some ChaosKnight
        | Ability.ChaosKnightPhantasm -> Some ChaosKnight
        | Ability.ChaosKnightRealityRift -> Some ChaosKnight
        | Ability.ChenDivineFavor -> Some Chen
        | Ability.ChenHandOfGod -> Some Chen
        | Ability.ChenHolyPersuasion -> Some Chen
        | Ability.ChenPenitence -> Some Chen
        | Ability.ChenTestOfFaith -> Some Chen
        | Ability.ChenTestOfFaithTeleport -> Some Chen
        | Ability.ClinkzBurningArmy -> Some Clinkz
        | Ability.ClinkzDeathPact -> Some Clinkz
        | Ability.ClinkzSearingArrows -> Some Clinkz
        | Ability.ClinkzStrafe -> Some Clinkz
        | Ability.ClinkzWindWalk -> Some Clinkz
        | Ability.CrystalMaidenBrillianceAura -> Some CrystalMaiden
        | Ability.CrystalMaidenCrystalNova -> Some CrystalMaiden
        | Ability.CrystalMaidenFreezingField -> Some CrystalMaiden
        | Ability.CrystalMaidenFrostbite -> Some CrystalMaiden
        | Ability.DarkSeerIonShell -> Some DarkSeer
        | Ability.DarkSeerSurge -> Some DarkSeer
        | Ability.DarkSeerVacuum -> Some DarkSeer
        | Ability.DarkSeerWallOfReplica -> Some DarkSeer
        | Ability.DarkWillowBedlam -> Some DarkWillow
        | Ability.DarkWillowBrambleMaze -> Some DarkWillow
        | Ability.DarkWillowCursedCrown -> Some DarkWillow
        | Ability.DarkWillowShadowRealm -> Some DarkWillow
        | Ability.DarkWillowTerrorize -> Some DarkWillow
        | Ability.DazzleBadJuju -> Some Dazzle
        | Ability.DazzlePoisonTouch -> Some Dazzle
        | Ability.DazzleShadowWave -> Some Dazzle
        | Ability.DazzleShallowGrave -> Some Dazzle
        | Ability.DazzleWeave -> Some Dazzle
        | Ability.DeathProphetCarrionSwarm -> Some DeathProphet
        | Ability.DeathProphetExorcism -> Some DeathProphet
        | Ability.DeathProphetSilence -> Some DeathProphet
        | Ability.DeathProphetSpiritSiphon -> Some DeathProphet
        | Ability.DeathProphetWitchcraft -> Some DeathProphet
        | Ability.DisruptorGlimpse -> Some Disruptor
        | Ability.DisruptorKineticField -> Some Disruptor
        | Ability.DisruptorStaticStorm -> Some Disruptor
        | Ability.DisruptorThunderStrike -> Some Disruptor
        | Ability.DoomBringerDevour -> Some DoomBringer
        | Ability.DoomBringerDoom -> Some DoomBringer
        | Ability.DoomBringerEmpty -> Some DoomBringer
        | Ability.DoomBringerInfernalBlade -> Some DoomBringer
        | Ability.DoomBringerScorchedEarth -> Some DoomBringer
        | Ability.DragonKnightBreatheFire -> Some DragonKnight
        | Ability.DragonKnightDragonBlood -> Some DragonKnight
        | Ability.DragonKnightDragonTail -> Some DragonKnight
        | Ability.DragonKnightElderDragonForm -> Some DragonKnight
        | Ability.DrowRangerFrostArrows -> Some DrowRanger
        | Ability.DrowRangerMarksmanship -> Some DrowRanger
        | Ability.DrowRangerSilence -> Some DrowRanger
        | Ability.DrowRangerTrueshot -> Some DrowRanger
        | Ability.DrowRangerWaveOfSilence -> Some DrowRanger
        | Ability.EarthshakerAftershock -> Some Earthshaker
        | Ability.EarthshakerEchoSlam -> Some Earthshaker
        | Ability.EarthshakerEnchantTotem -> Some Earthshaker
        | Ability.EarthshakerFissure -> Some Earthshaker
        | Ability.EarthSpiritBoulderSmash -> Some EarthSpirit
        | Ability.EarthSpiritGeomagneticGrip -> Some EarthSpirit
        | Ability.EarthSpiritMagnetize -> Some EarthSpirit
        | Ability.EarthSpiritPetrify -> Some EarthSpirit
        | Ability.EarthSpiritRollingBoulder -> Some EarthSpirit
        | Ability.EarthSpiritStoneCaller -> Some EarthSpirit
        | Ability.ElderTitanAncestralSpirit -> Some ElderTitan
        | Ability.ElderTitanEarthSplitter -> Some ElderTitan
        | Ability.ElderTitanEchoStomp -> Some ElderTitan
        | Ability.ElderTitanEchoStompSpirit -> Some ElderTitan
        | Ability.ElderTitanNaturalOrder -> Some ElderTitan
        | Ability.ElderTitanNaturalOrderSpirit -> Some ElderTitan
        | Ability.ElderTitanReturnSpirit -> Some ElderTitan
        | Ability.EmberSpiritActivateFireRemnant -> Some EmberSpirit
        | Ability.EmberSpiritFireRemnant -> Some EmberSpirit
        | Ability.EmberSpiritFlameGuard -> Some EmberSpirit
        | Ability.EmberSpiritSearingChains -> Some EmberSpirit
        | Ability.EmberSpiritSleightOfFist -> Some EmberSpirit
        | Ability.EnchantressEnchant -> Some Enchantress
        | Ability.EnchantressImpetus -> Some Enchantress
        | Ability.EnchantressNaturesAttendants -> Some Enchantress
        | Ability.EnchantressUntouchable -> Some Enchantress
        | Ability.EnigmaBlackHole -> Some Enigma
        | Ability.EnigmaDemonicConversion -> Some Enigma
        | Ability.EnigmaMalefice -> Some Enigma
        | Ability.EnigmaMidnightPulse -> Some Enigma
        | Ability.FacelessVoidBacktrack -> Some FacelessVoid
        | Ability.FacelessVoidChronosphere -> Some FacelessVoid
        | Ability.FacelessVoidTimeDilation -> Some FacelessVoid
        | Ability.FacelessVoidTimeLock -> Some FacelessVoid
        | Ability.FacelessVoidTimeWalk -> Some FacelessVoid
        | Ability.FurionForceOfNature -> Some Furion
        | Ability.FurionSprout -> Some Furion
        | Ability.FurionTeleportation -> Some Furion
        | Ability.FurionWrathOfNature -> Some Furion
        | Ability.GrimstrokeDarkArtistry -> Some Grimstroke
        | Ability.GrimstrokeInkCreature -> Some Grimstroke
        | Ability.GrimstrokeSoulChain -> Some Grimstroke
        | Ability.GrimstrokeSpiritWalk -> Some Grimstroke
        | Ability.GyrocopterCallDown -> Some Gyrocopter
        | Ability.GyrocopterFlakCannon -> Some Gyrocopter
        | Ability.GyrocopterHomingMissile -> Some Gyrocopter
        | Ability.GyrocopterRocketBarrage -> Some Gyrocopter
        | Ability.HuskarBerserkersBlood -> Some Huskar
        | Ability.HuskarBurningSpear -> Some Huskar
        | Ability.HuskarInnerFire -> Some Huskar
        | Ability.HuskarInnerVitality -> Some Huskar
        | Ability.HuskarLifeBreak -> Some Huskar
        | Ability.InvokerAlacrity -> Some Invoker
        | Ability.InvokerChaosMeteor -> Some Invoker
        | Ability.InvokerColdSnap -> Some Invoker
        | Ability.InvokerDeafeningBlast -> Some Invoker
        | Ability.InvokerEmp -> Some Invoker
        | Ability.InvokerEmpty -> Some Invoker
        | Ability.InvokerExort -> Some Invoker
        | Ability.InvokerForgeSpirit -> Some Invoker
        | Ability.InvokerGhostWalk -> Some Invoker
        | Ability.InvokerIceWall -> Some Invoker
        | Ability.InvokerInvoke -> Some Invoker
        | Ability.InvokerQuas -> Some Invoker
        | Ability.InvokerSunStrike -> Some Invoker
        | Ability.InvokerTornado -> Some Invoker
        | Ability.InvokerWex -> Some Invoker
        | Ability.JakiroDualBreath -> Some Jakiro
        | Ability.JakiroIcePath -> Some Jakiro
        | Ability.JakiroLiquidFire -> Some Jakiro
        | Ability.JakiroMacropyre -> Some Jakiro
        | Ability.JuggernautBladeDance -> Some Juggernaut
        | Ability.JuggernautBladeFury -> Some Juggernaut
        | Ability.JuggernautHealingWard -> Some Juggernaut
        | Ability.JuggernautOmniSlash -> Some Juggernaut
        | Ability.KeeperOfTheLightBlindingLight -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightChakraMagic -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightEmpty -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightIlluminate -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightIlluminateEnd -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightManaLeak -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightRecall -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightSpiritForm -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightSpiritFormIlluminate -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightSpiritFormIlluminateEnd -> Some KeeperOfTheLight
        | Ability.KeeperOfTheLightWillOWisp -> Some KeeperOfTheLight
        | Ability.KunkkaGhostship -> Some Kunkka
        | Ability.KunkkaReturn -> Some Kunkka
        | Ability.KunkkaTidebringer -> Some Kunkka
        | Ability.KunkkaTorrent -> Some Kunkka
        | Ability.KunkkaXMarksTheSpot -> Some Kunkka
        | Ability.LegionCommanderDuel -> Some LegionCommander
        | Ability.LegionCommanderMomentOfCourage -> Some LegionCommander
        | Ability.LegionCommanderOverwhelmingOdds -> Some LegionCommander
        | Ability.LegionCommanderPressTheAttack -> Some LegionCommander
        | Ability.LeshracDiabolicEdict -> Some Leshrac
        | Ability.LeshracLightningStorm -> Some Leshrac
        | Ability.LeshracPulseNova -> Some Leshrac
        | Ability.LeshracSplitEarth -> Some Leshrac
        | Ability.LichChainFrost -> Some Lich
        | Ability.LichDarkSorcery -> Some Lich
        | Ability.LichFrostArmor -> Some Lich
        | Ability.LichFrostAura -> Some Lich
        | Ability.LichFrostNova -> Some Lich
        | Ability.LichFrostShield -> Some Lich
        | Ability.LichSinisterGaze -> Some Lich
        | Ability.LifeStealerAssimilate -> Some LifeStealer
        | Ability.LifeStealerAssimilateEject -> Some LifeStealer
        | Ability.LifeStealerConsume -> Some LifeStealer
        | Ability.LifeStealerControl -> Some LifeStealer
        | Ability.LifeStealerEmpty -> Some LifeStealer
        | Ability.LifeStealerFeast -> Some LifeStealer
        | Ability.LifeStealerInfest -> Some LifeStealer
        | Ability.LifeStealerOpenWounds -> Some LifeStealer
        | Ability.LifeStealerRage -> Some LifeStealer
        | Ability.LinaDragonSlave -> Some Lina
        | Ability.LinaFierySoul -> Some Lina
        | Ability.LinaLagunaBlade -> Some Lina
        | Ability.LinaLightStrikeArray -> Some Lina
        | Ability.LionFingerOfDeath -> Some Lion
        | Ability.LionImpale -> Some Lion
        | Ability.LionManaDrain -> Some Lion
        | Ability.LionVoodoo -> Some Lion
        | Ability.LoneDruidRabid -> Some LoneDruid
        | Ability.LoneDruidSavageRoar -> Some LoneDruid
        | Ability.LoneDruidSavageRoarBear -> Some LoneDruid
        | Ability.LoneDruidSpiritBear -> Some LoneDruid
        | Ability.LoneDruidSpiritBearDefender -> Some LoneDruid
        | Ability.LoneDruidSpiritBearDemolish -> Some LoneDruid
        | Ability.LoneDruidSpiritBearEntangle -> Some LoneDruid
        | Ability.LoneDruidSpiritBearReturn -> Some LoneDruid
        | Ability.LoneDruidSpiritLink -> Some LoneDruid
        | Ability.LoneDruidTrueForm -> Some LoneDruid
        | Ability.LoneDruidTrueFormBattleCry -> Some LoneDruid
        | Ability.LoneDruidTrueFormDruid -> Some LoneDruid
        | Ability.LunaEclipse -> Some Luna
        | Ability.LunaLucentBeam -> Some Luna
        | Ability.LunaLunarBlessing -> Some Luna
        | Ability.LunaMoonGlaive -> Some Luna
        | Ability.LycanFeralImpulse -> Some Lycan
        | Ability.LycanHowl -> Some Lycan
        | Ability.LycanShapeshift -> Some Lycan
        | Ability.LycanSummonWolves -> Some Lycan
        | Ability.LycanSummonWolvesCriticalStrike -> Some Lycan
        | Ability.LycanSummonWolvesInvisibility -> Some Lycan
        | Ability.MagnataurEmpower -> Some Magnataur
        | Ability.MagnataurReversePolarity -> Some Magnataur
        | Ability.MagnataurShockwave -> Some Magnataur
        | Ability.MagnataurSkewer -> Some Magnataur
        | Ability.MarsArenaOfBlood -> Some Mars
        | Ability.MarsBulwark -> Some Mars
        | Ability.MarsGodsRebuke -> Some Mars
        | Ability.MarsSpear -> Some Mars
        | Ability.MedusaManaShield -> Some Medusa
        | Ability.MedusaMysticSnake -> Some Medusa
        | Ability.MedusaSplitShot -> Some Medusa
        | Ability.MedusaStoneGaze -> Some Medusa
        | Ability.MeepoDividedWeStand -> Some Meepo
        | Ability.MeepoEarthbind -> Some Meepo
        | Ability.MeepoGeostrike -> Some Meepo
        | Ability.MeepoPoof -> Some Meepo
        | Ability.MeepoRansack -> Some Meepo
        | Ability.MiranaArrow -> Some Mirana
        | Ability.MiranaInvis -> Some Mirana
        | Ability.MiranaLeap -> Some Mirana
        | Ability.MiranaStarfall -> Some Mirana
        | Ability.MonkeyKingBoundlessStrike -> Some MonkeyKing
        | Ability.MonkeyKingJinguMastery -> Some MonkeyKing
        | Ability.MonkeyKingMischief -> Some MonkeyKing
        | Ability.MonkeyKingPrimalSpring -> Some MonkeyKing
        | Ability.MonkeyKingPrimalSpringEarly -> Some MonkeyKing
        | Ability.MonkeyKingTreeDance -> Some MonkeyKing
        | Ability.MonkeyKingUntransform -> Some MonkeyKing
        | Ability.MonkeyKingWukongsCommand -> Some MonkeyKing
        | Ability.MorphlingAdaptiveStrikeAgi -> Some Morphling
        | Ability.MorphlingAdaptiveStrikeStr -> Some Morphling
        | Ability.MorphlingHybrid -> Some Morphling
        | Ability.MorphlingMorph -> Some Morphling
        | Ability.MorphlingMorphAgi -> Some Morphling
        | Ability.MorphlingMorphReplicate -> Some Morphling
        | Ability.MorphlingMorphStr -> Some Morphling
        | Ability.MorphlingReplicate -> Some Morphling
        | Ability.MorphlingWaveform -> Some Morphling
        | Ability.NagaSirenEnsnare -> Some NagaSiren
        | Ability.NagaSirenMirrorImage -> Some NagaSiren
        | Ability.NagaSirenRipTide -> Some NagaSiren
        | Ability.NagaSirenSongOfTheSiren -> Some NagaSiren
        | Ability.NagaSirenSongOfTheSirenCancel -> Some NagaSiren
        | Ability.NecrolyteDeathPulse -> Some Necrolyte
        | Ability.NecrolyteHeartstopperAura -> Some Necrolyte
        | Ability.NecrolyteReapersScythe -> Some Necrolyte
        | Ability.NecrolyteSadist -> Some Necrolyte
        | Ability.NecrolyteSadistStop -> Some Necrolyte
        | Ability.NevermoreDarkLord -> Some Nevermore
        | Ability.NevermoreNecromastery -> Some Nevermore
        | Ability.NevermoreRequiem -> Some Nevermore
        | Ability.NevermoreShadowraze -> Some Nevermore
        | Ability.NightStalkerCripplingFear -> Some NightStalker
        | Ability.NightStalkerDarkness -> Some NightStalker
        | Ability.NightStalkerHunterInTheNight -> Some NightStalker
        | Ability.NightStalkerVoid -> Some NightStalker
        | Ability.NyxAssassinBurrow -> Some NyxAssassin
        | Ability.NyxAssassinImpale -> Some NyxAssassin
        | Ability.NyxAssassinManaBurn -> Some NyxAssassin
        | Ability.NyxAssassinSpikedCarapace -> Some NyxAssassin
        | Ability.NyxAssassinUnburrow -> Some NyxAssassin
        | Ability.NyxAssassinVendetta -> Some NyxAssassin
        | Ability.ObsidianDestroyerArcaneOrb -> Some ObsidianDestroyer
        | Ability.ObsidianDestroyerAstralImprisonment -> Some ObsidianDestroyer
        | Ability.ObsidianDestroyerEquilibrium -> Some ObsidianDestroyer
        | Ability.ObsidianDestroyerEssenceAura -> Some ObsidianDestroyer
        | Ability.ObsidianDestroyerSanityEclipse -> Some ObsidianDestroyer
        | Ability.OgreMagiBloodlust -> Some OgreMagi
        | Ability.OgreMagiFireblast -> Some OgreMagi
        | Ability.OgreMagiFrostArmor -> Some OgreMagi
        | Ability.OgreMagiIgnite -> Some OgreMagi
        | Ability.OgreMagiMulticast -> Some OgreMagi
        | Ability.OgreMagiUnrefinedFireblast -> Some OgreMagi
        | Ability.OmniknightDegenAura -> Some Omniknight
        | Ability.OmniknightGuardianAngel -> Some Omniknight
        | Ability.OmniknightPacify -> Some Omniknight
        | Ability.OmniknightPurification -> Some Omniknight
        | Ability.OmniknightRepel -> Some Omniknight
        | Ability.OracleFalsePromise -> Some Oracle
        | Ability.OracleFatesEdict -> Some Oracle
        | Ability.OracleFortunesEnd -> Some Oracle
        | Ability.OraclePurifyingFlames -> Some Oracle
        | Ability.PangolierGyroshell -> Some Pangolier
        | Ability.PangolierGyroshellStop -> Some Pangolier
        | Ability.PangolierHeartpiercer -> Some Pangolier
        | Ability.PangolierLuckyShot -> Some Pangolier
        | Ability.PangolierShieldCrash -> Some Pangolier
        | Ability.PangolierSwashbuckle -> Some Pangolier
        | Ability.PhantomAssassinBlur -> Some PhantomAssassin
        | Ability.PhantomAssassinCoupDeGrace -> Some PhantomAssassin
        | Ability.PhantomAssassinPhantomStrike -> Some PhantomAssassin
        | Ability.PhantomAssassinStiflingDagger -> Some PhantomAssassin
        | Ability.PhantomLancerDoppelwalk -> Some PhantomLancer
        | Ability.PhantomLancerJuxtapose -> Some PhantomLancer
        | Ability.PhantomLancerPhantomEdge -> Some PhantomLancer
        | Ability.PhantomLancerSpiritLance -> Some PhantomLancer
        | Ability.PhoenixFireSpirits -> Some Phoenix
        | Ability.PhoenixIcarusDive -> Some Phoenix
        | Ability.PhoenixIcarusDiveStop -> Some Phoenix
        | Ability.PhoenixLaunchFireSpirit -> Some Phoenix
        | Ability.PhoenixSunRay -> Some Phoenix
        | Ability.PhoenixSunRayStop -> Some Phoenix
        | Ability.PhoenixSunRayToggleMove -> Some Phoenix
        | Ability.PhoenixSupernova -> Some Phoenix
        | Ability.PuckDreamCoil -> Some Puck
        | Ability.PuckEtherealJaunt -> Some Puck
        | Ability.PuckIllusoryOrb -> Some Puck
        | Ability.PuckPhaseShift -> Some Puck
        | Ability.PuckWaningRift -> Some Puck
        | Ability.PudgeDismember -> Some Pudge
        | Ability.PudgeFleshHeap -> Some Pudge
        | Ability.PudgeMeatHook -> Some Pudge
        | Ability.PudgeRot -> Some Pudge
        | Ability.PugnaDecrepify -> Some Pugna
        | Ability.PugnaLifeDrain -> Some Pugna
        | Ability.PugnaNetherBlast -> Some Pugna
        | Ability.PugnaNetherWard -> Some Pugna
        | Ability.QueenofpainBlink -> Some QueenOfPain
        | Ability.QueenofpainScreamOfPain -> Some QueenOfPain
        | Ability.QueenofpainShadowStrike -> Some QueenOfPain
        | Ability.QueenofpainSonicWave -> Some QueenOfPain
        | Ability.RattletrapBatteryAssault -> Some Rattletrap
        | Ability.RattletrapHookshot -> Some Rattletrap
        | Ability.RattletrapPowerCogs -> Some Rattletrap
        | Ability.RattletrapRocketFlare -> Some Rattletrap
        | Ability.RazorEyeOfTheStorm -> Some Razor
        | Ability.RazorPlasmaField -> Some Razor
        | Ability.RazorStaticLink -> Some Razor
        | Ability.RazorUnstableCurrent -> Some Razor
        | Ability.RikiBlinkStrike -> Some Riki
        | Ability.RikiPermanentInvisibility -> Some Riki
        | Ability.RikiSmokeScreen -> Some Riki
        | Ability.RikiTricksOfTheTrade -> Some Riki
        | Ability.RubickArcaneSupremacy -> Some Rubick
        | Ability.RubickEmpty -> Some Rubick
        | Ability.RubickFadeBolt -> Some Rubick
        | Ability.RubickHidden -> Some Rubick
        | Ability.RubickNullField -> Some Rubick
        | Ability.RubickSpellSteal -> Some Rubick
        | Ability.RubickTelekinesis -> Some Rubick
        | Ability.RubickTelekinesisLand -> Some Rubick
        | Ability.SandkingBurrowstrike -> Some SandKing
        | Ability.SandkingCausticFinale -> Some SandKing
        | Ability.SandkingEpicenter -> Some SandKing
        | Ability.SandkingSandStorm -> Some SandKing
        | Ability.ShadowDemonDemonicPurge -> Some ShadowDemon
        | Ability.ShadowDemonDisruption -> Some ShadowDemon
        | Ability.ShadowDemonShadowPoison -> Some ShadowDemon
        | Ability.ShadowDemonShadowPoisonRelease -> Some ShadowDemon
        | Ability.ShadowDemonSoulCatcher -> Some ShadowDemon
        | Ability.ShadowShamanEtherShock -> Some ShadowShaman
        | Ability.ShadowShamanMassSerpentWard -> Some ShadowShaman
        | Ability.ShadowShamanShackles -> Some ShadowShaman
        | Ability.ShadowShamanVoodoo -> Some ShadowShaman
        | Ability.ShredderChakram -> Some Shredder
        | Ability.ShredderReactiveArmor -> Some Shredder
        | Ability.ShredderReturnChakram -> Some Shredder
        | Ability.ShredderTimberChain -> Some Shredder
        | Ability.ShredderWhirlingDeath -> Some Shredder
        | Ability.SilencerCurseOfTheSilent -> Some Silencer
        | Ability.SilencerGlaivesOfWisdom -> Some Silencer
        | Ability.SilencerGlobalSilence -> Some Silencer
        | Ability.SilencerLastWord -> Some Silencer
        | Ability.SkeletonKingHellfireBlast -> Some SkeletonKing
        | Ability.SkeletonKingMortalStrike -> Some SkeletonKing
        | Ability.SkeletonKingReincarnation -> Some SkeletonKing
        | Ability.SkeletonKingVampiricAura -> Some SkeletonKing
        | Ability.SkywrathMageAncientSeal -> Some SkywrathMage
        | Ability.SkywrathMageArcaneBolt -> Some SkywrathMage
        | Ability.SkywrathMageConcussiveShot -> Some SkywrathMage
        | Ability.SkywrathMageMysticFlare -> Some SkywrathMage
        | Ability.SlardarAmplifyDamage -> Some Slardar
        | Ability.SlardarBash -> Some Slardar
        | Ability.SlardarSlithereenCrush -> Some Slardar
        | Ability.SlardarSprint -> Some Slardar
        | Ability.SlarkDarkPact -> Some Slark
        | Ability.SlarkEssenceShift -> Some Slark
        | Ability.SlarkPounce -> Some Slark
        | Ability.SlarkShadowDance -> Some Slark
        | Ability.SniperAssassinate -> Some Sniper
        | Ability.SniperHeadshot -> Some Sniper
        | Ability.SniperShrapnel -> Some Sniper
        | Ability.SniperTakeAim -> Some Sniper
        | Ability.SpectreDesolate -> Some Spectre
        | Ability.SpectreDispersion -> Some Spectre
        | Ability.SpectreHaunt -> Some Spectre
        | Ability.SpectreReality -> Some Spectre
        | Ability.SpectreSpectralDagger -> Some Spectre
        | Ability.SpiritBreakerBulldoze -> Some SpiritBreaker
        | Ability.SpiritBreakerChargeOfDarkness -> Some SpiritBreaker
        | Ability.SpiritBreakerEmpoweringHaste -> Some SpiritBreaker
        | Ability.SpiritBreakerGreaterBash -> Some SpiritBreaker
        | Ability.SpiritBreakerNetherStrike -> Some SpiritBreaker
        | Ability.StormSpiritBallLightning -> Some StormSpirit
        | Ability.StormSpiritElectricVortex -> Some StormSpirit
        | Ability.StormSpiritOverload -> Some StormSpirit
        | Ability.StormSpiritStaticRemnant -> Some StormSpirit
        | Ability.SvenGodsStrength -> Some Sven
        | Ability.SvenGreatCleave -> Some Sven
        | Ability.SvenStormBolt -> Some Sven
        | Ability.SvenWarcry -> Some Sven
        | Ability.TechiesFocusedDetonate -> Some Techies
        | Ability.TechiesLandMines -> Some Techies
        | Ability.TechiesMinefieldSign -> Some Techies
        | Ability.TechiesRemoteMines -> Some Techies
        | Ability.TechiesRemoteMinesSelfDetonate -> Some Techies
        | Ability.TechiesStasisTrap -> Some Techies
        | Ability.TechiesSuicide -> Some Techies
        | Ability.TemplarAssassinMeld -> Some TemplarAssassin
        | Ability.TemplarAssassinPsiBlades -> Some TemplarAssassin
        | Ability.TemplarAssassinPsionicTrap -> Some TemplarAssassin
        | Ability.TemplarAssassinRefraction -> Some TemplarAssassin
        | Ability.TemplarAssassinSelfTrap -> Some TemplarAssassin
        | Ability.TemplarAssassinTrap -> Some TemplarAssassin
        | Ability.TerrorbladeConjureImage -> Some Terrorblade
        | Ability.TerrorbladeMetamorphosis -> Some Terrorblade
        | Ability.TerrorbladeReflection -> Some Terrorblade
        | Ability.TerrorbladeSunder -> Some Terrorblade
        | Ability.TidehunterAnchorSmash -> Some Tidehunter
        | Ability.TidehunterGush -> Some Tidehunter
        | Ability.TidehunterKrakenShell -> Some Tidehunter
        | Ability.TidehunterRavage -> Some Tidehunter
        | Ability.TinkerHeatSeekingMissile -> Some Tinker
        | Ability.TinkerLaser -> Some Tinker
        | Ability.TinkerMarchOfTheMachines -> Some Tinker
        | Ability.TinkerRearm -> Some Tinker
        | Ability.TinyAvalanche -> Some Tiny
        | Ability.TinyCraggyExterior -> Some Tiny
        | Ability.TinyGrow -> Some Tiny
        | Ability.TinyToss -> Some Tiny
        | Ability.TinyTossTree -> Some Tiny
        | Ability.TreantEyesInTheForest -> Some Treant
        | Ability.TreantLeechSeed -> Some Treant
        | Ability.TreantLivingArmor -> Some Treant
        | Ability.TreantNaturesGuise -> Some Treant
        | Ability.TreantOvergrowth -> Some Treant
        | Ability.TrollWarlordBattleTrance -> Some TrollWarlord
        | Ability.TrollWarlordBerserkersRage -> Some TrollWarlord
        | Ability.TrollWarlordFervor -> Some TrollWarlord
        | Ability.TrollWarlordWhirlingAxesMelee -> Some TrollWarlord
        | Ability.TrollWarlordWhirlingAxesRanged -> Some TrollWarlord
        | Ability.TuskFrozenSigil -> Some Tusk
        | Ability.TuskIceShards -> Some Tusk
        | Ability.TuskIceShardsStop -> Some Tusk
        | Ability.TuskLaunchSnowball -> Some Tusk
        | Ability.TuskSnowball -> Some Tusk
        | Ability.TuskTagTeam -> Some Tusk
        | Ability.TuskWalrusKick -> Some Tusk
        | Ability.TuskWalrusPunch -> Some Tusk
        | Ability.UndyingDecay -> Some Undying
        | Ability.UndyingFleshGolem -> Some Undying
        | Ability.UndyingSoulRip -> Some Undying
        | Ability.UndyingTombstone -> Some Undying
        | Ability.UndyingTombstoneZombieAura -> Some Undying
        | Ability.UndyingTombstoneZombieDeathstrike -> Some Undying
        | Ability.UrsaEarthshock -> Some Ursa
        | Ability.UrsaEnrage -> Some Ursa
        | Ability.UrsaFurySwipes -> Some Ursa
        | Ability.UrsaOverpower -> Some Ursa
        | Ability.VengefulspiritCommandAura -> Some VengefulSpirit
        | Ability.VengefulspiritMagicMissile -> Some VengefulSpirit
        | Ability.VengefulspiritNetherSwap -> Some VengefulSpirit
        | Ability.VengefulspiritWaveOfTerror -> Some VengefulSpirit
        | Ability.VenomancerPlagueWard -> Some Venomancer
        | Ability.VenomancerPoisonNova -> Some Venomancer
        | Ability.VenomancerPoisonSting -> Some Venomancer
        | Ability.VenomancerVenomousGale -> Some Venomancer
        | Ability.ViperCorrosiveSkin -> Some Viper
        | Ability.ViperNethertoxin -> Some Viper
        | Ability.ViperPoisonAttack -> Some Viper
        | Ability.ViperViperStrike -> Some Viper
        | Ability.VisageGraveChill -> Some Visage
        | Ability.VisageGravekeepersCloak -> Some Visage
        | Ability.VisageSoulAssumption -> Some Visage
        | Ability.VisageStoneFormSelfCast -> Some Visage
        | Ability.VisageSummonFamiliars -> Some Visage
        | Ability.VisageSummonFamiliarsStoneForm -> Some Visage
        | Ability.WarlockFatalBonds -> Some Warlock
        | Ability.WarlockGolemFlamingFists -> Some Warlock
        | Ability.WarlockGolemPermanentImmolation -> Some Warlock
        | Ability.WarlockRainOfChaos -> Some Warlock
        | Ability.WarlockShadowWord -> Some Warlock
        | Ability.WarlockUpheaval -> Some Warlock
        | Ability.WeaverGeminateAttack -> Some Weaver
        | Ability.WeaverShukuchi -> Some Weaver
        | Ability.WeaverTheSwarm -> Some Weaver
        | Ability.WeaverTimeLapse -> Some Weaver
        | Ability.WindrunnerFocusfire -> Some Windrunner
        | Ability.WindrunnerPowershot -> Some Windrunner
        | Ability.WindrunnerShackleshot -> Some Windrunner
        | Ability.WindrunnerWindrun -> Some Windrunner
        | Ability.WinterWyvernArcticBurn -> Some WinterWyvern
        | Ability.WinterWyvernColdEmbrace -> Some WinterWyvern
        | Ability.WinterWyvernSplinterBlast -> Some WinterWyvern
        | Ability.WinterWyvernWintersCurse -> Some WinterWyvern
        | Ability.WispOvercharge -> Some Wisp
        | Ability.WispRelocate -> Some Wisp
        | Ability.WispSpirits -> Some Wisp
        | Ability.WispSpiritsIn -> Some Wisp
        | Ability.WispSpiritsOut -> Some Wisp
        | Ability.WispTether -> Some Wisp
        | Ability.WispTetherBreak -> Some Wisp
        | Ability.WitchDoctorDeathWard -> Some WitchDoctor
        | Ability.WitchDoctorMaledict -> Some WitchDoctor
        | Ability.WitchDoctorParalyzingCask -> Some WitchDoctor
        | Ability.WitchDoctorVoodooRestoration -> Some WitchDoctor
        | Ability.ZuusArcLightning -> Some Zuus
        | Ability.ZuusCloud -> Some Zuus
        | Ability.ZuusLightningBolt -> Some Zuus
        | Ability.ZuusStaticField -> Some Zuus
        | Ability.ZuusThundergodsWrath -> Some Zuus
        | _ -> None

    /// <summary>
    ///    Get the Abilities that a given Hero casts in Dota2
    /// </summary>
    ///
    /// <param name="hero">
    ///     The Dota 2 hero to obtain Abilities for
    /// </param>
    ///
    /// <returns>
    ///     A list of Abilities that belong to a given Hero
    /// </returns>
    let getAbilities (hero : Hero) : Ability list =
        match hero with
        | Abaddon ->
            [
                Ability.AbaddonAphoticShield
                Ability.AbaddonBorrowedTime
                Ability.AbaddonDeathCoil
                Ability.AbaddonFrostmourne
            ]
        | AbyssalUnderlord ->
            [
                Ability.AbyssalUnderlordAtrophyAura
                Ability.AbyssalUnderlordCancelDarkRift
                Ability.AbyssalUnderlordDarkRift
                Ability.AbyssalUnderlordFirestorm
                Ability.AbyssalUnderlordPitOfMalice
            ]
        | Alchemist ->
            [
                Ability.AlchemistAcidSpray
                Ability.AlchemistChemicalRage
                Ability.AlchemistGoblinsGreed
                Ability.AlchemistUnstableConcoction
                Ability.AlchemistUnstableConcoctionThrow
            ]
        | AncientApparition ->
            [
                Ability.AncientApparitionChillingTouch
                Ability.AncientApparitionColdFeet
                Ability.AncientApparitionIceBlast
                Ability.AncientApparitionIceBlastRelease
                Ability.AncientApparitionIceVortex
            ]
        | Antimage ->
            [
                Ability.AntimageBlink
                Ability.AntimageCounterspell
                Ability.AntimageManaBreak
                Ability.AntimageManaVoid
                Ability.AntimageSpellShield
            ]
        | ArcWarden ->
            [
                Ability.ArcWardenFlux
                Ability.ArcWardenMagneticField
                Ability.ArcWardenSparkWraith
                Ability.ArcWardenTempestDouble
            ]
        | Axe ->
            [
                Ability.AxeBattleHunger
                Ability.AxeBerserkersCall
                Ability.AxeCounterHelix
                Ability.AxeCullingBlade
            ]
        | Bane ->
            [
                Ability.BaneBrainSap
                Ability.BaneEnfeeble
                Ability.BaneFiendsGrip
                Ability.BaneNightmare
                Ability.BaneNightmareEnd
            ]
        | Batrider ->
            [
                Ability.BatriderFirefly
                Ability.BatriderFlamebreak
                Ability.BatriderFlamingLasso
                Ability.BatriderStickyNapalm
            ]
        | Beastmaster ->
            [
                Ability.BeastmasterBoarPoison
                Ability.BeastmasterCallOfTheWild
                Ability.BeastmasterCallOfTheWildBoar
                Ability.BeastmasterCallOfTheWildHawk
                Ability.BeastmasterGreaterBoarPoison
                Ability.BeastmasterHawkInvisibility
                Ability.BeastmasterInnerBeast
                Ability.BeastmasterPrimalRoar
                Ability.BeastmasterWildAxes
            ]
        | Bloodseeker ->
            [
                Ability.BloodseekerBloodBath
                Ability.BloodseekerBloodrage
                Ability.BloodseekerRupture
                Ability.BloodseekerThirst
            ]
        | BountyHunter ->
            [
                Ability.BountyHunterJinada
                Ability.BountyHunterShurikenToss
                Ability.BountyHunterTrack
                Ability.BountyHunterWindWalk
            ]
        | Brewmaster ->
            [
                Ability.BrewmasterCinderBrew
                Ability.BrewmasterDrunkenBrawler
                Ability.BrewmasterDrunkenHaze
                Ability.BrewmasterEarthHurlBoulder
                Ability.BrewmasterEarthPulverize
                Ability.BrewmasterEarthSpellImmunity
                Ability.BrewmasterFirePermanentImmolation
                Ability.BrewmasterPrimalSplit
                Ability.BrewmasterStormCyclone
                Ability.BrewmasterStormDispelMagic
                Ability.BrewmasterStormWindWalk
                Ability.BrewmasterThunderClap
            ]
        | Bristleback ->
            [
                Ability.BristlebackBristleback
                Ability.BristlebackQuillSpray
                Ability.BristlebackViscousNasalGoo
                Ability.BristlebackWarpath
            ]
        | Broodmother ->
            [
                Ability.BroodmotherIncapacitatingBite
                Ability.BroodmotherInsatiableHunger
                Ability.BroodmotherPoisonSting
                Ability.BroodmotherSpawnSpiderite
                Ability.BroodmotherSpawnSpiderlings
                Ability.BroodmotherSpinWeb
                Ability.BroodmotherSpinWebDestroy
            ]
        | Centaur ->
            [
                Ability.CentaurDoubleEdge
                Ability.CentaurHoofStomp
                Ability.CentaurReturn
                Ability.CentaurStampede
            ]
        | ChaosKnight ->
            [
                Ability.ChaosKnightChaosBolt
                Ability.ChaosKnightChaosStrike
                Ability.ChaosKnightPhantasm
                Ability.ChaosKnightRealityRift
            ]
        | Chen ->
            [
                Ability.ChenDivineFavor
                Ability.ChenHandOfGod
                Ability.ChenHolyPersuasion
                Ability.ChenPenitence
                Ability.ChenTestOfFaith
                Ability.ChenTestOfFaithTeleport
            ]
        | Clinkz ->
            [
                Ability.ClinkzBurningArmy
                Ability.ClinkzDeathPact
                Ability.ClinkzSearingArrows
                Ability.ClinkzStrafe
                Ability.ClinkzWindWalk
            ]
        | CrystalMaiden ->
            [
                Ability.CrystalMaidenBrillianceAura
                Ability.CrystalMaidenCrystalNova
                Ability.CrystalMaidenFreezingField
                Ability.CrystalMaidenFrostbite
            ]
        | DarkSeer ->
            [
                Ability.DarkSeerIonShell
                Ability.DarkSeerSurge
                Ability.DarkSeerVacuum
                Ability.DarkSeerWallOfReplica
            ]
        | DarkWillow ->
            [
                Ability.DarkWillowBedlam
                Ability.DarkWillowBrambleMaze
                Ability.DarkWillowCursedCrown
                Ability.DarkWillowShadowRealm
                Ability.DarkWillowTerrorize
            ]
        | Dazzle ->
            [
                Ability.DazzleBadJuju
                Ability.DazzlePoisonTouch
                Ability.DazzleShadowWave
                Ability.DazzleShallowGrave
                Ability.DazzleWeave
            ]
        | DeathProphet ->
            [
                Ability.DeathProphetCarrionSwarm
                Ability.DeathProphetExorcism
                Ability.DeathProphetSilence
                Ability.DeathProphetSpiritSiphon
                Ability.DeathProphetWitchcraft
            ]
        | Disruptor ->
            [
                Ability.DisruptorGlimpse
                Ability.DisruptorKineticField
                Ability.DisruptorStaticStorm
                Ability.DisruptorThunderStrike
            ]
        | DoomBringer ->
            [
                Ability.DoomBringerDevour
                Ability.DoomBringerDoom
                Ability.DoomBringerEmpty
                Ability.DoomBringerInfernalBlade
                Ability.DoomBringerScorchedEarth
            ]
        | DragonKnight ->
            [
                Ability.DragonKnightBreatheFire
                Ability.DragonKnightDragonBlood
                Ability.DragonKnightDragonTail
                Ability.DragonKnightElderDragonForm
            ]
        | DrowRanger ->
            [
                Ability.DrowRangerFrostArrows
                Ability.DrowRangerMarksmanship
                Ability.DrowRangerSilence
                Ability.DrowRangerTrueshot
                Ability.DrowRangerWaveOfSilence
            ]
        | Earthshaker ->
            [
                Ability.EarthshakerAftershock
                Ability.EarthshakerEchoSlam
                Ability.EarthshakerEnchantTotem
                Ability.EarthshakerFissure
            ]
        | EarthSpirit ->
            [
                Ability.EarthSpiritBoulderSmash
                Ability.EarthSpiritGeomagneticGrip
                Ability.EarthSpiritMagnetize
                Ability.EarthSpiritPetrify
                Ability.EarthSpiritRollingBoulder
                Ability.EarthSpiritStoneCaller
            ]
        | ElderTitan ->
            [
                Ability.ElderTitanAncestralSpirit
                Ability.ElderTitanEarthSplitter
                Ability.ElderTitanEchoStomp
                Ability.ElderTitanEchoStompSpirit
                Ability.ElderTitanNaturalOrder
                Ability.ElderTitanNaturalOrderSpirit
                Ability.ElderTitanReturnSpirit
            ]
        | EmberSpirit ->
            [
                Ability.EmberSpiritActivateFireRemnant
                Ability.EmberSpiritFireRemnant
                Ability.EmberSpiritFlameGuard
                Ability.EmberSpiritSearingChains
                Ability.EmberSpiritSleightOfFist
            ]
        | Enchantress ->
            [
                Ability.EnchantressEnchant
                Ability.EnchantressImpetus
                Ability.EnchantressNaturesAttendants
                Ability.EnchantressUntouchable
            ]
        | Enigma ->
            [
                Ability.EnigmaBlackHole
                Ability.EnigmaDemonicConversion
                Ability.EnigmaMalefice
                Ability.EnigmaMidnightPulse
            ]
        | FacelessVoid ->
            [
                Ability.FacelessVoidBacktrack
                Ability.FacelessVoidChronosphere
                Ability.FacelessVoidTimeDilation
                Ability.FacelessVoidTimeLock
                Ability.FacelessVoidTimeWalk
            ]
        | Furion ->
            [
                Ability.FurionForceOfNature
                Ability.FurionSprout
                Ability.FurionTeleportation
                Ability.FurionWrathOfNature
            ]
        | Grimstroke ->
            [
                Ability.GrimstrokeDarkArtistry
                Ability.GrimstrokeInkCreature
                Ability.GrimstrokeSoulChain
                Ability.GrimstrokeSpiritWalk
            ]
        | Gyrocopter ->
            [
                Ability.GyrocopterCallDown
                Ability.GyrocopterFlakCannon
                Ability.GyrocopterHomingMissile
                Ability.GyrocopterRocketBarrage
            ]
        | Huskar ->
            [
                Ability.HuskarBerserkersBlood
                Ability.HuskarBurningSpear
                Ability.HuskarInnerFire
                Ability.HuskarInnerVitality
                Ability.HuskarLifeBreak
            ]
        | Invoker ->
            [
                Ability.InvokerAlacrity
                Ability.InvokerChaosMeteor
                Ability.InvokerColdSnap
                Ability.InvokerDeafeningBlast
                Ability.InvokerEmp
                Ability.InvokerEmpty
                Ability.InvokerExort
                Ability.InvokerForgeSpirit
                Ability.InvokerGhostWalk
                Ability.InvokerIceWall
                Ability.InvokerInvoke
                Ability.InvokerQuas
                Ability.InvokerSunStrike
                Ability.InvokerTornado
                Ability.InvokerWex
            ]
        | Jakiro ->
            [
                Ability.JakiroDualBreath
                Ability.JakiroIcePath
                Ability.JakiroLiquidFire
                Ability.JakiroMacropyre
            ]
        | Juggernaut ->
            [
                Ability.JuggernautBladeDance
                Ability.JuggernautBladeFury
                Ability.JuggernautHealingWard
                Ability.JuggernautOmniSlash
            ]
        | KeeperOfTheLight ->
            [
                Ability.KeeperOfTheLightBlindingLight
                Ability.KeeperOfTheLightChakraMagic
                Ability.KeeperOfTheLightEmpty
                Ability.KeeperOfTheLightIlluminate
                Ability.KeeperOfTheLightIlluminateEnd
                Ability.KeeperOfTheLightManaLeak
                Ability.KeeperOfTheLightRecall
                Ability.KeeperOfTheLightSpiritForm
                Ability.KeeperOfTheLightSpiritFormIlluminate
                Ability.KeeperOfTheLightSpiritFormIlluminateEnd
                Ability.KeeperOfTheLightWillOWisp
            ]
        | Kunkka ->
            [
                Ability.KunkkaGhostship
                Ability.KunkkaReturn
                Ability.KunkkaTidebringer
                Ability.KunkkaTorrent
                Ability.KunkkaXMarksTheSpot
            ]
        | LegionCommander ->
            [
                Ability.LegionCommanderDuel
                Ability.LegionCommanderMomentOfCourage
                Ability.LegionCommanderOverwhelmingOdds
                Ability.LegionCommanderPressTheAttack
            ]
        | Leshrac ->
            [
                Ability.LeshracDiabolicEdict
                Ability.LeshracLightningStorm
                Ability.LeshracPulseNova
                Ability.LeshracSplitEarth
            ]
        | Lich ->
            [
                Ability.LichChainFrost
                Ability.LichDarkSorcery
                Ability.LichFrostArmor
                Ability.LichFrostAura
                Ability.LichFrostNova
                Ability.LichFrostShield
                Ability.LichSinisterGaze
            ]
        | LifeStealer ->
            [
                Ability.LifeStealerAssimilate
                Ability.LifeStealerAssimilateEject
                Ability.LifeStealerConsume
                Ability.LifeStealerControl
                Ability.LifeStealerEmpty
                Ability.LifeStealerFeast
                Ability.LifeStealerInfest
                Ability.LifeStealerOpenWounds
                Ability.LifeStealerRage
            ]
        | Lina ->
            [
                Ability.LinaDragonSlave
                Ability.LinaFierySoul
                Ability.LinaLagunaBlade
                Ability.LinaLightStrikeArray
            ]
        | Lion ->
            [
                Ability.LionFingerOfDeath
                Ability.LionImpale
                Ability.LionManaDrain
                Ability.LionVoodoo
            ]
        | LoneDruid ->
            [
                Ability.LoneDruidRabid
                Ability.LoneDruidSavageRoar
                Ability.LoneDruidSavageRoarBear
                Ability.LoneDruidSpiritBear
                Ability.LoneDruidSpiritBearDefender
                Ability.LoneDruidSpiritBearDemolish
                Ability.LoneDruidSpiritBearEntangle
                Ability.LoneDruidSpiritBearReturn
                Ability.LoneDruidSpiritLink
                Ability.LoneDruidTrueForm
                Ability.LoneDruidTrueFormBattleCry
                Ability.LoneDruidTrueFormDruid
            ]
        | Luna ->
            [
                Ability.LunaEclipse
                Ability.LunaLucentBeam
                Ability.LunaLunarBlessing
                Ability.LunaMoonGlaive
            ]
        | Lycan ->
            [
                Ability.LycanFeralImpulse
                Ability.LycanHowl
                Ability.LycanShapeshift
                Ability.LycanSummonWolves
                Ability.LycanSummonWolvesCriticalStrike
                Ability.LycanSummonWolvesInvisibility
            ]
        | Magnataur ->
            [
                Ability.MagnataurEmpower
                Ability.MagnataurReversePolarity
                Ability.MagnataurShockwave
                Ability.MagnataurSkewer
            ]
        | Mars ->
            [
                Ability.MarsArenaOfBlood
                Ability.MarsBulwark
                Ability.MarsGodsRebuke
                Ability.MarsSpear
            ]
        | Medusa ->
            [
                Ability.MedusaManaShield
                Ability.MedusaMysticSnake
                Ability.MedusaSplitShot
                Ability.MedusaStoneGaze
            ]
        | Meepo ->
            [
                Ability.MeepoDividedWeStand
                Ability.MeepoEarthbind
                Ability.MeepoGeostrike
                Ability.MeepoPoof
                Ability.MeepoRansack
            ]
        | Mirana ->
            [
                Ability.MiranaArrow
                Ability.MiranaInvis
                Ability.MiranaLeap
                Ability.MiranaStarfall
            ]
        | MonkeyKing ->
            [
                Ability.MonkeyKingBoundlessStrike
                Ability.MonkeyKingJinguMastery
                Ability.MonkeyKingMischief
                Ability.MonkeyKingPrimalSpring
                Ability.MonkeyKingPrimalSpringEarly
                Ability.MonkeyKingTreeDance
                Ability.MonkeyKingUntransform
                Ability.MonkeyKingWukongsCommand
            ]
        | Morphling ->
            [
                Ability.MorphlingAdaptiveStrikeAgi
                Ability.MorphlingAdaptiveStrikeStr
                Ability.MorphlingHybrid
                Ability.MorphlingMorph
                Ability.MorphlingMorphAgi
                Ability.MorphlingMorphReplicate
                Ability.MorphlingMorphStr
                Ability.MorphlingReplicate
                Ability.MorphlingWaveform
            ]
        | NagaSiren ->
            [
                Ability.NagaSirenEnsnare
                Ability.NagaSirenMirrorImage
                Ability.NagaSirenRipTide
                Ability.NagaSirenSongOfTheSiren
                Ability.NagaSirenSongOfTheSirenCancel
            ]
        | Necrolyte ->
            [
                Ability.NecrolyteDeathPulse
                Ability.NecrolyteHeartstopperAura
                Ability.NecrolyteReapersScythe
                Ability.NecrolyteSadist
                Ability.NecrolyteSadistStop
            ]
        | Nevermore ->
            [
                Ability.NevermoreDarkLord
                Ability.NevermoreNecromastery
                Ability.NevermoreRequiem
                Ability.NevermoreShadowraze
            ]
        | NightStalker ->
            [
                Ability.NightStalkerCripplingFear
                Ability.NightStalkerDarkness
                Ability.NightStalkerHunterInTheNight
                Ability.NightStalkerVoid
            ]
        | NyxAssassin ->
            [
                Ability.NyxAssassinBurrow
                Ability.NyxAssassinImpale
                Ability.NyxAssassinManaBurn
                Ability.NyxAssassinSpikedCarapace
                Ability.NyxAssassinUnburrow
                Ability.NyxAssassinVendetta
            ]
        | ObsidianDestroyer ->
            [
                Ability.ObsidianDestroyerArcaneOrb
                Ability.ObsidianDestroyerAstralImprisonment
                Ability.ObsidianDestroyerEquilibrium
                Ability.ObsidianDestroyerEssenceAura
                Ability.ObsidianDestroyerSanityEclipse
            ]
        | OgreMagi ->
            [
                Ability.OgreMagiBloodlust
                Ability.OgreMagiFireblast
                Ability.OgreMagiFrostArmor
                Ability.OgreMagiIgnite
                Ability.OgreMagiMulticast
                Ability.OgreMagiUnrefinedFireblast
            ]
        | Omniknight ->
            [
                Ability.OmniknightDegenAura
                Ability.OmniknightGuardianAngel
                Ability.OmniknightPacify
                Ability.OmniknightPurification
                Ability.OmniknightRepel
            ]
        | Oracle ->
            [
                Ability.OracleFalsePromise
                Ability.OracleFatesEdict
                Ability.OracleFortunesEnd
                Ability.OraclePurifyingFlames
            ]
        | Pangolier ->
            [
                Ability.PangolierGyroshell
                Ability.PangolierGyroshellStop
                Ability.PangolierHeartpiercer
                Ability.PangolierLuckyShot
                Ability.PangolierShieldCrash
                Ability.PangolierSwashbuckle
            ]
        | PhantomAssassin ->
            [
                Ability.PhantomAssassinBlur
                Ability.PhantomAssassinCoupDeGrace
                Ability.PhantomAssassinPhantomStrike
                Ability.PhantomAssassinStiflingDagger
            ]
        | PhantomLancer ->
            [
                Ability.PhantomLancerDoppelwalk
                Ability.PhantomLancerJuxtapose
                Ability.PhantomLancerPhantomEdge
                Ability.PhantomLancerSpiritLance
            ]
        | Phoenix ->
            [
                Ability.PhoenixFireSpirits
                Ability.PhoenixIcarusDive
                Ability.PhoenixIcarusDiveStop
                Ability.PhoenixLaunchFireSpirit
                Ability.PhoenixSunRay
                Ability.PhoenixSunRayStop
                Ability.PhoenixSunRayToggleMove
                Ability.PhoenixSupernova
            ]
        | Puck ->
            [
                Ability.PuckDreamCoil
                Ability.PuckEtherealJaunt
                Ability.PuckIllusoryOrb
                Ability.PuckPhaseShift
                Ability.PuckWaningRift
            ]
        | Pudge ->
            [
                Ability.PudgeDismember
                Ability.PudgeFleshHeap
                Ability.PudgeMeatHook
                Ability.PudgeRot
            ]
        | Pugna ->
            [
                Ability.PugnaDecrepify
                Ability.PugnaLifeDrain
                Ability.PugnaNetherBlast
                Ability.PugnaNetherWard
            ]
        | QueenOfPain ->
            [
                Ability.QueenofpainBlink
                Ability.QueenofpainScreamOfPain
                Ability.QueenofpainShadowStrike
                Ability.QueenofpainSonicWave
            ]
        | Rattletrap ->
            [
                Ability.RattletrapBatteryAssault
                Ability.RattletrapHookshot
                Ability.RattletrapPowerCogs
                Ability.RattletrapRocketFlare
            ]
        | Razor ->
            [
                Ability.RazorEyeOfTheStorm
                Ability.RazorPlasmaField
                Ability.RazorStaticLink
                Ability.RazorUnstableCurrent
            ]
        | Riki ->
            [
                Ability.RikiBlinkStrike
                Ability.RikiPermanentInvisibility
                Ability.RikiSmokeScreen
                Ability.RikiTricksOfTheTrade
            ]
        | Rubick ->
            [
                Ability.RubickArcaneSupremacy
                Ability.RubickEmpty
                Ability.RubickFadeBolt
                Ability.RubickHidden
                Ability.RubickNullField
                Ability.RubickSpellSteal
                Ability.RubickTelekinesis
                Ability.RubickTelekinesisLand
            ]
        | SandKing ->
            [
                Ability.SandkingBurrowstrike
                Ability.SandkingCausticFinale
                Ability.SandkingEpicenter
                Ability.SandkingSandStorm
            ]
        | ShadowDemon ->
            [
                Ability.ShadowDemonDemonicPurge
                Ability.ShadowDemonDisruption
                Ability.ShadowDemonShadowPoison
                Ability.ShadowDemonShadowPoisonRelease
                Ability.ShadowDemonSoulCatcher
            ]
        | ShadowShaman ->
            [
                Ability.ShadowShamanEtherShock
                Ability.ShadowShamanMassSerpentWard
                Ability.ShadowShamanShackles
                Ability.ShadowShamanVoodoo
            ]
        | Shredder ->
            [
                Ability.ShredderChakram
                Ability.ShredderReactiveArmor
                Ability.ShredderReturnChakram
                Ability.ShredderTimberChain
                Ability.ShredderWhirlingDeath
            ]
        | Silencer ->
            [
                Ability.SilencerCurseOfTheSilent
                Ability.SilencerGlaivesOfWisdom
                Ability.SilencerGlobalSilence
                Ability.SilencerLastWord
            ]
        | SkeletonKing ->
            [
                Ability.SkeletonKingHellfireBlast
                Ability.SkeletonKingMortalStrike
                Ability.SkeletonKingReincarnation
                Ability.SkeletonKingVampiricAura
            ]
        | SkywrathMage ->
            [
                Ability.SkywrathMageAncientSeal
                Ability.SkywrathMageArcaneBolt
                Ability.SkywrathMageConcussiveShot
                Ability.SkywrathMageMysticFlare
            ]
        | Slardar ->
            [
                Ability.SlardarAmplifyDamage
                Ability.SlardarBash
                Ability.SlardarSlithereenCrush
                Ability.SlardarSprint
            ]
        | Slark ->
            [
                Ability.SlarkDarkPact
                Ability.SlarkEssenceShift
                Ability.SlarkPounce
                Ability.SlarkShadowDance
            ]
        | Sniper ->
            [
                Ability.SniperAssassinate
                Ability.SniperHeadshot
                Ability.SniperShrapnel
                Ability.SniperTakeAim
            ]
        | Spectre ->
            [
                Ability.SpectreDesolate
                Ability.SpectreDispersion
                Ability.SpectreHaunt
                Ability.SpectreReality
                Ability.SpectreSpectralDagger
            ]
        | SpiritBreaker ->
            [
                Ability.SpiritBreakerBulldoze
                Ability.SpiritBreakerChargeOfDarkness
                Ability.SpiritBreakerEmpoweringHaste
                Ability.SpiritBreakerGreaterBash
                Ability.SpiritBreakerNetherStrike
            ]
        | StormSpirit ->
            [
                Ability.StormSpiritBallLightning
                Ability.StormSpiritElectricVortex
                Ability.StormSpiritOverload
                Ability.StormSpiritStaticRemnant
            ]
        | Sven ->
            [
                Ability.SvenGodsStrength
                Ability.SvenGreatCleave
                Ability.SvenStormBolt
                Ability.SvenWarcry
            ]
        | Techies ->
            [
                Ability.TechiesFocusedDetonate
                Ability.TechiesLandMines
                Ability.TechiesMinefieldSign
                Ability.TechiesRemoteMines
                Ability.TechiesRemoteMinesSelfDetonate
                Ability.TechiesStasisTrap
                Ability.TechiesSuicide
            ]
        | TemplarAssassin ->
            [
                Ability.TemplarAssassinMeld
                Ability.TemplarAssassinPsiBlades
                Ability.TemplarAssassinPsionicTrap
                Ability.TemplarAssassinRefraction
                Ability.TemplarAssassinSelfTrap
                Ability.TemplarAssassinTrap
            ]
        | Terrorblade ->
            [
                Ability.TerrorbladeConjureImage
                Ability.TerrorbladeMetamorphosis
                Ability.TerrorbladeReflection
                Ability.TerrorbladeSunder
            ]
        | Tidehunter ->
            [
                Ability.TidehunterAnchorSmash
                Ability.TidehunterGush
                Ability.TidehunterKrakenShell
                Ability.TidehunterRavage
            ]
        | Tinker ->
            [
                Ability.TinkerHeatSeekingMissile
                Ability.TinkerLaser
                Ability.TinkerMarchOfTheMachines
                Ability.TinkerRearm
            ]
        | Tiny ->
            [
                Ability.TinyAvalanche
                Ability.TinyCraggyExterior
                Ability.TinyGrow
                Ability.TinyToss
                Ability.TinyTossTree
            ]
        | Treant ->
            [
                Ability.TreantEyesInTheForest
                Ability.TreantLeechSeed
                Ability.TreantLivingArmor
                Ability.TreantNaturesGuise
                Ability.TreantOvergrowth
            ]
        | TrollWarlord ->
            [
                Ability.TrollWarlordBattleTrance
                Ability.TrollWarlordBerserkersRage
                Ability.TrollWarlordFervor
                Ability.TrollWarlordWhirlingAxesMelee
                Ability.TrollWarlordWhirlingAxesRanged
            ]
        | Tusk ->
            [
                Ability.TuskFrozenSigil
                Ability.TuskIceShards
                Ability.TuskIceShardsStop
                Ability.TuskLaunchSnowball
                Ability.TuskSnowball
                Ability.TuskTagTeam
                Ability.TuskWalrusKick
                Ability.TuskWalrusPunch
            ]
        | Undying ->
            [
                Ability.UndyingDecay
                Ability.UndyingFleshGolem
                Ability.UndyingSoulRip
                Ability.UndyingTombstone
                Ability.UndyingTombstoneZombieAura
                Ability.UndyingTombstoneZombieDeathstrike
            ]
        | Ursa ->
            [
                Ability.UrsaEarthshock
                Ability.UrsaEnrage
                Ability.UrsaFurySwipes
                Ability.UrsaOverpower
            ]
        | VengefulSpirit ->
            [
                Ability.VengefulspiritCommandAura
                Ability.VengefulspiritMagicMissile
                Ability.VengefulspiritNetherSwap
                Ability.VengefulspiritWaveOfTerror
            ]
        | Venomancer ->
            [
                Ability.VenomancerPlagueWard
                Ability.VenomancerPoisonNova
                Ability.VenomancerPoisonSting
                Ability.VenomancerVenomousGale
            ]
        | Viper ->
            [
                Ability.ViperCorrosiveSkin
                Ability.ViperNethertoxin
                Ability.ViperPoisonAttack
                Ability.ViperViperStrike
            ]
        | Visage ->
            [
                Ability.VisageGraveChill
                Ability.VisageGravekeepersCloak
                Ability.VisageSoulAssumption
                Ability.VisageStoneFormSelfCast
                Ability.VisageSummonFamiliars
                Ability.VisageSummonFamiliarsStoneForm
            ]
        | Warlock ->
            [
                Ability.WarlockFatalBonds
                Ability.WarlockGolemFlamingFists
                Ability.WarlockGolemPermanentImmolation
                Ability.WarlockRainOfChaos
                Ability.WarlockShadowWord
                Ability.WarlockUpheaval
            ]
        | Weaver ->
            [
                Ability.WeaverGeminateAttack
                Ability.WeaverShukuchi
                Ability.WeaverTheSwarm
                Ability.WeaverTimeLapse
            ]
        | Windrunner ->
            [
                Ability.WindrunnerFocusfire
                Ability.WindrunnerPowershot
                Ability.WindrunnerShackleshot
                Ability.WindrunnerWindrun
            ]
        | WinterWyvern ->
            [
                Ability.WinterWyvernArcticBurn
                Ability.WinterWyvernColdEmbrace
                Ability.WinterWyvernSplinterBlast
                Ability.WinterWyvernWintersCurse
            ]
        | Wisp ->
            [
                Ability.WispOvercharge
                Ability.WispRelocate
                Ability.WispSpirits
                Ability.WispSpiritsIn
                Ability.WispSpiritsOut
                Ability.WispTether
                Ability.WispTetherBreak
            ]
        | WitchDoctor ->
            [
                Ability.WitchDoctorDeathWard
                Ability.WitchDoctorMaledict
                Ability.WitchDoctorParalyzingCask
                Ability.WitchDoctorVoodooRestoration
            ]
        | Zuus ->
            [
                Ability.ZuusArcLightning
                Ability.ZuusCloud
                Ability.ZuusLightningBolt
                Ability.ZuusStaticField
                Ability.ZuusThundergodsWrath
            ]


    /// <summary>
    ///    Get the attack information for a given Dota 2 hero.
    /// </summary>
    ///
    /// <param name="hero">
    ///     The Dota 2 hero to obtain attack information for.
    /// </param>
    ///
    /// <returns>
    ///     The attack information of a given Dota 2 hero.
    /// </returns>
    let getAttack (hero : Hero) : Attack =
        match hero with
        | TemplarAssassin ->
            { range = Distance.fromIntLiteral 140 }
        | Abaddon
        | AbyssalUnderlord
        | Alchemist
        | Antimage
        | Axe
        | Beastmaster
        | Bloodseeker
        | BountyHunter
        | Brewmaster
        | Bristleback
        | Broodmother
        | Centaur
        | ChaosKnight
        | DarkSeer
        | DragonKnight
        | EarthSpirit
        | Earthshaker
        | ElderTitan
        | EmberSpirit
        | FacelessVoid
        | Juggernaut
        | Kunkka
        | LegionCommander
        | LifeStealer
        | Lycan
        | Magnataur
        | Meepo
        | NagaSiren
        | NightStalker
        | NyxAssassin
        | OgreMagi
        | Omniknight
        | Pangolier
        | PhantomAssassin
        | PhantomLancer
        | Pudge
        | Rattletrap
        | Riki
        | SandKing
        | Shredder
        | Slardar
        | Slark
        | SkeletonKing
        | Spectre
        | SpiritBreaker
        | Sven
        | Terrorblade
        | Tidehunter
        | Tiny
        | Treant
        | Tusk
        | Undying
        | Ursa ->
            { range = Distance.fromIntLiteral 150 }
        | DoomBringer ->
            { range = Distance.fromIntLiteral 175 }
        | Mars ->
            { range = Distance.fromIntLiteral 250 }
        | MonkeyKing ->
            { range = Distance.fromIntLiteral 300 }
        | Luna ->
            { range = Distance.fromIntLiteral 330 }
        | Morphling ->
            { range = Distance.fromIntLiteral 350 }
        | Gyrocopter ->
            { range = Distance.fromIntLiteral 365 }
        | Batrider ->
            { range = Distance.fromIntLiteral 375 }
        | Zuus ->
            { range = Distance.fromIntLiteral 380 }
        | Bane
        | Huskar
        | Jakiro
        | ShadowShaman
        | VengefulSpirit ->
            { range = Distance.fromIntLiteral 400 }
        | Weaver
        | WinterWyvern ->
            { range = Distance.fromIntLiteral 425 }
        | ObsidianDestroyer
        | Venomancer ->
            { range = Distance.fromIntLiteral 450 }
        | Razor
        | DarkWillow ->
            { range = Distance.fromIntLiteral 475 }
        | StormSpirit ->
            { range = Distance.fromIntLiteral 480 }
        | Enigma
        | Phoenix
        | Nevermore
        | ShadowDemon
        | Tinker
        | TrollWarlord ->
            { range = Distance.fromIntLiteral 500 }
        | Dazzle
        | Enchantress
        | Grimstroke
        | Lich
        | LoneDruid
        | Necrolyte
        | Puck
        | QueenOfPain
        | Rubick
        | Sniper ->
            { range = Distance.fromIntLiteral 550 }
        | Viper
        | Wisp ->
            { range = Distance.fromIntLiteral 575 }
        | CrystalMaiden
        | DeathProphet
        | Disruptor
        | Invoker
        | KeeperOfTheLight
        | Leshrac
        | Lion
        | Medusa
        | Furion
        | Silencer
        | SkywrathMage
        | Visage
        | Warlock
        | Windrunner
        | WitchDoctor ->
            { range = Distance.fromIntLiteral 600 }
        | Oracle ->
            { range = Distance.fromIntLiteral 620 }
        | ArcWarden
        | DrowRanger ->
            { range = Distance.fromIntLiteral 625 }
        | Mirana
        | Pugna ->
            { range = Distance.fromIntLiteral 630 }
        | Clinkz ->
            { range = Distance.fromIntLiteral 640 }
        | Chen ->
            { range = Distance.fromIntLiteral 650 }
        | Lina ->
            { range = Distance.fromIntLiteral 670 }
        | AncientApparition ->
            { range = Distance.fromIntLiteral 675 }
        | Techies ->
            { range = Distance.fromIntLiteral 700 }