module StatBanana.Web.Client.Components.Templates.HeroPageTemplate

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

type Styles =
    { container : string
      content : string
      footer : string
      header : string
      hero : string }

type Section =
    | ContainedSection of ReactElement list

/// <summary>
///     Renders a page with a expressive 'hero' element at the top.
/// </summary>
///
/// <param name="header">
///     The navigation header to use.
/// </param>
///
/// <param name="hero">
///     The hero. The saviour.
/// </param>
///
/// <param name="content">
///     The main content of the page.
/// </param>
///
/// <param name="footer">
///     The footer of the page.
/// </param>
///
/// <returns>
///     The rendered page.
/// </returns>
let template
    (hero : ReactElement)
    (sections : Section list)
    (footer : ReactElement) : ReactElement =
    let styles : Styles = importAll "./HeroPageTemplate.sass"
    let renderSection (section : Section) =
        match section with
        | ContainedSection content ->
            Section.section [] [
                Container.container [] content
            ]
    fragment [] [
        hero
        div [ ClassName styles.content ] [
            (sections |> List.map renderSection |> ofList)
        ]
        div [ ClassName styles.footer ] [
            footer
        ]
    ]