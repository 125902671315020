/// Switches toggle the state of a single setting on or off.
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.Switch

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React
open StatBanana.Domain
open StatBanana.Web.Client.Import

type private Styles =
    { container : string
      label : string
      off : string
      on : string
      switch : string
      thumb : string
      track : string }

let switch
    (offLabel : ReactElement)
    (onLabel : ReactElement)
    (on : bool)
    (onSwitch : MouseEvent -> unit)
    : ReactElement =

    let styles : Styles = importAll "./Switch.sass"

    let toggledOffLabelClasses =
        [ (styles.label, true)
          (styles.off, on) ]
        |> ClassNames.classNames
    let toggledOnLabelClasses =
        [ (styles.label, true)
          (styles.off, not on) ]
        |> ClassNames.classNames
    let toggleMarkerClasses =
        [ (styles.thumb, true)
          (styles.on, on) ]
        |> ClassNames.classNames

    div [ ClassName styles.container ] [
        div [ ClassName toggledOffLabelClasses ] [
            offLabel
        ]
        div [ ClassName styles.switch
              OnClick onSwitch ] [
            div [ ClassName styles.track ] []
            div [ ClassName toggleMarkerClasses ] []
        ]
        div [ ClassName toggledOnLabelClasses ] [
            onLabel
        ]
    ]

/// <summary>
///     Renders a switch for billing periods.
/// </summary>
///
/// <param name="isYearly">
///     Whether the switch is currently on Yearly.
/// </param>
///
/// <param name="onSwitch">
///     Handler for when the switch is switched.
/// </param>
let billingPeriod
    (isYearly : bool)
    (onToggle : MouseEvent -> unit)
    :ReactElement =

    let offLabel =
        div [] [
            BillingPeriod.Monthly
            |> BillingPeriod.toString
            |> str
        ]
    let onLabel =
        div [] [
            BillingPeriod.Yearly
            |> BillingPeriod.toString
            |> str
        ]

    switch
        offLabel
        onLabel
        isYearly
        onToggle
