/// A draggable timeline object
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.DraggableTimelineObject

open System.Text.RegularExpressions

open Fable.Import
open Fable.Import.React

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import.ReactDraggable

/// <summary>
///     Renders a wrapped version of an object that gives it the
///     ability to be dragged around the timeline.
/// </summary>
///
/// <param name="rulerWidth">
///     The current width of the timeline ruler
/// </param>
///
/// <param name="timelineZoomLevel">
///     The current timeline zoom level
/// </param>
///
/// <param name="onStartDrag">
///     The function called when dragging starts
/// </param>
///
/// <param name="onStopDrag">
///     The function called when dragging stops
/// </param>
///
/// <param name="xPosition">
///     The current position of the draggable element on the x-axis
/// </param>
///
/// <param name="object">
///     The function that takes a React ref handler function and returns
///     a React element representing the object being dragabble
/// </param>
///
/// <param name="onRef">
///     The function that is called on creation of a React ref of the object so
///     parents of this component can access the child ref directly
/// </param>
let draggable
    (rulerWidth : int)
    (timelineZoomLevel : TimelineZoomLevel)
    (onStartDrag : MouseEvent -> unit)
    (onStopDrag : int -> unit)
    (xPosition : int)
    (object : (Browser.Element -> unit) -> ReactElement)
    (onRef : (Browser.Element -> unit)) : ReactElement =
    let mutable objectRef : Creatable<Browser.Element> = NotCreated
    let getTransformXValueOfObject () =
        match objectRef with
        | Created element ->
            // The transform style property is returned as a
            // 2d transformation matrix: transform(a, b, c, d, tx, ty)
            // The regex to extract the numbers from the matrix
            let style = Browser.window.getComputedStyle element
            let matches = (Regex @"(-?[0-9\.]+)").Matches style.transform
            let transformX = int matches.[4].Value
            transformX
        | NotCreated ->
            0
    let getTimeFromCurrentObjectPosition zoomLevel =
        let transformXValue = float (getTransformXValueOfObject ())
        int ((transformXValue * 10.) / TimelineZoomLevel.toFloat(zoomLevel))
    let onStartDragHandler (event : MouseEvent) _ =
        event.stopPropagation ()
        onStartDrag event
    let onStopDragHandler (event : MouseEvent) _ =
        event.stopPropagation ()
        (getTimeFromCurrentObjectPosition timelineZoomLevel)
        |> onStopDrag
    draggable [ DraggableProps.Axis DraggableAxis.X
                DraggableProps.Bounds
                    { left = 0; top = 0; bottom = 0; right = rulerWidth }
                DraggableProps.Handle ".handle"
                DraggableProps.OnStart onStartDragHandler
                DraggableProps.OnStop onStopDragHandler
                DraggableProps.Position
                    { x = xPosition
                      y = 0 } ] [
        (object (fun ref ->
                    onRef ref
                    objectRef <- Created ref))
    ]
