namespace StatBanana.Domain

/// The unmodified price of a product.
type ListPrice =
    { /// The price
      amount : decimal
      /// The currency that the amount is in
      currency : Currency }

/// The discounted price of a product.
type DiscountedPrice =
    { /// The original price
      listAmount : decimal
      /// The new discounted price
      discountedAmount : decimal
      /// The currency that the amounts are in
      currency : Currency }

/// The price for a subscription as it is presented to a customer.
type Price =
    | List of ListPrice
    | Discounted of DiscountedPrice
module Price =
    /// Gets the currency of the price.
    let getCurrency price =
        match price with
        | Price.List price -> price.currency
        | Price.Discounted price -> price.currency