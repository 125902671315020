module StatBanana.Web.Client.Components.Atoms.CardInfo

open Fable.Helpers.React
open Fable.Import.React

open StatBanana.Domain

let info (cardInfo : CardInfo) : ReactElement =
    sprintf
        "%s ending in %d expiring %d/%d"
        cardInfo.brand
        cardInfo.lastFour
        cardInfo.expiryMonth
        cardInfo.expiryYear
    |> str