/// A page that displays the strategiser interface
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.StrategiserPage

open System
open System.Text.RegularExpressions

open Fable.Core
open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React
open Fable.PowerPack
open Thoth.Json

open Elmish
open Elmish.Browser.Navigation
open Fulma
open Leaflet

open StatBanana.Domain
open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Dota2
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Extensions
open StatBanana.Web.Client.Import.AnimeJS.Anime
open StatBanana.Web.Client.Services
open StatBanana.Web.Client.Stories.Components.Organisms
open StatBanana.Web.Client.Stories.Components.Organisms

/// A Leaflet Circle to represent building attack range.
type BuildingAttackRangeCircle =
    BuildingAttackRangeCircle of Leaflet.Circle<obj>

/// Collection of active building attack range circles.
type BuildingAttackRangeCircles =
    BuildingAttackRangeCircles of ResizeArray<BuildingAttackRangeCircle>

/// A Leaflet Circle to represent hero attack range.
type HeroAttackRangeCircle =
    HeroAttackRangeCircle of Leaflet.Circle<obj>

/// Collection of active hero attack range circles.
type HeroAttackRangeCircles =
    HeroAttackRangeCircles of ResizeArray<HeroAttackRangeCircle>

/// A Leaflet Circle to represent ward sight radius.
type WardSightCircle =
    WardSightCircle of Leaflet.Circle<obj>

/// Collection of active ward sight circles.
type WardSightCircles =
    WardSightCircles of ResizeArray<WardSightCircle>

/// The undoable part of the model passed down to the Strategiser page.
type UndoableModel =
    { DrawingStrokes : Map<Guid, Drawing>
      Layers : Layer list
      LinearRulers : Map<Guid, RulerPoints>
      MapMarkers : Map<LayerId, Leaflet.Marker<obj>>
      RadialRulers : Map<Guid, RulerPoints> }

/// A Leaflet Circle to represent a ability's effect radius.
type AbilityEffectCircle =
    AbilityEffectCircle of Leaflet.Circle<obj>

/// A Leaflet Circle to represent a ability's effect radius.
type AbilityEffectCircles =
    AbilityEffectCircles of ResizeArray<AbilityEffectCircle>

/// Model passed down to the strategiser page
type Model =
    { AbilityEffectCircles : AbilityEffectCircles
      ActiveModifierKeys : string list
      CollaborationUpdateListenerIsActive : bool
      CreateMapMarkersForExistingLayers : bool
      CreateMapMarkersForNewLayers : bool
      CurrentMode : Mode
      CurrentPlaybackState : PlaybackState
      CurrentTime : int
      CurrentSidebarTab : SidebarTab option
      CurrentSidebarTool : SidebarTool
      DemoTimer : int option
      EditingLaneCreepMeetingPoints : bool
      ExistingSessions: Fetchable<(SessionId * Session) list> option
      IsLocked : bool
      Game : Game
      HeroAttackRangeCircles : HeroAttackRangeCircles
      HeroFilterString : string
      LayerBeingRenamed : Layer option
      LeavingStrategiserListener : unit option
      LoadSessionPanelActivated : bool
      Map : Loadable<Leaflet.Map>
      MapTileUrl : string
      Notifications : Notification list
      OnlineCollaborativeSessionViewers : CollaborativeSessionUser list
      OpenedModal : Modal
      PopulateStaticMarkers : bool
      SavingSession : bool
      SelectedKeyframe : Selectable<LayerUnit * int>
      SelectedLayers : Layer list
      Session : Fetchable<SessionId * Session> option
      SessionNotFound : bool
      SessionViewersListenerIsActive : bool
      StartedCollaborationSessionAt : DateTimeOffset
      TimelineDuration : int
      TimelineMarksPerSecond : int
      TimelineOpened : bool
      TimelineZoomLevel : TimelineZoomLevel
      TogglingCollaborationMode : bool
      UndoableModel : History<UndoableModel>
      UserMadeChanges : bool
      UserJustSignedUp : bool
      WardSightCircles : WardSightCircles }

let buildingAttackRangeCircles =
    new ResizeArray<BuildingAttackRangeCircle> ()
    |> BuildingAttackRangeCircles

/// Events/actions that can be dispatched by Strategiser page.
type Msg =
    | Tick
    | Stop
    | Play
    | Pause
    | SkipToTime of int
    | UpdateTimelineZoom of TimelineZoomLevel option
    | UpdateTimelineDuration of int
    | OpenSidebar of SidebarTab
    | CloseSidebar
    | SelectSidebarTool of SidebarTool
    | DeselectSidebarTool
    | PopulatedStaticMarkers
    | MapCreated of Map
    | MapClicked
    | MapZoomed of float
    | AddItemToMap of LayerUnit * Leaflet.Marker<obj>
    | AddMapMarkersForLayersToMap of (LayerUnit * Leaflet.Marker<obj>) list
    | CreatedMapMarkersForExistingLayers
    | CreatedMapMarkersForNewLayers
    | AddStaticItemsToMap of (LayerUnit * Leaflet.Marker<obj>) list
    | MoveMarker of LeafletEvent * LayerId
    | ToggleTimelineOpened
    | AddLayerGroup
    | GroupStaticLayers of string * bool * Layer list
    | ToggleLayerGroupOpened of LayerGroup
    | ToggleLayerLocking of Layer
    | ToggleLayerVisibility of Layer
    | EnableLayerRenaming of Layer
    | DisableLayerRenaming
    | MoveLayerBeforeAnother of Layer * string
    | MoveLayerAfterAnother of Layer * string
    | MoveLayerIntoGroup of Layer * string
    | UpdateHeroFilterString of string
    | SelectKeyframe of LayerUnit * int
    | DeselectKeyframe
    | DemoTimerTick
    | DemoFinished
    | OpenModal of Modal
    | CloseModal
    | AppFocused of Browser.FocusEvent
    | KeyPressedDown of Browser.KeyboardEvent
    | KeyPressedUp of Browser.KeyboardEvent
    | UserIsLeaving of Browser.BeforeUnloadEvent
    | AddActiveModifierKey of string
    | RemoveActiveModifierKey of string
    | RemoveAllActiveModifierKeys
    | AddHeroAttackRangeCircleToStorage of HeroAttackRangeCircle
    | AddWardSightCircleToStorage of WardSightCircle
    | AddAbilityEffectCircleToStorage of AbilityEffectCircle
    | DeleteAbilityEffectCircleFromStorage of AbilityEffectCircle
    | DeleteWardSightCircleFromStorage of WardSightCircle
    | DeleteWardSightCirclesFromStorage of WardSightCircle list
    | CreateBuildingLayerGroup
    | CreateNeutralCampLayerGroup
    | MoveKeyframe of LayerUnit * int * int
    | DeleteKeyframe of LayerUnit * int
    | ChangeSideForLayer of Layer * Dota2.Side
    | ChangeAttackRangeVisibilityForLayer of Layer * AttackRangeCircleVisibility
    | ChangeBuildingStateForLayer of Layer * BuildingState
    | ChangeColorForLayer of Layer * string
    | ChangeFontSizeForLayer of Layer * float
    | ChangeWidthForLayer of Layer * float
    | DeleteLayerFromTimeline of Layer
    | DeleteLayerFromInfoPane of Layer
    | DeleteLayersFromTimeline of Layer list
    | SelectMapMarker of LayerId
    | SelectTimelineLayer of Layer
    | UpdateAllLayersBuildingStates of Layer list
    | UpdateAllLayersTextMarkerContent of Layer list
    | RenameLayer of Layer
    | DrawingStrokeAdded of Guid * Leaflet.Polyline<obj,obj>
    | DrawingStrokeRemoved of Guid
    | LinearRulerAdded of Guid * Leaflet.LinearRuler
    | LinearRulerRemoved of Guid
    | RadialRulerAdded of Guid * Leaflet.RadialRuler
    | RadialRulerRemoved of Guid
    | Undo
    | Redo
    | DemoSessionError
    | DemoSessionFetched of Session
    | SaveSession of AuthenticatedUser
    | MakeSessionPrivate of SessionId
    | MakeSessionPublic of SessionId
    | ShareSession of SessionId
    | RenameSession of SessionId * string
    | DeleteSession of SessionId
    | FetchUserSessions of AuthenticatedUser
    | GoBackFromLoadSessionsPanel
    | UserSessionsFetched of (SessionId * Session) list
    | FetchUserSessionsFailed of exn
    | SessionCreated of SessionId * Session
    | SessionSaved of SessionId * Session * Notification
    | SessionMadePrivate of SessionId
    | SessionMadePublic of SessionId
    | SessionRenamed of SessionId * string
    | SessionFetched of SessionId * Session
    | SessionDeleted of SessionId
    | ModelPopulatedFromSession of Model
    | ModelPopulatedFromCollaborationUpdate
        of newModel : Model * mapMarkerPositions : Map<LayerId, Coordinates>
    | SessionNotFound
    | DismissNotification of Notification
    | UserMadeChanges
    | ResetUserMadeChanges
    | FetchSessionFailed of exn
    | SaveSessionFailed of exn * Notification
    | SessionOperationFailed of exn
    | InitSessionViewersFirestoreListener
    | NewSessionViewersUpdate
        of online : CollaborativeSessionUser list * offline: CollaborativeSessionUser list
    | SessionViewerListenerError of exn
    | SessionEntered
    | SessionEnterFailed of exn
    | EnableCollaborationMode
    | EnabledCollaborationMode
    | EnableCollaborationModeFailed of exn
    | DisableCollaborationMode
    | DisabledCollaborationMode of duration : int
    | DisableCollaborationModeFailed of exn
    | CollaborationSessionStarted
    | CollaborationSessionEnded
    | ApplyCollaborationUpdate
        of time : int * mapMarkerPositions : Map<LayerId, Coordinates> * save : Save option
    | SendCollaborationUpdate
    | CollaborationUpdateSent
    | CollaborationUpdateFailed of exn
    | InitCollaborationUpdateFirestoreListener
    | ReceivedCollaborationUpdate of bool * PlaybackState * int * Map<LayerId, Coordinates> * Save option
    | CollaborationUpdateListenerError of exn
    | UserAttemptedActionWhileLocked
    | Error

/// Save text marker content to a list of given Layers.
let private saveAllTextMarkerContentToLayers
    (layers : Layer list)
    : Layer list =

    let syncTextareaToUnit (unit : LayerUnit) =
        match unit.item with
        | TextItem text ->
            let markerElement =
                "id-" + (LayerId.toString unit.id)
                |> Browser.document.getElementById
            let textareaElement =
                markerElement.querySelector("textarea")
                :?> Browser.HTMLTextAreaElement
            let value = textareaElement.value
            { unit with
                item = text |> TextItem.setText value |> TextItem }
        | _ ->
            unit
    let syncTextareaToLayer layer =
        match layer with
        | LayerUnit unit ->
            unit
            |> syncTextareaToUnit
            |> LayerUnit
        | LayerGroup group ->
            let newChildren =
                group.children
                |> List.map syncTextareaToUnit
            { group with children = newChildren }
            |> LayerGroup
    List.map syncTextareaToLayer layers

let mutable private currentAnimeJSTimelineInstance = AnimeJS.initAnimeTimeline ()

let mutable private onBeforeUnloadListener : unit option = None

module Cmd =

    // Bunch of private common functions used across more than one Cmd

    let private addItemToLeafletMap
        (isViewerMode : bool)
        (map : Leaflet.Map)
        (marker : Leaflet.Marker<obj>)
        (id : LayerId)
        (item : Item)
        : Leaflet.Marker<obj> =

        item
        |> (Leaflet.createDivIconFromItem
                (Marker.mapMarker isViewerMode true)
                ("id-" + LayerId.toString id))
        |> U2.Case2
        |> marker.setIcon
        |> ignore
        map |> U2.Case1 |> marker.addTo |> ignore
        marker

    let private isBuildingDestroyedAtThisTime
        currentTime
        (keyframes : Strategiser.Keyframes)
        : bool =

        let firstKeyframe = keyframes |> Map.toList |> List.head
        let _, latestKeyframeInfo =
            keyframes
            |> Map.toList
            |> List.fold
                (fun closestKeyframe (time, state) ->
                    if time < currentTime then (time, state)
                    else closestKeyframe)
                firstKeyframe
        latestKeyframeInfo.isGrey

    let private whatFactionOwnsBuildingAtThisTime
        currentTime
        (keyframes : Strategiser.Keyframes)
        : FactionType =
        let firstKeyframe = keyframes |> Map.toList |> List.head
        let _, latestKeyframeInfo =
            keyframes
            |> Map.toList
            |> List.fold
                (fun closestKeyframe (time, state) ->
                    if time < currentTime then (time, state)
                    else closestKeyframe)
                firstKeyframe
        latestKeyframeInfo.capturedBy

    let private createSaveFromModel (model : Model) =
        let drawings =
            model.UndoableModel.present.DrawingStrokes
            |> Map.toList
            |> List.map snd
        let linearRulers =
            model.UndoableModel.present.LinearRulers
            |> Map.toList
            |> List.map snd
            |> List.map LinearRuler
        let radialRulers =
            model.UndoableModel.present.RadialRulers
            |> Map.toList
            |> List.map snd
            |> List.map RadialRuler
        let rulers = List.concat [ linearRulers; radialRulers ]

        { drawings = drawings
          layers = model.UndoableModel.present.Layers
          rulers = rulers }

    let private generateUndoableModelFromSave
        (undoableModel : UndoableModel)
        (save : Save) =

        let drawings =
            save.drawings
            |> List.map (fun drawing ->
                Guid.NewGuid (), drawing)
            |> Map.ofList
        let linearRulers =
            save.rulers
            |> List.map (fun ruler ->
                match ruler with
                | LinearRuler linearRuler ->
                    (Guid.NewGuid (), linearRuler) |> Some
                | RadialRuler _ ->
                    None)
            |> List.choose id
            |> Map.ofList
        let radialRulers =
            save.rulers
            |> List.map (fun ruler ->
                match ruler with
                | RadialRuler radialRuler ->
                    (Guid.NewGuid (), radialRuler) |> Some
                | LinearRuler _ ->
                    None)
            |> List.choose id
            |> Map.ofList

        { undoableModel with
              DrawingStrokes = drawings
              Layers = save.layers
              LinearRulers = linearRulers
              RadialRulers = radialRulers }

    let private populateModelFromCollaborationUpdate
        (time : int)
        (newMapMarkersPositions : Map<LayerId, Coordinates>)
        (collabUpdate : Save)
        (model : Model) =

        let removeMapMarkersFromModel
            (idsToKeep : LayerId list)
            (undoableModel : UndoableModel) =

            let filteredMapMarkers =
                undoableModel.MapMarkers
                |> Map.filter (fun id _ ->
                    idsToKeep
                    |> List.tryFind (fun idToKeep -> id = idToKeep)
                    |> Option.isSome)

            { undoableModel with MapMarkers = filteredMapMarkers }

        let newPresent =
            collabUpdate
            |> generateUndoableModelFromSave
                model.UndoableModel.present
            |> removeMapMarkersFromModel
                (newMapMarkersPositions |> Map.toList |> List.map fst)

        { model with
            CurrentTime = time
            UndoableModel =
              model.UndoableModel
              |> History.setPresent newPresent }

    let private populateModelFromLatestSave
        (latestSave : Save)
        (model : Model) =

        { model with
            UndoableModel =
              latestSave
              |> generateUndoableModelFromSave model.UndoableModel.present
              |> History.create }

    // The actual Cmds

    let fetchSession
        (app : AppConfig)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess result =
            match result with
            | Some session ->
                SessionFetched (sessionId, session)
            | None ->
                SessionNotFound
        let ofError (exn : exn) =
            FetchSessionFailed exn
        Cmd.ofPromise
            app.sessionStoreService.getSession
            sessionId
            ofSuccess
            ofError

    [<Import("default", from="../Static/assets/demo/dota2_demo_session.json")>]
    let demoSessionJson : obj = jsNative

    let fetchDemoSession (app: AppConfig) : Cmd<Msg> =

        let stringifiedJson =  demoSessionJson |> JS.JSON.stringify

        // Decoder takes in the stringified Session
        let sessionDecoder = Thoth.Json.Decode.Auto.fromString<Session>(stringifiedJson)

        let msg =
            match sessionDecoder with
            | Ok session ->
                DemoSessionFetched session
            | _ ->
                DemoSessionError
        Cmd.ofMsg msg

    let fetchSessionsForUser
        (app : AppConfig)
        (user : AuthenticatedUser)
        : Cmd<Msg> =

        let ofSuccess result =
            UserSessionsFetched result
        let ofError exn =
            FetchUserSessionsFailed exn
        Cmd.ofPromise
            app.sessionStoreService.getSessionsForUser
            user.id
            ofSuccess
            ofError

    let populateModelWithCollaborationUpdate
        (time : int)
        (mapMarkersPositions : Map<LayerId, Coordinates>)
        (save : Save)
        (model : Model)
        : Cmd<Msg> =

        let newModel =
            model
            |> populateModelFromCollaborationUpdate time mapMarkersPositions save

        (newModel, mapMarkersPositions)
        |> ModelPopulatedFromCollaborationUpdate
        |> Cmd.ofMsg

    let populateModelWithLatestSaveFromSession
        (session : Session)
        (model : Model)
        : Cmd<Msg> =

        model
        |> populateModelFromLatestSave session.latestSave
        |> ModelPopulatedFromSession
        |> Cmd.ofMsg

    let enterSession
        (app : AppConfig)
        (user : AuthenticatedUser)
        (isOwner : bool)
        (sessionId : SessionId)
        : Cmd<Msg> =

        app.collabSessionService.enterSession user isOwner sessionId

        Cmd.none

    let enableCollaboration
        (app : AppConfig)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess _ =
            EnabledCollaborationMode
        let ofError exn =
            EnableCollaborationModeFailed exn
        Cmd.ofPromise
            app.collabSessionService.enableCollaboration
            sessionId
            ofSuccess
            ofError

    let disableCollaboration
        (app : AppConfig)
        (sessionId : SessionId)
        (sessionStartTime : DateTimeOffset)
        : Cmd<Msg> =

        let ofSuccess _ =
            (DateTimeOffset.Now - sessionStartTime).Seconds
            |> DisabledCollaborationMode
        let ofError exn =
            DisableCollaborationModeFailed exn
        Cmd.ofPromise
            app.collabSessionService.disableCollaboration
            sessionId
            ofSuccess
            ofError

    let sendCollaborationUpdate
        (app : AppConfig)
        (model : Model)
        : Cmd<Msg> =

        match model.Session with
        | Some (Fetched (sessionId, _)) ->
            let mapMarkerPositions =
                model.UndoableModel.present.MapMarkers
                |> Map.map (fun _ marker ->
                    let latlng = marker.getLatLng()
                    { latitude = latlng.lat
                      longitude = latlng.lng })
            let newUpdate =
                model
                |> createSaveFromModel

            let ofSuccess _ =
                CollaborationUpdateSent
            let ofError exn =
                CollaborationUpdateFailed exn
            Cmd.ofPromise
                (app.collabSessionService.sendUpdate
                    model.CurrentPlaybackState
                    model.CurrentTime
                    mapMarkerPositions
                    newUpdate)
                sessionId
                ofSuccess
                ofError
        | Some Fetching
        | None ->
            Cmd.none

    /// <summary>
    ///     Adds an Item to a Leaflet map.
    /// </summary>
    ///
    /// <param name="isViewerMode">
    ///     Whether the app is current in viewer (no-edit) mode.
    /// </param>
    ///
    /// <param name="map">
    ///     The loaded Leaflet map to add the Item to.
    /// </param>
    ///
    /// <param name="marker">
    ///     The Leaflet Marker associated to the Item to add to the map.
    /// </param>
    ///
    /// <param name="id">
    ///     The id of the Item's associated Layer.
    /// </param>
    ///
    /// <param name="item">
    ///     The Item to add to the Leaflet map.
    /// </param>
    let addItemToMap
        (isViewerMode : bool)
        (map : Leaflet.Map)
        (marker : Leaflet.Marker<obj>)
        (id : LayerId)
        (item : Item) : Cmd<Msg> =
        addItemToLeafletMap isViewerMode map marker id item |> ignore
        Cmd.none

    /// <summary>
    ///     Adds a list of Items to a Leaflet map.
    /// </summary>
    ///
    /// <param name="isViewerMode">
    ///     Whether the app is current in viewer (no-edit) mode.
    /// </param>
    ///
    /// <param name="map">
    ///     The loaded Leaflet map to add the Item to.
    /// </param>
    ///
    /// <param name="items">
    ///     A list of Items that are to be added to the Leaflet map.
    /// </param>
    let addItemsToMap
        (isViewerMode : bool)
        (map : Leaflet.Map)
        (items : (LayerId * Item * Leaflet.Marker<obj>) list) : Cmd<Msg> =
        items
        |> List.iter (fun (id, item, marker) ->
            addItemToLeafletMap isViewerMode map marker id item |> ignore)
        Cmd.none

    /// <summary>
    ///     Visually updates buildings to match their appropriate building state at a given time.
    /// </summary>
    ///
    /// <param name="currentTime">
    ///     The current time.
    /// </param>
    ///
    /// <param name="layers">
    ///     A list of Layers to work on.
    /// </param>
    let animateBuildingState
        (currentTime : int)
        (layers : Layer list)
        : Cmd<_> =

        let updateOutpostElementSrc
            capturedBy
            (buildingItem : Dota2BuildingItem)
            (element : Browser.Element) =
            let newBuildingItem =
                { buildingItem with
                    state = (BuildingState.Captured capturedBy) }
            let newSrc = (Marker.getDota2BuildingIcon newBuildingItem)
            let currentSrc = element.lastElementChild.getAttribute "src"
            if (newSrc <> currentSrc) then
                element.lastElementChild.setAttribute ("src", newSrc)

        let updateMarkerElementStylesWithState
            isDestroyed
            (element : Browser.Element) =
            if isDestroyed then
                element.classList.add "greyscale"
                |> ignore
            else
                element.classList.remove "greyscale"
                |> ignore

        let updateUnitStyle (unit : LayerUnit) =
            let markerElement =
                "id-" + (unit.id |> LayerId.toString)
                |> Browser.document.getElementById
            match unit.item with
            | Dota2Item (Dota2BuildingItem _)
            | Dota2Item (Dota2WardItem _) ->
                let isDestroyed =
                    isBuildingDestroyedAtThisTime
                        currentTime
                        unit.keyframes
                markerElement
                |> updateMarkerElementStylesWithState isDestroyed
            | Dota2Item (Dota2HeroItem _)
            | Dota2Item (Dota2LaneCreepItem _)
            | Dota2Item (Dota2NeutralCampItem _)
            | Dota2Item (Dota2AbilityItem _)
            | GenericItem _
            | TextItem _ ->
                ()

        let updateOutpostStyleForLayerUnit (unit : LayerUnit) =
            let markerElement =
                "id-" + (unit.id |> LayerId.toString)
                |> Browser.document.getElementById
            match unit.item with
            | Dota2Item (Dota2BuildingItem buildingItem) ->
                match buildingItem.building with
                | Outpost _ ->
                    let capturedBy =
                        whatFactionOwnsBuildingAtThisTime
                            currentTime
                            unit.keyframes
                    markerElement
                    |> updateOutpostElementSrc capturedBy buildingItem
                | building ->
                    ()
            | Dota2Item (Dota2WardItem _)
            | Dota2Item (Dota2HeroItem _)
            | Dota2Item (Dota2LaneCreepItem _)
            | Dota2Item (Dota2NeutralCampItem _)
            | Dota2Item (Dota2AbilityItem _)
            | GenericItem _
            | TextItem _ ->
                ()

        layers
        |> List.iter (fun layer ->
            match layer with
            | LayerUnit unit -> updateUnitStyle unit
            | LayerGroup group ->
                group.children
                |> List.iter updateUnitStyle)

        layers
        |> List.iter (fun layer ->
            match layer with
            | LayerUnit unit -> updateOutpostStyleForLayerUnit unit
            | LayerGroup group ->
                group.children
                |> List.iter updateOutpostStyleForLayerUnit)

        Cmd.none

    /// <summary>
    ///     Ensures all Layers have the correct building state based on the current time.
    /// </summary>
    ///
    /// <param name="currentTime">
    ///     The current time.
    /// </param>
    ///
    /// <param name="layers">
    ///     A list of Layers to work on.
    /// </param>
    let saveAllBuildingStatesToLayers
        (currentTime : int)
        (layers : Layer list) : Cmd<Msg> =
        let saveBuildingStateToLayerUnit (unit : LayerUnit) =
            match unit.item with
            | Dota2Item (Dota2BuildingItem building) ->
                let currentState =
                    match building.building with
                    | Building.Outpost side ->
                        let capturedBy =
                            whatFactionOwnsBuildingAtThisTime
                                currentTime
                                unit.keyframes
                        BuildingState.Captured capturedBy
                    | building ->
                        let isDestroyed =
                            isBuildingDestroyedAtThisTime
                                currentTime
                                unit.keyframes
                        if isDestroyed then BuildingState.Destroyed
                        else BuildingState.NotDestroyed
                let newItem =
                    building
                    |> Dota2BuildingItem.setBuildingState currentState
                    |> Dota2BuildingItem
                    |> Dota2Item
                { unit with item = newItem }
            | Dota2Item (Dota2WardItem ward) ->
                let isDestroyed =
                    isBuildingDestroyedAtThisTime
                        currentTime
                        unit.keyframes
                let currentState =
                    if isDestroyed then BuildingState.Destroyed
                    else BuildingState.NotDestroyed
                let newItem =
                    ward
                    |> Dota2WardItem.setState currentState
                    |> Dota2WardItem
                    |> Dota2Item
                { unit with item = newItem }
            | Dota2Item (Dota2HeroItem _)
            | Dota2Item (Dota2LaneCreepItem _)
            | Dota2Item (Dota2NeutralCampItem _)
            | Dota2Item (Dota2AbilityItem _)
            | GenericItem _
            | TextItem _ ->
                unit
        let saveBuildingStateToLayer layer =
            match layer with
            | LayerUnit unit ->
                saveBuildingStateToLayerUnit unit
                |> LayerUnit
            | LayerGroup group ->
                let newChildren =
                    group.children
                    |> List.map saveBuildingStateToLayerUnit
                { group with children = newChildren }
                |> LayerGroup
        layers
        |> List.map saveBuildingStateToLayer
        |> UpdateAllLayersBuildingStates
        |> Cmd.ofMsg

    /// <summary>
    ///     Adds CSS required to visually indicate selection for a map marker by a LayerId.
    /// </summary>
    ///
    /// <param name="layerId">
    ///     The LayerId to use to find the Leaflet Marker to visually select.
    /// </param>
    let visuallySelectMapMarkerByLayerId (layerId : LayerId) : Cmd<_> =
        let element =
             "id-" + (LayerId.toString layerId)
             |> Browser.document.getElementById
        let maybeTextarea = "textarea" |> element.querySelector
        if element.querySelector "textarea" <> null then
            maybeTextarea.classList.add "selected"
        else
            element.classList.add "selected"
        Cmd.none

    /// <summary>
    ///     Adds CSS required to visually indicate selection for map markers by LayerIds.
    /// </summary>
    ///
    /// <param name="layerIds">
    ///     The LayerIds to use to find the Leaflet Markers to visually select.
    /// </param>
    let visuallySelectMapMarkersByLayerIds (layerIds : LayerId list) : Cmd<Msg> =
        let styleMarkerByLayerId layerId =
            let element =
                 "id-" + (LayerId.toString layerId)
                 |> Browser.document.getElementById
            let maybeTextarea = "textarea" |> element.querySelector
            if element.querySelector "textarea" <> null then
                maybeTextarea.classList.add "selected"
            else
                element.classList.add "selected"
        List.iter styleMarkerByLayerId layerIds
        Cmd.none

    /// Ensures all selected map markers are visually unselected.
    let visuallyUnselectAllMapMarkers () : Cmd<_> =
        let selectedElements =
            ".selected" |> Browser.document.querySelectorAll
        selectedElements
        |> Fable.nodeListOfToList
        |> List.iter (fun element ->
            let maybeTextarea = "textarea" |> element.querySelector
            if element.querySelector "textarea" <> null then
                maybeTextarea.classList.remove "selected"
            else
                element.classList.remove "selected")
        Cmd.none

    let private updateMarkerDraggabilityForLayerUnit
        draggable
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (unit : LayerUnit) =

        let marker = markers |> Map.find unit.id
        match unit.item, marker.dragging with
        | Dota2Item (Dota2HeroItem _), Some dragging
        | Dota2Item (Dota2LaneCreepItem _), Some dragging
        | Dota2Item (Dota2WardItem _), Some dragging
        | Dota2Item (Dota2AbilityItem _), Some dragging
        | GenericItem _, Some dragging
        | TextItem _, Some dragging ->
            if draggable then dragging.enable () |> ignore
            else dragging.disable () |> ignore
        | Dota2Item (Dota2BuildingItem _), _
        | Dota2Item (Dota2NeutralCampItem _), _
        | _, None ->
            ()

    let private updateMarkerDraggabilityForLayer draggable markers (layer : Layer) =
        match layer with
        | LayerUnit unit ->
            unit
            |> (updateMarkerDraggabilityForLayerUnit draggable markers)
        | LayerGroup group ->
            group.children
            |> List.iter (updateMarkerDraggabilityForLayerUnit draggable markers)

    /// <summary>
    ///     Sets the draggability of Markers based on the currently selected tool.
    /// </summary>
    ///
    /// <param name="selectedTool">
    ///     The currently selected tool.
    /// </param>
    ///
    /// <param name="layers">
    ///     A list of Layers to work on.
    /// </param>
    ///
    /// <param name="markers">
    ///     A list of Markers to work on.
    /// </param>
    let updateMarkerDraggabilityBasedOnSelectedTool
        (selectedTool : SidebarTool)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (layers : Layer list)
        : Cmd<Msg> =

        let isDraggable =
            match selectedTool with
            | Panning
            | Redoing
            | Undoing ->
                true
            | Drawing
            | Erasing
            | Measuring StraightMeasuring
            | Measuring RadialMeasuring
            | Texting ->
                false
        layers
        |> List.iter (updateMarkerDraggabilityForLayer isDraggable markers)
        Cmd.none

    /// <summary>
    ///     Disables draggability of Markers.
    /// </summary>
    ///
    /// <param name="layers">
    ///     A list of Layers to work on.
    /// </param>
    ///
    /// <param name="markers">
    ///     A list of Markers to work on.
    /// </param>
    let disableMarkerDraggability
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (layers : Layer list)
        : Cmd<Msg> =

        layers
        |> List.iter (updateMarkerDraggabilityForLayer false markers)

        Cmd.none

    /// <summary>
    ///     Enables draggability of Markers.
    /// </summary>
    ///
    /// <param name="layers">
    ///     A list of Layers to work on.
    /// </param>
    ///
    /// <param name="markers">
    ///     A list of Markers to work on.
    /// </param>
    let enableMarkerDraggability
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (layers : Layer list)
        : Cmd<Msg> =

        layers
        |> List.iter (updateMarkerDraggabilityForLayer true markers)

        Cmd.none

    /// <summary>
    ///     Saves all text marker content to the model.
    /// </summary>
    ///
    /// <param name="layers">
    ///     A list of Layers to save text marker content to.
    /// </param>
    let saveAllTextMarkerContentToModel (layers : Layer list) : Cmd<Msg> =
        layers
        |> saveAllTextMarkerContentToLayers
        |> UpdateAllLayersTextMarkerContent
        |> Cmd.ofMsg

    /// <summary>
    ///     Updates all text markers with content from Layers.
    /// </summary>
    ///
    /// <param name="layers">
    ///     A list of Layers to update text markers with.
    /// </param>
    let updateTextMarkerWithContentFromLayers (layers : Layer list) : Cmd<_> =
        let syncUnitToTextarea (unit : LayerUnit) =
            match unit.item with
            | TextItem text ->
                let markerElement =
                    "id-" + (LayerId.toString unit.id)
                    |> Browser.document.getElementById
                let textareaElement =
                    markerElement.querySelector("textarea")
                    :?> Browser.HTMLTextAreaElement
                textareaElement.value <- text.text
            | _ ->
                ()
        let syncLayerToTextarea layer =
            match layer with
            | LayerUnit unit ->
                unit
                |> syncUnitToTextarea
            | LayerGroup group ->
                group.children
                |> List.iter syncUnitToTextarea
        List.iter syncLayerToTextarea layers
        Cmd.none

    /// <summary>
    ///     Updates textarea CSS based on given SidebarTool.
    /// </summary>
    ///
    /// <param name="tool">
    ///     The given SidebarTool.
    /// </param>
    let updateTextMarkerStylesBasedOnTool (tool : SidebarTool) : Cmd<_> =
        let textareas =
            "textarea"
            |> Browser.document.querySelectorAll
            |> Fable.nodeListOfToList
        match tool with
        | Texting ->
            List.iter
                (Fable.setElementInlineStyleProperty "cursor" "text")
                textareas
        | Drawing
        | Erasing
        | Measuring StraightMeasuring
        | Measuring RadialMeasuring
        | Panning
        | Redoing
        | Undoing ->
            List.iter
                (Fable.setElementInlineStyleProperty "cursor" "pointer")
                textareas
        Cmd.none

    /// <summary>
    ///     Removes any Leaflet circles for a given Layer.
    /// </summary>
    ///
    /// <param name="heroAttackRangeCircles">
    ///     A collection of active hero attack range circles.
    /// </param>
    ///
    /// <param name="wardSightCircles">
    ///     A collection of active ward sight circles.
    /// </param>
    ///
    /// <param name="marker">
    ///     The Leaflet marker to work with.
    /// </param>
    ///
    /// <param name="layer">
    ///     The Layer to delete Leaflet circles for.
    /// </param>
    let deleteLeafletCirclesForLayer
        (heroAttackRangeCircles : HeroAttackRangeCircles)
        (wardSightCircles : WardSightCircles)
        (abilityEffectCircles : AbilityEffectCircles)
        (marker : Leaflet.Marker<obj>)
        (layer : Layer) : Cmd<_> =
        match layer with
        | LayerUnit { item = Dota2Item (Dota2HeroItem _) } ->
            let markerElement = marker.getElement ()
            let markerId =
                match markerElement with
                | Some element ->
                    let matches =
                        (Regex @"id-[a-z0-9\-]+").Matches
                            element.className
                    matches.[0].ToString ()
                | None -> ""
            let circleToRemove =
                let (HeroAttackRangeCircles attackRangeCircles) =
                    heroAttackRangeCircles
                attackRangeCircles
                |> Seq.toList
                |> Seq.tryFind (fun (HeroAttackRangeCircle circle) ->
                    match circle.options.className with
                    | Some className ->
                        className.Contains (markerId.Replace("id-", ""))
                    | None ->
                        false)
            circleToRemove
            |> Option.map (fun (HeroAttackRangeCircle circle) ->
                circle.remove ())
            |> ignore
            Cmd.none
        | LayerUnit { item = Dota2Item (Dota2WardItem _) } ->
            let circleToRemove =
                let (WardSightCircles wardSightCircles) = wardSightCircles
                wardSightCircles
                |> Seq.toList
                |> Seq.tryFind (fun (WardSightCircle circle) ->
                    let markerLatLng = marker.getLatLng ()
                    let circleLatLng = circle.getLatLng ()
                    // If the circle's center is the ward marker,
                    // this is the circle to remove
                    markerLatLng.lat = circleLatLng.lat &&
                    markerLatLng.lng = circleLatLng.lng)
            match circleToRemove with
            | Some (WardSightCircle circle) ->
                circle.remove ()
                |> ignore

                circle
                |> WardSightCircle
                |> DeleteWardSightCircleFromStorage
                |> Cmd.ofMsg
            | None ->
                Cmd.none
        | LayerUnit { item = Dota2Item (Dota2AbilityItem _) } ->
            let circleToRemove =
                let (AbilityEffectCircles abilityEffectCircles) =
                    abilityEffectCircles

                abilityEffectCircles
                |> Seq.toList
                |> Seq.tryFind (fun (AbilityEffectCircle circle) ->
                    let markerLatLng = marker.getLatLng ()
                    let circleLatLng = circle.getLatLng ()
                    // If the circle's center is the ward marker,
                    // this is the circle to remove
                    markerLatLng.lat = circleLatLng.lat &&
                    markerLatLng.lng = circleLatLng.lng)
            match circleToRemove with
            | Some (AbilityEffectCircle circle) ->
                circle.remove ()
                |> ignore

                circle
                |> AbilityEffectCircle
                |> DeleteAbilityEffectCircleFromStorage
                |> Cmd.ofMsg
            | None ->
                Cmd.none
        | LayerUnit { item = Dota2Item (Dota2BuildingItem _) }
        | LayerUnit { item = Dota2Item (Dota2LaneCreepItem _) }
        | LayerUnit { item = Dota2Item (Dota2NeutralCampItem _) }
        | LayerUnit { item = GenericItem _ }
        | LayerUnit { item = TextItem _ }
        | LayerGroup _ ->
            Cmd.none

    /// <summary>
    ///     Removes any Leaflet circles for given Layers.
    /// </summary>
    ///
    /// <param name="heroAttackRangeCircles">
    ///     A collection of active hero attack range circles.
    /// </param>
    ///
    /// <param name="wardSightCircles">
    ///     A collection of active ward sight circles.
    /// </param>
    ///
    /// <param name="markers">
    ///     A map of currently active Leaflet markers
    /// </param>
    ///
    /// <param name="layers">
    ///     The Layers to delete Leaflet circles for.
    /// </param>
    let deleteLeafletCirclesForLayers
        (heroAttackRangeCircles : HeroAttackRangeCircles)
        (wardSightCircles : WardSightCircles)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (layers : Layer list) : Cmd<_> =
        let deleteHeroAttackRangeCirclesForLayerUnit (layerUnit : LayerUnit) =
            match layerUnit.item with
            | Dota2Item (Dota2HeroItem _) ->
                let marker = markers |> Map.find layerUnit.id
                let markerElement = marker.getElement ()
                let markerId =
                    match markerElement with
                    | Some element ->
                        let matches =
                            (Regex @"id-[a-z0-9\-]+").Matches
                                element.className
                        matches.[0].ToString ()
                    | None -> ""
                let circleToRemove =
                    let (HeroAttackRangeCircles attackRangeCircles) =
                        heroAttackRangeCircles
                    attackRangeCircles
                    |> Seq.toList
                    |> Seq.tryFind (fun (HeroAttackRangeCircle circle) ->
                        match circle.options.className with
                        | Some className ->
                            className.Contains (markerId.Replace("id-", ""))
                        | None ->
                            false)
                circleToRemove
                |> Option.map (fun (HeroAttackRangeCircle circle) ->
                    circle.remove ())
                |> ignore
            | Dota2Item (Dota2BuildingItem _)
            | Dota2Item (Dota2LaneCreepItem _)
            | Dota2Item (Dota2NeutralCampItem _)
            | Dota2Item (Dota2AbilityItem _)
            | Dota2Item (Dota2WardItem _)
            | GenericItem _
            | TextItem _ ->
                ()
        let deleteHeroAttackRangeCirclesForLayer layer =
            match layer with
            | LayerUnit unit ->
                unit
                |> deleteHeroAttackRangeCirclesForLayerUnit
            | LayerGroup group ->
                group.children
                |> List.iter deleteHeroAttackRangeCirclesForLayerUnit
        layers
        |> List.iter deleteHeroAttackRangeCirclesForLayer
        let wardSightCirclesToRemove =
            let tryToFindAssociatedWardSightCircleForLayerUnit
                (layerUnit : LayerUnit) =
                match layerUnit.item with
                | Dota2Item (Dota2WardItem _) ->
                    let marker = markers |> Map.find layerUnit.id
                    let (WardSightCircles wardSightCircles) = wardSightCircles
                    wardSightCircles
                    |> Seq.toList
                    |> Seq.tryFind (fun (WardSightCircle circle) ->
                        let markerLatLng = marker.getLatLng ()
                        let circleLatLng = circle.getLatLng ()
                        // Remove if the circle's center same as ward marker center
                        markerLatLng.lat = circleLatLng.lat &&
                        markerLatLng.lng = circleLatLng.lng)
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _
                | TextItem _ ->
                    None
            let tryToFindAssociatedWardSightCircleForLayer layer =
                match layer with
                | LayerUnit layerUnit ->
                    [ layerUnit
                      |> tryToFindAssociatedWardSightCircleForLayerUnit ]
                | LayerGroup layerGroup ->
                    layerGroup.children
                    |> List.map tryToFindAssociatedWardSightCircleForLayerUnit
            layers
            |> List.map tryToFindAssociatedWardSightCircleForLayer
            |> List.concat
            |> List.choose id
        wardSightCirclesToRemove
        |> DeleteWardSightCirclesFromStorage
        |> Cmd.ofMsg

    /// <summary>
    ///     Adds a list of Leaflet Markers to a Leaflet Map.
    /// </summary>
    ///
    /// <param name="markers">
    ///     The Leaflet Markers to add to a Leaflet Map.
    /// </param>
    ///
    /// <param name="map">
    ///     The Leaflet Map to add Leaflet Markers to.
    /// </param>
    let addLeafletMarkersToMap
        (markers : Leaflet.Marker<obj> list)
        (map : Leaflet.Map) : Cmd<_> =
        markers
        |> List.iter (fun marker -> !^map |> marker.addTo |> ignore)
        Cmd.none

    /// <summary>
    ///     Adds a list of Leaflet Polylines to a Leaflet Map.
    /// </summary>
    ///
    /// <param name="polylines">
    ///     The Leaflet Polylines to add to a Leaflet Map.
    /// </param>
    ///
    /// <param name="map">
    ///     The Leaflet Map to add Leaflet Polylines to.
    /// </param>
    let addLeafletPolylinesToMap
        (polylines : Leaflet.Polyline<obj,obj> list)
        (map : Leaflet.Map) : Cmd<_> =
        polylines
        |> List.iter (fun polyline -> !^map |> polyline.addTo |> ignore)
        Cmd.none

    /// <summary>
    ///     Adds a list of Leaflet Circles to a Leaflet Map.
    /// </summary>
    ///
    /// <param name="circles">
    ///     The Leaflet Circles to add to a Leaflet Map.
    /// </param>
    ///
    /// <param name="map">
    ///     The Leaflet Map to add Leaflet Circles to.
    /// </param>
    let addLeafletCirclesToMap
        (circles : Leaflet.Circle<obj> list)
        (map : Leaflet.Map)
        : Cmd<_> =

        circles
        |> List.iter (fun circle -> !^map |> circle.addTo |> ignore)
        Cmd.none


    let updateLeafletMarkerPositions
        (mapMarkerPositions : Map<LayerId, Coordinates>)
        (mapMarkers : Map<LayerId, Leaflet.Marker<obj>>)
        : Cmd<_> =

        mapMarkers
        |> Map.iter (fun layerId leafletMarker ->
            let position = mapMarkerPositions |> Map.tryFind layerId
            position
            |> Option.iter (fun position ->
                leafletMarker.setLatLng !^(position.latitude, position.longitude)
                |> ignore)
            |> ignore)

        Cmd.none

    /// <summary>
    ///     Removes a Leaflet Marker from the Leaflet Map.
    /// </summary>
    ///
    /// <param name="marker">
    ///     The Leaflet Marker to remove from Leaflet Map.
    /// </param>
    let removeLeafletMarkerFromMap (marker : Leaflet.Marker<obj>) : Cmd<_> =
        marker.remove () |> ignore
        Cmd.none

    /// <summary>
    ///     Removes a list of Leaflet Markers from the Leaflet Map.
    /// </summary>
    ///
    /// <param name="markers">
    ///     The Leaflet Markers to remove from Leaflet Map.
    /// </param>
    let removeLeafletMarkersFromMap
        (markers : Leaflet.Marker<obj> list)
        : Cmd<_> =

        markers
        |> List.iter (fun marker -> marker.remove () |> ignore)

        Cmd.none

    /// <summary>
    ///     Removes a list of Leaflet Polylines from the Leaflet Map.
    /// </summary>
    ///
    /// <param name="polylines">
    ///     The Leaflet Polylines to remove from Leaflet Map.
    /// </param>
    let removeLeafletPolylinesFromMap
        (polylines : Leaflet.Polyline<obj,obj> list) : Cmd<_> =
        polylines
        |> List.iter (fun polyline -> polyline.remove () |> ignore)
        Cmd.none

    /// <summary>
    ///     Removes a list of Leaflet Circles from the Leaflet Map.
    /// </summary>
    ///
    /// <param name="circles">
    ///     The Leaflet Circles to remove from Leaflet Map.
    /// </param>
    let removeLeafletCirclesFromMap
        (circles : Leaflet.Circle<obj> list) : Cmd<_> =
        circles
        |> List.iter (fun circle -> circle.remove () |> ignore)
        Cmd.none

    /// <summary>
    ///     Recreates a Leaflet Marker.
    /// </summary>
    ///
    /// <param name="isViewerMode">
    ///     Whether the app is current in viewer (no-edit) mode.
    /// </param>
    ///
    /// <param name="item">
    ///     The Item to repopulate the Leaflet Marker.
    /// </param>
    ///
    /// <param name="layerId">
    ///     The LayerId to set on the Leaflet Marker.
    /// </param>
    ///
    /// <param name="marker">
    ///     The Leaflet Marker to recreate.
    /// </param>
    let recreateLeafletMarker
        (isViewerMode : bool)
        (item : Item)
        (layerId : LayerId)
        (marker : Leaflet.Marker<obj>)
        : Cmd<_> =
        Leaflet.createDivIconFromItem
            (Marker.mapMarker isViewerMode true)
            ("id-" + LayerId.toString layerId)
            item
        |> U2.Case2
        |> marker.setIcon
        |> ignore
        Cmd.none

    /// <summary>
    ///     Recreates a list of Leaflet Markers.
    /// </summary>
    ///
    /// <param name="isViewerMode">
    ///     Whether the app is current in viewer (no-edit) mode.
    /// </param>
    ///
    /// <param name="markers">
    ///     A list of Leaflet Markers along with their associated Item and LayerId to repopulate.
    /// </param>
    let recreateLeafletMarkers
        (isViewerMode : bool)
        (layers : Layer list)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        : Cmd<_> =

        let recreateMarkerForLayerUnit
            (layerUnit : LayerUnit) =
            Leaflet.createDivIconFromItem
                (Marker.mapMarker isViewerMode layerUnit.visible)
                ("id-" + LayerId.toString layerUnit.id)
                layerUnit.item

        let recreateMarkerWithGroupVisibility
            (groupIsVisible : bool)
            (layerUnit : LayerUnit) =

            let isVisible =
                // if group is visible, then use the layerUnit's visibility
                // if group is not visible, hide all layerUnits' visibility
                match groupIsVisible with
                | true -> layerUnit.visible
                | false -> false

            Leaflet.createDivIconFromItem
                (Marker.mapMarker isViewerMode isVisible)
                ("id-" + LayerId.toString layerUnit.id)
                layerUnit.item

        layers
        |> List.iter (fun layer ->
            match layer with
            | LayerUnit unit ->
                let marker = markers |> Map.find unit.id
                recreateMarkerForLayerUnit unit
                |> U2.Case2
                |> marker.setIcon
                |> ignore
            | LayerGroup group ->
                group.children
                |> List.iter (fun unit ->
                    let marker = markers |> Map.find unit.id
                    recreateMarkerWithGroupVisibility group.visible unit
                    |> U2.Case2
                    |> marker.setIcon
                    |> ignore)
        )
        Cmd.none

    /// <summary>
    ///     Ensures the position of Leaflet Markers are up to date.
    /// </summary>
    ///
    /// <param name="map">
    ///     A Leaflet map.
    /// </param>
    ///
    /// <param name="markers">
    ///     A list of Leaflet Markers.
    /// </param>
    let syncPositionOfLeafletMarkersFromModel
        (map : Leaflet.Map)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        : Cmd<_> =

        markers
        |> Map.iter (fun id marker ->
            marker.setLatLng !^(marker.getLatLng())
            |> ignore
            marker.addTo !^map
            |> ignore)

        Cmd.none

    /// <summary>
    ///     Toggle the visibility of attack range circles for a given layer.
    /// </summary>
    ///
    /// <param name="map">
    ///     The Leaflet Map to add building attack range circles to.
    /// </param>
    ///
    /// <param name="attackRangeState">
    ///     The current visibility state of the attack range circle.
    /// </param>
    ///
    /// <param name="marker">
    ///     The current visibility state of the attack range circle.
    /// </param>
    let toggleAttackRangeCircleVisibilityForLayer
        (map : Leaflet.Map)
        (attackRangeCircleState : AttackRangeCircleVisibility)
        (marker : Leaflet.Marker<obj>)
        (layer : Layer)
        : Cmd<_> =

        match layer with
        | LayerUnit
            { item = Dota2Item (Dota2BuildingItem building) } ->
            let layerId =
                "id-" + (layer |> Layer.getId |> LayerId.toString)
            let (BuildingAttackRangeCircles buildingAttackRangeCircles) =
                buildingAttackRangeCircles
            if attackRangeCircleState = AttackRangeCircleVisibility.Visible then
                match (building.building |> Dota2.Building.getAttack) with
                | Some attack ->
                    let attackRangeCircle =
                        Leaflet.createAttackRadiusCircle
                            layerId
                            (marker.getLatLng ())
                            (Leaflet.convertDota2DistanceToLatLngDistance
                                attack.range)
                            1.
                    attackRangeCircle
                    |> BuildingAttackRangeCircle
                    |> buildingAttackRangeCircles.Add
                    !^map |> attackRangeCircle.addTo |> ignore
                | None ->
                    ()
            else
                buildingAttackRangeCircles
                |> Seq.toList
                |> List.iter (fun (BuildingAttackRangeCircle circle) ->
                    let circleElement = circle.getElement ()
                    let circleId =
                        match circleElement with
                        | Some element ->
                            let matches =
                                (Regex @"id-[a-z0-9\-]+").Matches
                                    element.className?baseVal
                            matches.[0].ToString ()
                        | None -> ""
                    if circleId = layerId then
                        circle.remove () |> ignore
                        circle
                        |> BuildingAttackRangeCircle
                        |> buildingAttackRangeCircles.Remove
                        |> ignore)
            Cmd.none
        | LayerUnit { item = Dota2Item (Dota2HeroItem _) } ->
            let layerIdString = layer |> Layer.getId |> LayerId.toString
            let circleId = layerIdString.Replace ("id-", "circle-")
            let circleElements =
                Browser.document.getElementsByClassName circleId
            if circleElements.length > 0. then
                let circleElement = circleElements.Item(0)
                let circlePath = circleElement :?> Browser.SVGClipPathElement
                let opacity =
                    match attackRangeCircleState with
                    | AttackRangeCircleVisibility.Visible -> "0.9"
                    | AttackRangeCircleVisibility.NotVisible -> "0.0"
                circlePath.attributes.item(2.).value <- opacity
            Cmd.none
        | LayerUnit { item = Dota2Item (Dota2HeroItem _) }
        | LayerUnit { item = Dota2Item (Dota2LaneCreepItem _) }
        | LayerUnit { item = Dota2Item (Dota2NeutralCampItem _) }
        | LayerUnit { item = Dota2Item (Dota2WardItem _) }
        | LayerUnit { item = Dota2Item (Dota2AbilityItem _) }
        | LayerUnit { item = GenericItem _ }
        | LayerUnit { item = TextItem _ }
        | LayerGroup _ ->
            Cmd.none

    /// <summary>
    ///     Saves the current session via the SessionStoreService.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="user">
    ///     The current authenticated user.
    /// </param>
    ///
    /// <param name="successNotification">
    ///     The notification to show while saving.
    /// </param>
    ///
    /// <param name="model">
    ///     The current model.
    /// </param>
    let saveCurrentSession
        (app : AppConfig)
        (user : AuthenticatedUser)
        (progressNotification : Notification)
        (model : Model)
        : Cmd<Msg> =

        let save =
            model
            |> createSaveFromModel

        Fable.Import.JS.console.log save

        match model.Session with
        // Existing session loaded - save to this session.
        | Some (Fetched (sessionId, session)) ->
            let ofSuccess (sessionId, savedTimeInSeconds) =
                let newSession =
                    let newLastSavedAt =
                        savedTimeInSeconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                    { session with lastSavedAt = newLastSavedAt }
                SessionSaved (sessionId, newSession, progressNotification)
            let ofError exn =
                SaveSessionFailed (exn, progressNotification)
            Cmd.ofPromise
                (app.sessionStoreService.saveToSession save)
                sessionId
                ofSuccess
                ofError

        // Brand new session - save a new session.
        | None ->
            let ofSuccess (id, session) =
                SessionCreated (id, session)
            let ofError exn =
                SessionOperationFailed exn
            Cmd.ofPromise
                (app.sessionStoreService.createSession user model.Game None)
                save
                ofSuccess
                ofError

        // Session is loading
        | Some Fetching ->
            Cmd.none

    /// <summary>
    ///    Makes a given session private via the SessionStoreService.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="sessionId">
    ///     The session to be made private.
    /// </param>
    let makeSessionPrivate
        (app : AppConfig)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess id =
            SessionMadePrivate id
        let ofError exn =
            SessionOperationFailed exn
        Cmd.ofPromise
            app.sessionStoreService.makeSessionPrivate
            sessionId
            ofSuccess
            ofError

    /// <summary>
    ///    Makes a given session public via the SessionStoreService.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="sessionId">
    ///     The session to be made public.
    /// </param>
    let makeSessionPublic
        (app : AppConfig)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess id =
            SessionMadePublic id
        let ofError exn =
            SessionOperationFailed exn
        Cmd.ofPromise
            app.sessionStoreService.makeSessionPublic
            sessionId
            ofSuccess
            ofError

    /// <summary>
    ///    Renames a given session via the SessionStoreService.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="newName">
    ///     The new name to give to the session.
    /// </param>
    ///
    /// <param name="sessionId">
    ///     The session to be renamed.
    /// </param>
    let renameSession
        (app : AppConfig)
        (newName : string)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess (id, _) =
            SessionRenamed (id, newName)
        let ofError exn =
            SessionOperationFailed exn
        Cmd.ofPromise
            (app.sessionStoreService.renameSession newName)
            sessionId
            ofSuccess
            ofError

    /// <summary>
    ///    Renames a given session via the SessionStoreService.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="sessionId">
    ///     The session to be deleted.
    /// </param>
    let deleteSession
        (app : AppConfig)
        (sessionId : SessionId)
        : Cmd<Msg> =

        let ofSuccess () =
            SessionDeleted sessionId
        let ofError exn =
            SessionOperationFailed exn
        Cmd.ofPromise
            app.sessionStoreService.deleteSession
            sessionId
            ofSuccess
            ofError

    let dismissNotificationLater notification : Cmd<Msg> =
        let delay duration =
            promise {
                do! Promise.sleep duration
                return 1.
            }
        let defaultNotificationAutoDismissTime = 5000
        let ofSuccess _ =
            DismissNotification notification
        let ofError _ =
            Error
        Cmd.ofPromise
            delay
            defaultNotificationAutoDismissTime
            ofSuccess
            ofError

    let disableDraggingOnMarkers
        (markers : Leaflet.Marker<obj> list) : Cmd<Msg> =
        markers
        |> List.iter (fun marker ->
            marker.dragging
            |> Option.iter (fun dragging -> dragging.disable () |> ignore)
            |> ignore)
        Cmd.none

/// <summary>
///     Initialises the page.
/// </summary>
///
/// <param name="app">
///     App config, including injected services.
/// </param>
///
/// <param name="optionalUser">
///     The currently authenticated user.
/// </param>
///
/// <param name="game">
///     The current game.
/// </param>
///
/// <param name="isDemoMode">
///     Force Strategiser into demo mode.
/// </param>
///
/// <param name="isMapViewerMode">
///     Force Strategiser into map viewer mode.
/// </param>
///
/// <param name="isSessionViewerMode">
///     Force Strategiser into session viewer mode.
/// </param>
///
/// <param name="sessionId">
///     The sessionId to load.
/// </param>
let init
    (app : AppConfig)
    (optionalUser : AuthenticatedUser option)
    (game : Game)
    (mode : Mode)
    (sessionId : SessionId option)
    : Model * Cmd<Msg> =

    let isFreshSignUp =
        match LocalStorageService.loadSignedUpDate () with
        | Some signUpDate ->
            let difference =
                DateTimeOffset.op_Subtraction (DateTimeOffset.Now, signUpDate)
            difference.TotalMinutes < 5.
        | None ->
            false

    let isNewSession =
        sessionId.IsNone && mode |> Mode.isDemoMode |> not

    let currentModal =
        match mode with
        | DemoMode ->
            DemoWelcomeModal
        | ViewerMode MapViewer ->
            MapViewerWelcomeModal
        | _ ->
            NoModal

    { AbilityEffectCircles =
          new ResizeArray<AbilityEffectCircle>() |> AbilityEffectCircles
      ActiveModifierKeys = []
      CollaborationUpdateListenerIsActive = false
      CreateMapMarkersForExistingLayers = false
      CreateMapMarkersForNewLayers = false
      CurrentMode = mode
      CurrentPlaybackState = Paused
      CurrentTime = 0
      CurrentSidebarTab = None
      CurrentSidebarTool = Panning
      DemoTimer = None
      EditingLaneCreepMeetingPoints = false
      ExistingSessions = None
      Game = game
      HeroAttackRangeCircles =
          new ResizeArray<HeroAttackRangeCircle>() |> HeroAttackRangeCircles
      HeroFilterString = ""
      IsLocked = false
      LayerBeingRenamed = None
      LeavingStrategiserListener = None
      LoadSessionPanelActivated = false
      Map = Loading
      MapTileUrl =
          "https://storage.googleapis.com/tiles-bucket" +
          "/dota2/7.24/{z}/tile_{x}_{y}.jpg"
      Notifications = []
      OnlineCollaborativeSessionViewers = []
      OpenedModal = currentModal
      PopulateStaticMarkers = isNewSession
      SavingSession = false
      SelectedKeyframe = NotSelected
      SelectedLayers = []
      Session = (if isNewSession then None else Some Fetching)
      SessionNotFound = false
      SessionViewersListenerIsActive = false
      StartedCollaborationSessionAt = DateTimeOffset.Now
      TimelineDuration = if (mode |> Mode.isDemoMode) then 15000 else 30000
      TimelineMarksPerSecond = 20
      TimelineOpened = mode |> Mode.isViewerMode |> not
      TimelineZoomLevel = TimelineZoomLevel.DefaultZoom
      TogglingCollaborationMode = false
      UndoableModel =
          { DrawingStrokes = Map.empty
            Layers = []
            LinearRulers = Map.empty
            MapMarkers = Map.empty
            RadialRulers = Map.empty }
          |> History.create
      UserMadeChanges = false
      UserJustSignedUp = isFreshSignUp
      WardSightCircles =
          new ResizeArray<WardSightCircle>() |> WardSightCircles },

    match mode, sessionId with
     | ViewerMode _, Some sessionId
     | EditorMode _, Some sessionId ->
         Cmd.fetchSession app sessionId
     | DemoMode, None ->
        Cmd.fetchDemoSession app
     // Ignore demo mode with a sessionId
     | DemoMode, Some _
     | ViewerMode _, None
     | EditorMode _, None ->
         Cmd.none

/// <summary>
///     Updates the model in response to a message.
/// </summary>
///
/// <param name="app">
///     App config, including injected services.
/// </param>
///
/// <param name="user">
///     The currently authenticated user.
/// </param>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="currentModel">
///     The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (optionalUser : AuthenticatedUser option)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> * Cmd<GlobalMsg> =

    // A bunch of functions related to updating the model

    let isMultiSelectModeEnabled activeModifierKeys =
        activeModifierKeys |> List.contains "Control"
     || activeModifierKeys |> List.contains "Meta"
     || activeModifierKeys |> List.contains "Shift"

    let updateLayerUnitInLayers (updatedLayerUnit : LayerUnit) layers =
        layers
        |> List.map (fun layer ->
            match layer with
            | LayerUnit unit when unit.id = updatedLayerUnit.id ->
                updatedLayerUnit |> LayerUnit
            | LayerUnit _ -> layer
            | LayerGroup group ->
                let hasUpdatedChildren group =
                    group.children
                    |> List.exists (fun child -> updatedLayerUnit.id = child.id)
                if hasUpdatedChildren group then
                    let newChildren =
                        group.children
                        |> List.map (fun child ->
                            if updatedLayerUnit.id = child.id then
                                updatedLayerUnit
                            else child)
                    { group with children = newChildren }
                    |> LayerGroup
                else
                    layer)

    let createLayerGroupFromLayers name perpetual layers =
        let chooseUnitLayers layer =
            match layer with
            | LayerUnit unit -> Some unit
            | LayerGroup _ -> None
        let unitLayers = List.choose chooseUnitLayers layers
        let children =
            if perpetual then List.map LayerUnit.perpetualise unitLayers
            else unitLayers
        LayerGroup
            { children = children
              id = Guid.NewGuid () |> LayerId.ofGuid
              locked = false
              opened = false
              perpetual = perpetual
              name = name
              visible = true }

    let deleteLayerFromLayersById
        timelineLayerIdToDelete
        timelineLayers =
        let deleteLayer layerIdToDelete layer =
            layerIdToDelete <> Layer.getId layer
        let deleteUnit layerIdToDelete (unit : LayerUnit) =
            layerIdToDelete <> unit.id
        let deleteLayerFromGroup layerToDelete layer =
            match layer with
            | LayerGroup group ->
                let newChildren =
                    List.filter (deleteUnit layerToDelete) group.children
                LayerGroup { group with children = newChildren }
            | LayerUnit _ ->
                layer
        timelineLayers
        |> List.filter (deleteLayer timelineLayerIdToDelete)
        |> List.map (deleteLayerFromGroup timelineLayerIdToDelete)

    let deleteLayerFromLayers
        timelineLayerToDelete
        timelineLayers =
        deleteLayerFromLayersById
            (Layer.getId timelineLayerToDelete)
            timelineLayers

    let updateLayers layersToUpdate layers =
        let updateLayer layer =
            let isLayerUpdated updatedLayer =
                let timelineLayerId = Layer.getId layer
                let updatedLayerId = Layer.getId updatedLayer
                timelineLayerId = updatedLayerId
            let updatedLayer =
                List.tryFind isLayerUpdated layersToUpdate
            match updatedLayer with
            | Some updatedLayer ->
                updatedLayer
            | None ->
                layer
        List.map updateLayer layers

    let toggleLayerVisibility toggledLayer markers layer =
        let layerMatchesToggledLayer toggledLayer layer =
            Layer.getId layer = Layer.getId toggledLayer
        match layer with
        | LayerGroup group ->
            if layerMatchesToggledLayer toggledLayer (LayerGroup group) then
                let updateChild (child : LayerUnit) =
                    { child with visible = not group.visible }
                let newChildren =
                    List.map updateChild group.children
                { group with
                    children = newChildren
                    visible = not group.visible }
                |> LayerGroup
            else
                let updateChildUnitVisibility
                    toggledLayer
                    (unit : LayerUnit) =
                    if layerMatchesToggledLayer toggledLayer (LayerUnit unit) then
                        { unit with visible = not unit.visible }
                    else unit
                let newChildren =
                    List.map
                        (updateChildUnitVisibility toggledLayer)
                        group.children
                { group with children = newChildren }
                |> LayerGroup
        | LayerUnit _ ->
            if layerMatchesToggledLayer toggledLayer layer then
                let updatedVisibility =
                    layer
                    |> Layer.isVisible
                    |> not
                Layer.setVisibility
                    updatedVisibility
                    layer
            else layer

    let toggleLayerLocking toggledLayer layer =
        let layerMatchesToggledLayer toggledLayer layer =
            Layer.getId layer = Layer.getId toggledLayer
        match layer with
        | LayerGroup group ->
            if layerMatchesToggledLayer toggledLayer (LayerGroup group) then
                let updateChild (child : LayerUnit) =
                    { child with locked = not group.locked }
                let newChildren =
                    List.map updateChild group.children
                { group with
                    children = newChildren
                    locked = not group.locked }
                |> LayerGroup
            else
                let updateChildUnitLocked toggledLayer (unit : LayerUnit) =
                    if layerMatchesToggledLayer toggledLayer (LayerUnit unit) then
                        { unit with locked = not unit.locked }
                    else unit
                let newChildren =
                    List.map (updateChildUnitLocked toggledLayer) group.children
                { group with children = newChildren }
                |> LayerGroup
        | LayerUnit _ ->
            if layerMatchesToggledLayer toggledLayer layer then
                Layer.toggleLocked layer
            else layer

    let matchLayerByIdString layerId layer =
        layerId = (layer |> Layer.getId |> LayerId.toString)
    let matchLayerToLayer layer1 layer2 =
        Layer.getId layer1 = Layer.getId layer2
    let matchLayerToUnit layer unit =
        Layer.getId layer = LayerUnit.getId unit
    let removeUnit unit1 unit2 =
        LayerUnit.getId unit1 <> LayerUnit.getId unit2
    let getLayerGroups layer =
        match layer with
        | LayerGroup group -> Some group
        | LayerUnit _ -> None
    let getGroupContainingChild child (group : LayerGroup) =
        group.children
        |> List.map LayerUnit.getId
        |> List.contains (Layer.getId child)

    let moveLayerAfterIndex
        movedLayer
        targetLayer
        targetIndex
        indexOffset
        timelineLayers =
        let layersWithoutMoved =
            deleteLayerFromLayers
                movedLayer
                timelineLayers
        match movedLayer, targetIndex with
        | LayerGroup _, Some index
        | LayerUnit _, Some index ->
            let listBeforeTarget, listAfterTarget =
                List.splitAt
                    (if index + indexOffset > layersWithoutMoved.Length then
                        layersWithoutMoved.Length
                     else index + indexOffset)
                    layersWithoutMoved
            List.append
               (List.append listBeforeTarget [movedLayer])
               listAfterTarget
        | LayerUnit targetUnit, None ->
            let group =
                timelineLayers
                |> List.choose getLayerGroups
                |> List.find (getGroupContainingChild targetLayer)
            let targetIndex =
                List.findIndex (matchLayerToUnit targetLayer) group.children
            let childrenWithoutMoved =
                List.filter (removeUnit targetUnit) group.children
            let listBeforeTarget, listAfterTarget =
                let offsetIndex = targetIndex + indexOffset
                let adjustedIndex =
                    if offsetIndex > childrenWithoutMoved.Length then
                        childrenWithoutMoved.Length
                    else offsetIndex
                List.splitAt adjustedIndex childrenWithoutMoved
            let newChildren =
                List.append
                   (List.append listBeforeTarget [targetUnit])
                   listAfterTarget
            let newGroup =
                { group with children = newChildren }
                |> LayerGroup
            timelineLayers
            |> deleteLayerFromLayers movedLayer
            |> updateLayers [newGroup]
        | LayerGroup _, None ->
            timelineLayers

    let moveLayerBeforeLayerId movedLayer targetLayerId layers =
        let targetLayer =
            layers
            |> Layer.flattenList
            |> List.find (matchLayerByIdString targetLayerId)
        let targetIndex =
            List.tryFindIndex (matchLayerToLayer targetLayer) layers
        moveLayerAfterIndex
            movedLayer
            targetLayer
            targetIndex
            0
            layers

    let moveLayerAfterLayerId movedLayer targetLayerId layers =
        let targetLayer =
            layers
            |> Layer.flattenList
            |> List.find (matchLayerByIdString targetLayerId)
        let targetIndex =
            List.tryFindIndex (matchLayerToLayer targetLayer) layers
        moveLayerAfterIndex
            movedLayer
            targetLayer
            targetIndex
            0
            layers

    let moveLayerIntoGroupId movedLayer groupId layers =
        let target =
            layers
            |> Layer.flattenList
            |> List.find (matchLayerByIdString groupId)
        match movedLayer, target with
        | LayerUnit movedUnit, LayerGroup group ->
            let newGroup =
                { group with
                    children = List.append group.children [movedUnit] }
                |> LayerGroup
            layers
            |> deleteLayerFromLayers movedLayer
            |> updateLayers [newGroup]
        | LayerGroup _, LayerGroup _
        | LayerGroup _, LayerUnit _
        | LayerUnit _, LayerUnit _ ->
            layers

    let updateLayerGroupOpened (toggledGroup : LayerGroup) layer =
        if toggledGroup.id = Layer.getId layer then
            let newGroup =
                LayerGroup.setOpened
                    (not toggledGroup.opened)
                    toggledGroup
            newGroup |> LayerGroup
        else layer

    let chooseBuildingLayers layer =
        match layer with
        | LayerUnit unit ->
            match unit.item with
            | Dota2Item (Dota2BuildingItem _) ->
                Some layer
            | _ ->
                None
        | LayerGroup _ -> None

    let chooseNeutralCampLayers layer =
        match layer with
        | LayerUnit unit ->
            match unit.item with
            | Dota2Item (Dota2NeutralCampItem _) ->
                Some layer
            | _ ->
                None
        | LayerGroup _ -> None

    let trackAddItemToMap itemAdded =
        match itemAdded with
        | Dota2Item (Dota2HeroItem _)
        | Dota2Item (Dota2LaneCreepItem _)
        | Dota2Item (Dota2WardItem _)
        | Dota2Item (Dota2AbilityItem _)
        | GenericItem _
        | TextItem _ ->
            itemAdded
            |> UserAction.DroppedMarkerOnMap
            |> AnalyticsCmd.strategiserAction app
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2NeutralCampItem _) ->
            Cmd.none

    let updateLayersAfterMoveMarkerMsg
        currentTime
        (leafletEvent : LeafletEvent)
        movedLayerId
        layers =

        let updateMovedLayerById layerId layer =
            match layer with
            | LayerUnit layerUnit when layerUnit.id = layerId ->
                let latLngFromEvent : LatLng = leafletEvent.target?_latlng
                let (_, prevKeyframeInfo) =
                    layerUnit.keyframes
                    |> Map.toList
                    |> List.rev
                    |> List.find (fun (time, _) ->
                        time < currentTime || time = 0)
                let newKeyframes =
                    layerUnit.keyframes
                    |> Map.add
                        currentTime
                        { prevKeyframeInfo with
                              position = { latitude = latLngFromEvent.lat
                                           longitude = latLngFromEvent.lng } }
                { layerUnit with keyframes = newKeyframes }
                |> LayerUnit
            | LayerUnit _
            | LayerGroup _ ->
                layer
        layers
        |> List.map (updateMovedLayerById movedLayerId)

    let updatePositionOfMovedLeafletMarker
        (leafletEvent : LeafletEvent)
        (movedLayerID : LayerId)
        (leafletMarkers : Map<LayerId, Leaflet.Marker<obj>>) =

        let leafletMarker = leafletMarkers |> Map.find movedLayerID
        let latLngFromEvent : LatLng = leafletEvent.target?_latlng
        leafletMarker.setLatLng !^latLngFromEvent
        |> ignore

        leafletMarkers |> Map.add movedLayerID leafletMarker

    let addGreyKeyframeToKeyframes
        (timeToAdd : int)
        (keyframes : Strategiser.Keyframes) : Strategiser.Keyframes =
        let (_, prevKeyframeInfo) =
            keyframes
            |> Map.toList
            |> List.rev
            |> List.find (fun (time, _) -> time < timeToAdd || time = 0)
        keyframes
        |> Map.map (fun time keyframeInfo ->
            if time < timeToAdd then
                { keyframeInfo with isGrey = false }
            else
                { keyframeInfo with isGrey = true })
        |> Map.add timeToAdd { prevKeyframeInfo with isGrey = true }

    let addNewCapturedStateKeyframeToKeyframes
        (timeToAdd : int)
        (newCapturedState : BuildingState)
        (keyframes : Strategiser.Keyframes) : Strategiser.Keyframes =
        let (_, prevKeyframeInfo) =
            keyframes
            |> Map.toList
            |> List.rev
            |> List.find (fun (time, _) -> time < timeToAdd || time = 0)
        let capturedBy =
            match newCapturedState with
            | BuildingState.Captured FactionType.Radiant ->
                FactionType.Radiant
            | _ ->
                FactionType.Dire
        keyframes
        |> Map.add timeToAdd { prevKeyframeInfo with capturedBy = capturedBy }

    let clearGreyKeyframesFromKeyframes
        (keyframes : Strategiser.Keyframes) : Strategiser.Keyframes =
        keyframes
        |> Map.map (fun _ keyframeInfo -> { keyframeInfo with isGrey = true })

    let filterLayerFromLayers layerToFilter layers =
        layers
        |> List.filter (fun layer -> layerToFilter <> layer)

    let filterLayersFromLayers layersToFilter layers =
        layers
        |> List.filter (fun layer ->
            layersToFilter
            |> List.contains layer
            |> not)

    let filterLayerWithinGroupsFromLayers layerToIgnore layers =
        layers
        |> List.map (fun layer ->
            match layer, layerToIgnore with
            | LayerGroup group, LayerUnit childToDelete ->
                let newChildren =
                    group.children
                    |> List.filter (fun child -> child <> childToDelete)
                { group with children = newChildren }
                |> LayerGroup
            | LayerGroup _, LayerGroup _
            | LayerUnit _, _ ->
                layer)

    let filterLayersWithinGroupsFromLayers layersToIgnore layers =
        let filterUnitsFromUnits layersToFilter children =
            children
            |> List.filter (fun child ->
                layersToFilter
                |> List.exists (fun filterLayer ->
                    match filterLayer with
                    | LayerUnit filterUnit -> filterUnit = child
                    | LayerGroup _ -> false)
                |> not)
        layers
        |> List.map (fun layer ->
            match layer with
            | LayerGroup group ->
                let newChildren =
                    group.children
                    |> filterUnitsFromUnits layersToIgnore
                { group with children = newChildren }
                |> LayerGroup
            | LayerUnit _ ->
                layer)

    let createRenamedLayer renamedLayer layer =
        let renamedLayerId = renamedLayer |> Layer.getId
        let currentLayerId = layer |> Layer.getId
        match layer, renamedLayer with
        | LayerUnit _, LayerUnit _
        | LayerGroup _, LayerGroup _ ->
            if currentLayerId = renamedLayerId then renamedLayer
            else layer
        | LayerGroup group, LayerUnit renamedChild ->
            let newChildren =
                group.children
                |> List.map (fun child ->
                    if child.id = renamedLayerId then renamedChild
                    else child)
            { group with children = newChildren }
            |> LayerGroup
        | LayerUnit _, LayerGroup _ ->
            layer

    let getTheDifferenceBetweenMaps mapB mapA =
        mapA
        |> Map.filter (fun key _ ->
            mapB
            |> Map.containsKey key
            |> not)
        |> Map.toList
        |> List.map snd

    let performUndoOrRedoWithNewUndoableModel map newUndoableModel =
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        let markersToAdd =
            newModel.UndoableModel.present.MapMarkers
            |> getTheDifferenceBetweenMaps
               currentModel.UndoableModel.present.MapMarkers
        let markersToRemove =
            currentModel.UndoableModel.present.MapMarkers
            |> getTheDifferenceBetweenMaps
                newModel.UndoableModel.present.MapMarkers
        let cmds =
            [ Cmd.ofMsg Pause
              Cmd.addLeafletMarkersToMap markersToAdd map
              Cmd.removeLeafletMarkersFromMap markersToRemove
              Cmd.updateTextMarkerWithContentFromLayers
                  newModel.UndoableModel.present.Layers
              Cmd.recreateLeafletMarkers
                (currentModel.CurrentMode |> Mode.isViewerMode)
                newModel.UndoableModel.present.Layers
                newModel.UndoableModel.present.MapMarkers ]
        newModel, Cmd.batch cmds, Cmd.none

    let addNotificationToModel notification model =
        { model with Notifications = notification :: model.Notifications }

    let createSessionCreatedNotification sessionId =
        { id = Guid.NewGuid ()
          title = None
          message = "Session has been created."
          timeout = None }
        |> DefaultNotification

    let createSessionSavingNotification () =
        { id = Guid.NewGuid ()
          title = None
          message = "Saving session..."
          timeout = None }
        |> DefaultNotification

    let createUpgradePromptNotification () =
        { id = Guid.NewGuid ()
          title = "Demo Mode" |> Some
          message = "Upgrade to premium to access the full suite of tools!"
          timeout = None }
        |> ErrorNotification

    let createAppLockedNotification () =
        { id = Guid.NewGuid ()
          title = "Locked" |> Some
          message = "You cannot do that right now."
          timeout = None }
        |> ErrorNotification

    let createSessionSavedNotification sessionId =
        { id = Guid.NewGuid ()
          title = None
          message = "Session has been saved."
          timeout = None }
        |> DefaultNotification

    let createSessionMadePublicNotification sessionId =
        { id = Guid.NewGuid ()
          title = "Session has been made public." |> Some
          message = "Any logged-in user with the link will be able to view the session but not make any edits."
          timeout = None }
        |> DefaultNotification

    let createSessionMadePrivateNotification sessionId =
        { id = Guid.NewGuid ()
          title = "Session has been made private." |> Some
          message = "Only you can access this session."
          timeout = None }
        |> DefaultNotification

    let createSessionSharedNotification sessionId =
        { id = Guid.NewGuid ()
          title = None
          message = "Session link has been copied to your clipboard."
          timeout = None }
        |> DefaultNotification

    let createSessionRenamedNotification () =
        { id = Guid.NewGuid ()
          title = None
          message = "Session has been renamed."
          timeout = None }
        |> DefaultNotification

    let createSessionLoadedNotification isViewerMode sessionId =
        let message =
            if isViewerMode then
                "Session loaded in viewer mode, no edits can be made."
            else "Session has been loaded."
        { id = Guid.NewGuid ()
          title = None
          message = message
          timeout = None }
        |> DefaultNotification

    let createSessionDeletedNotification () =
        { id = Guid.NewGuid ()
          title = None
          message = "Session has been deleted."
          timeout = None }
        |> DefaultNotification

    let createExceptionOccuredNotification (exn : exn) =
        { id = Guid.NewGuid ()
          title = None
          message = exn.Message
          timeout = None }
        |> ErrorNotification

    let moveToNextKeyframe (layerUnit, currentTime) =
        let tryFindNextKeyframe
            (layerUnit : LayerUnit)
            currentTime : (int * KeyframeableAttributes) option =
            layerUnit.keyframes
            |> Map.toList
            |> List.tryFind (fun (time, _) -> time > currentTime)
        match tryFindNextKeyframe layerUnit currentTime with
        | Some (time,_) ->
            (layerUnit, time)
            |> SelectKeyframe
            |> Cmd.ofMsg
        | None ->
            Cmd.none

    let moveToPreviousKeyframe (layerUnit, currentTime) =
        let tryFindPreviousKeyframe
            (layerUnit : LayerUnit)
            currentTime : (int * KeyframeableAttributes) option =
            layerUnit.keyframes
            |> Map.toList
            |> List.rev
            |> List.tryFind (fun (time, _) -> time < currentTime && time <> 0)
        match tryFindPreviousKeyframe layerUnit currentTime with
        | Some (time,_) ->
            (layerUnit, time)
            |> SelectKeyframe
            |> Cmd.ofMsg
        | None ->
            Cmd.none

    let keyboardShortcutHandler model (event : Browser.KeyboardEvent) =
        let target = event.target :?> Browser.Element
        let targetIsInputOrTextarea =
            target.tagName.ToLower () = "input" || target.tagName.ToLower () = "textarea"
        if not targetIsInputOrTextarea then
            let cmds =
                match event.key, model.CurrentMode with
                // Shortcuts only available in non-viewer mode
                | "Delete", DemoMode
                | "Delete", EditorMode _
                | "Backspace", DemoMode
                | "Backspace", EditorMode _ ->
                    match model.Map, model.SelectedKeyframe with
                    | Loaded _, Selected selectedKeyframe ->
                        [ Pause |> Cmd.ofMsg
                          selectedKeyframe |> DeleteKeyframe |> Cmd.ofMsg ]
                    | Loaded _, NotSelected
                    | Loading, Selected _
                    | Loading, NotSelected ->
                        []
                | "j", DemoMode
                | "j", EditorMode _ ->
                    match model.SelectedKeyframe with
                    | Selected selectedKeyframe ->
                        [ selectedKeyframe |> moveToPreviousKeyframe ]
                    | NotSelected ->
                        []
                | "k", DemoMode
                | "k", EditorMode _ ->
                    match model.SelectedKeyframe with
                    | Selected selectedKeyframe ->
                        [ selectedKeyframe |> moveToNextKeyframe ]
                    | NotSelected ->
                        []
                | "s", DemoMode
                | "s", EditorMode _ ->
                    if event.metaKey || event.ctrlKey then
                        [ optionalUser
                          |> Option.map (SaveSession >> Cmd.ofMsg)
                          |> Option.defaultValue Cmd.none ]
                    else []
                | "y", DemoMode
                | "y", EditorMode _ ->
                    if event.metaKey || event.ctrlKey then
                        [ Redo |> Cmd.ofMsg ]
                    else []
                | "Z", DemoMode
                | "Z", EditorMode _
                | "z", DemoMode
                | "z", EditorMode _ ->
                    if (event.metaKey || event.ctrlKey) && event.shiftKey then
                        [ Redo |> Cmd.ofMsg ]
                    else if event.metaKey || event.ctrlKey then
                        [ Undo |> Cmd.ofMsg ]
                    else []
                | "/", DemoMode
                | "/", EditorMode _ ->
                    if event.metaKey || event.ctrlKey then
                        [ KeyboardShortcutsModal |> OpenModal |> Cmd.ofMsg ]
                    else []
                | "?", DemoMode
                | "?", EditorMode _ ->
                    [ KeyboardShortcutsModal |> OpenModal |> Cmd.ofMsg ]

                // Shortcuts that work regardless of viewer mode
                | " ", DemoMode
                | " ", EditorMode _
                | " ", ViewerMode _ ->
                    match model.CurrentPlaybackState with
                    | Paused -> [ Play |> Cmd.ofMsg ]
                    | Playing -> [ Pause |> Cmd.ofMsg ]

                | "Alt", _
                | "Control", _
                | "Meta", _
                | "Shift", _ ->
                    [ event.key |> AddActiveModifierKey |> Cmd.ofMsg ]

                | _ ->
                    []

            Cmd.batch cmds
        else
            let autoHeightTextareaForLayer (layer : Layer) =
                let autoHeightTextareaForUnit (unit : LayerUnit) =
                    match unit.item with
                    | TextItem _ ->
                        let marker =
                            model.UndoableModel.present.MapMarkers
                            |> Map.find unit.id
                        match marker.getElement () with
                        | Some markerElement ->
                            let textareaElement =
                                markerElement.querySelector("textarea")
                                :?> Browser.HTMLElement
                            if textareaElement <> null then
                                textareaElement.style.height <- "auto"
                                textareaElement.style.height <-
                                    textareaElement.scrollHeight.ToString ()
                                    + "px"
                        | None -> ()
                    | Dota2Item (Dota2BuildingItem _)
                    | Dota2Item (Dota2HeroItem _)
                    | Dota2Item (Dota2LaneCreepItem _)
                    | Dota2Item (Dota2NeutralCampItem _)
                    | Dota2Item (Dota2WardItem _)
                    | Dota2Item (Dota2AbilityItem _)
                    | GenericItem _ ->
                        ()
                match layer with
                | LayerUnit unit ->
                    autoHeightTextareaForUnit unit
                | LayerGroup group ->
                    group.children
                    |> List.iter autoHeightTextareaForUnit
            model.UndoableModel.present.Layers
            |> List.iter autoHeightTextareaForLayer

            Cmd.none

    let moveLayersToFirstKeyframe
        (map : Leaflet.Map)
        (attackRangeCircles : HeroAttackRangeCircles)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        (layers : Layer list) : unit =
        let moveLayerUnitMarkerToFirstKeyframe map (layerUnit : LayerUnit) =
            // For Items that are not keyframable for their
            // movement, ignore
            match layerUnit.item with
            | Dota2Item (Dota2BuildingItem _)
            | Dota2Item (Dota2HeroItem _)
            | Dota2Item (Dota2LaneCreepItem _)
            | Dota2Item (Dota2NeutralCampItem _)
            | GenericItem _
            | TextItem _ ->
                let firstKeyframeInfo =
                    layerUnit.keyframes
                    |> Map.toList
                    |> List.head
                    |> snd

                let firstPosition = firstKeyframeInfo.position
                let firstLatLngExpression =
                    !^(firstPosition.latitude, firstPosition.longitude)

                match layerUnit.item with
                | Dota2Item(Dota2BuildingItem _)
                | Dota2Item(Dota2NeutralCampItem _) ->
                    // Don't update the LatLng positions
                    // of static markers.
                    // This provides backwards compatibility
                    // with old sessions that have LatLngs
                    // keyframed in static markers that have been
                    // moved in newer versions.
                    ()
                | _ ->
                    let marker = Map.find layerUnit.id markers
                    marker.setLatLng firstLatLngExpression
                    |> ignore

                let circleId =
                    "circle-" + LayerId.toString layerUnit.id
                let (HeroAttackRangeCircles attackRangeCircles)
                    = attackRangeCircles
                attackRangeCircles
                |> Seq.toList
                |> List.iter (fun (HeroAttackRangeCircle circle) ->
                    match circle.options.className with
                    | Some className ->
                        if className.Contains circleId then
                            circle.setLatLng firstLatLngExpression |> ignore
                            circle.addTo !^map |> ignore
                    | None -> ())
            | Dota2Item (Dota2AbilityItem _)
            | Dota2Item (Dota2WardItem _) ->
                ()
        let moveLayerMarkerToFirstKeyframe map layer =
            match layer with
            | LayerUnit unit ->
                unit
                |> moveLayerUnitMarkerToFirstKeyframe map
            | LayerGroup group ->
                group.children
                |> List.iter (moveLayerUnitMarkerToFirstKeyframe map)
        layers |> List.iter (moveLayerMarkerToFirstKeyframe map)

    /// Upon adding animations to the timeline it stores the initial value of
    /// its animation target, so we need our active timeline instance to know that.
    let populateAnimeTimelineWithLayers
        (map : Leaflet.Map)
        (timelineDuration : int)
        (layers : Layer list)
        (animeTimeline : AnimeTimelineInstance)
        : AnimeTimelineInstance =

        let addLayerToAnimeTimeline layer =
            let addLayerUnitToAnimeTimelineLayer
                (animeTimeline : AnimeTimelineInstance)
                (layerUnit : LayerUnit) =
                match layerUnit.item with
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | GenericItem _
                | TextItem _ ->
                    let animeParams =
                        layerUnit
                        |> AnimeJS.generateAnimeTimelineParamsFromLayerUnit
                            timelineDuration
                            (map.getZoom ())
                            ("id-" + LayerId.toString layerUnit.id)
                            EasingOptions.EaseInOutSine
                    animeTimeline.add (animeParams, 0.)
                    |> ignore
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2WardItem _)
                | Dota2Item (Dota2AbilityItem _)
                | Dota2Item (Dota2NeutralCampItem _) ->
                    ()
            match layer with
            | LayerUnit layerUnit ->
                layerUnit
                |> addLayerUnitToAnimeTimelineLayer animeTimeline
            | LayerGroup layerGroup ->
                layerGroup.children
                |> List.iter (addLayerUnitToAnimeTimelineLayer animeTimeline)
        List.iter addLayerToAnimeTimeline layers
        animeTimeline

    let prepareMarkersForAnimation
        (map : Leaflet.Map)
        (timelineDuration : int)
        (currentTime : int)
        (attackRangeCircles : HeroAttackRangeCircles)
        (layers : Layer list)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        : AnimeTimelineInstance =

        layers
        |> moveLayersToFirstKeyframe map attackRangeCircles markers

        // Create new anime timeline
        let newAnimeTimeline =
            (AnimeJS.initAnimeTimeline ())
            |> populateAnimeTimelineWithLayers map timelineDuration layers

        // Seek new anime timeline to the current time
        currentTime |> float |> newAnimeTimeline.seek

        // Sync up the positions of the markers to Leaflet
        let syncMarkerPositionToLeafletForLayerUnit (layerUnit : LayerUnit) =
            let marker = Map.find layerUnit.id markers
            // Extracting the style attribute for the marker element
            let markerStyle =
                let leafletMarkerElement =
                    ".id-" + LayerId.toString layerUnit.id
                    |> Browser.document.querySelector
                Browser.window.getComputedStyle leafletMarkerElement
            // The transform style property is returned as a
            // 2d transformation matrix: transform(a, b, c, d, tx, ty)
            // The regex to extract the numbers from the matrix
            let matches =
                (Regex @"(-?[0-9\.]+)").Matches markerStyle.transform
            let transformX = matches.[4].Value |> float
            let transformY = matches.[5].Value |> float
            let pointOnMap = (transformX, transformY)
            let latlng = pointOnMap |> U2.Case2 |> map.layerPointToLatLng
            latlng |> U3.Case1 |> marker.setLatLng |> ignore
            map |> U2.Case1 |> marker.addTo |> ignore
        let syncMarkerPositionToLeafletForLayer layer =
            match layer with
            | LayerUnit layerUnit ->
                layerUnit
                |> syncMarkerPositionToLeafletForLayerUnit
            | LayerGroup group ->
                group.children
                |> List.iter syncMarkerPositionToLeafletForLayerUnit
        layers
        |> List.iter syncMarkerPositionToLeafletForLayer

        newAnimeTimeline

    let updateAnimationForMapZoom
        (map : Leaflet.Map)
        (currentAnimeTimeline : AnimeTimelineInstance)
        (timelineDuration : int)
        (currentPlaybackState : PlaybackState)
        (currentTime : int)
        (heroAttackRangeCircles : HeroAttackRangeCircles)
        (layers : Layer list)
        (markers : Map<LayerId, Leaflet.Marker<obj>>)
        : AnimeTimelineInstance =

        if currentPlaybackState = Playing then
            currentAnimeTimeline.pause ()
            |> ignore

            layers
            |> moveLayersToFirstKeyframe map heroAttackRangeCircles markers

            // Create new anime timeline
            let newAnimeTimeline =
                (AnimeJS.initAnimeTimeline ())
                |> populateAnimeTimelineWithLayers map timelineDuration layers

            currentTime |> float |> newAnimeTimeline.seek
            newAnimeTimeline.play ()
            newAnimeTimeline
        else
            prepareMarkersForAnimation
                map
                timelineDuration
                currentTime
                heroAttackRangeCircles
                layers
                markers

    // Actual Updating

    let delay duration =
        promise {
            do! Promise.sleep duration
            return 1.
        }

    match msg, currentModel.CurrentPlaybackState with
    | UserIsLeaving event, _ ->
        if currentModel.CurrentMode |> Mode.isDemoMode |> not && currentModel.UserMadeChanges then
            event.preventDefault()
            event.returnValue <- ""
        currentModel, Cmd.none, Cmd.none

    | AppFocused _, _ ->
        currentModel, Cmd.ofMsg RemoveAllActiveModifierKeys, Cmd.none

    | KeyPressedDown event, _ ->
        currentModel, event |> keyboardShortcutHandler currentModel, Cmd.none

    | KeyPressedUp event, _ ->
        currentModel, Cmd.ofMsg (RemoveActiveModifierKey event.key), Cmd.none

    | Tick, Playing ->
        let tickRate = 75
        if currentModel.CurrentTime + tickRate < currentModel.TimelineDuration then
            let newModel =
                { currentModel with
                    CurrentTime = (currentModel.CurrentTime + tickRate) }
            let cmds =
                [ Cmd.animateBuildingState
                    currentModel.CurrentTime
                    newModel.UndoableModel.present.Layers
                  Cmd.ofPromise
                    delay
                    tickRate
                    (fun _ -> Tick)
                    (fun _ -> Error) ]
            newModel, Cmd.batch cmds, Cmd.none
        else
            let newModel =
                { currentModel with
                    CurrentTime = currentModel.TimelineDuration }
            newModel, Cmd.ofMsg Pause, Cmd.none
    | Tick, Paused ->
        currentModel, Cmd.none, Cmd.none

    | Play, Paused ->
        match currentModel.Map with
        | Loaded map ->
            let newModel =
                if currentModel.CurrentTime = currentModel.TimelineDuration then
                   { currentModel with
                        CurrentPlaybackState = Playing
                        CurrentTime = 0 }
                else { currentModel with CurrentPlaybackState = Playing }
            let cmds =
                [ Cmd.disableMarkerDraggability
                    newModel.UndoableModel.present.MapMarkers
                    newModel.UndoableModel.present.Layers
                  Cmd.ofMsg SendCollaborationUpdate
                  Cmd.ofMsg Tick ]
            currentAnimeJSTimelineInstance <-
                prepareMarkersForAnimation
                    map
                    newModel.TimelineDuration
                    newModel.CurrentTime
                    newModel.HeroAttackRangeCircles
                    newModel.UndoableModel.present.Layers
                    newModel.UndoableModel.present.MapMarkers
            currentAnimeJSTimelineInstance.play ()
            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            currentModel, Cmd.none, Cmd.none
    | Play, Playing ->
        currentModel, Cmd.none, Cmd.none

    | Pause, Playing ->
        match currentModel.Map with
        | Loaded map ->
            let newModel = { currentModel with CurrentPlaybackState = Paused }
            let cmds =
                [ Cmd.enableMarkerDraggability
                    newModel.UndoableModel.present.MapMarkers
                    newModel.UndoableModel.present.Layers
                  Cmd.saveAllBuildingStatesToLayers
                    newModel.CurrentTime
                    newModel.UndoableModel.present.Layers
                  Cmd.ofMsg SendCollaborationUpdate ]
            currentAnimeJSTimelineInstance.pause ()
            currentAnimeJSTimelineInstance <-
                prepareMarkersForAnimation
                    map
                    newModel.TimelineDuration
                    newModel.CurrentTime
                    newModel.HeroAttackRangeCircles
                    newModel.UndoableModel.present.Layers
                    newModel.UndoableModel.present.MapMarkers

            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            currentModel, Cmd.none, Cmd.none
    | Pause, Paused ->
        currentModel, Cmd.none, Cmd.none

    | Stop, _ ->
        let newModel =
            { currentModel with
                CurrentTime = 0
                CurrentPlaybackState = Paused }
        newModel, Cmd.ofMsg (SkipToTime 0), Cmd.none

    | SkipToTime skipToTime, _ ->
        match currentModel.Map with
        | Loaded map ->
            let boundedSkipToTime =
                if skipToTime > currentModel.TimelineDuration then
                    currentModel.TimelineDuration
                else skipToTime
            let newModel = { currentModel with CurrentTime = boundedSkipToTime }
            let cmd =
                match newModel.Map with
                | Loaded map ->
                    Cmd.batch
                        [ Cmd.ofMsg SendCollaborationUpdate
                          Cmd.ofMsg Pause
                          Cmd.saveAllBuildingStatesToLayers
                            newModel.CurrentTime
                            newModel.UndoableModel.present.Layers ]
                | Loading ->
                    Browser.console.error "Map not loaded."
                    Cmd.ofMsg Error
            currentAnimeJSTimelineInstance.pause ()
            currentAnimeJSTimelineInstance.seek (float skipToTime)
            currentAnimeJSTimelineInstance <-
                prepareMarkersForAnimation
                    map
                    newModel.TimelineDuration
                    newModel.CurrentTime
                    newModel.HeroAttackRangeCircles
                    newModel.UndoableModel.present.Layers
                    newModel.UndoableModel.present.MapMarkers
            newModel, cmd, Cmd.none
        | Loading ->
            currentModel, Cmd.none, Cmd.none

    | UpdateTimelineZoom zoomLevel, _ ->
        let newModel =
            { currentModel with
                TimelineMarksPerSecond =
                    match zoomLevel with
                    | Some WidestZoom -> 5
                    | Some WideZoom -> 10
                    | Some DefaultZoom
                    | Some CloseZoom
                    | Some ClosestZoom -> currentModel.TimelineMarksPerSecond
                    | None -> currentModel.TimelineMarksPerSecond
                TimelineZoomLevel = zoomLevel.Value }
        newModel, Cmd.none, Cmd.none

    | UpdateTimelineDuration newTimelineDuration, _ ->
        let newModel =
            { currentModel with TimelineDuration = newTimelineDuration }
        newModel, Cmd.none, Cmd.none

    | OpenSidebar tab, _ ->
        let newModel = { currentModel with CurrentSidebarTab = Some tab }
        newModel, Cmd.none, Cmd.none

    | CloseSidebar, _ ->
        let newModel = { currentModel with CurrentSidebarTab = None }
        newModel, Cmd.none, Cmd.none

    | SelectSidebarTool tool, _ ->
        match currentModel.CurrentMode with
        | ViewerMode (SessionViewer _) ->
            currentModel, Cmd.none, Cmd.none
        | DemoMode
        | EditorMode _
        | ViewerMode MapViewer ->
            if not currentModel.IsLocked then
                match currentModel.Map, tool with
                | Loaded _, Redoing ->
                    currentModel, Cmd.ofMsg Redo, Cmd.none
                | Loaded _, Undoing ->
                    currentModel, Cmd.ofMsg Undo, Cmd.none
                | Loaded _, _ ->
                    let newModel = { currentModel with CurrentSidebarTool = tool }
                    let cmds =
                        [ Cmd.updateMarkerDraggabilityBasedOnSelectedTool
                            tool
                            newModel.UndoableModel.present.MapMarkers
                            newModel.UndoableModel.present.Layers
                          Cmd.updateTextMarkerStylesBasedOnTool tool ]
                    newModel, Cmd.batch cmds, Cmd.none
                | Loading, _ ->
                    currentModel, Cmd.ofMsg Error, Cmd.none
            else
                currentModel, Cmd.ofMsg UserAttemptedActionWhileLocked, Cmd.none

    | DeselectSidebarTool, _ ->
        let newModel = { currentModel with CurrentSidebarTool = Panning }
        newModel, Cmd.none, Cmd.none

    | MapCreated map, _ ->
        let newModel = { currentModel with Map = Loaded map }
        newModel, Cmd.none, Cmd.none

    | CreateBuildingLayerGroup, _ ->
        let buildingLayers =
            List.choose
                chooseBuildingLayers
                currentModel.UndoableModel.present.Layers
        currentModel, (GroupStaticLayers ("Buildings", true, buildingLayers))
                      |> Cmd.ofMsg, Cmd.none

    | CreateNeutralCampLayerGroup, _ ->
        let neutralCampLayers =
            List.choose
                chooseNeutralCampLayers
                currentModel.UndoableModel.present.Layers
        currentModel, (GroupStaticLayers ("Neutral Camps", true, neutralCampLayers))
                      |> Cmd.ofMsg, Cmd.none

    | PopulatedStaticMarkers, _ ->
        let newModel = { currentModel with PopulateStaticMarkers = false }
        newModel, Cmd.none, Cmd.none

    | MapZoomed _, _ ->
        // On map zoomed
        match currentModel.Map with
        | Loaded map ->
            currentAnimeJSTimelineInstance <-
                updateAnimationForMapZoom
                    map
                    currentAnimeJSTimelineInstance
                    currentModel.TimelineDuration
                    currentModel.CurrentPlaybackState
                    currentModel.CurrentTime
                    currentModel.HeroAttackRangeCircles
                    currentModel.UndoableModel.present.Layers
                    currentModel.UndoableModel.present.MapMarkers
        | Loading ->
            Browser.console.error "Map not loaded."
        currentModel, Cmd.none, Cmd.none

    | AddItemToMap (layerUnit, leafletMarker), _ ->
        match currentModel.Map with
        | Loaded map ->
            let newLeafletMarkers =
                currentModel.UndoableModel.present.MapMarkers
                |> Map.add layerUnit.id leafletMarker
            let newLayers =
                [ LayerUnit layerUnit ]
                |> List.append currentModel.UndoableModel.present.Layers
            let newUndoableModel =
                let newState =
                    { currentModel.UndoableModel.present with
                        Layers = newLayers
                        MapMarkers = newLeafletMarkers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with UndoableModel = newUndoableModel }
            let cmds =
                Cmd.batch
                    [ Cmd.addItemToMap
                        (currentModel.CurrentMode |> Mode.isViewerMode)
                        map
                        leafletMarker
                        layerUnit.id
                        layerUnit.item
                      trackAddItemToMap layerUnit.item
                      Cmd.ofMsg SendCollaborationUpdate ]
            newModel, cmds, Cmd.none
        | Loading ->
            Browser.console.error "Map not loaded."
            currentModel, Cmd.none, Cmd.none

    | AddMapMarkersForLayersToMap mapMarkersToAdd, _ ->
        match currentModel.Map with
        | Loaded map ->
            let newLeafletMarkers =
                let leafletMarkersToAdd =
                    mapMarkersToAdd
                    |> List.map (fun (layerUnit, marker) ->
                        layerUnit.id, marker)
                    |> Map.ofList
                leafletMarkersToAdd
                |> Map.fold
                       (fun acc key value -> Map.add key value acc)
                        currentModel.UndoableModel.present.MapMarkers
            let newUndoableModel =
                let newState =
                    { currentModel.UndoableModel.present with
                        MapMarkers = newLeafletMarkers }
                newState
                |> History.create
            let newModel =
                { currentModel with UndoableModel = newUndoableModel }
            let cmds =
                Cmd.batch
                    [ Cmd.addItemsToMap
                        (currentModel.CurrentMode |> Mode.isViewerMode)
                        map
                        (mapMarkersToAdd
                         |> List.map (fun (layerUnit, marker) ->
                                layerUnit.id, layerUnit.item, marker))
                      Cmd.updateTextMarkerWithContentFromLayers
                          newModel.UndoableModel.present.Layers ]
            newModel, cmds, Cmd.none
        | Loading ->
            Browser.console.error "Map not loaded."
            currentModel, Cmd.none, Cmd.none

    | AddStaticItemsToMap itemsToAdd, _ ->
        match currentModel.Map with
        | Loaded map ->
            let newLeafletMarkers =
                let leafletMarkersToAdd =
                    itemsToAdd
                    |> List.map (fun (layerUnit, marker) ->
                        layerUnit.id, marker)
                    |> Map.ofList
                leafletMarkersToAdd
                |> Map.fold
                       (fun acc key value -> Map.add key value acc)
                        currentModel.UndoableModel.present.MapMarkers
            let newLayers =
                itemsToAdd
                |> List.map (fun (layerUnit,_) -> layerUnit |> LayerUnit)
                |> List.append currentModel.UndoableModel.present.Layers
            let newUndoableModel =
                let newState =
                    { currentModel.UndoableModel.present with
                        Layers = newLayers
                        MapMarkers = newLeafletMarkers }
                History.create newState
            let newModel =
                { currentModel with UndoableModel = newUndoableModel }
            let cmds =
                [ Cmd.addItemsToMap
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    map
                    (itemsToAdd
                     |> List.map (fun (layerUnit, marker) ->
                            layerUnit.id, layerUnit.item, marker)) ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            Browser.console.error "Map not loaded."
            currentModel, Cmd.none, Cmd.none

    | MoveMarker (leafletEvent, layerId), _ ->
        match currentModel.Map with
        | Loaded map ->
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> saveAllTextMarkerContentToLayers
                |> updateLayersAfterMoveMarkerMsg
                       currentModel.CurrentTime
                       leafletEvent
                       layerId
            let newLeafletMarkers =
                currentModel.UndoableModel.present.MapMarkers
                |> updatePositionOfMovedLeafletMarker leafletEvent layerId
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers
                        MapMarkers = newLeafletMarkers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    UndoableModel = newUndoableModel }
            let cmds =
                [ Cmd.ofMsg UserMadeChanges
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            Browser.console.error "Map not loaded."
            currentModel, Cmd.none, Cmd.none

    | ToggleTimelineOpened, _ ->
        let newModel =
            { currentModel with
                TimelineOpened = not currentModel.TimelineOpened }
        newModel, Cmd.none, Cmd.none

    | AddLayerGroup, _ ->
        let newLayerGroup =
            { children = []
              id = (Guid.NewGuid ()) |> LayerId.ofGuid
              locked = false
              opened = false
              perpetual = false
              name = "New Group"
              visible = true }
            |> LayerGroup
        let newLayers =
            [ newLayerGroup ]
            |> List.append currentModel.UndoableModel.present.Layers
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | GroupStaticLayers (name, perpetual, groupedLayers), _ ->
        let layerGroup =
            createLayerGroupFromLayers
                name
                perpetual
                groupedLayers
        let groupedLayersIds = List.map Layer.getId groupedLayers
        let removeGroupedLayers layer =
            not (List.contains (Layer.getId layer) groupedLayersIds)
        let filteredLayers =
            currentModel.UndoableModel.present.Layers
            |> List.filter removeGroupedLayers
        let newLayers =
            [layerGroup]
            |> List.append filteredLayers
        let newUndoableModel =
            let newState =
                { currentModel.UndoableModel.present with Layers = newLayers }
            History.create newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | EnableLayerRenaming timelineLayer, _ ->
        let newModel =
            { currentModel with
                LayerBeingRenamed = timelineLayer |> Some }
        newModel, Cmd.none, Cmd.none

    | DisableLayerRenaming, _ ->
        let newModel =
            { currentModel with LayerBeingRenamed = None }
        newModel, Cmd.none, Cmd.none

    | ToggleLayerLocking toggledLayer, _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> List.map (toggleLayerLocking toggledLayer)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | ToggleLayerVisibility toggledLayer, _ ->
        match currentModel.Map with
        | Loaded _ ->
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> List.map (toggleLayerVisibility
                                toggledLayer
                                currentModel.UndoableModel.present.MapMarkers)
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    UndoableModel = newUndoableModel }
            let cmd =
                Cmd.recreateLeafletMarkers
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newModel.UndoableModel.present.Layers
                    newModel.UndoableModel.present.MapMarkers
            newModel, cmd, Cmd.none
        | Loading ->
            Browser.console.error "Map not loaded."
            currentModel, Cmd.none, Cmd.none

    | ToggleLayerGroupOpened toggledGroup, _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> List.map (updateLayerGroupOpened toggledGroup)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | MoveLayerBeforeAnother (movedLayer, targetLayerId), _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> moveLayerBeforeLayerId movedLayer targetLayerId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | MoveLayerAfterAnother (movedLayer, targetLayerId), _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> moveLayerAfterLayerId movedLayer targetLayerId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | MoveLayerIntoGroup (movedLayer, groupId), _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> moveLayerIntoGroupId movedLayer groupId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | UpdateHeroFilterString filterString, _ ->
        let newModel = { currentModel with HeroFilterString = filterString }
        newModel, Cmd.none, Cmd.none

    | SelectKeyframe (mapItemId, keyframe), _ ->
        let newModel =
            { currentModel with
                SelectedKeyframe = Selected (mapItemId, keyframe) }
        newModel, Cmd.none, Cmd.none

    | DeselectKeyframe, _ ->
        let newModel = { currentModel with SelectedKeyframe = NotSelected }
        newModel, Cmd.none, Cmd.none

    | DemoTimerTick, _ ->
        match currentModel.DemoTimer with
        | Some timer ->
            let newTimerValue = timer - 1
            if newTimerValue > 0 then
                let newModel =
                    { currentModel with
                        DemoTimer = Some newTimerValue }
                let cmd =
                        Cmd.ofPromise
                            delay
                            1000
                            (fun _ -> DemoTimerTick)
                            (fun _ -> Error)
                newModel, cmd, Cmd.none
            else
                let newModel =
                    { currentModel with
                        DemoTimer = Some 0 }
                newModel, Cmd.none, Cmd.none
        | None ->
            currentModel, Cmd.none, Cmd.none

    | DemoFinished, _ ->
        let newModel =
            { currentModel with
                CurrentSidebarTool = Panning
                IsLocked = true
                OpenedModal = DemoFinishedModal }
        newModel, Cmd.none, Cmd.none

    | OpenModal modalToOpen, _ ->
        let newModel =
            { currentModel with OpenedModal = modalToOpen }
        newModel, Cmd.none, Cmd.none

    | CloseModal, _ ->
        match currentModel.OpenedModal with
        | DemoWelcomeModal ->
            let newModel =
                { currentModel with
                    DemoTimer = Some 300
                    OpenedModal = NoModal }
            let cmd =
                let sub dispatch =
                    Browser.window.setInterval
                        ((fun () -> dispatch DemoFinished), 300000, [])
                    |> ignore
                Cmd.batch
                    [ Cmd.ofSub sub
                      Cmd.ofMsg DemoTimerTick ]
            newModel, cmd, Cmd.none
        | _ ->
            let newModel =
                { currentModel with OpenedModal = NoModal }
            newModel, Cmd.none, Cmd.none

    | AddActiveModifierKey keyToAdd, _ ->
        let newModel =
            { currentModel with
                ActiveModifierKeys =
                    keyToAdd :: currentModel.ActiveModifierKeys }
        newModel, Cmd.none, Cmd.none

    | RemoveActiveModifierKey keyToRemove, _ ->
        let notTheRemovedKey key = keyToRemove <> key
        let filteredActiveModifierKeys =
            List.filter notTheRemovedKey currentModel.ActiveModifierKeys
        let newModel =
            { currentModel with
                ActiveModifierKeys = filteredActiveModifierKeys }
        newModel, Cmd.none, Cmd.none

    | RemoveAllActiveModifierKeys, _ ->
        let newModel = { currentModel with ActiveModifierKeys = [] }
        newModel, Cmd.none, Cmd.none

    | AddHeroAttackRangeCircleToStorage circle, _ ->
        let (HeroAttackRangeCircles newHeroAttackRangeCircles) =
            currentModel.HeroAttackRangeCircles
        newHeroAttackRangeCircles.Add(circle)
        let newModel =
            { currentModel with
                HeroAttackRangeCircles =
                    newHeroAttackRangeCircles |> HeroAttackRangeCircles }
        newModel, Cmd.none, Cmd.none

    | AddWardSightCircleToStorage circle, _ ->
        let (WardSightCircles newWardSightCircles) =
            currentModel.WardSightCircles
        newWardSightCircles.Add circle
        let newModel =
            { currentModel with
                 WardSightCircles =
                     newWardSightCircles |> WardSightCircles }
        newModel, Cmd.none, Cmd.none

    | AddAbilityEffectCircleToStorage circle, _ ->
        let (AbilityEffectCircles newAbilityEffectCircles) =
            currentModel.AbilityEffectCircles
        newAbilityEffectCircles.Add circle
        let newModel =
            { currentModel with
                 AbilityEffectCircles =
                     newAbilityEffectCircles |> AbilityEffectCircles }
        newModel, Cmd.none, Cmd.none

    | DeleteWardSightCircleFromStorage circleToRemove, _ ->
        let (WardSightCircles newWardSightCircles) =
            currentModel.WardSightCircles
        newWardSightCircles.Remove circleToRemove |> ignore
        let newModel =
            { currentModel with
                 WardSightCircles =
                     newWardSightCircles |> WardSightCircles }
        newModel, Cmd.none, Cmd.none

    | DeleteAbilityEffectCircleFromStorage circleToRemove, _ ->
        let (AbilityEffectCircles newAbilityEffectCircles) =
            currentModel.AbilityEffectCircles
        newAbilityEffectCircles.Remove circleToRemove |> ignore
        let newModel =
            { currentModel with
                 AbilityEffectCircles =
                     newAbilityEffectCircles |> AbilityEffectCircles }
        newModel, Cmd.none, Cmd.none

    | DeleteWardSightCirclesFromStorage circlesToRemove, _ ->
        let (WardSightCircles newWardSightCircles) =
            currentModel.WardSightCircles
        circlesToRemove
        |> Seq.toList
        |> List.iter (fun circle -> newWardSightCircles.Remove circle |> ignore)
        let newModel =
            { currentModel with
                 WardSightCircles =
                     newWardSightCircles |> WardSightCircles }
        newModel, Cmd.none, Cmd.none

    | MapClicked, _ ->
        let newModel =
            { currentModel with SelectedLayers = [] }
        let cmds =
            [ Cmd.visuallyUnselectAllMapMarkers ()
              (currentModel.UndoableModel.present.Layers
               |> Cmd.saveAllTextMarkerContentToModel)
              Cmd.ofMsg CloseSidebar ]
        newModel, Cmd.batch cmds, Cmd.none

    | MoveKeyframe (affectedLayerUnit, currentTime, newTime), _ ->
        let marker = Map.find currentTime affectedLayerUnit.keyframes
        let updatedKeyframes =
            affectedLayerUnit.keyframes
            |> Map.remove currentTime
            |> Map.add newTime marker
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> List.map (fun layer ->
                let replaceAffectedLayerUnit
                    (affectedLayerUnit : LayerUnit)
                    (layerUnit : LayerUnit) =
                    if layerUnit.id = affectedLayerUnit.id then
                        { affectedLayerUnit with
                            keyframes = updatedKeyframes }
                    else layerUnit
                match layer with
                | LayerUnit unit ->
                    unit
                    |> (replaceAffectedLayerUnit affectedLayerUnit)
                    |> LayerUnit
                | LayerGroup group ->
                    let newChildren =
                        group.children
                        |> List.map (replaceAffectedLayerUnit affectedLayerUnit)
                    { group with children = newChildren }
                    |> LayerGroup)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | DeleteKeyframe (affectedLayerUnit, currentTime), _ ->
        match currentModel.Map with
        | Loaded map ->
            let newKeyframes =
                affectedLayerUnit.keyframes
                |> Map.remove currentTime
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> List.map (fun layer ->
                    let updateKeyframesForUnit newKeyframes (unit : LayerUnit) =
                        if unit.id = affectedLayerUnit.id then
                            { affectedLayerUnit with keyframes = newKeyframes }
                        else unit
                    match layer with
                    | LayerUnit unit ->
                        unit
                        |> updateKeyframesForUnit newKeyframes
                        |> LayerUnit
                    | LayerGroup group ->
                        let newChildren =
                            group.children
                            |> List.map (updateKeyframesForUnit newKeyframes)
                        { group with children = newChildren }
                        |> LayerGroup)
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    UndoableModel = newUndoableModel }
            let cmds =
                [ Cmd.saveAllBuildingStatesToLayers
                    newModel.CurrentTime
                    newModel.UndoableModel.present.Layers
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            currentModel, Cmd.none, Cmd.none

    | SelectMapMarker layerId, _ ->
        if not currentModel.IsLocked then
            let selectedLayer =
                currentModel.UndoableModel.present.Layers
                |> Layer.flattenList
                |> List.find (fun layer -> Layer.getId layer = layerId)
            if isMultiSelectModeEnabled currentModel.ActiveModifierKeys then
                let newModel =
                    { currentModel with
                        SelectedLayers =
                            selectedLayer :: currentModel.SelectedLayers }
                newModel, Cmd.none, Cmd.none
            else
                let newModel =
                    { currentModel with
                        SelectedLayers = [ selectedLayer ] }
                let cmds =
                    [ Cmd.visuallyUnselectAllMapMarkers ()
                      Cmd.visuallySelectMapMarkerByLayerId layerId
                      Cmd.ofMsg (OpenSidebar Info) ]
                newModel, Cmd.batch cmds, Cmd.none
        else
            currentModel, Cmd.ofMsg UserAttemptedActionWhileLocked, Cmd.none

    | SelectTimelineLayer selectedLayer, _ ->
        if currentModel.IsLocked then
            currentModel, Cmd.ofMsg UserAttemptedActionWhileLocked, Cmd.none
        else if isMultiSelectModeEnabled currentModel.ActiveModifierKeys then
            let newModel =
                { currentModel with
                    SelectedLayers =
                        selectedLayer :: currentModel.SelectedLayers }
            newModel, Cmd.none, Cmd.none
        else
            let layerId = selectedLayer |> Layer.getId
            let newModel =
                { currentModel with
                    SelectedLayers = [ selectedLayer ] }
            let cmds =
                match selectedLayer with
                | LayerUnit _ ->
                    [ Cmd.visuallyUnselectAllMapMarkers ()
                      Cmd.visuallySelectMapMarkerByLayerId layerId
                      Cmd.ofMsg (OpenSidebar Info) ]
                | LayerGroup _ ->
                    [ Cmd.visuallyUnselectAllMapMarkers () ]
            newModel, Cmd.batch cmds, Cmd.none

    | ChangeSideForLayer (affectedLayer, newSide), _ ->
        match affectedLayer with
        | LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | Dota2Item (Dota2HeroItem hero) ->
                    hero
                    |> Dota2HeroItem.setSide newSide
                    |> Dota2HeroItem
                    |> Dota2Item
                | Dota2Item (Dota2LaneCreepItem creep) ->
                    creep
                    |> Dota2LaneCreepItem.setSide newSide
                    |> Dota2LaneCreepItem
                    |> Dota2Item
                | Dota2Item (Dota2WardItem ward) ->
                    ward
                    |> Dota2WardItem.setSide newSide
                    |> Dota2WardItem
                    |> Dota2Item
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _
                | TextItem _ ->
                    affectedLayerUnit.item
            let newLayerUnit =
                { affectedLayerUnit with item = newItem }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel  }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find newLayerUnit.id
            let cmds =
                [ Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    newLayerUnit.id
                    affectedMarker
                  Cmd.visuallySelectMapMarkerByLayerId newLayerUnit.id
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | ChangeAttackRangeVisibilityForLayer
        (affectedLayer, newAttackRangeCircleVisibility), _ ->
        match currentModel.Map, affectedLayer with
        | Loaded map, LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | Dota2Item (Dota2BuildingItem building) ->
                    building
                    |> Dota2BuildingItem.setAttackRangeCircleVisibility
                        newAttackRangeCircleVisibility
                    |> Dota2BuildingItem
                    |> Dota2Item
                | Dota2Item (Dota2HeroItem hero) ->
                    hero
                    |> Dota2HeroItem.setAttackRangeCircleVisibility
                        newAttackRangeCircleVisibility
                    |> Dota2HeroItem
                    |> Dota2Item
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2WardItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _
                | TextItem _ ->
                    affectedLayerUnit.item
            let newLayerUnit =
                { affectedLayerUnit with item = newItem }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find newLayerUnit.id
            let cmds =
               [ Cmd.toggleAttackRangeCircleVisibilityForLayer
                    map
                    newAttackRangeCircleVisibility
                    affectedMarker
                    affectedLayer
                 Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    newLayerUnit.id
                    affectedMarker
                 Cmd.visuallySelectMapMarkerByLayerId newLayerUnit.id ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loaded _, LayerGroup _
        | Loading, LayerUnit _
        | Loading, LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | ChangeBuildingStateForLayer (affectedLayer, newBuildingState), _ ->
        match currentModel.Map, affectedLayer with
        | Loaded _, LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | Dota2Item (Dota2BuildingItem building) ->
                    building
                    |> Dota2BuildingItem.setBuildingState newBuildingState
                    |> Dota2BuildingItem
                    |> Dota2Item
                | Dota2Item (Dota2WardItem ward) ->
                    ward
                    |> Dota2WardItem.setState newBuildingState
                    |> Dota2WardItem
                    |> Dota2Item
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _
                | TextItem _ ->
                    affectedLayerUnit.item
            let newKeyframes =
                match newBuildingState with
                | BuildingState.Destroyed ->
                    affectedLayerUnit.keyframes
                    |> addGreyKeyframeToKeyframes currentModel.CurrentTime
                | BuildingState.NotDestroyed ->
                    affectedLayerUnit.keyframes
                    |> clearGreyKeyframesFromKeyframes
                | (BuildingState.Captured _) ->
                    affectedLayerUnit.keyframes
                    |> addNewCapturedStateKeyframeToKeyframes currentModel.CurrentTime newBuildingState
            let newLayerUnit =
                { affectedLayerUnit with
                    item = newItem
                    keyframes = newKeyframes }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find newLayerUnit.id
            let cmds =
                [ Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    newLayerUnit.id
                    affectedMarker
                  Cmd.visuallySelectMapMarkerByLayerId newLayerUnit.id
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loaded _, LayerGroup _
        | Loading, LayerUnit _
        | Loading, LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | ChangeColorForLayer (affectedLayer, newColor), _ ->
        match affectedLayer with
        | LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | TextItem text ->
                    let markerElement =
                        "id-" + (LayerId.toString affectedLayerUnit.id)
                        |> Browser.document.getElementById
                    let textareaElement =
                        markerElement.querySelector("textarea")
                        :?> Browser.HTMLTextAreaElement
                    let newText = textareaElement.value
                    text
                    |> TextItem.setText newText
                    |> TextItem.setColor newColor
                    |> TextItem
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2WardItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _ ->
                    affectedLayerUnit.item
            let newLayerUnit =
                { affectedLayerUnit with item = newItem }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find newLayerUnit.id
            let cmds =
                [ Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    affectedLayerUnit.id
                    affectedMarker
                  Cmd.visuallySelectMapMarkerByLayerId newLayerUnit.id
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | ChangeFontSizeForLayer (affectedLayer, newFontSize), _ ->
        match affectedLayer with
        | LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | TextItem text ->
                    let markerElement =
                        "id-" + (LayerId.toString affectedLayerUnit.id)
                        |> Browser.document.getElementById
                    let textareaElement =
                        markerElement.querySelector("textarea")
                        :?> Browser.HTMLTextAreaElement
                    let newText = textareaElement.value
                    text
                    |> TextItem.setText newText
                    |> TextItem.setFontSize newFontSize
                    |> TextItem
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2WardItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _ ->
                    affectedLayerUnit.item
            let newLayerUnit =
                { affectedLayerUnit with item = newItem }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find newLayerUnit.id
            let cmds =
                [ Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    newLayerUnit.id
                    affectedMarker
                  Cmd.visuallySelectMapMarkerByLayerId newLayerUnit.id
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | ChangeWidthForLayer (affectedLayer, newWidth), _ ->
        match affectedLayer with
        | LayerUnit affectedLayerUnit ->
            let newItem =
                match affectedLayerUnit.item with
                | TextItem text ->
                    let markerElement =
                        "id-" + (LayerId.toString affectedLayerUnit.id)
                        |> Browser.document.getElementById
                    let textareaElement =
                        markerElement.querySelector("textarea")
                        :?> Browser.HTMLTextAreaElement
                    let newText = textareaElement.value
                    text
                    |> TextItem.setText newText
                    |> TextItem.setWidth newWidth
                    |> TextItem
                | Dota2Item (Dota2BuildingItem _)
                | Dota2Item (Dota2HeroItem _)
                | Dota2Item (Dota2LaneCreepItem _)
                | Dota2Item (Dota2NeutralCampItem _)
                | Dota2Item (Dota2WardItem _)
                | Dota2Item (Dota2AbilityItem _)
                | GenericItem _ ->
                    affectedLayerUnit.item
            let newLayerUnit =
                { affectedLayerUnit with item = newItem }
            let newLayers =
                currentModel.UndoableModel.present.Layers
                |> updateLayerUnitInLayers newLayerUnit
            let newUndoableModel =
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            let newModel =
                { currentModel with
                    SelectedLayers = [ newLayerUnit |> LayerUnit ]
                    UndoableModel = newUndoableModel }
            let affectedMarker =
                newModel.UndoableModel.present.MapMarkers
                |> Map.find affectedLayerUnit.id
            let cmds =
                [ Cmd.recreateLeafletMarker
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newItem
                    affectedLayerUnit.id
                    affectedMarker
                  Cmd.ofMsg SendCollaborationUpdate ]
            newModel, Cmd.batch cmds, Cmd.none
        | LayerGroup _ ->
            currentModel, Cmd.none, Cmd.none

    | DeleteLayerFromInfoPane layerToDelete, _
    | DeleteLayerFromTimeline layerToDelete, _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> filterLayerFromLayers layerToDelete
            |> filterLayerWithinGroupsFromLayers layerToDelete
        let newLeafletMarkers =
            currentModel.UndoableModel.present.MapMarkers
            |> Map.remove (Layer.getId layerToDelete)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers
                    MapMarkers = newLeafletMarkers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                SelectedLayers = []
                UndoableModel = newUndoableModel }
        let markerToDelete =
            currentModel.UndoableModel.present.MapMarkers
            |> Map.find (Layer.getId layerToDelete)
        let cmds =
            [ Cmd.deleteLeafletCirclesForLayer
                newModel.HeroAttackRangeCircles
                newModel.WardSightCircles
                newModel.AbilityEffectCircles
                markerToDelete
                layerToDelete
              Cmd.removeLeafletMarkerFromMap markerToDelete
              Cmd.ofMsg CloseSidebar
              Cmd.ofMsg SendCollaborationUpdate ]
        newModel, Cmd.batch cmds, Cmd.none

    | DeleteLayersFromTimeline layersToDelete, _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> filterLayersFromLayers layersToDelete
            |> filterLayersWithinGroupsFromLayers layersToDelete
        let newLeafletMarkers =
            currentModel.UndoableModel.present.MapMarkers
            |> Map.filter (fun layerId _ ->
                layersToDelete
                |> List.map Layer.getId
                |> List.contains layerId
                |> not)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    Layers = newLayers
                    MapMarkers = newLeafletMarkers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with
                SelectedLayers = []
                UndoableModel = newUndoableModel }
        let markersToDelete =
            currentModel.UndoableModel.present.MapMarkers
            |> Map.filter (fun layerId _ ->
                layersToDelete
                |> List.map Layer.getId
                |> List.contains layerId)
        let cmds =
            [ Cmd.deleteLeafletCirclesForLayers
                newModel.HeroAttackRangeCircles
                newModel.WardSightCircles
                markersToDelete
                layersToDelete
              Cmd.removeLeafletMarkersFromMap
                (markersToDelete |> Map.toList |> List.map snd)
              Cmd.ofMsg SendCollaborationUpdate ]
        newModel, Cmd.batch cmds, Cmd.none

    | UpdateAllLayersTextMarkerContent newLayers, _
    | UpdateAllLayersBuildingStates newLayers, _ ->
        let newUndoableModel =
            if currentModel.UndoableModel.present.Layers <> newLayers then
                let newState =
                   { currentModel.UndoableModel.present with
                        Layers = newLayers }
                currentModel.UndoableModel
                |> History.setPresent newState
            else currentModel.UndoableModel
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        let cmds =
            [ Cmd.recreateLeafletMarkers
                (currentModel.CurrentMode |> Mode.isViewerMode)
                newModel.UndoableModel.present.Layers
                newModel.UndoableModel.present.MapMarkers
              Cmd.ofMsg SendCollaborationUpdate ]
        newModel, Cmd.batch cmds, Cmd.none

    | RenameLayer renamedLayer, _ ->
        let newLayers =
            currentModel.UndoableModel.present.Layers
            |> List.map (createRenamedLayer renamedLayer)
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with Layers = newLayers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.none, Cmd.none

    | DrawingStrokeAdded (strokeId, newDrawingStroke), _ ->
        let drawingCoordinates =
            newDrawingStroke.getLatLngs ()
            |> Seq.map (fun latlng ->
                { latitude = latlng.lat; longitude = latlng.lng })
            |> Seq.toList
        let newDrawingStrokes =
            currentModel.UndoableModel.present.DrawingStrokes
            |> Map.add strokeId { coordinates = drawingCoordinates }
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    DrawingStrokes = newDrawingStrokes }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | DrawingStrokeRemoved strokeId, _ ->
        let newDrawingStrokes =
            currentModel.UndoableModel.present.DrawingStrokes
            |> Map.remove strokeId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    DrawingStrokes = newDrawingStrokes }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | LinearRulerAdded (rulerId, newRuler), _ ->
        let rulerLatlngs = (fst newRuler).getLatLngs ()
        let rulerPoints =
            { originPoint =
                { latitude = rulerLatlngs.[0].lat
                  longitude = rulerLatlngs.[0].lng }
              endPoint =
                { latitude = rulerLatlngs.[1].lat
                  longitude = rulerLatlngs.[1].lng } }
        let newRulers =
            currentModel.UndoableModel.present.LinearRulers
            |> Map.add rulerId rulerPoints
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    LinearRulers = newRulers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | LinearRulerRemoved rulerId, _ ->
        let newRulers =
            currentModel.UndoableModel.present.LinearRulers
            |> Map.remove rulerId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    LinearRulers = newRulers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | RadialRulerAdded (rulerid, newRuler), _ ->
        let _, linearRuler, _ = newRuler
        let rulerLatlngs = linearRuler.getLatLngs ()
        let rulerPoints =
            { originPoint =
                { latitude = rulerLatlngs.[0].lat
                  longitude = rulerLatlngs.[0].lng }
              endPoint =
                { latitude = rulerLatlngs.[1].lat
                  longitude = rulerLatlngs.[1].lng } }
        let newRulers =
            currentModel.UndoableModel.present.RadialRulers
            |> Map.add rulerid rulerPoints
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    RadialRulers = newRulers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | RadialRulerRemoved rulerId, _ ->
        let newRulers =
            currentModel.UndoableModel.present.RadialRulers
            |> Map.remove rulerId
        let newUndoableModel =
            let newState =
               { currentModel.UndoableModel.present with
                    RadialRulers = newRulers }
            currentModel.UndoableModel
            |> History.setPresent newState
        let newModel =
            { currentModel with UndoableModel = newUndoableModel }
        newModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | Undo, _ ->
        match currentModel.Map with
        | Loaded map ->
            let newUndoableModel =
                currentModel.UndoableModel
                |> History.goBackOne
            newUndoableModel
            |> performUndoOrRedoWithNewUndoableModel map
        | Loading ->
            currentModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | Redo, _ ->
        match currentModel.Map with
        | Loaded map ->
            let newUndoableModel =
                currentModel.UndoableModel
                |> History.goForwardOne
            newUndoableModel
            |> performUndoOrRedoWithNewUndoableModel map
        | Loading ->
            currentModel, Cmd.ofMsg SendCollaborationUpdate, Cmd.none

    | FetchSessionFailed exn, _ ->
        let newNotification = createExceptionOccuredNotification exn
        let newModel =
            { currentModel with Session = None }
            |> addNotificationToModel newNotification
        newModel, Cmd.none, ErrorCmd.unhandledException exn

    | SaveSessionFailed (exn, savingNotification), _ ->
        let newNotification = createExceptionOccuredNotification exn
        let newModel =
            { currentModel with SavingSession = false }
            |> addNotificationToModel newNotification
        newModel, Cmd.ofMsg (DismissNotification savingNotification), Cmd.none

    | SessionOperationFailed exn, _ ->
        let newNotification = createExceptionOccuredNotification exn
        let newModel =
            currentModel
            |> addNotificationToModel newNotification
        newModel, Cmd.none, Cmd.none

    | SaveSession user, _ ->
        match currentModel.CurrentMode with
        | DemoMode ->
            let newNotification = createUpgradePromptNotification ()
            let newModel =
                currentModel
                |> addNotificationToModel newNotification
            let cmd =
                Cmd.dismissNotificationLater newNotification
            newModel, cmd, Cmd.none
        | EditorMode _ ->
            let newNotification = createSessionSavingNotification ()
            let newModel =
                { currentModel with SavingSession = true }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.ofMsg Pause
                  Cmd.saveCurrentSession app user newNotification currentModel
                  Cmd.dismissNotificationLater newNotification ]
            newModel, Cmd.batch cmds, Cmd.none
        | ViewerMode _ ->
            currentModel, Cmd.none, Cmd.none

    | MakeSessionPrivate sessionId, _ ->
        currentModel, Cmd.makeSessionPrivate app sessionId, Cmd.none

    | MakeSessionPublic sessionId, _ ->
        currentModel, Cmd.makeSessionPublic app sessionId, Cmd.none

    | ShareSession sessionId, _ ->
        let newNotification = createSessionSharedNotification sessionId
        let newModel =
            currentModel
            |> addNotificationToModel newNotification
        let cmds =
            [ Cmd.dismissNotificationLater newNotification
              (UserAction.ClickedShareSession |> AnalyticsCmd.strategiserAction app) ]

        newModel, Cmd.batch cmds, Cmd.none

    | RenameSession (sessionId, newName), _ ->
        currentModel, Cmd.renameSession app newName sessionId, Cmd.none

    | DeleteSession sessionId, _ ->
        currentModel, Cmd.deleteSession app sessionId, Cmd.none

    | FetchUserSessions user, _ ->
        let newModel =
            { currentModel with ExistingSessions = Some Fetching }
        newModel, Cmd.fetchSessionsForUser app user, Cmd.none

    | GoBackFromLoadSessionsPanel, _ ->
        let newModel =
            { currentModel with ExistingSessions = None }
        newModel, Cmd.none, Cmd.none

    | UserSessionsFetched sessions, _ ->
        let newModel =
            { currentModel with
                ExistingSessions = Fetched sessions |> Some }
        newModel, Cmd.none, Cmd.none

    | FetchUserSessionsFailed exn, _ ->
        let newNotification = createExceptionOccuredNotification exn
        let newModel =
            { currentModel with
                ExistingSessions = None }
            |> addNotificationToModel newNotification
        newModel, Cmd.none, Cmd.none

    | SessionCreated (createdSessionId, session), _ ->
        // DISABLED: Convert Session into JSON and print it to create a demo session.
        //           Uncomment to enable it.
        // let sessionJson = Encode.Auto.toString(4, session)
        // Fable.Import.Browser.console.log sessionJson

        let newNotification = createSessionCreatedNotification createdSessionId
        let newModel =
            { currentModel with
                SavingSession = false
                Session = Fetched (createdSessionId, session) |> Some
                UserMadeChanges = false }
            |> addNotificationToModel newNotification
        let modifyUrlCmd =
            (Route.Strategiser (currentModel.Game, Some createdSessionId))
            |> Route.getPath
            |> Navigation.modifyUrl
        let cmds =
            Cmd.batch
                [ Cmd.dismissNotificationLater newNotification
                  modifyUrlCmd ]
        newModel, cmds, Cmd.none

    | SessionSaved (savedSessionId, newSession, savingNotification), _ ->
        let newNotification = createSessionSavedNotification savedSessionId
        let newModel =
            { currentModel with
                SavingSession = false
                Session = Fetched (savedSessionId, newSession) |> Some
                UserMadeChanges = false }
            |> addNotificationToModel newNotification
        let cmds =
            Cmd.batch
                [ Cmd.ofMsg (DismissNotification savingNotification)
                  Cmd.dismissNotificationLater newNotification
                  (UserAction.SavedSession |> AnalyticsCmd.strategiserAction app) ]
        newModel, cmds, Cmd.none

    | SessionMadePrivate privatedSessionId, _ ->
        let analyticsCmd =
            (UserAction.ToggledSessionPrivacy false |> AnalyticsCmd.strategiserAction app)
        match currentModel.Session, currentModel.ExistingSessions with
        | Some (Fetched (currentSessionId, currentSession)),
          Some (Fetched existingSessions) when currentSessionId = privatedSessionId ->
            let newNotification =
                createSessionMadePrivateNotification privatedSessionId
            let newSession =
                { currentSession with isPublic = false }
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = privatedSessionId then id, newSession
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some
                    Session = Fetched (privatedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | Some (Fetched (currentSessionId, currentSession)),
          _ when currentSessionId = privatedSessionId ->
            let newNotification =
                createSessionMadePrivateNotification privatedSessionId
            let newSession =
                { currentSession with isPublic = false }
            let newModel =
                { currentModel with
                    Session = Fetched (privatedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | _, Some (Fetched existingSessions) ->
            let newNotification = createSessionMadePrivateNotification ()
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = privatedSessionId then
                        id, { session with isPublic = false }
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | _ ->
            currentModel, Cmd.none, Cmd.none

    | SessionMadePublic publicedSessionId, _ ->
        let analyticsCmd =
            (UserAction.ToggledSessionPrivacy true |> AnalyticsCmd.strategiserAction app)
        match currentModel.Session, currentModel.ExistingSessions with
        | Some (Fetched (currentSessionId, currentSession)),
          Some (Fetched existingSessions) when currentSessionId = publicedSessionId ->
            let newNotification =
                createSessionMadePublicNotification publicedSessionId
            let newSession =
                { currentSession with isPublic = true }
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = publicedSessionId then id, newSession
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some
                    Session = Fetched (publicedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | Some (Fetched (currentSessionId, currentSession)),
          _ when currentSessionId = publicedSessionId ->
            let newNotification =
                createSessionMadePublicNotification publicedSessionId
            let newSession =
                { currentSession with isPublic = true }
            let newModel =
                { currentModel with
                    Session = Fetched (publicedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | _, Some (Fetched existingSessions) ->
            let newNotification = createSessionRenamedNotification ()
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = publicedSessionId then
                        id, { session with isPublic = true }
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some }
                |> addNotificationToModel newNotification
            let cmds =
                [ Cmd.dismissNotificationLater newNotification
                  analyticsCmd ]

            newModel, Cmd.batch cmds, Cmd.none
        | _ ->
            currentModel, Cmd.none, Cmd.none

    | SessionRenamed (renamedSessionId, newName), _ ->
        match currentModel.Session, currentModel.ExistingSessions with
        | Some (Fetched (currentSessionId, currentSession)),
          Some (Fetched existingSessions) when currentSessionId = renamedSessionId ->
            let newNotification = createSessionRenamedNotification ()
            let newSession =
                { currentSession with name = newName }
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = renamedSessionId then id, newSession
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some
                    Session = Fetched (renamedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            newModel, Cmd.dismissNotificationLater newNotification, Cmd.none
        | Some (Fetched (currentSessionId, currentSession)),
          _ when currentSessionId = renamedSessionId ->
            let newNotification = createSessionRenamedNotification ()
            let newSession =
                { currentSession with name = newName }
            let newModel =
                { currentModel with
                    Session = Fetched (renamedSessionId, newSession) |> Some }
                |> addNotificationToModel newNotification
            newModel, Cmd.dismissNotificationLater newNotification, Cmd.none
        | _, Some (Fetched existingSessions) ->
            let newNotification = createSessionRenamedNotification ()
            let newExistingSessions =
                existingSessions
                |> List.map (fun (id, session) ->
                    if id = renamedSessionId then
                        id, { session with name = newName }
                    else id, session)
            let newModel =
                { currentModel with
                    ExistingSessions = Fetched newExistingSessions |> Some }
                |> addNotificationToModel newNotification
            newModel, Cmd.dismissNotificationLater newNotification, Cmd.none
        | _ ->
            currentModel, Cmd.none, Cmd.none

    | DemoSessionError, _ ->
        Browser.console.warn "Failed to process demo session."
        currentModel, Cmd.none, Cmd.none
    | DemoSessionFetched session, _ ->
        let sessionId = SessionId "demo"
        let isViewerMode = false
        let newNotification = createSessionLoadedNotification isViewerMode sessionId
        let newModel =
            { currentModel with
                CurrentMode = DemoMode
                Session = Fetched (sessionId, session) |> Some
                TimelineOpened = isViewerMode |> not
                UserMadeChanges = false }
            |> addNotificationToModel newNotification

        let cmds =
            Cmd.batch [ Cmd.populateModelWithLatestSaveFromSession session newModel
                        Cmd.dismissNotificationLater newNotification ]

        newModel, cmds, Cmd.none

    | SessionFetched (sessionId, session), _ ->

        // Filter session layers from invalid markers e.g Shrines
        // since Shrines are removed as of 7.24
        let isLayerUnitValid(layerUnit : LayerUnit) =
            match layerUnit.item with
            | Dota2Item (Dota2BuildingItem { building = (Building.Shrine _) }) ->
                false
            | _ ->
                true
        let removeShrinesFromLayerUnits layer =
            match layer with
            | LayerUnit { item = Dota2Item (Dota2BuildingItem { building = (Building.Shrine _) }) } ->
                false
            | LayerUnit layerUnit -> true
            | LayerGroup group -> true
        let removeShrinesFromLayerGroups layer =
            match layer with
            | LayerUnit layerUnit -> LayerUnit layerUnit
            | LayerGroup group ->
                let filteredChildren =
                    group.children
                    |> List.filter (isLayerUnitValid)
                { group with children = filteredChildren }
                |> LayerGroup
        let filteredLayers =
            session.latestSave.layers
            |> List.filter removeShrinesFromLayerUnits
            |> List.map removeShrinesFromLayerGroups
        let filteredLatestSave = {
            session.latestSave with layers = filteredLayers
        }
        let filteredSession = {
            session with latestSave = filteredLatestSave
        }

        let userIsOwner =
            optionalUser
            |> Option.map (fun user -> user.id = filteredSession.userId)
            |> Option.defaultValue false
        let isViewerMode = not userIsOwner && filteredSession.isPublic
        let currentMode =
            if isViewerMode then
                ViewerMode (SessionViewer false)
            else
                EditorMode false
        let newNotification = createSessionLoadedNotification isViewerMode sessionId
        let newModel =
            { currentModel with
                CurrentMode = currentMode
                Session = Fetched (sessionId, filteredSession) |> Some
                TimelineOpened = isViewerMode |> not
                UserMadeChanges = false }
            |> addNotificationToModel newNotification

        let maybeEnterSessionCmd =
            if filteredSession.isPublic then
                optionalUser
                |> Option.map (fun user ->
                    Cmd.enterSession app user userIsOwner sessionId)
                |> Option.defaultValue Cmd.none
            else Cmd.none
        let maybeSendCollabUpdateCmd =
            if userIsOwner && currentModel.CurrentMode |> Mode.isHostingCollaboration then
                Cmd.ofMsg SendCollaborationUpdate
            else Cmd.none
        let cmds =
            Cmd.batch
                [ Cmd.populateModelWithLatestSaveFromSession filteredSession newModel
                  Cmd.dismissNotificationLater newNotification
                  maybeEnterSessionCmd
                  maybeSendCollabUpdateCmd ]

        newModel, cmds, Cmd.none

    | SessionNotFound, _ ->
        { currentModel with Session = None }, Cmd.none, Cmd.none

    | SessionDeleted deletedSessionId, _ ->
        let deleteFromExistingSessions () =
            match currentModel.ExistingSessions with
            | Some (Fetched userSessions) ->
                let newExistingSessions =
                    userSessions
                    |> List.filter (fun (id, _) -> id <> deletedSessionId)
                let newModel =
                    { currentModel with
                        ExistingSessions = Fetched newExistingSessions |> Some }
                newModel, Cmd.none, Cmd.none
            | Some Fetching
            | None ->
                currentModel, Cmd.none, Cmd.none
        match currentModel.Session with
        | Some (Fetched (sessionId, _)) ->
            if deletedSessionId = sessionId then
                let newNotification = createSessionDeletedNotification ()
                let newModel =
                    currentModel
                    |> addNotificationToModel newNotification
                Browser.window.location.href <- ((Game.Dota2, None) |> Route.Strategiser |> Route.getPath)
                newModel, Cmd.none, Cmd.none
            else deleteFromExistingSessions ()
        | Some Fetching
        | None ->
            deleteFromExistingSessions ()

    | ModelPopulatedFromSession newModel, _ ->
        let markersToRemove =
            currentModel.UndoableModel.present.MapMarkers
            |> getTheDifferenceBetweenMaps
                newModel.UndoableModel.present.MapMarkers
        let newModel =
            { newModel with CreateMapMarkersForExistingLayers = true }
        newModel, Cmd.removeLeafletMarkersFromMap markersToRemove, Cmd.none

    | ModelPopulatedFromCollaborationUpdate (newModel, mapMarkerPositions), _ ->
        let markersToRemove =
            currentModel.UndoableModel.present.MapMarkers
            |> getTheDifferenceBetweenMaps
                newModel.UndoableModel.present.MapMarkers
        let newModel =
            { newModel with CreateMapMarkersForNewLayers = true }
        let cmds =
            [ Cmd.removeLeafletMarkersFromMap markersToRemove
              Cmd.updateLeafletMarkerPositions
                mapMarkerPositions
                newModel.UndoableModel.present.MapMarkers ]
        newModel, Cmd.batch cmds, Cmd.none

    | CreatedMapMarkersForExistingLayers, _ ->
        let newModel =
            { currentModel with CreateMapMarkersForExistingLayers = false }
        let cmd =
            Cmd.recreateLeafletMarkers
                (currentModel.CurrentMode |> Mode.isViewerMode)
                newModel.UndoableModel.present.Layers
                newModel.UndoableModel.present.MapMarkers
        newModel, cmd, Cmd.none

    | CreatedMapMarkersForNewLayers, _ ->
        match currentModel.Map with
        | Loaded map ->
            let newModel =
                { currentModel with CreateMapMarkersForNewLayers = false }
            let cmds =
                [ Cmd.recreateLeafletMarkers
                    (currentModel.CurrentMode |> Mode.isViewerMode)
                    newModel.UndoableModel.present.Layers
                    newModel.UndoableModel.present.MapMarkers
                  Cmd.syncPositionOfLeafletMarkersFromModel
                    map
                    newModel.UndoableModel.present.MapMarkers
                  Cmd.saveAllBuildingStatesToLayers
                    newModel.CurrentTime
                    newModel.UndoableModel.present.Layers ]
            newModel, Cmd.batch cmds, Cmd.none
        | Loading ->
            currentModel, Cmd.none, Cmd.none

    | DismissNotification notificationToDismiss, _ ->
        let newNotifications =
            currentModel.Notifications
            |> List.filter (fun notification ->
                (notification |> Notification.getId) <> (notificationToDismiss |> Notification.getId))
        let newModel =
            { currentModel with Notifications = newNotifications }
        newModel, Cmd.none, Cmd.none

    | UserMadeChanges, _ ->
        match currentModel.CurrentMode with
        | ViewerMode _ ->
            currentModel, Cmd.none, Cmd.none
        | DemoMode
        | EditorMode _ ->
            let newModel =
                { currentModel with UserMadeChanges = true }
            newModel, Cmd.none, Cmd.none

    | ResetUserMadeChanges, _ ->
        let newModel =
            { currentModel with UserMadeChanges = false }
        newModel, Cmd.none, Cmd.none

    | InitSessionViewersFirestoreListener, _ ->
        let newModel =
            { currentModel with SessionViewersListenerIsActive = true }
        newModel, Cmd.none, Cmd.none

    | SessionViewerListenerError exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | NewSessionViewersUpdate (online, offline), _ ->
        let newOnlineCollabSessionViewers =
            let filtered =
                currentModel.OnlineCollaborativeSessionViewers
                |> List.filter (fun viewer ->
                    offline
                    |> List.tryFind (fun offliner -> offliner.id = viewer.id)
                    |> Option.isNone)
            List.concat
                [ online
                  filtered ]
        let newModel =
            { currentModel with
                OnlineCollaborativeSessionViewers = newOnlineCollabSessionViewers }
        newModel, Cmd.none, Cmd.none

    | SessionEntered, _ ->
        currentModel, Cmd.none, Cmd.none

    | SessionEnterFailed exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | EnableCollaborationMode, _ ->
        match currentModel.Session with
        | Some (Fetched (sessionId, _)) ->
            let newModel = { currentModel with TogglingCollaborationMode = true }
            newModel, Cmd.enableCollaboration app sessionId, Cmd.none
        | Some Fetching
        | None ->
            currentModel, Cmd.none, Cmd.none

    | EnableCollaborationModeFailed exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | EnabledCollaborationMode, _ ->
        let newModel =
            { currentModel with
                CurrentMode = currentModel.CurrentMode |> Mode.enableCollaboration
                StartedCollaborationSessionAt = DateTimeOffset.Now
                TogglingCollaborationMode = false }
        let cmds =
            [ Cmd.ofMsg SendCollaborationUpdate
              UserAction.StartedCollaborationSession
              |> AnalyticsCmd.strategiserAction app ]
        newModel, Cmd.batch cmds, Cmd.none

    | DisableCollaborationMode, _ ->
        match currentModel.Session with
        | Some (Fetched (sessionId, _)) ->
            let newModel = { currentModel with TogglingCollaborationMode = true }
            newModel, Cmd.disableCollaboration
                          app
                          sessionId
                          currentModel.StartedCollaborationSessionAt, Cmd.none
        | Some Fetching
        | None ->
            currentModel, Cmd.none, Cmd.none

    | DisabledCollaborationMode duration, _ ->
        let newModel =
            { currentModel with
                CurrentMode = currentModel.CurrentMode |> Mode.disableCollaboration
                TogglingCollaborationMode = false }
        newModel, UserAction.EndedCollaborationSession duration
                  |> AnalyticsCmd.strategiserAction app, Cmd.none

    | DisableCollaborationModeFailed exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | SendCollaborationUpdate, _ ->
        if currentModel.CurrentMode |> Mode.isHostingCollaboration then
            currentModel, Cmd.sendCollaborationUpdate app currentModel, Cmd.none
        else
            currentModel, Cmd.none, Cmd.none

    | CollaborationUpdateSent, _ ->
        currentModel, Cmd.none, Cmd.none

    | CollaborationUpdateFailed exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | InitCollaborationUpdateFirestoreListener, _ ->
        let newModel =
            { currentModel with CollaborationUpdateListenerIsActive = true }
        newModel, Cmd.none, Cmd.none

    | ReceivedCollaborationUpdate
        (collabModeEnabled, playbackState, time, mapMarkerPositions, update), _ ->
        let isCurrentlyCollaborating =
            currentModel.CurrentMode |> Mode.isCollaborating
        if collabModeEnabled && not isCurrentlyCollaborating then
            let newModel =
                { currentModel with
                    CurrentMode = currentModel.CurrentMode |> Mode.enableCollaboration }
            newModel, Cmd.batch
                        [ Cmd.ofMsg CollaborationSessionStarted
                          Cmd.ofMsg
                              (ApplyCollaborationUpdate
                                   (time, mapMarkerPositions, update)) ], Cmd.none
        else if not collabModeEnabled && isCurrentlyCollaborating then
            let newModel =
                { currentModel with
                    CurrentMode = currentModel.CurrentMode |> Mode.disableCollaboration }
            newModel, Cmd.batch
                        [ Cmd.ofMsg Pause
                          Cmd.ofMsg CollaborationSessionEnded ], Cmd.none
        else if collabModeEnabled then
            let playOrPauseCmd =
                if playbackState = Paused then Cmd.ofMsg Pause
                else Cmd.ofMsg Play
            currentModel, Cmd.batch
                          [ playOrPauseCmd
                            (ApplyCollaborationUpdate (time, mapMarkerPositions, update))
                            |> Cmd.ofMsg ], Cmd.none

        else
            currentModel, Cmd.none, Cmd.none

    | CollaborationSessionStarted, _ ->
        currentModel, Cmd.none, Cmd.none

    | CollaborationSessionEnded, _ ->
        match currentModel.Session with
        | Some (Fetched session) ->
            currentModel, Cmd.ofMsg (SessionFetched session), Cmd.none
        | Some Fetching
        | None ->
            currentModel, Cmd.none, Cmd.none

    | ApplyCollaborationUpdate (time, mapMarkerPositions, update), _ ->
        let cmd =
            match update with
            | Some update ->
                Cmd.batch
                    [ Cmd.populateModelWithCollaborationUpdate
                          time
                          mapMarkerPositions
                          update
                          currentModel ]
            | None ->
                Cmd.none

        currentModel, cmd, Cmd.none

    | CollaborationUpdateListenerError exn, _ ->
        currentModel, Cmd.none, Cmd.none

    | UserAttemptedActionWhileLocked, _ ->
        let newNotification = createAppLockedNotification ()
        let newModel =
            currentModel
            |> addNotificationToModel newNotification
        let cmds =
            Cmd.batch
                [ Cmd.dismissNotificationLater newNotification ]
        newModel, cmds, Cmd.none

    | Error, _ ->
        currentModel, Cmd.none, Cmd.none

let private populateMapWithBuildings dispatch =
    let createDota2BuildingMapItem dispatch building =
        let id = Guid.NewGuid ()
        let coordinates = Dota2.Building.getCoordinates building
        let defaultBuildingState =
            match building with
            | Building.Outpost side ->
                (BuildingState.Captured side)
            | building ->
                BuildingState.NotDestroyed
        let defaultCapturedBy =
            match building with
            | Building.Outpost side -> side
            | _ -> FactionType.Dire
        let item =
            { attackRangeCircleVisibility =
                AttackRangeCircleVisibility.NotVisible
              building = building
              state = defaultBuildingState }
            |> Dota2BuildingItem
            |> Dota2Item
        let latlng =
            { latitude =
                Leaflet.convertDota2CoordinateToLatOrLng coordinates.x
              longitude =
                Leaflet.convertDota2CoordinateToLatOrLng coordinates.y }
        let keyframeInfo : KeyframeableAttributes =
            { isGrey = false
              opacity = 1.
              position = latlng
              capturedBy = defaultCapturedBy
              scale = 1. }
        let layer : LayerUnit =
            { id = LayerId.ofGuid id
              item = item
              keyframes = Map.empty.Add(0, keyframeInfo)
              locked = true
              name = Dota2.Building.getName building
              perpetual = true
              selected = false
              visible = true }
        let leafletMarker =
            Leaflet.createMarkerAtPosition
                false
                Leaflet.LeafletMarkerZIndex.DefaultMarker
                !^(Leaflet.convertDota2CoordinateToLatOrLng coordinates.x,
                   Leaflet.convertDota2CoordinateToLatOrLng coordinates.y)
        let clickHandler _ =
            LayerId.ofGuid id |> SelectMapMarker |> dispatch
            Panning |> SelectSidebarTool |> dispatch
        leafletMarker.addEventListener ("click", clickHandler) |> ignore
        (layer, leafletMarker)
        |> Some
    let addBuildings markers =
        Dota2.Building.all
        |> List.map (createDota2BuildingMapItem dispatch)
        |> List.choose id
        |> List.append markers
    List.empty
    |> addBuildings
    |> AddStaticItemsToMap
    |> dispatch

let private populateMapWithNeutralCamps dispatch =
    let createDota2NeutralCampMapItem dispatch camp =
        let id = Guid.NewGuid ()
        let coordinates = Dota2.NeutralCamp.getCoordinates camp
        match coordinates with
        | Some { x = x; y = y } ->
            let item =
                { camp = camp }
                |> Dota2NeutralCampItem
                |> Dota2Item
            let coordinates =
                { latitude = Leaflet.convertDota2CoordinateToLatOrLng x
                  longitude = Leaflet.convertDota2CoordinateToLatOrLng y }
            let keyframeInfo : KeyframeableAttributes =
                { isGrey = false
                  opacity = 1.
                  position = coordinates
                  capturedBy = FactionType.Dire
                  scale = 1. }
            let layer : LayerUnit =
                { id = LayerId.ofGuid id
                  item = item
                  keyframes = Map.empty.Add(0, keyframeInfo)
                  locked = true
                  name = Dota2.NeutralCamp.getName camp
                  perpetual = true
                  selected = false
                  visible = true }
            let leafletMarker =
                Leaflet.createMarkerAtPosition
                    false
                    Leaflet.LeafletMarkerZIndex.DefaultMarker
                    !^(Leaflet.convertDota2CoordinateToLatOrLng x,
                       Leaflet.convertDota2CoordinateToLatOrLng y)
            let clickHandler _ =
                LayerId.ofGuid id |> SelectMapMarker |> dispatch
                Panning |> SelectSidebarTool |> dispatch
            leafletMarker.addEventListener ("click", clickHandler) |> ignore
            (layer, leafletMarker)
            |> Some
        | None -> None
    let addNeutralCamps markers =
        Dota2.NeutralCamp.all
        |> List.map (createDota2NeutralCampMapItem dispatch)
        |> List.choose id
        |> List.append markers
    List.empty
    |> addNeutralCamps
    |> AddStaticItemsToMap
    |> dispatch

let createAndAddMapMarkerToMapForLayerUnit
    dispatch
    (map : Leaflet.Map)
    isViewerMode
    layerUnit =
    let { position = firstPosition } =
        layerUnit.keyframes
        |> Map.find 0
    let firstLatLng =
        (firstPosition.latitude, firstPosition.longitude)
        |> Leaflet.createLatLngFromTuple
    let leafletMarker =
        Leaflet.createMarkerAtPosition
            (not isViewerMode)
            Leaflet.LeafletMarkerZIndex.UserMarker
            !^firstLatLng
    match layerUnit.item with
    | Dota2Item (Dota2AbilityItem abilityItem) ->
        let ability = abilityItem.ability
        // add event handlers to leaflet marker
        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        let dota2AbilityEffectRadius =
            Dota2.Ability.getEffectRadius ability

        match dota2AbilityEffectRadius with
        | radius when Distance.toInt radius > 0 ->
            let latLngAbilityEffectRadius =
                dota2AbilityEffectRadius
                |> Leaflet.convertDota2DistanceToLatLngDistance

            let abilityEffectCircle : Circle<obj> =
                Leaflet.createAbilityEffectCircle
                    (leafletMarker.getLatLng ())
                    latLngAbilityEffectRadius
            !^map |> abilityEffectCircle.addTo |> ignore

            abilityEffectCircle
            |> AbilityEffectCircle
            |> AddAbilityEffectCircleToStorage
            |> dispatch

            let moveHandler (event : LeafletEvent) =
                match event.target with
                | Some marker ->
                    // Update the circle's position
                    let markerPosition : LatLng = marker?_latlng
                    abilityEffectCircle.setLatLng(!^markerPosition)
                    |> ignore
                | None -> ()
            leafletMarker.addEventListener ("move", moveHandler)
            |> ignore
        | _ -> ()

        let movedHandler (event : LeafletEvent) =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | Dota2Item (Dota2HeroItem heroItem) ->
        // Store the circle to be able to remove it later
        let attackRangeAsRadius =
            (Dota2.Hero.getAttack heroItem.hero).range
            |> Leaflet.convertDota2DistanceToLatLngDistance
        let attackRadiusCircle : Circle<obj> =
            Leaflet.createAttackRadiusCircle
                (layerUnit.id.ToString ())
                (leafletMarker.getLatLng ())
                attackRangeAsRadius
                0.
        !^map |> attackRadiusCircle.addTo |> ignore
        attackRadiusCircle
        |> HeroAttackRangeCircle
        |> AddHeroAttackRangeCircleToStorage
        |> dispatch
        // add event handlers to leaflet marker
        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        let movedHandler event =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        let dragHandler (event : LeafletEvent) =
            match event.target with
            | Some _ ->
                // Update the circle's position
                match attackRadiusCircle.getElement () with
                | Some path ->
                    let initialLatLng = attackRadiusCircle.getLatLng ()
                    let (lngDistance, latDistance) =
                        let rawLngDistance =
                            event?latlng?lng - initialLatLng.lng
                        let rawLatDistance =
                            initialLatLng.lat - event?latlng?lat
                        let zoomFactor = 2. ** map.getZoom ()
                        (rawLngDistance * zoomFactor,
                         rawLatDistance * zoomFactor)
                    let newTransform =
                        sprintf
                             "translateX(%fpx) translateY(%fpx)"
                             lngDistance
                             latDistance
                    path
                    |> Fable.setElementInlineStyleProperty
                        "transform"
                        newTransform

                | None -> ()
            | None -> ()
        leafletMarker.addEventListener ("drag", dragHandler)
        |> ignore
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | Dota2Item (Dota2LaneCreepItem _) ->
        // add event handlers to marker
        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        let movedHandler event =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | Dota2Item (Dota2WardItem wardItem) ->
        // Store the circle to be able to remove it later
        let wardSightCircle : Circle<obj> =
            Leaflet.createSightAroundWard firstLatLng 0.
        !^map |> wardSightCircle.addTo |> ignore
        wardSightCircle
        |> WardSightCircle
        |> AddWardSightCircleToStorage
        |> dispatch
        // add event handlers to marker
        let clickHandler (event : LeafletEvent) =
            match event.target with
            | Some marker ->
                // Show/hide the circle if the Ward is clicked
                let icon : Browser.HTMLElement = marker?_icon
                let iconClassList =
                    icon.firstElementChild.firstElementChild.classList
                if iconClassList.contains "selected" then
                    let sightRadiusAsDistance =
                        Leaflet.convertDota2DistanceToLatLngDistance
                            (Dota2.Ward.getVisionRadius wardItem.kind)
                    if wardSightCircle.getRadius () = 0. then
                        sightRadiusAsDistance
                        |> wardSightCircle.setRadius
                        |> ignore
                    else wardSightCircle.setRadius 0. |> ignore
            | None -> ()
            layerUnit.id |> SelectMapMarker |> dispatch
        let movedHandler (event : LeafletEvent) =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        let moveHandler (event : LeafletEvent) =
            match event.target with
            | Some marker ->
                // Update the circle's position
                let markerPosition : LatLng = marker?_latlng
                wardSightCircle.setLatLng(!^markerPosition) |> ignore
            | None -> ()
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        leafletMarker.addEventListener ("move", moveHandler)
        |> ignore
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | GenericItem _ ->
        // add event handlers to marker
        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        let movedHandler (event : LeafletEvent) =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | TextItem _ ->
        // add event handlers to marker
        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
            Texting |> SelectSidebarTool |> dispatch
        leafletMarker.addEventListener ("click", clickHandler)
        |> ignore
        let movedHandler event =
            (event, layerUnit.id) |> MoveMarker |> dispatch
        leafletMarker.addEventListener ("moveend", movedHandler)
        |> ignore

        (layerUnit, leafletMarker)

    | Dota2Item (Dota2BuildingItem item) ->
        let coordinates = Dota2.Building.getCoordinates item.building
        let leafletMarker =
            Leaflet.createMarkerAtPosition
                false
                Leaflet.LeafletMarkerZIndex.DefaultMarker
                !^(Leaflet.convertDota2CoordinateToLatOrLng coordinates.x,
                   Leaflet.convertDota2CoordinateToLatOrLng coordinates.y)

        let clickHandler _ =
            layerUnit.id |> SelectMapMarker |> dispatch
            Panning |> SelectSidebarTool |> dispatch
        leafletMarker.addEventListener ("click", clickHandler) |> ignore

        (layerUnit, leafletMarker)
    | Dota2Item (Dota2NeutralCampItem item) ->
        let createDota2NeutralCampMapItem camp =
            let coordinates = Dota2.NeutralCamp.getCoordinates camp
            match coordinates with
            | Some { x = x; y = y } ->
                let leafletMarker =
                    Leaflet.createMarkerAtPosition
                        false
                        Leaflet.LeafletMarkerZIndex.DefaultMarker
                        !^(Leaflet.convertDota2CoordinateToLatOrLng x,
                           Leaflet.convertDota2CoordinateToLatOrLng y)
                let clickHandler _ =
                    layerUnit.id |> SelectMapMarker |> dispatch
                    Panning |> SelectSidebarTool |> dispatch

                leafletMarker.addEventListener ("click", clickHandler) |> ignore
                Some (layerUnit, leafletMarker)
            | None -> None

        (createDota2NeutralCampMapItem item.camp).Value

let onDropItemOnMap model dispatch (event : MouseEvent) (item : Item) =
    if model.IsLocked then
        dispatch UserAttemptedActionWhileLocked
    else
        dispatch UserMadeChanges

        match model.Map with
        | Loaded map ->
            let id = Guid.NewGuid ()
            let currentLatLng =
                (event.clientX, event.clientY)
                |> U2.Case2
                |> map.containerPointToLatLng
            let keyframeInfo : KeyframeableAttributes =
                { isGrey = false
                  opacity = 1.
                  position = { latitude = currentLatLng.lat
                               longitude = currentLatLng.lng }
                  capturedBy = FactionType.Dire
                  scale = 1. }
            let keyframes : Strategiser.Keyframes =
                Map.empty
                |> Map.add 0 keyframeInfo
                |> Map.add model.CurrentTime keyframeInfo
            let layer : LayerUnit =
                { id = LayerId.ofGuid id
                  item = item
                  keyframes = keyframes
                  locked = false
                  name = Item.getName item
                  perpetual = false
                  selected = false
                  visible = true }

            let leafletMarker =
                Leaflet.createMarkerAtPosition
                    true
                    Leaflet.LeafletMarkerZIndex.UserMarker
                    (currentLatLng |> U3.Case1)
            match item with
            | Dota2Item (Dota2AbilityItem abilityItem) ->
                let ability = abilityItem.ability
                // add event handlers to leaflet marker
                let clickHandler _ =
                    LayerId.ofGuid id |> SelectMapMarker |> dispatch
                leafletMarker.addEventListener ("click", clickHandler)
                |> ignore

                let dota2AbilityEffectRadius =
                    Dota2.Ability.getEffectRadius ability

                match dota2AbilityEffectRadius with
                | radius when Distance.toInt radius > 0 ->
                    let latLngAbilityEffectRadius =
                        dota2AbilityEffectRadius
                        |> Leaflet.convertDota2DistanceToLatLngDistance

                    let abilityEffectCircle : Circle<obj> =
                        Leaflet.createAbilityEffectCircle
                            currentLatLng
                            latLngAbilityEffectRadius
                    !^map |> abilityEffectCircle.addTo |> ignore

                    abilityEffectCircle
                    |> AbilityEffectCircle
                    |> AddAbilityEffectCircleToStorage
                    |> dispatch
                    let moveHandler (event : LeafletEvent) =
                        match event.target with
                        | Some marker ->
                            // Update the circle's position
                            let markerPosition : LatLng = marker?_latlng
                            abilityEffectCircle.setLatLng(!^markerPosition)
                            |> ignore
                        | None -> ()

                    leafletMarker.addEventListener ("move", moveHandler)
                    |> ignore
                | _ -> ()

                let movedHandler (event : LeafletEvent) =
                    (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
                leafletMarker.addEventListener ("moveend", movedHandler)
                |> ignore

                (layer, leafletMarker)
                |> AddItemToMap
                |> dispatch

            | Dota2Item (Dota2HeroItem heroItem) ->
                // Store the circle to be able to remove it later
                let attackRangeAsRadius =
                    (Dota2.Hero.getAttack heroItem.hero).range
                    |> Leaflet.convertDota2DistanceToLatLngDistance
                let attackRadiusCircle : Circle<obj> =
                    Leaflet.createAttackRadiusCircle
                        (id.ToString ())
                        (leafletMarker.getLatLng ())
                        attackRangeAsRadius
                        0.
                !^map |> attackRadiusCircle.addTo |> ignore
                attackRadiusCircle
                |> HeroAttackRangeCircle
                |> AddHeroAttackRangeCircleToStorage
                |> dispatch
                // add event handlers to leaflet marker
                let clickHandler _ =
                    LayerId.ofGuid id |> SelectMapMarker |> dispatch
                leafletMarker.addEventListener ("click", clickHandler)
                |> ignore
                let movedHandler event =
                    (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
                let moveHandler (event : LeafletEvent) =
                    match event.target with
                    | Some _ ->
                        // Update the circle's position
                        match attackRadiusCircle.getElement () with
                        | Some path ->
                            let initialLatLng = attackRadiusCircle.getLatLng ()
                            let (lngDistance, latDistance) =
                                let rawLngDistance =
                                    event?latlng?lng - initialLatLng.lng
                                let rawLatDistance =
                                    initialLatLng.lat - event?latlng?lat
                                let zoomFactor = 2. ** map.getZoom ()
                                (rawLngDistance * zoomFactor,
                                 rawLatDistance * zoomFactor)
                            let newTransform =
                                sprintf
                                     "translateX(%fpx) translateY(%fpx)"
                                     lngDistance
                                     latDistance
                            path
                            |> Fable.setElementInlineStyleProperty
                                "transform"
                                newTransform
                        | None -> ()
                    | None -> ()
                leafletMarker.addEventListener ("drag", moveHandler)
                |> ignore
                leafletMarker.addEventListener ("moveend", movedHandler)
                |> ignore

                (layer, leafletMarker)
                |> AddItemToMap
                |> dispatch

            | Dota2Item (Dota2LaneCreepItem _) ->
                // add event handlers to marker
                let clickHandler _ =
                    LayerId.ofGuid id |> SelectMapMarker |> dispatch
                leafletMarker.addEventListener ("click", clickHandler)
                |> ignore
                let movedHandler event =
                    (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
                leafletMarker.addEventListener ("moveend", movedHandler)
                |> ignore

                (layer, leafletMarker)
                |> AddItemToMap
                |> dispatch

            | Dota2Item (Dota2WardItem wardItem) ->
                // Store the circle to be able to remove it later
                let wardSightCircle : Circle<obj> =
                    Leaflet.createSightAroundWard currentLatLng 0.
                !^map |> wardSightCircle.addTo |> ignore
                wardSightCircle
                |> WardSightCircle
                |> AddWardSightCircleToStorage
                |> dispatch
                // add event handlers to marker
                let clickHandler (event : LeafletEvent) =
                    match event.target with
                    | Some marker ->
                        // Show/hide the circle if the Ward is clicked
                        let icon : Browser.HTMLElement = marker?_icon
                        let iconClassList =
                            icon.firstElementChild.firstElementChild.classList
                        if iconClassList.contains "selected" then
                            let sightRadiusAsDistance =
                                Leaflet.convertDota2DistanceToLatLngDistance
                                    (Dota2.Ward.getVisionRadius wardItem.kind)
                            if wardSightCircle.getRadius () = 0. then
                                sightRadiusAsDistance
                                |> wardSightCircle.setRadius
                                |> ignore
                            else wardSightCircle.setRadius 0. |> ignore
                    | None -> ()
                    LayerId.ofGuid id |> SelectMapMarker |> dispatch
                let movedHandler (event : LeafletEvent) =
                    (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
                let moveHandler (event : LeafletEvent) =
                    match event.target with
                    | Some marker ->
                        // Update the circle's position
                        let markerPosition : LatLng = marker?_latlng
                        wardSightCircle.setLatLng(!^markerPosition) |> ignore
                    | None -> ()
                leafletMarker.addEventListener ("click", clickHandler)
                |> ignore
                leafletMarker.addEventListener ("move", moveHandler)
                |> ignore
                leafletMarker.addEventListener ("moveend", movedHandler)
                |> ignore

                (layer, leafletMarker)
                |> AddItemToMap
                |> dispatch

            | GenericItem _ ->
                // add event handlers to marker
                let clickHandler _ =
                    LayerId.ofGuid id |> SelectMapMarker |> dispatch
                leafletMarker.addEventListener ("click", clickHandler)
                |> ignore
                let movedHandler (event : LeafletEvent) =
                    (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
                leafletMarker.addEventListener ("moveend", movedHandler)
                |> ignore

                (layer, leafletMarker)
                |> AddItemToMap
                |> dispatch

            | TextItem _
            | Dota2Item (Dota2BuildingItem _)
            | Dota2Item (Dota2NeutralCampItem _) ->
                Browser.console.warn "Invalid item dropped on map"
        | Loading ->
            Browser.console.error "Map not loaded."

let getAddedLayers oldLayers newLayer =
    oldLayers
    |> Layer.flattenList
    |> List.tryFind (fun layer ->
        (layer |> Layer.getId) = (newLayer |> Layer.getId))
    |> Option.isNone

let createAndAddMapMarkerForLayer dispatch map viewerMode layer =
    match layer with
    | LayerUnit layerUnit ->
        [ layerUnit
          |> createAndAddMapMarkerToMapForLayerUnit
                 dispatch
                 map
                 viewerMode ]
    | LayerGroup layerGroup ->
        layerGroup.children
        |> List.map
               (createAndAddMapMarkerToMapForLayerUnit
                    dispatch
                    map
                    viewerMode)

type private Styles =
    { bottomPane : string
      demoTimer : string
      mapViewerCta : string
      notifications : string
      placeholderContainer : string
      placeholderMessage : string
      timelineToggle : string }

let private styles : Styles = importAll "./Strategiser.sass"

/// Defines subscriptions to dispatch Msgs for event listeners
let subscribe () : Cmd<Msg> =
    let sub dispatch =
        Browser.window.addEventListener_focus
            (fun event -> event |> AppFocused |> dispatch)
        Browser.window.addEventListener_keydown
            (fun event ->
                match event.ctrlKey || event.metaKey, event.key with
                | true, "y"
                | true, "s" ->
                    event.preventDefault ()
                | _, _ ->
                    ()
                event |> KeyPressedDown |> dispatch)
        Browser.window.addEventListener_keyup
            (fun event -> event |> KeyPressedUp |> dispatch)
    Cmd.ofSub sub

/// Defines the view to render based on the current state.
let view
    (app : AppConfig)
    (optionalUser : AuthenticatedUser option)
    (onSignOut : unit -> unit)
    (model : Model)
    (dispatch : Msg -> unit) =

    let isCollaborating =
        model.CurrentMode |> Mode.isCollaborating
    let isDemoMode =
        model.CurrentMode |> Mode.isDemoMode
    let isMapViewerMode =
        model.CurrentMode |> Mode.isMapViewerMode
    let isViewerMode =
        model.CurrentMode |> Mode.isViewerMode

    match onBeforeUnloadListener with
    | None ->
        onBeforeUnloadListener <-
            Browser.window.addEventListener_beforeunload
                (UserIsLeaving >> dispatch)
            |> Some
    | Some _ ->
        ()

    // populate map with static markers
    match model.Map, model.PopulateStaticMarkers with
    | Loaded _, true ->
        populateMapWithBuildings dispatch
        populateMapWithNeutralCamps dispatch
        dispatch CreateBuildingLayerGroup
        dispatch CreateNeutralCampLayerGroup
        dispatch PopulatedStaticMarkers
    | Loaded _, false
    | Loading, true
    | Loading, false ->
        ()

    // populate map with map markers
    match model.Map with
    | Loaded map ->
        if isCollaborating && model.CreateMapMarkersForNewLayers then
            let newLayers =
                model.UndoableModel.present.Layers
                |> List.filter (getAddedLayers model.UndoableModel.past.Head.Layers)
            if newLayers |> List.isEmpty |> not then
                newLayers
                |> List.map (createAndAddMapMarkerForLayer dispatch map isViewerMode)
                |> List.concat
                |> AddMapMarkersForLayersToMap
                |> dispatch

                dispatch CreatedMapMarkersForNewLayers

        else if model.CreateMapMarkersForExistingLayers then
            model.UndoableModel.present.Layers
            |> List.map (createAndAddMapMarkerForLayer dispatch map isViewerMode)
            |> List.concat
            |> AddMapMarkersForLayersToMap
            |> dispatch

            dispatch CreatedMapMarkersForExistingLayers
    | Loading ->
        ()

    // Leaflet
    let renderLeaflet =
        let onMapClick _ =
            dispatch MapClicked
        let onMapCreated map =
            map |> MapCreated |> dispatch
        let onMapDrawingStrokeCreated stroke =
            dispatch UserMadeChanges
            stroke |> DrawingStrokeAdded |> dispatch
        let onMapDrawingStrokeRemoved strokeId =
            dispatch UserMadeChanges
            strokeId |> DrawingStrokeRemoved |> dispatch
        let onMapLinearRulerCreated ruler =
            dispatch UserMadeChanges
            ruler |> LinearRulerAdded |> dispatch
        let onMapLinearRulerRemoved rulerId =
            dispatch UserMadeChanges
            rulerId |> LinearRulerRemoved |> dispatch
        let onMapRadialRulerCreated ruler =
            dispatch UserMadeChanges
            ruler |> RadialRulerAdded |> dispatch
        let onMapRadialRulerRemoved rulerId =
            dispatch UserMadeChanges
            rulerId |> RadialRulerRemoved |> dispatch
        let onMapZoomed (event : LeafletEvent) =
            event.target?_zoom |> MapZoomed |> dispatch
        let addTextMapItem _ (latlngExpression : LatLngExpression) _ =
            let id = Guid.NewGuid ()
            let lat, lng =
                match latlngExpression with
                | Leaflet.LatLng latlng ->
                    latlng.lat, latlng.lng
                | Leaflet.LatLngLiteral latlng ->
                    latlng.lat, latlng.lng
                | Leaflet.LatLngTuple (lat, lng) ->
                    lat, lng
            let item =
                { color = "#ffffff"
                  fontSize = 24.
                  text = "Text"
                  width = 300. }
                |> TextItem
            let keyframeInfo : KeyframeableAttributes =
                { isGrey = false
                  opacity = 1.
                  position = { latitude = lat
                               longitude = lng }
                  capturedBy = FactionType.Dire
                  scale = 1. }
            let keyframes : Strategiser.Keyframes =
                Map.empty
                |> Map.add 0 keyframeInfo
                |> Map.add model.CurrentTime keyframeInfo
            let layer : LayerUnit =
                { id = LayerId.ofGuid id
                  item = item
                  keyframes = keyframes
                  locked = false
                  name = item |> Item.getName
                  perpetual = false
                  selected = false
                  visible = true }
            let leafletMarker =
                Leaflet.createMarkerAtPosition
                    true
                    Leaflet.LeafletMarkerZIndex.UserMarker
                    ((lat, lng) |> U3.Case3)
            // add event handlers to marker
            let clickHandler _ =
                LayerId.ofGuid id |> SelectMapMarker |> dispatch
                Texting |> SelectSidebarTool |> dispatch
            leafletMarker.addEventListener ("click", clickHandler) |> ignore
            let movedHandler event =
                (event, LayerId.ofGuid id) |> MoveMarker |> dispatch
            leafletMarker.addEventListener ("moveend", movedHandler) |> ignore

            dispatch UserMadeChanges
            (layer, leafletMarker)
            |> AddItemToMap
            |> dispatch

        let demoTimer =
            match model.DemoTimer with
            | Some timer ->
                let color =
                    if timer <= 60 then IsDanger
                    else IsWarning
                Tag.tag [ Tag.Color color
                          Tag.CustomClass styles.demoTimer
                          Tag.Size IsLarge ] [
                     Time.demoTimer timer
                ]
            | None ->
                nothing

        let mapViewerCta =
            if isMapViewerMode && model.OpenedModal = NoModal then
                let onClickHandler _ =
                    MapViewerWelcomeModal
                    |> OpenModal
                    |> dispatch
                Button.button [ Button.Color IsWarning
                                Button.CustomClass styles.mapViewerCta
                                Button.OnClick onClickHandler ] [
                    str "Want to do more?"
                ]
            else
                nothing

        match model.Session with
        // new sessions
        | None
        // existing sessions, but loaded
        | Some (Fetched _) ->
            let center =
                if isMapViewerMode then
                    !^(-550., 535.)
                else
                    !^(-760., 515.)
            let defaultZoom =
                if isMapViewerMode then
                    0.
                else
                    -1.
            fragment [] [
                Leaflet.leaflet
                    { center = center
                      createTextMapItem = addTextMapItem
                      currentSidebarTool = model.CurrentSidebarTool
                      defaultZoom = defaultZoom
                      drawings = model.UndoableModel.present.DrawingStrokes
                      linearRulers = model.UndoableModel.present.LinearRulers
                      map = model.Map
                      onClick = onMapClick
                      onCreated = onMapCreated
                      onDrawingStrokeCreated = onMapDrawingStrokeCreated
                      onDrawingStrokeRemoved = onMapDrawingStrokeRemoved
                      onLinearRulerCreated = onMapLinearRulerCreated
                      onLinearRulerRemoved = onMapLinearRulerRemoved
                      onRadialRulerCreated = onMapRadialRulerCreated
                      onRadialRulerRemoved = onMapRadialRulerRemoved
                      onZoomed = onMapZoomed
                      radialRulers = model.UndoableModel.present.RadialRulers
                      tileUrl = model.MapTileUrl }
                demoTimer
                mapViewerCta
            ]
        | Some Fetching ->
            nothing

    // Sidebar - Left
    let renderLeftSidebar =
        if isViewerMode && not isMapViewerMode then nothing
        else
            let panTool : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.HandPaper ] []
                  id = SidebarTab.Pan
                  position = SidebarTabPosition.Top
                  title = None }
            let drawTool : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.PencilAlt ] []
                  id = SidebarTab.Draw
                  position = SidebarTabPosition.Top
                  title = None }
            let textTool : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.Font ] []
                  id = SidebarTab.Text
                  position = SidebarTabPosition.Top
                  title = None }
            let eraseTool : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.Eraser ] []
                  id = SidebarTab.Erase
                  position = SidebarTabPosition.Top
                  title = None }
            let measureTool : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.Ruler ] []
                  id = SidebarTab.Measure
                  position = SidebarTabPosition.Top
                  title = None }
            let undoButton : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = model.UndoableModel |> History.hasPast |> not
                  icon = Fa.i [ Fa.Solid.Undo ] []
                  id = SidebarTab.Undo
                  position = SidebarTabPosition.Bottom
                  title = None }
            let redoButton : Sidebar.SidebarTabAndContentData =
                { content = None
                  disabled = model.UndoableModel |> History.hasFuture |> not
                  icon = Fa.i [ Fa.Solid.Redo ] []
                  id = SidebarTab.Redo
                  position = SidebarTabPosition.Bottom
                  title = None }
            let tools =
                if isMapViewerMode then
                    [ panTool
                      drawTool
                      measureTool
                      eraseTool
                      undoButton
                      redoButton ]
                else
                    [ panTool
                      drawTool
                      measureTool
                      eraseTool
                      textTool
                      undoButton
                      redoButton ]
            Sidebar.sidebar
                model.CurrentSidebarTab
                model.CurrentSidebarTool
                SidebarOpenDirection.Right
                false
                (OpenSidebar >> dispatch)
                (fun _ -> CloseSidebar |> dispatch)
                (SelectSidebarTool >> dispatch)
                tools


    // Sidebar - Right
    let renderRightSidebar =
        let createHeroItem hero =
            { attackRangeCircleVisibility =
                AttackRangeCircleVisibility.NotVisible
              hero = hero
              side = Dota2.Side.Neutral }
            |> Dota2HeroItem
            |> Dota2Item
        let onHeroFilterChange filterString =
            filterString |> UpdateHeroFilterString |> dispatch
        let heroesTab : Sidebar.SidebarTabAndContentData =
            { content =
                Dota2.Hero.all
                |> List.map createHeroItem
                |> ItemPane.filterableItemPane
                       5
                       (onDropItemOnMap model dispatch)
                       onHeroFilterChange
                       model.HeroFilterString
                |> Some
              disabled = false
              icon = Fa.i [ Fa.Solid.Mask ] []
              id = SidebarTab.Heroes
              position = SidebarTabPosition.Top
              title = Some "Heroes" }
        let gameSpecificMarkersTab : Sidebar.SidebarTabAndContentData =
            let createDota2WardItem ward =
                { kind = ward
                  side = Dota2.Side.Neutral
                  state = BuildingState.NotDestroyed }
                |> Dota2WardItem
                |> Dota2Item
            let wardsList =
                List.map createDota2WardItem Dota2.Ward.all
            let laneCreep =
                { side = Dota2.Side.Neutral }
                |> Dota2LaneCreepItem
                |> Dota2Item
            { content =
                laneCreep :: wardsList
                |> ItemPane.itemPane 5 (onDropItemOnMap model dispatch)
                |> Some
              disabled = false
              icon = Fa.i [ Fa.Solid.Gamepad ] []
              id = SidebarTab.GameSpecificMarkers
              position = SidebarTabPosition.Top
              title = Some "Dota 2 Markers" }
        let symbolsTab : Sidebar.SidebarTabAndContentData =
            { content =
                let symbolsList =
                    GenericItem.all
                    |> List.map GenericItem
                symbolsList
                |> ItemPane.itemPane 5 (onDropItemOnMap model dispatch)
                |> Some
              disabled = false
              icon = Fa.i [ Fa.Solid.Star ] []
              id = SidebarTab.Symbols
              position = SidebarTabPosition.Top
              title = Some "Symbols" }
        let infoTab : Sidebar.SidebarTabAndContentData =
            if List.isEmpty model.SelectedLayers then
                { content = None
                  disabled = true
                  icon = Fa.i [ Fa.Solid.Info ] []
                  id = SidebarTab.Info
                  position = SidebarTabPosition.Top
                  title = Some "Info" }
            else if model.SelectedLayers.Length > 1 then
                { content = None
                  disabled = false
                  icon = Fa.i [ Fa.Solid.Info ] []
                  id = SidebarTab.Info
                  position = SidebarTabPosition.Top
                  title =
                      Some (model.SelectedLayers.Length.ToString ()
                          + " Items Selected") }
            else
                let selectedLayer = List.head model.SelectedLayers
                match selectedLayer with
                | LayerUnit { item = item } ->
                    let onSideChange side =
                        (selectedLayer, side)
                        |> ChangeSideForLayer
                        |> dispatch
                    let onAttackRangeStateChange attackRangeState =
                        (selectedLayer, attackRangeState)
                        |> ChangeAttackRangeVisibilityForLayer
                        |> dispatch
                    let onBuildingStateChange state =
                        (selectedLayer, state)
                        |> ChangeBuildingStateForLayer
                        |> dispatch
                    let onTextColorChange color =
                        (selectedLayer, color)
                        |> ChangeColorForLayer
                        |> dispatch
                    let onTextFontSizeChange fontSize =
                        (selectedLayer, fontSize)
                        |> ChangeFontSizeForLayer
                        |> dispatch
                    let onTextWidthChange width =
                        (selectedLayer, width)
                        |> ChangeWidthForLayer
                        |> dispatch
                    let onDelete () =
                        selectedLayer
                        |> DeleteLayerFromInfoPane
                        |> dispatch
                    let title =
                        match item with
                        | TextItem _ ->
                            Some "Text"
                        | _ ->
                            item |> Item.getName |> Some
                    { content =
                        item
                        |> ItemInfoPane.itemInfoPane
                               isViewerMode
                               onSideChange
                               onAttackRangeStateChange
                               onBuildingStateChange
                               onTextColorChange
                               onTextFontSizeChange
                               onTextWidthChange
                               onDelete
                               (onDropItemOnMap model dispatch)
                        |> Some
                      disabled = false
                      icon = Fa.i [ Fa.Solid.Info ] []
                      id = SidebarTab.Info
                      position = SidebarTabPosition.Top
                      title = title }
                | LayerGroup _ ->
                    { content = None
                      disabled = true
                      icon = Fa.i [ Fa.Solid.Info ] []
                      id = SidebarTab.Info
                      position = SidebarTabPosition.Top
                      title = Some "Info" }
        let sessionsTab : Sidebar.SidebarTabAndContentData =
            let onGoBackClickHandler _ =
                dispatch GoBackFromLoadSessionsPanel
            let onSaveSessionClickHandler _ =
                optionalUser
                |> Option.iter (SaveSession >> dispatch)
            let onMakeSessionPrivateHandler sessionId =
                (MakeSessionPrivate sessionId)
                |> dispatch
            let onMakeSessionPublicHandler sessionId =
                (MakeSessionPublic sessionId)
                |> dispatch
            let onShareSessionClickHandler sessionId =
                (ShareSession sessionId)
                |> dispatch
            let onRenameSessionHandler newName sessionId =
                (RenameSession (sessionId, newName))
                |> dispatch
            let onDeleteSessionClickHandler sessionId =
                (DeleteSession sessionId)
                |> dispatch
            let onExistingSessionsClickHandler _ =
                optionalUser
                |> Option.iter (FetchUserSessions >> dispatch)
            let content =
                match model.Session, model.ExistingSessions with
                | _, Some loadableSessions ->
                    loadableSessions
                    |> SessionManagementPane.existingSessions
                        onGoBackClickHandler
                        onMakeSessionPrivateHandler
                        onMakeSessionPublicHandler
                        onShareSessionClickHandler
                        onRenameSessionHandler
                        onDeleteSessionClickHandler
                | session, _ ->
                    session
                    |> SessionManagementPane.sessionPane
                        isDemoMode
                        isViewerMode
                        model.SavingSession
                        (Route.MapViewer model.Game |> Route.getPath)
                        onSaveSessionClickHandler
                        onMakeSessionPrivateHandler
                        onMakeSessionPublicHandler
                        onShareSessionClickHandler
                        onRenameSessionHandler
                        onDeleteSessionClickHandler
                        onExistingSessionsClickHandler
            let title =
                match model.ExistingSessions with
                | Some _ ->
                    "Your Sessions"
                | None ->
                    "Current Session"
            { content = content |> Some
              disabled = false
              icon = Fa.i [ Fa.Solid.Cloud ] []
              id = SidebarTab.Sessions
              position = SidebarTabPosition.Bottom
              title = title |> Some }
        let tabs =
            match optionalUser with
            | Some user ->
                let userTab : Sidebar.SidebarTabAndContentData =
                    let onSignOutClickHandler _ =
                        onSignOut ()
                    let userTabTitle =
                        match user.displayName with
                        | Some displayName -> displayName
                        | None -> user.email
                    { content =
                        UserInfoPane.userInfoPane onSignOutClickHandler
                        |> Some
                      disabled = false
                      icon =
                        UserAvatar.avatar user.photo user.displayName user.email 28
                      id = SidebarTab.User
                      position = SidebarTabPosition.Bottom
                      title = userTabTitle |> Some }
                if isMapViewerMode then
                    [ infoTab ]
                else if isViewerMode then
                    [ infoTab
                      sessionsTab
                      userTab ]
                else
                    [ heroesTab
                      gameSpecificMarkersTab
                      symbolsTab
                      infoTab
                      sessionsTab
                      userTab ]
            | None ->
                if isDemoMode then
                    [ heroesTab
                      gameSpecificMarkersTab
                      symbolsTab
                      infoTab ]
                else
                    [ infoTab ]

        Sidebar.sidebar
            model.CurrentSidebarTab
            model.CurrentSidebarTool
            SidebarOpenDirection.Left
            true
            (OpenSidebar >> dispatch)
            (fun _ -> CloseSidebar |> dispatch)
            (SelectSidebarTool >> dispatch)
            tabs

    // Timeline
    let renderTimeline =
        let onAddLayerGroupClick () =
            dispatch UserMadeChanges
            dispatch AddLayerGroup
        let onDeleteLayerClick (layers : Layer list) =
            (DeleteLayersFromTimeline layers)
            |> dispatch
        let onLayerClick (layer : Layer) =
            (SelectTimelineLayer layer)
            |> dispatch
        let onLayerStopDrag _ (layer : Layer) =
            (SelectTimelineLayer layer)
            |> dispatch
        let onLayerMoveBefore layer targetLayerId =
            (layer, targetLayerId) |> MoveLayerBeforeAnother |> dispatch
        let onLayerMoveAfter layer targetLayerId =
            (layer, targetLayerId) |> MoveLayerAfterAnother |> dispatch
        let onLayerMoveToGroup layer groupId =
            (layer, groupId) |> MoveLayerIntoGroup |> dispatch
        let onEnableLayerRenaming (layer : Layer) =
            dispatch (EnableLayerRenaming layer)
        let onLayerRename newName (layer : Layer) =
            layer |> Layer.setName newName |> RenameLayer |> dispatch
            dispatch DisableLayerRenaming
        let onToggleLayerVisibility (layer : Layer) =
            layer |> ToggleLayerVisibility |> dispatch
        let onToggleLayerLocked (layer : Layer) =
            layer |> ToggleLayerLocking |> dispatch
        let onToggleLayerGroupOpened (group : LayerGroup) =
            group |> ToggleLayerGroupOpened |> dispatch
        let onKeyframeClick (layerUnit, time) =
            (layerUnit, time) |> SelectKeyframe |> dispatch
        let onKeyframeMove _ =
            dispatch Pause
        let onKeyframeMoved (layerUnit : LayerUnit, currentTime) newTime =
            dispatch UserMadeChanges
            (layerUnit, currentTime, newTime)
            |> MoveKeyframe
            |> dispatch
        let renderTimelineToggler () =
            if isViewerMode then nothing
            else
                let onTimelineToggleHandler () =
                    dispatch ToggleTimelineOpened
                div [ ClassName styles.timelineToggle ] [
                    ElementOpenedToggle.toggle
                        model.TimelineOpened
                        (Fa.i [ Fa.Solid.AngleDown ] [])
                        (Fa.i [ Fa.Solid.AngleUp ] [])
                        onTimelineToggleHandler
                ]
        let canStartCollabMode =
            match model.Session with
            | Some (Fetched (_, session)) ->
                session.isPublic
            | Some Fetching
            | None ->
                false
        let sessionOwner =
            match model.Session with
            | Some (Fetched (_, session)) ->
                session.userId |> Some
            | Some Fetching
            | None ->
                None
        let onlineViewers =
            model.OnlineCollaborativeSessionViewers
            |> List.filter (fun viewer ->
                optionalUser
                |> Option.map (fun user ->
                    viewer.id <> user.id)
                |> Option.defaultValue true)

        div [ ClassName styles.bottomPane ] [
            renderTimelineToggler ()

            ControlPanel.controlPanel
                canStartCollabMode
                model.TogglingCollaborationMode
                isCollaborating
                isDemoMode
                isViewerMode
                (model.Session.IsNone && isViewerMode)
                sessionOwner
                onlineViewers
                { CurrentPlaybackState = model.CurrentPlaybackState
                  CurrentTime = model.CurrentTime
                  TimelineDuration = model.TimelineDuration }
                (fun _ -> Play |> dispatch)
                (fun _ -> Pause |> dispatch)
                (fun _ -> Stop |> dispatch)
                (fun _ -> EnableCollaborationMode |> dispatch)
                (fun _ -> DisableCollaborationMode |> dispatch)
                (Some >> UpdateTimelineZoom >> dispatch)
                (UpdateTimelineDuration >> dispatch)

            Timeline.timeline
                { CurrentPlaybackState = model.CurrentPlaybackState
                  CurrentTime = model.CurrentTime
                  MarksPerSecond = model.TimelineMarksPerSecond
                  SelectedKeyframe = model.SelectedKeyframe
                  SelectedTimelineLayers = model.SelectedLayers
                  TimelineDuration = model.TimelineDuration
                  TimelineLayerBeingRenamed = model.LayerBeingRenamed
                  TimelineLayers = model.UndoableModel.present.Layers
                  TimelineOpened = model.TimelineOpened
                  TimelineZoomLevel = model.TimelineZoomLevel }
                (fun _ -> dispatch DeselectKeyframe)
                (fun _ -> dispatch Pause)
                (SkipToTime >> dispatch)
                onAddLayerGroupClick
                onDeleteLayerClick
                onLayerClick
                onLayerStopDrag
                onLayerMoveBefore
                onLayerMoveAfter
                onLayerMoveToGroup
                onEnableLayerRenaming
                onLayerRename
                onToggleLayerVisibility
                onToggleLayerLocked
                onToggleLayerGroupOpened
                onKeyframeClick
                onKeyframeMove
                onKeyframeMoved
        ]

    let renderNotifications =
        let onClickNotificationDismiss notification =
            (DismissNotification notification)
            |> dispatch
        NotificationPanel.panel
            onClickNotificationDismiss
            model.Notifications

    let renderPlaceholder content =
        div [ ClassName styles.placeholderContainer ] [
            content |> ofList
            div [ ClassName styles.notifications ] [
                renderNotifications
            ]
        ]

    let sessionNotFoundMessage =
        let buttonLink =
            (model.Game, None) |> Route.Strategiser |> Route.getPath
        renderPlaceholder [
            Fa.i [ Fa.Solid.QuestionCircle
                   Fa.Size Fa.ISize.Fa3x ] [ ]
            div [ ClassName styles.placeholderMessage ] [
                str "Session not found."
            ]
            Level.level [] [
                Level.item [] [
                    Button.a [ Button.Color Color.IsInfo
                               Button.Props [ Href buttonLink ] ] [
                        str "Create New Session"
                    ]
                ]
            ]
        ]

    let needSubscriptionMessage =
        renderPlaceholder [
            Fa.i [ Fa.Solid.ExclamationCircle
                   Fa.Size Fa.ISize.Fa3x ] [ ]
            div [ ClassName styles.placeholderMessage ] [
                str "You need a subscription to access the StatBanana Strategy Maker."
            ]
            Level.level [] [
                Level.item [] [
                    Button.a [ Button.Color Color.IsInfo
                               Button.Props [ Href (Route.Pricing |> Route.getPath) ] ] [
                        str "View Pricing"
                    ]
                ]
            ]
        ]

    let bottomPane =
        if isMapViewerMode then
            nothing
        else
            renderTimeline

    let welcomeModal =
        match model.CurrentMode with
        | DemoMode ->
            fragment [] [
                DemoFinishedModal.modal
                    model.Game
                    (model.OpenedModal = DemoFinishedModal)
                DemoWelcomeModal.modal
                    model.Game
                    (model.OpenedModal = DemoWelcomeModal)
                    (fun _ -> dispatch CloseModal)
            ]
        | ViewerMode MapViewer ->
            fragment [] [
                MapViewerWelcomeModal.modal
                    model.Game
                    (model.OpenedModal = MapViewerWelcomeModal)
                    (fun _ -> dispatch CloseModal)
            ]
        | _ ->
            nothing

    let renderSessionInterface () =
        fragment [] [
            StandardEditingTemplate.template
                renderNotifications
                renderLeaflet
                renderLeftSidebar
                renderRightSidebar
                bottomPane
            KeyboardShortcutsModal.modal
                (model.OpenedModal = KeyboardShortcutsModal)
                (fun _ -> dispatch CloseModal)
            welcomeModal
        ]

    let userHasAccess =
        optionalUser
        |> Option.map (fun user ->
            AuthenticatedUser.hasStrategiserAccess
                app.deploymentEnvironment
                model.Game
                user)
        |> Option.defaultValue false

    // The actual interface
    match model.Session with
    | None ->
        if model.SessionNotFound then
            sessionNotFoundMessage
        else if isDemoMode || isViewerMode || userHasAccess then
            renderSessionInterface ()
        else if model.UserJustSignedUp then
            renderPlaceholder [
                Loading.loading ()
                div [ ClassName styles.placeholderMessage ] [
                    str "Preparing access..."
                ]
            ]
        else
            needSubscriptionMessage

    | Some (Fetched (sessionId, session)) ->
        if isViewerMode || userHasAccess || isDemoMode then
            match optionalUser with
            | Some user ->
                let shouldInitSesssionViewersListener =
                    not model.SessionViewersListenerIsActive && session.isPublic
                let shouldInitCollaborationUpdateListener =
                    not model.CollaborationUpdateListenerIsActive
                    && session.isPublic
                    && session.userId <> user.id

                if shouldInitSesssionViewersListener then
                    app.collabSessionService.listenForViewers
                        sessionId
                        (NewSessionViewersUpdate >> dispatch)
                        (SessionViewerListenerError >> dispatch)
                    dispatch InitSessionViewersFirestoreListener

                if shouldInitCollaborationUpdateListener then
                    app.collabSessionService.listenForUpdates
                        sessionId
                        (ReceivedCollaborationUpdate >> dispatch)
                    dispatch InitCollaborationUpdateFirestoreListener

                renderSessionInterface ()
            | None ->
                if isDemoMode then
                    renderSessionInterface ()
                else
                    nothing
        else
            needSubscriptionMessage

    | Some Fetching ->
        renderPlaceholder [
            Loading.loading ()
            div [ ClassName styles.placeholderMessage ] [
                str "Loading session..."
            ]
        ]