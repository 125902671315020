/// Functions that serve as wrappers around Leaflet for Strategiser
module StatBanana.Web.Client.Extensions.Leaflet

open Fable.Core
open Fable.Core.JsInterop
open Fable.Import

open Leaflet

open StatBanana.Web.Client.Domain

[<RequireQualifiedAccess>]
type LeafletMarkerZIndex =
    | DefaultMarker
    | UserMarker
module LeafletMarkerZIndex =
    /// <summary>
    ///     Converts a LeafletMarkerZIndex to a float
    /// </summary>
    ///
    /// <param name="value">
    ///     The LeafletMarkerZIndex that should be converted
    /// </param>
    let toFloat (value : LeafletMarkerZIndex) : float =
        match value with
        | LeafletMarkerZIndex.DefaultMarker -> -9999.
        | LeafletMarkerZIndex.UserMarker -> 0.

// Allow for type-testing against LatLngExpressions (U3)
let (|LatLngLiteral|LatLng|LatLngTuple|)
    (obj : LatLngExpression) : Choice<LatLngLiteral, LatLng, LatLngTuple> =
        match obj?name with
        | "LatLngLiteral" -> LatLngLiteral (unbox obj)
        | "LatLng" -> LatLng (unbox obj)
        | "LatLngTuple" -> LatLngTuple (unbox obj)
        | _ -> LatLng (unbox obj)

/// <summary>
///     Convert Leaflet LatLng distance to Dota2 distance units
///     Dota2 Map Size - 15000 x 15000 units
///     Leaflet Map Size - 1025 lat x 1025 long
/// </summary>
///
/// <param name="distance">
///     The LatLng distance to convert to Dota2 units
/// </param>
let convertLatLngDistanceToDota2Distance (distance : float) : float =
    let distanceRatio = 1025. / 15000.
    System.Math.Round (distance / distanceRatio)

/// <summary>
///     Convert Dota2 distance units to Leaflet LatLngs
///     Dota2 Map Size - 15000 x 15000 units
///     Leaflet Map Size - 1025 lat x 1025 long
/// </summary>
///
/// <param name="distance">
///     The Dota2 distance units to convert
/// </param>
let convertDota2DistanceToLatLngDistance (distance : Dota2.Distance) : float =
    let distanceRatio = 1025. / 15000.
    System.Math.Round (Dota2.Distance.toFloat (distance) * distanceRatio)

/// <summary>
///     Convert Dota2 coordinate to Leaflet lat/lng
///     Dota2 Map Size - 15000 x 15000 units
///     Leaflet Map Size - 1025 lat x 1025 long
/// </summary>
///
/// <param name="units">
///     The Dota2 distance units to convert
/// </param>
let convertDota2CoordinateToLatOrLng (coordinate : Dota2.Coordinate) : float =
    let distanceRatio = 1025. / 15000.
    System.Math.Round (Dota2.Coordinate.toFloat coordinate * distanceRatio)

/// <summary>
///     Creates a Leaflet.LatLng from a latitude and longitude tuple.
/// </summary>
///
/// <param name="latitude">
///     The latitude value.
/// </param>
///
/// <param name="longitude">
///     The longitude value.
/// </param>
let createLatLngFromTuple (latitude : float, longitude : float) : LatLng =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    (latitude, longitude) |> U4.Case1 |> leafletJS.latLng

/// <summary>
///     Creates a Leaflet image icon.
/// </summary>
///
/// <param name="imagePath">
///     The path to the icon image.
/// </param>
///
/// <param name="imageSize">
///     The size of the icon image.
/// </param>
let createImageIcon
    (className : string)
    (imagePath : string)
    (imageSize : int) : Leaflet.Icon<IconOptions> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    leafletJS.icon (jsOptions<IconOptions> <| fun options ->
        options.className <- className |> Some
        let iconSize = (float imageSize, float imageSize) |> U2.Case2 |> Some
        options.iconSize <- iconSize
        options.iconUrl <- imagePath)

/// <summary>
///     Creates a Leaflet DivIcon from a MapItem.
/// </summary>
///
/// <param name="iconComponent">
///     A function that will be used to generate the icon html.
/// </param>
///
/// <param name="id">
///     The identifying string to add to the className of the icon.
/// </param>
///
/// <param name="item">
///     The Item to use in for the creation of a Leaflet DivIcon.
/// </param>
let createDivIconFromItem
    (iconComponent : string -> Strategiser.Item -> React.ReactElement)
    (id : string)
    (item : Strategiser.Item) : Leaflet.DivIcon =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let iconString =
        item |> iconComponent id |> ReactDomServer.renderToString
    let iconSize = 50.
    let divIconOptions =
        jsOptions<Leaflet.DivIconOptions> <| fun options ->
            options.className <- id |> Some
            options.html <- iconString |> U2.Case1 |> Some
            options.iconSize <- (iconSize, iconSize) |> U2.Case2 |> Some

    leafletJS.divIcon(divIconOptions)

/// <summary>
///     Creates a Leaflet marker
/// </summary>
///
/// <param name="latlng">
///     The position at which the marker should be initially placed
/// </param>
let createMarkerAtPosition
    (draggable : bool)
    (zIndexOffset : LeafletMarkerZIndex)
    (latlng : Leaflet.LatLngExpression) : Leaflet.Marker<_> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let markerOptions =
        jsOptions<Leaflet.MarkerOptions> <| fun options ->
            options.draggable <- Some draggable
            options.zIndexOffset <- zIndexOffset
                                    |> LeafletMarkerZIndex.toFloat
                                    |> Some

    leafletJS.marker(latlng, markerOptions)

/// <summary>
///     Creates a Leaflet Circle that represents the
///     vision radius that a ward has
/// </summary>
///
/// <param name="center">
///     The position of the Ward (the center of the Circle)
/// </param>
///
/// <param name="radius">
///     Vision radius of the circle converted to LatLng units
/// </param>
let createSightAroundWard
    (center : LatLng)
    (radius : float) : Leaflet.Circle<obj> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let circleMarkerOptions = jsOptions<CircleMarkerOptions> (fun options ->
        options.radius <- Some radius
        options.fillColor <- Some "#ffbe1c"
        options.color <- Some "#000"
        options.weight <- Some 2.
        options.dashArray <- Some "20 40")

    leafletJS.circle (!^center, circleMarkerOptions)

/// <summary>
///     Creates a Leaflet Circle where the radius is the
///     distance of the ruler.
/// </summary>
///
/// <param name="id">
///     The id to give the circle as a classname
/// </param>
///
/// <param name="center">
///     The position of the circle (the center of the Circle)
/// </param>
///
/// <param name="radius">
///     The radius of the circle given as the distance of the ruler
/// </param>
let createRadialMeasurement
    (id : string)
    (center : LatLng)
    (radius : float) : Leaflet.Circle<obj> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let circleMarkerOptions = jsOptions<CircleMarkerOptions> (fun options ->
        options.className <- Some id
        options.radius <- Some radius
        options.fillColor <- Some "#11bbf4"
        options.fill <- Some false
        options.color <- Some "#ff9d00"
        options.dashArray <- Some "30 10"
        options.opacity <- Some 0.8
        options.weight <- Some 4.)

    leafletJS.circle (!^center, circleMarkerOptions)

/// <summary>
///     Creates a Leaflet Circle where the radius is the
///     attack range of a particular unit
/// </summary>
///
/// <param name="id">
///     The id to give the circle as a classname
/// </param>
///
/// <param name="center">
///     The position of the circle (the center of the Circle)
/// </param>
///
/// <param name="radius">
///     The radius of the circle given as the attack radius
/// </param>
let createAttackRadiusCircle
    (id : string)
    (center : LatLng)
    (radius : float)
    (opacity : float) : Leaflet.Circle<obj> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let circleMarkerOptions = jsOptions<CircleMarkerOptions> (fun options ->
        options.className <- Some (id + " circle-" + id)
        options.radius <- Some radius
        options.fill <- Some false
        options.color <- Some "#e00821"
        options.opacity <- Some opacity
        options.weight <- Some 2.)

    leafletJS.circle (!^center, circleMarkerOptions)

/// <summary>
///     Creates a Leaflet Circle where the radius is the
///     area of effect of a particular ability
/// </summary>
///
/// <param name="center">
///     The position of the circle (the center of the Circle)
/// </param>
///
/// <param name="radius">
///     The radius of the circle given as the area of effect
/// </param>
let createAbilityEffectCircle
    (center : LatLng)
    (radius : float) : Leaflet.Circle<obj> =
    let leafletJS : Leaflet.IExports = importAll "leaflet"
    let circleMarkerOptions = jsOptions<CircleMarkerOptions> (fun options ->
        options.radius <- Some radius
        options.fillColor <- Some "#70c3ff"
        options.color <- Some "#70c3ff"
        options.weight <- Some 2.)

    leafletJS.circle (!^center, circleMarkerOptions)
