namespace StatBanana.Domain

/// A plan for paying for a premium package for a particular game.
type Plan =
    { package : PremiumPackage
      billingPeriod : BillingPeriod }
module Plan =
    /// Gets the package corresponding to the given plan
    let asPackage (plan : Plan) : Package =
        Premium plan.package

    let getDefault () : Plan =
        { package = Standard
          billingPeriod = Monthly }

/// A set of plans for paying for premium packages of each game.
type PlanSet =
    { dota2 : Plan option }
module PlanSet =
    /// <summary>
    ///     Gets the plan for a particular game from the set.
    /// </summary>
    ///
    /// <param name="game">
    ///     The game to get the plan for.
    /// </param>
    ///
    /// <param name="set">
    ///     The set to get the plan from.
    /// </param>
    let getPlan (game : Game) (set : PlanSet) =
        match game with
        | Game.Dota2 -> set.dota2

    /// <summary>
    ///     Gets the plans for each game in the set.
    /// </summary>
    ///
    /// <param name="set">
    ///     The set to get the plans from.
    /// </param>
    ///
    /// <returns>
    ///     A sequence of game-plan pairs. May be empty if the set is empty.
    /// </returns>
    let getPlans (set : PlanSet) =
        seq {
            for game in Game.all do
                let plan = set |> getPlan game
                if plan.IsSome then
                    yield game, plan.Value
        }

    /// <summary>
    ///     Returns a modified version of the set with the plan for the given
    ///     game replaced.
    /// </summary>
    ///
    /// <param name="game">
    ///     The game to set the plan for.
    /// </param>
    ///
    /// <param name="plan">
    ///     The plan to set for the game. May be None if the set should not contain
    ///     a plan for the given game.
    /// </param>
    ///
    /// <returns>
    ///     A modified plan set.
    /// <returns>
    let withPlan (game : Game) (plan : Plan option) (set : PlanSet) =
        match game with
        | Game.Dota2 -> { set with dota2 = plan }

    /// <summary>
    ///     Converts the given plan set into a package set.
    /// </summary>
    ///
    /// <param name="set">
    ///     The set to convert.
    /// </param>
    let asPackageSet (set : PlanSet) : PackageSet =
        { PackageSet.dota2 =
            set.dota2 |> Option.map Plan.asPackage |> Option.defaultValue Package.Free  }

/// <summary>
///     Functions for calculating the price of a plan.
/// </summary>
///
/// <remarks>
///     Beware that the calculated price for a plan might change over time. The
///     price that a user actually pays for a plan might, for historical reasons,
///     not necessarily match what this module calculates.
/// </remarks>
module PlanPricing =
    open System

    /// <summary>
    ///     Gets the current price for a plan.
    /// </summary>
    ///
    /// <param name="plan">
    ///     The plan to check the price of.
    /// </param>
    ///
    /// <returns>
    ///     The price of the plan, according to the current rules for calculating
    ///     its price.
    /// </returns>
    let getCurrentPrice (plan : Plan) : Price =

        let monthlyAmount = 4.95m
        let yearlyAmount = monthlyAmount * 12m
        let yearlyDiscount =
            match plan.billingPeriod with
            | BillingPeriod.Yearly ->
                yearlyAmount - (monthlyAmount * 10m)
            | BillingPeriod.Monthly ->
                0m

        match plan.package with
        | Standard ->
            let listAmount =
                match plan.billingPeriod with
                | BillingPeriod.Monthly ->
                    monthlyAmount
                | BillingPeriod.Yearly ->
                    yearlyAmount

            let discountedAmount =
                listAmount - yearlyDiscount
                |> (fun v -> Math.Max(0m, v))

            if listAmount > discountedAmount then
                { listAmount = listAmount
                  discountedAmount = discountedAmount
                  currency = Currency.USD }
                |> Price.Discounted
            else
                { amount = listAmount
                  currency = Currency.USD }
                |> Price.List
