module StatBanana.Web.Client.Pages.NotFoundPage

open Fable.Helpers.React

open Elmish
open Fulma

open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain

/// Application state passed down to this page.
type Model = unit

/// Events/actions that can be dispatched by this page.
type Msg = unit

/// Initialises the page.
let init () : Model * Cmd<Msg> =
    (), Cmd.none

/// <summary>
///     Updates the model in response to a message
/// </summary>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="model">
///     The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (model : Model) : Model * Cmd<Msg> =
    match model, msg with
    | (), () -> model, Cmd.none

/// <summary>
///     Render a 404 not found page.
/// </summary>
///
/// <remarks>
///     This page has no Msg, Model, init or update logic.  If you need those, you should probably
///     be rendering the Loading animation component within one of the other pages.
/// </remarks>
let view () =
    FullscreenCenterContentTemplate.template [
        Heading.h1 [] [
            str "404 Not Found"
        ]
    ]