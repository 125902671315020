namespace StatBanana.Web.Client.Dto.Firestore.Strategiser

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Item DTOs.
[<RequireQualifiedAccess>]
module ItemDto =
    module AttackRangeCircleVisibility =
        let fromDomain
            (visibility : Strategiser.AttackRangeCircleVisibility) : int =
            match visibility with
            | Strategiser.AttackRangeCircleVisibility.Visible ->
                1
            | Strategiser.AttackRangeCircleVisibility.NotVisible ->
                2

        let toDomain (item : int) : Strategiser.AttackRangeCircleVisibility =
            match item with
            | 1 -> Strategiser.AttackRangeCircleVisibility.Visible
            | 2 -> Strategiser.AttackRangeCircleVisibility.NotVisible
            | unexpectedVisibility ->
                sprintf "Unexpected AttackRangeCircleVisibility DTO item: %d" unexpectedVisibility
                |> JsonAdapter.JsonParsingException
                |> raise

    module FactionType =
        let fromDomain (side : Dota2.FactionType) : int =
            match side with
            | Dota2.FactionType.Dire ->
                1
            | Dota2.FactionType.Radiant ->
                2

        let toDomain (item : int) : Dota2.FactionType =
            match item with
            | 1 -> Dota2.FactionType.Dire
            | 2 -> Dota2.FactionType.Radiant
            | unexpectedSide ->
                sprintf "Unexpected FactionType DTO item: %d" unexpectedSide
                |> JsonAdapter.JsonParsingException
                |> raise

    module BuildingState =
        let fromDomain (state : Strategiser.BuildingState) : int =
            match state with
            | Strategiser.BuildingState.Destroyed ->
                1
            | Strategiser.BuildingState.NotDestroyed ->
                2
            | (Strategiser.BuildingState.Captured Dota2.FactionType.Dire) ->
                3
            | (Strategiser.BuildingState.Captured Dota2.FactionType.Radiant) ->
                4

        let toDomain (item : int) : Strategiser.BuildingState =
            match item with
            | 1 -> Strategiser.BuildingState.Destroyed
            | 2 -> Strategiser.BuildingState.NotDestroyed
            | 3 -> (Strategiser.BuildingState.Captured Dota2.FactionType.Dire)
            | 4 -> (Strategiser.BuildingState.Captured Dota2.FactionType.Radiant)
            | unexpectedState ->
                sprintf "Unexpected BuildingState DTO item: %d" unexpectedState
                |> JsonAdapter.JsonParsingException
                |> raise

    module Dota2AbilityItem =
        let fromDomain (item : Strategiser.Dota2AbilityItem) : obj =
            createObj [
                "ability" ==> (item.ability |> Dota2.Ability.toId)
            ]

        let toDomain (item : obj) : Strategiser.Dota2AbilityItem =
            { ability =
                item
                |> JsonAdapter.getString "ability"
                |> Dota2.Ability.fromId
                |> JsonAdapter.raiseOnError }

    module Dota2BuildingItem =
        let fromDomain (item : Strategiser.Dota2BuildingItem) : obj =
            createObj [
                "attackRangeCircleVisibility" ==>
                    (item.attackRangeCircleVisibility
                     |> AttackRangeCircleVisibility.fromDomain)
                "building" ==> (item.building |> Dota2.Building.toId)
                "state" ==> (item.state |> BuildingState.fromDomain)
            ]

        let toDomain (item : obj) : Strategiser.Dota2BuildingItem =
            { attackRangeCircleVisibility =
                item
                |> JsonAdapter.getInt "attackRangeCircleVisibility"
                |> AttackRangeCircleVisibility.toDomain
              building =
                item
                |> JsonAdapter.getString "building"
                |> Dota2.Building.fromId
                |> JsonAdapter.raiseOnError
              state =
                item
                |> JsonAdapter.getInt "state"
                |> BuildingState.toDomain }

    module Dota2HeroItem =
        let fromDomain (item : Strategiser.Dota2HeroItem) : obj =
            createObj [
                "attackRangeCircleVisibility" ==>
                    (item.attackRangeCircleVisibility
                     |> AttackRangeCircleVisibility.fromDomain)
                "hero" ==> (item.hero |> Dota2.Hero.toId)
                "side" ==> (item.side |> Dota2.Side.toId)
            ]

        let toDomain (item : obj) : Strategiser.Dota2HeroItem =
            { attackRangeCircleVisibility =
                item
                |> JsonAdapter.getInt "attackRangeCircleVisibility"
                |> AttackRangeCircleVisibility.toDomain
              hero =
                item
                |> JsonAdapter.getString "hero"
                |> Dota2.Hero.fromId
                |> JsonAdapter.raiseOnError
              side =
                item
                |> JsonAdapter.getString "side"
                |> Dota2.Side.fromId
                |> JsonAdapter.raiseOnError}

    module Dota2LaneCreepItem =
        let fromDomain
            (item : Strategiser.Dota2LaneCreepItem) : obj =
            createObj [
                "side" ==> (item.side |> Dota2.Side.toId)
            ]

        let toDomain (item : obj) : Strategiser.Dota2LaneCreepItem =
            { side =
                item
                |> JsonAdapter.getString "side"
                |> Dota2.Side.fromId
                |> JsonAdapter.raiseOnError }

    module Dota2NeutralCampItem =
        let fromDomain
            (item : Strategiser.Dota2NeutralCampItem) : obj =
            createObj [
                "camp" ==> (item.camp |> Dota2.NeutralCamp.toId)
            ]

        let toDomain (item : obj) : Strategiser.Dota2NeutralCampItem =
            { camp =
                item
                |> JsonAdapter.getString "camp"
                |> Dota2.NeutralCamp.fromId
                |> JsonAdapter.raiseOnError }

    module Dota2WardItem =
        let fromDomain (item : Strategiser.Dota2WardItem) : obj =
            createObj [
                "kind" ==> (item.kind |> Dota2.Ward.toId)
                "side" ==> (item.side |> Dota2.Side.toId)
                "state" ==> (item.state |> BuildingState.fromDomain)
            ]

        let toDomain (item : obj) : Strategiser.Dota2WardItem =
            { kind =
                item
                |> JsonAdapter.getString "kind"
                |> Dota2.Ward.fromId
                |> JsonAdapter.raiseOnError
              side =
                item
                |> JsonAdapter.getString "side"
                |> Dota2.Side.fromId
                |> JsonAdapter.raiseOnError
              state =
                item
                |> JsonAdapter.getInt "state"
                |> BuildingState.toDomain }

    module Dota2Item =
        let fromDomain (item : Strategiser.Dota2Item) : obj =
            match item with
            | Strategiser.Dota2AbilityItem ability ->
                createObj [
                    "Tag" ==> "Dota2AbilityItem"
                    "Dota2AbilityItemData" ==>
                        (ability |> Dota2AbilityItem.fromDomain)
                    "Dota2BuildingItemData" ==> null
                    "Dota2HeroItemData" ==> null
                    "Dota2LaneCreepItemData" ==> null
                    "Dota2NeutralCampItemData" ==> null
                    "Dota2WardItemData" ==> null
                ]
            | Strategiser.Dota2BuildingItem building ->
                createObj [
                    "Tag" ==> "Dota2BuildingItem"
                    "Dota2BuildingItemData" ==>
                        (building |> Dota2BuildingItem.fromDomain)
                    "Dota2HeroItemData" ==> null
                    "Dota2LaneCreepItemData" ==> null
                    "Dota2NeutralCampItemData" ==> null
                    "Dota2WardItemData" ==> null
                ]
            | Strategiser.Dota2HeroItem hero ->
                createObj [
                    "Tag" ==> "Dota2HeroItem"
                    "Dota2BuildingItemData" ==> null
                    "Dota2HeroItemData" ==>
                        (hero |> Dota2HeroItem.fromDomain)
                    "Dota2LaneCreepItemData" ==> null
                    "Dota2NeutralCampItemData" ==> null
                    "Dota2WardItemData" ==> null
                ]
            | Strategiser.Dota2LaneCreepItem laneCreep ->
                createObj [
                    "Tag" ==> "Dota2LaneCreepItem"
                    "Dota2BuildingItemData" ==> null
                    "Dota2HeroItemData" ==> null
                    "Dota2LaneCreepItemData" ==>
                        (laneCreep |> Dota2LaneCreepItem.fromDomain)
                    "Dota2NeutralCampItemData" ==> null
                    "Dota2WardItemData" ==> null
                ]
            | Strategiser.Dota2NeutralCampItem neutralCamp ->
                createObj [
                    "Tag" ==> "Dota2NeutralCampItem"
                    "Dota2BuildingItemData" ==> null
                    "Dota2HeroItemData" ==> null
                    "Dota2LaneCreepItemData" ==> null
                    "Dota2NeutralCampItemData" ==>
                        (neutralCamp |> Dota2NeutralCampItem.fromDomain)
                    "Dota2WardItemData" ==> null
                ]
            | Strategiser.Dota2WardItem ward ->
                createObj [
                    "Tag" ==> "Dota2WardItem"
                    "Dota2BuildingItemData" ==> null
                    "Dota2HeroItemData" ==> null
                    "Dota2LaneCreepItemData" ==> null
                    "Dota2NeutralCampItemData" ==> null
                    "Dota2WardItemData" ==> (ward |> Dota2WardItem.fromDomain)
                ]

        let toDomain (item : obj) : Strategiser.Dota2Item =
            let tag = item |> JsonAdapter.getString "Tag"
            match tag with
            | "Dota2AbilityItem" ->
                item
                |> JsonAdapter.getObj "Dota2AbilityItemData"
                |> Dota2AbilityItem.toDomain
                |> Strategiser.Dota2AbilityItem
            | "Dota2BuildingItem" ->
                item
                |> JsonAdapter.getObj "Dota2BuildingItemData"
                |> Dota2BuildingItem.toDomain
                |> Strategiser.Dota2BuildingItem
            | "Dota2HeroItem" ->
                item
                |> JsonAdapter.getObj "Dota2HeroItemData"
                |> Dota2HeroItem.toDomain
                |> Strategiser.Dota2HeroItem
            | "Dota2LaneCreepItem" ->
                item
                |> JsonAdapter.getObj "Dota2LaneCreepItemData"
                |> Dota2LaneCreepItem.toDomain
                |> Strategiser.Dota2LaneCreepItem
            | "Dota2NeutralCampItem" ->
                item
                |> JsonAdapter.getObj "Dota2NeutralCampItemData"
                |> Dota2NeutralCampItem.toDomain
                |> Strategiser.Dota2NeutralCampItem
            | "Dota2WardItem" ->
                item
                |> JsonAdapter.getObj "Dota2WardItemData"
                |> Dota2WardItem.toDomain
                |> Strategiser.Dota2WardItem
            | unexpectedTag ->
                sprintf "Unexpected Dota2Item DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise

    module GenericItem =
        let fromDomain (item : Strategiser.GenericItem) : int =
            match item with
            | Strategiser.Star -> 1
            | Strategiser.Circle -> 2
            | Strategiser.Square -> 3
            | Strategiser.Heart -> 4
            | Strategiser.Battle -> 5

        let toDomain (item : int) : Strategiser.GenericItem =
            match item with
            | 1 -> Strategiser.Star
            | 2 -> Strategiser.Circle
            | 3 -> Strategiser.Square
            | 4 -> Strategiser.Heart
            | 5 -> Strategiser.Battle
            | unexpectedItem ->
                sprintf "Unexpected GenericItem DTO item: %d" unexpectedItem
                |> JsonAdapter.JsonParsingException
                |> raise

    module TextItem =
        let fromDomain (item : Strategiser.TextItem) : obj =
            createObj [
                "color" ==> item.color
                "fontSize" ==> item.fontSize
                "text" ==> item.text
                "width" ==> item.width
            ]

        let toDomain (item : obj) : Strategiser.TextItem =
            { color = item |> JsonAdapter.getString "color"
              fontSize = item |> JsonAdapter.getFloat "fontSize"
              text = item |> JsonAdapter.getString "text"
              width = item |> JsonAdapter.getFloat "width" }


    module Item =
        let fromDomain (item : Strategiser.Item) : obj =
            match item with
            | Strategiser.Dota2Item dota2Item ->
                createObj [
                    "Tag" ==> "Dota2Item"
                    "Dota2ItemData" ==> (dota2Item |> Dota2Item.fromDomain)
                    "GenericItemData" ==> null
                    "TextItemData" ==> null
                ]
            | Strategiser.GenericItem genericItem ->
                createObj [
                    "Tag" ==> "GenericItem"
                    "Dota2ItemData" ==> null
                    "GenericItemData" ==>
                        (genericItem |> GenericItem.fromDomain)
                    "TextItemData" ==> null
                ]
            | Strategiser.TextItem textItem ->
                createObj [
                    "Tag" ==> "TextItem"
                    "Dota2ItemData" ==> null
                    "GenericItemData" ==> null
                    "TextItemData" ==>
                        (textItem |> TextItem.fromDomain)
                ]

        let toDomain (item : obj) : Strategiser.Item =
            let tag = item |> JsonAdapter.getString "Tag"
            match tag with
            | "Dota2Item" ->
                item
                |> JsonAdapter.getObj "Dota2ItemData"
                |> Dota2Item.toDomain
                |> Strategiser.Dota2Item
            | "GenericItem" ->
                item
                |> JsonAdapter.getInt "GenericItemData"
                |> GenericItem.toDomain
                |> Strategiser.GenericItem
            | "TextItem" ->
                item
                |> JsonAdapter.getObj "TextItemData"
                |> TextItem.toDomain
                |> Strategiser.TextItem
            | unexpectedTag ->
                sprintf "Unexpected Item DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise