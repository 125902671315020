/// A session management pane for the sidebar
module StatBanana.Web.Client.Components.Molecules.SessionManagementPane

open System

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React
open Fable.PowerPack

open Fulma
open Fulma.Extensions.Wikiki

open Fulma
open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser

type Styles =
    { container : string
      propertyIcon : string
      session : string
      sessionActions : string
      sessionDetails : string
      sessionRow : string
      visibilityPanel : string }

let private onRenameSessionClickHandler (id, session) onRename =
    Browser.window.prompt
        ("Please enter a new name for the session", session.name)
    |> Option.ofObj
    |> Option.iter (fun newName ->
        onRename newName id)

let private buildSessionShareLink sessionId game =
    let port =
        if Browser.window.location.port <> "80" then
            ":" + Browser.window.location.port
        else ""
    String.concat
        ""
        [ Browser.window.location.protocol
          "//"
          Browser.window.location.hostname
          port
          (Route.Strategiser (game, Some sessionId) |> Route.getPath) ]

let private renderSession
    sessionIsSaving
    showSaveButton
    showLoadButton
    showQuickActions
    onSaveSession
    onMakeSessionPrivate
    onMakeSessionPublic
    onShareSessionClick
    onRenameSession
    onDeleteClick
    ((sessionId : SessionId), (session : Session)) =

    let styles : Styles = importAll "./SessionManagementPane.sass"
    let formatSaveDate (date : DateTimeOffset) =
        date.LocalDateTime
        |> Date.Format.localFormat
               Date.Local.englishUS
               "yyyy-MM-dd hh:mm:ss"
    let tooltipClassName =
        String.concat
            " "
            [ Tooltip.ClassName; Tooltip.IsTooltipLeft ]
    let onMakePrivateClickHandler (event : MouseEvent) =
        event.preventDefault ()
        if Browser.window.confirm
               "Are you sure you want to make this session private? Only you will be able to access the session." then
            onMakeSessionPrivate sessionId
    let onMakePublicClickHandler (event : MouseEvent) =
        event.preventDefault ()
        if Browser.window.confirm
               "Are you sure you want to make this session public? Any logged-in user with the link will be able to view the session but not make any edits." then
            onMakeSessionPublic sessionId
    let onShareClickHandler (event : MouseEvent) =
        event.preventDefault ()

        // Copy the current browser url to the user's clipboard
        let tempInput =
            Browser.document.createElement "input" :?> Browser.HTMLInputElement
        tempInput.style <- jsOptions<Browser.CSSStyleDeclaration> <| fun style ->
            style.position <- "absolute"
            style.left <- "-1000px"
            style.top <- "-1000px"
        tempInput.value <- buildSessionShareLink sessionId session.game
        Browser.document.body.appendChild tempInput |> ignore
        tempInput.select ()
        Browser.document.execCommand "copy" |> ignore
        Browser.document.body.removeChild tempInput |> ignore

        onShareSessionClick sessionId
    let onRenameClickHandler (event : MouseEvent) =
        event.preventDefault ()
        onRenameSessionClickHandler (sessionId, session) onRenameSession
    let onDeleteClickHandler (event : MouseEvent) =
        event.preventDefault ()
        if Browser.window.confirm
               "Are you sure you want to delete this session?" then
            onDeleteClick sessionId
    let renderActions () =
        let renderSaveButton () =
            if showSaveButton then
                let icon =
                    if sessionIsSaving then
                        Fa.i [ Fa.Solid.CircleNotch
                               Fa.Spin ] []
                    else
                        Fa.i [ Fa.Solid.Save ] []
                Button.button [ Button.CustomClass tooltipClassName
                                Button.Color IsSuccess
                                Button.OnClick onSaveSession
                                Button.Props
                                    [ Data ("tooltip", "Save Session") ] ] [
                    Icon.icon [] [
                        icon
                    ]
                ]
            else nothing
        let renderLoadButton () =
            if showLoadButton then
                Button.a [ Button.CustomClass tooltipClassName
                           Button.Color IsSuccess
                           Button.Disabled sessionIsSaving
                           Button.Props
                               [ Data ("tooltip", "Open Session")
                                 Href (Route.Strategiser (session.game, Some sessionId)
                                       |> Route.getPath) ] ] [
                    Icon.icon [] [
                        Fa.i [ Fa.Solid.ExternalLinkAlt ] []
                    ]
                ]
            else nothing
        let renderQuickActions () =
            if showQuickActions then
                Dropdown.dropdown [ Dropdown.IsHoverable
                                    Dropdown.IsRight ] [
                    div [] [
                        Button.button [] [
                            Icon.icon [] [
                                Fa.i [ Fa.Solid.EllipsisV ] []
                            ]
                        ]
                    ]
                    Dropdown.menu [] [
                        Dropdown.content [] [
                            Dropdown.Item.a [ Dropdown.Item.Option.Props
                                                [ Href ""
                                                  OnClick onRenameClickHandler ] ] [
                                str "Rename"
                            ]
                            Dropdown.Item.a [ Dropdown.Item.Option.Props
                                                [ Href ""
                                                  OnClick onDeleteClickHandler ] ] [
                                str "Delete"
                            ]
                        ]
                    ]
                ]
            else nothing
        div [ ClassName styles.sessionActions ] [
            renderSaveButton ()
            renderLoadButton ()
            renderQuickActions ()
        ]
    let renderPrivacyStatus () =
        let icon =
            if session.isPublic then Fa.Solid.Globe
            else Fa.Solid.Lock
        let text =
            if session.isPublic then "Public"
            else "Private"
        fragment [] [
            Icon.icon [ Icon.CustomClass styles.propertyIcon ] [
                Fa.i [ icon ] []
            ]
            str text
        ]
    let renderShareLink () =
        if session.isPublic then
            Button.button [ Button.Color Color.IsDark
                            Button.OnClick onShareClickHandler ] [
                str "Copy Share Link"
            ]
        else nothing
    let renderPrivacyToggle () =
        if session.isPublic then
            Button.button [ Button.Color Color.IsPrimary
                            Button.OnClick onMakePrivateClickHandler ] [
                str "Make Private"
            ]
        else
            Button.button [ Button.Color Color.IsPrimary
                            Button.OnClick onMakePublicClickHandler ] [
                str "Make Public"
            ]
    div [ ClassName styles.session ] [
        div [ ClassName styles.sessionRow ] [
            div [ ClassName styles.sessionDetails ] [
                Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                    str session.name
                ]
                div [] [
                    Icon.icon [ Icon.CustomClass styles.propertyIcon ] [
                        Fa.i [ Fa.Solid.History ] []
                    ]
                    str (session.lastSavedAt |> formatSaveDate)
                ]
                div [] [
                    renderPrivacyStatus ()
                ]
            ]
            renderActions ()
        ]
        Field.div [ Field.CustomClass styles.visibilityPanel ] [
            renderPrivacyToggle ()
            renderShareLink ()
        ]
    ]


/// <summary>
///     Renders a pane to manage the current session.
/// </summary>
///
/// <param name="showPremiumAd">
///     Whether the premium ads should be shown.
/// </param>
///
/// <param name="hideCurrentSession">
///     Whether the current session should be hidden.
/// </param>
///
/// <param name="sessionIsSaving">
///     Whether session saving is currently occuring.
/// </param>
///
/// <param name="mapViewerLink">
///     The link to the map viewer.
/// </param>
///
/// <param name="onSaveSessionClick">
///     The function that is called when the "Save Session" button is clicked.
/// </param>
///
/// <param name="onMakeSessionPrivate">
///     The function that is called when the "Make Private" button is clicked.
/// </param>
///
/// <param name="onMakeSessionPublic">
///     The function that is called when the "Make Public" button is clicked.
/// </param>
///
/// <param name="onShareSessionClick">
///     The function that is called when "Share Session"" is clicked.
/// </param>
///
/// <param name="onRenameSessionClick">
///     The function that is called when the "Rename Session" button is clicked.
/// </param>
///
/// <param name="onDeleteSessionClick">
///     The function that is called when the "Delete Session" button is clicked.
/// </param>
///
/// <param name="onOpenExistingSessionClick">
///     The function that is called when the "Open Existing Session" button is clicked.
/// </param>
///
/// <param name="currentSession">
///     The current Session.
/// </param>
let sessionPane
    (showPremiumAds : bool)
    (hideCurrentSession : bool)
    (sessionIsSaving : bool)
    (mapViewerLink : string)
    (onSaveSessionClick : MouseEvent -> unit)
    (onMakeSessionPrivate : SessionId -> unit)
    (onMakeSessionPublic : SessionId -> unit)
    (onShareSessionClick : SessionId -> unit)
    (onRenameSession : string -> SessionId -> unit)
    (onDeleteSessionClick : SessionId -> unit)
    (onExistingSessionsClick : MouseEvent -> unit)
    (currentSession : Fetchable<SessionId * Session> option)
    : ReactElement =

    let styles : Styles = importAll "./SessionManagementPane.sass"
    let renderCurrentSession session =
        match session, hideCurrentSession with
        | Some (Fetched session), false ->
            Field.div [] [
                session
                |> renderSession
                       sessionIsSaving
                       true
                       false
                       true
                       onSaveSessionClick
                       onMakeSessionPrivate
                       onMakeSessionPublic
                       onShareSessionClick
                       onRenameSession
                       onDeleteSessionClick
                hr []
            ]
        | Some Fetching, false
        | None, false ->
            Field.div [] [
                Button.button [ Button.Color Color.IsSuccess
                                Button.OnClick onSaveSessionClick ] [
                    str "Save Session"
                ]
                hr []
            ]
        | _, true ->
            nothing

    let renderContent () =
        if showPremiumAds then
            PremiumAd.ad ()
        else
            fragment [] [
                renderCurrentSession currentSession
                Field.div [] [
                    Button.a [ Button.Color Color.IsInfo
                               Button.Props [ Href ((Game.Dota2, None) |> Route.Strategiser |> Route.getPath) ] ] [
                        str "New Session"
                    ]
                ]
                Field.div [] [
                    Button.button [ Button.Color Color.IsInfo
                                    Button.OnClick onExistingSessionsClick ] [
                        str "Existing Sessions"
                    ]
                ]
                Field.div [] [
                    Button.a [ Button.Color Color.IsGrey
                               Button.Props [ Href mapViewerLink ] ] [
                        str "Map Viewer"
                    ]
                ]
            ]

    div [ ClassName styles.container ] [
        renderContent ()
    ]

/// <summary>
///     Renders a pane to view and open existing sessions.
/// </summary>
///
/// <param name="onGoBackClick">
///     The function that is called when the "Go Back" button is clicked.
/// </param>
///
/// <param name="onMakeSessionPublic">
///     The function that is called when the "Make Private" button is clicked.
/// </param>
///
/// <param name="onMakeSessionPublic">
///     The function that is called when the "Make Public" button is clicked.
/// </param>
///
/// <param name="onShareSessionClick">
///     The function that is called when "Share Session"" is clicked.
/// </param>
///
/// <param name="onRenameSession">
///     The function that is called when session is renamed.
/// </param>
///
/// <param name="onDeleteSessionClick">
///     The function that is called when the "Delete Session" button is clicked.
/// </param>
///
/// <param name="sessions">
///     A list of Sessions.
/// </param>
let existingSessions
    (onGoBackClick : MouseEvent -> unit)
    (onMakeSessionPrivate : SessionId -> unit)
    (onMakeSessionPublic : SessionId -> unit)
    (onShareSessionClick : SessionId -> unit)
    (onRenameSession : string -> SessionId -> unit)
    (onDeleteSessionClick : SessionId -> unit)
    (sessions : Fetchable<(SessionId * Session) list>)
    : ReactElement =

    let styles : Styles = importAll "./SessionManagementPane.sass"
    div [ ClassName styles.container ] [
        yield
            match sessions with
            | Fetched sessions ->
                fragment [] [
                    Button.button [ Button.OnClick onGoBackClick ] [
                        str "Go Back"
                    ]
                    div [] [
                        sessions
                        |> List.map
                               (renderSession
                                    false
                                    false
                                    true
                                    true
                                    ignore
                                    onMakeSessionPrivate
                                    onMakeSessionPublic
                                    onShareSessionClick
                                    onRenameSession
                                    onDeleteSessionClick)
                        |> ofList
                    ]
                ]
            | Fetching ->
                Loading.loading ()
    ]