/// Pricing Card for the Pricing Page
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.CheckoutItem

open System

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms

type Styles =
    { card : string
      footerLink : string
      lineItem : string }

/// <summary>
///     Renders a "checkout" item, like a line item on a receipt.
/// </summary>
///
/// <param name="order">
///     The order.
/// </param>
let checkoutItem
    (order : Order)
    : ReactElement =

    let styles : Styles = importAll "./CheckoutItem.sass"

    match order.lineItem with
    | Subscribe plan ->
        Card.card [] [
            Card.content [ CustomClass styles.lineItem ] [
                div [] [
                    str (order.game |> Game.getName)
                    Text.p [ Modifiers [ Modifier.TextSize (Screen.All, TextSize.Is7)
                                         Modifier.TextTransform TextTransform.Italic ] ] [
                        sprintf "Premium (%s)" (plan.billingPeriod |> BillingPeriod.toString)
                        |> str
                    ]
                ]
                div [] [
                    plan
                    |> PlanPricing.getCurrentPrice
                    |> Price.amount
                ]
            ]
        ]
    | Cancel ->
        nothing