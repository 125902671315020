[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.PricingPage

open System

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props

open Elmish
open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.PricingPage
open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Services

type Model =
    { CurrentProduct : Product
      UserProfile : UserProfile option }

type Msg =
    | UserProfileDoesNotExist of AuthenticatedUser
    | FetchUserProfileError of exn
    | UserProfileFetched of UserProfile
    | ClickProductTab of Product
    | ClickSubscribe of Game * Plan

module Cmd =

    let fetchUserProfile
        (app : AppConfig)
        (authenticatedUser : AuthenticatedUser)
        : Cmd<Msg> =

        let ofSuccess result =
            match result with
            | Some firestoreUser ->
                UserProfileFetched firestoreUser
            | None ->
                UserProfileDoesNotExist authenticatedUser
        let ofError (exn : exn) =
            FetchUserProfileError exn
        Cmd.ofPromise
            app.userStoreService.getUser
            authenticatedUser.id
            ofSuccess
            ofError

    let proccedToCheckout
        (game : Game)
        (plan : Plan)
        : Cmd<Msg> =

        let lineItem =
            plan
            |> LineItem.Subscribe

        match game with
        | Game.Dota2 ->
            { createdAt = DateTimeOffset.Now
              game = game
              lineItem = lineItem }
        |> LocalStorageService.saveSubscriptionOrder

        RouterCmd.navigateTo Route.Checkout

let init
    (app : AppConfig)
    (optionalUser : AuthenticatedUser option)
    : Model * Cmd<Msg> =

    let cmd =
        match optionalUser with
        | Some user -> Cmd.fetchUserProfile app user
        | None -> Cmd.none

    { CurrentProduct = Dota2
      UserProfile = None }, cmd

let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> =

    match msg with
    | UserProfileFetched userProfile ->
        let newModel =
            { currentModel with UserProfile = userProfile |> Some }
        newModel, Cmd.none
    | UserProfileDoesNotExist authenticatedUser ->
        let newModel =
            { currentModel with UserProfile = None }
        newModel, Cmd.none
    | FetchUserProfileError _ ->
        let newModel =
            { currentModel with UserProfile = None }
        newModel, Cmd.none

    | ClickProductTab product ->
        let newModel =
            { currentModel with CurrentProduct = product }
        newModel, Cmd.none

    | ClickSubscribe (game, plan) ->
        currentModel, Cmd.proccedToCheckout game plan

type private Styles =
    { active : string
      dota2 : string
      hasPlans : string
      lol : string
      ow : string
      productWell : string
      productTab : string
      well : string }

let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    let styles : Styles = importAll "./Pricing.sass"

    let onGameTabClickHandler product =
        (ClickProductTab product)
        |> dispatch

    let renderTab product =
        Tabs.tab [ Tabs.Tab.CustomClass styles.productTab
                   Tabs.Tab.IsActive (product = model.CurrentProduct)
                   Tabs.Tab.Props [ OnClick (fun _ -> onGameTabClickHandler product) ] ] [
            a [] [
                product
                |> Product.getName
                |> str
            ]
        ]

    let renderProductWell product =

        let onSubscribeClickHandler game billingPeriod =

            (game, { package = Standard; billingPeriod = billingPeriod })
            |> ClickSubscribe
            |> dispatch

        let className =
            ClassNames.classNames
                [ (styles.productWell, true)
                  (styles.active, product = model.CurrentProduct) ]
        div [ ClassName className ] [
            match product with
            | Product.Dota2 ->
                let isSubscribed =
                    match optionalUser with
                    | Some user ->
                        user.claims |> UserClaim.isSubscribed Game.Dota2
                    | None ->
                        false
                let hasTrialled =
                    match model.UserProfile with
                    | Some { trialsUsed = trialsUsed } ->
                        trialsUsed
                        |> TrialsUsedSet.isTrialAvailable Game.Dota2
                        |> not
                    | None ->
                        false
                yield div [ ClassName styles.hasPlans ] [
                    PricingTable.table
                        isSubscribed
                        hasTrialled
                        (onSubscribeClickHandler Game.Dota2)
                        (Dota2 |> Product.getFreeFeatures)
                        (Dota2 |> Product.getPremiumFeatures)
                ]
            | Product.LeagueOfLegends ->
                yield FlashyCTA.Horizontal.leagueOfLegendsNoBg ()
            | Product.Overwatch ->
                yield FlashyCTA.Horizontal.overwatchNoBg ()
        ]

    let wellClassName =
        match model.CurrentProduct with
        | Dota2 ->
            styles.dota2
        | LeagueOfLegends ->
            styles.lol
        | Overwatch ->
            styles.ow
        + " "
        + styles.well

    StandardTemplate.template [
        Section.section [ Section.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
            Heading.h1 [] [
                str "Pricing Plans"
            ]
            p [] [
                str "Get premium access to the ultimate strategy toolset."
            ]
        ]

        div [ ClassName wellClassName ] [
            Tabs.tabs [ Tabs.IsCentered
                        Tabs.IsToggle
                        Tabs.IsToggleRounded ] [
                Product.all
                |> List.map renderTab
                |> ofList
            ]
            Product.all
            |> List.map renderProductWell
            |> ofList
        ]

        Section.section [] [
            Box.box' [] [
                Heading.h3 [] [
                    str "Are you a content creator/influencer?"
                ]
                p [] [
                    a [ Href "https://forms.gle/Fxu7Z6VHxDDwjXnh6" ] [
                        str "Fill out this form"
                    ]
                    str " to find out how you can collaborate with StatBanana!"
                ]
            ]
            Box.box' [] [
                Heading.h3 [] [
                    str "How does the free trial work?"
                ]
                p [] [
                    str "The trial gives you full premium access to StatBanana for 14 days that you can start using instantly. \
                         No credit card required, you only pay when you are ready to upgrade."
                ]
            ]
        ]

    ] (Some Route.Pricing) optionalUser onSignOut
