/// A timehead for a timeline
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.TimelinePlayhead

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

type Styles =
    { container : string
      head : string
      line : string }

/// <summary>
///     Renders a playhead in the context of a timeline.
/// </summary>
///
/// <param name="onRef">
///     The function that can be used to extract the React ref of a
///     rendered playhead
/// </param>
let playhead (onRef : Browser.Element -> unit ) : ReactElement =
    let styles: Styles = importAll "./TimelinePlayhead.sass"

    div [ ClassName styles.container
          Ref onRef ] [
        div [ ClassName (styles.head + " handle") ] []
        div [ ClassName styles.line ] []
    ]