namespace StatBanana.Web.Client.Dto.Firestore.Strategiser

open System

open Fable.Core.JsInterop

open StatBanana.Utils
open StatBanana.Web.Client.Domain

/// Field definitions for Firestore Layer DTOs.
[<RequireQualifiedAccess>]
module LayerDto =
    module LayerUnit =
        let fromDomain (unit : Strategiser.LayerUnit) : obj =
            createObj [
                "id" ==> (unit.id |> Strategiser.LayerId.toString)
                "item" ==> (unit.item |> ItemDto.Item.fromDomain)
                "keyframes" ==>
                    (unit.keyframes |> KeyframeDto.Keyframes.fromDomain)
                "locked" ==> unit.locked
                "name" ==> unit.name
                "perpetual" ==> unit.perpetual
                "selected" ==> unit.selected
                "visible" ==> unit.visible
            ]

        let toDomain (unit : obj) : Strategiser.LayerUnit =
            { id =
                unit
                |> JsonAdapter.getString "id"
                |> Guid.Parse
                |> Strategiser.LayerId.ofGuid
              item = unit |> JsonAdapter.getObj "item" |> ItemDto.Item.toDomain
              keyframes =
                  unit
                  |> JsonAdapter.getArray "keyframes"
                  |> KeyframeDto.Keyframes.toDomain
              locked = unit |> JsonAdapter.getBool "locked"
              name = unit |> JsonAdapter.getString "name"
              perpetual = unit |> JsonAdapter.getBool "perpetual"
              selected = unit |> JsonAdapter.getBool "selected"
              visible = unit |> JsonAdapter.getBool "visible" }

    module LayerGroup =
        let fromDomain (group : Strategiser.LayerGroup) : obj =
            let children =
                group.children |> List.map LayerUnit.fromDomain |> List.toArray
            createObj [
                "children" ==> children
                "id" ==> (group.id |> Strategiser.LayerId.toString)
                "locked" ==> group.locked
                "name" ==> group.name
                "opened" ==> group.opened
                "perpetual" ==> group.perpetual
                "visible" ==> group.visible
            ]

        let toDomain (group : obj) : Strategiser.LayerGroup =
            { children =
                group
                |> JsonAdapter.getArray "children"
                |> List.map LayerUnit.toDomain
              id =
                group
                |> JsonAdapter.getString "id"
                |> Guid.Parse
                |> Strategiser.LayerId.ofGuid
              locked = group |> JsonAdapter.getBool "locked"
              name = group |> JsonAdapter.getString "name"
              opened = group |> JsonAdapter.getBool "opened"
              perpetual = group |> JsonAdapter.getBool "perpetual"
              visible = group |> JsonAdapter.getBool "visible" }

    module Layer =
        let fromDomain (layer : Strategiser.Layer) : obj =
            match layer with
            | Strategiser.LayerGroup group ->
                createObj [
                    "Tag" ==> "LayerGroup"
                    "LayerUnitData" ==> null
                    "LayerGroupData" ==> (group |> LayerGroup.fromDomain)
                ]
            | Strategiser.LayerUnit unit ->
                createObj [
                    "Tag" ==> "LayerUnit"
                    "LayerUnitData" ==> (unit |> LayerUnit.fromDomain)
                    "LayerGroupData" ==> null
                ]

        let toDomain (layer : obj) : Strategiser.Layer =
            let tag = layer |> JsonAdapter.getString "Tag"
            match tag with
            | "LayerGroup" ->
                layer
                |> JsonAdapter.getObj "LayerGroupData"
                |> LayerGroup.toDomain
                |> Strategiser.LayerGroup
            | "LayerUnit" ->
                layer
                |> JsonAdapter.getObj "LayerUnitData"
                |> LayerUnit.toDomain
                |> Strategiser.LayerUnit
            | unexpectedTag ->
                sprintf "Unexpected Layer DTO tag: %s" unexpectedTag
                |> JsonAdapter.JsonParsingException
                |> raise