module StatBanana.Web.Client.Components.Organisms.DemoFinishedModal

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Domain

/// <summary>
///     Renders a modal that is shown at the end of a demo.
/// </summary>
///
/// <param name="isOpened.">
///     Whether the modal is opened.
/// </param>
///
/// <param name="onClose">
///     The function to call when the modal is closed.
/// </param>
let modal (game : Game) (isOpened : bool) =
    Modal.modal [ Modal.IsActive isOpened
                  Modal.Props [ Id "DemoFinishedModal" ] ] [
        Modal.background [] []
        Modal.content [] [
            Box.box' [] [
                Heading.h2 [ Heading.Modifiers
                                [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                    str "Time's Up"
                ]
                Content.content [] [
                    p [] [
                        str "You've reached the 5 minute time limit for this session."
                    ]
                    p [] [
                        str "Would you like "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "unlimited access to StatBanana"
                        ]
                        str " with the ability to "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "save your sessions"
                        ]
                        str " and "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "host collaborative sessions"
                        ]
                        str "?"
                    ]
                    p [] [
                        str "Start your 14 day free trial of StatBanana Premium today!"
                    ]
                ]
                Button.list [] [
                    Button.a [ Button.Color Color.IsWarning
                               Button.Props [ Href (None |> Route.SignUp |> Route.getPath) ]
                               Button.IsFullWidth ] [
                        str "Start Free Trial"
                    ]
                    Button.a [ Button.IsFullWidth
                               Button.IsOutlined
                               Button.Props [ Href (Route.Demo game |> Route.getPath) ] ] [
                        str "Start New Session"
                    ]
                ]
            ]
        ]
    ]