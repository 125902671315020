/// A set of wrapped fulma buttons
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.Button

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Web.Client.Domain

type Styles =
    { cta : string
      nav : string }

/// <summary>
///     Renders a button that can toggle between play and pause
/// </summary>
///
/// <param name="currentPlaybackState">
///     The current playback state to toggle the button
/// </param>
/// <param name="onPlay">
///     The function to call when the button is clicked, and it's paused
/// </param>
/// <param name="onPause">
///     The function to call when the button is clicked, and it's playing
/// </param>
let playPauseButton
    (currentPlaybackState : Strategiser.PlaybackState)
    (onClickPlay : MouseEvent -> unit)
    (onClickPause : MouseEvent -> unit) : ReactElement =
    match currentPlaybackState with
    | Strategiser.Paused ->
        Button.button [ Button.Color IsDark
                        Button.OnClick onClickPlay ] [
            Icon.icon [] [
                Fa.i [ Fa.Solid.Play ] []
            ]
        ]
    | Strategiser.Playing ->
        Button.button [ Button.Color IsDark
                        Button.OnClick onClickPause ] [
            Icon.icon [] [
                Fa.i [ Fa.Solid.Pause ] []
            ]
        ]

let mainCtaButton (size : ISize) (href : string) (text : string) =
       let styles : Styles = importAll "./Button.sass"
       Button.a [ Button.Color Color.IsWarning
                  Button.CustomClass styles.cta
                  Button.IsRounded
                  Button.Props [ Href href ]
                  Button.Size size ] [
           str text
       ]

let secondaryCtaButton (size : ISize) (href : string) (text : string) =
       let styles : Styles = importAll "./Button.sass"
       Button.a [ Button.Color Color.IsWarning
                  Button.CustomClass styles.cta
                  Button.IsOutlined
                  Button.IsRounded
                  Button.Props [ Href href ]
                  Button.Size size ] [
           str text
       ]

let mainNavLink (size : ISize) (href : string) (text : string) =
       let styles : Styles = importAll "./Button.sass"
       Button.a [ Button.CustomClass styles.nav
                  Button.Props [ Href href ]
                  Button.Size size ] [
           str text
       ]