/// Contains the auth form
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.Authentication

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Components.Molecules

/// <summary>
///     Renders an auth container.
/// </summary>
///
/// <param name="onAuthComponentRendered">
///     Callback for when the auth component is rendered.
/// </param>
///
/// <param name="formTitle">
///     The title of the auth form.
/// </param>
///
/// <param name="formDescription">
///     The description of the auth form.
/// </param>
///
/// <param name="formLink">
///     The link that will be rendered at the bottom of the auth form
/// </param>
///
/// <returns>
///     The rendered auth container.
/// </returns>
let authentication
    (isUIPendingRedirect : bool)
    (onAuthComponentRendered : string -> unit)
    (formTitle : string)
    (formDescription : string)
    (formLink : ReactElement)
    : ReactElement =

    importAll "./Authentication.sass"
    div [ ClassName "auth-container" ] [
        AuthForm.authForm
            isUIPendingRedirect
            onAuthComponentRendered
            formTitle
            formDescription
            formLink
    ]