/// Functions that serve as safer wrappers around AnimeJS
module StatBanana.Web.Client.Extensions.AnimeJS

open Fable.Core
open Fable.Core.JsInterop

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.AnimeJS
open StatBanana.Web.Client.Import.AnimeJS.Anime

/// <summary>
///     Converts MapItemPositionKeyframes into AnimeJS keyframes for animation.
///     Returns keyframes for translateX and translateY as a tuple
/// </summary>
///
/// <param name="timelineDuration">
///     The duration of the timeline
/// </param>
///
/// <param name="map">
///     The Leaflet map used for point conversion
/// </param>
///
/// <param name="keyframes">
///     The keyframes to convert to anime position keyframes.
/// </param>
let generateAnimePositionKeyframes
    (timelineDuration : int)
    (mapZoom : float)
    (keyframes : Strategiser.Keyframes) : Keyframes * Keyframes =
    let translateXKeyframes = Keyframes ([])
    let translateYKeyframes = Keyframes ([])

    // Create first keyframes to set initial state
    translateXKeyframes.Add
        (jsOptions<PropertyParameters> (fun options ->
            options.duration <- 0. |> U2.Case2
            options.value <- 0. |> U2.Case1 |> U3.Case1))
    translateYKeyframes.Add
        (jsOptions<PropertyParameters> (fun options ->
            options.duration <- 0. |> U2.Case2
            options.value <- 0. |> U2.Case1 |> U3.Case1))

    // Get position info from the first keyframe, positionally everything is
    // is pinned from the first keyframe position
    let firstKeyframeInfo =
        keyframes
        |> Map.toList
        |> List.head
        |> snd
    let firstPosition = firstKeyframeInfo.position

    let extractAnimeJSPositionKeyframes
        (keyframePair : Strategiser.Keyframe * Strategiser.Keyframe) =
        let ((previousTime, _), (currentTime, currentKeyframeInfo)) =
            keyframePair
        let duration =
            match (currentTime - previousTime) with
            | diff when diff < 0 -> 0
            | diff -> diff
            |> float
            |> U2.Case2

        // we want to look for the difference between the current point
        // and the very first keyframe point. all animations are mapped
        // relatively from the first keyframe point not from each keyframe
        // so the transform value that gets animated assumes that
        // x:0, y:0 is always the first keyframe
        let yValue, xValue =
            let currentPosition = currentKeyframeInfo.position
            currentPosition.latitude - firstPosition.latitude,
            currentPosition.longitude - firstPosition.longitude
        let zoomFactor = 2. ** mapZoom
        translateXKeyframes.Add
            (jsOptions<PropertyParameters>
                (fun options ->
                    options.duration <- duration
                    options.value <- xValue * zoomFactor
                                     |> U2.Case1
                                     |> U3.Case1))
        translateYKeyframes.Add
            (jsOptions<PropertyParameters>
                (fun options ->
                    options.duration <- duration
                    options.value <- -yValue * zoomFactor
                                     |> U2.Case1
                                     |> U3.Case1))

    // Convert keyframes to animekeyframes
    keyframes
    |> Map.toSeq
    |> Seq.pairwise
    |> Seq.iter extractAnimeJSPositionKeyframes

    // Create a "final" keyframe to fill the animation up otherwise
    // the duration of the animation only becomes as long as you've
    // keyframed up to e.g. if your last keyframe is at 10s, the total
    // duration of the animation would be 10s which isn't correct
    let lastKeyframeTime, _ = keyframes |> Map.toArray |> Array.last
    let lastXPoint = translateXKeyframes.[translateXKeyframes.Count - 1]
    let lastYPoint = translateYKeyframes.[translateYKeyframes.Count - 1]
    let difference = timelineDuration - lastKeyframeTime
    match difference with
    | diff when difference > 0 ->
        translateXKeyframes.Add
            (jsOptions<PropertyParameters> (fun options ->
                    options.duration <- diff |> float |> U2.Case2
                    options.value <- lastXPoint?value |> U2.Case1 |> U3.Case1))
        translateYKeyframes.Add
            (jsOptions<PropertyParameters> (fun options ->
                options.duration <- diff |> float |> U2.Case2
                options.value <- lastYPoint?value |> U2.Case1 |> U3.Case1))
        translateXKeyframes, translateYKeyframes
    | _ -> translateXKeyframes, translateYKeyframes

/// <summary>
///     Creates params for AnimeJS timeline for a LayerUnit.
/// </summary>
///
/// <param name="timelineDuration">
///     The duration of the timeline.
/// </param>
///
/// <param name="mapZoom">
///     The Leaflet map zoom level.
/// </param>
///
/// <param name="targetId">
///     The unique ID of the for AnimeJS to target.
/// </param>
///
/// <param name="layerUnit">
///     The LayerUnit to generate AnimeJS timeline params for.
/// </param>
let generateAnimeTimelineParamsFromLayerUnit
    (timelineDuration : int)
    (mapZoom : float)
    (targetId : string)
    (easing : EasingOptions)
    (layerUnit : Strategiser.LayerUnit) : AnimeTimelineParams =
    jsOptions<AnimeTimelineParams> (fun options ->
        options.easing <- easing |> U3.Case1 |> Some
        options.targets <-
            let circleId = targetId.Replace ("id-", "circle-")
            let targets = [| "." + targetId ; "." + circleId |]
            // can't be downcast without changing bindings
            !!targets |> U2.Case2
        let translateXKeyFrames, translateYKeyFrames =
            layerUnit.keyframes
            |> generateAnimePositionKeyframes timelineDuration mapZoom
        options.translateX <- translateXKeyFrames |> U3.Case3 |> Some
        options.translateY <- translateYKeyFrames |> U3.Case3 |> Some)

/// <summary>
///     The function that creates a base anime timeline to act as the central
///     controller for our animations
/// </summary>
let initAnimeTimeline _ : AnimeTimelineInstance =
    anime.timeline
        (jsOptions<AnimeParams> <| fun options ->
            options.autoplay <- false |> Some
            options.direction <- DirectionOptions.Normal |> U2.Case1 |> Some
    |> U2.Case1)