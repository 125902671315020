/// Monospaced content
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.MonospaceBlock

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

type Styles = { monospace : string }

/// <summary>
///     Renders given content with monospaced type, and a code-style background.
/// </summary>
///
/// <param name="content">
///     The content to be rendered in the block
/// </param>
let block (content : ReactElement) : ReactElement =
    let styles : Styles = importAll "./MonospaceBlock.sass"
    span [ ClassName styles.monospace ] [ content ]