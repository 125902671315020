/// Subscription Card for User Settings page
[<RequireQualifiedAccess>]
module rec StatBanana.Web.Client.Components.Molecules.SubscriptionCard

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open Fable.FontAwesome
open System
open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms

type Styles = { attribute : string
                attributeTitle : string
                changeBillingCycleLink : string }

let private renderAttribute title attribute =
    let styles : Styles = importAll "./SubscriptionCard.sass"

    div [ ClassName styles.attribute ] [
        Text.div [ CustomClass styles.attributeTitle
                   Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
            str title
        ]
        attribute |> ofList
    ]

/// Renders a subscription card where user has an active trial subscription.
let trial
    (game : Game)
    (firstBillingDate : DateTimeOffset)
    (onClickCancelHandler : MouseEvent -> unit) =

    let styles : Styles = importAll "./SubscriptionCard.sass"

    Card.card [] [
        Card.header [] [
            Card.Header.title [ Card.Header.Title.IsCentered ] [
                game |> Game.getAbbreviatedName |> str
            ]
        ]
        Card.content [] [
            renderAttribute "Status" [
                SubscriptionStatusTag.trial ()
            ]
            renderAttribute "Trial Expiry Date" [
                firstBillingDate.Date.ToShortDateString() |> str
            ]
        ]
        Card.footer [] [
            Card.Footer.a [ GenericOption.Props [ OnClick onClickCancelHandler ] ] [
                str "Cancel Trial"
            ]
        ]
    ]

/// Renders a subscription card where user has a current active subscription.
let subscribed
    (game : Game)
    (paymentMethod : PaymentMethod)
    (currentBillingPeriod : BillingPeriod)
    (nextBillingPeriod : BillingPeriod option)
    (nextBillingDate : DateTimeOffset)
    (onClickCancelHandler : MouseEvent -> unit) =

    let styles : Styles = importAll "./SubscriptionCard.sass"

    let billingPeriodText =
        let currentPeriod =
            currentBillingPeriod |> BillingPeriod.toString
        let renewalText =
            match nextBillingPeriod with
            | Some nextBillingPeriod ->
                if currentBillingPeriod <> nextBillingPeriod then
                    " (" + (nextBillingPeriod |> BillingPeriod.toString) +
                    " after next payment date)"
                else
                    ""
            | None ->
                ""

        currentPeriod + renewalText

    Card.card [] [
        Card.header [] [
            Card.Header.title [ Card.Header.Title.IsCentered ] [
                game |> Game.getAbbreviatedName |> str
            ]
        ]
        Card.content [] [
            renderAttribute "Status" [
                SubscriptionStatusTag.subscribed ()
            ]
            renderAttribute "Payment Method" [
                match paymentMethod with
                | PaymentMethod.PayPal ->
                    yield Fa.i [ Fa.Brand.CcPaypal
                                 Fa.Size Fa.Fa2x ] []
                | PaymentMethod.Stripe cardInfo ->
                    yield div [] [
                        Fa.i [ Fa.Solid.CreditCard
                               Fa.Size Fa.Fa2x
                               Fa.PullLeft ] []
                        CardInfo.info cardInfo
                    ]
            ]
            renderAttribute "Payment Cycle" [
                str billingPeriodText
            ]
            renderAttribute "Next Payment Date" [
                nextBillingDate.Date.ToShortDateString() |> str
            ]
        ]
        Card.footer [] [
            Card.Footer.a [ GenericOption.Props [ OnClick onClickCancelHandler ] ] [
                str "Cancel Subscription"
            ]
        ]
    ]

/// Renders a subscription card where user has a cancelled subscription.
let cancelled
    (game : Game)
    (expiryDate : DateTimeOffset) =

    Card.card [] [
        Card.header [] [
            Card.Header.title [ Card.Header.Title.IsCentered ] [
                game |> Game.getAbbreviatedName |> str
            ]
        ]
        Card.content [] [
            renderAttribute "Status" [
                SubscriptionStatusTag.cancelled ()
            ]
            renderAttribute "Subscription Expiry Date" [
                expiryDate.Date.ToShortDateString() |> str
            ]
        ]
    ]

/// Renders a subscription card where user has no current subscription.
let free (game : Game) =
    let styles : Styles = importAll "./SubscriptionCard.sass"

    Card.card [] [
        Card.header [] [
            Card.Header.title [ Card.Header.Title.IsCentered ] [
                game |> Game.getAbbreviatedName |> str
            ]
        ]
        Card.content [] [
            div [ ClassName styles.attribute ] [
                Text.div [ CustomClass styles.attributeTitle
                           Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                    str "Status"
                ]
                SubscriptionStatusTag.free ()
            ]
        ]
    ]