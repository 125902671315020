namespace StatBanana.Web.Client.Domain.Dota2

/// The possible tower types in Dota 2.
type TowerType =
    | Tier1
    | Tier2
    | Tier3
    | Tier4
module TowerType =
    /// A list of all the tower types in Dota 2.
    let all =
        [ Tier1
          Tier2
          Tier3
          Tier4 ]

/// The possible barracks types in Dota 2.
type BarracksType =
    | Melee
    | Ranged

/// The possible building types in Dota 2.
type BuildingType =
    | Ancient
    | Barracks of BarracksType
    | Outpost
    | Shrine
    | Tower of TowerType
module BuildingType =
    /// A list of all the building types in Dota 2.
    let all =
        [ Ancient
          Barracks Ranged
          Barracks Melee
          Outpost
          Shrine
          Tower Tier1
          Tower Tier2
          Tower Tier3
          Tower Tier4 ]

    /// <summary>
    ///    Get the name for a given Dota 2 building type.
    /// </summary>
    ///
    /// <param name="building">
    ///     The Dota 2 building type to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 building type.
    /// </returns>
    let getName (building : BuildingType) : string=
        match building with
        | Ancient -> "Ancient"
        | Barracks Ranged -> "Ranged Barracks"
        | Barracks Melee -> "Melee Barracks"
        | Outpost -> "Outpost"
        | Shrine -> "Shrine"
        | Tower Tier1 -> "Tier 1 Tower"
        | Tower Tier2 -> "Tier 2 Tower"
        | Tower Tier3 -> "Tier 3 Tower"
        | Tower Tier4 -> "Tier 4 Tower"

/// The possible buildings in Dota 2.
type Building =
    | Ancient of FactionType
    | Barracks of FactionType * Lane * BarracksType
    | Outpost of FactionType
    | Shrine of FactionType
    | Tower of FactionType * Lane * TowerType
module Building =
    /// A list of all the buildings in Dota 2
    let all =
        seq {
            for faction in [ FactionType.Dire; FactionType.Radiant ] do
                yield Ancient faction
                yield Tower (faction, Lane.Top, TowerType.Tier4)
                yield Tower (faction, Lane.Bottom, TowerType.Tier4)
                for lane in Lane.all do
                    for barracksType in [ BarracksType.Melee; BarracksType.Ranged ] do
                        yield Barracks (faction, lane, barracksType)
                    for towerType in [ TowerType.Tier3; TowerType.Tier2; TowerType.Tier1 ] do
                        yield Tower (faction, lane, towerType)
                yield Outpost faction
        }
        |> Seq.toList

    /// <summary>
    ///    Serialise a given Dota 2 building to a string id value for persistance.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="building">
    ///     The Dota 2 building to serialise to a string id value.
    /// </param>
    ///
    /// <returns>
    ///     The serialised id value of the given Dota 2 building.
    /// </returns>
    let toId (building : Building) : string =
        match building with
        | Ancient FactionType.Dire ->
            "dire_ancient"
        | Ancient FactionType.Radiant ->
            "radiant_ancient"
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier4) ->
            "dire_top_tier4_tower"
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier4) ->
            "dire_bottom_tier4_tower"
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier4) ->
            "radiant_top_tier4_tower"
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier4) ->
            "radiant_bottom_tier4_tower"
        | Barracks (FactionType.Dire, Lane.Top, BarracksType.Melee) ->
            "dire_top_melee_barracks"
        | Barracks (FactionType.Dire, Lane.Top, BarracksType.Ranged) ->
            "dire_top_ranged_barracks"
        | Barracks (FactionType.Dire, Lane.Middle, BarracksType.Melee) ->
            "dire_middle_melee_barracks"
        | Barracks (FactionType.Dire, Lane.Middle, BarracksType.Ranged) ->
            "dire_middle_ranged_barracks"
        | Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Melee) ->
            "dire_bottom_melee_barracks"
        | Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Ranged) ->
            "dire_bottom_ranged_barracks"
        | Barracks (FactionType.Radiant, Lane.Top, BarracksType.Melee) ->
            "radiant_top_melee_barracks"
        | Barracks (FactionType.Radiant, Lane.Top, BarracksType.Ranged) ->
            "radiant_top_ranged_barracks"
        | Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Melee) ->
            "radiant_middle_melee_barracks"
        | Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Ranged) ->
            "radiant_middle_ranged_barracks"
        | Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Melee) ->
            "radiant_bottom_melee_barracks"
        | Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Ranged) ->
            "radiant_bottom_ranged_barracks"
        | Outpost (FactionType.Dire) ->
            "dire_outpost"
        | Outpost (FactionType.Radiant) ->
            "radiant_outpost"
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier3) ->
            "dire_top_tier3_tower"
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier2) ->
            "dire_top_tier2_tower"
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier1) ->
            "dire_top_tier1_tower"
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier3) ->
            "dire_middle_tier3_tower"
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier2) ->
            "dire_middle_tier2_tower"
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier1) ->
            "dire_middle_tier1_tower"
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier3) ->
            "dire_bottom_tier3_tower"
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier2) ->
            "dire_bottom_tier2_tower"
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier1) ->
            "dire_bottom_tier1_tower"
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier3) ->
            "radiant_top_tier3_tower"
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier2) ->
            "radiant_top_tier2_tower"
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier1) ->
            "radiant_top_tier1_tower"
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier3) ->
            "radiant_middle_tier3_tower"
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier2) ->
            "radiant_middle_tier2_tower"
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier1) ->
            "radiant_middle_tier1_tower"
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier3) ->
            "radiant_bottom_tier3_tower"
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier2) ->
            "radiant_bottom_tier2_tower"
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier1) ->
            "radiant_bottom_tier1_tower"
        | Shrine (FactionType.Dire) ->
            "dire_shrine"
        | Shrine (FactionType.Radiant) ->
            "radiant_shrine"
        | _ ->
            "invalid_building"

    /// <summary>
    ///     Deserialise a Dota 2 building that was serialised using <c>toId</c>.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="id">
    ///     The serialised Dota 2 building.
    /// </param>
    ///
    /// <returns>
    ///     Building, or an error message if the building could not be deserialised.
    /// </returns>
    let fromId (id : string) : Result<Building, string> =
        match id with
        | "dire_ancient" ->
            Ancient FactionType.Dire
            |> Ok
        | "radiant_ancient" ->
            Ancient FactionType.Radiant
            |> Ok
        | "dire_top_tier4_tower" ->
            Tower (FactionType.Dire, Lane.Top, TowerType.Tier4)
            |> Ok
        | "dire_bottom_tier4_tower" ->
            Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier4)
            |> Ok
        | "radiant_top_tier4_tower" ->
            Tower (FactionType.Radiant, Lane.Top, TowerType.Tier4)
            |> Ok
        | "radiant_bottom_tier4_tower" ->
            Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier4)
            |> Ok
        | "dire_top_melee_barracks" ->
            Barracks (FactionType.Dire, Lane.Top, BarracksType.Melee)
            |> Ok
        | "dire_top_ranged_barracks" ->
            Barracks (FactionType.Dire, Lane.Top, BarracksType.Ranged)
            |> Ok
        | "dire_middle_melee_barracks" ->
            Barracks (FactionType.Dire, Lane.Middle, BarracksType.Melee)
            |> Ok
        | "dire_middle_ranged_barracks" ->
            Barracks (FactionType.Dire, Lane.Middle, BarracksType.Ranged)
            |> Ok
        | "dire_bottom_melee_barracks" ->
            Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Melee)
            |> Ok
        | "dire_bottom_ranged_barracks" ->
            Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Ranged)
            |> Ok
        | "radiant_top_melee_barracks" ->
            Barracks (FactionType.Radiant, Lane.Top, BarracksType.Melee)
            |> Ok
        | "radiant_top_ranged_barracks" ->
            Barracks (FactionType.Radiant, Lane.Top, BarracksType.Ranged)
            |> Ok
        | "radiant_middle_melee_barracks" ->
            Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Melee)
            |> Ok
        | "radiant_middle_ranged_barracks" ->
            Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Ranged)
            |> Ok
        | "radiant_bottom_melee_barracks" ->
            Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Melee)
            |> Ok
        | "radiant_bottom_ranged_barracks" ->
            Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Ranged)
            |> Ok
        | "dire_outpost" ->
            Outpost (FactionType.Dire)
            |> Ok
        | "radiant_outpost" ->
            Outpost (FactionType.Radiant)
            |> Ok
        | "dire_top_tier3_tower" ->
            Tower (FactionType.Dire, Lane.Top, TowerType.Tier3)
            |> Ok
        | "dire_top_tier2_tower" ->
            Tower (FactionType.Dire, Lane.Top, TowerType.Tier2)
            |> Ok
        | "dire_top_tier1_tower" ->
            Tower (FactionType.Dire, Lane.Top, TowerType.Tier1)
            |> Ok
        | "dire_middle_tier3_tower" ->
            Tower (FactionType.Dire, Lane.Middle, TowerType.Tier3)
            |> Ok
        | "dire_middle_tier2_tower" ->
            Tower (FactionType.Dire, Lane.Middle, TowerType.Tier2)
            |> Ok
        | "dire_middle_tier1_tower" ->
            Tower (FactionType.Dire, Lane.Middle, TowerType.Tier1)
            |> Ok
        | "dire_bottom_tier3_tower" ->
            Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier3)
            |> Ok
        | "dire_bottom_tier2_tower" ->
            Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier2)
            |> Ok
        | "dire_bottom_tier1_tower" ->
            Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier1)
            |> Ok
        | "radiant_top_tier3_tower" ->
            Tower (FactionType.Radiant, Lane.Top, TowerType.Tier3)
            |> Ok
        | "radiant_top_tier2_tower" ->
            Tower (FactionType.Radiant, Lane.Top, TowerType.Tier2)
            |> Ok
        | "radiant_top_tier1_tower" ->
            Tower (FactionType.Radiant, Lane.Top, TowerType.Tier1)
            |> Ok
        | "radiant_middle_tier3_tower" ->
            Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier3)
            |> Ok
        | "radiant_middle_tier2_tower" ->
            Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier2)
            |> Ok
        | "radiant_middle_tier1_tower" ->
            Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier1)
            |> Ok
        | "radiant_bottom_tier3_tower" ->
            Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier3)
            |> Ok
        | "radiant_bottom_tier2_tower" ->
            Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier2)
            |> Ok
        | "radiant_bottom_tier1_tower" ->
            Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier1)
            |> Ok
        | "dire_shrine" ->
            Shrine (FactionType.Dire)
            |> Ok
        | "radiant_shrine" ->
            Shrine (FactionType.Radiant)
            |> Ok
        // Special cases (for backwards compatibility of Sessions)
        // Since both bottom shrines are removed
        // as of 7.23f, bottom shrines will error,
        // and top shrines will retain their state.
        |  "dire_top_shrine" ->
            Shrine (FactionType.Dire)
            |> Ok
        | "dire_bottom_shrine" ->
            Shrine (FactionType.Dire)
            |> Ok
        | "radiant_top_shrine" ->
            Shrine (FactionType.Radiant)
            |> Ok
        | "radiant_bottom_shrine" ->
            Shrine (FactionType.Radiant)
            |> Ok
        // Invalid or uncaught building.
        | invalidBuilding ->
            sprintf "Invalid Dota 2 building : %s" invalidBuilding
            |> Error

    /// <summary>
    ///    Get the attack information for a given Dota 2 building.
    /// </summary>
    ///
    /// <param name="building">
    ///     The Dota 2 building to obtain attack information for.
    /// </param>
    ///
    /// <returns>
    ///     The attack information for a given Dota 2 building.
    /// </returns>
    let getAttack (building : Building) : Attack option =
        match building with
        | Tower _ ->
            { range = Distance.fromIntLiteral 700 }
            |> Some
        | Ancient _
        | Barracks _
        | Outpost _
        | Shrine _ ->
            None

    /// <summary>
    ///    Get the building type for a given Dota 2 building.
    /// </summary>
    ///
    /// <param name="building">
    ///     The Dota 2 building to obtain the building type for.
    /// </param>
    let getBuildingType (building : Building) : BuildingType =
        match building with
        | (Ancient _) ->
            BuildingType.Ancient
        | (Barracks (_,_,barracksType)) ->
            BuildingType.Barracks barracksType
        | (Outpost (_)) ->
            BuildingType.Outpost
        | (Shrine (_)) ->
            BuildingType.Shrine
        | (Tower (_,_,towerType)) ->
            BuildingType.Tower towerType

    /// <summary>
    ///     Get the name for a given Dota 2 building.
    /// </summary>
    ///
    /// <param name="building">
    ///     The Dota 2 building to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 building.
    /// </returns>
    let getName (building : Building) : string =
        match building with
        | (Ancient side) ->
            String.concat
                " "
                [ FactionType.getName side
                  BuildingType.getName BuildingType.Ancient ]
        | (Barracks (side, lane, barracksType)) ->
            String.concat
                " "
                [ FactionType.getName side
                  Lane.getName lane
                  BuildingType.getName (BuildingType.Barracks barracksType) ]
        | (Outpost (side)) ->
            String.concat
                " "
                [ FactionType.getName side
                  BuildingType.getName BuildingType.Outpost ]
        | (Shrine (side)) ->
            String.concat
                " "
                [ FactionType.getName side
                  BuildingType.getName BuildingType.Shrine ]
        | (Tower (side, lane, towerType)) ->
            String.concat
                " "
                [ FactionType.getName side
                  Lane.getName lane
                  BuildingType.getName (BuildingType.Tower towerType) ]

    /// <summary>
    ///    Get the side for a given Dota 2 building.
    /// </summary>
    ///
    /// <param name="building">
    ///     The Dota 2 building to obtain a side for.
    /// </param>
    ///
    /// <returns>
    ///     The side of the given Dota 2 building.
    /// </returns>
    let getFaction (building : Building) : FactionType =
        match building with
        | (Ancient faction) ->
            faction
        | (Barracks (faction,_,_)) ->
            faction
        | (Outpost (faction)) ->
            faction
        | (Shrine (faction)) ->
            faction
        | (Tower (faction,_,_)) ->
            faction

    /// <summary>
    ///    Get the coordinates for a given Dota 2 building.
    /// </summary>
    ///
    /// <param name="neutralCamp">
    ///     The Dota 2 building to obtain a coordinates for.
    /// </param>
    ///
    /// <returns>
    ///     If valid Dota 2 building, the coordinates of the given Dota 2 building, otherwise None.
    /// </returns>
    let getCoordinates (building : Building) : Coordinates =
        match building with
        | Ancient (FactionType.Dire) ->
            { x = Coordinate -2899.0; y = Coordinate 12558.0 }
        | Ancient (FactionType.Radiant) ->
            { x = Coordinate -12375.0; y = Coordinate 2111.0 }
        | Barracks (FactionType.Dire, Lane.Top, BarracksType.Ranged) ->
            { x = Coordinate -1891.0; y = Coordinate 11074.0 }
        | Barracks (FactionType.Dire, Lane.Top, BarracksType.Melee) ->
            { x = Coordinate -2477.0; y = Coordinate 11091.0 }
        | Barracks (FactionType.Radiant, Lane.Top, BarracksType.Ranged) ->
            { x = Coordinate -10926.0; y = Coordinate 1213.0 }
        | Barracks (FactionType.Radiant, Lane.Top, BarracksType.Melee) ->
            { x = Coordinate -10926.0; y = Coordinate 1655.0 }
        | Barracks (FactionType.Dire, Lane.Middle, BarracksType.Ranged) ->
            { x = Coordinate -3595.0; y = Coordinate 11431.0 }
        | Barracks (FactionType.Dire, Lane.Middle, BarracksType.Melee) ->
            { x = Coordinate -3995.0; y = Coordinate 11848.0 }
        | Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Ranged) ->
            { x = Coordinate -11340.0; y = Coordinate 2846.0 }
        | Barracks (FactionType.Radiant, Lane.Middle, BarracksType.Melee) ->
            { x = Coordinate -11651.0; y = Coordinate 3194.0 }
        | Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Ranged) ->
            { x = Coordinate -4381.0; y = Coordinate 12988.0 }
        | Barracks (FactionType.Dire, Lane.Bottom, BarracksType.Melee) ->
            { x = Coordinate -4365.0; y = Coordinate 13595.0 }
        | Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Ranged) ->
            { x = Coordinate -12869.0; y = Coordinate 3547.0 }
        | Barracks (FactionType.Radiant, Lane.Bottom, BarracksType.Melee) ->
            { x = Coordinate -13330.0; y = Coordinate 3540.0 }
        | Outpost (FactionType.Dire) ->
            { x = Coordinate -3351.0; y = Coordinate 5759.0 }
        | Outpost (FactionType.Radiant) ->
            { x = Coordinate -11378.0; y = Coordinate 9874.0 }
        | Shrine (FactionType.Dire) ->
            { x = Coordinate -4259.219512; y = Coordinate 6701.560975610 }
        | Shrine (FactionType.Radiant) ->
            { x = Coordinate -7072.902439; y = Coordinate 3948.195121951 }
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier4) ->
            { x = Coordinate -3084.0; y = Coordinate 12026.0 }
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier4) ->
            { x = Coordinate -3084.0; y = Coordinate 12026.0 }
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier4) ->
            { x = Coordinate -3399.0; y = Coordinate 12337.0 }
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier4) ->
            { x = Coordinate -11943.0; y = Coordinate 2239.0 }
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier4) ->
            { x = Coordinate -11943.0; y = Coordinate 2239.0 }
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier4) ->
            { x = Coordinate -12247.0; y = Coordinate 2534.0 }
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier3) ->
            { x = Coordinate -2164.0; y = Coordinate 10762.0 }
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier3) ->
            { x = Coordinate -4010.0; y = Coordinate 11422.0 }
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier3) ->
            { x = Coordinate -4679.0; y = Coordinate 13297.0 }
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier3) ->
            { x = Coordinate -10599.0; y = Coordinate 1438.0 }
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier3) ->
            { x = Coordinate -11277.0; y = Coordinate 3227.0 }
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier3) ->
            { x = Coordinate -13099.0; y = Coordinate 3843.0 }
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier2) ->
            { x = Coordinate -1941.0; y = Coordinate 7522.0 }
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier2) ->
            { x = Coordinate -5523.0; y = Coordinate 9799.0 }
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier2) ->
            { x = Coordinate -7109.0; y = Coordinate 13185.0 }
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier2) ->
            { x = Coordinate -8243.0; y = Coordinate 1855.0 }
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier2) ->
            { x = Coordinate -9896.0; y = Coordinate 4313.0 }
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier2) ->
            { x = Coordinate -13291.0; y = Coordinate 7379.0 }
        | Tower (FactionType.Dire, Lane.Top, TowerType.Tier1) ->
            { x = Coordinate -1939.0; y = Coordinate 3241.0 }
        | Tower (FactionType.Dire, Lane.Middle, TowerType.Tier1) ->
            { x = Coordinate -6869.0; y = Coordinate 8001.0 }
        | Tower (FactionType.Dire, Lane.Bottom, TowerType.Tier1) ->
            { x = Coordinate -9064.0; y = Coordinate 13242.0 }
        | Tower (FactionType.Radiant, Lane.Top, TowerType.Tier1) ->
            { x = Coordinate -5795.0; y = Coordinate 1778.0 }
        | Tower (FactionType.Radiant, Lane.Middle, TowerType.Tier1) ->
            { x = Coordinate -8753.0; y = Coordinate 6090.0 }
        | Tower (FactionType.Radiant, Lane.Bottom, TowerType.Tier1) ->
            { x = Coordinate -13097.0; y = Coordinate 12000.0 }