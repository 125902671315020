/// Defines the standard editing layout as a template
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Templates.StandardEditingTemplate

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

type Styles =
    { bottomPane: string
      container : string
      editor : string
      leftPane : string
      notifications : string
      pane : string
      rightPane : string }

/// <summary>
///     Instantiates a page using the standard editing template.
/// </summary>
///
/// <param name="notifications">
///     A component to render in the "notifications" template part (bottom right
///     of the screen, sits on top everything).
/// </param>
/// <param name="editor">
///     A component to render in the "editor" template part (top two-thirds of
///     the screen, above the bottomPane).
/// </param>
/// <param name="rightPane">
///     A component to render in the "rightPane" template part (a floating
///     right sidebar that sits above the editor).
/// </param>
/// <param name="bottomPane">
///     A component to render in the "bottomPane" template part (bottom
///     two-thirds of the screen, below the editor).
/// </param>
let template
    (notifications : ReactElement)
    (editor : ReactElement)
    (leftPane : ReactElement)
    (rightPane : ReactElement)
    (bottomPane : ReactElement) : ReactElement =
    let styles : Styles = importAll "./StandardEditingTemplate.sass"
    let leftPaneClassName = styles.pane + " " + styles.leftPane
    let rightPaneClassName = styles.pane + " " + styles.rightPane
    fragment [] [
        div [ ClassName styles.notifications ] [
            notifications
        ]
        div [ ClassName styles.container ] [
            div [ ClassName styles.editor ] [
                div [ ClassName leftPaneClassName ] [
                    leftPane
                ]
                editor
                div [ ClassName rightPaneClassName ] [
                    rightPane
                ]
            ]
            div [ ClassName styles.bottomPane ] [
                bottomPane
            ]
        ]
    ]
