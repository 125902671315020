[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.PrivacyPage

open Fable.Helpers.React

open Elmish
open Fulma

open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain

/// Application state passed down to this page
type Model = unit

/// Events/actions that can be dispatched by this page.
type Msg = unit

/// Run on initialisation. Page is static, does nothing.
let init () : Model * Cmd<Msg> =
    (), Cmd.none

/// <summary>
///     Updates the model in response to a message
/// </summary>
/// <param name="msg">
///     The message to action
/// </param>
/// <param name="currentModel">
///     The model prior to actioning the message
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model) : Model * Cmd<Msg> =
    match currentModel, msg with
    | (), () -> currentModel, Cmd.none

/// <summary>
///     Defines the view to render based on the current state.
/// </summary>
/// <param name="model">
///     The current state or model
/// </param>
/// <param name="dispatchMsg">
///     The function that allows the view to dispatch Messages
/// </param>
let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =
    StandardTemplate.template [
        Heading.h1 [] [ str "Privacy Policy" ]
        Content.content [] [
            ol [] [
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "We respect your privacy"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Gamurs Pty Ltd respects your right to privacy and is committed to
                              safeguarding the privacy of our customers and website visitors.
                              This policy sets out how we collect and treat your personal
                              information."
                        ]
                        li [] [ str
                            "We adhere to the Australian Privacy Principles contained in the
                              Privacy Act 1988 (Cth) and to the extent applicable, the EU General
                              Data Protection Regulation (GDPR)."
                        ]
                        li [] [ str
                            "\"Personal information\" is information we hold which is
                              identifiable as being about you. This includes information such as
                              your name, email address, identification number, or any other type
                              of information that can reasonably identify an individual, either
                              directly or indirectly."
                        ]
                        li [] [
                            str "You may contact us in writing at Level 2, 116 Queen Street,
                                  Beaconsfield, New South Wales, 2015 for further information about
                                  this Privacy Policy."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "What personal information is collected"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [
                            str "Gamurs Pty Ltd will, from time to time, receive and store personal
                                  information you submit to our website, provided to us directly or
                                  given to us in other forms."
                        ]
                        li [] [
                            str "You may provide basic information such as your name, phone number,
                                  address and email address to enable us to send you information,
                                  provide updates and process your product or service order."
                        ]
                        li [] [
                            str "We may collect additional information at other times, including but
                                  not limited to, when you provide feedback, when you provide
                                  information about your personal or business affairs, change your
                                  content or email preference, respond to surveys and/or promotions,
                                  provide financial or credit card information, or communicate with
                                  our customer support."
                        ]
                        li [] [
                            str "Additionally, we may also collect any other information you provide
                                  while interacting with us."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "How we collect your personal information"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [
                            str "Gamurs Pty Ltd collects personal information from you in a variety
                                  of ways, including when you interact with us electronically or in
                                  person, when you access our website and when we engage in business
                                  activities with you. We may receive personal information from third
                                  parties. If we do, we will protect it as set out in this Privacy
                                  Policy."
                        ]
                        li [] [
                            str "By providing us with personal information, you consent to the
                                  supply of that information subject to the terms of this Privacy
                                  Policy."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "How we use your personal information"
                    ]
                    p [] [
                        str "Gamurs Pty Ltd will use personal information only for the purposes that
                              you consent to. This may include to:"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [
                            str "provide you with products and services during the usual course of
                                  our business activities;"
                        ]
                        li [] [
                            str "administer our business activities;"
                        ]
                        li [] [
                            str "manage, research and develop our products and services;"
                        ]
                        li [] [
                            str "provide you with information about our products and services;"
                        ]
                        li [] [
                            str "communicate with you by a variety of measures including, but not
                                  limited to, by telephone, email, sms or mail; and"
                        ]
                        li [] [
                            str "investigate any complaints."
                        ]
                    ]
                    p [] [
                        str "If you withhold your personal information, it may not be possible for
                          us to provide you with our products and services or for you to fully
                          access our website."
                    ]
                    p [] [ str
                        "We may disclose your personal information to comply with a legal
                          requirement, such as a law, regulation, court order, subpoena, warrant,
                          legal proceedings or in response to a law enforcement agency request."
                    ]
                    p [] [ str
                        "If there is a change of control in our business or a sale or transfer
                          of business assets, we reserve the right to transfer to the extent
                          permissible at law our user databases, together with any personal
                          information and non-personal information contained in those databases."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "Disclosure of your personal information"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Gamurs Pty Ltd may disclose your personal information to any of our
                              employees, officers, insurers, professional advisers, agents,
                              suppliers or subcontractors insofar as reasonably necessary for the
                              purposes set out in this privacy policy."
                        ]
                        li [] [ str
                            "If we do disclose your personal information to a third party, we
                              will protect it in accordance with this privacy policy."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "General Data Protection Regulation (GDPR) for the European Union (EU)"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Gamurs Pty Ltd will comply with the principles of data protection
                              set out in the GDPR for the purpose of fairness, transparency and
                              lawful data collection and use."
                        ]
                        li [] [ str
                            "We process your personal information as a Processor and/or to the
                              extent that we are a Controller as defined in the GDPR."
                        ]
                        li [] [ str
                            "We must establish a lawful basis for processing your personal
                              information. The legal basis for which we collect your personal
                              information depends on the data that we collect and how we use it."
                        ]
                        li [] [ str
                            "We will only collect your personal information with your express
                              consent for a specific purpose and any data collected will be to
                              the extent necessary and not excessive for its purpose. We will
                              keep your data safe and secure."
                        ]
                        li [] [ str
                            "We will also process your personal information if it is necessary
                              for our legitimate interests, or to fulfil a contractual or legal
                              obligation."
                        ]
                        li [] [ str
                            "We process your personal information if it is necessary to protect
                              your life or in a medical situation, it is necessary to carry out a
                              public function, a task of public interest or if the function has a
                              clear basis in law."
                        ]
                        li [] [ str
                            "We do not collect or process any personal information from you that
                              is considered \"Sensitive Personal Information\" under the GDPR,
                              such as personal information relating to your sexual orientation or
                              ethnic origin unless we have obtained your explicit consent, or if
                              it is being collected subject to and in accordance with the GDPR."
                        ]
                        li [] [ str
                            "You must not provide us with your personal information if you are
                              under the age of 16 without the consent of your parent or someone
                              who has parental authority for you. We do not knowingly collect or
                              process the personal information of children."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                         "Your rights under the GDPR"
                    ]
                    p [] [ str
                        "If you are an individual residing in the EU, you have certain rights as
                          to how your personal information is obtained and used. Gamurs Pty Ltd
                          complies with your rights under the GDPR as to how your personal
                          information is used and controlled if you are an individual residing in
                          the EU."
                    ]
                    p [] [ str
                        "Except as otherwise provided in the GDPR, you have the following rights:"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "to be informed how your personal information is being used;"
                        ]
                        li [] [ str
                            "access your personal information (we will provide you with a free
                              copy of it);"
                        ]
                        li [] [ str
                            "to correct your personal information if it is inaccurate or
                              incomplete;"
                        ]
                        li [] [ str
                            "to delete your personal information (also known as \"the right to
                              be forgotten\");"
                        ]
                        li [] [ str
                            "to restrict processing of your personal information;"
                        ]
                        li [] [ str
                            "to retain and reuse your personal information for your own purposes;"
                        ]
                        li [] [ str
                            "to object to your personal information being used; and"
                        ]
                        li [] [ str
                            "to object against automated decision making and profiling."
                        ]
                    ]
                    p [] [ str
                        "Please contact us at any time to exercise your rights under the GDPR at
                          the contact details in this Privacy Policy."
                    ]
                    p [] [ str
                        "We may ask you to verify your identity before acting on any of your
                          requests."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Hosting and International Data Transfers"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Information that we collect may from time to time be stored,
                              processed in or transferred between parties or sites located in
                              countries outside of Australia. These may include, but are not
                              limited to Australia, United Kingdom, and USA."
                        ]
                        li [] [ str
                            "We and our other group companies have offices and/or facilities in
                              Australia and USA. Transfers to each of these countries will be
                              protected by appropriate safeguards, these include one or more of
                              the following: the use of standard data protection clauses adopted
                              or approved by the European Commission which you can obtain from
                              the European Commission Website; the use of binding corporate
                              rules, a copy of which you can obtain from Gamurs Pty Ltd's Data
                              Protection Officer."
                        ]
                        li [] [ str
                            "The hosting facilities for our website are situated in Australia
                              and USA. Transfers to each of these Countries will be protected by
                              appropriate safeguards, these include one or more of the following:
                              the use of standard data protection clauses adopted or approved by
                              the European Commission which you can obtain from the European
                              Commission Website; the use of binding corporate rules, a copy of
                              which you can obtain from Gamurs Pty Ltd's Data Protection Officer."
                        ]
                        li [] [ str
                            "Our Suppliers and Contractors are situated in Australia, United
                              Kingdom, and USA. Transfers to each of these Countries will be
                              protected by appropriate safeguards, these include one or more of
                              the following: the use of standard data protection clauses adopted
                              or approved by the European Commission which you can obtain from
                              the European Commission Website; the use of binding corporate
                              rules, a copy of which you can obtain from Gamurs Pty Ltd's Data
                              Protection Officer."
                        ]
                        li [] [ str
                            "You acknowledge that personal data that you submit for publication
                              through our website or services may be available, via the internet,
                              around the world. We cannot prevent the use (or misuse) of such
                              personal data by others."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Security of your personal information"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Gamurs Pty Ltd is committed to ensuring that the information you
                              provide to us is secure. In order to prevent unauthorised access or
                              disclosure, we have put in place suitable physical, electronic and
                              managerial procedures to safeguard and secure information and
                              protect it from misuse, interference, loss and unauthorised access,
                              modification and disclosure."
                        ]
                        li [] [ str
                            "Where we employ data processors to process personal information on
                              our behalf, we only do so on the basis that such data processors
                              comply with the requirements under the GDPR and that have adequate
                              technical measures in place to protect personal information against
                              unauthorised use, loss and theft."
                        ]
                        li [] [ str
                            "The transmission and exchange of information is carried out at your
                              own risk. We cannot guarantee the security of any information that
                              you transmit to us, or receive from us. Although we take measures
                              to safeguard against unauthorised disclosures of information, we
                              cannot assure you that personal information that we collect will
                              not be disclosed in a manner that is inconsistent with this Privacy
                              Policy."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Access to your personal information"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "You may request details of personal information that we hold about
                              you in accordance with the provisions of the Privacy Act 1988
                              (Cth), and to the extent applicable the EU GDPR. If you would like
                              a copy of the information which we hold about you or believe that
                              any information we hold on you is inaccurate, out of date,
                              incomplete, irrelevant or misleading, please email us at
                              support@statbanana.com"
                        ]
                        li [] [ str
                            "We reserve the right to refuse to provide you with information that
                              we hold about you, in certain circumstances set out in the Privacy
                              Act or any other applicable law."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Complaints about privacy"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "If you have any complaints about our privacy practices, please feel
                              free to send in details of your complaints to support@statbanana.com. We
                              take complaints very seriously and will respond shortly after
                              receiving written notice of your complaint."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Changes to Privacy Policy"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [ str
                            "Please be aware that we may change this Privacy Policy in the
                              future. We may modify this Policy at any time, in our sole
                              discretion and all modifications will be effective immediately
                              upon our posting of the modifications on our website or notice
                              board. Please check back from time to time to review our Privacy
                              Policy."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Website"
                    ]
                    Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "When you visit our website"
                            ]
                            p [] [ str
                                "When you come to our website (https://statbanana.com), we may collect
                                  certain information such as browser type, operating system, website
                                  visited immediately before coming to our site, etc. This
                                  information is used in an aggregated manner to analyse how people
                                  use our site, such that we can improve our service."
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Cookies"
                            ]
                            p [] [ str
                                "We may from time to time use cookies on our website. Cookies are
                                  very small files which a website uses to identify you when you come
                                  back to the site and to store details about your use of the site.
                                  Cookies are not malicious programs that access or damage your
                                  computer. Most web browsers automatically accept cookies but you
                                  can choose to reject cookies by changing your browser settings.
                                  However, this may prevent you from taking full advantage of our
                                  website. Our website may from time to time use cookies to analyses
                                  website traffic and help us provide a better website visitor
                                  experience. In addition, cookies may be used to serve relevant ads
                                  to website visitors through third party services such as Google
                                  AdWords. These ads may appear on this website or other websites you
                                  visit."
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                                str "Third party sites"
                            ]
                            p [] [ str
                                "Our site may from time to time have links to other websites not
                                  owned or controlled by us. These links are meant for your
                                  convenience only. Links to third party websites do not constitute
                                  sponsorship or endorsement or approval of these websites. Please be
                                  aware that Gamurs Pty Ltd is not responsible for the privacy
                                  practises of other such websites. We encourage our users to be
                                  aware, when they leave our website, to read the privacy statements
                                  of each and every website that collects personal identifiable
                                  information."
                            ]
                        ]
                    ]
                ]
            ]
            p [] [ str "Last Updated: 15th April 2019" ]
        ]
    ] (Some Route.Privacy) optionalUser onSignOut