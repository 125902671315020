/// Holds layers in the timeline
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.TimelineLayersPanel

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain.Strategiser

type Styles = { layers : string; toolbar : string; toolbarButton : string }

/// <summary>
///     Renders a list of layers
/// </summary>
///
/// <param name="selectedLayers">
///     A list of currently selected layers
/// </param>
///
/// <param name="timelineLayerBeingRenamed">
///     The timeline layer currently being renamed
/// </param>
///
/// <param name="onAddGroupClick">
///     The function to call when the "Add Group" button is clicked
/// </param>
///
/// <param name="onDeleteLayerClick">
///     The function to call when the "Delete Layer" button is clicked
/// </param>
///
/// <param name="onLayerClick">
///     The function to call when a layer is clicked
/// </param>
///
/// <param name="onLayerStopDrag">
///     The function to call when a layer is starting to be dragged
/// </param>
///
/// <param name="onLayerMoveBefore">
///     The function to call when a layer has been moved before another layer
/// </param>
///
/// <param name="onLayerMoveAfter">
///     The function to call when a layer has been moved after another layer
/// </param>
///
/// <param name="onLayerMoveToGroup">
///     The function to call when a layer has been moved to a group
/// </param>
///
/// <param name="onEnableLayerRenaming">
///     The function to call when a layer has renaming enabled
/// </param>
///
/// <param name="onLayerRename">
///     The function to call when a layer is renamed
/// </param>
///
/// <param name="onToggleLayerVisibility">
///     The function to call when a layer's visibility is toggled
/// </param>
///
/// <param name="onToggleLayerLocked">
///     The function to call when the layer's locked status is toggled
/// </param>
///
/// <param name="onToggleLayerGroupOpened">
///     The function to call when a layer group is opened or closed
/// </param>
///
/// <param name="layers">
///     A list of layers to render
/// </param>
let layersPanel
    (selectedLayers : Layer list)
    (timelineLayerBeingRenamed : Layer option)
    (onAddGroupClick : unit -> unit)
    (onDeleteClick : Layer list -> unit)
    (onLayerClick : Layer -> unit)
    (onLayerStopDrag : MouseEvent -> Layer -> unit)
    (onLayerMoveBefore : Layer -> string -> unit)
    (onLayerMoveAfter : Layer -> string -> unit)
    (onLayerMoveToGroup : Layer -> string -> unit)
    (onEnableLayerRenaming : Layer -> unit)
    (onLayerRename : string -> Layer -> unit)
    (onToggleLayerVisibility : Layer -> unit)
    (onToggleLayerLocked : Layer -> unit)
    (onToggleLayerGroupOpened : LayerGroup -> unit)
    (layers : Layer list) : ReactElement =
    let styles: Styles = importAll "./TimelineLayersPanel.sass"
    let onAddGroupClickHandler _ =
        onAddGroupClick ()
    let canDeleteLayers =
        let isLayerNotDeletable layer = not (Layer.canDelete layer)
        not (List.isEmpty selectedLayers)
     && not (List.exists isLayerNotDeletable selectedLayers)
    let onDeleteHandler _ =
        if canDeleteLayers then onDeleteClick selectedLayers
    let renderLayer layer =
        TimelineLayer.timelineLayer
            selectedLayers
            timelineLayerBeingRenamed
            onLayerClick
            onLayerStopDrag
            onLayerMoveBefore
            onLayerMoveAfter
            onLayerMoveToGroup
            onEnableLayerRenaming
            onLayerRename
            onToggleLayerVisibility
            onToggleLayerLocked
            onToggleLayerGroupOpened
            layer
    fragment [] [
        div [ ClassName styles.toolbar ] [
            div [ ClassName styles.toolbarButton
                  Disabled (not canDeleteLayers)
                  OnClick onDeleteHandler ] [
                Fa.i [ Fa.Solid.Trash ] []
            ]
            div [ ClassName styles.toolbarButton
                  OnClick onAddGroupClickHandler ] [
                Fa.i [ Fa.Solid.FolderPlus ] []
            ]
        ]
        div [ ClassName styles.layers ] [
            layers
            |> List.map renderLayer
            |> ofList
        ]
    ]