namespace StatBanana.Web.Client.Services.Firebase

open System

open Fable.Core.JsInterop
open Fable.PowerPack

open StatBanana.Domain
open StatBanana.Dto.Firestore.Games
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Dto.Firestore
open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Import.Firebase

module FirebaseSessionService =

    let private firebase : Firebase.IExports = importAll "firebase/app"

    let private sessionsCollectionName = "sessions"

    let private bindFirebaseError (error : Exception) =
        Fable.Import.JS.console.error (error.Message, error)
        error.Message
        |> Exception
        |> Promise.reject

    let private createSession
        (firebaseApp : Firebase.App.App)
        (currentUser : AuthenticatedUser)
        (game : Game)
        (name : string option)
        (save : Save) =

        let collection = firebaseApp.firestore().collection sessionsCollectionName
        let documentData = jsOptions<Firestore.DocumentData> <| fun data ->
            let now = firebase.firestore.Timestamp.now()
            data.Item "createdAt" <- now :> obj |> Some
            data.Item "deletedAt" <- "" :> obj |> Some
            data.Item "game" <- game |> GameDto.Game.fromDomain :> obj |> Some
            data.Item "lastSavedAt" <- now :> obj |> Some
            data.Item "latestSave" <-
                save |> Strategiser.SessionDto.Save.fromDomain |> Some
            data.Item "name" <-
                match name with
                | Some name -> name :> obj |> Some
                | None ->
                    let nowWithOffset =
                        now.seconds
                        |> int64
                        |> DateTimeOffset.FromUnixTimeSeconds
                    let formattedNow =
                        nowWithOffset.LocalDateTime
                        |> Date.Format.localFormat
                               Date.Local.englishUS
                               "yyyy-MM-dd hh:mm:ss"
                    ("Untitled Session - " + formattedNow) :> obj
                    |> Some
            data.Item "isPublic" <- false :> obj |> Some
            data.Item "userId" <- currentUser.id :> obj |> Some
        collection.add documentData
        |> Promise.map (fun docRef ->
            (SessionId docRef.id, documentData |> DocumentDeserialiser.Session.firestoreSession))
        |> Promise.catchBind bindFirebaseError

    let private deleteSession
        (firebaseApp : Firebase.App.App)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore()
                .collection("sessions")
                .doc(sessionId)
        let updateData = jsOptions<Firestore.UpdateData> <| fun data ->
            data.Item "deletedAt" <-
                firebase.firestore.Timestamp.now() :> obj |> Some
        doc.update updateData
        |> Promise.catchBind bindFirebaseError

    let private getSession
        (firebaseApp : Firebase.App.App)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore()
                .collection(sessionsCollectionName)
                .doc(sessionId)
        doc.get()
        |> Promise.map (fun doc ->
            doc.data()
            |> Option.map DocumentDeserialiser.Session.firestoreSession)
        |> Promise.catchBind bindFirebaseError

    let private getSessionsForUser (firebaseApp : Firebase.App.App) (userId : string) =
        firebaseApp.firestore()
            .collection(sessionsCollectionName)
            .where(!^"userId", Firestore.WhereFilterOp.EqualTo, userId :> obj |> Some)
            .where(!^"deletedAt", Firestore.WhereFilterOp.EqualTo, "" :> obj |> Some)
            .orderBy(!^"lastSavedAt", Firestore.OrderByDirection.Desc)
            .get()
        |> Promise.map (fun querySnapshot ->
            querySnapshot.docs
            |> Seq.toList
            |> List.map (fun docSnapshot ->
                let deserialisedData =
                    docSnapshot.data()
                    |> DocumentDeserialiser.Session.firestoreSession
                (SessionId docSnapshot.id, deserialisedData)))
        |> Promise.catchBind bindFirebaseError

    let private makeSessionPrivate
        (firebaseApp : Firebase.App.App)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore().collection("sessions").doc(sessionId)
        let updateData = jsOptions<Firestore.UpdateData> <| fun data ->
            data.Item "isPublic" <- false :> obj |> Some
        doc.update updateData
        |> Promise.map (fun _ -> SessionId sessionId)
        |> Promise.catchBind bindFirebaseError

    let private makeSessionPublic
        (firebaseApp : Firebase.App.App)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore().collection("sessions").doc(sessionId)
        let updateData = jsOptions<Firestore.UpdateData> <| fun data ->
            data.Item "isPublic" <- true :> obj |> Some
        doc.update updateData
        |> Promise.map (fun _ -> SessionId sessionId)
        |> Promise.catchBind bindFirebaseError

    let private renameSession
        (firebaseApp : Firebase.App.App)
        (name : string)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore().collection("sessions").doc(sessionId)
        let updateData = jsOptions<Firestore.UpdateData> <| fun data ->
            data.Item "name" <- name :> obj |> Some
        doc.update updateData
        |> Promise.map (fun _ -> (SessionId sessionId, name))
        |> Promise.catchBind bindFirebaseError

    let private saveToSession
        (firebaseApp : Firebase.App.App)
        (save : Save)
        (SessionId sessionId) =

        let doc =
            firebaseApp.firestore().collection("sessions").doc(sessionId)
        let updateData = jsOptions<Firestore.UpdateData> <| fun data ->
            data.Item "latestSave" <-
                save |> Strategiser.SessionDto.Save.fromDomain |> Some
            data.Item "lastSavedAt" <-
                firebase.firestore.Timestamp.now() :> obj |> Some
        doc.update updateData
        |> Promise.map (fun _ ->
            (SessionId sessionId, firebase.firestore.Timestamp.now().seconds))
        |> Promise.catchBind bindFirebaseError

    /// <summary>
    ///     The service implementation.
    /// </summary>
    ///
    /// <param name="app">
    ///     Initialised Firebase app.
    /// </param>
    let initialise (app : Firebase.App.App) : SessionStoreService =
        { createSession = createSession app
          deleteSession = deleteSession app
          getSession = getSession app
          getSessionsForUser = getSessionsForUser app
          makeSessionPrivate = makeSessionPrivate app
          makeSessionPublic = makeSessionPublic app
          renameSession = renameSession app
          saveToSession = saveToSession app }