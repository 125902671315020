module StatBanana.Web.Client.Components.Organisms.MapViewerWelcomeModal

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Domain

/// <summary>
///     Renders a modal that welcomes users to the map viewer.
/// </summary>
///
/// <param name="isOpened.">
///     Whether the modal is opened.
/// </param>
///
/// <param name="onClose">
///     The function to call when the modal is closed.
/// </param>
let modal (game : Game) (isOpened : bool) (onClose : MouseEvent -> unit) =
    Modal.modal [ Modal.IsActive isOpened
                  Modal.Props [ Id "DemoWelcomeModal" ] ] [
        Modal.background [ Props [ OnClick onClose ] ] []
        Modal.content [] [
            Box.box' [] [
                Heading.h2 [ Heading.Modifiers
                                [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                    sprintf "StatBanana %s Map Viewer" (game |> Game.getName)
                    |> str
                ]
                Content.content [] [
                    p [] [
                        str "You can pan around our hand stiched high resolution map, draw, and measure to your heart's content!"
                    ]
                    p [] [
                        str "If you want to "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "create your own strategies"
                        ]
                        str " using this high resolution map with "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "placeable hero icons"
                        ]
                        str ", access to "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "powerful animation tools"
                        ]
                        str ", and the ability to "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "save your sessions"
                        ]
                        str " and "
                        Text.span [ GenericOption.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                            str "host collaborative sessions"
                        ]
                        str ", try out the StatBanana Strategiser!"
                    ]
                ]
                Button.list [] [
                    Button.a [ Button.Color Color.IsWarning
                               Button.IsFullWidth
                               Button.Props [ Href (Route.Demo game |> Route.getPath) ] ] [
                        str "Try Creating A Strategy"
                    ]
                    Button.button [ Button.IsFullWidth
                                    Button.IsOutlined
                                    Button.OnClick onClose ] [
                        str "Continue to Map Viewer"
                    ]
                ]
            ]
        ]
        Modal.close [ Modal.Close.Size IsLarge
                      Modal.Close.OnClick onClose ] []
    ]