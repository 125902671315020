namespace StatBanana.Domain

open System

type SubscriptionStatus =
    | [<CompiledName "active">] Active
    | [<CompiledName "awaiting_confirmation">] AwaitingConfirmation
    | [<CompiledName "cancelled">] Cancelled
    | [<CompiledName "expired">] Expired
    | [<CompiledName "past_due">] PastDue
    | [<CompiledName "switching_plan">] SwitchingPlan
    | [<CompiledName "trial_period">] TrialPeriod

type StripeSubscriptionId = StripeSubscriptionId of string

type PayPalSubscriptionId = PayPalSubscriptionId of string

type ExternalSubscriptionId =
    | Stripe of StripeSubscriptionId
    | PayPal of PayPalSubscriptionId
    | Trial

/// A change made to a user's subscription to the service.
type SubscriptionChange =
    { plan : Changeable<Plan>
      renewAs : Changeable<Plan option>
      started : Changeable<DateTimeOffset>
      expires : Changeable<DateTimeOffset>
      externalId : ExternalSubscriptionId
      status : Changeable<SubscriptionStatus> }

/// A user's subscription to the service.
type Subscription =
    { plan : Plan
      renewAs : Plan option
      started : DateTimeOffset
      expires : DateTimeOffset
      externalId : ExternalSubscriptionId
      status : SubscriptionStatus }
module Subscription =
    /// Gets the current plan from the subscription.
    let asCurrentPlan (subscription : Subscription) : Plan =
        subscription.plan

    /// Gets the upcoming plan from the subscription.
    let asUpcomingPlan (subscription : Subscription) : Plan option =
        subscription.renewAs

/// A set of subscriptions.
type SubscriptionSet =
    { dota2 : Subscription option }
module SubscriptionSet =
    /// Gets the default, empty subscription set.
    let getDefault () =
        { dota2 = None }

    /// Gets the subscription for a particular game from the set, if there is one.
    let getSubscription (game : Game) (set : SubscriptionSet) : Subscription option =
        match game with
        | Game.Dota2 -> set.dota2

    /// Lists all of the subscriptions in the set.
    let getSubscriptions (set : SubscriptionSet) =
        seq {
            for game in Game.all do
                let Subscription = set |> getSubscription game
                if Subscription.IsSome then
                    yield game, Subscription.Value
        }

    /// Converts a subscription set into a set of plans, where each plan is a
    /// current plan in a subscription.
    let asCurrentPlanSet (set : SubscriptionSet) =
        { PlanSet.dota2 = set.dota2 |> Option.map Subscription.asCurrentPlan }

    /// Converts a subscription set into a set of plans, where each plan is
    /// an upcoming plan in a subscription.
    let asUpcomingPlanSet (set : SubscriptionSet) =
        { PlanSet.dota2 = set.dota2 |> Option.bind Subscription.asUpcomingPlan }
