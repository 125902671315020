namespace StatBanana.Web.Client.Domain.Strategiser

open System

/// A type that represents the ID for a Layer.
type LayerId = private LayerId of Guid
module LayerId =
    /// <summary>
    ///     Creates a LayerId from a Guid.
    /// </summary>
    ///
    /// <param name="guid">
    ///     The Guid to create a LayerId from.
    /// </param>
    let ofGuid (guid : Guid) : LayerId =
        LayerId guid

    /// <summary>
    ///     Returns a string representation of a LayerId.
    /// </summary>
    ///
    /// <param name="id">
    ///     The LayerId to create a string for.
    /// </param>
    let toString (id : LayerId) : string =
        let (LayerId guid) = id
        guid.ToString ()

/// The fundamental Layer type which is mapped to an Item.
type LayerUnit =
    { id : LayerId
      item : Item
      keyframes : Keyframes
      locked : bool
      name : string
      /// Indicates that it can or cannot be modified in anyway.
      perpetual : bool
      selected : bool
      visible : bool }
module LayerUnit =
    /// <summary>
    ///     Retrieves the id of a given LayerUnit.
    /// </summary>
    ///
    /// <param name="unit">
    ///     The LayerUnit to retreive the id for.
    /// </param>
    let getId (unit : LayerUnit) : LayerId =
        unit.id

    /// <summary>
    ///     Makes a given LayerUnit perpetual (can't be deleted or edited).
    /// </summary>
    ///
    /// <param name="layer">
    ///     The LayerUnit to perpertualise.
    /// </param>
    let perpetualise (unit : LayerUnit) : LayerUnit =
        { unit with perpetual = true }

/// A Layer type that can hold a collection of unit Layers to form a group.
type LayerGroup =
    { children : LayerUnit list
      id : LayerId
      locked : bool
      name : string
      opened : bool
      /// Indicates that it can or cannot be modified in anyway.
      perpetual : bool
      visible : bool }
module LayerGroup =
    /// <summary>
    ///     Retrieves the children of a given LayerGroup.
    /// </summary>
    ///
    /// <param name="unit">
    ///     The LayerUnit to retreive the id for.
    /// </param>
    let getChildren (group : LayerGroup) : LayerUnit list =
        group.children

    /// <summary>
    ///     Sets the given LayerGroup's opened status.
    /// </summary>
    ///
    /// <param name="opened">
    ///     Whether the Group is opened or closed.
    /// </param>
    ///
    /// <param name="group">
    ///     The LayerGroup to retreive the MapItems for.
    /// </param>
    let setOpened
        (opened : bool)
        (group : LayerGroup) : LayerGroup =
        { group with opened = opened }

    /// <summary>
    ///     Makes a given LayerGroup perpetual (can't be deleted or edited).
    /// </summary>
    ///
    /// <param name="layer">
    ///     The LayerGroup to perpertualise.
    /// </param>
    let perpetualise (group : LayerGroup) : LayerGroup =
        { group with perpetual = true }

/// The core Strategiser type that links Items, Keyframes and inherently Leaflet Markers.
type Layer =
    | LayerUnit of LayerUnit
    | LayerGroup of LayerGroup
module Layer =
    /// <summary>
    ///     Retrieves the visibilty of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to retreive the visibility for.
    /// </param>
    let isVisible (layer : Layer) : bool =
        match layer with
        | LayerGroup group -> group.visible
        | LayerUnit unit -> unit.visible

    /// <summary>
    ///     Updates the visibilty of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to update the visibility for.
    /// </param>
    let setVisibility
        (visible : bool)
        (layer : Layer) : Layer =
        match layer with
        | LayerGroup group ->
            LayerGroup { group with visible = visible }
        | LayerUnit unit ->
            LayerUnit { unit with visible = visible }

    /// <summary>
    ///     Retrieves the id of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to retreive the id for.
    /// </param>
    let getId (layer : Layer) : LayerId =
        match layer with
        | LayerGroup group -> group.id
        | LayerUnit unit -> unit.id

    /// <summary>
    ///     Retrieves the name of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to retreive the name for.
    /// </param>
    let getName (layer : Layer) : string =
        match layer with
        | LayerGroup group -> group.name
        | LayerUnit unit -> unit.name

    /// <summary>
    ///     Updates the name of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to update the name for.
    /// </param>
    let setName (name : string) (layer : Layer) : Layer =
        match layer with
        | LayerGroup group ->
            LayerGroup { group with name = name }
        | LayerUnit unit ->
            LayerUnit { unit with name = name }

    /// <summary>
    ///     Ensures all Layers sit on the same level, i.e. children of groups become siblings of their group.
    /// </summary>
    ///
    /// <param name="layers">
    ///     The list of Layers to flatten.
    /// </param>
    let flattenList
        (layers : Layer list) : Layer list =
        let getUnits layer =
            match layer with
            | LayerGroup group ->
                LayerGroup group
             :: List.map LayerUnit group.children
            | LayerUnit unit ->
                [LayerUnit unit]
        layers
        |> List.map getUnits
        |> List.concat

    /// <summary>
    ///     Gets perpetuality of a given Layer.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to get the perpetuality for.
    /// </param>
    let getPerpetuality (layer : Layer) : bool =
        match layer with
        | LayerGroup group ->
            group.perpetual
        | LayerUnit unit ->
            unit.perpetual

    /// <summary>
    ///     Makes a given Layer perpetual (can't be deleted or edited).
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to perpertualise.
    /// </param>
    let perpetualise (layer : Layer) : Layer =
        match layer with
        | LayerGroup group ->
            group |> LayerGroup.perpetualise |> LayerGroup
        | LayerUnit unit ->
            unit |> LayerUnit.perpetualise |> LayerUnit

    /// <summary>
    ///     Toggles a given Layer locked status.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to toggle locked status.
    /// </param>
    let toggleLocked (layer : Layer) : Layer =
        match layer with
        | LayerGroup group ->
            let updateChild (child : LayerUnit) =
                { child with locked = not child.locked }
            let updatedChildren =
                List.map updateChild group.children
            LayerGroup
                { group with
                    children = updatedChildren
                    locked = not group.locked }
        | LayerUnit unit ->
            LayerUnit { unit with locked = not unit.locked }

    /// <summary>
    ///     Returns if a given Layer can be deleted.
    /// </summary>
    ///
    /// <param name="layer">
    ///     The Layer to check if deletion can occur.
    /// </param>
    let canDelete (layer : Layer) : bool =
        match layer with
        | LayerGroup group ->
            not group.perpetual && not group.locked
        | LayerUnit unit ->
            not unit.perpetual && not unit.locked