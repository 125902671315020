module StatBanana.Web.Client.App

open Elmish
open Elmish.React
open Elmish.Browser.Navigation
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.Browser

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Pages

/// Defines the initial state and initial command (= side-effect) of the application
let private init (app : AppConfig) page : Model * Cmd<Msg> =
    let model, cmd = LandingPage.init app None
    let initialModel = { DeploymentEnvironment = app.deploymentEnvironment
                         PageModel = LandingPageModel model
                         Router = Router.init ()
                         User = AuthServiceNotInitialised }

    Router.urlUpdate app page initialModel

/// Lift a Page-Msg Cmd and a GlobalMsg-Cmd to applicaliation-Msg-Cmds, and combine the lifted Cmds
/// into a single batch command.
let private liftCmds
    (liftPageMsg : 'p -> Msg)
    (pageCmd : Cmd<'p>)
    (globalCmd : Cmd<GlobalMsg>)
    : Cmd<Msg> =

    Cmd.batch
        [ Cmd.map liftPageMsg pageCmd
          Cmd.map GlobalMsg globalCmd ]

/// Computes the next state of the application based on the current state and the incoming events/messages.
/// It can also run side-effects (encoded as commands), such as calling the server via HTTP.
/// These commands in turn, can dispatch messages to which the update function will react.
let private update
    (app : AppConfig)
    (msg : Msg)
    (model : Model)
    : Model * Cmd<Msg> =

    let optionalUser = CurrentUser.toAuthenticatedUserOption model.User
    match msg, model.PageModel, optionalUser with
    | AuthMsg authMsg, _, _ ->
        AuthMsgHandler.update app authMsg model
    | GlobalMsg globalMsg, _, _ ->
        GlobalMsgHandler.update app globalMsg model
    | RouteInitialised, _, _ ->
        model, Cmd.none

    | ArticlePageMsg msg, ArticlePageModel pageModel, _ ->
        let newPageModel, cmd = ArticlePage.update app msg pageModel
        { model with PageModel = ArticlePageModel newPageModel },
        Cmd.map ArticlePageMsg cmd
    | AuthPageMsg msg, AuthPageModel pageModel, _ ->
        let newPageModel, cmd = AuthPage.update app msg pageModel
        { model with PageModel = AuthPageModel newPageModel },
        Cmd.map AuthPageMsg cmd
    | CheckoutPageMsg msg, CheckoutPageModel pageModel, _ ->
        let newPageModel, pageCmd, globalCmd = CheckoutPage.update app msg pageModel
        { model with PageModel = CheckoutPageModel newPageModel },
        liftCmds CheckoutPageMsg pageCmd globalCmd
    | ClientErrorPageMsg msg, ClientErrorPageModel pageModel, _ ->
        let newPageModel, cmd = ClientErrorPage.update app msg pageModel
        { model with PageModel = ClientErrorPageModel newPageModel },
        Cmd.map ClientErrorPageMsg cmd
    | ImagesPageMsg msg, ImagesPageModel pageModel, _ ->
        let newPageModel, cmd = ImagesPage.update msg pageModel
        { model with PageModel = ImagesPageModel newPageModel },
        Cmd.map ImagesPageMsg cmd
    | LandingPageMsg msg, LandingPageModel pageModel, _ ->
        let newPageModel, cmd = LandingPage.update app msg pageModel
        { model with PageModel = LandingPageModel newPageModel },
        Cmd.map LandingPageMsg cmd
    | NewSubscriptionSuccessPageMsg msg, NewSubscriptionSuccessPageModel pageModel, Some user ->
        let newPageModel, cmd = NewSubscriptionSuccessPage.update app user msg pageModel
        { model with PageModel = NewSubscriptionSuccessPageModel newPageModel },
        Cmd.map NewSubscriptionSuccessPageMsg cmd
    | NotFoundPageMsg msg, NotFoundPageModel pageModel, _ ->
        let newPageModel, cmd = NotFoundPage.update app msg pageModel
        { model with PageModel = NotFoundPageModel newPageModel },
        Cmd.map NotFoundPageMsg cmd
    | PricingPageMsg msg, PricingPageModel pageModel, _ ->
        let newPageModel, cmd = PricingPage.update app msg pageModel
        { model with PageModel = PricingPageModel newPageModel },
        Cmd.map PricingPageMsg cmd
    | PrivacyPageMsg msg, PrivacyPageModel pageModel, _ ->
        let newPageModel, cmd = PrivacyPage.update app msg pageModel
        { model with PageModel = PrivacyPageModel newPageModel },
        Cmd.map PrivacyPageMsg cmd
    | StrategiserPageMsg msg, StrategiserPageModel pageModel, _ ->
        let newPageModel, pageCmd, globalCmd = StrategiserPage.update app optionalUser msg pageModel
        { model with PageModel = StrategiserPageModel newPageModel },
        liftCmds StrategiserPageMsg pageCmd globalCmd
    | TermsPageMsg msg, TermsPageModel pageModel, _ ->
        let newPageModel, cmd = TermsPage.update app msg pageModel
        { model with PageModel = TermsPageModel newPageModel },
        Cmd.map TermsPageMsg cmd
    | UserSettingsPageMsg msg, UserSettingsPageModel pageModel, _ ->
        let newPageModel, cmd =
            UserSettingsPage.update
                app
                msg
                pageModel
        { model with PageModel = UserSettingsPageModel newPageModel },
        Cmd.map UserSettingsPageMsg cmd

    | ArticlePageMsg _, _, _
    | AuthPageMsg _, _, _
    | CheckoutPageMsg _, _, _
    | ClientErrorPageMsg _, _, _
    | ImagesPageMsg _, _, _
    | LandingPageMsg _, _, _
    | LoadingPageMsg _, _, _
    | NewSubscriptionSuccessPageMsg _, _, _
    | NotFoundPageMsg _, _, _
    | PricingPageMsg _, _, _
    | PrivacyPageMsg _, _, _
    | StrategiserPageMsg _, _, _
    | TermsPageMsg _, _, _
    | UserSettingsPageMsg _, _, _ ->
        console.log "Ignoring message from unmounted page" |> ignore
        model, Cmd.none

//
// VIEW
//

/// Render the appropriate view, based on the current PageModel
let view (app : AppConfig) model dispatch =
    let onSignOut _ =
        UserRequestedSignOut
        |> Msg.AuthMsg
        |> dispatch

    div [ Key "statbanana-application" ] [
        let optionalUser = CurrentUser.toAuthenticatedUserOption model.User
        let unauthedStrategiserCondition (model : StrategiserPage.Model) =
            model.CurrentMode |> Mode.isDemoMode // demo
            || model.CurrentMode |> Mode.isViewerMode && model.Session.IsNone // map viewer
        match model.PageModel, optionalUser with
        // Unauthenticated pages
        | ArticlePageModel model, _ ->
            yield ArticlePage.view
                optionalUser
                model
                (ArticlePageMsg >> dispatch)
                onSignOut
        | AuthPageModel model, _ ->
            yield AuthPage.view
                app
                model
                (AuthPageMsg >> dispatch)
                onSignOut
        | ClientErrorPageModel _, _ ->
            yield ClientErrorPage.view
                model.DeploymentEnvironment
                optionalUser
                onSignOut
        | ImagesPageModel model, _ ->
            yield ImagesPage.view
                optionalUser
                model
                (ImagesPageMsg >> dispatch)
                onSignOut
        | LandingPageModel model, _ ->
            yield LandingPage.view
                optionalUser
                model
                (LandingPageMsg >> dispatch)
                onSignOut
        | LoadingPageModel _, _ ->
            yield LoadingPage.view ()
        | NotFoundPageModel _, _ ->
            yield NotFoundPage.view ()
        | PrivacyPageModel model, _ ->
            yield PrivacyPage.view
                optionalUser
                model
                (PrivacyPageMsg >> dispatch)
                onSignOut
        | StrategiserPageModel model, _ when unauthedStrategiserCondition model ->
            yield StrategiserPage.view
                app
                optionalUser
                onSignOut
                model
                (StrategiserPageMsg >> dispatch)
        | TermsPageModel model, _ ->
            yield TermsPage.view
                optionalUser
                model
                (TermsPageMsg >> dispatch)
                onSignOut

        // Authenticated pages
        | CheckoutPageModel model, Some user ->
            yield CheckoutPage.view
                app
                user
                model
                (CheckoutPageMsg >> dispatch)
                onSignOut
        | NewSubscriptionSuccessPageModel model, Some user ->
            yield NewSubscriptionSuccessPage.view
                app
                user
                model
                (NewSubscriptionSuccessPageMsg >> dispatch)
                onSignOut
        | PricingPageModel model, _ ->
            yield PricingPage.view
                optionalUser
                model
                (PricingPageMsg >> dispatch)
                onSignOut
        | StrategiserPageModel model, Some _ ->
            yield StrategiserPage.view
                app
                optionalUser
                onSignOut
                model
                (StrategiserPageMsg >> dispatch)
        | UserSettingsPageModel model, Some user ->
            yield UserSettingsPage.view
                user
                onSignOut
                model
                (UserSettingsPageMsg >> dispatch)

        //
        // Transitional State - attempting to render an authenticated page, but we are no longer
        // authenticated.
        //
        // This state indicates that we are either waiting on a "redirect to login" command
        // triggered by a UserLoggedOut message to be executed, or that the user navigated to a
        // page requiring auth using the browser back button but isn't authenticated.  In the
        // second case, the Router should trigger a redirect to login.
        | _, None ->
            Fable.Import.JS.console.warn("Authentication required, you should be redirected shortly")
            yield LoadingPage.view ()
    ]

//
// RUN
//

/// <summary>
///     Create a subscription to dispatch messages on auth state changes.
/// </summary>
///
/// <param name="authService">
///     The auth service that we will register auth change callbacks with.
/// </param>
///
/// <param name="initial">
///     The initial app model.
/// </param>
let private subscribeToAuthStateChanges
    (firebaseAuthService : AuthService)
    (initial : Model)
    : Cmd<Msg> =

    let sub dispatch =
        // Callbacks for when the current auth state changes
        let signedInCallback : AuthSignedInCallback =
            fun result ->
                match result with
                | Ok authUser ->
                    authUser
                    |> UserSignedIn
                    |> Msg.AuthMsg
                    |> dispatch
                | Error errorMsg ->
                    // Clear the invalid auth token, so the page isn't stuck in a broken state
                    Fable.Import.JS.console.error errorMsg
                    Fable.Import.JS.console.warn "Clearing invalid auth token"
                    // Dispatch a message to clear the auth token for the user
                    UserRequestedSignOut
                    |> Msg.AuthMsg
                    |> dispatch
        let signedOutCallback : AuthSignedOutCallback =
            fun () ->
                UserSignedOut
                |> Msg.AuthMsg
                |> dispatch

        // Register callbacks with the AuthService
        firebaseAuthService.onStateChanged signedInCallback signedOutCallback

    Cmd.ofSub sub

let subscriptions model =
    Cmd.batch
        [ Cmd.map StrategiserPageMsg (StrategiserPage.subscribe ()) ]

// Attach init, update and URL handling functions to the application configuration type.
type AppConfig with
    member this.init = init this
    member this.update = update this
    member this.view = view this
    member this.urlParser = Router.urlParser
    member this.urlUpdate = Router.urlUpdate this

// Note: Webpack autmoatically sets the DEBUG directive when calling fable-compiler for dev-server builds.
#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

let start (app : AppConfig) : unit =
    Program.mkProgram app.init app.update app.view
    |> Program.withSubscription subscriptions
    |> Program.withSubscription (subscribeToAuthStateChanges app.authService)
    |> Program.toNavigable app.urlParser app.urlUpdate

    #if DEBUG
//    |> Program.withConsoleTrace
    |> Program.withHMR
    #endif

    |> Program.withReact "app"

    #if DEBUG
//    |> Program.withDebugger
    #endif

    |> Program.run
