/// Renders available keyboard shortcuts
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.KeyboardShortcutsHelp

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open StatBanana.Web.Client.Components.Atoms

type Styles =
    { section : string
      sectionHeader : string
      sectionHint : string
      sectionTitle : string
      shortcut : string
      shortcutRow : string
      stackedShortcuts : string }

/// <summary>
///     Renders a component that shows available keyboard shortcuts
/// </summary>
let help =
    let styles : Styles = importAll "./KeyboardShortcutsHelp.sass"
    let userAgent = Browser.navigator.userAgent
    let cmdOrCtrl =
        if userAgent.Contains "Mac" then "⌘"
        else "Ctrl"
    div [] [
        section [ ClassName styles.section ] [
            div [ ClassName styles.sectionHeader ] [
                h2 [ ClassName styles.sectionTitle ] [
                    str "Editing"
                ]
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Save Current Session" ]
                span [] [
                    MonospaceBlock.block (str cmdOrCtrl)
                    str " + "
                    MonospaceBlock.block (str "s")
                ]
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Undo" ]
                span [] [
                    MonospaceBlock.block (str cmdOrCtrl)
                    str " + "
                    MonospaceBlock.block (str "z")
                ]
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Redo" ]
                span [ ClassName styles.stackedShortcuts ] [
                    span [] [
                        MonospaceBlock.block (str (cmdOrCtrl))
                        str " + "
                        MonospaceBlock.block (str "y")
                    ]
                    span [] [
                        MonospaceBlock.block (str (cmdOrCtrl))
                        str " + "
                        MonospaceBlock.block (str "Shift")
                        str " + "
                        MonospaceBlock.block (str "z")
                    ]
                ]
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Multi select layers" ]
                span [ ClassName styles.stackedShortcuts ] [
                    span [] [
                        MonospaceBlock.block (str (cmdOrCtrl))
                        str " + "
                        MonospaceBlock.block (str "Click")
                    ]
                    span [] [
                        MonospaceBlock.block (str "Shift")
                        str " + "
                        MonospaceBlock.block (str "Click")
                    ]
                ]
            ]
        ]
        section [ ClassName styles.section ] [
            div [ ClassName styles.sectionHeader ] [
                h2 [ ClassName styles.sectionTitle ] [
                    str "Keyframe management"
                ]
                small [ ClassName styles.sectionHint ] [
                    str "(When a keyframe is selected)"
                ]
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Go to next keyframe" ]
                MonospaceBlock.block (str "k")
            ]
            div [ ClassName styles.shortcutRow ] [
                span [] [ str "Go to previous keyframe" ]
                MonospaceBlock.block (str "j")
            ]
            div [ ClassName styles.shortcutRow  ] [
                span [] [ str "Delete keyframe" ]
                span [] [
                    MonospaceBlock.block (str "Delete")
                    str " or "
                    MonospaceBlock.block (str "Backspace")
                ]
            ]
        ]
    ]