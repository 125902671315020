/// Render a subscription status tag.
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.SubscriptionStatusTag

open Fable.Helpers.React

open Fulma

/// Renders a tag that indicates user has an active trial subscription.
let trial () =
    Tag.tag [ Tag.Color IsWarning
              Tag.Size Size.IsMedium ] [
        str "Premium (trial)"
    ]

/// Renders a tag that indicates user has a current active subscription.
let subscribed () =
    Tag.tag [ Tag.Color IsSuccess
              Tag.Size Size.IsMedium ] [
        str "Premium"
    ]

/// Renders a tag that indicates user has a current cancelled subscription.
let cancelled () =
    Tag.tag [ Tag.Color IsDark
              Tag.Size Size.IsMedium ] [
        str "Premium (cancelled)"
    ]

/// Renders a tag that indicates user has no current subscription.
let free () =
    Tag.tag [ Tag.Color IsGrey
              Tag.Size Size.IsMedium ] [
        str "Free"
    ]