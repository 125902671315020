module StatBanana.Web.Client.Components.Organisms.DemoWelcomeModal

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain

/// <summary>
///     Renders a modal that welcomes users to the demo.
/// </summary>
///
/// <param name="isOpened.">
///     Whether the modal is opened.
/// </param>
///
/// <param name="onClose">
///     The function to call when the modal is closed.
/// </param>
let modal (game : Game) (isOpened : bool) (onClose : MouseEvent -> unit) =
    Modal.modal [ Modal.IsActive isOpened
                  Modal.Props [ Id "DemoWelcomeModal" ] ] [
        Modal.background [ Props [ OnClick onClose ] ] []
        Modal.content [] [
            Box.box' [] [
                Heading.h2 [ Heading.Modifiers
                                [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                    sprintf "StatBanana Demo for %s" (game |> Game.getName)
                    |> str
                ]
                Content.content [] [
                    p [] [
                        str "You are currently viewing the demo mode.
                            You have access to the full application however the following limits apply:"
                    ]
                    ul [] [
                        li [] [ str "You can only use it for 5 minutes at a time." ]
                        li [] [ str "You cannot save your strategy." ]
                        li [] [ str "You cannot host collaborative sessions." ]
                    ]
                ]
                Button.button [ Button.Color Color.IsWarning
                                Button.IsFullWidth
                                Button.OnClick onClose ] [
                    str "Get Started"
                ]
            ]
        ]
        Modal.close [ Modal.Close.Size IsLarge
                      Modal.Close.OnClick onClose ] []
    ]