/// A full timeline used to control map elements
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Organisms.Timeline

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Extensions
open StatBanana.Web.Client.Import

type Styles =
    { closed : string
      container : string
      content : string
      field : string
      lanes : string
      layers : string
      opened : string
      ruler : string }

type Model =
    { CurrentPlaybackState : PlaybackState
      CurrentTime : int
      MarksPerSecond : int
      SelectedKeyframe : Selectable<LayerUnit * int>
      SelectedTimelineLayers : Layer list
      TimelineDuration : int
      TimelineLayerBeingRenamed : Layer option
      TimelineLayers : Layer list
      TimelineOpened : bool
      TimelineZoomLevel : TimelineZoomLevel }

/// <summary>
///     Renders a timeline UI component for controlling animations.
/// </summary>
///
/// <param name="model">
///     A record containing information required to render the control panel:
///     current playback state, the current time, the number of marks per
///     second, the duration of the timeline, and the current zoom level
/// </param>
///
/// <param name="onPause">
///     The function to call when the user presses pause
/// </param>
///
/// <param name="onSkip">
///     The function to call when the user skips along time
/// </param>
///
/// <param name="onAddLayerGroupClick">
///     The function to call when the "Add Group" button is clicked
/// </param>
///
/// <param name="onDeleteLayersClick">
///     The function to call when the "Delete Layer" button is clicked
/// </param>
///
/// <param name="onLayerClick">
///     The function to call when a layer is clicked
/// </param>
///
/// <param name="onLayerStopDrag">
///     The function to call when a layer is starting to be dragged
/// </param>
///
/// <param name="onLayerMoveBefore">
///     The function to call when a layer has been moved before another layer
/// </param>
///
/// <param name="onLayerMoveAfter">
///     The function to call when a layer has been moved after another layer
/// </param>
///
/// <param name="onLayerMoveToGroup">
///     The function to call when a layer has been moved to a group
/// </param>
///
/// <param name="onEnableLayerRenaming">
///     The function to call when a layer has renaming enabled
/// </param>
///
/// <param name="onLayerRename">
///     The function to call when a layer is renamed
/// </param>
///
/// <param name="onToggleLayerVisibility">
///     The function to call when a layer's visibility is toggled
/// </param>
///
/// <param name="onToggleLayerLocked">
///     The function to call when a layer's locked status is toggled
/// </param>
///
/// <param name="onToggleLayerGroupOpened">
///     The function to call when a layer group is opened or closed
/// </param>
///
/// <param name="onKeyframeClick">
///     The function to call when a keyframe is clicked
/// </param>
///
/// <param name="onKeyframeMove">
///     The function to call when a keyframe is moved
/// </param>
let timeline
    (model : Model)
    (onFieldClick : MouseEvent -> unit)
    (onPause : MouseEvent -> unit)
    (onSkip : int -> unit)
    (onAddLayerGroupClick : unit -> unit)
    (onDeleteLayersClick : Layer list -> unit)
    (onLayerClick : Layer -> unit)
    (onLayerStopDrag : MouseEvent -> Layer -> unit)
    (onLayerMoveBefore : Layer -> string -> unit)
    (onLayerMoveAfter : Layer -> string -> unit)
    (onLayerMoveToGroup : Layer -> string -> unit)
    (onEnableLayerRenaming : Layer -> unit)
    (onLayerRename : string -> Layer -> unit)
    (onToggleLayerVisibility : Layer -> unit)
    (onToggleLayerLocked : Layer -> unit)
    (onToggleLayerGroupOpened : LayerGroup -> unit)
    (onKeyframeClick : LayerUnit * int-> unit)
    (onKeyframeMove : LayerUnit * int -> unit)
    (onKeyframeMoved : LayerUnit * int -> int -> unit)
    : ReactElement =

    let mutable rulerRef : Creatable<Browser.Element> = NotCreated
    let mutable playheadRef : Creatable<Browser.Element> = NotCreated
    let styles: Styles = importAll "./Timeline.sass"
    let rulerWidth =
        TimelineRuler.getRulerWidth
             model.TimelineDuration
             model.MarksPerSecond
             model.TimelineZoomLevel
    let timelineRulerModel : TimelineRuler.Model =
        { MarksPerSecond = model.MarksPerSecond
          TimelineDuration = model.TimelineDuration
          TimelineZoomLevel = model.TimelineZoomLevel }
    let timelineLanesModel : TimelineLanes.Model =
        { RulerWidth = rulerWidth
          SelectedKeyframe = model.SelectedKeyframe
          TimelineLayers = model.TimelineLayers
          TimelineZoomLevel = model.TimelineZoomLevel }
    let onScrollHandler (event : UIEvent) =
        let element = event.currentTarget :?> Browser.HTMLElement
        match rulerRef, playheadRef with
        | Created ruler, Created playhead ->
            if element.scrollTop > 0. then
                let scrollTop = element.scrollTop.ToString () + "px"
                Fable.setElementInlineStyleProperty "top" scrollTop ruler
                Fable.setElementInlineStyleProperty "top" scrollTop playhead
            else
                Fable.setElementInlineStyleProperty "top" "0px" ruler
                Fable.setElementInlineStyleProperty "top" "0px" playhead
        | Created _, NotCreated
        | NotCreated, Created _
        | NotCreated, NotCreated ->
            ()
    let getTimeFromClickEvent (event : MouseEvent) zoomLevel =
        let target = event.currentTarget :?> Browser.Element
        let bounds = target.getBoundingClientRect()
        let xDistance = (event.clientX - bounds.left) + target.scrollLeft
        int ((xDistance * 10.) / TimelineZoomLevel.toFloat zoomLevel)
    let getCurrentObjectPositionFromModel currentTime zoomLevel =
        match currentTime with
        | 0 -> 0
        | _ ->
            ((float (currentTime / 10)) * TimelineZoomLevel.toFloat zoomLevel)
            |> int
    let onFieldClickHandler event =
        (getTimeFromClickEvent event model.TimelineZoomLevel)
        |> onSkip
        onFieldClick event
    let className =
        ClassNames.classNames
            [ (styles.container, true)
              (styles.opened, model.TimelineOpened)
              (styles.closed, not model.TimelineOpened) ]
    div [ ClassName className
          OnScroll onScrollHandler ] [
        div [ ClassName styles.layers ] [
            TimelineLayersPanel.layersPanel
                model.SelectedTimelineLayers
                model.TimelineLayerBeingRenamed
                onAddLayerGroupClick
                onDeleteLayersClick
                onLayerClick
                onLayerStopDrag
                onLayerMoveBefore
                onLayerMoveAfter
                onLayerMoveToGroup
                onEnableLayerRenaming
                onLayerRename
                onToggleLayerVisibility
                onToggleLayerLocked
                onToggleLayerGroupOpened
                model.TimelineLayers
        ]
        div [ ClassName styles.field
              OnMouseDown onFieldClickHandler ] [
            DraggableTimelineObject.draggable
                rulerWidth
                model.TimelineZoomLevel
                onPause
                onSkip
                (getCurrentObjectPositionFromModel
                     model.CurrentTime
                     model.TimelineZoomLevel)
                (fun objRef -> TimelinePlayhead.playhead objRef)
                (fun ref -> playheadRef <- Created ref)
            div [ ClassName styles.ruler
                  Ref (fun ref -> rulerRef <- Created ref ) ] [
                TimelineRuler.ruler timelineRulerModel
            ]
            TimelineLanes.lanes
                timelineLanesModel
                onKeyframeClick
                onKeyframeMove
                onKeyframeMoved
        ]
    ]
