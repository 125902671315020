namespace StatBanana.Web.Client.Domain.Strategiser

/// The possible playback states for Strategiser.
type PlaybackState =
    | Paused
    | Playing

/// The possible zoom levels for a Strategiser timeline.
type TimelineZoomLevel =
    | WidestZoom
    | WideZoom
    | DefaultZoom
    | CloseZoom
    | ClosestZoom
module TimelineZoomLevel =
    /// <summary>
    ///     Converts an int value to a TimelineZoomLevel.
    /// </summary>
    ///
    /// <param name="value">
    ///     The int that should be converted to a TimelineZoomLevel.
    /// </param>
    let fromInt (value : int) : TimelineZoomLevel option =
        match value with
        | 0 -> Some WidestZoom
        | 1 -> Some WideZoom
        | 2 -> Some DefaultZoom
        | 3 -> Some CloseZoom
        | 4 -> Some ClosestZoom
        | _ -> None

    /// <summary>
    ///     Converts a TimelineZoomLevel to a float.
    /// </summary>
    ///
    /// <param name="value">
    ///     The TimelineZoomLevel that will be converted to a float.
    /// </param>
    let toFloat (value : TimelineZoomLevel) : float =
        match value with
        | WidestZoom -> 0.25
        | WideZoom -> 0.5
        | DefaultZoom -> 1.
        | CloseZoom -> 2.
        | ClosestZoom -> 4.

    /// <summary>
    ///     Checks if a given TimelineZoomLevel is the WidestZoom.
    /// </summary>
    ///
    /// <param name="zoomLevel">
    ///     The TimelineZoomLevel to check.
    /// </param>
    let isWidestZoom (zoomLevel : TimelineZoomLevel) : bool =
        zoomLevel = WidestZoom