namespace StatBanana.Web.Client

open StatBanana.Domain

// Suppress warnings about recursive object references.  We need one to
// recursively parse routes encoded as query params.
#nowarn "40"

open Elmish
open Elmish.Browser.Navigation
open Elmish.Browser.UrlParser
open Fable.Import

open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Pages

/// Responsible for routing based on the browser URL
module Router =
    /// What triggered the current call to urlUpdate
    type private UrlUpdateReason =
        /// The browser location changed
        | LocationModified
        /// Manually requesting that a previously deferred urlUpdate be completed,
        /// not that the required services have been initialised.
        | DeferredUpdate

    module Route =
        /// <summary>
        ///     Is authentication required for the specified route?
        /// </summary>
        ///
        /// <param name="route">
        ///     The route to check.
        /// </param>
        ///
        /// <returns>
        ///     True if authentication is required, false if authentication is
        ///     optional.
        /// </returns>
        let isAuthRequired (route : Route) : bool =
            match route with
            | Route.Checkout _
            | Route.NewSubscriptionSuccess
            | Route.Strategiser _
            | Route.UserSettings _ ->
                true
            | Route.Article _
            | Route.Demo _
            | Route.Images
            | Route.Landing
            | Route.LogIn _
            | Route.MapViewer _
            | Route.Pricing
            | Route.Privacy
            | Route.SignUp _
            | Route.Terms ->
                false

    /// Ghetto clone of the param parser in Elmish.Browser (which is internal so cannot be used
    /// here).  We need this so we can support login redirect URIs containing query params.
    module private RedirectParamParser =
        open Elmish.Browser

        /// Split a browser path into the pathName and search (i.e. queryParam) segments
        let splitPath (path : string) : string * string =
            let parts = path.Split [|'?'|]
            match parts with
            | [| pathName; search |] ->
                // Put the leading '?' back on the search segment
                pathName, sprintf "?%s" search
            | [| pathName |] ->
                pathName, ""
            | _ ->
                Fable.Import.JS.console.error("Failed to split path segments for: ", path)
                // Continue as best we can without splitting
                path, ""

        /// Lifted from https://elmish.github.io/browser/parser.html
        let private toKeyValuePair (segment : string) =
            match segment.Split('=') with
            | [| key; value |] ->
                Option.tuple (Option.ofFunc JS.decodeURI key) (Option.ofFunc JS.decodeURI value)
            | _ -> None

        /// Param parser for decoded redirect URIs, adapted from
        /// https://elmish.github.io/browser/parser.html
        let parseParams (queryString : string) =
            if queryString.Length < 2 then
                Map.empty
            else
                queryString.Substring(1).Split('&')
                |> Seq.map toKeyValuePair
                |> Seq.choose id
                |> Map.ofSeq

    /// Custom URL segment parsers
    module private UrlParser =

        /// Parse a URL segment or param value to a domain Game
        let game state =
            custom
                "game"
                (fun (gameSlug : string) ->
                    let game : Game option = Game.fromSlug gameSlug
                    match game with
                    | Some game ->
                        Ok game
                    | None ->
                        let msg = sprintf "Invalid game slug: %s" gameSlug
                        Fable.Import.JS.console.debug(msg)
                        Error msg)
                state

        /// Parse a URL segment or param value to a domain SessionId
        let sessionIdOption state =
            custom
                "sessionId"
                (fun (sessionIdSlug : string) ->
                    if sessionIdSlug = "new" then
                        None |> Ok
                    else
                        sessionIdSlug
                        |> SessionId
                        |> Some
                        |> Ok)
                state

    /// <summary>
    ///     Parse a route from a URL encoded query parameter value.  The
    ///     encoded route may also include query parameters.
    /// </summary>
    ///
    /// <remarks>
    ///     This function should only be used to parse routes provided as query
    ///     params.  The browser location should be parsed using the default
    ///     routeParser implementation.
    /// </remarks>
    ///
    /// <param name="routeParser">
    ///     Route parser for the router making use of this custom segment parser.
    /// </param>
    ///
    /// <param name="paramValue">
    ///     Query param value string from which to parse the route.
    /// </param>
    ///
    /// <returns>
    ///     The route, if the path represents a valid route.  Otherwise, None.
    /// </returns>
    let parseRouteFromQueryParam routeParser (paramValue : string) : Route option =
        let route =
            paramValue
            |> RedirectParamParser.splitPath
            |> (fun (pathName, queryString) ->
                let queryParams = RedirectParamParser.parseParams queryString
                parse routeParser pathName queryParams)

        if route.IsNone then
            Fable.Import.JS.console.error ("Unknown route (in query param): " + paramValue)

        route

    /// Parses the browser location to a strongly-typed page route
    let rec private routeParser : Parser<Route -> Route, _> =
        /// Parse a query param containing a URL encoded route
        let routeAsQueryParam key =
            customParam key <| fun value ->
                value
                |> Option.map JS.decodeURIComponent
                |> Option.bind (parseRouteFromQueryParam routeParser)

        let strategiserRouteParser =
            let raw =
                UrlParser.game </> s "session" </> UrlParser.sessionIdOption
            map (fun game sessionId -> (game, sessionId)) raw

        oneOf
            [ map Route.Article (s "article" </> str)
              map Route.Checkout (s "checkout")
              map Route.Demo (UrlParser.game </> s "demo")
              map Route.Images (s "images")
              map Route.Landing (s "")
              map Route.LogIn (s "login" <?> routeAsQueryParam "to")
              map Route.MapViewer (UrlParser.game </> s "viewer")
              map Route.NewSubscriptionSuccess (s "subscription-created")
              map Route.Pricing (s "pricing")
              map Route.Privacy (s "privacy")
              map Route.SignUp (s "signup" <?> routeAsQueryParam "to")
              map Route.Strategiser strategiserRouteParser
              map Route.Terms (s "terms")
              map Route.UserSettings (s "settings") ]

    /// Redirects the browser to a supplied page route
    let private redirectTo route =
        Navigation.modifyUrl (Route.getPath route)

    /// urlUpdate handler - NotFound page
    let private initNotFoundPage model routerCmds =
        let m, cmd = NotFoundPage.init ()
        { model with PageModel = NotFoundPageModel m},
        Cmd.batch (Cmd.map NotFoundPageMsg cmd :: routerCmds)

    /// urlUpdate handler - invalid route
    ///
    /// Note: for invalid routes, display the not found page, but do not modify the browser location
    let private handleInvalidRoute (app : AppConfig) (model : Model) =
        let route = Browser.window.location.href
        Fable.Import.JS.console.error ("Unknown route: " + route)
        initNotFoundPage model [ AnalyticsCmd.invalidRoute app route ]

    /// Default post-auth redirect destination
    let private defaultAuthPageDestination : Route = Route.Strategiser (Game.Dota2, None)

    /// Use given destination if possible, or fallback to default
    let private authPageDestinationOrDefault optionalDestination : Route =
        optionalDestination
        |> Option.defaultWith (fun _ ->
            Fable.Import.Browser.console.warn("Falling back to default post-login destination")
            defaultAuthPageDestination)

    /// <summary>
    ///     Elmish-browser URL parser. Parses using using full-path URLs, as opposed to hashbang URLs.
    /// </summary>
    ///
    /// <param name="location">
    ///     Browser.Location to parse.
    /// </param>
    ///
    /// <returns>
    ///     The Route parsed from the location, or None if the location did not match a known route.
    /// </returns>
    let urlParser location = parsePath routeParser location

    let private initArticlePage app model slug routerCmds =
        let pageModel, cmd = ArticlePage.init app slug
        { model with PageModel = ArticlePageModel pageModel },
        Cmd.batch (Cmd.map ArticlePageMsg cmd :: routerCmds)

    let private initAuthPage model destination pageState routerCmds =
        let destination = authPageDestinationOrDefault destination
        let pageModel, cmd = AuthPage.init pageState destination
        { model with PageModel = AuthPageModel pageModel },
        Cmd.batch (Cmd.map AuthPageMsg cmd :: routerCmds)

    let private initCheckoutPage app authenticatedUser model routerCmds =
        let pageModel, cmd = CheckoutPage.init app authenticatedUser
        { model with PageModel = CheckoutPageModel pageModel },
        Cmd.batch (Cmd.map CheckoutPageMsg cmd :: routerCmds)

    let private initImagesPage model routerCmds =
        let pageModel, cmd = ImagesPage.init ()
        { model with PageModel = ImagesPageModel pageModel },
        Cmd.batch (Cmd.map ImagesPageMsg cmd :: routerCmds)

    let private initLandingPage app optionalUser model routerCmds =
        AnimateOnScroll.initAOS ()
        let pageModel, cmd = LandingPage.init app optionalUser
        { model with PageModel = LandingPageModel pageModel },
        Cmd.batch (Cmd.map LandingPageMsg cmd :: routerCmds)

    /// urlUpdate handler - Loading page
    let private initLoadingPage model cmd =
        let m = LoadingPage.init ()
        { model with PageModel = LoadingPageModel m },
        cmd

    let private initNewSubscriptionSuccessPage app authenticatedUser model routerCmds =
        let pageModel, cmd = NewSubscriptionSuccessPage.init app authenticatedUser
        { model with PageModel = NewSubscriptionSuccessPageModel pageModel },
        Cmd.batch (Cmd.map NewSubscriptionSuccessPageMsg cmd :: routerCmds)

    let private initPricingPage app optionalUser model routerCmds =
        let pageModel, cmd = PricingPage.init app optionalUser
        { model with PageModel = PricingPageModel pageModel },
        Cmd.batch (Cmd.map PricingPageMsg cmd :: routerCmds)

    let private initPrivacyPage model routerCmds =
        let pageModel, cmd = PrivacyPage.init ()
        { model with PageModel = PrivacyPageModel pageModel },
        Cmd.batch (Cmd.map PrivacyPageMsg cmd :: routerCmds)

    let private initStrategiserPage app optionalUser model game mode sessionId routerCmds =
        let pageModel, cmd = StrategiserPage.init app optionalUser game mode sessionId
        { model with PageModel = StrategiserPageModel pageModel },
        Cmd.batch (Cmd.map StrategiserPageMsg cmd :: routerCmds)

    let private initTermsPage model routerCmds =
        let pageModel, cmd = TermsPage.init ()
        { model with PageModel = TermsPageModel pageModel },
        Cmd.batch (Cmd.map TermsPageMsg cmd :: routerCmds)

    let private initUserSettingsPage app authenticatedUser model routerCmds =
        let pageModel, cmd = UserSettingsPage.init app authenticatedUser
        { model with PageModel = UserSettingsPageModel pageModel },
        Cmd.batch (Cmd.map UserSettingsPageMsg cmd :: routerCmds)

    let private handleNotFound (model : Model) =
        Browser.console.error
            ("Error parsing url: " + Browser.window.location.href)
        (model, redirectTo Route.Landing)

    /// Updates meta description based on the new route
    let private setMetaTitle (result : Route option) : unit =
        let titleTag = Browser.document.querySelector "title"
        match result with
        | Some route ->
            Route.getMetaTitle route
            |> Option.iter (fun title -> titleTag.textContent <- title)
        | None ->
            titleTag.textContent <- "404! Not Found - StatBanana"

    /// Updates meta description based on the new route
    let private setMetaDescription (result : Route option) : unit =
        let metaTag = Browser.document.querySelector "meta[name='description']"
        match result with
        | Some route ->
            Route.getMetaDescription route
            |> Option.iter (fun desc -> metaTag.setAttribute("content", desc))
        | None ->
            metaTag.setAttribute("content", "Page not found.")

    /// Navigation logic for page-level routes.
    let private initRoute (app : AppConfig) (route : Route) model user =
        // Initialise the appropriate page based on the route,
        // and send analytics pageview events.
        //
        // Note the we only send pageview events when initialising a concrete
        // page, never for redirects.
        match user, route with
        // Pages accessible only when authenticated
        | Some authenticatedUser, Route.Checkout ->
            initCheckoutPage
                app
                authenticatedUser
                model
                [ AnalyticsCmd.pageview app route ]
        | Some authenticatedUser, Route.NewSubscriptionSuccess ->
            initNewSubscriptionSuccessPage
                app
                authenticatedUser
                model
                [ AnalyticsCmd.pageview app Route.NewSubscriptionSuccess ]
        | Some _, (Route.Strategiser (game, sessionId) as route) ->
            initStrategiserPage
                app
                user
                model
                game
                (Strategiser.Mode.EditorMode false)
                sessionId
                [ AnalyticsCmd.pageview app route ]
        | Some authenticatedUser, Route.UserSettings ->
            initUserSettingsPage
                app
                authenticatedUser
                model
                [ AnalyticsCmd.pageview app Route.UserSettings ]

        // Pages not accessible when authenticated
        | None, (Route.LogIn destination as route) ->
            initAuthPage
                model
                destination
                AuthPage.AuthAction.LogIn
                [ AnalyticsCmd.pageview app route ]
        | None, (Route.SignUp destination as route) ->
            initAuthPage
                model
                destination
                AuthPage.AuthAction.SignUp
                [ AnalyticsCmd.pageview app route ]
        | Some _, Route.LogIn destinationOption
        | Some _, Route.SignUp destinationOption ->
            // Redirect to post-signup destination
            let destination = authPageDestinationOrDefault destinationOption
            model, RouterCmd.navigateTo destination

        // Pages accessible regardless of authentication state
        | _, Route.Article slug ->
            initArticlePage
                app
                model
                slug
                [ AnalyticsCmd.pageview app Route.Landing ]
        | _, Route.Demo game ->
            initStrategiserPage
                app
                user
                model
                game
                (Strategiser.Mode.DemoMode)
                None
                [ AnalyticsCmd.pageview app route ]
        | _, Route.Images ->
            initImagesPage
                model
                [ AnalyticsCmd.pageview app Route.Images ]
        | _, Route.Landing ->
            initLandingPage
                app
                user
                model
                [ AnalyticsCmd.pageview app Route.Landing ]
        | _, (Route.MapViewer game as route) ->
            initStrategiserPage
                app
                user
                model
                game
                (Strategiser.Mode.ViewerMode Strategiser.MapViewer)
                None
                [ AnalyticsCmd.pageview app route ]
        | _, Route.Pricing ->
            initPricingPage
                app
                user
                model
                [ AnalyticsCmd.pageview app Route.Pricing ]
        | _, Route.Privacy ->
            initPrivacyPage
                model
                [ AnalyticsCmd.pageview app Route.Privacy ]
        | _, Route.Terms ->
            initTermsPage
                model
                [ AnalyticsCmd.pageview app Route.Terms ]

        | None, _ ->
            //
            // Route requires authentication, but user is not authenticated.
            //
            // This state indicates that we are either waiting on a "redirect to login" command
            // triggered by a UserLoggedOut message to be executed (via AuthMsgHandler), or that the
            // user navigated to a page requiring auth using the browser back button but isn't
            // authenticated.
            //

            // Render a loading page while we wait - with no associated model, or update logic.
            Fable.Import.JS.console.log("Waiting for redirect to login ...")

            // Dispatch an additional user UserSignedOut message - this is required to handle
            // the case where the user navigated to a page requiring auth using the browser back
            // button but is no longer authenticated.  In this case, the auth service won't dipatch
            // a message to trigger the redirect as it would already have done so before the user
            // hit 'back'.
            let redirectCmd =
                UserSignedOut
                |> AuthMsg
                |> Cmd.ofMsg

            // Render a loading page while we wait.
            initLoadingPage model redirectCmd

    /// Perform a URL update
    let private doUrlUpdate
        (app : AppConfig)
        (parsedRoute : Route option)
        (model : Model)
        : Model * Cmd<Msg> =

        // Set window title - must occur before sending analytics events
        setMetaTitle parsedRoute

        // Set meta description
        setMetaDescription parsedRoute

        let routeWasInitialised, (newModel, command) =
            match model.User, parsedRoute with
            // Defer initialisation of routes until the auth state is known
            | AuthServiceNotInitialised, Some _ ->
                Fable.Import.JS.console.log("Deferring URL update until AuthService is initialised ...")

                // Render a loading page while we wait.
                false, initLoadingPage model Cmd.none

            // Otherwise, initialise the route
            | Authenticated _, Some route
            | NotAuthenticated, Some route ->
                let user = CurrentUser.toAuthenticatedUserOption model.User
                true, initRoute app route model user

            // Handle invalid or unknown routes
            | _, None ->
                true, handleInvalidRoute app model

        /// Dispatch a Msg reporting that the URL was updated
        let newCommand =
            match routeWasInitialised with
            | true ->
                [ Cmd.ofMsg RouteInitialised
                  command ]
                |> Cmd.batch
            | false ->
                // For deferred updates, dispatch RouteInitialised only after
                // the deferred update has been completed
                command

        // Push the current route to the model
        let newRouterModel =
            { newModel.Router with
                route = parsedRoute
                routeInitialised = routeWasInitialised }

        { newModel with Router = newRouterModel }, newCommand

    let private urlUpdateForReason
        (reason : UrlUpdateReason)
        (app : AppConfig)
        (parsedRoute : Route option)
        (model : Model)
        : Model * Cmd<Msg> =

        match reason, model.Router.routeInitialised with
        | LocationModified, _ ->
            doUrlUpdate app parsedRoute model
        | DeferredUpdate, false ->
            Fable.Import.JS.console.log("Completing deferred URL update ...")
            doUrlUpdate app parsedRoute model
        | DeferredUpdate, true ->
            // Do not perform a deferred update if the route has already been
            // initialised
            model, Cmd.none

    /// <summary>
    ///     Update the application state and dispatch commands in response
    ///     to browser location updates.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="parsedRoute">
    ///     Result from the <c>routeParser</c>
    /// </param>
    ///
    /// <param name="model">
    ///     Application state
    /// </param>
    let urlUpdate
        (app : AppConfig)
        (parsedRoute : Route option)
        (model : Model)
        : Model * Cmd<Msg> =

        urlUpdateForReason LocationModified app parsedRoute model

    /// <summary>
    ///     Complete the previously deferred URL update, if any.
    /// </summary>
    ///
    /// <param name="app">
    ///     App config, including injected services
    /// </param>
    ///
    /// <param name="model">
    ///     Application state, as returned by previous update step.
    /// </param>
    ///
    /// <param name="cmd">
    ///     Command returned by previous update step, yet to be actioned.
    /// </param>
    let completeDeferredUrlUpdate
        (app : AppConfig)
        (model : Model, cmd :Cmd<Msg>)
        : Model * Cmd<Msg> =

        let newModel, routerCmd =
            urlUpdateForReason DeferredUpdate app model.Router.route model

        newModel, Cmd.batch [ cmd; routerCmd ]

    /// Initial router state
    let init () : RouterModel =
        { route = None
          routeInitialised = false }