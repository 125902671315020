module StatBanana.Web.Client.Components.Molecules.NavigationHeader

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React
open Fable.Import
open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain

type Styles =
    { border : string
      logo : string
      navbar : string
      wheat : string }

type private Props = unit

type private State = { isBurgerMenuActive : bool }

type private BurgerMenu(initialProps) as this =
    inherit Component<obj, State>(initialProps)
    do
        this.setInitState
            { isBurgerMenuActive = false }

    override this.render () =
        let styles : Styles = importAll "./NavigationHeader.sass"

        let toggleBurgerMenu _ =
            let menuElement = Browser.document.getElementById "burger-menu"
            match this.state.isBurgerMenuActive with
            | false ->
                menuElement.classList.add "is-active"
            | true ->
                menuElement.classList.remove "is-active"

            (fun state _ ->
                { this.state with
                    isBurgerMenuActive =
                        not this.state.isBurgerMenuActive })
            |> this.setState
            |> ignore

        let burgerProps : IHTMLProp list =
            [ OnClick toggleBurgerMenu ]

        let burgerClassName =
            let isActiveClass =
                if this.state.isBurgerMenuActive then " is-active" else ""
            styles.wheat + isActiveClass

        fragment [] [
            Navbar.burger [ GenericOption.CustomClass burgerClassName
                            GenericOption.Props burgerProps ] [
                span [] []
                span [] []
                span [] []
            ]
        ]

let getLogoSrc (isLight : bool) : string =
    match isLight with
    | true -> "/logo_statbanana_white.png"
    | false -> "/logo_statbanana.png"

let header
    (currentRoute : Route option)
    (useLightLogo : bool)
    (optionalUser : AuthenticatedUser option)
    (onSignOut : unit -> unit)
    : ReactElement =

    let styles : Styles = importAll "./NavigationHeader.sass"

    let renderCta (user : AuthenticatedUser) =
        if user.claims |> UserClaim.isSubscribed Game.Dota2 then
            Navbar.Item.div [] [
                Button.mainCtaButton
                    IsMedium
                    ((Game.Dota2, None) |> Route.Strategiser |> Route.getPath)
                    "Use Statbanana"
            ]
        else
            Navbar.Item.div [] [
                Button.mainCtaButton
                    IsMedium
                    (Route.Pricing |> Route.getPath)
                    "Upgrade Now"
            ]

    let renderAuthenticationNavbarItem (isMobile : bool) =
        let settingsDropdownItemProps =
            Dropdown.Item.Option.Props [ Href "/settings" ]
        let signOutDropdownItemProps =
            Dropdown.Item.Option.Props [ OnClick (fun _ -> onSignOut ()) ]
        match optionalUser with
        | Some user ->
            if isMobile then
                fragment [] [
                    Navbar.Item.a [] [
                        Button.mainNavLink
                            IsMedium
                            (Route.UserSettings |> Route.getPath)
                            "Settings"
                    ]
                    Navbar.Item.a [ Navbar.Item.Option.Props [ OnClick (fun _ -> onSignOut ()) ] ] [
                        Button.mainNavLink
                            IsMedium
                            (Route.Landing |> Route.getPath)
                            "Sign Out"
                    ]
                ]
            else
                fragment [] [
                    Dropdown.dropdown [ Dropdown.IsHoverable ] [
                        Navbar.Item.div [] [
                            Button.mainNavLink
                                IsMedium
                                (Route.UserSettings |> Route.getPath)
                                "Account"
                            Icon.icon [ Icon.Size IsSmall ] [
                                Fa.i [ Fa.Solid.AngleDown ] [ ]
                            ]
                        ]
                        Dropdown.menu [] [
                            Dropdown.content [] [
                                Dropdown.Item.a [ settingsDropdownItemProps ] [
                                    str "Settings"
                                ]
                                Dropdown.Item.a [ signOutDropdownItemProps ] [
                                    str "Sign Out"
                                ]
                            ]
                        ]
                    ]
                    (renderCta user)
                ]
        | None ->
            fragment [] [
                Navbar.Item.div [] [
                    Button.mainNavLink
                        IsMedium
                        (Route.Demo Game.Dota2 |> Route.getPath)
                        "Demo"
                ]
                Navbar.Item.div [] [
                    Button.mainNavLink
                        IsMedium
                        (currentRoute |> Route.LogIn |> Route.getPath)
                        "Log In"
                ]
                Navbar.Item.div [] [
                    Button.mainCtaButton
                        IsLarge
                        (None |> Route.SignUp |> Route.getPath)
                        "Try Now"
                ]
            ]

    let renderedBurger =
        ofType<BurgerMenu,_,_> () []

    let mainMobileCta =
        match optionalUser with
        | Some user ->
            renderCta user
        | None ->
            nothing

    Navbar.navbar [ Navbar.CustomClass styles.navbar ] [
        Container.container [] [
            Navbar.Brand.div [] [
                Navbar.Item.a [ Navbar.Item.CustomClass styles.logo
                                Navbar.Item.Props [ Href "/" ] ] [
                    img [ Src (getLogoSrc useLightLogo) ]
                ]
                renderedBurger
            ]
            Navbar.menu [] [
                Navbar.End.div [] [
                    Navbar.Item.div [] [
                        Button.mainNavLink
                            IsMedium
                            (Route.Pricing |> Route.getPath)
                            "Pricing"
                    ]
                    Navbar.Item.div [] [
                        Button.mainNavLink
                            IsMedium
                            (Game.Dota2 |> Route.MapViewer |> Route.getPath)
                            "Map Viewer"
                    ]
                    renderAuthenticationNavbarItem false
                ]
            ]
            Navbar.menu [ Navbar.Menu.Props [ Id "burger-menu" ]
                          Navbar.Menu.Modifiers [ Modifier.IsHidden (Screen.Desktop, true) ] ] [
                Navbar.End.div [] [
                    mainMobileCta
                    Navbar.Item.a [] [
                        Button.mainNavLink
                            IsMedium
                            (Route.Pricing |> Route.getPath)
                            "Pricing"
                    ]
                    Navbar.Item.div [] [
                        Button.mainNavLink
                            IsMedium
                            (Game.Dota2 |> Route.MapViewer |> Route.getPath)
                            "Map Viewer"
                    ]
                    renderAuthenticationNavbarItem true
                ]
            ]
        ]
    ]