namespace StatBanana.Web.Client.Services.Analytics

open Fable.Core
open Fable.Core.JsInterop
open Fable.Import

open StatBanana.Web.Client.Domain

/// <summary>
///     Service responsible for reporting analytics events to Amplitude
/// </summary>
///
/// <remarks>
///    <note type="caution">
///        Window titles and URLs containing Personally Identifiable Information (PII)
///        must not be sent to Amplitude Analytics.
///     </note>
/// </remarks>
module Amplitude =

    /// Our custom Amplitude event types
    module private AmplitudeEvent =
        [<Literal>]
        let invalidRoute = "Invalid Route"

        [<Literal>]
        let signedUp = "Signed Up"

        [<Literal>]
        let subscribed = "Subscribed"

        [<Literal>]
        let unhandledError = "Unhandled Error"

        let viewedPage pageType = sprintf "Viewed %s Page" pageType

    /// Amplitude SDK client
    module private AmplitudeClient =

        [<Import("default", "amplitude-js")>]
        let private amplitudeJs: obj = jsNative

        /// Create a client instance
        let private client: obj = amplitudeJs?getInstance ()

        /// Initialise an instance of the Amplitude SDK client. This function MUST
        /// be called before any of the other tracking functions will record any
        /// data.
        let initialise (apiKey: string): unit = client?init (apiKey)

        /// <summary>
        ///     Set userId used to identify the current user.
        /// </summary>
        ///
        /// <remarks>
        ///     Anonymous events sent in the current session, before this id was
        ///     provided, will be be merged under the provided user id.
        /// </remarks>
        let setUserId (userId: string): unit = client?setUserId (userId)

        /// Send an event with the specified name and properties, appending default
        /// properties (location and pageTitle).
        let sendEvent (eventName: string) (customProperties: (string * obj) list): unit =

            // Send location and page title with all events (for context)
            let location = Browser.window.location.toString ()
            let pageTitle = Browser.document.title

            let requiredProperties =
                [ "location" ==> location
                  "pageTitle" ==> pageTitle ]

            let eventProperties =
                [ requiredProperties; customProperties ]
                |> List.concat
                |> createObj

            client?logEvent (eventName, eventProperties)

    /// Send an invalid route
    let private invalidRoute (route: string): unit =
        let eventProperties = [ "route" ==> route ]

        AmplitudeClient.sendEvent AmplitudeEvent.invalidRoute eventProperties

    /// Send a pageview event to Amplitude for the specified route
    let private pageview (route: Route): unit =
        let eventName =
            PageType.fromRoute route
            |> AmplitudeEvent.viewedPage

        AmplitudeClient.sendEvent eventName []

    /// Send a user action event to Amplitude
    let private userAction (action: Strategiser.UserAction): unit =
        let eventProperties =
            let actionData = action |> Strategiser.UserAction.getData
            match action, actionData with
            | Strategiser.UserAction.DroppedMarkerOnMap _, Strategiser.UserActionData.DroppedMarkerData data ->
                [ "game" ==> data.game
                  "item" ==> data.item
                  "type" ==> data.``type`` ]

            | Strategiser.UserAction.EndedCollaborationSession duration, _ -> [ "duration" ==> duration ]

            | Strategiser.UserAction.ClickedShareSession, _
            | Strategiser.UserAction.SavedSession, _
            | Strategiser.UserAction.StartedCollaborationSession, _
            | Strategiser.UserAction.ToggledSessionPrivacy _, _
            | Strategiser.UserAction.DroppedMarkerOnMap _, _ -> []

        AmplitudeClient.sendEvent (action |> Strategiser.UserAction.getName) eventProperties

    /// Send an event reporting that the current user signed up
    let private userSignedUp (): unit =
        AmplitudeClient.sendEvent AmplitudeEvent.signedUp []

    /// Send an event reporting that the current user has subscribed
    let private userSubscribed (): unit =
        AmplitudeClient.sendEvent AmplitudeEvent.subscribed []

    /// <summary>
    ///     Send an error message.
    /// </summary>
    ///
    /// <param name="description">
    ///     Description of the exception.
    /// </param>
    let private sendError (description: string): unit =
        let eventProperties = [ "description" ==> description ]

        AmplitudeClient.sendEvent AmplitudeEvent.unhandledError eventProperties

    /// <summary>
    ///     Send an exception.
    /// </summary>
    ///
    /// <param name="ex">
    ///     The exception
    /// </param>
    let private sendException (ex: exn): unit =
        let description = ex.ToString()
        sendError description

    /// Send the provided analytics event
    let private track (event: AnalyticsEvent): unit =
        match event with
        | InvalidRoute invalidPath -> invalidRoute invalidPath
        | PageView route -> pageview route
        | StrategiserAction action -> userAction action
        | UnhandledError errorMsg -> sendError errorMsg
        | UnhandledException ex -> sendException ex
        | UserIdAvailable userId -> AmplitudeClient.setUserId userId
        | UserSignedUp userId ->
            AmplitudeClient.setUserId userId
            userSignedUp ()
        | UserSubscribed _ -> userSubscribed ()

    /// <summary>
    ///     Initialise an Amplitude analytics provider for the
    ///     AnalyticsService.
    /// </summary>
    ///
    /// <param name="env">
    ///     Deployment environment, used to determine which project to send
    ///     events to.
    /// </param>
    ///
    /// <returns>
    ///     Amplitude analytics provider.
    /// </returns>
    let provider (env: DeploymentEnvironment): AnalyticsProvider =
        let apiKey =
            match env with
            | DeploymentEnvironment.LocalDevelopment
            | DeploymentEnvironment.Development -> "018b3de739db61c2eefe6c78f9365a6f"
            | DeploymentEnvironment.Production -> "904e0faf3d4846d8e68f7e9b62756357"

        // Initialise the amplitude client
        AmplitudeClient.initialise apiKey

        // Provide analytics event handler
        track
