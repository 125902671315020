namespace StatBanana.Web.Client.Domain

open StatBanana.Domain

type SubscriptionClaim =
    { game : Game
      pacakge : PremiumPackage }

/// Claim made by the user about themself.
type UserClaim =
    | Developer
    | Influencer
    | Subscriber of SubscriptionClaim
module UserClaim =

    let isDeveloper (claims : UserClaim list) : bool =
        claims
        |> List.contains Developer

    let isInfluencer (claims : UserClaim list) : bool =
        claims
        |> List.contains Influencer

    let isSubscribed (game : Game) (claims : UserClaim list) =
        claims
        |> List.exists (fun claim ->
            match claim with
            | UserClaim.Subscriber sub ->
                sub.game = game
            | _ ->
                false)