/// A MapItem
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.Marker

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import

type Styles = {
    abilityItem : string
    animatableItem : string
    attackRangeCircle : string
    backgroundImageIcon : string
    circleIcon : string
    container : string
    dire : string
    genericMarker : string
    greyscale : string
    icon : string
    item : string
    laneCreep : string
    leftAligned : string
    neutralCamp : string
    neutralCampBorder : string
    radiant : string
    roundItem : string
    staticItem : string
    textingTextarea : string
    ward : string }
let private styles : Styles = importAll "./Marker.sass"

let private getDota2AbilityIconSrc (ability : Dota2.Ability) =
    // Temporary until we get our own assets
    let abilityIconCDN = "http://cdn.dota2.com/apps/dota2/images/abilities"

    abilityIconCDN + "/" + (Dota2.Ability.toId ability) + "_md.png"

let private getDota2HeroPortraitSrc hero =
    let assetDir = "assets/games/dota2/heroes"
    let heroImageFilename =
        match hero with
        | Dota2.Hero.Abaddon -> "abaddon.png"
        | Dota2.Hero.AbyssalUnderlord -> "abyssal-underlord.png"
        | Dota2.Hero.Alchemist -> "alchemist.png"
        | Dota2.Hero.AncientApparition -> "ancient-apparition.png"
        | Dota2.Hero.Antimage -> "antimage.png"
        | Dota2.Hero.ArcWarden -> "arc-warden.png"
        | Dota2.Hero.Axe -> "axe.png"
        | Dota2.Hero.Bane -> "bane.png"
        | Dota2.Hero.Batrider -> "batrider.png"
        | Dota2.Hero.Beastmaster -> "beastmaster.png"
        | Dota2.Hero.Bloodseeker -> "bloodseeker.png"
        | Dota2.Hero.BountyHunter -> "bounty-hunter.png"
        | Dota2.Hero.Brewmaster -> "brewmaster.png"
        | Dota2.Hero.Bristleback -> "bristleback.png"
        | Dota2.Hero.Broodmother -> "broodmother.png"
        | Dota2.Hero.Centaur -> "centaur.png"
        | Dota2.Hero.ChaosKnight -> "chaos-knight.png"
        | Dota2.Hero.Chen -> "chen.png"
        | Dota2.Hero.Clinkz -> "clinkz.png"
        | Dota2.Hero.CrystalMaiden -> "crystal-maiden.png"
        | Dota2.Hero.DarkSeer -> "dark-seer.png"
        | Dota2.Hero.DarkWillow -> "dark-willow.png"
        | Dota2.Hero.Dazzle -> "dazzle.png"
        | Dota2.Hero.DeathProphet -> "death-prophet.png"
        | Dota2.Hero.Disruptor -> "disruptor.png"
        | Dota2.Hero.DoomBringer -> "doom-bringer.png"
        | Dota2.Hero.DragonKnight -> "dragon-knight.png"
        | Dota2.Hero.DrowRanger -> "drow-ranger.png"
        | Dota2.Hero.Earthshaker -> "earthshaker.png"
        | Dota2.Hero.EarthSpirit -> "earth-spirit.png"
        | Dota2.Hero.ElderTitan -> "elder-titan.png"
        | Dota2.Hero.EmberSpirit -> "ember-spirit.png"
        | Dota2.Hero.Enchantress -> "enchantress.png"
        | Dota2.Hero.Enigma -> "enigma.png"
        | Dota2.Hero.FacelessVoid -> "faceless-void.png"
        | Dota2.Hero.Furion -> "furion.png"
        | Dota2.Hero.Grimstroke -> "grimstroke.png"
        | Dota2.Hero.Gyrocopter -> "gyrocopter.png"
        | Dota2.Hero.Huskar -> "huskar.png"
        | Dota2.Hero.Invoker -> "invoker.png"
        | Dota2.Hero.Jakiro -> "jakiro.png"
        | Dota2.Hero.Juggernaut -> "juggernaut.png"
        | Dota2.Hero.KeeperOfTheLight -> "keeper-of-the-light.png"
        | Dota2.Hero.Kunkka -> "kunkka.png"
        | Dota2.Hero.LegionCommander -> "legion-commander.png"
        | Dota2.Hero.Leshrac -> "leshrac.png"
        | Dota2.Hero.Lich -> "lich.png"
        | Dota2.Hero.LifeStealer -> "life-stealer.png"
        | Dota2.Hero.Lina -> "lina.png"
        | Dota2.Hero.Lion -> "lion.png"
        | Dota2.Hero.LoneDruid -> "lone-druid.png"
        | Dota2.Hero.Luna -> "luna.png"
        | Dota2.Hero.Lycan -> "lycan.png"
        | Dota2.Hero.Magnataur -> "magnataur.png"
        | Dota2.Hero.Mars -> "mars.png"
        | Dota2.Hero.Medusa -> "medusa.png"
        | Dota2.Hero.Meepo -> "meepo.png"
        | Dota2.Hero.Mirana -> "mirana.png"
        | Dota2.Hero.MonkeyKing -> "monkey-king.png"
        | Dota2.Hero.Morphling -> "morphling.png"
        | Dota2.Hero.NagaSiren -> "naga-siren.png"
        | Dota2.Hero.Necrolyte -> "necrolyte.png"
        | Dota2.Hero.Nevermore -> "nevermore.png"
        | Dota2.Hero.NightStalker -> "night-stalker.png"
        | Dota2.Hero.NyxAssassin -> "nyx-assassin.png"
        | Dota2.Hero.ObsidianDestroyer -> "obsidian-destroyer.png"
        | Dota2.Hero.OgreMagi -> "ogre-magi.png"
        | Dota2.Hero.Omniknight -> "omniknight.png"
        | Dota2.Hero.Oracle -> "oracle.png"
        | Dota2.Hero.Pangolier -> "pangolier.png"
        | Dota2.Hero.PhantomAssassin -> "phantom-assassin.png"
        | Dota2.Hero.PhantomLancer -> "phantom-lancer.png"
        | Dota2.Hero.Phoenix -> "phoenix.png"
        | Dota2.Hero.Puck -> "puck.png"
        | Dota2.Hero.Pudge -> "pudge.png"
        | Dota2.Hero.Pugna -> "pugna.png"
        | Dota2.Hero.QueenOfPain -> "queenofpain.png"
        | Dota2.Hero.Rattletrap -> "rattletrap.png"
        | Dota2.Hero.Razor -> "razor.png"
        | Dota2.Hero.Riki -> "riki.png"
        | Dota2.Hero.Rubick -> "rubick.png"
        | Dota2.Hero.SandKing -> "sand-king.png"
        | Dota2.Hero.ShadowDemon -> "shadow-demon.png"
        | Dota2.Hero.ShadowShaman -> "shadow-shaman.png"
        | Dota2.Hero.Shredder -> "shredder.png"
        | Dota2.Hero.Silencer -> "silencer.png"
        | Dota2.Hero.SkeletonKing -> "skeleton-king.png"
        | Dota2.Hero.SkywrathMage -> "skywrath-mage.png"
        | Dota2.Hero.Slardar -> "slardar.png"
        | Dota2.Hero.Slark -> "slark.png"
        | Dota2.Hero.Sniper -> "sniper.png"
        | Dota2.Hero.Spectre -> "spectre.png"
        | Dota2.Hero.SpiritBreaker -> "spirit-breaker.png"
        | Dota2.Hero.StormSpirit -> "storm-spirit.png"
        | Dota2.Hero.Sven -> "sven.png"
        | Dota2.Hero.Techies -> "techies.png"
        | Dota2.Hero.TemplarAssassin -> "templar-assassin.png"
        | Dota2.Hero.Terrorblade -> "terrorblade.png"
        | Dota2.Hero.Tidehunter -> "tidehunter.png"
        | Dota2.Hero.Tinker -> "tinker.png"
        | Dota2.Hero.Tiny -> "tiny.png"
        | Dota2.Hero.Treant -> "treant.png"
        | Dota2.Hero.TrollWarlord -> "troll-warlord.png"
        | Dota2.Hero.Tusk -> "tusk.png"
        | Dota2.Hero.Undying -> "undying.png"
        | Dota2.Hero.Ursa -> "ursa.png"
        | Dota2.Hero.VengefulSpirit -> "vengefulspirit.png"
        | Dota2.Hero.Venomancer -> "venomancer.png"
        | Dota2.Hero.Viper -> "viper.png"
        | Dota2.Hero.Visage -> "visage.png"
        | Dota2.Hero.Warlock -> "warlock.png"
        | Dota2.Hero.Weaver -> "weaver.png"
        | Dota2.Hero.Windrunner -> "windrunner.png"
        | Dota2.Hero.WinterWyvern -> "winter-wyvern.png"
        | Dota2.Hero.Wisp -> "wisp.png"
        | Dota2.Hero.WitchDoctor -> "witch-doctor.png"
        | Dota2.Hero.Zuus -> "zeus.png"
    assetDir + "/" + heroImageFilename

let getDota2BuildingIcon (buildingItem : Dota2BuildingItem) =
    let assetDir = "assets/games/dota2/buildings"
    let buildingImageFilename =
        match buildingItem |> Dota2BuildingItem.getBuilding with
        | Dota2.Building.Ancient (Dota2.FactionType.Dire) ->
            "ancient-enemy.png"
        | Dota2.Building.Tower (Dota2.FactionType.Dire,_,_)
        | Dota2.Building.Shrine (Dota2.FactionType.Dire) ->
            "tower-enemy-45.png"
        | Dota2.Building.Barracks (Dota2.FactionType.Dire,_,_) ->
            "barracks-enemy-45.png"
        | Dota2.Building.Ancient (Dota2.FactionType.Radiant) ->
            "ancient-friendly.png"
        | Dota2.Building.Tower (Dota2.FactionType.Radiant,_,_)
        | Dota2.Building.Shrine (Dota2.FactionType.Radiant) ->
            "tower-friendly-45.png"
        | Dota2.Building.Barracks (Dota2.FactionType.Radiant,_,_) ->
            "barracks-friendly-45.png"
        | Dota2.Building.Outpost outpostSide ->
            match buildingItem.state with
            | (BuildingState.Captured side) ->
                if (side = Dota2.FactionType.Dire) then "outpost-dire.png"
                else "outpost-radiant.png"
            | _ ->
                if (outpostSide = Dota2.FactionType.Dire) then "outpost-dire.png"
                else "outpost-radiant.png"

    assetDir + "/" + buildingImageFilename

let private getDota2NeutralCampIcon (camp : Dota2.NeutralCamp) =
    let assetDir = "assets/games/dota2/neutrals"
    let campImageFilename =
        match camp with
        | Dota2.NeutralCamp.SmallCamp (_,_,_) ->
            "small-camp.png"
        | Dota2.NeutralCamp.MediumCamp (_,_,_) ->
            "medium-camp.png"
        | Dota2.NeutralCamp.LargeCamp (_,_,_) ->
            "large-camp.png"
        | Dota2.NeutralCamp.AncientCamp (_,_,_) ->
            "ancient-camp.png"
        | Dota2.NeutralCamp.Roshan ->
            "roshan.png"
    assetDir + "/" + campImageFilename

let private getDota2WardIconSrc (wardItem : Dota2WardItem) =
    let assetDir = "assets/games/dota2/wards/"
    let wardImageFilename =
        match wardItem.state, wardItem.side, wardItem.kind with
        | BuildingState.NotDestroyed,
          Dota2.Side.Faction Dota2.FactionType.Dire,
          Dota2.Ward.Sentry ->
            "SentryWardEnemy.png"
        | BuildingState.NotDestroyed,
          Dota2.Side.Faction Dota2.FactionType.Dire,
          Dota2.Ward.Observer -> "ObserverWardEnemy.png"
        | BuildingState.NotDestroyed,
          Dota2.Side.Faction Dota2.FactionType.Radiant,
          Dota2.Ward.Sentry -> "SentryWardFriendly.png"
        | BuildingState.NotDestroyed,
          Dota2.Side.Faction Dota2.FactionType.Radiant,
          Dota2.Ward.Observer -> "ObserverWardFriendly.png"
        | BuildingState.NotDestroyed,
          Dota2.Side.Neutral,
          Dota2.Ward.Sentry -> "SentryWardFriendlyCB.png"
        | BuildingState.NotDestroyed,
          Dota2.Side.Neutral,
          Dota2.Ward.Observer -> "ObserverWardFriendlyCB.png"
        | BuildingState.Destroyed,
          _,
          Dota2.Ward.Observer -> "ObserverWardDestroyed.png"
        | BuildingState.Destroyed,
          _,
          Dota2.Ward.Sentry -> "SentryWardDestroyed.png"
        | BuildingState.Captured _, _, _ -> "ObserverWardFriendly.png"
    assetDir + wardImageFilename

let private getGenericSymbolSrc (symbol : GenericItem) =
    let assetDir = "assets/generic_symbols/"
    let symbolFileName =
        match symbol with
        | Square -> "square.png"
        | Battle -> "sword.png"
        | Circle -> "circle.png"
        | Heart -> "heart.png"
        | Star -> "star.png"
    assetDir + symbolFileName

let private renderImgIcon customClassName src =
    let className =
        ClassNames.classNames
            [ (styles.icon, true)
              (customClassName, customClassName.Length > 0) ]
    img [ ClassName className
          Draggable false
          Src src ]

let private renderAbilityIconWithFallback src =
    let backgroundProp =
        "url('" + src + "')"
    let fallbackBackgroundProp =
        "url('assets/games/dota2/abilities/fallback.jpg')"

    div [
        ClassName styles.backgroundImageIcon
        Style [
            CSSProp.Background fallbackBackgroundProp
            CSSProp.BackgroundSize "cover"
        ]
    ] [
        div [
            ClassName styles.backgroundImageIcon
            Style [
                CSSProp.Background backgroundProp
                CSSProp.BackgroundSize "cover"
            ]
        ] []
    ]

let private renderCircleIcon customClassName =
    let className =
        ClassNames.classNames
            [ (styles.circleIcon, true)
              (customClassName, customClassName.Length > 0) ]
    div [ ClassName className ] []

let private renderTextMarker isViewerMode text =
    let className = styles.textingTextarea + " " + styles.leftAligned
    let topValue = ((45. - text.fontSize) / 2.)
    textarea [ ClassName className
               DefaultValue text.text
               Disabled isViewerMode
               Style [ Color text.color
                       FontSize text.fontSize
                       Width text.width
                       CSSProp.Top topValue ] ] []

/// <summary>
///     Renders a given Item as a Marker.
/// </summary>
///
/// <param name="item">
///     The Item to render as a Marker.
/// </param>
let marker (item : Item) : ReactElement =
    let className = styles.item + " " + styles.roundItem
    let icon =
        match item with
        | Dota2Item (Dota2LaneCreepItem _) ->
            renderCircleIcon styles.laneCreep
        | Dota2Item (Dota2BuildingItem building) ->
            renderImgIcon "" (getDota2BuildingIcon building)
        | Dota2Item (Dota2HeroItem hero) ->
            renderImgIcon "" (getDota2HeroPortraitSrc hero.hero)
        | Dota2Item (Dota2NeutralCampItem camp) ->
            renderImgIcon "" (getDota2NeutralCampIcon camp.camp)
        | Dota2Item (Dota2WardItem ward) ->
            renderImgIcon "" (getDota2WardIconSrc ward)
        | Dota2Item (Dota2AbilityItem ability) ->
            renderAbilityIconWithFallback
                (getDota2AbilityIconSrc ability.ability)
        | GenericItem symbol ->
            renderImgIcon "" (getGenericSymbolSrc symbol)
        | TextItem _ ->
            nothing
    div [ ClassName className ] [
        icon
    ]

/// <summary>
///     Renders a given Item as a Marker on a map.
/// </summary>
///
/// <param name="isViewerMode">
///     Whether the app is current in viewer (no-edit) mode.
/// </param>
/// <param name="isVisible">
///     Whether or not the marker has been hidden by the user
/// </param>
///
/// <param name="id">
///     The ID to set on the Marker element.
/// </param>
///
/// <param name="item">
///     The Item to render as a Marker on a map.
/// </param>
let mapMarker
    (isViewerMode : bool)
    (isVisible : bool)
    (id : string)
    (item : Item)
    : ReactElement =
    let typeClassName =
        match item with
        | GenericItem _ ->
            styles.genericMarker
        | Dota2Item (Dota2WardItem _) ->
            styles.staticItem + " " + styles.ward
        | Dota2Item (Dota2LaneCreepItem _)
        | TextItem _ ->
            ""
        | Dota2Item (Dota2HeroItem _) ->
            styles.roundItem + " " + styles.animatableItem
        | Dota2Item (Dota2AbilityItem _) ->
            styles.roundItem
            + " "
            + styles.abilityItem
            + " "
            + styles.animatableItem
        | Dota2Item (Dota2BuildingItem _)
        | Dota2Item (Dota2NeutralCampItem _) ->
            styles.staticItem

    let sideClassName =
        match item with
        | Dota2Item dota2item ->
            match Dota2Item.getSide dota2item with
            | Dota2.Side.Faction Dota2.FactionType.Radiant -> styles.radiant
            | Dota2.Side.Faction Dota2.FactionType.Dire -> styles.dire
            | _ -> ""
        | TextItem _
        | GenericItem _ ->
            ""

    let buildingStateClassName =
        let predicate =
            match item with
            | Dota2Item (Dota2BuildingItem building) ->
                building
                |> Dota2BuildingItem.getBuildingState = BuildingState.Destroyed
            | Dota2Item (Dota2WardItem ward) ->
                ward
                |> Dota2WardItem.getState = BuildingState.Destroyed
            | _ -> false
        ClassNames.classNames [ (styles.greyscale, predicate) ]

    let itemClassName =
        String.concat
            " "
            [ styles.item
              typeClassName
              sideClassName ]

    let renderItem =
        match item with
        | Dota2Item (Dota2BuildingItem building) ->
            renderImgIcon buildingStateClassName (getDota2BuildingIcon building)
        | Dota2Item (Dota2HeroItem hero) ->
            renderImgIcon "" (getDota2HeroPortraitSrc hero.hero)
        | Dota2Item (Dota2LaneCreepItem _) ->
            let className = styles.laneCreep + " " + sideClassName
            renderCircleIcon className
        | Dota2Item (Dota2NeutralCampItem camp) ->
            renderImgIcon "" (getDota2NeutralCampIcon camp.camp)
        | Dota2Item (Dota2WardItem ward) ->
            renderImgIcon buildingStateClassName (getDota2WardIconSrc ward)
        | Dota2Item (Dota2AbilityItem ability) ->
            renderAbilityIconWithFallback
                (getDota2AbilityIconSrc ability.ability)
        | GenericItem symbol ->
            renderImgIcon "" (getGenericSymbolSrc symbol)
        | TextItem text ->
            renderTextMarker
                isViewerMode
                text

    let containerClassName =
        styles.container + (if isVisible then "" else " invisible")
    div [ ClassName containerClassName ] [
        div [ ClassName itemClassName
              Id id  ] [
            renderItem
        ]
    ]