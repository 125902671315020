/// A layer in the timeline
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.TimelineLayer

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Extensions
open StatBanana.Web.Client.Import
open StatBanana.Web.Client.Import.ReactDraggable

type Styles =
    { childLayers : string
      disabledCursor : string
      groupArrow : string
      groupIcon : string
      grouped : string
      keyframeManager : string
      layer : string
      layerContent : string
      left : string
      lock : string
      groupLayerCursor : string
      orderLayerCursorBottom : string
      orderLayerCursorTop : string
      name : string
      nameInput : string
      opened : string
      renameGroup : string
      right : string
      selected : string
      visibility : string }

let private styles: Styles = importAll "./TimelineLayer.sass"

// Start of disgusting javascript in f# for layer dragging

let private getLayerContainers () =
    Fable.nodeListOfToList (Browser.document.querySelectorAll ".timelineLayer")

let private getGroupLayerHandle (layer : Browser.Element) =
    layer.querySelector ".groupHandle"

let private getUnitLayerHandle (layer : Browser.Element) =
    layer.querySelector ".unitHandle"

let private getLayerElement (layer : Browser.Element) =
    layer.firstElementChild

let private isLayerElementAGroup (layerElement : Browser.Element) =
    layerElement.classList.contains "group"

let private isLayerElementInAGroup (layerElement : Browser.Element) =
    layerElement.classList.contains styles.grouped

let private isLayerElementProtected (layerElement : Browser.Element) =
    layerElement.classList.contains "protected"

let private getGroupIdFromLayerElement (layerElement : Browser.Element) =
    layerElement.getAttribute "data-group-id"

let private drawLayerDragIndicator
    (draggedLayerContainer : Browser.Element)
    (event : MouseEvent) =
    let layers = getLayerContainers ()
    let updateLayerStyle (layerContainer : Browser.Element) =
        let rect = layerContainer.getBoundingClientRect ()
        let beingDragged =
            layerContainer.classList.contains "react-draggable-dragging"
        let layerElement = getLayerElement layerContainer
        let shouldAddBottomOrderCursor =
            event.clientY >= rect.top && rect.bottom - event.clientY < 10.
         && event.clientY < rect.bottom && not beingDragged
         && not (isLayerElementProtected layerElement)
        let shouldAddTopOrderCursor =
            event.clientY >= rect.top && event.clientY - rect.top < 5.
         && event.clientY < rect.bottom && not beingDragged
         && not (isLayerElementProtected layerElement)
        let shouldAddGroupCursor =
            if isLayerElementAGroup layerElement then
                let handle = getGroupLayerHandle layerContainer
                let handleRect = handle.getBoundingClientRect ()
                let inSameGroup =
                    let isDraggedGrouped =
                        draggedLayerContainer
                        |> getLayerElement
                        |> isLayerElementInAGroup
                    if isDraggedGrouped then
                        let draggedGroupId =
                            draggedLayerContainer
                            |> getLayerElement
                            |> getGroupIdFromLayerElement
                        draggedGroupId = handle.id
                    else false
                event.clientY >= handleRect.top
             && event.clientY < handleRect.bottom && not beingDragged
             && not (isLayerElementProtected layerElement) && not inSameGroup
            else false
        if shouldAddBottomOrderCursor then
            if isLayerElementAGroup layerElement then
                let handle = getGroupLayerHandle layerContainer
                handle.classList.remove styles.groupLayerCursor
            layerContainer.classList.remove styles.orderLayerCursorTop
            layerContainer.classList.add styles.orderLayerCursorBottom
        else if shouldAddTopOrderCursor then
            layerContainer.classList.remove styles.orderLayerCursorBottom
            layerContainer.classList.add styles.orderLayerCursorTop
        else if shouldAddGroupCursor && shouldAddTopOrderCursor then
            let handle = getGroupLayerHandle layerContainer
            handle.classList.remove styles.groupLayerCursor
            layerContainer.classList.remove styles.orderLayerCursorBottom
            layerContainer.classList.add styles.orderLayerCursorTop
        else if shouldAddGroupCursor then
            let handle = getGroupLayerHandle layerContainer
            layerContainer.classList.remove styles.orderLayerCursorTop
            layerContainer.classList.remove styles.orderLayerCursorBottom
            handle.classList.add styles.groupLayerCursor
        else
            if isLayerElementAGroup layerElement then
                let handle = getGroupLayerHandle layerContainer
                handle.classList.remove styles.groupLayerCursor
            layerContainer.classList.remove styles.orderLayerCursorTop
            layerContainer.classList.remove styles.orderLayerCursorBottom
    List.iter updateLayerStyle layers

let private removeLayerDragIndicators () =
    let layers =
        Fable.nodeListOfToList
            (Browser.document.querySelectorAll ".timelineLayer")
    let updateLayerStyle (layerContainer : Browser.Element) =
        let layerElement = layerContainer.firstElementChild
        if layerElement.classList.contains "group" then
            let handle = getGroupLayerHandle layerContainer
            handle.classList.remove styles.groupLayerCursor
        layerContainer.classList.remove styles.orderLayerCursorTop
        layerContainer.classList.remove styles.orderLayerCursorBottom
    List.iter updateLayerStyle layers

let private disableCursorForProtectedLayer (layer : Browser.Element) =
    let layerElement = getLayerElement layer
    if isLayerElementProtected layerElement then
        layerElement.classList.add styles.disabledCursor

let private resetCursor (layer : Browser.Element) =
    let layerElement = getLayerElement layer
    layerElement.classList.remove styles.disabledCursor

let private findClosestLayerFromMouseEvent
    (targetNode : Browser.Element)
    (event : MouseEvent) =
    let ignoreElement elementToIgnore layerContainer =
        elementToIgnore <> layerContainer
    let getClosestLayerToEvent
        (event : MouseEvent)
        (layerContainer : Browser.Element) =
        let layerElement = getLayerElement layerContainer
        let rect = layerContainer.getBoundingClientRect ()
        if isLayerElementAGroup layerElement then
            let handle = getGroupLayerHandle layerContainer
            let handleRect = handle.getBoundingClientRect ()
            event.clientY >= handleRect.top && event.clientY < handleRect.bottom
        else
            event.clientY >= rect.top && event.clientY < rect.bottom
    (getLayerContainers ())
    |> List.filter (ignoreElement targetNode)
    |> List.rev
    |> List.tryFind (getClosestLayerToEvent event)

let private draggableLayer
    disabled
    onStop
    onMoveLayerBefore
    onMoveLayerAfter
    onAddLayerToGroup
    renderedLayer =
    let onStartHandler (event : MouseEvent) _ =
        event.stopPropagation ()
    let onDragHandler (event : MouseEvent) (data : DraggableData) =
        drawLayerDragIndicator data.node event
    let onStopHandler (event : MouseEvent) (data : DraggableData) =
        let getIdFromLayerContainer (layerContainer : Browser.Element) =
            let isGroup =
                layerContainer |> getLayerElement |> isLayerElementAGroup
            let isGrouped =
                layerContainer |> getLayerElement |> isLayerElementInAGroup
            if isGroup then (getGroupLayerHandle layerContainer).id
            else (getUnitLayerHandle layerContainer).id
        match findClosestLayerFromMouseEvent data.node event with
        | Some layerContainer ->
            let moveBefore =
                layerContainer.classList.contains styles.orderLayerCursorTop
            let moveAfter =
                layerContainer.classList.contains styles.orderLayerCursorBottom
            let addToGroup =
                if layerContainer |> getLayerElement |> isLayerElementAGroup
                then
                    let handle =
                        layerContainer |> getLayerElement |> getGroupLayerHandle
                    handle.classList.contains styles.groupLayerCursor
                else false
            if moveBefore then
                onMoveLayerBefore (getIdFromLayerContainer layerContainer)
            else if moveAfter then
                onMoveLayerAfter (getIdFromLayerContainer layerContainer)
            else if addToGroup then
                onAddLayerToGroup (getIdFromLayerContainer layerContainer)
        | None -> ()
        removeLayerDragIndicators ()
        onStop event
    let position = { x = 0; y = 0 }
    draggable [ DraggableProps.Axis DraggableAxis.Y
                DraggableProps.Disabled disabled
                DraggableProps.OnDrag onDragHandler
                DraggableProps.OnStart onStartHandler
                DraggableProps.OnStop onStopHandler
                DraggableProps.Position position ] [
        div [ ClassName "timelineLayer" ] [
            renderedLayer
        ]
    ]

// End of disgusting javascript in f#

let private renderVisibilityIcon visible =
    if visible then
        Fa.i [ Fa.Solid.Eye ] []
    else
        Fa.i [ Fa.Solid.EyeSlash ] []

let private renderLockIcon locked =
    if locked then
        Fa.i [ Fa.Solid.Lock ] []
    else
        Fa.i [ Fa.Solid.LockOpen ] []

let private timelineLayerUnit
    isSelected
    isGrouped
    groupId
    onClick
    onToggleVisibility
    onToggleLock
    (unit : LayerUnit) =
    let onClickHandler (event : MouseEvent) =
        event.stopPropagation ()
        onClick unit
    let onToggleVisibilityHandler (event : MouseEvent) =
        event.stopPropagation ()
        onToggleVisibility event unit
    let onToggleLockHandler (event : MouseEvent) =
        event.stopPropagation ()
        onToggleLock event unit
    let className =
        ClassNames.classNames
            [ (styles.layer, true)
              ("unitHandle", true)
              (styles.selected, isSelected)
              (styles.grouped, isGrouped)
              ("protected", unit.perpetual) ]
    div [ ClassName className
          Data ("group-id", groupId)
          Id (LayerId.toString unit.id)
          OnClick onClickHandler ] [
        div [ ClassName styles.layerContent ] [
            div [ ClassName styles.left ] [
                div [ ClassName styles.visibility
                      OnClick onToggleVisibilityHandler ] [
                    renderVisibilityIcon unit.visible
                ]
                div [ ClassName styles.lock
                      Disabled unit.perpetual
                      OnClick onToggleLockHandler ] [
                    renderLockIcon (unit.locked || unit.perpetual)
                ]
            ]
            div [ ClassName styles.right ] [
                div [ ClassName styles.name ] [
                    str unit.name
                ]
            ]
        ]
    ]

let private timelineLayerGroup
    selectedTimelineLayers
    isSelected
    isBeingRenamed
    onGroupClick
    onGroupStopDrag
    onGroupMovedBefore
    onGroupMovedAfter
    onUnitClick
    onUnitStopDrag
    onUnitMoveBefore
    onUnitMoveAfter
    onUnitMoveToGroup
    onEnableRenaming
    onRename
    onToggleVisibility
    onToggleUnitVisibility
    onToggleLocked
    onToggleUnitLocked
    onToggleGroupOpened
    (group : LayerGroup) =
    let onToggleVisibilityHandler (event : MouseEvent) =
        onToggleVisibility event group
    let onToggleLockedHandler (event : MouseEvent) =
        if not group.perpetual then
            onToggleLocked event group
    let arrowIcon =
        if group.opened then
            Fa.i [ Fa.Solid.AngleDown ] []
        else
            Fa.i [ Fa.Solid.AngleRight ] []
    let folderIcon =
        if group.opened then
            Fa.i [ Fa.Solid.FolderOpen ] []
        else
            Fa.i [ Fa.Solid.Folder ] []
    let onNameClickHandler (event : MouseEvent) =
        event.stopPropagation ()
        if isSelected && not isBeingRenamed && not group.perpetual then
            onEnableRenaming (LayerGroup group)
    let onNameSubmitHandler (event : FormEvent) =
        event.preventDefault ()
        let form = event.target :?> Browser.HTMLElement
        let input = form.querySelector ("input") :?> Browser.HTMLInputElement
        onRename input.value (LayerGroup group)
    let onNameKeyDownHandler (event : KeyboardEvent) =
        match event.key with
        | "Escape" ->
            onRename group.name (LayerGroup group)
        | _ -> ()
    let renderChild (child : LayerUnit) =
        let onUnitStopDragHandler event =
            onUnitStopDrag event child
        let onUnitMoveBeforeHandler targetId =
            onUnitMoveBefore child targetId
        let onUnitMoveAfterHandler targetId =
            onUnitMoveAfter child targetId
        let onUnitMoveToGroupHandler groupId =
            onUnitMoveToGroup child groupId
        let isSelected =
            List.contains (child |> LayerUnit) selectedTimelineLayers
        draggableLayer
            false
            onUnitStopDragHandler
            onUnitMoveBeforeHandler
            onUnitMoveAfterHandler
            onUnitMoveToGroupHandler
            (timelineLayerUnit
                 isSelected
                 true
                 group.id
                 onUnitClick
                 onToggleUnitVisibility
                 onToggleUnitLocked
                 child)
    let renderGroup =
        let layerClassName =
            ClassNames.classNames
                [ (styles.layer, true)
                  ("group", true)
                  (styles.opened, group.opened)
                  (styles.selected, isSelected)
                  ("protected", group.perpetual) ]
        let layerContentClassName = "groupHandle " + styles.layerContent
        div [ ClassName layerClassName
              OnClick onGroupClick ] [
            div [ ClassName layerContentClassName
                  Id (LayerId.toString group.id) ] [
                div [ ClassName styles.left ] [
                    div [ ClassName styles.visibility
                          OnClick onToggleVisibilityHandler ] [
                        renderVisibilityIcon group.visible
                    ]
                    div [ ClassName styles.lock
                          Disabled group.perpetual
                          OnClick onToggleLockedHandler ] [
                        renderLockIcon (group.locked || group.perpetual)
                    ]
                ]
                div [ ClassName styles.right ] [
                    fragment [] [
                        div [ ClassName styles.groupArrow
                              OnClick onToggleGroupOpened ] [
                            arrowIcon
                        ]
                        div [ ClassName styles.groupIcon ] [
                            folderIcon
                        ]
                    ]
                    div [ ClassName styles.name ] [
                        form [ OnSubmit onNameSubmitHandler
                               OnClick onNameClickHandler ] [
                            input [ ClassName styles.nameInput
                                    DefaultValue group.name
                                    Disabled (not isBeingRenamed)
                                    OnKeyDown onNameKeyDownHandler ]
                        ]
                    ]
                ]
            ]
            div [ ClassName styles.childLayers ] [
                group.children |> (List.map renderChild) |> ofList
            ]
        ]
    draggableLayer
        isBeingRenamed
        onGroupStopDrag
        onGroupMovedBefore
        onGroupMovedAfter
        ignore
        renderGroup

/// <summary>
///     Renders a timeline layer
/// </summary>
///
/// <param name="selectedTimelineLayers">
///     A list of currently selected timeline layers
/// </param>
/// <param name="timelineLayerBeingRenamed">
///     A list of currently selected timeline layers
/// </param>
/// <param name="onClick">
///     The function to call when a layer is clicked
/// </param>
/// <param name="onStopDrag">
///     The function to call when a layer is starting to be dragged
/// </param>
/// <param name="onMoveBefore">
///     The function to call when a layer has been moved before another layer
/// </param>
/// <param name="onMoveAfter">
///     The function to call when a layer has been moved after another layer
/// </param>
/// <param name="onMoveToGroup">
///     The function to call when a layer has been moved to a group
/// </param>
/// <param name="onEnableRenaming">
///     The function to call when a layer has renaming enabled
/// </param>
/// <param name="onRename">
///     The function to call when a layer is renamed
/// </param>
/// <param name="onVisibilityToggle">
///     The function to call when the layer's visibility is toggled
/// </param>
/// <param name="onToggleLocked">
///     The function to call when the layer's locked status is toggled
/// </param>
/// <param name="onToggleLayerGroupOpened">
///     The function to call when a layer group is opened or closed
/// </param>
/// <param name="layer">
///     The layer information used to render this layer
/// </param>
let timelineLayer
    (selectedTimelineLayers : Layer list)
    (timelineLayerBeingRenamed : Layer option)
    (onClick : Layer -> unit)
    (onStopDrag : MouseEvent -> Layer -> unit)
    (onMoveBefore : Layer -> string -> unit)
    (onMoveAfter : Layer -> string -> unit)
    (onMoveToGroup : Layer -> string -> unit)
    (onEnableRenaming : Layer -> unit)
    (onRename : string -> Layer -> unit)
    (onToggleVisibility : Layer -> unit)
    (onToggleLocked : Layer -> unit)
    (onToggleGroupOpened : LayerGroup -> unit)
    (layer : Layer) : ReactElement =
    let onClickHandler _ =
        onClick layer
    let onStopDragHandler event =
        onStopDrag event layer
    let onMoveBeforeHandler targetId =
        onMoveBefore layer targetId
    let onMoveAfterHandler targetId =
        onMoveAfter layer targetId
    let onMoveToGroupHandler groupId =
        onMoveToGroup layer groupId
    let onUnitClickHandler (unit : LayerUnit) =
        onClick (LayerUnit unit)
    let onUnitStopDragHandler event _ =
        onStopDrag event layer
    let onUnitMoveBeforeHandler (unit : LayerUnit) targetId =
        onMoveBefore (LayerUnit unit) targetId
    let onUnitMoveAfterHandler (unit : LayerUnit) targetId =
        onMoveAfter (LayerUnit unit) targetId
    let onUnitMoveToGroupHandler (unit : LayerUnit) groupId =
        onMoveToGroup (LayerUnit unit) groupId
    let onToggleVisibilityHandler (event : MouseEvent) _ =
        event.stopPropagation ()
        onToggleVisibility layer
    let onToggleUnitVisibilityHandler (event : MouseEvent) unit =
        event.stopPropagation ()
        onToggleVisibility (LayerUnit unit)
    let onToggleLockedHandler (event : MouseEvent) _ =
        event.stopPropagation ()
        onToggleLocked layer
    let onToggleUnitLockedHandler (event : MouseEvent) unit =
        event.stopPropagation ()
        onToggleLocked (LayerUnit unit)
    let onToggleGroupOpenedHandler (event : MouseEvent) =
        event.stopPropagation ()
        match layer with
        | LayerGroup group -> onToggleGroupOpened group
        | LayerUnit _ -> ()
    let isSelected =
        List.contains
            layer
            selectedTimelineLayers
    let isBeingRenamed =
        match timelineLayerBeingRenamed with
        | Some layerBeingRenamed -> layer = layerBeingRenamed
        | None -> false
    match layer with
    | LayerGroup group ->
        timelineLayerGroup
            selectedTimelineLayers
            isSelected
            isBeingRenamed
            onClickHandler
            onStopDragHandler
            onMoveBeforeHandler
            onMoveAfterHandler
            onUnitClickHandler
            onUnitStopDragHandler
            onUnitMoveBeforeHandler
            onUnitMoveAfterHandler
            onUnitMoveToGroupHandler
            onEnableRenaming
            onRename
            onToggleVisibilityHandler
            onToggleUnitVisibilityHandler
            onToggleLockedHandler
            onToggleUnitLockedHandler
            onToggleGroupOpenedHandler
            group
    | LayerUnit unit ->
        draggableLayer
            false
            onStopDragHandler
            onMoveBeforeHandler
            onMoveAfterHandler
            onMoveToGroupHandler
            (timelineLayerUnit
                isSelected
                false
                ""
                onClickHandler
                onToggleVisibilityHandler
                onToggleLockedHandler
                unit)