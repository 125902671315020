namespace StatBanana.Web.Client.Domain.Dota2

/// The possible neutral camp types in Dota 2.
type NeutralCampType =
    | SmallCamp
    | MediumCamp
    | LargeCamp
    | AncientCamp
    | Roshan
module NeutralCampType =
    /// All the neutral camp types in Dota 2.
    let all =
        [ SmallCamp
          MediumCamp
          LargeCamp
          AncientCamp
          Roshan ]

    /// <summary>
    ///    Get the name for a given Dota 2 neutral camp type.
    /// </summary>
    ///
    /// <param name="neutralCamp">
    ///     The Dota 2 neutral camp type to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 neutral camp type.
    /// </returns>
    let getName (neutralCamp : NeutralCampType) : string =
        match neutralCamp with
        | SmallCamp ->
            "Small Camp"
        | MediumCamp ->
            "Medium Camp"
        | LargeCamp ->
            "Large Camp"
        | AncientCamp ->
            "Ancient Camp"
        | Roshan ->
            "Roshan"

/// The possible neutral camps in Dota 2.
type NeutralCamp =
    | SmallCamp of FactionType * Jungle * index : int
    | MediumCamp of FactionType * Jungle * index : int
    | LargeCamp of FactionType * Jungle * index : int
    | AncientCamp of FactionType * Jungle * index : int
    | Roshan
module NeutralCamp =
    /// A list of the neutral camps in Dota 2.
    let all =
        [ Roshan
          AncientCamp (FactionType.Dire, Jungle.Top, 0)
          AncientCamp (FactionType.Radiant, Jungle.Bottom, 0)
          LargeCamp (FactionType.Dire, Jungle.Top, 0)
          LargeCamp (FactionType.Dire, Jungle.Top, 1)
          LargeCamp (FactionType.Dire, Jungle.Top, 2)
          LargeCamp (FactionType.Dire, Jungle.Bottom, 0)
          LargeCamp (FactionType.Radiant, Jungle.Top, 0)
          LargeCamp (FactionType.Radiant, Jungle.Bottom, 0)
          LargeCamp (FactionType.Radiant, Jungle.Bottom, 1)
          LargeCamp (FactionType.Radiant, Jungle.Bottom, 2)
          MediumCamp (FactionType.Dire, Jungle.Top, 0)
          MediumCamp (FactionType.Dire, Jungle.Top, 1)
          MediumCamp (FactionType.Dire, Jungle.Bottom, 0)
          MediumCamp (FactionType.Radiant, Jungle.Top, 0)
          MediumCamp (FactionType.Radiant, Jungle.Bottom, 0)
          MediumCamp (FactionType.Radiant, Jungle.Bottom, 1)
          SmallCamp (FactionType.Dire, Jungle.Top, 0)
          SmallCamp (FactionType.Radiant, Jungle.Bottom, 0) ]

    /// <summary>
    ///    Serialise a given Dota 2 neutral camp to a string id value for persistance.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="neutralCamp">
    ///     The Dota 2 neutral camp to serialise to a string id value.
    /// </param>
    ///
    /// <returns>
    ///     The serialised id value of the given Dota 2 neutral camp.
    /// </returns>
    let toId (neutralCamp : NeutralCamp) : string =
        match neutralCamp with
        | Roshan -> "roshan"
        | AncientCamp (FactionType.Dire, Jungle.Top, 0) ->
            "dire_top_0_ancient"
        | AncientCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            "radiant_bottom_0_ancient"
        | LargeCamp (FactionType.Dire, Jungle.Top, 0) ->
            "dire_top_0_large"
        | LargeCamp (FactionType.Dire, Jungle.Top, 1) ->
            "dire_top_1_large"
        | LargeCamp (FactionType.Dire, Jungle.Top, 2) ->
            "dire_top_2_large"
        | LargeCamp (FactionType.Dire, Jungle.Bottom, 0) ->
            "dire_bottom_0_large"
        | LargeCamp (FactionType.Radiant, Jungle.Top, 0) ->
            "radiant_top_0_large"
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            "radiant_bottom_0_large"
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 1) ->
            "radiant_bottom_1_large"
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 2) ->
            "radiant_bottom_2_large"
        | MediumCamp (FactionType.Dire, Jungle.Top, 0) ->
            "dire_top_0_medium"
        | MediumCamp (FactionType.Dire, Jungle.Top, 1) ->
            "dire_top_1_medium"
        | MediumCamp (FactionType.Dire, Jungle.Bottom, 0) ->
            "dire_bottom_0_medium"
        | MediumCamp (FactionType.Radiant, Jungle.Top, 0) ->
            "radiant_top_0_medium"
        | MediumCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            "radiant_bottom_0_medium"
        | MediumCamp (FactionType.Radiant, Jungle.Bottom, 1) ->
            "radiant_bottom_1_medium"
        | SmallCamp (FactionType.Dire, Jungle.Top, 0) ->
            "dire_bottom_0_small"
        | SmallCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            "radiant_bottom_0_small"

        | _ -> "invalid_neutral_camp"

    /// <summary>
    ///     Deserialise a Dota 2 neutral camp that was serialised using <c>toId</c>.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="id">
    ///     The serialised Dota 2 neutral camp.
    /// </param>
    ///
    /// <returns>
    ///     NeutralCamp, or an error message if the side could not be deserialised.
    /// </returns>
    let fromId (id : string) : Result<NeutralCamp, string> =
        match id with
        | "roshan" -> Ok Roshan
        | "dire_top_0_ancient" ->
            AncientCamp (FactionType.Dire, Jungle.Top, 0)
            |> Ok
        | "radiant_bottom_0_ancient" ->
            AncientCamp (FactionType.Radiant, Jungle.Bottom, 0)
            |> Ok
        | "dire_top_0_large" ->
            LargeCamp (FactionType.Dire, Jungle.Top, 0)
            |> Ok
        | "dire_top_1_large" ->
            LargeCamp (FactionType.Dire, Jungle.Top, 1)
            |> Ok
        | "dire_top_2_large" ->
            LargeCamp (FactionType.Dire, Jungle.Top, 2)
            |> Ok
        | "dire_bottom_0_large" ->
            LargeCamp (FactionType.Dire, Jungle.Bottom, 0)
            |> Ok
        | "radiant_top_0_large" ->
            LargeCamp (FactionType.Radiant, Jungle.Top, 0)
            |> Ok
        | "radiant_bottom_0_large" ->
            LargeCamp (FactionType.Radiant, Jungle.Bottom, 0)
            |> Ok
        | "radiant_bottom_1_large" ->
            LargeCamp (FactionType.Radiant, Jungle.Bottom, 1)
            |> Ok
        | "radiant_bottom_2_large" ->
            LargeCamp (FactionType.Radiant, Jungle.Bottom, 2)
            |> Ok
        | "dire_top_0_medium" ->
            MediumCamp (FactionType.Dire, Jungle.Top, 0)
            |> Ok
        | "dire_top_1_medium" ->
            MediumCamp (FactionType.Dire, Jungle.Top, 1)
            |> Ok
        | "dire_bottom_0_medium" ->
            MediumCamp (FactionType.Dire, Jungle.Bottom, 0)
            |> Ok
        | "radiant_top_0_medium" ->
            MediumCamp (FactionType.Radiant, Jungle.Top, 0)
            |> Ok
        | "radiant_bottom_0_medium" ->
            MediumCamp (FactionType.Radiant, Jungle.Bottom, 0)
            |> Ok
        | "radiant_bottom_1_medium" ->
            MediumCamp (FactionType.Radiant, Jungle.Bottom, 1)
            |> Ok
        | "dire_bottom_0_small" ->
            SmallCamp (FactionType.Dire, Jungle.Top, 0)
            |> Ok
        | "radiant_bottom_0_small" ->
            SmallCamp (FactionType.Radiant, Jungle.Bottom, 0)
            |> Ok
        | invalidNeutralCamp ->
            sprintf "Invalid Dota 2 neutral camp : %s" invalidNeutralCamp
            |> Error

    /// <summary>
    ///    Get the name for a given Dota 2 neutral camp.
    /// </summary>
    ///
    /// <param name="neutralCamp">
    ///     The Dota 2 neutral camp to obtain a name for.
    /// </param>
    ///
    /// <returns>
    ///     The name of the given Dota 2 neutral camp.
    /// </returns>
    let getName (neutralCamp : NeutralCamp) : string =
        match neutralCamp with
        | SmallCamp (faction,_,_) ->
            String.concat
                " "
                [ FactionType.getName faction
                  NeutralCampType.getName NeutralCampType.SmallCamp ]
        | MediumCamp (faction,_,_) ->
            String.concat
                " "
                [ FactionType.getName faction
                  NeutralCampType.getName NeutralCampType.MediumCamp ]
        | LargeCamp (faction,_,_) ->
            String.concat
                " "
                [ FactionType.getName faction
                  NeutralCampType.getName NeutralCampType.LargeCamp ]
        | AncientCamp (faction,_,_) ->
            String.concat
                " "
                [ FactionType.getName faction
                  NeutralCampType.getName NeutralCampType.AncientCamp ]
        | Roshan ->
            NeutralCampType.getName NeutralCampType.Roshan

    /// <summary>
    ///    Get the coordinates for a given Dota 2 neutral camp.
    /// </summary>
    ///
    /// <param name="neutralCamp">
    ///     The Dota 2 neutral camp to obtain a coordinates for.
    /// </param>
    ///
    /// <returns>
    ///     If valid Dota 2 neutral camp, the coordinates of the given Dota 2 neutral camp, otherwise None.
    /// </returns>
    let getCoordinates (neutralCamp : NeutralCamp) : Coordinates option =
        match neutralCamp with
        | Roshan ->
            { x = Coordinate -5365.0; y = Coordinate 4952.0 }
            |> Some
        | AncientCamp (FactionType.Dire, Jungle.Top, 0) ->
            { x = Coordinate -7807.0; y = Coordinate 11188.0 }
            |> Some
        | AncientCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            { x = Coordinate -12194.0; y = Coordinate 8568.0 }
            |> Some
        | LargeCamp (FactionType.Dire, Jungle.Top, 0) ->
            { x = Coordinate -4235.0; y = Coordinate 3551.0 }
            |> Some
        | LargeCamp (FactionType.Dire, Jungle.Top, 1) ->
            { x = Coordinate -4196.0; y = Coordinate 7418.0 }
            |> Some
        | LargeCamp (FactionType.Dire, Jungle.Top, 2) ->
            { x = Coordinate -4425.0; y = Coordinate 8729.0 }
            |> Some
        | LargeCamp (FactionType.Dire, Jungle.Bottom, 0) ->
            { x = Coordinate -6776.0; y = Coordinate 11336.0 }
            |> Some
        | LargeCamp (FactionType.Radiant, Jungle.Top, 0) ->
            { x = Coordinate -7774.0; y = Coordinate 3071.0 }
            |> Some
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            { x = Coordinate -11329.0; y = Coordinate 5802.0 }
            |> Some
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 1) ->
            { x = Coordinate -7946.0; y = Coordinate 5093.0 }
            |> Some
        | LargeCamp (FactionType.Radiant, Jungle.Bottom, 2) ->
            { x = Coordinate -11363.0; y = Coordinate 11744.0 }
            |> Some
        | MediumCamp (FactionType.Dire, Jungle.Top, 0) ->
            { x = Coordinate -2759.0; y = Coordinate 7055.0 }
            |> Some
        | MediumCamp (FactionType.Dire, Jungle.Top, 1) ->
            { x = Coordinate -5376.0; y = Coordinate 6840.0 }
            |> Some
        | MediumCamp (FactionType.Dire, Jungle.Bottom, 0) ->
            { x = Coordinate -7751.0; y = Coordinate 9344.0 }
            |> Some
        | MediumCamp (FactionType.Radiant, Jungle.Top, 0) ->
            { x = Coordinate -6432.0; y = Coordinate 3909.0 }
            |> Some
        | MediumCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            { x = Coordinate -9441.0; y = Coordinate 7476.0 }
            |> Some
        | MediumCamp (FactionType.Radiant, Jungle.Bottom, 1) ->
            { x = Coordinate -10613.0; y = Coordinate 7403.0 }
            |> Some
        | SmallCamp (FactionType.Dire, Jungle.Top, 0) ->
            { x = Coordinate -3062.0; y = Coordinate 5138.0 }
            |> Some
        | SmallCamp (FactionType.Radiant, Jungle.Bottom, 0) ->
            { x = Coordinate -11691.0; y = Coordinate 10473.0 }
            |> Some
        | _ ->
            None