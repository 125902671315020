module StatBanana.Web.Client.Extensions.Axios

open Fable.Core
open Fable.Import.Axios

open StatBanana.Web.Client.Domain

type private AuthHeaders =
    { [<CompiledName("Authorization")>] authorization : string }

/// Attach a bearer token to the axios request.
let authenticateWith (bearerToken : BearerToken) : AxiosXHRConfigBase<'a> =
    let token = BearerToken.getToken bearerToken

    JsInterop.jsOptions
        (fun (config : AxiosXHRConfigBase<'a>) ->
            config.headers <-
                { authorization = "Bearer " + token }
                |> JsInterop.toPlainJsObj
                |> Some )
