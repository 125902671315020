[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.UserSettingsPage

open Fable.Helpers.React
open Fable.Import

open Elmish
open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Components.Atoms

open StatBanana.Web.Client.Domain

type Model =
    { Error : exn option
      Loading : bool
      UserProfile : UserProfile option }

type Msg =
    | FirestoreUserDoesNotExist of AuthenticatedUser
    | FirestoreUserError of exn
    | FirestoreUserFetched of UserProfile
    | SubscriptionCancellationRequest of AuthenticatedUser * Game
    | SubscriptionCancelled of AuthenticatedUser
    | SubscriptionCancelError of AuthenticatedUser * exn

module Cmd =

    /// <summary>
    ///     Submit a request to cancel a user's subscription
    /// </summary>
    ///
    /// <param name="app">
    ///     The AppConfig and its services
    /// </param>
    ///
    /// <param name="user">
    ///     The authenticated user to cancel the subscription for
    /// </param>
    ///
    /// <param name="game">
    ///     The Game (product) to cancel the subscription for
    /// </param>
    ///
    /// <returns>
    ///     A command to request subscription cancellation
    /// </returns>
    let cancelSubscription
        (app : AppConfig)
        (user : AuthenticatedUser)
        (game : Game)
        : Cmd<Msg> =

        let ofSuccess _ =
            SubscriptionCancelled user
        let ofError (exn : exn) =
            SubscriptionCancelError (user, exn)
        Cmd.ofPromise
            (app.subscriptionService.cancel user)
            game
            ofSuccess
            ofError

    /// <summary>
    ///     Submit a request to get a Firestore User based
    ///     on an authenticated user
    /// </summary>
    ///
    /// <param name="app">
    ///     The AppConfig and its services
    /// </param>
    ///
    /// <param name="user">
    ///     The authenticated user to get the
    ///     corresponding FirestoreUser for
    /// </param>
    /// <returns>
    ///     A command to request the FirestoreUser
    /// </returns>
    let fetchFirestoreUser
        (app : AppConfig)
        (authenticatedUser : AuthenticatedUser)
        : Cmd<Msg> =

        let ofSuccess result =
            match result with
            | Some user ->
                FirestoreUserFetched user
            | None ->
                FirestoreUserDoesNotExist authenticatedUser
        let ofError (exn : exn) =
            FirestoreUserError exn
        Cmd.ofPromise
            app.userStoreService.getUser
            authenticatedUser.id
            ofSuccess
            ofError

    let refreshPage () : Cmd<Msg> =
        Browser.window.location.reload()
        Cmd.none

let init
    (app : AppConfig)
    (user : AuthenticatedUser)
    : Model * Cmd<Msg> =
    let initialModel : Model =
        { Error = None
          UserProfile = None
          Loading = true }

    initialModel, Cmd.fetchFirestoreUser app user

let update
    (app : AppConfig)
    (msg : Msg)
    (model : Model)
    : Model * Cmd<Msg> =

    match msg with
    | FirestoreUserFetched firestoreUser ->
        let newModel =
            { model with
                UserProfile = firestoreUser |> Some
                Loading = false }
        newModel, Cmd.none
    | FirestoreUserDoesNotExist authenticatedUser ->
        let newModel =
            { model with
                UserProfile = None
                Loading = false }
        newModel, Cmd.none
    | FirestoreUserError exn ->
        let newModel =
            { model with
                Error = Some exn
                Loading = false }
        newModel, Cmd.none

    | SubscriptionCancellationRequest (authenticatedUser, game) ->
        let cmd =
            Cmd.cancelSubscription
                app
                authenticatedUser
                game
        model, cmd
    | SubscriptionCancelled authenticatedUser ->
        model, Cmd.refreshPage ()
    | SubscriptionCancelError (authenticatedUser, exn) ->
        let newModel =
            { model with
                Error = Some exn
                Loading = false }
        newModel, Cmd.none

let view
    (user : AuthenticatedUser)
    (onSignOut : unit -> unit)
    (model : Model)
    (dispatch : Msg -> unit) =

    let handleCancelSubscription
        (user : AuthenticatedUser)
        (game : Game)
        (subscription : Subscription) =

        let confirmed =
            match subscription.status with
            | SubscriptionStatus.TrialPeriod ->
                Browser.window.confirm
                    ("🍌 Are you sure you wish to cancel your trial? 🍌")
            | _ ->
                Browser.window.confirm
                    ("🍌 Are you sure you wish to unsubscribe? 🍌")
        if confirmed then
            SubscriptionCancellationRequest (user, game)
            |> dispatch

    let renderSubscriptionCard (game : Game) =
        match model.UserProfile with
        | Some userProfile ->
            let currentSubscription =
                userProfile.currentSubscriptions
                |> SubscriptionSet.getSubscription game
            match currentSubscription with
            | Some ({ status = SubscriptionStatus.TrialPeriod } as subscription) ->
                let onClickCancelHandler _ =
                    handleCancelSubscription
                        user
                        game
                        subscription
                SubscriptionCard.trial
                    game
                    subscription.expires
                    onClickCancelHandler
            | Some ({ status = SubscriptionStatus.Active } as subscription)
            | Some ({ status = SubscriptionStatus.PastDue } as subscription)
            | Some ({ status = SubscriptionStatus.SwitchingPlan } as subscription) ->
                let nextPeriod =
                    subscription.renewAs
                    |> Option.map (fun renewAs -> renewAs.billingPeriod)
                let onClickCancelHandler _ =
                    handleCancelSubscription
                        user
                        game
                        subscription
                let paymentMethod =
                    match subscription.externalId, userProfile.stripeCardInfo with
                    | ExternalSubscriptionId.PayPal _, _ ->
                        PaymentMethod.PayPal
                    | ExternalSubscriptionId.Stripe _, Some cardInfo ->
                        PaymentMethod.Stripe cardInfo
                    | _, _ ->
                        PaymentMethod.PayPal
                SubscriptionCard.subscribed
                    game
                    paymentMethod
                    subscription.plan.billingPeriod
                    nextPeriod
                    subscription.expires
                    onClickCancelHandler
            | Some ({ status = SubscriptionStatus.Cancelled } as subscription) ->
                SubscriptionCard.cancelled game subscription.expires
            | Some { status = SubscriptionStatus.AwaitingConfirmation }
            | Some { status = SubscriptionStatus.Expired }
            | None ->
                SubscriptionCard.free game
        | None ->
            SubscriptionCard.free game

    let renderError (error : exn option) =
        match error with
        | Some error ->
            Message.message [ Message.Color Color.IsDanger ] [
                Message.body [] [
                    str error.Message
                ]
            ]
        | None ->
            nothing

    if model.Loading then
        FullscreenCenterContentTemplate.template [
            Loading.loading ()
        ]
    else
        StandardTemplate.template [
            renderError model.Error
            Section.section [] [
                Heading.h1 [] [ str "Account Settings" ]
            ]
            Section.section [] [
                Content.content [ Content.Option.Size Size.IsLarge ] [
                    str "Manage your subscriptions"
                ]
                Content.content [] [
                    Columns.columns [ Columns.Option.IsMultiline ] [
                        Game.all
                        |> List.map (fun game ->
                            Column.column [ Column.Width (Screen.Desktop, Column.IsHalf)
                                            Column.Width (Screen.Mobile, Column.IsFull) ] [
                                renderSubscriptionCard game
                            ])
                        |> ofList
                    ]
                ]
            ]
        ] (Some Route.UserSettings) (user |> Some) onSignOut